import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_PERSON } from '../../helpers/routes';
import { fetchGetData } from '../../hooks/fetchGetData';
import { fetchPostDataWithUser } from '../../hooks/fetchPostDataWithUser';
import { contactSingleTabs } from '../../data/ContactSingle2';
import {
	updateDropdowns,
	updateTabsObject,
} from '../../helpers/SingleViewHelpers';
import { fetchPutData } from '../../hooks/fetchPutData';
import postDataAndImage from '../../helpers/postDataAndImage';
import { fetchPutArrayImages } from '../../hooks/fetchPutArrayImages';
import { transformContactRolesOptions } from '../../helpers/transformContactRolesData';

export const getContacts = createAsyncThunk(
	'contacts/getContacts',
	async () => {
		const { organization_user_id } = localStorage;
		return await fetchGetData(
			`${ENDPOINTS_PERSON.GET_CONTACTS}${organization_user_id}/`
		);
	}
);

export const getContact = createAsyncThunk(
	'contacts/getContact',
	async (id) => {
		return await fetchPostDataWithUser(`${ENDPOINTS_PERSON.GET_CONTACT}${id}/`);
	}
);

export const putContact = createAsyncThunk(
	'contacts/putContact',
	async (input) => {
		const { values, id } = input;
		return await fetchPutData(`${ENDPOINTS_PERSON.PUT_CONTACT}${id}/`, values);
	}
);

export const getContactRoles = createAsyncThunk(
	'contacts/getContactRoles',
	async () => {
		return await fetchGetData(ENDPOINTS_PERSON.GET_CONTACT_ROLES);
	}
);

export const postContact = createAsyncThunk(
	'contact/postContact',
	async (values) => {
		return await postDataAndImage(ENDPOINTS_PERSON.POST_CONTACT, values);
	}
);

export const organizeImageGrid = createAsyncThunk(
	'contacts/organizeImageGrid',
	async (values) => {
		return await fetchPutArrayImages(values);
	}
);

const initialSingleContact = {
	first_name: 'Este es un nombre',
	last_name: 'Este es un apellido',
	pictures: [''],
	email: 'email@gmail.com',
};

const initialContactSingleTabs = updateTabsObject(
	contactSingleTabs,
	initialSingleContact
);

const initialState = {
	contacts: null,
	singleContact: null,
	status: null,
	contactSingleTabs: initialContactSingleTabs,
	contactRoles: [],
	loadedContactRoles: false,
};

export const contactReducer = createSlice({
	name: 'contacts',
	initialState,
	reducers: {},
	extraReducers: {
		//Get All contacts Contact
		[getContacts.pending]: (state) => {
			state.status = 'loading';
		},
		[getContacts.fulfilled]: (state, { payload }) => {
			if (payload[0].person_name === 'Persona Desconocida') {
				state.contacts = {
					code: 0,
					message: 'No tienes contactos. Por favor crea un contacto.',
				};
			} else {
				state.contacts = payload;
			}
			state.status = 'success';
		},
		[getContacts.rejected]: (state) => {
			state.status = 'failed';
		},

		//Get Single Contact
		[getContact.pending]: (state) => {
			state.status = 'loading';
		},
		[getContact.fulfilled]: (state, { payload }) => {
			let Single = payload;
			state.singleContact = Single;
			state.contactSingleTabs = updateTabsObject(
				state.contactSingleTabs,
				Single
			);
			state.contactSingleTabs = updateDropdowns(
				state.contactSingleTabs,
				state.contactRoles,
				0,
				0,
				2
			);
			state.status = 'success';
		},
		[getContact.rejected]: (state) => {
			state.status = 'failed';
		},

		//put Single Contact
		[putContact.pending]: (state) => {
			state.status = 'loading';
		},
		[putContact.fulfilled]: (state) => {
			state.status = 'success';
		},
		[putContact.rejected]: (state) => {
			state.status = 'failed';
		},

		//get Contact roles
		[getContactRoles.fulfilled]: (state, { payload }) => {
			state.contactRoles = transformContactRolesOptions(payload);
			state.loadedContactRoles = true;
		},
		[getContactRoles.pending]: (state) => {
			state.status = 'loading';
		},
		[getContactRoles.rejected]: (state) => {
			state.status = 'failed';
		},

		//Post Contact
		[postContact.pending]: (state) => {
			state.status = 'loading';
		},
		[postContact.fulfilled]: (state) => {
			state.status = 'success';
		},
		[postContact.rejected]: (state) => {
			state.status = 'failed';
		},
		// organize grid of Images
		[organizeImageGrid.pending]: (state) => {
			state.status = 'loading';
		},
		[organizeImageGrid.fulfilled]: (state) => {
			state.status = 'success';
		},
		[organizeImageGrid.rejected]: (state) => {
			state.status = 'failed';
		},
	},
});

// export const {} = contactReducer.actions;

export default contactReducer.reducer;
