import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// UI
import { ReactComponent as ClosedCrossIcon } from '../assets/icons/CrossGrey.svg';

const ModalWithTextAndLink = ({
	showModalWithTextAndLink,
	setShowModalWithTextAndLink,
	text,
	thereIsAQuestionAndEmail,
	thereIsALink,
	h3Text,
	linkText,
	linkRedirection,
}) => {
	const handlePopUpClickContainer = (e) => e.stopPropagation();
	const { t } = useTranslation();
	if (showModalWithTextAndLink) {
		document.body.classList.add('overflow-hidden');
	}
	const hideModal = () => {
		document.body.classList.remove('overflow-hidden');
		setShowModalWithTextAndLink(false);
	};

	return (
		<div
			className='fixed top-0 right-0 z-20 my-auto flex h-full w-full cursor-pointer bg-black-50 bg-opacity-70'
			onClick={hideModal}
		>
			<div
				className='m-auto my-6 mx-auto flex h-3/4  w-[95%] items-center sm:w-1/2 md:p-0 lg:w-1/3'
				onClick={handlePopUpClickContainer}
			>
				<div className='relative flex flex-col rounded-lg  border-0 bg-white pb-8 shadow-lg'>
					<div
						className='absolute top-2 right-2  cursor-pointer'
						onClick={hideModal}
					>
						<ClosedCrossIcon />
					</div>
					<div className='my-4 mx-8'>
						<p className='mt-8 text-center text-base font-light'>{t(text)}</p>
						<div className='flex flex-col items-center'>
							<h3 className='p-2'>{h3Text}</h3>
							{thereIsALink && (
								<Link
									to={linkRedirection}
									className=' text-[14px] font-normal leading-[18px] text-green-50 underline'
								>
									{linkText}
								</Link>
							)}
							{thereIsAQuestionAndEmail && (
								<h3 className='py-4 text-[14px] font-normal leading-[18px]'>
									{t('NecesitasAyuda')}
									<span className='pl-1 text-green-50 underline'>
										<a href='mailto: contacto@datarte.art'>
											contacto@datarte.art
										</a>
									</span>
								</h3>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ModalWithTextAndLink.propTypes = {
	/** Boolean that determines if the modal is visible in the parent component*/
	showModalWithTextAndLink: PropTypes.bool,
	/** Function to sets the showModalWithTextAndLink value in the parent component */
	setModalWithTextAndLink: PropTypes.func,
	/** Boolean prop that determines  if the question and email will be show  */
	thereIsAQuestionAndEmail: PropTypes.bool,
	/**String prop with text content  */
	text: PropTypes.string,
	/**String prop with the third level heading content  */
	h3Text: PropTypes.string,
	/**Boolean prop that determines  if the link will be show */
	thereIsALink: PropTypes.bool,
	/**String prop with the redirection string for the link */
	linkRedirection: PropTypes.string,
	/**String prop with the text content for the link */
	linkText: PropTypes.string,
};

ModalWithTextAndLink.defaultProps = {
	thereIsAQuestionAndEmail: false,
	thereIsALink: true,
	linkRedirection: '/recuperacion_contrasena',
};

export default ModalWithTextAndLink;
