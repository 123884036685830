import {
	ENDPOINTS_ARTIST,
	ENDPOINTS_ARTWORK,
	ENDPOINTS_COLLECTION,
	ENDPOINTS_DOCUMENT,
	ENDPOINTS_LOCATION,
	ENDPOINTS_PERSON,
	ENDPOINTS_PORTFOLIO,
	ENDPOINTS_EXPOSITION,
} from './routes';

export const urlDeleteElement = (section, id) => {
	switch (section) {
		case 'artistas':
			return {
				getInfoElementUrl: `${ENDPOINTS_ARTIST.GET_ARTIST_INFO}${id}/`,
				deleteElementUrl: `${ENDPOINTS_ARTIST.DELETE_ARTIST}${id}/`,
			};
		case 'obras':
			return {
				getInfoElementUrl: `${ENDPOINTS_ARTWORK.GET_ARTWORK_INFO}${id}/`,
				deleteElementUrl: `${ENDPOINTS_ARTWORK.DELETE_ARTWORK}${id}/`,
			};

		case 'contactos':
			return {
				getInfoElementUrl: `${ENDPOINTS_PERSON.GET_CONTACT_INFO}${id}/`,
				deleteElementUrl: `${ENDPOINTS_PERSON.DELETE_CONTACT}${id}/`,
			};

		case 'documentos':
			return {
				getInfoElementUrl: `${ENDPOINTS_DOCUMENT.GET_INFO_DOCUMENT}${id}/`,
				deleteElementUrl: `${ENDPOINTS_DOCUMENT.DELETE_DOCUMENT}${id}/`,
			};

		case 'colecciones':
			return {
				getInfoElementUrl: `${ENDPOINTS_COLLECTION.GET_COLLECTION_INFO}${id}/`,
				deleteElementUrl: `${ENDPOINTS_COLLECTION.DELETE_COLLECTION}${id}/`,
			};

		case 'lugares':
			return {
				getInfoElementUrl: `${ENDPOINTS_LOCATION.GET_LOCATIONS_GENERAL_INFO}${id}/`,
				deleteElementUrl: `${ENDPOINTS_LOCATION.DELETE_LOCATION_GENERAL}${id}/`,
			};
		case 'exposiciones':
			return {
				getInfoElementUrl: `${ENDPOINTS_EXPOSITION.GET_EXPOSITION_INFO}${id}/`,
				deleteElementUrl: `${ENDPOINTS_EXPOSITION.DELETE_EXPOSITION}${id}/`,
			};

		case 'salas':
			return {
				getInfoElementUrl: `${ENDPOINTS_LOCATION.GET_LOCATION_ROOM_INFO}${id}/`,
				deleteElementUrl: `${ENDPOINTS_LOCATION.DELETE_LOCATION_ROOM}${id}/`,
			};

		case 'ubicacion':
			return {
				getInfoElementUrl: `${ENDPOINTS_LOCATION.GET_INFO_LOCATION}${id}/`,
				deleteElementUrl: `${ENDPOINTS_LOCATION.DELETE_UBICATION}${id}/`,
			};

		case 'portafolios':
			return {
				getInfoElementUrl: `${ENDPOINTS_PORTFOLIO.GET_INFO_PORTFOLIO}${id}/`,
				deleteElementUrl: `${ENDPOINTS_PORTFOLIO.DELETE_PORTFOLIO}${id}/`,
			};

		default:
			return '';
	}
};
