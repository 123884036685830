/* -- Third Party -- */
import { useEffect, useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* BL */
import {
	documentInitialValues,
	documentFormValidationSchema,
} from '../../../BusinessLogic/data/DocumentSingle2';
import {
	getDocuments,
	postDocument,
} from '../../../BusinessLogic/redux/reducers/document';
import { backSectionURL } from '../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../BusinessLogic/helpers/textGoBackGenerator';
import { getArtwork } from '../../../BusinessLogic/redux/reducers/artwork';
import {
	getArtist,
	getArtists,
} from '../../../BusinessLogic/redux/reducers/artist';
import { getArtworks } from '../../../BusinessLogic/redux/reducers/artwork';

/* UI */
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import whiteCheck from '../../../UI/assets/icons/whiteCheck.svg';
import ListItemText from '../../../UI/atoms/Inputs/ListItemText';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import FieldTextFormik from '../../../UI/molecules/Field/FieldTextFormik';
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import SectionForm from '../../../UI/organisms/Section/SectionForm';
import FileSectionForm from '../../../UI/organisms/Document/FileSectionForm';
import LoadingText from '../../../UI/molecules/Loading/LoadingText';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

/**
 * @description manages the Documents form page edit or create.
 * @returns
 */

export default function DocumentsForm() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { singleDocument } = useSelector((state) => state.documents);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);
	const { artists } = useSelector((state) => state.artists);
	const { artworks } = useSelector((state) => state.artworks);
	const { appUser } = useSelector((state) => state.appUser);

	const { organization_user_id, unknown_values } = localStorage;
	const unknownValues = unknown_values && JSON.parse(unknown_values);

	const mainArtistId = appUser?.main_artist_id;

	const [showSaving, setShowSaving] = useState(false);

	useEffect(() => {
		if (!artists) {
			dispatch(getArtists());
		}
	}, [dispatch, artists]);

	useEffect(() => {
		if (!artworks) {
			dispatch(getArtworks());
		}
	}, [dispatch, artworks]);

	const checkRedirect = () => {
		let redirect = '/documentos';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage = 'RegresarDocumentos';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	const isCreatingDocumentFromArtwork =
		navigationHistoryURL[0]?.includes('/obras/');

	return (
		<TemplateWithNavBar>
			<div className={showSaving && 'opacity-60'}>
				<SecondaryNavbarSpecific
					title={t('NuevoDocumento')}
					textColor='text-light_green-50'
					color='bg-light_green-50'
					hide='md:invisible'
					redirect={checkRedirect()}
					textBack={CheckTextBack()}
					textBackDesign='pl-3 text-light_green-50'
					backAction={() => returnBackAction()}
					arrowLeftColor='light_green'
				/>

				<Formik
					initialValues={{
						...documentInitialValues,
						main_picture_id: unknownValues.picture_id,
						organization_user_id: organization_user_id,
						file_type: 'IMAGE',
						type: 'DOCUMENT',
						type_id: singleDocument?.id ?? 0,
						element: isCreatingDocumentFromArtwork ? 'ARTWORK' : 'NO_ELEMENT',
						element_id: isCreatingDocumentFromArtwork
							? navigationHistoryURL[0].slice(7)
							: 0,
					}}
					validationSchema={documentFormValidationSchema}
					onSubmit={(values) => {
						if (values.element_id === 0) {
							values = { ...values, element: 'NO_ELEMENT' };
						}
						if (values.element === 'ARTIST') {
							values = { ...values, artist_id: values.element_id };
						}
						if (values.element === 'ARTWORK') {
							values = { ...values, artwork_id: values.element_id };
						}
						setShowSaving(true);
						dispatch(postDocument(values))
							.then((res) => {
								if (values.element === 'ARTWORK') {
									dispatch(getArtwork(values.element_id));
								}
								navigate(`/documentos/${res.payload.id}`);
								dispatch(getDocuments());
								dispatch(getArtist({ id: mainArtistId }));
							})
							.finally(() => setShowSaving(false));
					}}
				>
					{(formProps) => (
						<Form className='mb-20'>
							<List>
								<div className='flex flex-col gap-4 md:flex-row lg:gap-12'>
									<FileSectionForm
										url={singleDocument?.document_url}
										alt={singleDocument?.name}
										formProps={formProps}
									/>

									<div className='mx-auto flex w-full flex-col md:w-1/2'>
										<SectionForm
											title={t('Identificación')}
											textColor='text-light_green-50'
											color='bg-light_green-50'
										>
											<FieldTextFormik
												label={t('Nombre')}
												dbName='name'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.name}
											/>
											<FieldTextFormik
												label={t('Descripción')}
												dbName='description'
												type='Text'
											/>
											<FieldTextFormik
												label={t('Fecha')}
												dbName='date'
												type='Date'
											/>

											<ListItem
												style={{
													paddingLeft: '0px',
													paddingRight: '0px',
													justifyContent: 'space-between',
												}}
											>
												<ListItemText
													primary={t('Pertenece')}
													design='body-Text2'
													style={{ fontWeight: 400 }}
												/>
												<Field
													name='element'
													type='Text'
													as='select'
													className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
												>
													<option value=''>{t('Ninguno')}</option>
													{artists?.length > 0 && (
														<option value='ARTIST'>{t('Artista')}</option>
													)}
													{artworks?.length > 0 && (
														<option value='ARTWORK'>{t('Obras')}</option>
													)}
												</Field>
											</ListItem>

											<ListItem
												style={{
													paddingLeft: '0px',
													paddingRight: '0px',
													justifyContent: 'space-between',
												}}
											>
												<ListItemText
													primary={''}
													design='body-Text2'
													style={{ fontWeight: 400 }}
												/>
												{((formProps.values.element === t('ARTIST') &&
													artists?.length >= 1) ||
													(formProps.values.element === t('ARTWORK') &&
														artworks?.length >= 1)) && (
													<Field
														name='element_id'
														type='Text'
														as='select'
														className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
													>
														{formProps.values.element === t('ARTIST') && (
															<>
																<option value={''} key={0}>
																	{'Seleccionar artista'}
																</option>

																{artists.map((element) => (
																	<option
																		value={element.id}
																		key={element.id + 1}
																	>
																		{element.name}
																	</option>
																))}
															</>
														)}
														{formProps.values.element === t('ARTWORK') && (
															<>
																<option value={''} key={0}>
																	{'Seleccionar obra'}
																</option>
																{artworks.map((element) => (
																	<option
																		value={element.id}
																		key={element.id + 1}
																	>
																		{element.name}
																	</option>
																))}
															</>
														)}
													</Field>
												)}
											</ListItem>
											<ErrorMessage name='element_id' />
										</SectionForm>

										{showSaving ? (
											<LoadingText text={t('Guardando')} />
										) : (
											<ButtonFabExtendedPrimary
												className='self-center text-white'
												icon={whiteCheck}
												text={t('Guardar')}
											/>
										)}
									</div>
								</div>
							</List>
						</Form>
					)}
				</Formik>
			</div>
		</TemplateWithNavBar>
	);
}
