import { Routes, Route } from 'react-router-dom';

//BL
import FormLocation from '../../Pages/sections/location/lastLocation/FormLocation';
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';
import LastLocationSingle from '../../Pages/sections/location/lastLocation/LastLocationSingle';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';

export const LocationRouter = () => {
	return (
		<Routes>
			<Route
				path='/:id/edit'
				element={
					<PrivateRoute>
						<FormLocation action='edit' />
					</PrivateRoute>
				}
			/>
			<Route
				path='/new'
				element={
					<PrivateRoute>
						<FormLocation action='new' />
					</PrivateRoute>
				}
			/>
			<Route
				path='/:id'
				element={
					<PrivateRoute>
						<LastLocationSingle />
					</PrivateRoute>
				}
			/>

			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default LocationRouter;
