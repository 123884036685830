//3rd
import { PropTypes } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//BL
import { downloadImage } from '../../../BusinessLogic/helpers/downloadMedia';
import ViewModal from '../../templates/ViewModal';
import textBlankEntityGenerator from '../../../BusinessLogic/helpers/textBlankEntityGenerator';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import ImageAndTitle from '../../molecules/Image/ImageAndTitle';
import TextSubTitle from '../../molecules/Text/TextSubTitle';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import Cross from '../../assets/icons/Cross.svg';
import DownloadIcon from '../../assets/icons/download.svg';
import CheckIcon from '../../assets/icons/whiteCheck.svg';
import Loading from '../Loading/PortfolioLoading';
import SortableGallery from '../Image/SortableGallery';
import { ReactComponent as AddBlack } from '../../assets/icons/AddBlack.svg';
import { ReactComponent as DotsVerticalGreen } from '../../assets/icons/DotsVerticalGreen.svg';

/**
 * @description render a section with its title and a grid of images
 * @param {*} param0
 * @returns
 */
const SectionImageGrid = ({
	section,
	sectionTitle,
	colorHr,
	images,
	hasEdit,
	setHasEdit,
	AddIconLink,
	hasAdd,
	hasDots,
	showDotsPopup,
	setShowDotsPopup,
	dotsOptionsInTab,
	showDownloadOptions,
	setShowDownloadOptions,
	deleteImagesFunction,
	editIconOptions,
	submitOrganizeImageGrid,
	submitOrganizeArtworksGrid,
	setShowEditPopup,
	submitOrganizeSubCollections,
	showEditPopup,
	setShowOrganizeGridOption,
	showOrganizeGridOption,
	setShowDeleteOption,
	showDeleteOption,
	saveLastSectionURL,
	saveURLHistory,
	objectFitProp,
	dotsOptionsInArtworksTab,
	isDefaultGeneralCollection,
}) => {
	const imagesToRender = images[0];

	const sectionName = imagesToRender.title.toLowerCase();
	const { t } = useTranslation();
	const [downloadArray, setDownloadArray] = useState([]);
	const [selectedImages, setSelectedImages] = useState(0);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [confirmDeleteElement, setConfirmDeleteElement] = useState(false);
	const idImage = useRef();
	const createImageStatus = useSelector((state) => state.createImage.status);
	const [sortableImages, setSortableImages] = useState([]);
	const [sortableArtwork, setSortableArtwork] = useState([]);
	const [sortableSubCollections, setSortableSubCollections] = useState([]);

	useEffect(() => {
		setSortableImages(imagesToRender.information);
		setSortableArtwork(imagesToRender.information);
		setSortableSubCollections(imagesToRender.information);
	}, [imagesToRender]);

	useEffect(() => {
		setDownloadArray([]);
		setSelectedImages(0);
	}, [showDownloadOptions]);

	const addCheckToImage = (imageToDownload, imageActive) => {
		if (!imageActive) {
			setDownloadArray([...downloadArray, imageToDownload]);
			setSelectedImages((selectedImages) => selectedImages + 1);
		}

		if (imageActive) {
			setSelectedImages((selectedImages) => selectedImages - 1);
			downloadArray.forEach((image) => {
				if (image === imageToDownload) {
					downloadArray.splice(image, 1);
				}
			});
		}
	};

	const cancelButtonAction = () => {
		setShowDownloadOptions(false);
		setShowOrganizeGridOption(false);
		setShowDeleteOption(false);
	};

	const downloadButtonFunction = () => {
		downloadArray.forEach((image, i) => {
			downloadImage(image, `Imagen ${i}`);
		});
	};

	const DeleteImageButton = (id) => {
		idImage.current = id;
		setShowConfirmationModal(true);
	};

	const handleImageOrVideo = (image) => {
		let isVideo = false;
		if (imagesToRender.title === 'Obras') {
			isVideo = image.video_url;
		}
		return isVideo;
	};

	useEffect(() => {
		if (confirmDeleteElement) {
			deleteImagesFunction(idImage.current);
		}
		setConfirmDeleteElement(false);
	}, [confirmDeleteElement, deleteImagesFunction]);

	return (
		<div className='flex flex-col'>
			<TextSubTitle
				section={section}
				colour={colorHr}
				design=''
				text={sectionTitle}
				hasEdit={hasEdit}
				setHasEdit={setHasEdit}
				AddIconLink={AddIconLink}
				hasAdd={hasAdd}
				hasDots={hasDots}
				showDotsPopup={showDotsPopup}
				setShowDotsPopup={setShowDotsPopup}
				dotsOptionsInTab={dotsOptionsInTab}
				dotsOptionsInArtworksTab={dotsOptionsInArtworksTab}
				showDownloadOptions={showDownloadOptions}
				setShowDownloadOptions={setShowDownloadOptions}
				editIconOptions={editIconOptions}
				setShowEditPopup={setShowEditPopup}
				showEditPopup={showEditPopup}
				saveLastSectionURL={saveLastSectionURL}
				saveURLHistory={saveURLHistory}
				isDefaultGeneralCollection={isDefaultGeneralCollection}
			/>
			<>
				{showConfirmationModal && (
					<ViewModal
						modalTitle={t('EliminarImagen')}
						modalTextOne={t('SeguroQueDeseasEliminarEstaImagen')}
						setConfirmDeleteElement={setConfirmDeleteElement}
						closeMenu={setShowConfirmationModal}
					/>
				)}

				{showDownloadOptions && (
					<TextContentSecond
						design={'mb-4'}
						text={`${selectedImages} ${t('ImágenesSeleccionadas')}`}
					/>
				)}
				{imagesToRender?.information.length < 1 &&
				createImageStatus !== 'loading' ? (
					<div className='flex flex-row flex-wrap'>
						<TextContentSecond
							text={
								sectionTitle === 'Imágenes'
									? t('ParaCrearNuevaImagen')
									: `${t(textBlankEntityGenerator(sectionTitle))}, `
							}
							design='pr-1'
						/>
						<div className='flex flex-row'>
							<TextContentSecond text={t('HazClicEnElBotón')} design='pr-1' />
							{sectionTitle === 'Imágenes' ? (
								((<DotsVerticalGreen />), (<AddBlack />))
							) : (
								<AddBlack />
							)}
						</div>
					</div>
				) : createImageStatus === 'loading' ? (
					<Loading />
				) : (
					<div className='flex flex-col'>
						{showOrganizeGridOption ? (
							<SortableGallery
								section={section}
								gridDesign='w-full grid grid-cols-3 md:grid-cols-4 gap-4 justify-center'
								DeleteImageButton={DeleteImageButton}
								sortableImages={sortableImages}
								setSortableImages={setSortableImages}
								sortableArtwork={sortableArtwork}
								setSortableArtwork={setSortableArtwork}
								sortableSubCollections={sortableSubCollections}
								setSortableSubCollections={setSortableSubCollections}
								sectionTitle={sectionTitle}
							/>
						) : (
							<div className='grid w-full grid-cols-3 justify-center gap-4 md:grid-cols-4'>
								{imagesToRender?.information.map((image, i) => (
									<ImageAndTitle
										key={i}
										id={image.id}
										imagen={
											handleImageOrVideo(image)
												? image.video_url
												: image.main_picture_url
										}
										title={image.name}
										sectionTitle={sectionTitle}
										redirect={sectionName}
										isPublic={image.isPublic}
										showDownloadOptions={showDownloadOptions}
										addCheckToImage={addCheckToImage}
										DeleteImageButton={DeleteImageButton}
										showDeleteOption={showDeleteOption}
										saveLastSectionURL={saveLastSectionURL}
										objectFitProp={objectFitProp}
										isVideoImage={handleImageOrVideo(image)}
									/>
								))}
							</div>
						)}
						{(showDownloadOptions ||
							showDeleteOption ||
							showOrganizeGridOption) && (
							<div className='my-5 mt-14 flex w-full flex-row justify-center gap-3'>
								<ButtonFab
									label={t('Cancelar')}
									colour='white'
									action={() => {
										cancelButtonAction();
										if (
											sectionTitle === t('Obras') ||
											sectionTitle === t('Subcolecciones')
										) {
											setHasEdit(false);
										} else {
											setHasEdit(true);
										}
									}}
									icon={Cross}
								/>
								{showDownloadOptions && (
									<ButtonFab
										colour='green'
										label={t('Descargar')}
										icon={DownloadIcon}
										action={() => downloadButtonFunction()}
									/>
								)}
								{showOrganizeGridOption && (
									<ButtonFab
										colour='green'
										label={t('Guardar')}
										icon={CheckIcon}
										action={() => {
											const isSubCollectionsTab =
												section === 'Collection' &&
												sectionTitle === t('Subcolecciones');
											if (isSubCollectionsTab) {
												submitOrganizeSubCollections(sortableSubCollections);
											} else if (section === 'Collection') {
												submitOrganizeArtworksGrid(sortableArtwork);
											} else {
												submitOrganizeImageGrid(sortableImages);
											}
											setShowOrganizeGridOption(false);
										}}
									/>
								)}
							</div>
						)}
					</div>
				)}
			</>
		</div>
	);
};

export default SectionImageGrid;

SectionImageGrid.propTypes = {
	/* title of the section */
	sectionTitle: PropTypes.string,
	/* string with the color of the hr */
	colorHr: PropTypes.string,
	/** data used for the images */
	images: PropTypes.array,
	/**  string with the link in the add (+) icon */
	AddIconLink: PropTypes.string,
	/**  bool that determines if there is edit icon or not*/
	hasEdit: PropTypes.bool,
	/**  function that makes visible or not the edit button  */
	setHasEdit: PropTypes.func,
	/**  bool that determines if there is add icon or not*/
	hasAdd: PropTypes.bool,
	/**  bool that determines if there is dots icon or not  */
	hasDots: PropTypes.func,
	/**  bool that determines visibility of the dots icon pop up   */
	showDotsPopup: PropTypes.bool,
	/**  function that makes visible or not the dots icon pop up  */
	setShowDotsPopup: PropTypes.func,
	/**  bool that determines visibility of the edit icon pop up   */
	showEditPopup: PropTypes.bool,
	/**  function that makes visible or not the edit icon pop up  */
	setShowEditPopup: PropTypes.func,
	/**  array with the options of the dots dropdown  */
	dotsOptionsInTab: PropTypes.array,
	/** array with the options of the dots dropdown in section artworks in collections */
	dotsOptionsInArtworksTab: PropTypes.array,
	/**  array with the options of the edit dropdown  */
	editIconOptions: PropTypes.array,
	/**  bool that determines visibility of the download options  */
	showDownloadOptions: PropTypes.bool,
	/**  function that makes visible or not the download options  */
	setShowDownloadOptions: PropTypes.func,
	/**  bool that determines visibility of the grid to organize the images */
	showOrganizeGridOption: PropTypes.bool,
	/**  function that makes visible or not the grid to organize the images  */
	setShowOrganizeGridOption: PropTypes.func,
	/**  bool that determines visibility of the delete option */
	setShowDeleteOption: PropTypes.bool,
	/**  function that makes visible or not the delete options  */
	showDeleteOption: PropTypes.func,
	/**  function that delete a single image in Images Tab  */
	deleteImagesFunction: PropTypes.func,
	/**  function that organize the grid of images in Images Tabs  */
	submitOrganizeImageGrid: PropTypes.func,
	/**  function that organize the grid of artworks in documents Tab */
	submitOrganizeArtworksGrid: PropTypes.func,
	/**  bool that handle to save the actual specific location */
	saveLastSectionURL: PropTypes.bool,
	/* function that execute to save the actual url in redux state */
	saveURLHistory: PropTypes.func,
	/** determines the object fit of the pre-visualization images */
	objectFitProp: PropTypes.string,
	/**Boolean prop that determines if the image section is located on default general collection*/
	isDefaultGeneralCollection: PropTypes.bool,
};

SectionImageGrid.defaultProps = {
	saveLastSectionURL: false,
	objectFitProp: 'cover',
};
