//3rd
import { PropTypes } from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	DndContext,
	closestCenter,
	MouseSensor,
	TouchSensor,
	DragOverlay,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	rectSortingStrategy,
	useSortable,
} from '@dnd-kit/sortable';

//UI
import PortfolioSlidePreview from './PortfolioSlidePreview';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import { useCallback } from 'react';
import { forwardRef } from 'react';

/**
 * @description Renders a draggable slides section of an expert portfolio
 */
const PortfolioDraggableSlides = ({
	slides,
	reorderSlidesAction,
	setShowReorderView,
}) => {
	const { t } = useTranslation();
	const [draggableSlides, setDraggableSlides] = useState(slides);
	const [activeId, setActiveId] = useState(null);

	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

	const handleSaveSection = () => {
		const reorderedSlides = draggableSlides.map((slide, index) => ({
			...slide,
			order: index + 1,
		}));
		reorderSlidesAction(reorderedSlides);
	};

	const handleDragStart = useCallback((event) => {
		setActiveId(event.active.id);
	}, []);

	const handleDragCancel = useCallback(() => {
		setActiveId(null);
	}, []);

	const handleDragEnd = useCallback((event) => {
		const { active, over } = event;
		if (active.id !== over?.id) {
			setDraggableSlides((slides) => {
				const oldIndex = slides.findIndex(
					(slide) => slide.slideId === active.id
				);
				const newIndex = slides.findIndex(
					(slide) => slide.slideId === over?.id
				);
				return arrayMove(slides, oldIndex, newIndex);
			});
		}
		setActiveId(null);
	}, []);

	return (
		<div className='w-full'>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragStart={handleDragStart}
				onDragEnd={handleDragEnd}
				onDragCancel={handleDragCancel}
			>
				<SortableContext items={draggableSlides} strategy={rectSortingStrategy}>
					<ul className='mx-auto grid w-5/6 max-w-6xl grid-cols-1 gap-5  pt-5 sm:grid-cols-2 lg:grid-cols-3 '>
						{draggableSlides.map((slide, index) => (
							<SortableItem
								key={slide.slideId}
								id={slide.slideId}
								slideId={slide.slideId}
								slide={slide}
								index={index}
							/>
						))}
					</ul>
				</SortableContext>
				<DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
					{activeId ? <Item slides={slides} id={activeId} isDragging /> : null}
				</DragOverlay>
			</DndContext>

			<div className='mb-48 mt-8 flex w-full flex-wrap items-center justify-center gap-8'>
				<ButtonFab
					label={t('Cancelar')}
					size={'xlarge'}
					colour={'white'}
					action={() => setShowReorderView(false)}
				/>
				<ButtonFab
					label={t('Guardar')}
					size={'xlarge'}
					action={() => handleSaveSection()}
				/>
			</div>
		</div>
	);
};

PortfolioDraggableSlides.propTypes = {
	/** Slides array to display */
	slides: PropTypes.array,
	/** Function tha determines the action to run when confirming slides reorder */
	reorderSlidesAction: PropTypes.func,
	/** Function to update the boolean state that determines the display of the draggable section*/
	setShowReorderView: PropTypes.func,
};

export default PortfolioDraggableSlides;

const SortableItem = ({ slide, slideId, id, index }) => {
	const { t } = useTranslation();
	const {
		isDragging,
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({ id: slideId });

	const style = {
		transform: CSS?.Transform?.toString(transform),
		transition,
	};

	return (
		<div
			ref={setNodeRef}
			withOpacity={isDragging}
			{...attributes}
			{...listeners}
			style={style}
			id={id}
			slideId={slideId}
			className='cursor-pointer bg-light_grey-75'
		>
			<PortfolioSlidePreview
				slide={slide}
				slideDesign=' animate-shake outline-dashed outline-offset-2 outline-gray-500'
				slideLabel={`${index + 1}. ${t(slide.type)}`}
			/>
		</div>
	);
};

const Item = forwardRef(
	({ id, slides, withOpacity, isDragging, style, ...props }, ref) => {
		const inlineStyles = {
			opacity: withOpacity ? '0.5' : '1',
			transformOrigin: '50% 50%',
			borderRadius: '10px',
			cursor: isDragging ? 'grabbing' : 'grab',
			boxShadow: isDragging
				? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
				: 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
			transform: isDragging ? 'scale(1.05)' : 'scale(1)',
			...style,
		};

		return (
			<div
				ref={ref}
				style={inlineStyles}
				{...props}
				className='cursor-pointer bg-light_grey-75'
			>
				<PortfolioSlidePreview
					slide={slides.find((slide) => slide.slideId === id)}
					slideDesign=' outline-dashed outline-offset-2 outline-gray-500'
				/>
			</div>
		);
	}
);
