import { NAVBAR_EN } from './NavbarTranslations/Navbar_en';
import { ONBOARDING_EN } from './OnboardingTranslations/Onboarding_en';
import { SINGLE_EN } from './SingleTranslations/Single_en';
import { VIEWALL_EN } from './ViewAllTraslations/ViewAll_en';
import { FORM_EN } from './FormTranslations/Form_en';
import { PAYMENTS_EN } from './PaymentsTranslations/Payments_en';

export const TRANSLATIONS_EN = {
	...NAVBAR_EN,
	...SINGLE_EN,
	...FORM_EN,
	...ONBOARDING_EN,
	...VIEWALL_EN,
	...PAYMENTS_EN,
};
