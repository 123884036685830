// 3rd party
import {
	Page,
	Text,
	View,
	Document,
	Image,
	Svg,
	Path,
	StyleSheet,
} from '@react-pdf/renderer';

//BL
import { getHttpRequestDefaultOptions } from '../../../BusinessLogic/helpers/getHttpRequestOptions';

/**
 * @description Download postulation catalogue of the one user
 * @param
 * @returns
 */

const styles = StyleSheet.create({
	page: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		fontSize: '13px',
		fontFamily: 'Helvetica',
		padding: '25px 0',
	},
	content: {
		padding: '50px',
	},
	name: {
		marginBottom: '10px',
		color: '#9CBDB9',
		fontFamily: 'Helvetica-Bold',
	},
	text: {
		marginBottom: '2px',
	},
	cards: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
	card: {
		width: '30%',
		overflow: 'hidden',
		marginBottom: '25px',
		padding: '20px 0',
	},
	cardTitle: {
		fontFamily: 'Helvetica-Bold',
		fontSize: '12px',
		margin: '16px 0',
	},
	cardImage: {
		width: '100%',
		height: '140px',
		objectFit: 'cover',
	},
	cardDetails: {
		fontSize: '11px',
	},
	cardBlock: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		fontSize: '11px',
	},
	textBold: {
		fontSize: '11px',
		fontFamily: 'Helvetica-Bold',
		marginBottom: '2px',
	},
	textLong: {
		whiteSpace: 'break-spaces',
		wordWrap: 'wrap',
	},
	footer: {
		backgroundColor: '#9CBDB9',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		color: '#fff',
		padding: '5px 15px',
	},
	footerContentOne: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	footerText: {
		fontSize: '12px',
		marginLeft: '10px',
	},
	logoDatarte: {
		height: '30px',
		width: '30px',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		marginBottom: '20px',
	},
	headerImageContainer: {
		width: '85px',
		height: '85px',
		borderRadius: '9999px',
		aspectRadio: '1/1',
		overflow: 'hidden',
	},
	headerImage: {
		objectFit: 'cover',
		height: '100%',
		maxWidth: '100%',
	},
	headerInformation: {
		padding: '0px 20px',
	},
});

export default function DownloadableForm({ artworks, artist }) {
	return (
		<Document>
			<Page style={styles.page} size={'A4'}>
				<View style={styles.content}>
					<View style={styles.header}>
						<View style={styles.headerImageContainer}>
							<Image
								style={styles.headerImage}
								src={getHttpRequestDefaultOptions(artist?.artist_main_image)}
							/>
						</View>
						<View style={styles.headerInformation}>
							<Text style={styles.name}>{artist.organization_user_name}</Text>
							<Text style={styles.text}>{artist.artist_city}</Text>
							<Text style={styles.text}>{artist.artist_country}</Text>
							<Text style={styles.text}>{artist.artist_phone}</Text>
							<Text style={styles.text}>{artist.artist_email}</Text>
						</View>
					</View>
					<View>
						<View style={styles.cards}>
							{artworks?.map((singleArtwork, i) => (
								<View style={styles.card} key={i}>
									<Image
										style={styles.cardImage}
										src={getHttpRequestDefaultOptions(
											singleArtwork.main_picture_url
										)}
									/>
									<Text>{singleArtwork.name}</Text>
									<View style={styles.cardDetails}>
										{singleArtwork.date && (
											<View style={styles.cardBlock}>
												<Text>{singleArtwork.date?.slice(0, 4)}</Text>
											</View>
										)}
										{singleArtwork.technique && (
											<View style={styles.cardBlock}>
												<Text>{singleArtwork.technique}</Text>
											</View>
										)}
										{singleArtwork.materials && (
											<View style={styles.cardBlock}>
												<Text style={styles.textLong}>
													{singleArtwork.materials}
												</Text>
											</View>
										)}
									</View>
								</View>
							))}
						</View>
					</View>
				</View>
				<View style={styles.footer}>
					<View style={styles.footerContentOne}>
						<Svg style={styles.logoDatarte}>
							<Path
								d='M3.91016 26.6266H11.1007C17.8573 26.6266 22.6303 22.1945 22.6303 15.9648C22.6303 9.73508 17.8573 5.33398 11.1007 5.33398H3.91016V26.6266ZM6.32766 24.4261V7.53453H11.1007C16.4316 7.53453 20.1818 11.0368 20.1818 15.9648C20.1818 20.9238 16.4316 24.4261 11.1007 24.4261H6.32766Z'
								fill='white'
							/>
							<Path
								d='M26.4395 26.5962H28.9413L20.0233 5.33398H17.5215L8.60352 26.5962H11.1053L14.0779 19.4778H23.4669L26.4395 26.5962ZM14.0779 19.4778L18.7871 8.30512L23.4669 19.4778H14.0779Z'
								fill='white'
							/>
						</Svg>
						<Text style={styles.footerText}>Creado en Datarte.art</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
}
