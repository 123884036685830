import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGetData } from '../../hooks/fetchGetData';
import { ENDPOINT_RESOURCES } from '../../helpers/routes';

/* Get all calendar events*/
export const getAllResources = createAsyncThunk(
	'resources/fetchAllResources',
	async () => {
		const { organization_user_id } = localStorage;
		return await fetchGetData(
			`${ENDPOINT_RESOURCES.GET_ALL_RESOURCES}/${organization_user_id}`
		);
	}
);

const initialState = {
	resources: null,
	status: null,
};

export const calendarSlice = createSlice({
	name: 'resources',
	initialState,
	reducers: {},
	extraReducers: {
		// Get all resources
		[getAllResources.pending]: (state) => {
			state.status = 'loading';
		},
		[getAllResources.fulfilled]: (state, { payload }) => {
			state.resources = payload.article_resources;
			state.status = 'success';
		},
		[getAllResources.rejected]: (state) => {
			state.status = 'failed';
		},
	},
});

export default calendarSlice.reducer;
