//3rd
import { PropTypes } from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	DndContext,
	closestCenter,
	MouseSensor,
	TouchSensor,
	DragOverlay,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	rectSortingStrategy,
	useSortable,
} from '@dnd-kit/sortable';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import { useCallback } from 'react';
import { forwardRef } from 'react';
import EditExpertArtwork from './ExpertItems/EditExpertArtwork';
import {
	putExpertPortfolioSlideItemsOrder,
	setCurrentViewExpertPortfolioAndSelection,
} from '../../../BusinessLogic/redux/reducers/portfolio';
import { useDispatch, useSelector } from 'react-redux';

/**
 * @description Renders a draggable slides section of an expert portfolio
 */
const PortfolioDraggableArtworks = ({ draggableItems, slideId }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [items, setItems] = useState(draggableItems);
	const [activeId, setActiveId] = useState(null);

	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

	const { expertPortfolioViewAndSelection } = useSelector(
		(state) => state.portfolios
	);

	const handleSaveSection = () => {
		const reorderedItems = items.map((item, index) => ({
			...item,
			order: index + 1,
		}));

		const newOrderedItems = {
			slideId,
			array_slide_item: reorderedItems,
		};

		dispatch(
			putExpertPortfolioSlideItemsOrder({
				slideId,
				newOrderedItems,
			})
		).finally(() => {
			dispatch(
				setCurrentViewExpertPortfolioAndSelection({
					...expertPortfolioViewAndSelection,
					isReordering: false,
				})
			);
		});
	};

	const handleDragStart = useCallback((event) => {
		setActiveId(event.active.id);
	}, []);

	const handleDragCancel = useCallback(() => {
		setActiveId(null);
	}, []);

	const handleDragEnd = useCallback((event) => {
		const { active, over } = event;

		if (active.id !== over?.id) {
			setItems((items) => {
				const oldIndex = items.findIndex(
					(item) => item.slideItemId === active.id
				);
				const newIndex = items.findIndex(
					(item) => item.slideItemId === over.id
				);
				return arrayMove(items, oldIndex, newIndex);
			});
		}
		setActiveId(null);
	}, []);

	return (
		<div className='w-full'>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragStart={handleDragStart}
				onDragEnd={handleDragEnd}
				onDragCancel={handleDragCancel}
			>
				<SortableContext items={items} strategy={rectSortingStrategy}>
					<ul className='mx-[5%] grid gap-12 md:grid-cols-2'>
						{items.map((item, index) => (
							<SortableItem
								key={item.slideItemId}
								id={item.slideItemId}
								item={item}
								index={index}
							/>
						))}
					</ul>
				</SortableContext>
				<DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
					{activeId ? <Item items={items} id={activeId} isDragging /> : null}
				</DragOverlay>
			</DndContext>

			<div className='mt-8 mb-48 flex w-full flex-wrap items-center justify-center gap-8'>
				<ButtonFab
					label={t('Cancelar')}
					size={'xlarge'}
					colour={'white'}
					action={() =>
						dispatch(
							setCurrentViewExpertPortfolioAndSelection({
								...expertPortfolioViewAndSelection,
								isReordering: false,
							})
						)
					}
				/>
				<ButtonFab
					label={t('Guardar')}
					size={'xlarge'}
					action={() => handleSaveSection()}
				/>
			</div>
		</div>
	);
};

PortfolioDraggableArtworks.propTypes = {
	/** Slides array to display */
	slides: PropTypes.array,
	/** Function tha determines the action to run when confirming slides reorder */
	reorderSlidesAction: PropTypes.func,
	/** Function to update the boolean state that determines the display of the draggable section*/
	setShowReorderView: PropTypes.func,
};

export default PortfolioDraggableArtworks;

const SortableItem = ({ item, id, index }) => {
	const {
		isDragging,
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({ id: id });

	const style = {
		transform: CSS?.Transform?.toString(transform),
		transition,
	};

	return (
		<div
			ref={setNodeRef}
			withOpacity={isDragging}
			{...attributes}
			{...listeners}
			style={style}
			id={id}
			itemID={id}
			className='cursor-pointer'
		>
			<EditExpertArtwork isReordering={true} artworkItemData={item} />
		</div>
	);
};

const Item = forwardRef(
	({ id, items, withOpacity, isDragging, style, ...props }, ref) => {
		const inlineStyles = {
			opacity: withOpacity ? '0.5' : '1',
			transformOrigin: '50% 50%',
			borderRadius: '10px',
			cursor: isDragging ? 'grabbing' : 'grab',
			transform: isDragging ? 'scale(1.05)' : 'scale(1)',
			...style,
		};

		return (
			<div
				ref={ref}
				style={inlineStyles}
				{...props}
				className='cursor-pointer '
			>
				<EditExpertArtwork
					isReordering={true}
					artworkItemData={items.find((item) => item.slideItemId === id)}
				/>
			</div>
		);
	}
);
