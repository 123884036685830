/* React */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

/* BL */
import {
	getContact,
	putContact,
	getContacts,
	organizeImageGrid,
	getContactRoles,
} from '../../../BusinessLogic/redux/reducers/contact';
import {
	contactSingleButtons,
	contactValidationSchema,
} from '../../../BusinessLogic/data/ContactSingle2';
import { ENDPOINT_FILES } from '../../../BusinessLogic/helpers/routes';
import { createImage } from '../../../BusinessLogic/redux/reducers/createImage';
import { deleteFile } from '../../../BusinessLogic/redux/reducers/deleteElement';
import { compressAndResizeImage } from '../../../BusinessLogic/helpers/imageSize';

/* UI */
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import ImageAndTitle from '../../../UI/molecules/Image/ImageAndTitle';
import NewTagButtons from '../../../UI/molecules/Buttons/Tag/NewTagButtons';
import TabInfoAndEdit from '../../../UI/organisms/Tabs/TabInfoAndEdit';
import { ReactComponent as Delete } from '../../../UI/assets/icons/Delete.svg';
import ButtonFabExtendeNav from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendeNav';
import ViewSingleSkeleton from '../../../UI/templates/SkeletonTemplates/ViewSingleSkeleton';
import TabImageAndEdit from '../../../UI/organisms/Tabs/TabImageAndEdit';

/**
 * @description new contact single section. It has an Image carousel and tabs.
 *
 */

const ContactSingle = () => {
	const { id } = useParams();
	const dispatch = useDispatch();

	const {
		singleContact,
		contactSingleTabs,
		contacts,
		status,
		loadedContactRoles,
	} = useSelector((state) => state.contacts);
	const { elementInfo } = useSelector((state) => state.deleteElement);

	const [indexInfo, setIndexInfo] = useState(0);

	const { t } = useTranslation();

	useEffect(() => {
		if (!singleContact || `${singleContact.id}` !== id)
			dispatch(getContact(id));
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!loadedContactRoles) {
			dispatch(getContactRoles());
		}
	}, [dispatch, loadedContactRoles]);

	function onSubmitAction(newValues) {
		let { values, id } = newValues;
		values = { ...values, role: values.roles_ids };
		dispatch(putContact({ values, id })).then(() => {
			dispatch(getContact(id));
			dispatch(getContacts());
		});
	}

	const createImagesFunction = async (imagesArray) => {
		for await (const image of imagesArray) {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			let values = {
				small_file,
				medium_file,
				large_file,
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
				type: 'PERSON',
				type_id: id,
				organization_user_id: singleContact.organization_user_id,
				file_type: 'IMAGE',
			};
			await dispatch(createImage(values));
		}
		dispatch(getContact(id));
	};

	const deleteImagesFunction = (imageId) => {
		let values = {
			element_id: singleContact.id,
			element: 'PERSON',
			url: `${ENDPOINT_FILES.DELETE_FILE}${imageId}/`,
		};
		dispatch(deleteFile(values)).then(() => {
			dispatch(getContact(id));
		});
	};

	const submitOrganizeImageGrid = (sortableImages) => {
		let values = {
			images: sortableImages,
			type: 'PERSON',
			type_id: id,
		};
		dispatch(organizeImageGrid(values)).then(() => {
			dispatch(getContact(id));
			dispatch(getContacts());
		});
	};
	const renderSwitch = () => {
		switch (contactSingleTabs[indexInfo].type) {
			case 'Information':
				return (
					<TabInfoAndEdit
						information={contactSingleTabs[indexInfo]}
						onSubmitAction={onSubmitAction}
						indexInfo={indexInfo}
						id={parseInt(id)}
						validationSchema={
							contactValidationSchema[indexInfo]
								? contactValidationSchema[indexInfo]
								: []
						}
						colorHr='purple'
					/>
				);
			case 'Images':
				return (
					<TabImageAndEdit
						information={contactSingleTabs[indexInfo]}
						indexInfo={indexInfo}
						color='purple'
						createImagesFunction={createImagesFunction}
						deleteImagesFunction={deleteImagesFunction}
						submitOrganizeImageGrid={submitOrganizeImageGrid}
						AddIconLink={`/${contactSingleTabs[
							indexInfo
						].tab.toLowerCase()}/new`}
					/>
				);

			default:
				return <h1>No hay contactos disponibles</h1>;
		}
	};

	return (
		<div>
			{!singleContact && status === 'loading' && (
				<ViewSingleSkeleton
					numberOfTitleAndFields={1}
					numberOfTabs={1}
					numberOfSingleRow={5}
					designCaption={'hidden'}
					designOfBottomTitleAndField={'hidden'}
				/>
			)}
			{singleContact && (
				<TemplateWithNavBar section='Contactos'>
					<NavbarSecondaryNew
						title={singleContact.name}
						color='bg-purple-50'
						textColor='text-purple-50'
						redirect='/contactos'
						textPopup={`${t('EliminarContacto')}`}
						popupIcon={<Delete />}
						modalTitle={`${t('EliminarContacto')}`}
						modalTextOne={`${t('SeguroQueDeseasEliminarEsteContacto')}`}
						modalTextTwo={elementInfo}
						id={parseInt(id)}
						section='contactos'
						textBack={`${t('RegresarContactos')}`}
						textBackDesign='pl-3 text-purple-50'
					/>
					<ButtonFabExtendeNav
						id={parseInt(id)}
						listItems={contacts.length ? contacts : []}
						section='contactos'
					/>
					<div className='flex w-full flex-col gap-8 md:flex-row'>
						<div className='flex w-full justify-center p-6 md:w-1/3 '>
							<ImageAndTitle
								imagen={singleContact.main_picture_url}
								objectFitProp={'scale-down'}
							/>
						</div>

						<div className='flex w-full flex-col md:w-2/3'>
							<NewTagButtons
								buttonList={contactSingleButtons}
								indexInfo={indexInfo}
								setIndexInfo={setIndexInfo}
							/>

							<div className='min-h-screen bg-light_grey-50 py-4'>
								{renderSwitch()}
							</div>
						</div>
					</div>
				</TemplateWithNavBar>
			)}
		</div>
	);
};

export default ContactSingle;

ContactSingle.propTypes = {};
