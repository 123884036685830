/* -- Third Party -- */
import { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

//BL
import {
	clearFilesInAlbum,
	setTitleInAlbumFiles,
} from '../../../BusinessLogic/redux/reducers/album';
import { useTranslation } from 'react-i18next';
import { backSectionURL } from '../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../BusinessLogic/helpers/textGoBackGenerator';
import {
	cleanNewCollection,
	getCollections,
	postCollection,
	updateNewCollection,
} from '../../../BusinessLogic/redux/reducers/collection';
import {
	collectionArtistFormValidationSchema,
	collectionGalleryFormValidationSchema,
} from '../../../BusinessLogic/data/CollectionSingle';
import { setCollectionOriginPath } from '../../../BusinessLogic/redux/reducers/routesHistory';
import { getArtistDropdown } from '../../../BusinessLogic/redux/reducers/artist';

//UI
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import ImageSectionForm from '../../../UI/organisms/Image/ImageSectionForm';
import SectionForm from '../../../UI/organisms/Section/SectionForm';
import FieldTextFormik from '../../../UI/molecules/Field/FieldTextFormik';
import FieldSelectFormik from '../../../UI/molecules/Field/FieldSelectFormik';
import FieldTextAreaFormik from '../../../UI/molecules/Field/FieldTextAreaFormik';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import whiteCheck from '../../../UI/assets/icons/whiteCheck.svg';
import LoadingText from '../../../UI/molecules/Loading/LoadingText';
import TextContentPrincipal from '../../../UI/atoms/Text/Content/TextContentPrincipal';
import ModalSelectImage from '../../../UI/organisms/PopUp/ModalSelectImage';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import DropZoneModal from '../../../UI/molecules/DropZone/DropZoneModal';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

/**
 * @description manages the Collection form to create one.
 * @returns
 */

export default function CollectionForm() {
	const { organization_user_id } = localStorage;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	const defaultImage =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';

	const { collectionSingle, newCollection } = useSelector(
		(state) => state.collections
	);
	const { collectionOriginPath } = useSelector((state) => state.routesHistory);
	const { selectedArtworks, selectedDocuments } = useSelector(
		(state) => state.album
	);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);
	const { appUser } = useSelector((state) => state.appUser);
	const { artistDropdown } = useSelector((state) => state.artists);

	const [showSaving, setShowSaving] = useState(false);
	const [showSelectImageModal, setShowSelectImageModal] = useState(false);
	const [showDropzoneModal, setShowDropzoneModal] = useState(false);
	const [dropZoneFiles, setDropZoneFiles] = useState([]);

	const isSubCollection = id ? true : false;
	const isGalleryUser = appUser.role_company === 'GALLERY';
	const isArtistUser = appUser.role_company === 'ARTIST';
	const isCatalogueArtistUser = appUser.role_company === 'ARTIST_CATALOGATION';

	const checkRedirect = () => {
		let redirect = collectionSingle?.collection_type
			? `/colecciones/${collectionSingle.collection_type}`
			: '/colecciones';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		dispatch(cleanNewCollection());
		dispatch(clearFilesInAlbum());
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage =
			navigationHistoryURL[navigationHistoryURL.length - 1] === '/'
				? 'RegresarHome'
				: 'RegresarColecciones';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	const handleSelectItems = (items, newCollectionValues) => {
		dispatch(updateNewCollection(newCollectionValues));
		dispatch(setTitleInAlbumFiles(items));
	};

	const handleUploadImage = (newCollectionValues) => {
		dispatch(
			updateNewCollection({
				...newCollectionValues,
				picture_name: null,
				picture_url: null,
				picture_id: null,
			})
		);
	};

	const handleSubmit = (values) => {
		setShowSaving(true);
		dispatch(postCollection(values))
			.then((res) => {
				dispatch(cleanNewCollection());
				dispatch(clearFilesInAlbum());
				dispatch(getCollections());
				if (collectionOriginPath) {
					navigate(collectionOriginPath);
					dispatch(setCollectionOriginPath(null));
				} else {
					navigate(`/colecciones/${res.payload.id}`);
				}
			})
			.finally(() => setShowSaving(false));
	};

	const handleUserTypeValidationForm = () => {
		if (isArtistUser || isCatalogueArtistUser) {
			return collectionArtistFormValidationSchema;
		} else if (isGalleryUser) {
			return collectionGalleryFormValidationSchema;
		}
	};

	const handleRedirectArtworks = (formProps) => {
		if (isGalleryUser) {
			if (!formProps.values.artist_id) {
				return '';
			} else {
				return '/colecciones/new/archivos';
			}
		} else if (isArtistUser || isCatalogueArtistUser) {
			return '/colecciones/new/archivos';
		}
	};

	const handleOnClickArtworks = (formProps) => {
		if (isGalleryUser) {
			if (!formProps.values.artist_id) {
				formProps.setFieldError('artist_id', 'DebeSeleccionarUnaOpción');
			} else {
				handleSelectItems('Obras', formProps.values);
			}
		} else if (isArtistUser || isCatalogueArtistUser) {
			handleSelectItems('Obras', formProps.values);
		}
	};

	useEffect(() => {
		dispatch(getArtistDropdown());
	}, [dispatch]);

	return (
		<TemplateWithNavBar>
			<div className={showSaving && 'opacity-60'}>
				<SecondaryNavbarSpecific
					title={isSubCollection ? t('SubColección') : t('NuevaColección')}
					textColor='text-green-50'
					color='bg-green-50'
					hide='md:invisible'
					redirect={checkRedirect()}
					textBack={CheckTextBack()}
					textBackDesign='pl-3 text-green-50'
					arrowLeftColor='green'
					backAction={() => returnBackAction()}
				/>
				<Formik
					enableReinitialize
					initialValues={{
						...newCollection,

						organization_user_id: Number(organization_user_id),
						collection_type: isSubCollection ? 'SUB_COLLECTION' : 'COLLECTION',
						artwork: selectedArtworks ? selectedArtworks : [],
						document: selectedDocuments ? selectedDocuments : [],
						parent_id: isSubCollection ? id : '',
						new_collection: true,
					}}
					validationSchema={handleUserTypeValidationForm}
					onSubmit={handleSubmit}
				>
					{(formProps) => (
						<Form className='mb-20'>
							<List>
								<div className='mb-8 flex w-full flex-col gap-4 md:flex-row lg:gap-12'>
									<div className='mx-auto flex flex-col items-center md:w-1/2'>
										<div
											className='w-full'
											onClick={(e) => {
												e.stopPropagation();
												setShowSelectImageModal(true);
											}}
										>
											{dropZoneFiles?.length > 0 ||
											formProps.values.url_file?.length > 0 ? (
												<ImageSectionForm
													formProps={formProps}
													containerDesign={'md:w-full'}
													dropzoneLabel='SoltarOClickImagenPortadaColeccion'
													mobileDropzoneLabel='ClickCargarImagenPortadaColeccion'
													initialDropZoneFiles={dropZoneFiles}
													isDropzoneClickable={false}
												/>
											) : (
												<LazyLoadImage
													alt={formProps.values.picture_name ?? ''}
													src={
														(formProps.values.picture_url &&
															formProps.values.picture_url + '_medium') ??
														defaultImage
													}
													placeholderSrc={
														formProps.values.picture_url + '_small'
													}
													width='100%'
													effect='blur'
													wrapperClassName={`overflow-hidden flex items-center lazyLoadingImages lazy-load-image-background blur lazy-load-image-loaded`}
													className='max-h-full max-w-full '
													delayTime='0'
													threshold='800'
													style={{
														objectFit: 'contain',
														aspectRatio: '1/1',
													}}
												/>
											)}
										</div>
										<ButtonFab
											label={t('SeleccionarImágenPortada')}
											size={'xlarge'}
											design={'my-4'}
											action={() => setShowSelectImageModal(true)}
										/>
									</div>

									<div className='mx-auto flex w-full flex-col md:w-1/2'>
										<SectionForm
											title={t('Identificación')}
											textColor='text-green-50'
											color='bg-green-50'
										>
											<FieldTextFormik
												label={t('Nombre')}
												dbName='name'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.name}
											/>

											<FieldTextFormik
												label={t('Fecha')}
												dbName='dates'
												type='Date'
											/>
										</SectionForm>

										{isGalleryUser && (
											<SectionForm
												title={t('Autor')}
												textColor='text-green-50'
												color='bg-green-50'
												containerDesign=''
											>
												<FieldSelectFormik
													label={t('Artista')}
													dbName='artist_id'
													options={artistDropdown?.allArtist}
													errors={
														formProps.errors && formProps.errors.artist_id
													}
													designErrors='text-right'
												/>
											</SectionForm>
										)}

										<SectionForm
											title={t('Obras')}
											textColor='text-green-50'
											color='bg-green-50'
										>
											{selectedArtworks.length > 0 && (
												<TextContentPrincipal
													text={
														selectedArtworks.length === 1
															? `1 ${t('ObraSeleccionada')}`
															: `${selectedArtworks.length} ${t(
																	'ObrasSeleccionadas'
															  )}`
													}
												/>
											)}
											<Link
												to={handleRedirectArtworks(formProps)}
												className='flex justify-center'
												data-test='artwork-selection-button'
											>
												<ButtonFabExtendedPrimary
													text={t('Seleccionar')}
													action={() => handleOnClickArtworks(formProps)}
												/>
											</Link>
										</SectionForm>

										<SectionForm
											title={t('Documentos')}
											textColor='text-green-50'
											color='bg-green-50'
										>
											{selectedDocuments.length > 0 && (
												<TextContentPrincipal
													text={
														selectedDocuments.length === 1
															? `1 ${t('DocumentoSeleccionado')}`
															: `${selectedDocuments.length} ${t(
																	'DocumentosSeleccionados'
															  )}`
													}
												/>
											)}
											<Link
												to='/colecciones/new/archivos'
												className='flex justify-center'
											>
												<ButtonFabExtendedPrimary
													text={t('Seleccionar')}
													action={() => {
														handleSelectItems('Documentos', formProps.values);
													}}
												/>
											</Link>
										</SectionForm>

										<SectionForm
											title={t('Descripción')}
											textColor='text-green-50'
											color='bg-green-50'
										>
											<FieldTextAreaFormik
												dbName='description'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.description}
											/>
										</SectionForm>

										{showSaving ? (
											<LoadingText text={t('Guardando')} />
										) : (
											<ButtonFabExtendedPrimary
												className='self-center text-white'
												icon={whiteCheck}
												text={t('Guardar')}
											/>
										)}
									</div>
								</div>
							</List>
							{showDropzoneModal && (
								<DropZoneModal
									showDropZoneModal={showDropzoneModal}
									setDropZoneFiles={setDropZoneFiles}
									dropZoneFiles={dropZoneFiles}
									collectAndCreateImage={() =>
										handleUploadImage(formProps.values)
									}
									maxFiles={1}
									setShowDropZoneModal={setShowDropzoneModal}
								/>
							)}
							{showSelectImageModal && (
								<ModalSelectImage
									setShowModal={setShowSelectImageModal}
									selectImageAction={() => {
										navigate('/colecciones/new/portada');
										dispatch(updateNewCollection(formProps.values));
									}}
									uploadImageAction={() => {
										setShowSelectImageModal(false);
										setShowDropzoneModal(true);
									}}
								/>
							)}
						</Form>
					)}
				</Formik>
			</div>
		</TemplateWithNavBar>
	);
}
