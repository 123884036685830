// 3rd Party
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

//BL
import {
	PrivateRoute,
	PublicAccessRoute,
} from '../../Pages/authentication/components/LoginUtils';

// UI
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';
import AllDocuments from '../../Pages/sections/document/AllDocuments';
import DocumentsForm from '../../Pages/sections/document/DocumentsForm';
import DocumentSingle from '../../Pages/sections/document/DocumentSingle';

const DocumentRouter = () => {
	const { appUser } = useSelector((state) => state.appUser);

	return (
		<Routes>
			{appUser.role_company !== 'GALLERY' && (
				<>
					<Route
						path='/'
						element={
							<PrivateRoute>
								<AllDocuments />
							</PrivateRoute>
						}
					/>
					<Route
						path='/:id/edit'
						element={
							<PrivateRoute test={'sss'}>
								<DocumentsForm action='edit' />
							</PrivateRoute>
						}
					/>
					<Route
						path='/new'
						element={
							<PrivateRoute>
								<DocumentsForm action='new' />
							</PrivateRoute>
						}
					/>
					<Route
						path='/:id'
						element={
							<PrivateRoute>
								<DocumentSingle />
							</PrivateRoute>
						}
					/>

					<Route
						path='/public/:id'
						element={
							<PublicAccessRoute>
								<DocumentSingle isPublic={true} />
							</PublicAccessRoute>
						}
					/>
				</>
			)}
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default DocumentRouter;
