import Resizer from 'react-image-file-resizer';
import * as imageConversion from 'image-conversion';

export const comppressImage = (file) => {
	return imageConversion.compressAccurately(file, 200);
};

export const resizeFileSmall = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			500,
			500,
			'JPEG',
			60,
			0,
			(uri) => {
				resolve(uri);
			},
			'blob'
		);
	});

export const resizeFileMedium = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			2000,
			2000,
			'JPEG',
			74,
			0,
			(uri) => {
				resolve(uri);
			},
			'blob'
		);
	});

export const resizeFileLarge = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			'JPEG',
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			'blob'
		);
	});

export function final_image(image_src) {
	/* handles null images */
	if (typeof image_src === 'undefined') {
		return 'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';
	} else {
		return image_src;
	}
}

export async function compressAndResizeImage(image) {
	let imageObject = {};
	try {
		imageObject.small_file = await resizeFileSmall(image);
		imageObject.medium_file = await resizeFileMedium(image);
		imageObject.large_file = image;
	} catch (error) {
		console.error(error);
	}
	return imageObject;
}
