//3rd parties
import { ListItem } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//BL

//UI
import InputCustomName from '../../atoms/Inputs/InputCustomName';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

/**
 * @description basic field used for all the formic calculations
 * @param {*} param0
 * @returns
 */

export default function FieldTextFormik({
	label,
	dbName,
	type,
	className,
	labelDesign,
	errorMessageDesign,
	textInlineStyle,
	errors,
	designInput,
	designContainerInput,
}) {
	const { t } = useTranslation();

	return (
		<div className={`${className}`}>
			<ListItem
				style={{
					paddingLeft: '0px',
					paddingRight: '0px',
					justifyContent: 'space-between',
				}}
			>
				<div className={labelDesign}>
					<TextContentSecond text={label} style={textInlineStyle} />
				</div>
				<div className={`flex flex-col ${designContainerInput}`}>
					<Field
						name={dbName}
						designInput={designInput}
						type={type}
						as={InputCustomName}
					/>
					{errors && (
						<div className={`w-40 md:w-56 ${errorMessageDesign}`}>
							{t(errors)}
						</div>
					)}
				</div>
			</ListItem>
		</div>
	);
}

FieldTextFormik.propTypes = {
	/** Text displayed on the string */
	label: PropTypes.string,
	/** Tailwind design of the label */
	labelDesign: PropTypes.string,
	/** This fields connects the data. Please use the same name that on the database for simplicity */
	dbname: PropTypes.string,
	/** Tailwind design of the main container */
	className: PropTypes.string,
	/** Tailwind design of the ErrorMessage*/
	errorMessageDesign: PropTypes.string,
	/** this error string is generated from te yup validation schema in case of a missing requerid field*/
	errors: PropTypes.string,
	/** styles for component input */
	designInput: PropTypes.string,
	/** styles for input container */
	designContainerInput: PropTypes.string,
};

FieldTextFormik.defaultProps = {
	type: 'text',
	className: '',
	labelDesign: 'w-max',
	textInlineStyle: { fontWeight: 400 },
};
