//3rd
import React, { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import FieldTextFormik from '../../molecules/Field/FieldTextFormik';
import Cross from '../../../UI/assets/icons/Cross.svg';
import Check from '../../../UI/assets/icons/whiteCheck.svg';
import RadioSelectFormik from '../../molecules/Field/RadioSelectFormik';
import ButtonSwitch from '../../atoms/Buttons/ButtonSwitch';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import TemplateWithNavBar from '../../templates/TemplateWithNavBar';
import NavbarSecondaryNew from '../Navbar/NavbarSecondaryNew';
import { ReactComponent as DeleteCanGrey } from '../../assets/icons/DeleteCanGrey.svg';
import ViewModal from '../../../UI/templates/ViewModal';

//BL
import { updateEditingCall } from '../../../BusinessLogic/redux/reducers/call';

import {
	useDeleteFormElementFromCall,
	useGetCallForm,
	useGetSingleCall,
	usePostCreateCallForm,
	usePutCall,
} from '../../../BusinessLogic/hooks/query/useQueryCalls';

const CallFieldForm = ({ setShowFieldForm, initialValues, edit }) => {
	const { t } = useTranslation();
	const { slug } = useParams();
	const dispatch = useDispatch();

	const { editingCall } = useSelector((state) => state.call);

	const { singleCall } = useGetSingleCall(slug);
	const { singleCallForm } = useGetCallForm(singleCall.form_id);

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const putCallMutation = usePutCall();
	const postCreateCallFormMutation = usePostCreateCallForm();
	const deleteFormElementFromCallMutation = useDeleteFormElementFromCall();

	const handleSwitchMandatoryButton = (formProps) => {
		formProps.setFieldValue('is_mandatory', !formProps.values.is_mandatory);
	};

	const handleDeleteFormElement = (id) => {
		deleteFormElementFromCallMutation.mutateAsync(id).then(() => {
			setShowFieldForm(false);
		});
	};

	const handleSubmit = async (values) => {
		let updatedCall = editingCall;

		if (edit) {
			const callFormFiltered = (singleCallForm?.forms?.form_elements ?? []).map(
				(field) => (field !== initialValues ? field : values)
			);

			updatedCall = {
				...editingCall,
				form_id: singleCall.form_id,
				form: callFormFiltered,
			};
		} else {
			updatedCall = {
				...editingCall,
				form_id: singleCall.form_id,
				form: [...(singleCallForm?.forms?.form_elements ?? []), values],
			};
		}

		if (!singleCall.form_id) {
			const res = await postCreateCallFormMutation.mutateAsync();
			if (res?.id) {
				updatedCall = {
					...updatedCall,
					form_id: res.id,
				};
			}
			await putCallMutation
				.mutateAsync(updatedCall)
				.then(() => {
					updatedCall?.form_id && dispatch(updateEditingCall(updatedCall));
					setShowFieldForm(false);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			await putCallMutation
				.mutateAsync(updatedCall)
				.then(() => {
					updatedCall?.form_id && dispatch(updateEditingCall(updatedCall));
					setShowFieldForm(false);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	return (
		<TemplateWithNavBar>
			{showDeleteModal && (
				<ViewModal
					setConfirmDeleteElement={() =>
						handleDeleteFormElement(initialValues.id)
					}
					closeMenu={setShowDeleteModal}
					modalTitle={'Eliminar Campo'}
					modalTextOne={'¿Seguro que deseas eliminar este campo?'}
				/>
			)}
			<NavbarSecondaryNew
				title={edit ? 'Editar campo' : 'Agregar campo'}
				textColor='text-purple-50 w-max'
				color='bg-purple-50'
				showDots={false}
				backAction={() => setShowFieldForm(false)}
				isNotAutomaticRedirect={true}
				textBack={`${t('RegresarFormulario')}`}
				textBackDesign='pl-3 text-purple-50'
				arrowLeftColor='purple'
				containerDesign='mb-4'
				optionalIcon={edit && <DeleteCanGrey data-test='delete-icon' />}
				handleClickOptionalIcon={() => setShowDeleteModal(true)}
			/>
			<Formik
				initialValues={initialValues}
				onSubmit={(values) => handleSubmit(values)}
			>
				{(formProps) => (
					<div className='w-full'>
						<div className='mb-4 grid grid-cols-1 gap-2 md:grid-cols-2'>
							<FieldTextFormik
								label={'Nombre:'}
								dbName='name'
								type='Text'
								designInput={'!bg-middle_grey-50 h-7'}
								designContainerInput={'w-1/2'}
							/>
							<label className='flex justify-start md:justify-end'>
								<div className='flex w-full items-center md:w-1/2 md:justify-start'>
									<TextContentSecond
										text={'Campo obligatorio:'}
										style={{ fontWeight: 400 }}
										design='w-1/2 md:w-auto md:mr-4'
									/>
									<ButtonSwitch
										switched={formProps.values.is_mandatory}
										onSwitchAction={() =>
											handleSwitchMandatoryButton(formProps)
										}
									/>
								</div>
							</label>
						</div>
						<RadioSelectFormik
							label={'Tipo:'}
							dbName='type'
							options={[
								{ label: 'Texto largo', value: 'TEXTO_LARGO' },
								{ label: 'Texto corto', value: 'TEXTO_CORTO' },
								{ label: 'Adjuntar documento', value: 'ADD_DOC' },
							]}
							className={'mb-4 grid grid-cols-2 md:grid-cols-4'}
							labelListDesign={
								'flex flex-col md:grid grid-cols-3 col-start-2 col-end-3 md:col-end-5'
							}
						/>
						<div className='my-12 flex w-full flex-row justify-center gap-3'>
							<ButtonFab
								label={t('Cancelar')}
								colour='white'
								icon={Cross}
								action={() => setShowFieldForm(false)}
							/>
							<ButtonFab
								colour='green'
								label={t('Guardar')}
								icon={Check}
								type='submit'
								action={() => formProps.submitForm()}
							/>
						</div>
					</div>
				)}
			</Formik>
		</TemplateWithNavBar>
	);
};

CallFieldForm.propTypes = {
	setShowFieldForm: PropTypes.bool,
	initialValues: PropTypes.object,
	edit: PropTypes.bool,
};

CallFieldForm.defaultProps = {
	initialValues: {
		name: '',
		type: 'TEXTO_LARGO',
		is_mandatory: 1,
		id: null,
	},
};

export default CallFieldForm;
