import { useDispatch } from 'react-redux';
import { setFilterArrayByClick } from '../../../../BusinessLogic/redux/reducers/filter';

export function ClickableChipClick({ chips, filterVariable }) {
	const dispatch = useDispatch();

	return (
		<select
			onChange={(e) => {
				if (e.target.value !== '') {
					dispatch(setFilterArrayByClick({ [filterVariable]: e.target.value }));
				}
			}}
			className=' h-8 w-full cursor-pointer rounded-md bg-middle_grey-50'
		>
			{chips.map((element) => (
				<option className='body-Text3'>{element}</option>
			))}
		</select>
	);
}
