//3rd party
import React, { useEffect, useState } from 'react';

//BL
import { dummyDataPortfolio } from '../../../BusinessLogic/data/PortfolioPublicView';
import ElementArtworkView from '../../../UI/molecules/Element/ElementArtworkView';

//UI

/**
 * @description renders a portfolio created not editable.
 * @param {*} param0
 * @returns a list of slides with a specific content like text and artworks.
 */
function PortfolioV2FinalView({ portfolio = dummyDataPortfolio }) {
	const [isVerticalScreen, setIsVerticalScreen] = useState(false);
	const [slideWidth, setSlideWidth] = useState(window.innerHeight);

	useEffect(() => {
		const changeInViewport = () => {
			if (window.innerWidth <= window.innerHeight) {
				setIsVerticalScreen(true);
				setSlideWidth(window.innerHeight / 2);
			} else {
				setIsVerticalScreen(false);
				setSlideWidth(window.innerHeight);
			}
		};
		window.addEventListener('resize', changeInViewport);
		return () => {
			window.removeEventListener('resize', changeInViewport);
		};
	});

	const renderSwitchPortfolio = (element, index) => {
		switch (element.type) {
			case 'Text':
				const positionTextLeft = `${element.position.left}%`;
				const positionTextTop = `${element.position.top}%`;
				const textFontsize = `${(slideWidth * element.fontSize) / 100}px`;
				const textContainerWidth =
					element.elementText === 'simpleText' ? 'w-[90%]' : 'w-fit';
				return (
					<div
						className={`absolute ${textContainerWidth}`}
						key={index}
						style={{
							left: positionTextLeft,
							top: positionTextTop,
						}}
					>
						<p
							className={`break-words`}
							style={{
								fontSize: textFontsize,
							}}
						>
							{element.content}
						</p>
					</div>
				);
			case 'artwork':
				const positionArtPictureLeft =
					element.picturePosition && `${element.picturePosition.left}%`;
				const positionArtPictureTop =
					element.picturePosition && `${element.picturePosition.top}%`;
				const positionArtTextLeft =
					element.textPosition && `${element.textPosition.left}%`;
				const positionArtTextTop =
					element.textPosition && `${element.textPosition.top}%`;
				return (
					<ElementArtworkView
						index={index}
						elementType={element.elementType}
						picture={element.picture && element.picture[0].url}
						name={element.text && element.text[0].name}
						measure={element.text && element.text[0].measure}
						technique={element.text && element.text[0].technique}
						width={element.picture && element.picture[0].width}
						year={element.text && element.text[0].year}
						textSize={`${(slideWidth * 3) / 100}px`}
						positionArtPictureLeft={positionArtPictureLeft}
						positionArtPictureTop={positionArtPictureTop}
						positionArtTextLeft={positionArtTextLeft}
						positionArtTextTop={positionArtTextTop}
					/>
				);
			default:
				break;
		}
	};

	return (
		<div className='flex w-full flex-col overflow-x-hidden'>
			<div className='mx-auto flex w-fit flex-col justify-items-center'>
				{portfolio.slides.map((slide, index) => (
					<div
						className={`relative overflow-hidden
							${
								isVerticalScreen
									? 'h-[50vh] max-h-[95vw] w-[50vh] max-w-[95vw]'
									: 'h-[100vh] w-[100vh]'
							}
						`}
						key={index}
					>
						{slide.content.map((element, i) =>
							renderSwitchPortfolio(element, i)
						)}
					</div>
				))}
			</div>
		</div>
	);
}

export default PortfolioV2FinalView;
