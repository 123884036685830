import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

//BL
import { fetchGetData } from '../../../BusinessLogic/hooks/fetchGetData';
import { fetchGetDataWithValues } from '../../../BusinessLogic/hooks/fetchGetDataWithValues';
import { getToken } from '../servicesAuthentication';

//handle private routes

const PrivateRoute = ({ children }) => {
	const { appUser } = useSelector((state) => state.appUser);

	return appUser.token ? children : <Navigate to='/iniciar-sesion' />;
};

const PublicRoute = ({ children }) => {
	const { appUser } = useSelector((state) => state.appUser);

	return appUser.token ? <Navigate to='/' /> : children;
};

const PublicAccessRoute = ({ children, publicUrl }) => {
	const navigate = useNavigate();

	let urlArray = publicUrl.split('/');
	let id = urlArray[urlArray.length - 1];
	let isPublic = urlArray[urlArray.length - 2];

	if (isPublic === 'public') {
		const { organization_user_id } = localStorage;
		fetchGetData('/api/public/app_user/permissions/' + id + '/').then(
			({ access, organization }) => {
				if (
					access === 'PRIVATE' &&
					Number(organization_user_id) === Number(organization)
				) {
				} else if (access === 'PASSWORD') {
					const { access } = localStorage;
					if (!access) {
						let password = prompt('escribe la contraseña:');
						let data = {
							password,
						};
						fetchGetDataWithValues(
							'/api/public/app_user/password/' + id + '/',
							data
						).then((res) => {
							if (res.error) {
								alert('you dont have credentials');
								navigate('/iniciar-sesion');
							} else {
								localStorage.setItem('access', true);
							}
						});
					}
				} else if (access === 'PUBLIC') {
				} else {
					alert('Esta colección es privada');
					navigate('/iniciar-sesion');
				}
			}
		);
	}

	return !getToken() ? children : <Navigate to='/' />;
};

export { PrivateRoute, PublicRoute, PublicAccessRoute };
