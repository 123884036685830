// 3rd party
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

//BL
import { transformInfoIntoInitialValues } from '../../../BusinessLogic/helpers/FormHelper';
import {
	getCities,
	getCountries,
} from '../../../BusinessLogic/redux/reducers/staticData';

// UI
import SectionTitleAndFields from '../Section/SectionTitleAndFields';
import SectionEditCityCountry from '../Section/SectionEditCityCountry';
import { useEffect } from 'react';

/**
 * @description renders a View City Country. And it's dynamic.
 * @param {*} param0
 * @returns
 */

const ViewCityCountry = ({
	information,
	sectionTitle,
	colorHr,
	onSubmitAction,
	id,
	validationSchema,
}) => {
	const dispatch = useDispatch();

	const [turnEdit, setTurnEdit] = useState(false);
	const { cities, countries } = useSelector((state) => state.staticData);

	useEffect(() => {
		if (!countries) {
			dispatch(getCountries());
		}
		if (!cities) {
			const countryId = information?.find(
				(element) => element.field === 'country_id'
			)?.value;
			if (countryId) {
				dispatch(getCities(countryId));
			}
		}
		//eslint-disable-next-line
	}, []);

	return (
		<div className='flex flex-col bg-light_grey-50'>
			{turnEdit ? (
				<SectionEditCityCountry
					colorHr={colorHr}
					sectionTitle={sectionTitle}
					informationArray={information}
					initialValues={transformInfoIntoInitialValues(information)}
					setTurnEdit={setTurnEdit}
					turnEdit={turnEdit}
					onSubmitAction={onSubmitAction}
					validationSchema={validationSchema}
					id={id}
				/>
			) : (
				<SectionTitleAndFields
					colorHr={colorHr}
					sectionTitle={sectionTitle}
					information={information}
					setTurnEdit={setTurnEdit}
					turnEdit={turnEdit}
				/>
			)}
		</div>
	);
};

export default ViewCityCountry;

ViewCityCountry.propTypes = {
	/* string with the text of the section*/
	information: PropTypes.string,
	/* number that representes the tab that is displayed */
	indexInfo: PropTypes.number,
	/* title of the section with information */
	sectionTitle: PropTypes.string,
	/* string with the color of the hr */
	colorHr: PropTypes.string,
	/* ValidationSchema used to manage error messages */
	validationSchema: PropTypes.object,
};
