// 3rd Party
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

// BL
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';

// UL
import PortfolioAll from '../../Pages/sections/portfolio/PortfolioAll';
import PortfolioSingle from '../../Pages/sections/portfolio/PortfolioSingle';
import PortfolioNew from '../../Pages/sections/portfolio/PortfolioNew';
import EditablePortfolio from '../../Pages/sections/portfolio/EditablePortfolio';
import PlantillaSelectionPortfolio from '../../Pages/sections/plantilla/PlantillaSelectionPortfolio';
import ExpertPortfolioEdition from '../../Pages/sections/portfolio/Expert/ExpertPortfolioEdition';
import ExpertPortfolioConfiguration from '../../Pages/sections/portfolio/Expert/ExpertPortfolioConfiguration';
import ExpertPortfolioConfigurationArtwork from '../../Pages/sections/portfolio/Expert/ExpertPortfolioConfigurationArtwork';
import ExpertPortfolioSlides from '../../Pages/sections/portfolio/Expert/ExpertPortfolioSlides';
import ExpertPortfolioPreview from '../../Pages/sections/portfolio/Expert/ExpertPortfolioPreview';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';
import ExpertPortfolioAddSlides from '../../Pages/sections/portfolio/Expert/ExpertPortfolioAddSlides';

/**
 * @description This router manages the routes for the public view of the porfolio.
 * It allows to create, view and edit
 * @returns
 */

export const PortfolioRouter = () => {
	const { appUser } = useSelector((state) => state.appUser);

	return (
		<Routes>
			{appUser.role_company !== 'ORGANIZATION' && (
				<>
					<Route
						path='/'
						element={
							<PrivateRoute>
								<PortfolioAll />
							</PrivateRoute>
						}
					/>
					<Route
						path='/new'
						element={
							<PrivateRoute>
								<PortfolioNew action='new' />
							</PrivateRoute>
						}
					/>
					<Route
						path='/editar'
						element={
							<PrivateRoute>
								<EditablePortfolio />
							</PrivateRoute>
						}
					/>
					<Route
						path='/plantillas'
						element={
							<PrivateRoute>
								<PlantillaSelectionPortfolio />
							</PrivateRoute>
						}
					/>
					<Route
						path='/portafolio-experto/:portfolioId/edicion'
						element={
							<PrivateRoute>
								<ExpertPortfolioEdition />
							</PrivateRoute>
						}
					/>
					<Route
						path='/portafolio-experto/:portfolioId/configuracion'
						element={
							<PrivateRoute>
								<ExpertPortfolioConfiguration />
							</PrivateRoute>
						}
					/>
					<Route
						path='/portafolio-experto/:portfolioId/configuracion/obra'
						element={
							<PrivateRoute>
								<ExpertPortfolioConfigurationArtwork />
							</PrivateRoute>
						}
					/>
					<Route
						path='/portafolio-experto/:portfolioId/secciones'
						element={
							<PrivateRoute>
								<ExpertPortfolioSlides />
							</PrivateRoute>
						}
					/>
					<Route
						path='/portafolio-experto/:portfolioId/secciones/agregar'
						element={
							<PrivateRoute>
								<ExpertPortfolioAddSlides />
							</PrivateRoute>
						}
					/>
					<Route
						path='/portafolio-experto/:portfolioId/previsualizacion'
						element={
							<PrivateRoute>
								<ExpertPortfolioPreview />
							</PrivateRoute>
						}
					/>
					<Route
						path='/:id'
						element={
							<PrivateRoute>
								<PortfolioSingle />
							</PrivateRoute>
						}
					/>
				</>
			)}
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default PortfolioRouter;
