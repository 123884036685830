//3rd party
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

//BL

//UI
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import { ReactComponent as Warning } from '../../../UI/assets/icons/Warning.svg';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import { useEffect } from 'react';

/**
 * @description new postulation elements
 * @returns
 */
function WarningModal({
	mainButtonAction,
	mainButtonTitle,
	secondaryButtonAction,
	secondaryButtonTitle,
	closeModalFunction,
	redirect,
	title,
	textPrimary,
	textSecondary,
}) {
	const navigate = useNavigate();

	useEffect(() => {
		document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	}, []);

	return (
		<div className='fixed inset-0 z-50 flex items-center justify-center bg-black-50/50'>
			<div className='flex w-[80%] flex-col gap-4 rounded-lg border-0 bg-white p-4 shadow-lg md:w-1/2 lg:w-1/4 '>
				<div className='flex items-center justify-center'>
					<Warning className='mr-2.5' />
					<TextTitleSub title={title} />
				</div>
				<TextContentSecond
					text={textPrimary}
					design='text-center leading-relaxed'
					style={{ fontSize: '18px', lineHeight: 1.2 }}
					truncate={false}
				/>
				<TextContentSecond
					text={textSecondary}
					design='text-center px-2'
					truncate={false}
				/>

				<div className='flex w-full items-center justify-center gap-8'>
					<ButtonFab
						label={secondaryButtonTitle}
						action={() => {
							if (secondaryButtonAction) {
								secondaryButtonAction();
							} else {
								closeModalFunction(false);
							}
						}}
						colour='white'
					/>

					<ButtonFab
						label={mainButtonTitle}
						action={() => {
							if (mainButtonAction) mainButtonAction();
							if (redirect) navigate(redirect);
						}}
					/>
				</div>
			</div>
		</div>
	);
}

WarningModal.propTypes = {
	/* triggers a function that handle the postulation accepted */
	action: PropTypes.func,
	/* function that close the menu */
	closeMenu: PropTypes.func,
	/* handles the url to redirect */
	url: PropTypes.string,
};

WarningModal.defaultProps = {
	// title: 'Recuerda',
	// closeMenu: '',
	// url: '',
};

export default WarningModal;
