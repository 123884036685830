// 3rd Party
import PropTypes from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';

// UI
import DownloadableExpertSlideItemTitle from '../../../../molecules/Portfolio/ExpertSlideItems/DownloadableExpertSlideItems/DownloadableExpertSlideItemTitle';
import DownloadableExpertSlideItemText from '../../../../molecules/Portfolio/ExpertSlideItems/DownloadableExpertSlideItems/DownloadableExpertSlideItemText';
import DownloadableExpertSlideItemImage from '../../../../molecules/Portfolio/ExpertSlideItems/DownloadableExpertSlideItems/DownloadableExpertSlideItemImage';

const styles = StyleSheet.create({
	title: {
		marginBottom: '24px',
		textTransform: 'uppercase',
	},
	textAndImageWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	textContainer: {
		width: '48%',
	},
	image: {
		width: '48%',
	},
});

export default function DownloadableExpertSlideTextBlockAndImage({
	slideItems,
}) {
	const titleElements = slideItems?.find(
		(element) => element.type === 'title'
	)?.slideItemElements;

	const textContent = slideItems
		?.find((item) => item.type === 'text')
		?.slideItemElements?.find((element) => element.type === 'text');

	const imageURL = slideItems
		?.find((item) => item.type === 'image')
		?.slideItemElements?.find((element) => element.type === 'image_url');

	return (
		<View style={styles.container}>
			<DownloadableExpertSlideItemTitle
				titleElements={titleElements}
				stylesTitle={styles.title}
			/>
			<View style={styles.textAndImageWrapper}>
				<DownloadableExpertSlideItemText
					stylesContainer={styles.textContainer}
					text={textContent?.content}
				/>
				<DownloadableExpertSlideItemImage
					stylesImage={styles.image}
					imageURL={imageURL?.content}
				/>
			</View>
		</View>
	);
}

DownloadableExpertSlideTextBlockAndImage.propTypes = {
	// contains the data to show
	slideItems: PropTypes.array,
};
