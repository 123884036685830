import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { transformInfoIntoInitialValues } from '../../../BusinessLogic/helpers/FormHelper';
import SectionTitleAndFields from '../Section/SectionTitleAndFields';
import SectionEditPassword from '../Section/SectionEditPassword';

/**
 * @description renders a password view of perfil management.
 * @param {*} param0
 * @returns
 */
const ViewPerfilPassword = ({
	indexInfo,
	information,
	onSubmitAction,
	sectionTitle,
	colorHr,
	id,
	validationSchema,
	section,
}) => {
	const [turnEdit, setTurnEdit] = useState(false);

	useEffect(() => {
		setTurnEdit(false);
	}, [indexInfo]);

	const itemsToShowOrNot = ['Confirmar nueva contraseña', 'Nueva contraseña'];
	const simplePasswordInfo = information.filter(
		(item) => !itemsToShowOrNot.includes(item.label)
	);
	const editPasswordInfo = information.filter((item) =>
		itemsToShowOrNot.includes(item.label)
	);

	return (
		<div className='flex flex-col bg-light_grey-50'>
			{turnEdit ? (
				<SectionEditPassword
					colorHr={colorHr}
					sectionTitle={sectionTitle}
					informationArray={editPasswordInfo}
					initialValues={transformInfoIntoInitialValues(information)}
					setTurnEdit={setTurnEdit}
					turnEdit={turnEdit}
					onSubmitAction={onSubmitAction}
					validationSchema={validationSchema}
					id={id}
				/>
			) : (
				<SectionTitleAndFields
					colorHr={colorHr}
					sectionTitle={sectionTitle}
					information={simplePasswordInfo}
					setTurnEdit={setTurnEdit}
					turnEdit={turnEdit}
					section={section}
				/>
			)}
		</div>
	);
};

ViewPerfilPassword.propTypes = {
	/* title of the section with information */
	sectionTitle: PropTypes.string,
	/* objects array with keys and values of each element  */
	information: PropTypes.array,
	/** Color of Header in Subtitle */
	colorHr: PropTypes.string,
	/** Trigger a function when the edit is clicked */
	setTurnEdit: PropTypes.func,
	/** Trigger edit mode in the section */
	turnEdit: PropTypes.bool,
	/* identify in which section single is using */
	section: PropTypes.string,
	/* ValidationSchema used to manage error messages */
	validationSchema: PropTypes.object,
	/** action on submit */
	onSubmitAction: PropTypes.func,
};

export default ViewPerfilPassword;
