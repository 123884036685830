//3rd
import React from 'react';
import { PropTypes } from 'prop-types';

//UI
import ExpertSlideCover from '../../organisms/Portfolio/ExpertSlides/ExpertSlideCover';
import ExpertSlideTextBlock from '../../organisms/Portfolio/ExpertSlides/ExpertSlideTextBlock';
import ExpertSlideTextBlockAndImage from '../../organisms/Portfolio/ExpertSlides/ExpertSlideTextBlockAndImage';
import ExpertSlideArtworkIndividual from '../../organisms/Portfolio/ExpertSlides/ExpertSlideArtworkIndividual';
import ExpertSlideArtworksGroup from '../../organisms/Portfolio/ExpertSlides/ExpertSlideArtworksGroup';
import ExpertSlideBackCover from '../../organisms/Portfolio/ExpertSlides/ExpertSlideBackCover';
import ExpertSlideFullScreenImage from '../../organisms/Portfolio/ExpertSlides/ExpertSlideFullScreenImage';

/**
 * @description Renders conditional IU to display the different slide types of an expert portfolio
 */
const PortfolioSlidePreview = ({ slide, slideDesign, slideLabel }) => {
	return (
		<div className='w-full'>
			<div
				className={`mx-auto aspect-[4/3] w-full overflow-hidden bg-white drop-shadow-lg ${slideDesign}`}
			>
				{slide.type === 'cover' && (
					<ExpertSlideCover
						slideData={slide}
						key={slide.id}
						isEdition={false}
						isScaled={true}
					/>
				)}
				{slide.type === 'textBlock' && (
					<ExpertSlideTextBlock
						slideData={slide}
						key={slide.id}
						isEdition={false}
						isScaled={true}
					/>
				)}
				{slide.type === 'textBlockAndImage' && (
					<ExpertSlideTextBlockAndImage
						slideData={slide}
						key={slide.id}
						isEdition={false}
						isScaled={true}
					/>
				)}
				{slide.type === 'artworkIndividual' && (
					<ExpertSlideArtworkIndividual
						slideData={slide}
						key={slide.id}
						isEdition={false}
						isScaled={true}
					/>
				)}
				{slide.type === 'artworksGroup' && (
					<ExpertSlideArtworksGroup
						slideData={slide}
						key={slide.id}
						isEdition={false}
						isScaled={true}
						designSlideItemArtwork='w-full'
					/>
				)}
				{slide.type === 'fullScreenImage' && (
					<ExpertSlideFullScreenImage
						slideData={slide}
						key={slide.id}
						isEdition={false}
					/>
				)}
				{slide.type === 'backCover' && (
					<ExpertSlideBackCover
						slideData={slide}
						key={slide.id}
						isEdition={false}
						isScaled={true}
					/>
				)}
			</div>
			{slideLabel && (
				<p className='my-2 w-full text-center'>{`${slideLabel}`}</p>
			)}
		</div>
	);
};

PortfolioSlidePreview.propTypes = {
	/** Object with the information of the slide */
	slide: PropTypes.object,
	/** Tailwind class that determines the styles of the slide container*/
	slideDesign: PropTypes.string,
	/** Text to display as the label of the slide */
	slideLabel: PropTypes.string,
};

export default PortfolioSlidePreview;
