// 3rd party
import {
	Page,
	Text,
	View,
	Document,
	Image,
	Svg,
	Path,
	StyleSheet,
} from '@react-pdf/renderer';

//BL
import { getHttpRequestDefaultOptions } from '../../../BusinessLogic/helpers/getHttpRequestOptions';

/**
 * @description Download postulation form of the one user
 * @param
 * @returns
 */

export default function DownloadableForm({ formOptions }) {
	const styles = StyleSheet.create({
		page: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			fontSize: '13px',
			fontFamily: 'Helvetica',
			padding: '25px 0',
		},
		content: {
			padding: '50px',
		},
		header: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-start',
		},
		personalInformation: {
			marginLeft: '0',
			padding: '0px 20px',
		},
		personalInformationBlock: {
			marginBottom: '5px',
		},
		profileImage: {
			objectFit: 'cover',
			height: '100%',
			maxWidth: '100%',
		},
		name: {
			marginBottom: '10px',
			color: '#9CBDB9',
			fontFamily: 'Helvetica-Bold',
		},
		formTitle: {
			margin: '20px 0 10px 0',
			color: '#CBBFD9',
			fontFamily: 'Helvetica-Bold',
		},
		questionTitle: {
			fontFamily: 'Helvetica-Bold',
			marginTop: '25px',
		},
		questionText: {
			marginTop: '10px',
		},
		cv: {
			marginTop: '45px',
		},
		cvTitle: {
			fontFamily: 'Helvetica-Bold',
			color: '#CBBFD9',
			display: 'block',
			marginBottom: '3px',
		},
		cvLabel: {
			display: 'inline',
		},
		cvURL: {
			color: '#34b3e5',
			display: 'inline',
			textDecoration: 'underline',
		},
		footer: {
			backgroundColor: '#9CBDB9',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			color: '#fff',
			padding: '5px 15px',
		},
		footerContentOne: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		footerText: {
			fontSize: '12px',
			marginLeft: '10px',
		},
		logoDatarte: {
			height: '30px',
			width: '30px',
		},
		profileImageContainer: {
			width: '85px',
			height: '85px',
			borderRadius: '9999px',
			aspectRadio: '1/1',
			overflow: 'hidden',
		},
	});

	return (
		<Document>
			<Page size={'A4'} style={styles.page}>
				<View style={styles.content}>
					<View style={styles.header}>
						<View style={styles.profileImageContainer}>
							<Image
								src={getHttpRequestDefaultOptions(
									formOptions.artist_main_image
								)}
								style={styles.profileImage}
							/>
						</View>
						<View style={styles.personalInformation}>
							<Text style={styles.name}>
								{formOptions.organization_user_name}
							</Text>
							{formOptions.artist_email && (
								<Text style={styles.personalInformationBlock}>
									{formOptions.artist_email}
								</Text>
							)}
							{formOptions.artist_phone && (
								<Text style={styles.personalInformationBlock}>
									{formOptions.artist_phone}
								</Text>
							)}
						</View>
					</View>
					<View>
						<Text style={styles.formTitle}>Formulario diligenciado</Text>
						{formOptions.forms?.map((question, i) => (
							<View key={i}>
								<Text style={styles.questionTitle}>
									{question.form_element_title}
								</Text>
								<Text style={styles.questionText}>{question.form_value}</Text>
							</View>
						))}
					</View>
				</View>
				<View style={styles.footer}>
					<View style={styles.footerContentOne}>
						<Svg style={styles.logoDatarte}>
							<Path
								d='M3.91016 26.6266H11.1007C17.8573 26.6266 22.6303 22.1945 22.6303 15.9648C22.6303 9.73508 17.8573 5.33398 11.1007 5.33398H3.91016V26.6266ZM6.32766 24.4261V7.53453H11.1007C16.4316 7.53453 20.1818 11.0368 20.1818 15.9648C20.1818 20.9238 16.4316 24.4261 11.1007 24.4261H6.32766Z'
								fill='white'
							/>
							<Path
								d='M26.4395 26.5962H28.9413L20.0233 5.33398H17.5215L8.60352 26.5962H11.1053L14.0779 19.4778H23.4669L26.4395 26.5962ZM14.0779 19.4778L18.7871 8.30512L23.4669 19.4778H14.0779Z'
								fill='white'
							/>
						</Svg>
						<Text style={styles.footerText}>Creado en Datarte.art</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
}
