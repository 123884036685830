import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGetData } from '../../hooks/fetchGetData';
import { ENDPOINT_CALENDAR } from '../../helpers/routes';
import { sortDatesInAscendingOrder } from '../../helpers/sortData';

/* Get all calendar events*/
export const getAllCalendarEvents = createAsyncThunk(
	'calendar/fetchAllCalendarEvents',
	async () => {
		return await fetchGetData(ENDPOINT_CALENDAR.GET_ALL_CALENDAR_EVENTS);
	}
);

const initialState = {
	calendarEvents: null,
	status: null,
};

export const calendarSlice = createSlice({
	name: 'calendar',
	initialState,
	reducers: {},
	extraReducers: {
		// Get calendar events
		[getAllCalendarEvents.pending]: (state) => {
			state.status = 'loading';
		},
		[getAllCalendarEvents.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.calendarEvents = sortDatesInAscendingOrder(payload.events);
		},
		[getAllCalendarEvents.rejected]: (state) => {
			state.status = 'failed';
		},
	},
});

export default calendarSlice.reducer;
