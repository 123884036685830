//3rd parties
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

//BL

//UI
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import { ReactComponent as Arrow } from '../../assets/icons/CaretLeft.svg';

/**
 * @description it's an item on the NavBar, this handles a variation of render if the item is selected, or have suboptions,
 * also, receives a rotation styles for the arrow icon, saves the index and suboptions in a useState
 * @param {*} param0
 * @returns
 */

export default function ItemSectionNavbarDesktop({
	option,
	section,
	subOptions,
	setSubOptions,
	subItems,
	url,
	setSubMenuItems,
	index,
	setIndexItem,
	indexItem,
	normalState,
	rotateState,
	setLabelItem,
	closeMenu,
}) {
	const selectStyle = {
		fontWeight: 700,
		textDecoration: 'underline',
		textUnderlinePosition: 'under',
		lineHeight: '1.375rem',
	};

	const handleClick = () => {
		setSubOptions(!subOptions);
		setSubMenuItems(subItems);
		setIndexItem(index);
		if (setLabelItem) setLabelItem(option);
	};

	return (
		<div className='flex cursor-pointer items-center rounded py-1 px-2 hover:bg-light_grey-100'>
			{subItems ? (
				<div
					onClick={() => handleClick()}
					className='flex flex-row items-center'
				>
					<TextTitleSub
						title={option}
						design='flex cursor-pointer'
						style={
							subOptions && indexItem === index
								? selectStyle
								: { fontWeight: 300 }
						}
					/>
					<Arrow
						className={`ml-2 h-6 w-6 transform transition duration-150 ease-in-out ${
							subOptions && indexItem === index ? normalState : rotateState
						}`}
					/>
				</div>
			) : (
				<Link to={url}>
					<div onClick={closeMenu}>
						<TextTitleSub
							title={option}
							design='flex cursor-pointer'
							style={option === section ? selectStyle : { fontWeight: 300 }}
						/>
					</div>
				</Link>
			)}
		</div>
	);
}

ItemSectionNavbarDesktop.propTypes = {
	/** Compares the option */
	option: PropTypes.string,
	/** Compares with the string to know if has been selected */
	section: PropTypes.string,
	/** boolean that make a rotation of the arrow icon if is true or false */
	subOptions: PropTypes.bool,
	/** function that handles the rotation change of the arrow icon if is clicked */
	setSubOptions: PropTypes.func,
	/** contents a array of submenu items */
	subItems: PropTypes.array,
	/** store the url of the item */
	url: PropTypes.string,
	/** function used to storage the submenuItems when the user clicks in the arrow */
	setSubMenuItems: PropTypes.func,
	/** function used to storage the unique index of the item when the user clicks in the arrow */
	setIndexItems: PropTypes.func,
	/** unique index of the item used in conditionals */
	index: PropTypes.number,
	/** Compares with the index to know if has been selected */
	indexItem: PropTypes.number,
	/** normal direction of the arrow */
	normalState: PropTypes.string,
	/** change direction of the arrow */
	rotateState: PropTypes.string,
	/** state function that saves the option label */
	setLabelItem: PropTypes.func,
	/* function that change the state to show the menu */
	closeMenu: PropTypes.func,
};
