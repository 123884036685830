import React from 'react';
import PropTypes from 'prop-types';

const SkeletonImage = ({ designMainImage, designCaption }) => {
	return (
		<div>
			<div
				className={`skeleton-animation-background-light my-1 w-full
                       animate-skeleton 
                       bg-light_grey-50 ${designMainImage}`}
				style={{ aspectRatio: '1/1' }}
			></div>
			<div
				className={`skeleton-animation-background-light
                      mt-2 animate-skeleton
                      bg-light_grey-50 ${designCaption}`}
			></div>
		</div>
	);
};

SkeletonImage.defaultProps = {
	designMainImage: '',
	designCaption: 'h-4 rounded-lg',
};

SkeletonImage.propTypes = {
	/** TailWind className for the main image */
	designMainImage: PropTypes.string,
	/** TailWind className for the caption */
	designCaption: PropTypes.string,
};

export default SkeletonImage;
