//3rd party
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

/* UI */
import ImageAndTitle from '../../molecules/Image/ImageAndTitle';
import ImageListContent from '../../molecules/Image/ImageListContent';
import TextWithLink from '../../molecules/Text/TextWithLink';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import GeneralCollectionCover from '../../assets/images/DefaultCollectionCover.png';

//BL
import { artworkImageAlt } from '../../../BusinessLogic/helpers/altImageHelper';

/**
 * @description Shows images when you pass a data array.
 * Can swap the images to squares or lines wiht the ChangeView attribute
 * @returns
 */

export default function ImagesSection({
	data,
	redirect,
	isPublic,
	changeView,
	newEntityLink,
	emptyMessage,
	isDataCollection,
	isArtworksSection,
	design = 'px-4',
}) {
	const { t } = useTranslation();

	const renderImageAndTitleCollection = (collection) => (
		<div className='flex flex-col'>
			{collection.is_general_collection && (
				<TextContentSecond
					text={t('AutomáticamenteGenerado')}
					design={`text-green-50 mb-2 font-semibold h-[28px]`}
				/>
			)}
			<ImageAndTitle
				key={collection.id}
				id={collection.id}
				imagen={
					collection.is_general_collection
						? GeneralCollectionCover
						: collection.main_picture_url
				}
				designContainer={`${!collection.is_general_collection && 'mt-[36px]'}`}
				title={collection.name}
				isImageWithoutFormat={collection.is_general_collection}
				redirect={redirect}
				isPublic={isPublic}
			/>
			{collection.is_general_collection && (
				<TextContentSecond
					text={t('CreamosEstaColecciónGeneral')}
					design='text-grey-50'
				/>
			)}
		</div>
	);

	const renderImageListContentCollection = (collection) => (
		<ImageListContent
			key={collection.id}
			id={collection.id}
			imagen={
				collection.is_general_collection
					? GeneralCollectionCover
					: collection.main_picture_url
			}
			isImageWithoutFormat={collection.is_general_collection}
			title={collection.name}
			redirect={redirect}
			designContainer={`${!collection.is_general_collection && 'mt-[36px]'}`}
		/>
	);

	return (
		<div className='container mx-auto'>
			{Array.isArray(data) && data.length === 0 ? (
				<div className='mx-auto flex w-11/12'>
					{emptyMessage ? (
						<TextContentSecond text={emptyMessage} />
					) : (
						<TextWithLink
							emptyEntityMessage='No hay portafolios. Por favor crea uno.'
							redirect={newEntityLink}
						/>
					)}
				</div>
			) : (
				<div
					className={
						changeView
							? 'flex flex-col justify-center px-8'
							: `grid w-full grid-cols-3 justify-center gap-4  sm:grid-cols-5 md:grid-cols-7 ${design}`
					}
				>
					{data.map((element, index) => (
						<Fragment key={index}>
							{changeView ? (
								<>
									{isDataCollection ? (
										renderImageListContentCollection(element)
									) : (
										<ImageListContent
											key={element.id}
											id={element.id}
											imagen={element.main_picture_url}
											title={element.name}
											redirect={redirect}
										/>
									)}
								</>
							) : (
								<>
									{isDataCollection ? (
										renderImageAndTitleCollection(element)
									) : (
										<ImageAndTitle
											key={element.id}
											id={element.id}
											imagen={element.main_picture_url}
											title={element.name}
											redirect={redirect}
											isPublic={isPublic}
											imageAlt={
												isArtworksSection
													? artworkImageAlt(
															element.name,
															element.artist_name,
															element.description
													  )
													: element.name
											}
										/>
									)}
								</>
							)}
						</Fragment>
					))}
				</div>
			)}
		</div>
	);
}

ImagesSection.propTypes = {
	/** Has the data to show the images */
	data: PropTypes.array,
	/** URL link to redirect to the image */
	redirect: PropTypes.string,
	/** Check if the item is public */
	isPublic: PropTypes.bool,
	/** False see the images in squares. True see the images in lines*/
	changeView: PropTypes.bool,
	/* link to new entity create */
	newEntityLink: PropTypes.string,
	//**Boolean that determines if the section is for artworks*/
	isArtworksSection: PropTypes.bool,
};

ImagesSection.defaultProps = {
	changeView: false,
};
