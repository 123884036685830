import * as Yup from 'yup';

//singleArtist
const contactSingleTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'labels',
				field: 'identification',
				information: [
					{ label: 'Nombres', value: '', field: 'first_name' },
					{ label: 'Apellidos', value: '', field: 'last_name' },
					{
						label: 'Rol',
						value: ['1'],
						field: 'roles_ids',
						type: 'checkbox',
						options: [
							{ id: '1', label: 'Photographer' },
							{ id: '2', label: 'Location Contact' },
							{ id: '3', label: 'Owner' },
						],
					},
					{ label: 'Email', value: '', field: 'email' },
					{ label: 'Teléfono', value: '', field: 'phone_number' },
					{ label: 'OtroNumeroTelefono', value: '', field: 'other' },
				],
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const contactSingleButtons = ['Identificación', 'Imágenes'];

const contactValidationSchema = [
	[
		{
			first_name: Yup.string().required('NombreEsNecesario'),
			last_name: Yup.string().required('ApellidoNecesario'),
			email: Yup.string().email('CorreoValido').required('EmailNecesario'),
			phone_number: Yup.string().min(7, 'TeléfonoValido').nullable(),
			other: Yup.string().min(7, 'TeléfonoValido').nullable(),
		},
	],
];

const contactFormValidationSchema = Yup.object({
	first_name: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('NombreEsNecesario'),
	last_name: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('ApellidoNecesario'),
	email: Yup.string().email('CorreoValido').required('EmailNecesario'),
	phone_number: Yup.string().min(7, 'TeléfonoValido').nullable(),
	other: Yup.string().min(7, 'TeléfonoValido').nullable(),
});

const contactInitialValues = {
	id: '',
	main_picture_id: 1,
	first_name: '',
	last_name: '',
	role: [],
	email: '',
	phone_number: '',
	other: '',
	organization_user_id: 1,
	name_image: '',
	description_image: '',
};

export {
	contactSingleTabs,
	contactSingleButtons,
	contactValidationSchema,
	contactFormValidationSchema,
	contactInitialValues,
};
