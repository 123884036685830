import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCalendarEvents } from '../redux/reducers/calendar';
import { getAllResources } from '../redux/reducers/resources';

export function useFetchHomeData(
	isArtistUser,
	isGalleryUser,
	isCatalogueArtistUser,
	isOrganizationUser
) {
	const dispatch = useDispatch();

	const { calendarEvents } = useSelector((state) => state.calendar);
	const { resources } = useSelector((state) => state.resources);

	const [isCalendarEventsLoaded, setIsCalendarEventsLoaded] = useState(false);
	const [isResourcesLoaded, setIsResourcesLoaded] = useState(false);

	const isLoading = !(isCalendarEventsLoaded && isResourcesLoaded);

	useEffect(() => {
		if (
			(isArtistUser || isCatalogueArtistUser || isGalleryUser) &&
			!calendarEvents
		) {
			setIsCalendarEventsLoaded(false);
			dispatch(getAllCalendarEvents()).finally(() => {
				setIsCalendarEventsLoaded(true);
			});
		} else {
			setIsCalendarEventsLoaded(true);
		}
		//eslint-disable-next-line
	}, [
		isArtistUser,
		isCatalogueArtistUser,
		calendarEvents,
		isCalendarEventsLoaded,
		dispatch,
	]);

	useEffect(() => {
		if (!resources) {
			setIsResourcesLoaded(false);
			dispatch(getAllResources()).finally(() => {
				setIsResourcesLoaded(true);
			});
		} else {
			setIsResourcesLoaded(true);
		}
	}, [resources, setIsResourcesLoaded, dispatch]);

	return isLoading;
}
