//3rd
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

//BL
import CardCall from '../../molecules/Card/CardCall';
import { cleanFormTemporaryCallSlug } from '../../../BusinessLogic/redux/reducers/call';
import { useEffect } from 'react';
/**
 * @description render a section with its title and a grid of calls
 * @param {*} param0
 * @returns
 */
function SectionAllCall({ callsData }) {
	const dispatch = useDispatch();
	const { formTemporaryCallSlug } = useSelector((state) => state.call);

	useEffect(() => {
		formTemporaryCallSlug && dispatch(cleanFormTemporaryCallSlug());
	}, [dispatch, formTemporaryCallSlug]);

	return (
		<div className='mx-auto flex w-full px-0 pb-10 md:px-2'>
			{Array.isArray(callsData) && callsData.length > 0 && (
				<div
					className={
						'mx-auto grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'
					}
					data-test={'calls-grid'}
				>
					{callsData.map((call) => (
						<CardCall
							key={call.id}
							id={call.id}
							slug={call.route_slug}
							organizationName={call.organization_name}
							organizationMainPictureUrl={call.call_organization_image}
							callName={call.name}
							city={call.city}
							country={call.country}
							callMainPictureUrl={call.main_picture_url}
							closeDate={call.closes_at}
							category={call.category}
							technique={call.technique}
							status={call.status}
							description={call.sub_header}
							hasAlliance={call.has_datarte_alliance}
						/>
					))}
				</div>
			)}
		</div>
	);
}

SectionAllCall.propTypes = {
	/** Has the data to show the announcement information */
	callsData: PropTypes.array,
};

export default SectionAllCall;
