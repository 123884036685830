import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

/**
 * @description renders portfolio slides.
 * @param {*} param0
 * @returns a slide with is elements
 */
function ElementArtworkView({
	elementType,
	picture,
	name,
	technique,
	year,
	width,
	measure,
	textSize,
	positionArtPictureLeft,
	positionArtPictureTop,
	positionArtTextLeft,
	positionArtTextTop,
	index,
}) {
	return (
		<>
			{elementType === 'elementArtworkComplete' && (
				<>
					<div
						className={`absolute max-h-[70%] w-fit-content`}
						style={{
							left: positionArtPictureLeft,
							top: positionArtPictureTop,
							right: '0',
							bottom: '0',
						}}
					>
						<LazyLoadImage
							src={picture}
							alt={name}
							className={'h-full w-full'}
						/>
					</div>
					<div
						className={`absolute content-center`}
						style={{
							left: positionArtTextLeft,
							top: positionArtTextTop,
						}}
					>
						<div className={`flex flex-col`}>
							<h1 style={{ fontSize: `${textSize}` }}>{name}</h1>
							<p style={{ fontSize: `${textSize}` }}>{measure}</p>
							<p style={{ fontSize: `${textSize}` }}>{technique}</p>
							<p style={{ fontSize: `${textSize}` }}>{year}</p>
						</div>
					</div>
				</>
			)}

			{elementType === 'artworkElementText' && (
				<div
					className={`absolute`}
					style={{
						left: positionArtTextLeft,
						top: positionArtTextTop,
					}}
				>
					<div className={`flex flex-col content-center justify-center`}>
						<h1 style={{ fontSize: `${textSize}` }}>{name}</h1>
						<p style={{ fontSize: `${textSize}` }}>{measure}</p>
						<p style={{ fontSize: `${textSize}` }}>{technique}</p>
						<p style={{ fontSize: `${textSize}` }}>{year}</p>
					</div>
				</div>
			)}

			{elementType === 'artworkElementPicture' && (
				<div
					className={`absolute max-h-[70%] w-fit-content`}
					style={{
						left: positionArtPictureLeft,
						top: positionArtPictureTop,
						right: '0',
						bottom: '0',
					}}
				>
					<LazyLoadImage src={picture} alt={name} className={'h-full w-full'} />
				</div>
			)}
		</>
	);
}

ElementArtworkView.propTypes = {
	/**  handle of which type of element artwork to render */
	elementType: PropTypes.string,
	/** url of the image to render */
	picture: PropTypes.string,
	/** name of the artwork or some text */
	name: PropTypes.string,
	/** technique of the artwork */
	technique: PropTypes.string,
	/** year of the artwork */
	year: PropTypes.string,
	/** measure of the artwork*/
	measure: PropTypes.string,
	/** width that handle the artwork */
	width: PropTypes.string,
	/** height that handle the artwork */
	height: PropTypes.string,
	/* fixed left position of the picture in tailwindcss */
	positionArtPictureLeft: PropTypes.string,
	/* fixed top position of the picture in tailwindcss */
	positionArtPictureTop: PropTypes.string,
	/* fixed left position of the text in tailwindcss */
	positionArtTextLeft: PropTypes.string,
	/* fixed top position of the text in tailwindcss */
	positionArtTextTop: PropTypes.string,
	/** Defines the size of the text of the artwork information */
	textSize: PropTypes.string,
	/* index as key container */
	index: PropTypes.number,
};

export default ElementArtworkView;
