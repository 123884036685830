//3rd parties
import React from 'react';
import { PropTypes } from 'prop-types';

//UI
import { ReactComponent as PerfilIcon } from '../../assets/icons/Perfil.svg';

/**
 * @description render the perfil icon with some action onclick.
 * @returns
 */
export default function FigureIconPerfil({ design, action }) {
	return (
		<div
			className={design}
			onClick={action}
			data-test={'user-icon-desktop-navbar'}
		>
			<PerfilIcon />
		</div>
	);
}

FigureIconPerfil.propTypes = {
	/** a function that is trigger when the icon is clicked */
	action: PropTypes.func,
	/** a string with tailwind styles */
	design: PropTypes.string,
};
