export const transformRolesText = (text) => {
	if (!text) return null;

	const initialRolesArray = text.split(',');

	const finalRolesArray = initialRolesArray.map((role) => {
		const capitalizedRoleText = role
			.split('_')
			.map((r) => r[0] + r.slice(1).toLowerCase());
		const finaleRoleText = capitalizedRoleText.join(' ');

		return finaleRoleText;
	});

	return finalRolesArray.join(', ');
};

export const transformContactRolesOptions = (roles) => {
	if (!roles || !Array.isArray(roles)) return null;

	const rolesOptionsTransformed = roles.map(({ id, label }) => {
		return {
			id: String(id),
			label: transformRolesText(label),
		};
	});

	return rolesOptionsTransformed;
};
