import React from 'react';
import PropTypes from 'prop-types';

import NavbarNewSkeleton from '../../organisms/Navbar/NavbarSkeleton/NavbarNewSkeleton';
import SkeletonTitle from '../../molecules/Skeleton/SkeletonTitle';

const TemplateWithNavbarSkeleton = ({
	designSkeletonTitle1,
	designSkeletonTitle2,
	designSkeletonTitle3,
	designSkeletonTitle4,
	designSkeletonTitleRight,
}) => {
	return (
		<div>
			<NavbarNewSkeleton />
			<SkeletonTitle
				designSkeletonTitle1={designSkeletonTitle1}
				designSkeletonTitle2={designSkeletonTitle2}
				designSkeletonTitle3={designSkeletonTitle3}
				designSkeletonTitle4={designSkeletonTitle4}
				designSkeletonTitleRight={designSkeletonTitleRight}
			/>
		</div>
	);
};

TemplateWithNavbarSkeleton.propTypes = {
	/** TailWind className for SkeletonTitle component*/
	designSkeletonTitle1: PropTypes.string,
	/** TailWind className for SkeletonTitle component*/
	designSkeletonTitle2: PropTypes.string,
	/** TailWind className for SkeletonTitle component */
	designSkeletonTitle3: PropTypes.string,
	/** TailWind className  for SkeletonTitle component*/
	designSkeletonTitle4: PropTypes.string,
};

export default TemplateWithNavbarSkeleton;
