//3rd
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
//BL
import {
	dateShortFormat,
	handleTimeZoneOffset,
} from '../../../BusinessLogic/helpers/dateFormats';

//UI
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import UnderlineHr from '../../atoms/UnderLine/UnderlineHr';
import CardOrganization from '../../molecules/Card/CardOrganization';
import FilterCallPostulations from '../../molecules/Filters/FilterCallPostulations';
import PortfolioLoading from '../Loading/PortfolioLoading';

const CallPostulations = ({ callPostulations, isLoading }) => {
	const [callPostulationsFiltered, setCallPostulationsFiltered] =
		useState(callPostulations);
	const initialFilterParameters = {
		sorting: '',
		status: '',
		date: '',
		name: '',
	};
	const [filterParameters, setFilterParameters] = useState(
		initialFilterParameters
	);

	const sortPostulations = (parameter, postulations) => {
		switch (parameter) {
			case 'date_ascending':
				return [...postulations].sort((a, b) => {
					const dateA = new Date(a?.updated_at ?? '');
					const dateB = new Date(b?.updated_at ?? '');
					return dateB - dateA;
				});
			case 'date_descending':
				return [...postulations].sort((a, b) => {
					const dateA = new Date(a?.updated_at ?? '');
					const dateB = new Date(b?.updated_at ?? '');
					return dateA - dateB;
				});
			case 'name_ascending':
				return [...postulations].sort((a, b) => {
					const nameA = a?.organization_user_name ?? '';
					const nameB = b?.organization_user_name ?? '';
					return nameA.localeCompare(nameB);
				});
			case 'name_descending':
				return [...postulations].sort((a, b) => {
					const nameA = a?.organization_user_name ?? '';
					const nameB = b?.organization_user_name ?? '';
					return nameB.localeCompare(nameA);
				});
			default:
				return postulations;
		}
	};

	useEffect(() => {
		let sortedAndFilteredPostulations = callPostulations;

		if (filterParameters.status) {
			sortedAndFilteredPostulations = sortedAndFilteredPostulations.filter(
				(postulation) => filterParameters.status === postulation.status
			);
		}

		if (filterParameters.date) {
			sortedAndFilteredPostulations = sortedAndFilteredPostulations.filter(
				(postulation) =>
					dateShortFormat(`${filterParameters.date} 00:00:00`) ===
					dateShortFormat(postulation.updated_at)
			);
		}

		if (filterParameters.name) {
			sortedAndFilteredPostulations = sortedAndFilteredPostulations.filter(
				(postulation) =>
					postulation.organization_user_name
						.toLocaleLowerCase()
						.includes(filterParameters.name.toLocaleLowerCase())
			);
		}

		if (filterParameters.sorting) {
			sortedAndFilteredPostulations = sortPostulations(
				filterParameters.sorting,
				sortedAndFilteredPostulations
			);
		}

		setCallPostulationsFiltered(sortedAndFilteredPostulations);
	}, [filterParameters, callPostulations]);

	if (isLoading) {
		return <PortfolioLoading />;
	}

	return (
		<>
			<section className='mt-6 mb-4 flex w-full flex-col items-center'>
				<div className='flex w-full items-center justify-between'>
					<TextTitleSub
						design={'text-purple-50'}
						title={'Postulaciones recibidas'}
					/>
					<FilterCallPostulations
						initialFilterParameters={initialFilterParameters}
						filterParameters={filterParameters}
						setFilterParameters={setFilterParameters}
					/>
				</div>
				<UnderlineHr colour={'purple'} />
			</section>
			<section
				className='mt-10 flex w-full flex-col pb-6 md:mt-4 md:grid  md:grid-cols-2 md:gap-x-12'
				data-test='call-postulations-grid'
			>
				{Array.isArray(callPostulationsFiltered) &&
					callPostulationsFiltered.map((postulation, index) => (
						<div
							key={index}
							onClick={() => {
								sessionStorage.setItem('previousPage', 'singleCall');
							}}
						>
							<CardOrganization
								name={postulation.organization_user_name}
								status={postulation.status}
								image={postulation.organization_main_picture_id}
								date={format(
									new Date(
										handleTimeZoneOffset(postulation.status_updated_at) ??
											postulation.created_at
									),
									'dd/MM/yyyy'
								)}
								redirect={'postulaciones'}
								id={postulation.id}
							/>
						</div>
					))}
			</section>
		</>
	);
};

CallPostulations.propTypes = {
	callPostulations: PropTypes.array,
	isLoading: PropTypes.bool,
};

CallPostulations.defaultProps = {
	callPostulations: [],
};

export default CallPostulations;
