//3rd
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

//UI
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import CardSwitch from '../../../UI/molecules/Card/CardSwitch';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import AddWhite from '../../../UI/assets/icons/AddWhite.svg';
import CallFieldForm from '../../../UI/organisms/Call/CallFieldForm';
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import UnderlineHr from '../../../UI/atoms/UnderLine/UnderlineHr';
import { ReactComponent as TextGrey } from '../../../UI/assets/icons/TextGrey.svg';
import { ReactComponent as EditIcon } from '../../../UI/assets/icons/pencil.svg';

//BL
import { updateEditingCall } from '../../../BusinessLogic/redux/reducers/call';
import {
	useGetSingleCall,
	useGetCallForm,
	usePutCall,
} from '../../../BusinessLogic/hooks/query/useQueryCalls';

/**
 * @description Renders the form view of a call
 * @param {*} param0
 * @returns
 */
const CallForm = () => {
	const { t } = useTranslation();
	const { slug } = useParams();
	const dispatch = useDispatch();
	const { editingCall } = useSelector((state) => state.call);

	const { singleCall } = useGetSingleCall(slug);
	const { singleCallForm } = useGetCallForm(singleCall?.form_id);

	const [hasForm, setHasForm] = useState(editingCall.has_form);
	const [showFieldForm, setShowFieldForm] = useState(false);
	const [selectedField, setSelectedField] = useState();

	const usePutCallMutation = usePutCall();

	useEffect(() => {
		if (hasForm !== editingCall.has_form) {
			dispatch(
				updateEditingCall({
					...editingCall,
					has_form: hasForm,
				})
			);
		}
	}, [dispatch, editingCall, hasForm]);

	const handleSwitchRequiredPostulation = () => {
		let editingCallFormatted = { ...editingCall };
		delete editingCallFormatted?.form;
		usePutCallMutation.mutateAsync({
			...editingCallFormatted,
			has_form: !hasForm,
		});
	};

	const checkLengthOfFormFields = () => {
		const isThereFormFields = singleCallForm?.forms?.form_elements;
		let editingCallFormatted = { ...editingCall };
		delete editingCallFormatted?.form;
		if (!isThereFormFields && hasForm) {
			usePutCallMutation.mutateAsync({
				...editingCallFormatted,
				has_form: false,
			});
		}
	};

	const getFieldTypeText = (type) => {
		switch (type) {
			case 'TEXTO_LARGO':
				return 'Recibe texto largo.';
			case 'TEXTO_CORTO':
				return 'Recibe texto corto.';
			case 'ADD_DOC':
				return 'Recibe documento.';
			default:
				return '';
		}
	};

	const handleEditField = (field) => {
		setSelectedField(field);
		setShowFieldForm(true);
	};

	return (
		<>
			{showFieldForm ? (
				<CallFieldForm
					setShowFieldForm={setShowFieldForm}
					initialValues={selectedField}
					edit={selectedField !== undefined}
				/>
			) : (
				<TemplateWithNavBar>
					<NavbarSecondaryNew
						title={'Formulario'}
						textColor='text-purple-50 w-max'
						color='bg-purple-50'
						showDots={false}
						redirect={`/convocatorias/${slug}`}
						textBack={`${t('RegresarConvocatoria')}`}
						textBackDesign='pl-3 text-purple-50'
						arrowLeftColor='purple'
						backAction={checkLengthOfFormFields}
					/>
					<div className='w-full'>
						<CardSwitch
							mainText={'Requerido'}
							secondText={
								'Crea un formulario para recopilar los datos y detalles que necesitas del artista.'
							}
							thirdText={
								'Recomendamos utilizar formularios para pedir statements, descripción del proyecto y otros textos.'
							}
							switched={hasForm}
							onSwitchAction={handleSwitchRequiredPostulation}
							setSwitched={setHasForm}
						/>
						{hasForm && (
							<div>
								{singleCallForm?.forms?.form_elements?.length > 0 && (
									<div>
										<div className='flex flex-col gap-1 py-4'>
											<TextTitleSub
												title={'Campos'}
												textColor={'text-purple-50'}
											/>
											<UnderlineHr colour={'purple'} />
										</div>
										<div className='flex flex-row justify-between gap-1 py-2'>
											<div className='flex flex-col gap-1'>
												<TextContentSecond
													text={`${singleCallForm?.forms?.form_elements?.length} Campos`}
													style={{ fontWeight: '700' }}
												/>
											</div>
											<Link
												to={`/convocatorias/${slug}/formulario/previsualizar`}
											>
												<TextContentSecond
													text='Visualizar'
													design={'text-sky-500 underline cursor-pointer'}
													style={{ fontWeight: '700' }}
												/>
											</Link>
										</div>
										{singleCallForm?.forms?.form_elements?.map((field, i) => (
											<div
												key={i}
												className='my-3 flex flex-row items-start gap-2'
											>
												<div className='flex h-9 w-9 items-center justify-center rounded-full bg-gray-200 p-2'>
													<TextGrey />
												</div>
												<div className='flex w-full flex-col gap-1'>
													<TextContentSecond
														text={field.name}
														design={'text-purple-50'}
														style={{ fontSize: '1rem', fontWeight: '700' }}
													/>
													<TextContentSecond
														text={getFieldTypeText(field.type)}
													/>
												</div>
												<div
													data-test='edit-icon'
													className='cursor-pointer'
													onClick={() => handleEditField(field)}
												>
													<EditIcon />
												</div>
											</div>
										))}
									</div>
								)}
								<div className='mb-16 mt-8 flex w-full flex-row justify-center gap-3'>
									<ButtonFab
										label={'Agregar campo'}
										size={'large'}
										icon={AddWhite}
										action={() => {
											setSelectedField();
											setShowFieldForm(true);
										}}
									/>
								</div>
							</div>
						)}
					</div>
				</TemplateWithNavBar>
			)}
		</>
	);
};

export default CallForm;
