import { format, isValid } from 'date-fns';

export const validDate = (date) => {
	return isNaN(Date.parse(date));
};

export const dateLongFormat = (date) => {
	if (validDate(date)) {
		return null;
	}

	const formattedDate = new Date(date.replace('GMT', '')).toLocaleDateString(
		'es',
		{
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		}
	);

	return formattedDate;
};

export const dateTimeLongFormat = (date) => {
	if (validDate(date)) {
		return null;
	}

	const formattedDate = new Date(date.replace('GMT', '')).toLocaleTimeString(
		'es',
		{
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour12: true,
			hour: '2-digit',
			minute: '2-digit',
		}
	);

	return formattedDate;
};

export const dateShortFormat = (date) => {
	if (validDate(date)) {
		return null;
	}

	const formattedDate = new Date(date.replace('GMT', '')).toLocaleDateString(
		'es',
		{
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
		}
	);

	return formattedDate;
};

export const dateISOFormat = (date) => {
	if (validDate(date)) {
		return null;
	}

	const formattedDate = new Date(date).toISOString().split('.')[0];

	return formattedDate;
};

export const handleTimeZoneOffset = (utcDateString) => {
	if (!utcDateString) return undefined;

	const utcDate = new Date(utcDateString);
	if (isNaN(utcDate.getTime())) return undefined;

	const localDate = new Date(
		utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
	);
	return localDate;
};

export const formatDateToYear = (date) => {
	const year = new Date(date);
	if (isValid(year)) {
		return format(year, 'yyyy');
	} else {
		return null;
	}
};
