// 3rd Party
import PropTypes from 'prop-types';

// UI
import ExpertSlideElementText from '../../../atoms/Portfolio/ExpertSlideElements/ExpertSlideElementText';

export default function ExpertSlideItemText({
	text,
	designText,
	designContainer,
}) {
	return (
		<>
			<div className={`${designContainer}`}>
				<ExpertSlideElementText text={text} designText={designText} />
			</div>
		</>
	);
}

ExpertSlideItemText.propTypes = {
	text: PropTypes.string,
	designText: PropTypes.string,
	label: PropTypes.string,
};
