import { Routes, Route } from 'react-router-dom';

import PageNotFound from '../../Pages/fallBackPages/PageNotFound';
import Payments from '../../UI/organisms/Payments/Payments';

export default function PaymentsRouter() {
	return (
		<Routes>
			<Route path='/' element={<Payments />} />
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
}
