//3rd party
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//BL
import {
	setApplyFilterArrayByClick,
	setFilterArraySorted,
} from '../../../BusinessLogic/redux/reducers/filter';

//UI
import { ReactComponent as CaretDown } from '../../assets/icons/CaretDownGrey.svg';
import { ReactComponent as HeartBlack } from '../../assets/icons/HeartBlack.svg';
import TextContentThird from '../../atoms/Text/Content/TextContentThird';

/**
 * @description render a filter one in single postulation
 * @param {*} param0
 * @returns
 */
function FilterSinglePostulation({ design }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [showDropDown, setShowDropDown] = useState(false);
	const [selectedOption, setSelectedOption] = useState(t('SeleccionaUno'));
	const options = [
		t('SeleccionaUno'),
		t('ObrasAprobadas'),
		t('MasNuevoAAntiguo'),
		t('MasAntiguoANuevo'),
		t('PrecioDeMenorAMayor'),
		t('PrecioDeMayorAMenor'),
	];

	return (
		<div className={`flex flex-col ${design}`}>
			<div
				className={` border bg-middle_grey-50 ${
					showDropDown ? 'rounded-none' : 'rounded-md'
				} flex flex-col`}
			>
				<button
					className='flex h-8 cursor-pointer flex-row items-center justify-between px-1'
					onClick={() => setShowDropDown(!showDropDown)}
				>
					<div className='flex flex-row'>
						<TextContentThird text={selectedOption} />
						{selectedOption === t('ObrasAprobadas') && (
							<div className='flex w-4 items-center pl-1'>
								<HeartBlack />
							</div>
						)}
					</div>
					<CaretDown
						className={!showDropDown && '-rotate-180 transform transition'}
					/>
				</button>
			</div>
			{showDropDown && (
				<div className='relative z-10 -mt-1 flex'>
					<div className='flex w-full flex-col'>
						{options.map(
							(option) =>
								option !== selectedOption && (
									<button
										className=' flex  cursor-pointer items-center bg-middle_grey-50 px-1 py-2 pl-4'
										onClick={() => {
											setShowDropDown(!showDropDown);
											setSelectedOption(option);
											dispatch(setFilterArraySorted({ sortedFilter: option }));
											dispatch(setApplyFilterArrayByClick());
										}}
									>
										<TextContentThird text={option} design='text-left' />
										{option === t('ObrasAprobadas') && (
											<div className='flex w-4 items-center pl-1'>
												<HeartBlack />
											</div>
										)}
									</button>
								)
						)}
					</div>
				</div>
			)}
		</div>
	);
}

FilterSinglePostulation.propTypes = {};

export default FilterSinglePostulation;
