//3rd
import React from 'react';
import PropTypes from 'prop-types';

//UI
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import ButtonSwitch from '../../atoms/Buttons/ButtonSwitch';

/**
 * @description Renders a card with a main text, a secondary text and a switch button
 * @param {*} param0
 * @returns
 */
const CardSwitch = ({
	mainText,
	secondText,
	thirdText,
	switched,
	setSwitched,
	onSwitchAction,
}) => {
	return (
		<div className='my-2 w-full gap-3 py-2'>
			<div className='mb-2 flex w-full justify-between'>
				<TextContentSecond text={mainText} style={{ fontWeight: '500' }} />
				<ButtonSwitch
					switched={switched}
					setSwitched={setSwitched}
					onSwitchAction={onSwitchAction}
				/>
			</div>
			<TextContentSecond text={secondText} />
			{thirdText && <TextContentSecond text={thirdText} />}
		</div>
	);
};

CardSwitch.propTypes = {
	/**The main text or title of the card*/
	mainText: PropTypes.string,
	/**Text content of the card*/
	secondText: PropTypes.string,
	/**Text complementary of the card*/
	thirdText: PropTypes.string,
	/** Boolean state that determines if the button is switched */
	switched: PropTypes.bool,
	/** Function to change the state of the switch button */
	setSwitched: PropTypes.func,
};

export default CardSwitch;
