import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// BL
import {
	getPortfolioToEdit,
	setIdOfPortfolioSlideToEdit,
	setIdOfPortfolioToEdit,
	updateSlideElement,
} from '../../../BusinessLogic/redux/reducers/editablePortfolio';
import {
	portfolioFormValidationSchemaWithPassword,
	portfolioEditFormValidationSchema,
} from '../../../BusinessLogic/data/PortfolioSingle';
import { PortfolioConfiguration } from '../../../UI/organisms/EditablePortfolio/PortfolioConfiguration';
// UI

import EditablePorfolioTopNavbar from '../../../UI/molecules/Portfolio/LayoutComponents/EditablePortfolioTopNavbar';

import CanvasEdition from '../../../UI/organisms/EditablePortfolio/CanvasEdition';
import PageSelection from '../../../UI/organisms/EditablePortfolio/PageSelection';
import TemplateSelection from '../../../UI/organisms/EditablePortfolio/TemplateSelection';

/**
 * @description renders editable portfolio feature.
 * @param {*} param0
 * @returns
 */

const EditablePortfolio = () => {
	const dispatch = useDispatch();
	const { portfolioSingleTabs } = useSelector((state) => state.portfolios);

	const {
		editablePortfolioView,
		idOfPortfolioToEdit,
		singleEditablePortfolio,
	} = useSelector((state) => state.editablePortfolio);
	const [indexInfo] = useState(0);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
	const [showSpecificView, setShowSpecificView] = useState(
		editablePortfolioView || 'canvasEdition'
	);
	const [currentSlideToEdit, setCurrentSlideToEdit] = useState(0);
	const information = portfolioSingleTabs[indexInfo].content;
	const informationArray = information.map((item) => item.information);

	useEffect(() => {
		dispatch(getPortfolioToEdit(idOfPortfolioToEdit)).then((res) => {
			dispatch(setIdOfPortfolioSlideToEdit(res.payload.slides[0].slide_id));
			dispatch(updateSlideElement(res.payload.slides[0].content));
		});
		return () => {
			dispatch(setIdOfPortfolioToEdit(null));
			dispatch(setIdOfPortfolioSlideToEdit(null));
			dispatch(updateSlideElement([]));
		};
	}, [dispatch, idOfPortfolioToEdit]);

	useEffect(() => {
		const changeInViewport = () => {
			if (window.innerWidth > 1024) {
				setIsMobile(false);
			} else {
				setIsMobile(true);
			}
		};
		window.addEventListener('resize', changeInViewport);
		return () => {
			window.removeEventListener('resize', changeInViewport);
		};
	});

	const renderSwitchPortfolioViews = () => {
		switch (showSpecificView) {
			case 'canvasEdition':
				return (
					<CanvasEdition
						isMobile={isMobile}
						setShowSpecificView={setShowSpecificView}
						currentPageToEdit={currentSlideToEdit}
						portfolioData={singleEditablePortfolio}
					/>
				);
			case 'pageSelection':
				return (
					<PageSelection
						isMobile={isMobile}
						setShowSpecificView={setShowSpecificView}
						setCurrentSlideToEdit={setCurrentSlideToEdit}
						portfolioData={singleEditablePortfolio}
					/>
				);
			case 'templateSelection':
				return <TemplateSelection />;
			case 'portfolioConfiguration':
				return (
					<PortfolioConfiguration
						information={informationArray[0]}
						image={
							singleEditablePortfolio.main_picture_url ??
							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image'
						}
						id={singleEditablePortfolio.id}
						title={singleEditablePortfolio.name}
						slug={singleEditablePortfolio.slug}
						permissions={singleEditablePortfolio.permission}
						portfolioData={singleEditablePortfolio}
						validationSchema={portfolioEditFormValidationSchema}
						validationSchemaWithPassword={
							portfolioFormValidationSchemaWithPassword
						}
						organization_user_id={singleEditablePortfolio.organization_user_id}
					/>
				);
			default:
				break;
		}
	};

	return (
		<div className=' h-screen bg-light_grey-75'>
			<EditablePorfolioTopNavbar
				showSpecificView={showSpecificView}
				setShowSpecificView={setShowSpecificView}
			/>
			{renderSwitchPortfolioViews()}
		</div>
	);
};

export default EditablePortfolio;
