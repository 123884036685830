// 3rd Party
import PropTypes from 'prop-types';
import { StyleSheet } from '@react-pdf/renderer';

// UI
import DownloadableExpertSlideItemArtwork from '../../../../molecules/Portfolio/ExpertSlideItems/DownloadableExpertSlideItems/DownloadableExpertSlideItemArtwork';

const styles = StyleSheet.create({
	container: {
		width: '80%',
		height: '60vh',
		marginHorizontal: 'auto',
	},
});

export default function DownloadableExpertSlideArtworkIndividual({
	artworkElements,
	artworkFieldsToShow,
}) {
	const artwork = artworkElements?.find(
		(item) => item.type === 'artwork'
	)?.slideItemElements;
	return (
		<DownloadableExpertSlideItemArtwork
			artworkElements={artwork}
			stylesContainer={styles.container}
			artworkFieldsToShow={artworkFieldsToShow}
		/>
	);
}

DownloadableExpertSlideArtworkIndividual.propTypes = {
	// contains the artwork data to show
	artworkElements: PropTypes.array,
	// values to show/hide the fields in artwork
	artworkFieldsToShow: PropTypes.object,
};
