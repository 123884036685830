import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';

import AllCall from '../../Pages/sections/call/AllCall';
import CallBenefits from '../../Pages/sections/call/CallBenefits';
import CallCV from '../../Pages/sections/call/CallCV';
import CallForm from '../../Pages/sections/call/CallForm';
import CallFormPreview from '../../Pages/sections/call/CallFormPreview';
import CallPortfolio from '../../Pages/sections/call/CallPortfolio';
import SingleCall from '../../Pages/sections/call/SingleCall';
import { BotellonGaleriaConv } from '../../UI/atoms/redirect/BotellonGaleriaConv';
import { DamaGaleriaConv } from '../../UI/atoms/redirect/DamaGaleriaConv';
import { ArvividGaleriaConv } from '../../UI/atoms/redirect/ArvividGaleriaConv';

/**
 * @description router to manage Call
 * @param {*} param0
 * @returns
 */
export const CallRouter = () => {
	return (
		<Routes>
			<Route path='/' element={<AllCall />} />

			<Route path='/5' element={<BotellonGaleriaConv />} />

			<Route path='/9' element={<DamaGaleriaConv />} />

			<Route
				path='/revisiondeportafolios-abstracto-arvivd'
				element={<ArvividGaleriaConv />}
			/>

			<Route exact path='/:slug' element={<SingleCall />} />

			<Route
				path='/:slug/beneficios'
				element={
					<PrivateRoute>
						<CallBenefits />
					</PrivateRoute>
				}
			/>

			<Route
				path='/:slug/cv'
				element={
					<PrivateRoute>
						<CallCV />
					</PrivateRoute>
				}
			/>

			<Route
				path='/:slug/portafolio'
				element={
					<PrivateRoute>
						<CallPortfolio />
					</PrivateRoute>
				}
			/>

			<Route
				path='/:slug/formulario'
				element={
					<PrivateRoute>
						<CallForm />
					</PrivateRoute>
				}
			/>

			<Route
				path='/:slug/formulario/previsualizar'
				element={
					<PrivateRoute>
						<CallFormPreview />
					</PrivateRoute>
				}
			/>
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default CallRouter;
