function transformInfoIntoInitialValues(informations) {
	let finalValue = {};
	informations.map(
		(information) => (finalValue[information.field] = information.value)
	);
	return finalValue;
}

function validationSchemaForTitleAndFields(content) {
	let finalObject = {};

	content.map((information) => {
		return (finalObject[information.field] = information.validation);
	});

	return finalObject;
}

export { transformInfoIntoInitialValues, validationSchemaForTitleAndFields };
