//3rd party
import PropTypes from 'prop-types';

//UI
import FilterSinglePostulation from '../Filter/FilterSinglePostulation';
import TextTitlePrincipal from '../../atoms/Text/Titles/TextTitlePrincipal';
import { ReactComponent as Funnel } from '../../assets/icons/Funnel.svg';
import FunnelFilterPostulation from '../Filter/FunnelFilterPostulation';
import { useTranslation } from 'react-i18next';

/**
 * @description contain filters used in postulation and a title in the center.
 * @returns
 */
function PostulationFiltersAndTitle({
	design,
	designFilterContainer,
	showCallFilter,
	setShowCallFilter,
}) {
	const { t } = useTranslation();

	return (
		<div
			className={`relative flex w-full flex-row items-center justify-between ${design}`}
		>
			<div className='invisible h-8 w-16 md:visible md:w-2/6 lg:w-1/4 xl:w-1/5'>
				<FilterSinglePostulation design={designFilterContainer} />
			</div>
			<div className='flex justify-center md:w-2/6 lg:w-1/4 xl:w-1/5'>
				<TextTitlePrincipal title={t('Obras')} />
			</div>
			<div className=' flex w-16 justify-end md:w-2/6 lg:w-1/4 xl:w-1/5'>
				<div className='relative'>
					<FunnelFilterPostulation
						showCallFilter={showCallFilter}
						setShowCallFilter={setShowCallFilter}
					/>
				</div>
				<Funnel
					className='cursor-pointer'
					onClick={() => setShowCallFilter(!showCallFilter)}
				/>
			</div>
		</div>
	);
}

PostulationFiltersAndTitle.propTypes = {
	/* handles other tailwindcss styles */
	design: PropTypes.string,
	/* styles of the filter container */
	designFilterContainer: PropTypes.string,
};

PostulationFiltersAndTitle.defaultProps = {
	design: 'mt-6 mb-12 px-4 md:px-8',
};

export default PostulationFiltersAndTitle;
