// 3rd Party
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

//BL
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';
import AllContacts from '../../Pages/sections/contact/AllContacts';
import ContactSingle from '../../Pages/sections/contact/ContactSingle';
import PersonForm from '../../Pages/sections/contact/PersonForm';

export const ContactRouter = () => {
	const { appUser } = useSelector((state) => state.appUser);

	return (
		<Routes>
			{appUser.role_company !== 'GALLERY' && (
				<>
					<Route
						path='/'
						element={
							<PrivateRoute>
								<AllContacts />
							</PrivateRoute>
						}
					/>
					<Route
						path='/new'
						element={
							<PrivateRoute>
								<PersonForm action='new' />
							</PrivateRoute>
						}
					/>
					<Route
						path='/:id'
						element={
							<PrivateRoute>
								<ContactSingle />
							</PrivateRoute>
						}
					/>
				</>
			)}
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default ContactRouter;
