import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';
//UI
import AllPostulation from '../../Pages/sections/postulation/AllPostulation';
import PostulationCatalogue from '../../Pages/sections/postulation/PostulationCatalogue';
import PostulationPortfolio from '../../Pages/sections/postulation/PostulationPortfolio';
import SinglePostulation from '../../Pages/sections/postulation/SinglePostulation';
import PostulationCVForm from '../../Pages/sections/postulation/PostulationCVForm';
import PostulationForm from '../../Pages/sections/postulation/PostulationForm';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';

/**
 * @name router to manage the postulations
 * @returns
 */

export const PostulationRouter = () => {
	return (
		<Routes>
			<Route
				path='/'
				element={
					<PrivateRoute>
						<AllPostulation />
					</PrivateRoute>
				}
			/>
			<Route
				path='/:id'
				element={
					<PrivateRoute>
						<SinglePostulation />
					</PrivateRoute>
				}
			/>
			<Route
				path='/:id/catalogo'
				element={
					<PrivateRoute>
						<PostulationCatalogue />
					</PrivateRoute>
				}
			/>
			<Route
				path='/:id/portafolio'
				element={
					<PrivateRoute>
						<PostulationPortfolio />
					</PrivateRoute>
				}
			/>
			<Route
				path='/:id/formulario'
				element={
					<PrivateRoute>
						<PostulationForm />
					</PrivateRoute>
				}
			/>
			<Route
				path='/:id/cv'
				element={
					<PrivateRoute>
						<PostulationCVForm />
					</PrivateRoute>
				}
			/>
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default PostulationRouter;
