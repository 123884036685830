import { Fragment } from 'react';

export const stringToSlug = (string) => {
	//removes any space and accents
	if (!string) return string;
	return string
		.toLowerCase()
		.replace(/\s+/g, '')
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');
};
export const parseArrayJsonToString = (jsonArray) => {
	// converts an array in JSON to a single string.
	try {
		const array = JSON.parse(jsonArray);
		return array.join(', ');
	} catch {
		return jsonArray;
	}
};
export const arrayToString = (array) => {
	// converts an array to a string with its elements separated by ','.
	if (Array.isArray(array)) {
		const cleanArray = array.filter((element) => element !== '');
		return cleanArray.join(', ');
	} else {
		return array;
	}
};
export const addBreakLinesTagsToText = (text) => {
	let textFormatted = '';
	if (text) {
		const slicesText = text.split('\n');
		textFormatted = slicesText.map((slice, index) => (
			<Fragment key={index}>
				{slice}
				<br />
			</Fragment>
		));
	}

	return textFormatted;
};
