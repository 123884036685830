// 3rd party
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//BL
import { transformInfoIntoInitialValues } from '../../../BusinessLogic/helpers/FormHelper';

// UI
import SectionTitleAndFields from '../Section/SectionTitleAndFields';
import SectionEditTitleAndFields from '../Section/SectionEditTitleAndFields';

/**
 * @description renders a only the title and fields sections (static and edit)
 * @param {*} param0
 * @returns
 */

const ViewTitleAndFields = ({
	indexInfo,
	information,
	onSubmitAction,
	sectionTitle,
	colorHr,
	id,
	validationSchema,
	section,
	hideEditOption,
}) => {
	const [turnEdit, setTurnEdit] = useState(false);

	useEffect(() => {
		setTurnEdit(false);
	}, [indexInfo]);

	return (
		<div className='flex flex-col bg-light_grey-50'>
			{turnEdit ? (
				<SectionEditTitleAndFields
					colorHr={colorHr}
					sectionTitle={sectionTitle}
					informationArray={information}
					initialValues={transformInfoIntoInitialValues(information)}
					setTurnEdit={setTurnEdit}
					turnEdit={turnEdit}
					onSubmitAction={onSubmitAction}
					validationSchema={validationSchema}
					id={id}
				/>
			) : (
				<SectionTitleAndFields
					colorHr={colorHr}
					sectionTitle={sectionTitle}
					information={information}
					setTurnEdit={setTurnEdit}
					turnEdit={turnEdit}
					section={section}
					hideEditOption={hideEditOption}
				/>
			)}
		</div>
	);
};

export default ViewTitleAndFields;

ViewTitleAndFields.propTypes = {
	/** array with title and content of the tab*/
	information: PropTypes.array,
	/** number that representes the tab that is displayed */
	indexInfo: PropTypes.number,
	/** title of the section with information */
	sectionTitle: PropTypes.string,
	/** string with the color of the hr */
	colorHr: PropTypes.string,
	/** id of the element */
	id: PropTypes.number,
	/* ValidationSchema used to manage error messages */
	validationSchema: PropTypes.object,
	/* identify in which section single is using */
	section: PropTypes.string,
};
