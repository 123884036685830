import * as Yup from 'yup';
//Portfolio SIngle
const portfolioSingleTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'portfolio',
				field: 'identification',
				information: [
					{ label: 'Título', value: 'Name', field: 'name' },
					{ label: 'URL', value: 'URL', field: 'slug' },
					{
						label: 'Acceso',
						value: 'PUBLIC',
						field: 'permissions',
						type: 'dropdown',
						options: [
							{ value: 'PUBLIC', label: 'Público' },
							{ value: 'PRIVATE', label: 'Privado' },
							{ value: 'PASSWORD', label: 'Público con clave' },
						],
					},
					{ label: 'MostrarPrecios', value: true, field: 'show_prices' },
					{
						label: 'Resolución',
						value: 'HIGH',
						field: 'image_quality',
						type: 'dropdown',
						options: [
							{ value: 'LOW', label: 'Baja' },
							{ value: 'MEDIUM', label: 'Media' },
							{ value: 'HIGH', label: 'Alta' },
						],
					},
				],
			},
		],
	},
];

const portfolioValidationSchema = [
	//this needs to have the same order than the buttons
	[
		//and the same order than the appearance of the content sections
		//far from elegant
		{
			name: Yup.string().required('NombreRequerido'),
			slug: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.required('UrlObligatorio')
				.matches(/^[a-z0-9]+(?:[-,_][a-z0-9]+)*$/, 'UrlAlfanuméricaYGuiones'),
		},
	],
];

const portfolioFormValidationSchemaWithPassword = Yup.object({
	slug: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('UrlObligatorio')

		.matches(
			/^[a-z0-9]+(?:[-,_][a-z0-9]+)*$/,
			'Únicamente caracteres alfanuméricos y guiones.'
		),
	password: Yup.string().required('ClaveRequerida').min(8, 'ClaveMuyCorta'),
	confirm_password: Yup.string()
		.required('ConfirmarClave')
		.when('password', (password, schema) => {
			return schema.test({
				test: (confirm_password) => confirm_password === password,
				message: 'ClavesNoConcuerdan',
			});
		}),
});

const portfolioEditFormValidationSchema = Yup.object({
	name: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('TituloRequerido'),
	slug: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('UrlObligatorio')
		.matches(/^[a-z0-9]+(?:[-,_][a-z0-9]+)*$/, 'UrlAlfanuméricaYGuiones'),
});

const portfolioSingleButtons = ['Identificación'];
// 	//slide - Type Cover
// 	{
// 		portfolioId: 1,
// 		slideId: 1,
// 		order: 1,
// 		type: 'cover',
// 		slideItems: [
// 			{
// 				slideId: 1,
// 				type: 'title',
// 				slideItemId: 1,
// 				order: 1,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'title',
// 						//Artist name - Gallery name
// 						content: 'Artista',
// 					},
// 					{
// 						slideItemElementId: 2,
// 						order: 2,
// 						type: 'subtitle',
// 						//Portfolio name
// 						content: 'Primer Portafolio',
// 					},
// 				],
// 			},
// 			{
// 				slideId: 1,
// 				type: 'text',
// 				slideItemId: 2,
// 				order: 2,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 3,
// 						order: 1,
// 						type: 'text',
// 						//descripción del portafolio
// 						content:
// 							'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
// 					},
// 				],
// 			},
// 			{
// 				slideId: 1,
// 				type: 'image',
// 				slideItemId: 3,
// 				order: 3,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 4,
// 						order: 1,
// 						type: 'url',
// 						//La imagen de portada de la colección
// 						content:
// 							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
// 					},
// 				],
// 			},
// 		],
// 	},
// 	// slide - type textBlockAndImage
// 	{
// 		portfolioId: 1,
// 		slideId: 2,
// 		order: 2,
// 		type: 'textBlockAndImage',
// 		slideItems: [
// 			{
// 				slideId: 5,
// 				type: 'title',
// 				slideItemId: 1,
// 				order: 1,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'title',
// 						content: 'Biografía',
// 					},
// 				],
// 			},
// 			{
// 				slideId: 6,
// 				type: 'text',
// 				slideItemId: 2,
// 				order: 2,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'text',
// 						//El texto del campo Biografía
// 						content:
// 							'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
// 					},
// 				],
// 			},
// 			{
// 				slideId: 7,
// 				type: 'image',
// 				slideElementId: 3,
// 				order: 3,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'url',
// 						//La imagen del artista
// 						content:
// 							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
// 					},
// 				],
// 			},
// 		],
// 	},
// 	// slide - type textBlock
// 	{
// 		portfolioId: 1,
// 		slideId: 4,
// 		order: 3,
// 		type: 'textBlock',
// 		slideItems: [
// 			{
// 				slideId: 4,
// 				type: 'title',
// 				slideItemId: 1,
// 				order: 1,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'title',
// 						content: 'Exposiciones',
// 					},
// 				],
// 			},
// 			{
// 				slideId: 4,
// 				type: 'text',
// 				slideItemId: 2,
// 				order: 2,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'text',
// 						content:
// 							'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
// 					},
// 				],
// 			},
// 		],
// 	},
// 	//slide - Artwork Group
// 	{
// 		portfolioId: 1,
// 		slideId: 2,
// 		order: 2,
// 		type: 'artworksGroup',
// 		// Tiene todas las obras de la colección asociada
// 		slideItems: [
// 			{
// 				slideId: 2,
// 				type: 'artwork',
// 				slideItemId: 1,
// 				order: 3,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'url',
// 						content:
// 							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
// 					},
// 					{
// 						slideItemElementId: 2,
// 						order: 2,
// 						type: 'title',
// 						content: 'Obra',
// 					},
// 					{
// 						slideItemElementId: 3,
// 						order: 3,
// 						type: 'description',
// 						content: 'Descripción',
// 					},
// 					{
// 						slideItemElementId: 4,
// 						order: 4,
// 						type: 'materials',
// 						content: 'Materiales',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'owner_status',
// 						content: 'Conservado',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'date',
// 						content: '2022',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'measures',
// 						content: '00x00x00cm',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'price',
// 						content: '1000',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'technique',
// 						content: 'Técnica',
// 					},
// 				],
// 			},
// 			{
// 				slideId: 2,
// 				type: 'artwork',
// 				slideItemId: 1,
// 				order: 3,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'url',
// 						content:
// 							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
// 					},
// 					{
// 						slideItemElementId: 2,
// 						order: 2,
// 						type: 'title',
// 						content: 'Obra',
// 					},
// 					{
// 						slideItemElementId: 3,
// 						order: 3,
// 						type: 'description',
// 						content: 'Descripción',
// 					},
// 					{
// 						slideItemElementId: 4,
// 						order: 4,
// 						type: 'materials',
// 						content: 'Materiales',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						//estado de conservación
// 						type: 'owner_status',
// 						content: 'Conservado',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'date',
// 						content: '2022',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'measures',
// 						content: '00x00x00cm',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'price',
// 						content: '1000',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'technique',
// 						content: 'Técnica',
// 					},
// 				],
// 			},
// 			{
// 				slideId: 2,
// 				type: 'artwork',
// 				slideItemId: 1,
// 				order: 3,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'url',
// 						content:
// 							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
// 					},
// 					{
// 						slideItemElementId: 2,
// 						order: 2,
// 						type: 'title',
// 						content: 'Obra',
// 					},
// 					{
// 						slideItemElementId: 3,
// 						order: 3,
// 						type: 'description',
// 						content: 'Descripción',
// 					},
// 					{
// 						slideItemElementId: 4,
// 						order: 4,
// 						type: 'materials',
// 						content: 'Materiales',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						//estado de conservación
// 						type: 'owner_status',
// 						content: 'Conservado',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'date',
// 						content: '2022',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'measures',
// 						content: '00x00x00cm',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'price',
// 						content: '1000',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'technique',
// 						content: 'Técnica',
// 					},
// 				],
// 			},
// 			{
// 				slideId: 2,
// 				type: 'artwork',
// 				slideItemId: 1,
// 				order: 3,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'url',
// 						content:
// 							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
// 					},
// 					{
// 						slideItemElementId: 2,
// 						order: 2,
// 						type: 'title',
// 						content: 'Obra',
// 					},
// 					{
// 						slideItemElementId: 3,
// 						order: 3,
// 						type: 'description',
// 						content: 'Descripción',
// 					},
// 					{
// 						slideItemElementId: 4,
// 						order: 4,
// 						type: 'materials',
// 						content: 'Materiales',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						//estado de conservación
// 						type: 'owner_status',
// 						content: 'Conservado',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'date',
// 						content: '2022',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'measures',
// 						content: '00x00x00cm',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'price',
// 						content: '1000',
// 					},
// 					{
// 						slideItemElementId: 5,
// 						order: 5,
// 						type: 'technique',
// 						content: 'Técnica',
// 					},
// 				],
// 			},
// 		],
// 	},
// 	// slide - Artwork Group de la subcolección
// 	{
// 		portfolioId: 1,
// 		slideId: 2,
// 		order: 2,
// 		type: 'artworkIndividual',
// 		// Tiene todas las obras de la subcolección de la colección
// 		slideItems: [
// 			{
// 				slideId: 2,
// 				type: 'artwork',
// 				slideItemId: 1,
// 				order: 3,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'url',
// 						content:
// 							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
// 					},
// 					{
// 						slideItemElementId: 2,
// 						order: 2,
// 						type: 'title',
// 						content: 'Obra',
// 					},
// 					{
// 						slideItemElementId: 3,
// 						order: 3,
// 						type: 'description',
// 						content: 'Descripción',
// 					},
// 					{
// 						slideItemElementId: 4,
// 						order: 4,
// 						type: 'materials',
// 						content: 'Materiales',
// 					},
// 				],
// 			},
// 		],
// 	},
// 	//slide fullScreenImage
// 	{
// 		portfolioId: 1,
// 		slideId: 6,
// 		order: 6,
// 		type: 'fullScreenImage',
// 		slideItems: [
// 			{
// 				slideId: 2,
// 				type: 'image',
// 				slideItemId: 1,
// 				order: 3,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'url',
// 						content:
// 							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
// 					},
// 				],
// 			},
// 		],
// 	},
// 	//slide - backCover
// 	{
// 		portfolioId: 1,
// 		slideId: 7,
// 		order: 7,
// 		type: 'backCover',
// 		slideItems: [
// 			{
// 				slideId: 4,
// 				type: 'title',
// 				slideItemId: 1,
// 				order: 1,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'sub_title',
// 						// gallery/artist name
// 						content: 'Nombre artista',
// 					},
// 				],
// 			},
// 			{
// 				slideId: 4,
// 				type: 'text',
// 				slideItemId: 2,
// 				order: 2,
// 				slideItemsElements: [
// 					{
// 						slideItemElementId: 1,
// 						order: 1,
// 						type: 'text',
// 						//artist/gallery email
// 						content: 'artista@mail.com',
// 					},
// 				],
// 			},
// 		],
// 	},
// ];

const expertPortfolioDefaultSlides = [
	//cover
	{
		portfolioId: 1,
		slideId: 1,
		order: 1,
		type: 'cover',
		slideItems: [
			{
				slideId: 1,
				type: 'title',
				slideItemId: 1,
				order: 1,
				slideItemElements: [
					{
						slideItemElementId: 1,
						order: 1,
						type: 'title',
						content: 'Nombre portafolio',
					},
					{
						slideItemElementId: 2,
						order: 2,
						type: 'subtitle',
						content: 'Nombre artista',
					},
				],
			},
			{
				slideId: 1,
				type: 'text',
				slideItemId: 2,
				order: 2,
				slideItemElements: [
					{
						slideItemElementId: 3,
						order: 1,
						type: 'text',
						content:
							'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit.',
					},
				],
			},
			{
				slideId: 1,
				type: 'image',
				slideItemId: 3,
				order: 3,
				slideItemElements: [
					{
						slideItemElementId: 4,
						order: 1,
						type: 'image_url',
						content:
							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
					},
				],
			},
		],
	},
	//textBlockAndImage
	{
		portfolioId: 1,
		slideId: 2,
		order: 2,
		type: 'textBlockAndImage',
		slideItems: [
			{
				slideId: 2,
				type: 'title',
				slideItemId: 4,
				order: 1,
				slideItemElements: [
					{
						slideItemElementId: 5,
						order: 1,
						type: 'title',
						content: 'Nombre de la sección',
					},
				],
			},
			{
				slideId: 2,
				type: 'text',
				slideItemId: 5,
				order: 2,
				slideItemElements: [
					{
						slideItemElementId: 6,
						order: 2,
						type: 'text',
						content:
							'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
					},
				],
			},
			{
				slideId: 2,
				type: 'image',
				slideElementId: 6,
				order: 3,
				slideItemElements: [
					{
						slideItemElementId: 7,
						order: 3,
						type: 'image_url',
						content:
							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
					},
				],
			},
		],
	},
	//textBlock
	{
		portfolioId: 1,
		slideId: 3,
		order: 3,
		type: 'textBlock',
		slideItems: [
			{
				slideId: 3,
				type: 'title',
				slideItemId: 7,
				order: 1,
				slideItemElements: [
					{
						slideItemElementId: 8,
						order: 1,
						type: 'title',
						content: 'Nombre de la sección',
					},
				],
			},
			{
				slideId: 3,
				type: 'text',
				slideItemId: 8,
				order: 2,
				slideItemElements: [
					{
						slideItemElementId: 9,
						order: 1,
						type: 'text',
						content:
							'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
					},
				],
			},
		],
	},
	//artworkGroup
	{
		portfolioId: 1,
		slideId: 5,
		order: 5,
		type: 'artworksGroup',
		slideItems: [
			{
				slideId: 5,
				type: 'artwork',
				slideItemId: 12,
				order: 1,
				slideItemElements: [
					{
						content:
							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
						order: 1,
						slideItemElementId: 23,
						type: 'image_url',
					},
					{
						content: '',
						order: 2,
						slideItemElementId: 15,
						type: 'title',
					},
					{
						content: '',
						order: 3,
						slideItemElementId: 17,
						type: 'materials',
					},
					{
						slideItemElementId: 19,
						order: 5,
						type: 'measures',
						content: '00x00x00cm',
					},
				],
			},
			{
				slideId: 5,
				type: 'artwork',
				slideItemId: 12,
				order: 2,
				slideItemElements: [
					{
						content:
							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
						order: 1,
						slideItemElementId: 23,
						type: 'image_url',
					},
					{
						content: '',
						order: 2,
						slideItemElementId: 15,
						type: 'title',
					},
					{
						content: '',
						order: 3,
						slideItemElementId: 17,
						type: 'materials',
					},
					{
						slideItemElementId: 19,
						order: 5,
						type: 'measures',
						content: '00x00x00cm',
					},
				],
			},
		],
	},
	//artworkIndividual
	{
		portfolioId: 1,
		slideId: 5,
		order: 5,
		type: 'artworkIndividual',
		slideItems: [
			{
				slideId: 5,
				type: 'artwork',
				slideItemId: 12,
				order: 1,
				slideItemElements: [
					{
						content:
							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
						order: 1,
						slideItemElementId: 23,
						type: 'image_url',
					},
					{
						content: '',
						order: 2,
						slideItemElementId: 15,
						type: 'title',
					},
					{
						content: '',
						order: 3,
						slideItemElementId: 17,
						type: 'materials',
					},
					{
						slideItemElementId: 19,
						order: 5,
						type: 'measures',
						content: '00x00x00cm',
					},
				],
			},
		],
	},
	//fullScreenImage
	{
		portfolioId: 1,
		slideId: 6,
		order: 6,
		type: 'fullScreenImage',
		slideItems: [
			{
				slideId: 2,
				type: 'image',
				slideItemId: 1,
				order: 3,
				slideItemElements: [
					{
						slideItemElementId: 1,
						order: 1,
						type: 'image_url',
						content:
							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
					},
				],
			},
		],
	},
	//backCover
	{
		portfolioId: 1,
		slideId: 9,
		order: 9,
		type: 'backCover',
		slideItems: [
			{
				slideId: 9,
				type: 'title',
				slideItemId: 22,
				order: 1,
				slideItemElements: [
					{
						slideItemElementId: 57,
						order: 1,
						type: 'title',
						content: 'Nombre artista',
					},
					{
						slideItemElementId: 58,
						order: 1,
						type: 'subtitle',
						content: 'artista@correo.com',
					},
				],
			},
		],
	},
];

const filterPortfolioGalleryInitialValues = {
	portfolio: '',
	artist_id: '',
};

const filterPortfolioArtistInitialValues = {
	portfolio: '',
};

export {
	portfolioSingleTabs,
	portfolioSingleButtons,
	portfolioValidationSchema,
	portfolioFormValidationSchemaWithPassword,
	portfolioEditFormValidationSchema,
	expertPortfolioDefaultSlides,
	filterPortfolioGalleryInitialValues,
	filterPortfolioArtistInitialValues,
};
