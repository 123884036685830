//3rd
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//UI
import { ReactComponent as FilterIcon } from '../../../UI/assets/icons/Filter.svg';
import { ReactComponent as Cross } from '../../../UI/assets/icons/Cross.svg';
import TextMainTitle from '../Text/TextMainTitle';

/**
 * @description Renders the main structure of the filter side bar
 * @param
 * @returns
 */

const FilterSidebar = ({ showFilter, setShowFilter, children }) => {
	const { t } = useTranslation();
	return (
		<>
			<div className='cursor-pointer' onClick={() => setShowFilter(true)}>
				<FilterIcon />
			</div>
			{showFilter && (
				<div
					className='fixed right-0 top-0 z-40 flex h-full w-full flex-row justify-end'
					onClick={() => setShowFilter(false)}
				>
					<div
						className='flex h-full w-3/4 flex-col overflow-x-auto rounded-l border-l-2 border-r-gray-900 bg-white p-6 lg:w-2/5'
						onClick={(e) => e.stopPropagation()}
					>
						<div className='relative flex flex-row items-center justify-center'>
							<TextMainTitle
								title={t('Filtros')}
								textColor={'text-green-50'}
								color={'green-50'}
							/>
							<span
								className='absolute right-1 cursor-pointer'
								onClick={() => setShowFilter(false)}
							>
								<Cross />
							</span>
						</div>
						{children}
					</div>
				</div>
			)}
		</>
	);
};

FilterSidebar.propTypes = {
	/** State that determines if the sidebar should be displayed*/
	showFilter: PropTypes.bool,
	/**Function to update the boolean state that determines if the sidebar should be displayed */
	setShowFilter: PropTypes.func,
};

FilterSidebar.defaultProps = {
	showFilter: false,
};

export default FilterSidebar;
