import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// UI
import ImageAndTitle from '../Image/ImageTitleSimple';
import ButtonFabExtendedPrimary from '../Buttons/FABExtended/ButtonFabExtendedPrimary';
import { ReactComponent as Back } from '../../assets/icons/Back.svg';
import { ReactComponent as MagnifyingGlassGrey } from '../../assets/icons/MagnifyingGlassGrey.svg';
// BL
import { setSlideElement } from '../../../BusinessLogic/redux/reducers/editablePortfolio';
import { getArtworks } from '../../../BusinessLogic/redux/reducers/artwork';
import {
	completeArtworkSelected,
	textArtworkLeftSelected,
	textArtworkRightSelected,
	imageSelected,
} from '../../../BusinessLogic/data/EditablePortfolio/EditablePortfolioArtworkData';
import { onSubmitArtworkElement } from '../../../BusinessLogic/helpers/editablePortfolio';

/**
 * It renders a list of artworks, and when one is clicked, it sets it as the preSelectedArtwork, and
 * when the user clicks the button, it sends the preSelectedArtwork to the reducer
 * @returns A component
 */
const EditablePortfolioArtworkSelection = ({
	designButtonBack,
	design,
	setChooseArtwork,
	selection,
	setIndexEditablePortfolioButton,
	setShowSelectionBar,
	handleSlideEdition,
	setShowSideModal,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { artworks } = useSelector((state) => state.artworks);
	const [preSelectedArtwork, setPreSelectedArtwork] = useState();
	const [currentArtworks, setCurrentArtworks] = useState([]);
	const [filteredArtwork, setFilteredArtwork] = useState('');

	const currentFilteredArtwork = (artwork, filteredArtwork) => {
		if (filteredArtwork === '') {
			return artwork;
		} else if (
			artwork?.name.toLowerCase().includes(filteredArtwork.toLowerCase())
		) {
			return artwork;
		}
	};

	useEffect(() => {
		if (!artworks) {
			dispatch(getArtworks()).then(() => setCurrentArtworks(artworks));
		}
		if (artworks) setCurrentArtworks(artworks);
	}, [dispatch, artworks]);

	const toggleFileInPreSelectedArtwork = (artwork) => {
		if (artwork === preSelectedArtwork) {
			setPreSelectedArtwork();
		} else {
			setPreSelectedArtwork(artwork);
		}
	};

	const filteredSelection = (selection) => {
		switch (selection) {
			case 'completeArtworkSelected':
				return completeArtworkSelected;
			case 'textArtworkLeftSelected':
				return textArtworkLeftSelected;
			case 'textArtworkRightSelected':
				return textArtworkRightSelected;
			default:
				return imageSelected;
		}
	};
	const onSubmitSelection = (artwork, selection) => {
		const values = onSubmitArtworkElement(artwork, selection);
		dispatch(setSlideElement(values));
	};

	const slideElementContent = (artwork, selection) => {
		const values = onSubmitArtworkElement(artwork, selection);
		return values;
	};

	return (
		<div className={design}>
			<div className='grid place-items-center'>
				<div className=' py-4  '>
					<button
						className={designButtonBack}
						onClick={() => {
							setChooseArtwork(false);
						}}
					>
						<Back />
					</button>
					<p>{t('EscogeLaObra')}</p>
				</div>

				<div className='relative w-full'>
					<MagnifyingGlassGrey className='absolute top-6' />

					<input
						type='text'
						className=' my-6 w-full rounded py-2 pl-8 text-left'
						placeholder={'Buscar en tus obras de Datarte'}
						onChange={(e) => setFilteredArtwork(e.target.value)}
					/>
				</div>

				<div className='grid h-[40vh] w-full grid-cols-3 justify-center  gap-4 overflow-y-scroll'>
					{currentArtworks?.length > 0 ? (
						currentArtworks
							?.filter((artwork) =>
								currentFilteredArtwork(artwork, filteredArtwork)
							)
							.map((image, index) => (
								<div
									key={index}
									onClick={() => {
										toggleFileInPreSelectedArtwork(image);
									}}
								>
									<ImageAndTitle
										key={index}
										id={image.id}
										imagen={image.main_picture_url}
										title={image.name}
										fileSelectedPreview={
											preSelectedArtwork &&
											preSelectedArtwork.artwork_id === image.artwork_id
										}
									/>
								</div>
							))
					) : (
						<p>No hay obras disponibles</p>
					)}
				</div>

				<div
					className={`mt-6 flex justify-center pb-22 ${
						!preSelectedArtwork && 'hidden'
					}`}
				>
					<ButtonFabExtendedPrimary
						text={t('AgregarAPágina')}
						action={() => {
							preSelectedArtwork &&
								onSubmitSelection(
									preSelectedArtwork,
									filteredSelection(selection)
								);
							handleSlideEdition(
								slideElementContent(
									preSelectedArtwork,
									filteredSelection(selection)
								),
								'EditablePortfolioArtworkSelection'
							);

							setIndexEditablePortfolioButton(null);
							setShowSelectionBar(false);
							setShowSideModal(false);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

EditablePortfolioArtworkSelection.propTypes = {
	/** String tailwind classes to set the design for the back button */
	designButtonBack: PropTypes.string,
	/** Function to set chooseArtwork to a boolean value in the parent component */
	setChooseArtwork: PropTypes.func,
	/** String value with the name of the selection data  */
	selection: PropTypes.string,
	/**Tailwind design for the component */
	design: PropTypes.string,
	/**Function to change the index in the EditablePortfolioButton in CanvasEdition.js */
	setIndexEditablePortfolioButton: PropTypes.func,
	/**Function to change if the SelectionBar will or not be displayed */
	setShowSelectionBar: PropTypes.func,
	/**Function to dispatch the function when an slide is edited */
	handleSlideEdition: PropTypes.func,
};

EditablePortfolioArtworkSelection.defaultProps = {
	designButtonBack: 'absolute left-0',
	design: 'bg-middle_grey-50 w-[80%] md:w-[70%] relative',
};

export default EditablePortfolioArtworkSelection;
