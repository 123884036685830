/**
 * @description generate text in the section that do not have any entity created .
 * @param {string} message Can be a single word or a long message but the important is that have word that match with a section
 * @returns
 */
function textBlankEntityGenerator(message) {
	const sectionMessage = message.toLowerCase();
	let newEntityMessage = '';
	const sectionList = {
		artistas: 'ParaCrearNuevoArtista',
		obras: 'ParaCrearNuevaObra',
		documento: 'ParaCrearNuevoDocumento',
		colecciones: 'ParaCrearNuevaColección',
		sub: 'ParaCrearNuevaSubColección',
		lugares: 'ParaCrearNuevoLugar',
		salas: 'ParaCrearNuevaSala',
		ubicaciones: 'ParaCrearNuevaUbicación',
		contactos: 'ParaCrearNuevoContacto',
		exposiciones: 'ParaCrearNuevaExposición',
		portafolios: 'ParaCrearNuevoPortafolio',
		artist: 'ParaCrearNuevoArtista',
		artworks: 'ParaCrearNuevaObra',
		documents: 'ParaCrearNuevoDocumento',
		collections: 'ParaCrearNuevaColección',
		locations: 'ParaCrearNuevoLugar',
		rooms: 'ParaCrearNuevaSala',
		ubications: 'ParaCrearNuevaUbicación',
		contacts: 'ParaCrearNuevoContacto',
		expositions: 'ParaCrearNuevaExposición',
		portfolios: 'ParaCrearNuevoPortafolio',
		archivos: 'Para crear un nuevo Archivo',
		files: 'Para crear un nuevo Archivo',
	};
	const keys = Object.keys(sectionList);
	keys.forEach((section) => {
		if (sectionMessage.includes(section))
			newEntityMessage = sectionList[section];
	});
	if (message === 'Sub collections') newEntityMessage = sectionList.sub;
	return newEntityMessage;
}

export default textBlankEntityGenerator;
