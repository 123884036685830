import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// UI
import ImageFullScreen from '../../UI/molecules/Image/ImageFullScreen';
import pageNotFound from '../../UI/assets/images/pageNotFound.jpg';

const PageNotFound = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className='h-[50vh] md:hidden'>
				<LazyLoadImage
					src={pageNotFound}
					width='100%'
					height='100%'
					effect='blur'
					wrapperClassName={` overflow-hidden flex items-center lazyLoadingImages`}
					delayTime='0'
					threshold='800'
					style={{ objectFit: 'cover' }}
				/>
			</div>
			<div className='flex flex-row md:h-[100vh]'>
				<ImageFullScreen
					image={pageNotFound}
					alt='Datarte Login Screen'
					className='hidden md:block md:w-1/2 lg:w-3/5'
				/>
				<div className=' flex flex-col justify-center '>
					<div className='mx-[5%] py-8 md:mx-[25%] '>
						<h2 className='text-[64px] font-[700] text-green-50'>Oops! </h2>
						<p className='text-[16px] font-[300] text-green-50'>
							{t('estaPáginaNoEstáDisponible')}
						</p>
						<h1 className='text-[128px] font-[700] text-green-50'>404 </h1>
						<p className='text-[16px] font-[300] text-green-50'>
							{t('LoSentimosEstaPáginaNoEstáDisponible')}
						</p>
						<p className='pt-8 text-[14px] font-[700] text-green-50'>
							{t('VolverA')}
							<Link to={'/'}>
								<span className='underline'> app.datarte.art </span>
							</Link>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default PageNotFound;
