export const homepageOrganizationsItemsContent = [
	'CrearConvocatoriasPersonalizadas',
	'AdaptarLaConvocatoriaATusNecesidades',
	'SolicitarPerfilDelArtistaDeAcuerdoConNecesidadesDeLaConvocatoria',
	'EscogerSiElArtistaPostulaPortafolioOCatálogo',
	'SolicitarDocumentosAdjuntosDelArtista',
	'CrearFormulariosPersonalizados',
	'HacerModificacionesYSeguimiento',
	'RevisarLosPerfilesPostulados',
	'EnvíarNotificacionesDeManeraAutomatizada',
];
