//3rd party components
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPlayer from 'react-player';

//BL

//UI
import 'react-lazy-load-image-component/src/effects/blur.css';
import TextContentThird from '../../atoms/Text/Content/TextContentThird';
import { Link } from 'react-router-dom';
import { ReactComponent as CheckIcon } from '../../assets/icons/Check.svg';
import { ReactComponent as RedCross } from '../../assets/icons/crossInRedCircle.svg';

/**
 * @description Molecule that shows the simplest image and a title
 * @changes
 * @param {*} param0
 * @returns
 */

export default function ImageAndTitle({
	id,
	imagen,
	title,
	redirect,
	imageFormat,
	objectFitProp,
	roundedBorder,
	aspectRatioProp,
	fileSelectedPreview,
	addCheckToImage,
	showDownloadOptions,
	showDeleteOption,
	deleteDocumentButton,
	isVideoImage,
}) {
	let image_format;
	if (imageFormat === 'small') {
		image_format = '_medium';
	} else {
		image_format = '';
	}

	let redirectPath = redirect ? `/${redirect}/${id}` : '';

	let defaultImage =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';

	const [imageActive, setImageActive] = useState(false);

	const toggleDownloadableDocument = () => {
		setImageActive(!imageActive);
		addCheckToImage(imagen, imageActive);
	};

	useEffect(() => {
		setImageActive(false);
	}, [showDownloadOptions]);
	return (
		<div className='relative'>
			<div className='relative flex flex-col items-center'>
				<div
					className={`w-full ${
						fileSelectedPreview ? 'border-4 border-solid border-yellow-50' : ''
					}`}
				>
					<Link
						className='relative h-full w-full'
						to={
							showDownloadOptions || showDeleteOption
								? { pathname: '' }
								: { pathname: redirectPath }
						}
					>
						{isVideoImage ? (
							<ReactPlayer
								url={imagen}
								width={'100%'}
								height={'100%'}
								controls={false}
								light={true}
								className='pointer-events-none aspect-square'
							/>
						) : (
							<LazyLoadImage
								alt={title}
								src={imagen ? imagen + image_format : defaultImage}
								width='100%'
								height='100%'
								effect='blur'
								wrapperClassName={`overflow-hidden flex items-center lazyLoadingImages`}
								delayTime='0'
								threshold='800'
								style={{
									objectFit: objectFitProp,
									aspectRatio: aspectRatioProp,
									borderRadius: roundedBorder,
								}}
							/>
						)}
						{showDownloadOptions && (
							<div
								onClick={() => toggleDownloadableDocument()}
								className=' absolute right-0 top-0 flex h-full w-full  cursor-pointer select-none justify-end p-2 '
							>
								<div
									className={` m-2 flex h-1/6 w-1/6 justify-center rounded-full border-2 ${
										imageActive && 'bg-white'
									}`}
								>
									{imageActive && showDownloadOptions && (
										<p className='flex h-full w-full place-content-center rounded-full text-white'>
											<CheckIcon className='h-full w-full' />
										</p>
									)}
								</div>
							</div>
						)}
					</Link>
				</div>
				{title && (
					<Link
						to={{
							pathname: `${redirect ? `/${redirect}/${id}` : ''}`,
						}}
					>
						<div className='mb-4 mt-2 flex w-full flex-row items-center justify-between'>
							{title && (
								<TextContentThird
									design='pt-2 pr-1 text-grey-50 text-left line-clamp-2 words-break'
									text={title}
								/>
							)}
						</div>
					</Link>
				)}
				{showDeleteOption && (
					<div
						onClick={() => deleteDocumentButton(id)}
						className=' absolute right-0 top-0 flex h-full w-full cursor-pointer select-none justify-end'
					>
						<div className={`m-2 flex h-1/6 w-1/6 justify-center`}>
							<RedCross className='h-full w-full' />
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

ImageAndTitle.propTypes = {
	/** Id of the image. This id is used for navigation */
	id: PropTypes.number,
	/** URL link to the image */
	imagen: PropTypes.string,
	/** Title on the card */
	title: PropTypes.string,
	/** String for the redirect*/
	redirect: PropTypes.string,
	/** Manages the format of the document */
	imageFormat: PropTypes.string,
	/* string that defines the object fit css property */
	objectFitProp: PropTypes.string,
	/* string that defines the rounder border css property */
	roundedBorder: PropTypes.string,
	/**  bool that handle the style if the file is selected */
	fileSelectedPreview: PropTypes.bool,
	/*function that sends the image id that will be deleted */
	DeleteImageButton: PropTypes.func,
	/*function that sends the document id that will be deleted */
	deleteDocumentButton: PropTypes.func,
};

ImageAndTitle.defaultProps = {
	imageFormat: 'small',
	objectFitProp: 'fill',
	roundedBorder: '0px',
	aspectRatioProp: '1/1',
	fileSelectedPreview: false,
};
