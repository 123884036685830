// 3rd party
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// BL

// UI
import { ShareSection } from '../../../UI/organisms/Share/ShareSection';
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import TextContentThird from '../../../UI/atoms/Text/Content/TextContentThird';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';

/**
 * @description render a modal share component
 * @param {*} param0
 * @returns
 */

const SharePortfolio = ({
	urlToShare,
	title,
	share,
	setShare,
	designOne,
	designTwo,
}) => {
	const [isCopied, setIsCopied] = useState(false);
	useEffect(() => {
		document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	}, []);

	const handleCopyUrl = () => {
		navigator.clipboard
			.writeText(urlToShare ?? window.location.href)
			.then(() => {
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 2000);
			});
	};

	return (
		<div
			className='fixed inset-0 z-10 overflow-y-auto'
			aria-labelledby='modal-title'
			role='dialog'
			aria-modal='true'
		>
			<div
				className={`flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0`}
			>
				<div className={designTwo} aria-hidden='true'></div>

				<span
					className={`hidden sm:inline-block sm:h-screen sm:align-middle`}
					aria-hidden='true'
				>
					&#8203;
				</span>

				<div className={designOne}>
					<div className='rounded bg-white px-4 pt-5 pb-4 shadow-lg sm:p-6 sm:pb-4 '>
						<div className='sm:flex sm:items-start'>
							<div className='flex w-80 flex-col justify-between py-2'>
								<TextTitleSub design='self-start mb-4' title={title} />
								<div>
									<div onClick={handleCopyUrl}>
										<TextContentThird
											design='font-normal self-start text-green-50 my-0.5 transition-all'
											text={isCopied ? 'Link copiado' : 'Haz clic para copiar'}
											style={{ fontWeight: 700 }}
										/>
									</div>
									<input
										id='copyText'
										className='body-Text3 w-full cursor-pointer rounded border border-transparent bg-middle_grey-50 py-1 pl-1 caret-transparent'
										type='text'
										value={urlToShare ?? window.location.href}
										onClick={handleCopyUrl}
										readOnly
									/>
								</div>
								<TextContentSecond
									design='self-start mb-4'
									text='Compartir en:'
									style={{ fontWeight: 700 }}
								/>
								<ShareSection url={urlToShare ?? window.location.href} />
								<ButtonFab
									label='Cerrar'
									design='flex justify-center mt-4'
									action={() => setShare(!share)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

SharePortfolio.propTypes = {
	/* activate or deactivate the modal */
	share: PropTypes.bool,
	/* change the state of share */
	setShare: PropTypes.func,
	/* main title of the modal */
	title: PropTypes.string,
	/*  boolean prop to set a second style */
	designOne: PropTypes.string,

	designTwo: PropTypes.string,
};

SharePortfolio.defaultProps = {
	title: 'Comparte tu portafolio',
	designOne:
		'absolute top-36 lg:static lg:top-0 inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-80',
	designTwo: 'fixed inset-0 bg-grey-50 bg-opacity-75 transition-opacity',
};

export default SharePortfolio;
