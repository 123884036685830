import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { paymentsOptions } from '../../../BusinessLogic/data/paymentsOptions';

import TemplateWithNavBar from '../../templates/TemplateWithNavBar';
import SecondaryNavbarSpecific from '../Navbar/NavbarSecondarySpecific';
import PaymentCard from './PaymentCard';

const AdminPayments = () => {
	const { t } = useTranslation();
	const { appUser } = useSelector((state) => state.appUser);

	const userRole = appUser.role_company;
	const isArtistUser = userRole === 'ARTIST';
	const allPayments = [
		...paymentsOptions.artist.monthly,
		...paymentsOptions.artist.annual,
	];

	if (!isArtistUser) {
		return <Navigate to='/' />;
	}

	return (
		<TemplateWithNavBar>
			<SecondaryNavbarSpecific
				title={t('EligeTuPlan')}
				textColor='text-green-50'
				color='bg-green-50'
				hide='invisible'
				textBack={t('RegresarConfiguracion')}
				arrowLeftColor='green'
				textBackDesign='pl-3 text-green-50'
			/>
			<div className='mx-auto flex w-full max-w-6xl flex-col justify-center gap-5 pb-8 text-center md:flex-row'>
				{allPayments.map((payment, index) => (
					<PaymentCard key={index} className='flex-1' {...payment} />
				))}
			</div>
		</TemplateWithNavBar>
	);
};

export default AdminPayments;
