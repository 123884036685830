import React from 'react';
import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns';

const CalendarCell = ({
	children,
	cellDesign,
	onClick,
	fullDate,
	calendarEvents,
	typeOfContent,
}) => {
	const spanDesign = 'w-[5px] h-[5px] inline-block rounded-full';
	const isCalendarEventsAnArray = Array.isArray(calendarEvents);

	const handleOnclickCalendarCell = () => {
		if (!calendarEvents) {
			return;
		}
		const targetEvent = calendarEvents.find((event) => {
			const eventDate = new Date(event.date_event);
			const formattedDate =
				isValid(eventDate) && format(eventDate, 'MM/yyyy/dd');

			return formattedDate === fullDate;
		});

		if (!targetEvent) {
			return;
		}

		const target = targetEvent.link.includes('app.datarte.art')
			? '_self'
			: '_blank';

		window.open(targetEvent.link, target);
	};

	const handleSwitchEventType = (calendarEvent) => {
		switch (calendarEvent.calendar_event_type_id) {
			case 1:
				return <span className={`${spanDesign} bg-yellow-50`} />;
			case 2:
				return <span className={`${spanDesign} bg-green-50`} />;
			case 3:
				return <span className={`${spanDesign}  bg-purple-50 `} />;
			case 4:
				return <span className={`${spanDesign}  bg-light_green-50`} />;
			default:
				return <div></div>;
		}
	};

	const handleCalendarDateColor = (calendarEvents) => {
		if (isCalendarEventsAnArray) {
			const eventsInDateArray = calendarEvents?.filter((event) => {
				const eventDate = new Date(event.date_event);
				if (
					isValid(eventDate) &&
					format(eventDate, 'MM/yyyy/dd') === fullDate
				) {
					return event;
				}
				return false;
			});

			const eventsInDateArraySorted = eventsInDateArray?.sort(
				(a, b) => a.calendar_event_type_id - b.calendar_event_type_id
			);

			if (eventsInDateArraySorted) {
				switch (eventsInDateArraySorted[0]?.calendar_event_type_id) {
					case 1:
						return 'bg-yellow-50 cursor-pointer transition-all duration-300 hover:scale-110';
					case 2:
						return 'bg-green-50 cursor-pointer transition-all duration-300 hover:scale-110';
					case 3:
						return ' bg-purple-50 cursor-pointer transition-all duration-300 hover:scale-110';
					case 4:
						return ' bg-light_green-50 cursor-pointer transition-all duration-300 hover:scale-110';
					default:
						return '';
				}
			}
		}
	};
	return (
		<>
			<div
				className={`relative flex flex-col items-center justify-center p-2 ${cellDesign} `}
				onClick={onClick}
			>
				<div
					onClick={() => handleOnclickCalendarCell()}
					className={
						typeOfContent === 'number' &&
						`inline-block h-8 w-8  rounded-full p-2 ${handleCalendarDateColor(
							calendarEvents
						)}`
					}
				>
					{children}
				</div>
				<div className='absolute -bottom-1 flex w-full items-center justify-center'>
					{isCalendarEventsAnArray &&
						calendarEvents.map((calendarEvent, index) => {
							const eventDate = new Date(calendarEvent.date_event);

							const calendarEventDate =
								isValid(eventDate) && format(eventDate, 'MM/yyyy/dd');

							return (
								<div key={index}>
									{calendarEventDate === fullDate && (
										<div className='px-[1px]'>
											{handleSwitchEventType(calendarEvent)}
										</div>
									)}
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
};

CalendarCell.propTypes = {
	/**String prop with the tailwind classes for the container design */
	cellDesign: PropTypes.string,
	/**Prop with the children for the component */
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.object,
	]),
	/** Function prop for the onClick event */
	onClick: PropTypes.func,
	/**String prop with the full date in the component MM/yyyy/dd*/
	fullDate: PropTypes.string,
	/**Array prop with the calendar events*/
	calendarEvents: PropTypes.array,
	/**String prop with the type of the children for calendar cell*/
	typeOfContent: PropTypes.string,
};

export default CalendarCell;
