import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//BL
import {
	putPortfolioSlide,
	getPortfolioToEdit,
	updateSlideElement,
} from '../../../BusinessLogic/redux/reducers/editablePortfolio';
import { EditablePortfolioButtons } from '../../../BusinessLogic/data/EditablePortfolio/EditablePortfolioArtworkData';
// UI
import ButtonFabSecondary from '../../atoms/Buttons/FAB/ButtonFabSecondary';
import EditablePortfolioArtwork from '../Portfolio/EditablePortfolioArtwork';
import EditablePortfolioSlide from '../Portfolio/EditablePortfolioSlide';
import EditablePortfolioText from '../Portfolio/EditablePortfolioText';
import AddWhite from '../../../UI/assets/icons/AddWhite.svg';
import DeleteCanGrey from '../../../UI/assets/icons/DeleteCanGrey.svg';
import { ReactComponent as PageGrey } from '../../../UI/assets/icons/PageGrey.svg';
import { ReactComponent as DeleteCan } from '../../../UI/assets/icons/DeleteCanGrey.svg';
import SelectionBarEditablePortfolioButtons from '../../molecules/Portfolio/SelectionBarEditablePortfolioButtons';
import EditablePortfolioLateralBar from '../../molecules/Portfolio/LayoutComponents/EditablePortfolioLateralBar';

const CanvasEdition = ({ isMobile, setShowSpecificView, portfolioData }) => {
	const dispatch = useDispatch();

	const {
		idOfPortfolioToEdit,
		idOfPortfolioSlideToEdit,
		editablePortfolioSlide,
		singleEditablePortfolio,
	} = useSelector((state) => state.editablePortfolio);

	const [indexEditablePortfolioButton, setIndexEditablePortfolioButton] =
		useState(null);

	const [showSelectionBar, setShowSelectionBar] = useState(false);
	const [showSideModal, setShowSideModal] = useState(false);
	const [selectedElementByIndex, setSelectedElementByIndex] = useState(null);
	const [valuesSlideToEdit, setValuesSlideToEdit] = useState({});
	const contentSlideToEdit = editablePortfolioSlide.content;

	useEffect(() => {
		setValuesSlideToEdit({
			id: idOfPortfolioSlideToEdit,
			values: {
				portfolio_id: idOfPortfolioToEdit,
				order: '',
				content: editablePortfolioSlide.content,
			},
		});
	}, [
		singleEditablePortfolio,
		editablePortfolioSlide.content,
		idOfPortfolioSlideToEdit,
		idOfPortfolioToEdit,
	]);

	const handleSlideEdition = (data, section) => {
		if (section === 'EditablePortfolioArtworkSelection') {
			valuesSlideToEdit.values = {
				...valuesSlideToEdit.values,
				content: [...valuesSlideToEdit.values.content, data],
			};
		} else if (section === 'Canvas') {
			valuesSlideToEdit.values = { ...valuesSlideToEdit.values, content: data };
		}
		return dispatch(putPortfolioSlide(valuesSlideToEdit)).then((res) => {
			dispatch(getPortfolioToEdit(idOfPortfolioToEdit));
		});
	};

	const deleteElementByIndex = (elementIndex) => {
		if (!elementIndex && elementIndex !== 0) return;
		let updatedContentOfSlide = editablePortfolioSlide.content.filter(
			(element, index) => index !== elementIndex
		);
		dispatch(updateSlideElement(updatedContentOfSlide));
	};

	const renderSwitch = () => {
		switch (indexEditablePortfolioButton) {
			case 0:
				return (
					<EditablePortfolioText
						setIndexEditablePortfolioButton={setIndexEditablePortfolioButton}
						setShowSelectionBar={setShowSelectionBar}
						setShowSideModal={setShowSideModal}
					/>
				);
			case 1:
				return (
					<EditablePortfolioArtwork
						setIndexEditablePortfolioButton={setIndexEditablePortfolioButton}
						setShowSelectionBar={setShowSelectionBar}
						setShowSideModal={setShowSideModal}
						handleSlideEdition={handleSlideEdition}
					/>
				);

			default:
				break;
		}
	};

	const numberOfSlides = portfolioData?.slides?.length;

	return (
		<>
			{indexEditablePortfolioButton || indexEditablePortfolioButton === 0 ? (
				<>
					{isMobile ? (
						<>
							<div className='flex items-center justify-center'>
								<div
									className={`mt-5 mb-1 h-[11px] w-[67px] cursor-pointer rounded bg-middle_grey-50`}
									onClick={() => {
										setShowSelectionBar(!showSelectionBar);
										setIndexEditablePortfolioButton();
									}}
								/>
							</div>
							<div className='h-[calc(100vh-116px)]'>{renderSwitch()}</div>
							<div className='absolute inset-0 -z-10'>
								<EditablePortfolioSlide
									setSelectedElementByIndex={setSelectedElementByIndex}
									selectedElementByIndex={selectedElementByIndex}
									contentSlideToEdit={contentSlideToEdit}
								/>
							</div>
						</>
					) : (
						<div className='z-40'>
							<EditablePortfolioSlide
								setSelectedElementByIndex={setSelectedElementByIndex}
								selectedElementByIndex={selectedElementByIndex}
								contentSlideToEdit={contentSlideToEdit}
							/>
						</div>
					)}
				</>
			) : (
				<div>
					<EditablePortfolioSlide
						setSelectedElementByIndex={setSelectedElementByIndex}
						selectedElementByIndex={selectedElementByIndex}
						contentSlideToEdit={contentSlideToEdit}
					/>
				</div>
			)}

			{isMobile && !showSelectionBar && (
				<div>
					<ButtonFabSecondary
						icon={AddWhite}
						design={
							'fixed bottom-4 left-6 hover:scale-105 transition-transform'
						}
						buttonDesign={'shadow-md'}
						action={() => setShowSelectionBar(true)}
					/>
					<ButtonFabSecondary
						icon={DeleteCanGrey}
						design={
							'fixed bottom-4 left-20 hover:scale-105 transition-transform'
						}
						buttonDesign={'shadow-md bg-white'}
						action={() => deleteElementByIndex(selectedElementByIndex)}
					/>

					<div
						className='fixed bottom-4 right-6 cursor-pointer rounded-full bg-white p-2 shadow-md transition-transform hover:scale-105'
						onClick={() => {
							setShowSpecificView('pageSelection');
							handleSlideEdition(editablePortfolioSlide.content, 'Canvas');
						}}
					>
						<PageGrey className='mx-auto h-8 w-8' />
						<div className='absolute top-[18px] left-[30%] h-4 w-4 text-center'>
							<p className=' text-xs text-grey-50'>{numberOfSlides}</p>
						</div>
					</div>
				</div>
			)}
			{!isMobile && !showSelectionBar && (
				<div className='fixed bottom-0 z-20 flex w-full cursor-pointer flex-row gap-8 lg:justify-end lg:bg-white '>
					<DeleteCan
						className='my-auto h-8 w-8 transition-transform hover:scale-105'
						onClick={() => deleteElementByIndex(selectedElementByIndex)}
					/>
					<div
						className=' relative m-1 flex flex-col p-1 transition-transform hover:scale-105'
						onClick={() => {
							setShowSpecificView('pageSelection');
							handleSlideEdition(editablePortfolioSlide.content, 'Canvas');
						}}
					>
						<PageGrey className='mx-auto h-8 w-8' />
						<div className='absolute top-[14px] left-[25%] h-4 w-4 text-center'>
							<p className=' text-xs text-grey-50'>{numberOfSlides}</p>
						</div>
					</div>
				</div>
			)}
			{!isMobile && (
				<EditablePortfolioLateralBar
					buttonList={EditablePortfolioButtons}
					indexInfo={indexEditablePortfolioButton}
					setIndexInfo={setIndexEditablePortfolioButton}
					showSideModal={showSideModal}
					setShowSideModal={setShowSideModal}
				>
					{renderSwitch()}
				</EditablePortfolioLateralBar>
			)}
			{showSelectionBar && isMobile && (
				<div className='fixed bottom-0 left-0 w-full'>
					<SelectionBarEditablePortfolioButtons
						buttonList={EditablePortfolioButtons}
						indexEditablePortfolioButton={indexEditablePortfolioButton}
						setIndexEditablePortfolioButton={setIndexEditablePortfolioButton}
					/>
				</div>
			)}
		</>
	);
};

export default CanvasEdition;
