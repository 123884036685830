// 3rd party
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//BL
import { transformInfoIntoInitialValues } from '../../../BusinessLogic/helpers/FormHelper';

// UI
import SectionTitleAndFields from '../Section/SectionTitleAndFields';
import SectionEditDocument from '../Section/SectionEditDocument';

/**
 * @description renders a Document view. And it's dynamic.
 * @param {*} param0
 * @returns
 */

const ViewDocument = ({
	indexInfo,
	information,
	sectionTitle,
	colorHr,
	onSubmitAction,
	id,
}) => {
	const [turnEdit, setTurnEdit] = useState(false);
	useEffect(() => {
		setTurnEdit(false);
	}, [indexInfo]);

	return (
		<div className='flex flex-col bg-light_grey-50'>
			{turnEdit ? (
				<SectionEditDocument
					colorHr={colorHr}
					sectionTitle={sectionTitle}
					informationArray={information}
					initialValues={transformInfoIntoInitialValues(information)}
					setTurnEdit={setTurnEdit}
					turnEdit={turnEdit}
					onSubmitAction={onSubmitAction}
					id={id}
				/>
			) : (
				<SectionTitleAndFields
					colorHr={colorHr}
					sectionTitle={sectionTitle}
					information={information}
					setTurnEdit={setTurnEdit}
					turnEdit={turnEdit}
				/>
			)}
		</div>
	);
};

export default ViewDocument;

ViewDocument.propTypes = {
	/* string with the text of the section*/
	information: PropTypes.string,
	/* number that representes the tab that is displayed */
	indexInfo: PropTypes.number,
	/* title of the section with information */
	sectionTitle: PropTypes.string,
	/* string with the color of the hr */
	colorHr: PropTypes.string,
	/* ValidationSchema used to manage error messages */
	validationSchema: PropTypes.object,
	/* id of the document in which we visualize*/
	id: PropTypes.number,
};
