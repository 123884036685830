// 3rd Party
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// UI
import HorizontalImageWithTitle from '../../molecules/Image/HorizontalImageWithTitle';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

const ImageGridOneToThree = ({ gridImages, displayPortfolioOptions }) => {
	const { t } = useTranslation();

	if (Array.isArray(gridImages) && gridImages.length === 0) {
		return (
			<TextContentSecond
				text={t('NoHayResultados')}
				design='text-grey-50 mt-4'
			/>
		);
	}

	return (
		<div
			className={
				'mx-auto grid w-full grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3'
			}
			data-test={'images-grid'}
		>
			{!gridImages?.message &&
				gridImages?.map((image) => (
					<div key={image.id}>
						{image.is_general_portfolio && (
							<TextContentSecond
								text={t('AutomáticamenteGenerado')}
								design={`text-green-50 mb-[8px] h-[16px] font-semibold`}
							/>
						)}
						<HorizontalImageWithTitle
							elementData={image}
							displayPortfolioOptions={displayPortfolioOptions}
							designContainer={`${!image.is_general_portfolio && 'mt-[24px]'}`}
							designImage={`${image.is_general_portfolio && 'object-fill'}`}
						/>
						{image.is_general_portfolio && (
							<TextContentSecond
								text={t('CreamosEstePortafolioGeneral')}
								design='text-grey-50 mt-4'
							/>
						)}
					</div>
				))}
		</div>
	);
};

export default ImageGridOneToThree;

ImageGridOneToThree.propTypes = {
	// Array of Images
	gridImages: PropTypes.array,
	// Bool to determine whether the component displays the portfolios options
	displayPortfolioOptions: PropTypes.bool,
};
