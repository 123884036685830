//3rd party
import React from 'react';
import PropTypes from 'prop-types';

//UI
import TextContentSecond from '../../../atoms/Text/Content/TextContentSecond';

/**
 * @description a button with an text and icon.
 * @returns
 */
function ButtonWithTextIcon({
	design,
	text,
	action,
	icon,
	contentDesign,
	iconDesign,
	textStyle,
}) {
	return (
		<button
			className={`${design} cursor-pointer`}
			onClick={action}
			type='button'
		>
			<div className={`${contentDesign} flex flex-row items-center`}>
				<TextContentSecond text={text} style={textStyle} />
				<div className={iconDesign}>{icon}</div>
			</div>
		</button>
	);
}

ButtonWithTextIcon.propTypes = {
	/** Icon that appears in the doc */
	icon: PropTypes.object,
	/** Design of the container */
	design: PropTypes.string,
	/* function to trigger when button is clicked */
	action: PropTypes.func,
	/* text to show before the icon */
	text: PropTypes.string,
	/** styles of the content */
	contentDesign: PropTypes.string,
	/** styles of the icon container */
	iconDesign: PropTypes.string,
	/** Style of the text */
	textStyle: PropTypes.object,
};

ButtonWithTextIcon.defaultProps = {
	contentDesign: 'p-4',
	design: 'rounded mx-auto',
	iconDesign: 'pl-1',
};

export default ButtonWithTextIcon;
