//3rd party components
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//BL
import { saveSectionURL } from '../../../BusinessLogic/redux/reducers/navigation';

//UI
import TextContentThird from '../../atoms/Text/Content/TextContentThird';
import { ReactComponent as CheckIcon } from '../../assets/icons/Check.svg';
import { ReactComponent as RedCross } from '../../assets/icons/crossInRedCircle.svg';

/**
 * @description renders a document with a title below
 * @param {*} param0
 * @returns
 */
function DocumentAndTitle({
	id,
	pdf,
	title,
	redirect,
	isPublic,
	documentHeight,
	documentWidth,
	saveLastSectionURL,
	fileSelectedPreview,
	addCheckToDocument,
	deleteDocumentButton,
	showDownloadOptions,
	showDeleteOption,
}) {
	const [documentActive, setDocumentActive] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();

	let redirectPath = redirect ? `/${redirect}/${id}` : '';

	const toggleDownloadableDocument = () => {
		setDocumentActive(!documentActive);
		addCheckToDocument(pdf, documentActive);
	};

	useEffect(() => {
		setDocumentActive(false);
	}, [showDownloadOptions]);

	return (
		<div
			onClick={() =>
				saveLastSectionURL && dispatch(saveSectionURL(location.pathname))
			}
		>
			<Link
				to={
					showDownloadOptions || showDeleteOption
						? { pathname: '' }
						: { pathname: redirectPath }
				}
			>
				<div className='relative flex max-w-xs flex-col items-center'>
					<div
						className={`flex justify-center overflow-y-hidden shadow ${
							fileSelectedPreview
								? ' border-4 border-solid border-yellow-50'
								: ''
						}`}
						style={{ height: `${documentHeight}px` }}
					>
						<Document file={pdf}>
							<Page
								pageNumber={1}
								width={documentWidth}
								className='flex rounded-xl'
								renderMode='svg'
							/>
						</Document>
					</div>
					{showDownloadOptions && (
						<div
							onClick={() => toggleDownloadableDocument()}
							className=' absolute top-0 right-0 flex h-full w-full  cursor-pointer select-none justify-end p-2 '
						>
							<div
								className={` m-2 flex h-1/6 w-1/6 justify-center rounded-full border-2 ${
									documentActive && 'bg-white'
								}`}
							>
								{documentActive && showDownloadOptions && (
									<p className='flex h-full w-full place-content-center rounded-full text-white'>
										<CheckIcon className='h-full w-full' />
									</p>
								)}
							</div>
						</div>
					)}
					{showDeleteOption && (
						<div
							onClick={() => deleteDocumentButton(id)}
							className=' absolute top-0 right-0 flex h-full w-full cursor-pointer select-none justify-end'
						>
							<div className={`m-2 flex h-1/6 w-1/6 justify-center`}>
								<RedCross className='h-full w-full' />
							</div>
						</div>
					)}
				</div>
				{title && (
					<TextContentThird
						design='pt-2 pr-1 text-grey-50 text-left line-clamp-2 words-break'
						text={title}
					/>
				)}
			</Link>
		</div>
	);
}

DocumentAndTitle.propTypes = {
	/* id of the document */
	id: PropTypes.number,
	/* string url of the pdf location */
	pdf: PropTypes.string,
	/* document name identification */
	title: PropTypes.string,
	/* url redirect to specific document */
	redirect: PropTypes.string,
	/** Checks if the element is public*/
	isPublic: PropTypes.string,
	/* handles a dynamic width of the document */
	documentWidth: PropTypes.string,
	/* handles a dynamic height of the document */
	documentHeight: PropTypes.string,
	/**  bool that handle to save the actual specific location */
	saveLastSectionURL: PropTypes.bool,
	/**  bool that handle the style if the file is selected */
	fileSelectedPreview: PropTypes.bool,
	/*function that sends the document id that will be deleted */
	deleteDocumentButton: PropTypes.func,
	/*function that when the document is clicked add a check icon in the download circle */
	addCheckToDocument: PropTypes.func,
	/* bool used to display a small circle on top left of the image that indicates if the image will be downloaded */
	showDownloadOptions: PropTypes.bool,
};

DocumentAndTitle.defaultProps = {
	width: '192',
	documentHeight: '112',
	saveLastSectionURL: false,
	fileSelectedPreview: false,
};

export default DocumentAndTitle;
