//3rd
import { PropTypes } from 'prop-types';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move';
import { t } from 'i18next';

//BL

const SortableGallery = ({
	gridDesign,
	setSortableImages,
	sortableImages,
	setSortableArtwork,
	sortableArtwork,
	setSortableSubCollections,
	sortableSubCollections,
	section,
	sectionTitle,
}) => {

	const onSortEnd = (oldIndex, newIndex) => {
		if (section === 'Collection') {
			if (sectionTitle === t('Subcolecciones')) {
				setSortableSubCollections((array) =>
					arrayMove(array, oldIndex, newIndex)?.map((artwork, index) => {
						return { ...artwork, order: index + 1 };
					})
				);
			} else {
				setSortableArtwork((array) =>
					arrayMove(array, oldIndex, newIndex)?.map((artwork, index) => {
						return { ...artwork, order: index + 1 };
					})
				);
			}
		} else {
			setSortableImages((array) => arrayMove(array, oldIndex, newIndex));
		}
	};

	return (
		<SortableList
			onSortEnd={onSortEnd}
			draggedItemClassName='dragged'
			className={`${gridDesign} select-none`}
		>
			{section === 'Collection'
				? sectionTitle === t('Subcolecciones')
					? sortableSubCollections.map((subcollection) => (
							<SortableItem key={subcollection.id}>
								<img
									src={subcollection.main_picture_url}
									alt={subcollection.id}
									style={{ aspectRatio: '1/1' }}
									className='border-2 border-dashed border-gray-500'
								/>
							</SortableItem>
					  ))
					: sortableArtwork.map((artwork) => (
							<SortableItem key={artwork.id}>
								<img
									src={artwork.main_picture_url}
									alt={artwork.id}
									style={{ aspectRatio: '1/1' }}
									className='border-2 border-dashed border-gray-500'
								/>
							</SortableItem>
					  ))
				: sortableImages.map((image) => (
						<SortableItem key={image.id}>
							<img
								src={image.main_picture_url}
								alt={image.id}
								style={{ aspectRatio: '1/1' }}
								className='border-2 border-dashed border-gray-500'
							/>
						</SortableItem>
				  ))}
		</SortableList>
	);
};

export default SortableGallery;

SortableGallery.propTypes = {
	/* tailwind classnames for the grid design */
	gridDesign: PropTypes.string,
	/* function that set the sortable images variable*/
	setSortableImages: PropTypes.func,
	/** sortable images used in the grid  */
	sortableImages: PropTypes.array,
	/* function that set the sortable artworks variable*/
	setSortableArtwork: PropTypes.func,
	/** sortable artworks used in the grid  */
	sortableArtwork: PropTypes.array,
	/* title of the section */
	sectionTitle: PropTypes.string,
	/* function that set the sortable subcollections variable*/
	setSortableSubCollections: PropTypes.func,
};
