//3rd party
import PropTypes from 'prop-types';

/* UI */
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import ImageAndTitlePlantilla from '../../molecules/Image/ImageAndTitlePlantilla';
import ImageListContent from '../../molecules/Image/ImageListContent';

/**
 * @description Shows images when you pass a data array.
 * Can swap the images to squares or lines wiht the ChangeView attribute
 * @returns
 */
export default function ImagesSectionPlantillas({
	data,
	redirect,
	isPublic,
	changeView,
}) {
	return (
		<div className='mx-auto w-[95%] md:w-[90%]'>
			{data[0]['id'] === undefined ? (
				<div className='flex justify-center'>
					<TextContentSecond text='No hay información disponible' />
				</div>
			) : (
				<div
					className={
						changeView
							? 'flex flex-col  justify-center'
							: 'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6'
					}
				>
					{data.map((collection, index) =>
						changeView ? (
							<ImageListContent
								key={collection.id}
								id={collection.id}
								imagen={collection.main_picture_url}
								title={collection.name}
								redirect={redirect}
							/>
						) : (
							<ImageAndTitlePlantilla
								key={collection.id}
								id={collection.id}
								imagen={collection.main_picture_url}
								title={collection.name}
								redirect={redirect}
								isPublic={isPublic}
							/>
						)
					)}
				</div>
			)}
		</div>
	);
}

ImagesSectionPlantillas.propTypes = {
	/** Has the data to show the images */
	data: PropTypes.array,
	/** URL link to redirect to the image */
	redirect: PropTypes.string,
	/** Check if the item is public */
	isPublic: PropTypes.bool,
	/** False see the images in squares. True see the images in lines*/
	changeView: PropTypes.bool,
};

ImagesSectionPlantillas.defaultProps = {
	changeView: false,
};
