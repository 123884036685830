import { ENDPOINT_FILES } from '../helpers/routes';
import { submitErrorToSlack } from '../helpers/SlackIntegrations';

export const fetchPostFile = async (values) => {
	const { token } = localStorage;
	let endpoint = '';
	let form = new FormData();
	form.append('document', values.document);
	form.append('organization_user_id', values.organization_user_id);
	form.append('name_image', values.name);
	form.append('description', values.name);
	form.append('type_id', values.type_id);
	form.append('type', values.type);
	form.append('file_type', values.file_type);
	return fetch(ENDPOINT_FILES.POST_FILE_PDF, {
		method: 'POST',
		body: form,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then(async (res) => {
			const response = await res.json();
			endpoint = res.url;
			if (res.status >= 500) {
				submitErrorToSlack(endpoint, response, 'POST');
			}
			return response;
		})
		.catch((error) => {
			if (error.response.status >= 500) {
				submitErrorToSlack(endpoint, error, 'GET');
			}
		});
};
