import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import NavbarSecondaryNew from '../../organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../TemplateWithNavBar';
import pencil from '../../assets/icons/pencil.svg';
import { ReactComponent as PortfolioWhite } from '../../assets/icons/PortfolioWhite.svg';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import CardPortfolio from '../../molecules/Card/CardPortfolio';

/**
 * @description renders the view of a preselected portfolio postulation
 */
const PortfolioConfirmation = ({ preselectedPortfolio, setPortfolioView }) => {
	const { id } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	return (
		<TemplateWithNavBar>
			<NavbarSecondaryNew
				title={t('PortafolioRequerido')}
				textColor='text-purple-50 hidden md:block'
				color='bg-purple-50'
				showDots={false}
				redirect={`/postulaciones/${id}`}
				textBack={t('RegresarConvocatoria')}
				textBackDesign='pl-3 text-purple-50'
				arrowLeftColor='purple'
				containerDesign={'px-0'}
			/>
			<div className='flex w-full gap-4 py-8'>
				<div className='flex h-14 w-14 items-center justify-center rounded-full bg-green-50 p-4'>
					<PortfolioWhite />
				</div>
				<div className='flex w-full flex-col gap-2'>
					<TextTitleSub
						title={t('PortafolioLibre')}
						design={'text-purple-50'}
					/>
					<TextContentPrincipal
						text={t('ParaAplicarAEstaConvocatoriaDebesSeleccionarUnPortafolio')}
					/>
				</div>
			</div>
			<div className='mx-auto w-full p-3 sm:w-1/2'>
				<CardPortfolio
					key={preselectedPortfolio.id}
					name={preselectedPortfolio.name}
					image={preselectedPortfolio.main_picture_url}
					action={() =>
						navigate(`/portafolio/${preselectedPortfolio.slug}`, {
							from: location.pathname,
						})
					}
				/>
			</div>
			<div className='mt-8 mb-16 flex w-full flex-col items-center justify-center gap-4 py-2 sm:flex-row-reverse sm:gap-8'>
				<ButtonFab
					label={t('Continuar')}
					size='large'
					design={'shadow-md'}
					styleText={{ marginLeft: '4px' }}
					action={() => navigate(`/postulaciones/${id}`)}
				/>
				<ButtonFab
					size='large'
					icon={pencil}
					label={t('Editar')}
					colour='white'
					design={'shadow-md'}
					styleText={{ marginLeft: '4px' }}
					action={() => setPortfolioView('selectionView')}
				/>
			</div>
		</TemplateWithNavBar>
	);
};

PortfolioConfirmation.propTypes = {
	/** Preselected portfolio information*/
	preselectedPortfolio: PropTypes.object,
	/**Function that changes the view of the of the postulation portfolio section*/
	setPortfolioView: PropTypes.func,
};

export default PortfolioConfirmation;
