//3rd party
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';

//UI
import PortfolioHeader from '../../../UI/organisms/PortfolioPlanilla/PortfolioHeader';
import PortfolioArtworks from '../../../UI/organisms/PortfolioPlanilla/PortfolioArtworks';
import PortfolioFooter from '../../../UI/organisms/PortfolioPlanilla/PortfolioFooter';
import PortfolioLoading from '../../../UI/organisms/Loading/PortfolioLoading';
import PasswordModal from '../password/PasswordModal';
import PortfolioSubcollection from '../../../UI/organisms/PortfolioPlanilla/PortfolioSubcollections';
import PortfolioArtistInformation from '../PortfolioPlanilla/PortfolioArtistInformation';
import SEO from '../../molecules/SEO/SEO';
/**
 * @description Section to manage the collections.
 */

const PlantillaCollection = ({ portfolio, showLoading }) => {
	const navigate = useNavigate();
	const showPorfolioArtworks =
		portfolio.artworks !== 'empty' && (portfolio.artworks ?? []).length > 0;

	const [access, setAccess] = useState(
		useSelector((state) => state.portfolios.portfolioPublic.permissions)
	);
	const appUser = useSelector((state) => state.appUser.appUser);

	const artistFirstName = portfolio?.artist?.first_name;
	const artistLastName = portfolio?.artist?.last_name;
	const portfolioArtistName = `${artistFirstName ?? ''} ${
		artistLastName ?? ''
	}`;

	return (
		<div>
			{showLoading ? (
				<PortfolioLoading />
			) : (
				<>
					{appUser.organization_user_id !== portfolio.organization_user_id &&
						access === 'PASSWORD' && (
							<PasswordModal portfolio={portfolio} setAccess={setAccess} />
						)}
					{access === 'PRIVATE' &&
						appUser.organization_user_id !== portfolio.organization_user_id &&
						navigate('/')}

					<SEO
						title={`Datarte | ${portfolioArtistName ?? ''} | ${
							portfolio.name ?? ''
						}`}
						description={`Portafolio de ${portfolioArtistName ?? ''}. ${
							portfolio.name ?? ''
						}. ${portfolio.description ?? ''}`}
						ogTitle={`Datarte | ${portfolioArtistName ?? ''} | ${
							portfolio.name ?? ''
						}`}
						ogDescription={`Portafolio de ${portfolioArtistName ?? ''}. ${
							portfolio.name ?? ''
						}. ${portfolio.description ?? ''}`}
						ogImage={portfolio.main_picture}
					/>

					<div className='mx-8 mb-16 mt-8 flex min-h-screen flex-col gap-5 font-mulish font-light md:mx-[10%] md:mt-16 md:gap-8'>
						<PortfolioHeader
							artist_name={portfolioArtistName}
							name={portfolio.name}
							description={portfolio.description}
							main_picture={portfolio.main_picture}
							showMainImage={typeof portfolio.artworks !== 'undefined'}
							video_url={portfolio.video_url}
						/>
						<PortfolioArtistInformation artist={portfolio.artist} />
						{showPorfolioArtworks && (
							<PortfolioArtworks
								artworks={portfolio.artworks}
								image_quality={portfolio.image_quality}
								showTitle={(portfolio.subcollection ?? []).length === 0}
								showPrices={portfolio.show_prices}
								artistName={portfolioArtistName}
							/>
						)}
						{portfolio.subcollection &&
							portfolio.subcollection.map((subcollection) => (
								<PortfolioSubcollection
									subcollection={subcollection}
									image_quality={portfolio.image_quality}
									artistName={portfolioArtistName}
								/>
							))}
						<div className='flex w-full flex-row justify-end'>
							<div className='flex w-fit flex-col items-end gap-1'>
								{(portfolio?.artist?.first_name ||
									portfolio?.artist?.last_name) && (
									<h6 className='flex flex-row text-right text-lg font-semibold md:text-xl'>
										{portfolio.artist.first_name &&
											portfolio.artist.first_name !== 'Artista Desconocido' &&
											`${portfolio.artist.first_name}`}{' '}
										{portfolio.artist.last_name &&
											portfolio.artist.last_name !== 'Artista Desconocido' &&
											`${portfolio.artist.last_name}`}
									</h6>
								)}
								{portfolio?.artist?.email && (
									<p className='w-full text-right text-sm font-light md:text-lg'>
										{portfolio.artist.email}
									</p>
								)}
							</div>
						</div>
					</div>
					<PortfolioFooter portfolio={portfolio} />
				</>
			)}
		</div>
	);
};

PlantillaCollection.propTypes = {
	/** Portfolio used for the array */
	portfolio: PropTypes.object,
	/** Portfolio used for the array */
	showLoading: PropTypes.bool,
	/** Access to a portfolio, only PUBLIC OR PRIVATE*/
	access: PropTypes.string,
	/** Edit access to a portfolio*/
	setAccess: PropTypes.func,
};

PlantillaCollection.defaultProps = {
	showLoading: false,
};

export default PlantillaCollection;
