// 3rd parties
import React from 'react';
import PropTypes from 'prop-types';

// UI
import TextContentThird from '../../atoms/Text/Content/TextContentThird';

/**
 * @description Single row with two text, label and full description, this version its more simple
 * @param {*} param0
 * @returns
 */
function TextSingleRowSmall({
	design,
	labelWeight,
	label,
	value,
	labelDesign,
	valueDesign,
}) {
	return (
		<div className={`flex flex-row ${design}`}>
			<TextContentThird
				design={labelDesign}
				style={labelWeight}
				text={`${label}: `}
			/>
			<TextContentThird design={valueDesign} text={value} />
		</div>
	);
}

TextSingleRowSmall.propTypes = {
	/** string simple label*/
	label: PropTypes.string,
	/** full description */
	value: PropTypes.string,
	/** this handles the font weight in inline style */
	labelWeight: PropTypes.object,
	/** add tailwind style in the container */
	design: PropTypes.string,
};

export default TextSingleRowSmall;
