import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_PORTFOLIO } from '../../helpers/routes';
import { fetchDeleteElement } from '../../hooks/fetchDeleteElement';
import { fetchGetData } from '../../hooks/fetchGetData';
import { fetchPostData } from '../../hooks/fetchPostData';
import { fetchPutData } from '../../hooks/fetchPutData';

export const getPortfolioToEdit = createAsyncThunk(
	'portfolio/getPortfolioToEdit',
	async (id) => {
		return await fetchGetData(`${ENDPOINTS_PORTFOLIO.GET_PORTFOLIO_V2}${id}/`);
	}
);
export const getPortfolioSlide = createAsyncThunk(
	'portfolio/getPortfolioSlide',
	async (id) => {
		return await fetchGetData(
			`${ENDPOINTS_PORTFOLIO.POST_PORTFOLIO_SLIDE}${id}/`
		);
	}
);
export const createPortfolioSlide = createAsyncThunk(
	'portfolio/createPortfolioSlide',
	async (values) => {
		return await fetchPostData(
			`${ENDPOINTS_PORTFOLIO.GET_PORTFOLIO_SLIDE}`,
			values
		);
	}
);

export const putPortfolioSlide = createAsyncThunk(
	'contacts/putPortfolioSlide',
	async (input) => {
		const { values, id } = input;
		return await fetchPutData(
			`${ENDPOINTS_PORTFOLIO.PUT_PORTFOLIO_SLIDE}${id}/`,
			values
		);
	}
);

export const postPortfolioSlide = createAsyncThunk(
	'contacts/postPortfolioSlide',

	async (input) => {
		const { values, id } = input;
		return await fetchPutData(
			`${ENDPOINTS_PORTFOLIO.PUT_PORTFOLIO_SLIDE}${id}/`,
			values
		);
	}
);
export const deleteSlide = createAsyncThunk(
	'contacts/deleteSlide',

	async (id) => {
		return await fetchDeleteElement(
			`${ENDPOINTS_PORTFOLIO.DELETE_PORTFOLIO_SLIDE}${id}/`
		);
	}
);

const initialEditablePortfolioSlide = {
	order: null,
	portfolioId: null,
	createdAt: null,
	updatedAt: null,
	content: [],
};

const initialState = {
	status: null,
	editablePortfolioView: null,
	idOfPortfolioToEdit: null,
	idOfPortfolioSlideToEdit: null,
	singleEditablePortfolio: null,
	editablePortfolioSlide: initialEditablePortfolioSlide,
};

export const editablePortfolioSlice = createSlice({
	name: 'editablePortfolioSlide',
	initialState,
	reducers: {
		setEditablePortfolioView: (state, { payload }) => {
			state.editablePortfolioView = payload;
		},
		setIdOfPortfolioToEdit: (state, { payload }) => {
			state.idOfPortfolioToEdit = payload;
		},

		setIdOfPortfolioSlideToEdit: (state, { payload }) => {
			state.idOfPortfolioSlideToEdit = payload;
		},
		setSlideElement: (state, { payload }) => {
			state.editablePortfolioSlide = {
				...state.editablePortfolioSlide,

				content: [...state.editablePortfolioSlide.content, payload],
			};
		},
		updateSlideElement: (state, { payload }) => {
			state.editablePortfolioSlide = {
				...state.editablePortfolioSlide,
				content: payload,
			};
		},
		clearEditablePortfolioSlideContent: (state) => {
			state.editablePortfolioSlide = {
				...state.editablePortfolioSlide,
				content: [],
			};
		},
		clearEditablePortfolio: (state) => {
			state.editablePortfolioSlide = initialEditablePortfolioSlide;
		},
		setPortfolioId: (state, { payload }) => {
			state.editablePortfolioSlide = {
				...state.editablePortfolioSlide,
				portfolioId: payload,
			};
		},
	},

	extraReducers: {
		//Get Portfolio to be edited
		[getPortfolioToEdit.pending]: (state, { payload }) => {
			state.status = 'loading';
		},
		[getPortfolioToEdit.rejected]: (state, { payload }) => {
			state.status = 'failed';
		},
		[getPortfolioToEdit.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.singleEditablePortfolio = payload;
		},

		//Put Portfolio Slide
		[putPortfolioSlide.pending]: (state, { payload }) => {
			state.status = 'loading';
		},
		[putPortfolioSlide.rejected]: (state, { payload }) => {
			state.status = 'failed';
		},
		[putPortfolioSlide.fulfilled]: (state, { payload }) => {
			state.status = 'success';
		},
		// get slide
		[getPortfolioSlide.fulfilled]: (state, { payload }) => {
			state.editablePortfolioSlide = payload.slide;
		},
		// create a new slide in the portfolio
		[createPortfolioSlide.pending]: (state, { payload }) => {
			state.status = 'loading';
		},
		[createPortfolioSlide.rejected]: (state, { payload }) => {
			state.status = 'failed';
		},
		[createPortfolioSlide.fulfilled]: (state, { payload }) => {
			state.status = 'success';
		},
		// delete a slide in the portfolio by id
		[deleteSlide.pending]: (state, { payload }) => {
			state.status = 'loading';
		},
		[deleteSlide.rejected]: (state, { payload }) => {
			state.status = 'failed';
		},
		[deleteSlide.fulfilled]: (state, { payload }) => {
			state.status = 'success';
		},
	},
});

export const {
	setEditablePortfolioView,
	setIdOfPortfolioToEdit,
	setIdOfPortfolioSlideToEdit,
	setSlideElement,
	updateSlideElement,
	setPortfolioId,
	clearEditablePortfolioSlideContent,
} = editablePortfolioSlice.actions;

export default editablePortfolioSlice.reducer;
