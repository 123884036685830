import * as Yup from 'yup';

//Single documents tabs information
const singleDocumentTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'documents',
				field: 'identification',
				information: [
					{ label: 'Nombre', value: '', field: 'name' },
					{ label: 'Descripción', value: '', field: 'description' },
					{ label: 'Fecha', value: '23/04/01', field: 'date', type: 'date' },
					{
						label: 'Pertenece',
						value: '',
						field: 'element_name',
						type: 'document_belongs',
					},
				],
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const singleDocumentButtons = ['Identificación', 'Imágenes'];
//Single documents tabs information
const catalogueArtistSingleDocumentTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'documents',
				field: 'identification',
				information: [
					{ label: 'Nombre', value: '', field: 'name' },
					{ label: 'Descripción', value: '', field: 'description' },
					{ label: 'Fecha', value: '23/04/01', field: 'date', type: 'date' },
					{
						label: 'Pertenece',
						value: '',
						field: 'element_name',
						type: 'document_belongs',
					},
				],
			},
		],
	},
	{
		tab: 'Obras',
		type: 'Artworks',
		content: [
			{
				title: 'Obras',
				type: 'images',
				field: 'artworks',
				information: [],
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const catalogueArtistSingleDocumentButtons = [
	'Identificación',
	'Obras',
	'Imágenes',
];

const documentValidationSchema = [
	//this needs to have the same order than the buttons
	[
		//and the same order than the appearance of the content sections
		//far from elegant
		{
			name: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.required('NombreEsNecesario'),
			description: Yup.string().nullable(),
			date: Yup.string().nullable(),
			element_name: Yup.string().nullable(),
		},
	],
];

const validationSchemaDocumentEdit = Yup.object({
	name: Yup.string()
		.min(2, 'Requiere por lo menos 2 caracteres.')
		.required('El nombre es requerido.'),
});

const documentFormValidationSchema = Yup.object({
	name: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('NombreEsNecesario'),
});

const documentInitialValues = {
	id: '',
	name: '',
	date: '',
	description: '',
	organization_user_id: 1,
	artist_id: 1,
	artwork_id: 1,
	main_picture_id: 1,
	name_image: '',
	description_image: '',
};

export {
	singleDocumentTabs,
	singleDocumentButtons,
	catalogueArtistSingleDocumentTabs,
	catalogueArtistSingleDocumentButtons,
	documentValidationSchema,
	documentFormValidationSchema,
	documentInitialValues,
	validationSchemaDocumentEdit,
};
