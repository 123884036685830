//3rd party components
import PropTypes from 'prop-types';

//UI
import TextTitleSub from './TextTitleSub';

export default function TitleFormSections({ title, color, textColor }) {
	return (
		<div className=''>
			<TextTitleSub textColor={textColor} title={title} />
			<hr className={`mt-px mb-8 w-full ${color}`}></hr>
		</div>
	);
}

TitleFormSections.propTypes = {
	/** Tailwind Colour of the text */
	textColor: PropTypes.string,
	/** Text in the component */
	title: PropTypes.string,
	/** Tailwind design */
	color: PropTypes.string,
};

TitleFormSections.defaultProps = {
	title: 'Identificación',
};
