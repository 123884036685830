/* -- Third Party -- */
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//BL
import postDataAndImage from '../../../../BusinessLogic/helpers/postDataAndImage';
import { ENDPOINTS_LOCATION } from '../../../../BusinessLogic/helpers/routes';
import {
	lastLocationInitialValues,
	lastLocationFormValidationSchema,
} from '../../../../BusinessLogic/data/SingleLastLocation2';
import { backSectionURL } from '../../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../../BusinessLogic/helpers/textGoBackGenerator';

//UI
import List from '@material-ui/core/List';
import whiteCheck from '../../../../UI/assets/icons/whiteCheck.svg';
import FieldTextFormik from '../../../../UI/molecules/Field/FieldTextFormik';
import ButtonFabExtendedPrimary from '../../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import SectionForm from '../../../../UI/organisms/Section/SectionForm';
import ImageSectionForm from '../../../../UI/organisms/Image/ImageSectionForm';
import SecondaryNavbarSpecific from '../../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import LoadingText from '../../../../UI/molecules/Loading/LoadingText';
import { getLocationRoom } from '../../../../BusinessLogic/redux/reducers/locationRoom';
import TemplateWithNavBar from '../../../../UI/templates/TemplateWithNavBar';

/**
 * @description manages the location form page edit or create.
 * @returns
 */
function FormLocation() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { organization_user_id } = localStorage;
	const { singleLocationRoom } = useSelector((state) => state.locationsRoom);
	const { singleLastLocation } = useSelector((state) => state.lastLocations);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);

	const [showSaving, setShowSaving] = useState(false);

	const initialValues = {
		...lastLocationInitialValues,
		location_id: singleLocationRoom.id,
		organization_user_id: organization_user_id,
		type: 'LOCATION',
		type_id: singleLastLocation.id ? singleLastLocation.id : 0,
		file_type: 'IMAGE',
	};

	const checkRedirect = () => {
		let redirect = `/salas/${singleLastLocation.location_room_id}`;
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage = 'RegresarSala';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	return (
		<TemplateWithNavBar>
			<div className={showSaving && 'opacity-60'}>
				<SecondaryNavbarSpecific
					title={t('NuevaUbicación')}
					textColor='text-purple-50'
					color='bg-purple-50'
					hide='md:invisible'
					redirect={checkRedirect()}
					textBack={CheckTextBack()}
					textBackDesign='pl-3 text-purple-50'
					backAction={() => returnBackAction()}
				/>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={lastLocationFormValidationSchema}
					onSubmit={(values) => {
						setShowSaving(true);
						postDataAndImage(ENDPOINTS_LOCATION.POST_LAST_LOCATION, values)
							.then((res) => {
								navigate(`/ubicaciones/${res.id}`);
								dispatch(getLocationRoom(singleLocationRoom.id));
							})
							.finally(() => setShowSaving(false));
					}}
				>
					{(formProps) => (
						<Form className='mb-20'>
							<List>
								<div className='flex flex-col gap-4 md:flex-row lg:gap-12'>
									<ImageSectionForm formProps={formProps} />
									<div className='mt-8 flex w-full flex-col items-center md:w-96'>
										<SectionForm
											title={t('Identificación')}
											textColor='text-purple-50'
											color='bg-purple-50'
										>
											<FieldTextFormik
												label={t('Nombre')}
												dbName='name'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.name}
											/>

											<FieldTextFormik
												label={t('Detalles')}
												dbName='details'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.details}
											/>
										</SectionForm>
										{showSaving ? (
											<LoadingText text={t('Guardando')} />
										) : (
											<ButtonFabExtendedPrimary
												className='self-center text-white'
												icon={whiteCheck}
												text={t('Guardar')}
											/>
										)}
									</div>
								</div>
							</List>
						</Form>
					)}
				</Formik>
			</div>
		</TemplateWithNavBar>
	);
}

export default FormLocation;
