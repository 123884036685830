import React from 'react';
import PropTypes from 'prop-types';

const TextElementArtwork = ({
	name,
	nameSize,
	technique,
	year,
	measure,
	textSize,
	design,
}) => {
	return (
		<div className={`flex flex-col content-center justify-center ${design}`}>
			<h1 style={{ fontSize: textSize }}>{name}</h1>
			<p style={{ fontSize: textSize }}>{measure}</p>
			<p style={{ fontSize: textSize }}>{technique}</p>
			<p style={{ fontSize: textSize }}>{year}</p>
		</div>
	);
};

TextElementArtwork.propTypes = {
	name: PropTypes.string,
	/** Passes the text technique to the component */
	technique: PropTypes.string,
	/** The year text to the component */
	year: PropTypes.string,
	/** Passes the text measure to the component */
	measure: PropTypes.string,
	/** Fixed component positioning (does not allow the component to be moved) */
	position: PropTypes.object,
	/** Default positioning of the moveable component */
	defaultPosition: PropTypes.object,
	/** Assign font size to name */
	nameSize: PropTypes.string,
	/** Assign font size to text title */
	textSize: PropTypes.string,
	/** Assign font size to text content */
	textContentSize: PropTypes.string,
};

export default TextElementArtwork;
