//3rd
import { useNavigate, useParams } from 'react-router';
import { PropTypes } from 'prop-types';

//UI
import { ReactComponent as CrossGrey } from '../../../assets/icons/CrossGrey.svg';
import { ReactComponent as FilePlusGrey } from '../../../assets/icons/FilePlusGrey.svg';
import { ReactComponent as DeleteCanGrey } from '../../../assets/icons/DeleteCanGrey.svg';
import { ReactComponent as CopyGrey } from '../../../assets/icons/CopyGrey.svg';
import { ReactComponent as GreyGridView } from '../../../assets/icons/GreyGridView.svg';
import TextContentThird from '../../../atoms/Text/Content/TextContentThird';
import { setCurrentViewExpertPortfolioAndSelection } from '../../../../BusinessLogic/redux/reducers/portfolio';
import { useDispatch } from 'react-redux';

const SectionsView = ({
	showReorderView,
	setShowReorderView,
	duplicateSlideAction,
	deleteSlideAction,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { portfolioId } = useParams();

	return (
		<div className='flex w-full max-w-full gap-4 overflow-x-auto px-2 xs:justify-around md:flex-col md:p-0 md:pt-4'>
			<div
				className='my-auto flex h-10 w-10 cursor-pointer rounded-full  bg-white shadow transition-transform duration-300 hover:scale-105
            md:hidden
            '
			>
				<CrossGrey
					className='mx-auto h-10 w-10 self-center p-2'
					onClick={() => {
						dispatch(
							setCurrentViewExpertPortfolioAndSelection({
								currentView: 'editionView',
								selectedElementId: null,
								selectedItemId: null,
								selectedSlideId: null,
							})
						);
						navigate(`/portafolios/portafolio-experto/${portfolioId}/edicion`);
					}}
				/>
			</div>
			<div className='my-2 h-12 w-[0.5px] bg-white shadow md:hidden'></div>
			<div
				className='flex cursor-pointer flex-col rounded-sm p-2 transition-colors duration-300 hover:bg-light_grey-75'
				onClick={() => {
					navigate(
						`/portafolios/portafolio-experto/${portfolioId}/secciones/agregar`
					);
				}}
			>
				<FilePlusGrey className='mx-auto h-6 w-6' />
				<TextContentThird
					text={'Agregar'}
					design='text-grey-50 break-words w-min text-center text-xs text-[10px]  my-auto md:mx-auto'
					style={{ lineHeight: '12px' }}
				/>
			</div>
			<div
				className='flex cursor-pointer flex-col rounded-sm p-2 transition-colors duration-300 hover:bg-light_grey-75'
				onClick={() => duplicateSlideAction()}
			>
				<CopyGrey className='mx-auto h-6 w-6 ' />
				<TextContentThird
					text={'Duplicar'}
					design='text-grey-50 break-words w-min text-center text-xs text-[10px] my-auto md:mx-auto'
					style={{ lineHeight: '12px' }}
				/>
			</div>
			<div
				className={`flex cursor-pointer flex-col rounded-sm p-2 transition-colors duration-300 hover:bg-light_grey-75 ${
					showReorderView && 'bg-light_grey-75'
				}`}
				onClick={() => setShowReorderView(true)}
			>
				<GreyGridView className='mx-auto h-6 w-6 ' />
				<TextContentThird
					text={'Ordenar'}
					design='text-grey-50 break-words w-min text-center text-xs text-[10px] my-auto md:mx-auto'
					style={{ lineHeight: '12px' }}
				/>
			</div>
			<div
				className='flex cursor-pointer flex-col rounded-sm p-2 transition-colors duration-300 hover:bg-light_grey-75'
				onClick={() => deleteSlideAction()}
			>
				<DeleteCanGrey className='mx-auto h-6 w-6 ' />
				<TextContentThird
					text={'Eliminar'}
					design='text-grey-50 break-words w-min text-center text-xs text-[10px] my-auto md:mx-auto'
					style={{ lineHeight: '12px' }}
				/>
			</div>
		</div>
	);
};

SectionsView.protoTypes = {
	//** Boolean state that determines if the slides are being displayed in the reorder view */
	showReorderView: PropTypes.bool,
	/** Function to update the boolean state that determines if the slides are being displayed in the reorder view */
	setShowReorderView: PropTypes.func,
	/** Function to run at confirming to duplicate a slide */
	duplicateSlideAction: PropTypes.fun,
	/** Function to run at confirming to delete a slide */
	deleteSlideAction: PropTypes.fun,
};

export default SectionsView;
