import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	value: { payload: '' },
	chip: {},
};

export const artistFilterSlice = createSlice({
	name: 'artistsFilterConfiguration',
	initialState,
	reducers: {
		addConfigurationInput(state, { payload }) {
			state.value = { ...state.value, payload };
		},
		addConfigurationChip(state, { payload }) {
			state.chip = { ...payload };
		},
		removeConfiguration(state, { payload }) {
			state.chip = {};
		},
	},
});

export const {
	removeConfiguration,
	addConfigurationInput,
	addConfigurationChip,
} = artistFilterSlice.actions;

export default artistFilterSlice.reducer;
