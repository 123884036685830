//3rd parties

import { ListItem } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// BL
import { setPortfolioPreviewName } from '../../../BusinessLogic/redux/reducers/portfolio';
import { setPortfolioPreviewSlug } from '../../../BusinessLogic/redux/reducers/portfolio';

//UI
import InputCustomName from '../../atoms/Inputs/InputCustomName';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

/**
 * This function is a formik field that takes in a label, dbName, formikFieldType, labelDesign,
 * errorMessageDesign, errors, and formProps. It returns a list item with a label and a formik field
 * @returns A function that returns a list item.
 */
export default function FieldTextFormikPortfolio({
	label,
	dbName,
	formikFieldType,
	labelDesign,
	errorMessageDesign,
	errors,
	formProps,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleSlugValue = (e) => {
		formProps.values.slug = e.target.value;
		formProps.setValues(formProps.values);
		dispatch(setPortfolioPreviewSlug(e.target.value));
	};

	const handleNameValue = (e) => {
		formProps.values.name = e.target.value;
		formProps.setValues(formProps.values);
		dispatch(setPortfolioPreviewName(e.target.value));
	};

	return (
		<ListItem
			style={{
				paddingLeft: '0px',
				paddingRight: '0px',
				justifyContent: 'space-between',
			}}
		>
			<div className={labelDesign}>
				<TextContentSecond text={label} />
			</div>
			<div className='flex flex-col'>
				{dbName === 'slug' ? (
					<div className='flex w-40 md:w-56'>
						<div className='flex w-full'>
							<span className='body-Text2 pt-1  text-right lowercase'>
								.../
							</span>

							<Field
								name={dbName}
								type={formikFieldType}
								as={InputCustomName}
								onBlur={(e) => {
									handleSlugValue(e, formProps);
								}}
							/>
						</div>
					</div>
				) : (
					<Field
						name={dbName}
						type={formikFieldType}
						as={InputCustomName}
						onBlur={(e) => {
							handleNameValue(e, formProps);
						}}
					/>
				)}
				{errors && (
					<div className={`w-40 md:w-56 ${errorMessageDesign}`}>
						{t(errors)}
					</div>
				)}
			</div>
		</ListItem>
	);
}

FieldTextFormikPortfolio.propTypes = {
	/** type used in the formik field */
	formikFieldType: PropTypes.string,
	/** Text displayed on the string */
	label: PropTypes.string,
	/** Tailwind design of the label */
	labelDesign: PropTypes.string,
	/** This fields connects the data. Please use the same name that on the database for simplicity */
	dbName: PropTypes.string,
	/** Tailwind design of the ErrorMessage*/
	errorMessageDesign: PropTypes.string,
	/** this error string is generated from te yup validation schema in case of a missing requerid field*/
	errors: PropTypes.string,
	/** Object with the formProps  from the parent component formik*/
	formProps: PropTypes.object,
};

FieldTextFormikPortfolio.defaultProps = {
	type: 'text',
	labelDesign: 'w-max',
};
