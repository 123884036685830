/* Icons */
import { Link } from 'react-router-dom';
import { ReactComponent as AddRole } from '../../../assets/icons/addRole.svg';

export default function ButtonLinkAddRole({ design }) {
	return (
		<Link to='/management/configuracion/user/nuevo'>
			<figure src={AddRole} alt='add role'>
				<AddRole fill='none' stroke={design} />
			</figure>
		</Link>
	);
}
