//3rd
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

//UI
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import CallStatusLabel from '../../atoms/Text/Labels/CallStatusLabel';

//BL
import { dateLongFormat } from '../../../BusinessLogic/helpers/dateFormats';
import { arrayToString } from '../../../BusinessLogic/helpers/textFormat';

/**
 * @description render a card with the main information of a call
 * @param {*} param0
 * @returns
 */
const CardCall = ({
	id,
	slug,
	organizationName,
	organizationMainPictureUrl,
	callName,
	city,
	country,
	callMainPictureUrl,
	closeDate,
	category,
	technique,
	status,
	description,
	hasAlliance,
}) => {
	return (
		<Link
			className='w-full border'
			key={id}
			to={{
				pathname: `/convocatorias/${slug}`,
			}}
		>
			<div className='relative flex justify-start gap-3 p-2 py-4'>
				<div className=' h-16 w-16'>
					<LazyLoadImage
						alt={organizationName}
						src={organizationMainPictureUrl}
						effect='blur'
						wrapperClassName={`overflow-hidden aspect-square rounded-full flex items-center lazyLoadingImages h-full`}
						className='object-cover'
						delayTime='0'
						threshold='800'
					/>
				</div>
				<div className='mb-4 w-full'>
					{callName && (
						<h3
							className='text-lg font-bold text-green-50 md:text-xl'
							style={{ wordBreak: 'break-word' }}
						>
							{callName}
						</h3>
					)}
					{organizationName && (
						<TextContentSecond
							text={organizationName}
							design={'text-sm font-light'}
						/>
					)}
					{((city && city !== 'null') ||
						(country && country !== 'Unknown')) && (
						<div className='flex gap-1'>
							{city && city !== 'null' && (
								<TextContentSecond
									text={`${city}`}
									design={'text-sm font-light'}
								/>
							)}
							{country && country !== 'Unknown' && (
								<TextContentSecond
									text={`${city && city !== 'null' && '-'} ${country}`}
									design={'text-sm font-light'}
								/>
							)}
						</div>
					)}
				</div>
				{hasAlliance && (
					<TextContentSecond
						design={
							' px-2 h-6 w-max bg-green-50 rounded-lg text-white flex items-center absolute bottom-0 right-1'
						}
						text={'Alianza Datarte'}
					/>
				)}
			</div>
			<LazyLoadImage
				alt={callName}
				src={callMainPictureUrl}
				width='100%'
				effect='blur'
				wrapperClassName={`overflow-hidden flex mt-2 items-center lazyLoadingImages aspect-video`}
				className='object-cover object-center'
				delayTime='0'
				threshold='800'
			/>
			<div className='flex flex-col justify-start gap-2 p-2 text-sm font-light'>
				<div className='flex justify-start gap-2'>
					<div className='w-full'>
						<h6>
							<span className='font-bold'>{`Fecha límite: `}</span>
							{dateLongFormat(closeDate)}
						</h6>
						{arrayToString(category)?.length > 0 && (
							<h6>
								<span className='font-bold'>{`Categoría: `}</span>
								{`${arrayToString(category)}`}
							</h6>
						)}
						{arrayToString(technique)?.length > 0 && (
							<h6>
								<span className='font-bold'>{`Técnica: `}</span>
								{`${arrayToString(technique)}`}
							</h6>
						)}
					</div>
					<div className='px-2'>
						<CallStatusLabel status={status} />
					</div>
				</div>
				<TextContentSecond
					text={description}
					design={'text-sm font-light line-clamp-5'}
				/>
			</div>
		</Link>
	);
};

CardCall.propTypes = {
	/** Id related to the call */
	id: PropTypes.number,
	/** Slug related to the call */
	slug: PropTypes.string,
	/** Name of the organization related to the call */
	organizationName: PropTypes.string,
	/** Picture url of the organization related to the call */
	organizationMainPictureUrl: PropTypes.string,
	/** Name of the call */
	callName: PropTypes.string,
	/** City of the call */
	city: PropTypes.string,
	/** Country of the call */
	country: PropTypes.string,
	/** Picture url of the call */
	callMainPictureUrl: PropTypes.string,
	/** Due date of the call */
	closeDate: PropTypes.string,
	/** Category of the call */
	category: PropTypes.string,
	/** Technique of the call */
	technique: PropTypes.string,
	/** Status of the call */
	status: PropTypes.string,
	/** Description of the call */
	description: PropTypes.string,
	/** Whether a call has an alliance with Datarte */
	hasAlliance: PropTypes.bool,
};

CardCall.defaultProps = {
	organizationMainPictureUrl:
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
	callMainPictureUrl:
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
};

export default CardCall;
