import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';
import 'react-lazy-load-image-component/src/effects/blur.css';

// UI
import TextWithLink from '../../molecules/Text/TextWithLink';
import ButtonFabPrincipalUrl from '../../atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import artworksImage from '../../assets/images/BlankSpacesImages/artworksImg.jpg';

function BlankSpacesTemplate({
	title,
	subtitle,
	text,
	link,
	message,
	image,
	isThereAButtonWithLink,
	isThereTextWithLink,
	isThereTextWithAction,
	paragraph,
	paragraphToTextWithAction,
	spanText,
	action,
	designContainerText,
	designImage,
}) {
	const { t } = useTranslation();
	return (
		<div className='z-50 mx-auto flex w-[95%] flex-col px-0 sm:w-[90%] md:px-6'>
			<h2 className='text-center text-[30px] font-normal leading-[40px]'>
				{t(title)}
			</h2>
			<h3 className='py-2 text-center text-[20px] font-normal leading-[24px] text-green-50'>
				{t(subtitle)}
			</h3>

			<div className=' flex flex-col items-center'>
				<div className={`py-4 ${designContainerText}`}>
					<p className='py-1 text-[16px] font-[300] leading-[21px]'>
						{t(text)}
					</p>
					<p className='text-[16px] font-[400] leading-[21px]'>
						{t(paragraph)}
					</p>
					{isThereTextWithAction && (
						<p className='py-1 text-[16px] font-[400] leading-[21px]'>
							{t(paragraphToTextWithAction)}{' '}
							<span onClick={action} className={'cursor-pointer text-green-50'}>
								{t(spanText)}
							</span>
						</p>
					)}
					{isThereTextWithLink && (
						<TextWithLink
							emptyEntityMessage={message}
							redirect={link}
							TextContentSecondParagraphDesign={'text-[16px]'}
						/>
					)}
				</div>
			</div>

			<LazyLoadImage
				src={image}
				width='100%'
				height='100%'
				effect='blur'
				className={`max-h-[60vh] object-scale-down ${designImage}`}
				wrapperClassName={`overflow-hidden flex items-center lazyLoadingImages`}
				delayTime='0'
				threshold='800'
			/>

			{isThereAButtonWithLink && (
				<ButtonFabPrincipalUrl label={t('Crear')} url={link} />
			)}
		</div>
	);
}

BlankSpacesTemplate.propTypes = {
	/** URL of the  image to be displayed in the component*/
	image: PropTypes.string,
	/** String prop with title text*/
	title: PropTypes.string,
	/** String prop with subtitle text*/
	subtitle: PropTypes.string,
	/** String prop with text*/
	text: PropTypes.string,
	/** String prop with the redirect link*/
	link: PropTypes.string,
	/** String prop with message for TextWithLink component*/
	message: PropTypes.string,
	/** Bool prop to determinate if there is a button in the component*/
	isThereAButtonWithLink: PropTypes.bool,
	/** Bool prop to determinate if TextWithLink will be display in the component*/
	isThereTextWithLink: PropTypes.bool,
	/** String prop with additional text*/
	paragraph: PropTypes.string,
	/** String prop with the paragraph  for the text with action*/
	paragraphToTextWithAction: PropTypes.string,
	/** String prop with the text for the span in text with action*/
	spanText: PropTypes.string,
	/** Func prop with the onclick action for the span in text with action*/
	action: PropTypes.func,
	/** String prop to add styles to container text*/
	designContainerText: PropTypes.string,
	/** String prop with image styles*/
	designImage: PropTypes.string,
};
BlankSpacesTemplate.defaultProps = {
	isThereAButtonWithLink: false,
	isThereTextWithLink: true,
	image: artworksImage,
};

export default BlankSpacesTemplate;
