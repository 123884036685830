import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
//UI
import App from './App';
import './index.css';
import './i18n';

const tagManagerArgs = {
	gtmId: 'GTM-KNZZSG7',
};

TagManager.initialize(tagManagerArgs);

const app = document.getElementById('app');
const root = createRoot(app);

root.render(
	<Router>
		<App />
	</Router>
);
