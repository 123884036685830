/* 3rd party */
import React from 'react';
import PropTypes from 'prop-types';

/* UI */
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import DocumentTypes from '../../../Pages/sections/document/DocumentTypes';

export default function FilesSection({
	files,
	action,
	text,
	preSelectedFiles,
	objectFitProp,
	section,
}) {
	return (
		<div
			className={`w-full ${
				files.length < 1 ? 'flex' : 'grid grid-cols-3 md:grid-cols-7'
			} gap-4`}
			data-test={'file-selection-grid'}
		>
			{!files.length ? (
				<TextContentSecond text={`No hay ${text?.toLowerCase()} disponibles`} />
			) : (
				files &&
				files.map((file) => (
					<div key={file.id} onClick={() => action(file)}>
						<DocumentTypes
							document={file}
							preSelectedFiles={preSelectedFiles}
							objectFitProp={objectFitProp}
							section={section}
						/>
					</div>
				))
			)}
		</div>
	);
}

FilesSection.propTypes = {
	/** determines the object fit of the pre-visualization images */
	objectFitProp: PropTypes.string,
	/* An array of objects*/
	files: PropTypes.array,
	/* A function for select or deselect the file */
	action: PropTypes.func,
	/** String  to be displayed when there is not files */
	text: PropTypes.string,
	/**Preselected files */
	preSelectedFiles: PropTypes.array,
	/**String prop to determinate in which section the component is */
	section: PropTypes.string,
};

FilesSection.defaultProps = {
	objectFitProp: 'fill',
};
