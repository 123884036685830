import * as Yup from 'yup';
//Single room tabs information
const singleRoomTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'labels',
				field: 'identification',
				information: [
					{ label: 'Nombre', value: 'Lorem Ipsum', field: 'name' },
					{ label: 'Piso', value: 'floor', field: 'floor' },
					{ label: 'Detalles', value: 'Lorem Ipsum', field: 'details' },
				],
			},
		],
	},
	{
		tab: 'Obras',
		type: 'Obras',
		content: [
			{
				title: 'Obras',
				type: 'images',
				field: 'artworks',
				information: [],
			},
		],
	},
	{
		tab: 'Ubicaciones',
		type: 'Ubicaciones',
		content: [
			{
				title: 'Ubicaciones',
				type: 'images',
				field: 'last_locations',
				information: [],
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const singleRoomButtons = [
	'Identificación',
	'Obras',
	'Ubicaciones',
	'Imágenes',
];

const singleRoomValidationSchema = [
	//this needs to have the same order than the buttons
	[
		//and the same order than the appearance of the content sections
		//far from elegant
		{
			name: Yup.string().required('NombreEsNecesario'),
			details: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.required('DetalleEsNecesario'),
			floor: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.required('PisoNecesario'),
		},
	],
];

const locationRoomValidationSchema = Yup.object({
	name: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('NombreEsNecesario'),
	details: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('DetalleEsNecesario'),
	floor: Yup.string().min(2, 'RequiereDosCaracteres').required('PisoNecesario'),
});

const locationRoomInitialValues = {
	id: '',
	name: '',
	floor: '',
	details: '',
	location_general_id: 1,
	main_picture_url: '',
	main_picture_id: 1,
	organization_user_id: 6,
	name_image: '',
	description_image: '',
};

export {
	singleRoomTabs,
	singleRoomButtons,
	singleRoomValidationSchema,
	locationRoomValidationSchema,
	locationRoomInitialValues,
};
