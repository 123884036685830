//3rd party
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//UI
import Eye from '../../../UI/assets/icons/Eye.svg';
import ImageMainSpecific from '../../../UI/molecules/Image/ImageMainSpecific';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';

/**
 * @description left hand side of the portfolio edit section
 * @param {*} param0
 * @returns
 */

const PortfolioEditImageAndVisualize = ({
	image,
	alt,
	buttonAction,
	design,
	wrapperClassName,
}) => {
	const { t } = useTranslation();

	return (
		<div className={`w-full md:w-1/2 ${design}`}>
			<div className=''>
				<ImageMainSpecific
					image={image}
					alt={alt}
					wrapperClassName={wrapperClassName}
				/>
			</div>
			<div className='mt-6 flex w-full justify-center'>
				<ButtonFab
					action={buttonAction}
					colour='white'
					icon={Eye}
					label={t('VisualizarPortafolio')}
					size='xlarge'
				/>
			</div>
		</div>
	);
};

PortfolioEditImageAndVisualize.propTypes = {
	/** Image displayed */
	image: PropTypes.string,
	/** Title displayed */
	alt: PropTypes.string,
	/** Action when click on the button */
	buttonAction: PropTypes.func,
	/** more tailwind styles to the container */
	design: PropTypes.string,
	/** tailwind styles for the wrapper of the lazyloading image container */
	wrapperClassName: PropTypes.string,
};

export default PortfolioEditImageAndVisualize;
