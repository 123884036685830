import { useState, useEffect } from 'react';
import { Dropzone, FileItem } from '@dropzone-ui/react';
import {
	resizeFileMedium,
	resizeFileSmall,
} from '../../../BusinessLogic/helpers/imageSize';
import { t } from 'i18next';
export default function FileSectionForm({ formProps }) {
	const [isMobile, setIsMobile] = useState(false);

	const [dropZoneFiles, setDropZoneFiles] = useState([]);
	const updateFiles = async (incomingFiles) => {
		setDropZoneFiles(incomingFiles);
		let file = incomingFiles[0].file;
		let smallPicture;
		let mediumPicture;
		let largePicture;
		if (incomingFiles[0].file.type === 'application/pdf') {
			formProps.setFieldValue('document', incomingFiles[0].file);
			formProps.setFieldValue('name_image', incomingFiles[0].file.name);
			formProps.setFieldValue('file_type', 'DOCUMENT');
		} else if (/video/.test(incomingFiles[0].file.type)) {
			formProps.setFieldValue('video', incomingFiles[0].file);
			formProps.setFieldValue('name_video', incomingFiles[0].file.name);
			formProps.setFieldValue('file_type', 'VIDEO');
		} else if (
			incomingFiles[0].file.type === 'image/png' ||
			incomingFiles[0].file.type === 'image/jpg' ||
			incomingFiles[0].file.type === 'image/jpeg'
		) {
			formProps.setFieldValue('file_type', 'IMAGE');
			try {
				smallPicture = await resizeFileSmall(file);
				mediumPicture = await resizeFileMedium(file);
				largePicture = file;
				formProps.setFieldValue('small_file', smallPicture);
				formProps.setFieldValue('medium_file', mediumPicture);
				formProps.setFieldValue('large_file', largePicture);
				formProps.setFieldValue('name_image', file.name);
			} catch (error) {
				console.error(error);
			}
		}
	};

	const handleDelete = (id) => {
		setDropZoneFiles(dropZoneFiles.filter((x) => x.id !== id));
	};

	useEffect(() => {
		const changeInViewport = () => {
			if (window.innerWidth > 768) {
				setIsMobile(false);
			} else {
				setIsMobile(true);
			}
		};
		window.addEventListener('resize', changeInViewport);
		return () => {
			window.removeEventListener('resize', changeInViewport);
		};
	});

	return (
		<div className='mx-auto flex w-full flex-col items-center text-xs sm:w-1/2'>
			<Dropzone
				className='p-4'
				onChange={updateFiles}
				value={dropZoneFiles}
				localization={'ES-es'}
				minHeight={'195px'}
				maxHeight={'300px'}
				label={
					isMobile
						? t('HazClicParaCargarElArchivo')
						: t('SueltaOHazClickAquíParaCargarElArchivo')
				}
				accept={'image/png, image/jpg, image/jpeg, video/*, application/pdf'}
				maxFiles={1}
				maxFileSize={104857600}
				view={'grid'}
			>
				{dropZoneFiles.map((file) => (
					<FileItem
						{...file}
						key={file.id}
						onDelete={handleDelete}
						alwaysActive
						localization={'ES-es'}
						preview
						info
						resultOnTooltip
					/>
				))}
			</Dropzone>
		</div>
	);
}
