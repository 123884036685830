//UI
import AuthenticationTemplate from '../../../UI/templates/AuthenticationTemplate';
import LoginForm from './LoginForm';

/**
 *
 * @description login component
 */

const Login = () => {
	return (
		<AuthenticationTemplate>
			<LoginForm />
		</AuthenticationTemplate>
	);
};

export default Login;
