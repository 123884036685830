import ButtonLinkBack from '../../atoms/Buttons/Link/ButtonLinkBack';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import TextTitlePrincipal from '../../atoms/Text/Titles/TextTitlePrincipal';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';

export default function NavbarSecondManagement({ title, text, style }) {
	return (
		<div className='flex'>
			<ButtonLinkBack design='lg:hidden' />
			<div className='ml-5 flex flex-col lg:ml-0'>
				<div className='lg:hidden'>
					<TextTitleSub textColor='text-green-50' title={title} style={style} />
				</div>
				<div className='hidden lg:block'>
					<TextTitlePrincipal textColor='text-green-50' title={title} />
				</div>
				<TextContentSecond design='mt-2	' text={text} />
			</div>
		</div>
	);
}
