// 3rd Party
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

// BL
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';

// UI
import AllArtworks from '../../Pages/sections/artwork/AllArtworks';
import ArtworkForm from '../../Pages/sections/artwork/ArtworkForm';
import ArtworkImagesForm from '../../Pages/sections/artwork/images/ImageArtwortForm';
import ArtworkSingle from '../../Pages/sections/artwork/ArtworkSingle';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';

const ArtworkRouter = () => {
	const { appUser } = useSelector((state) => state.appUser);

	return (
		<Routes>
			{appUser.role_company !== 'ORGANIZATION' && (
				<>
					<Route
						path='/'
						element={
							<PrivateRoute>
								<AllArtworks />
							</PrivateRoute>
						}
					/>

					<Route />

					<Route
						path='/:id/edit'
						element={
							<PrivateRoute>
								<ArtworkForm action='edit' />
							</PrivateRoute>
						}
					/>

					<Route
						path='/new'
						element={
							<PrivateRoute>
								<ArtworkForm action='new' />
							</PrivateRoute>
						}
					/>

					<Route
						path='/:id'
						element={
							<PrivateRoute>
								<ArtworkSingle />
							</PrivateRoute>
						}
					/>

					{/* Images*/}

					<Route
						path='/imagenes/:id/edit'
						element={
							<PrivateRoute>
								<ArtworkImagesForm action='edit' />
							</PrivateRoute>
						}
					/>

					<Route
						path='/imagenes/new'
						element={
							<PrivateRoute>
								<ArtworkImagesForm action='new' />
							</PrivateRoute>
						}
					/>
				</>
			)}
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default ArtworkRouter;
