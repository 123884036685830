//3rd party
import { useDispatch } from 'react-redux';

//BL
import { ENDPOINTS_APPUSER } from '../../../BusinessLogic/helpers/routes';
import { fetchPutData } from '../../../BusinessLogic/hooks/fetchPutData';
import { cleanArtworkImage } from '../../../BusinessLogic/redux/reducers/artworkImage';
import { changeSession } from '../../../Pages/authentication/servicesAuthentication';
import { getAppUser } from '../../../BusinessLogic/redux/reducers/appUser';
import { useTranslation } from 'react-i18next';

//UI
import ButtonLinkText from '../../atoms/Buttons/Link/ButtonLinkText';

export default function OrganizationMenu({ rolUser, idOrganization, roleId }) {
	const { app_user } = localStorage;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const values = {
		organization_user_id: idOrganization,
	};
	const changeMainAccount = () => {
		fetchPutData(
			`${ENDPOINTS_APPUSER.CHANGE_MAIN_ACCOUNT}${app_user}/`,
			values
		);
	};

	return (
		<ul
			className='h-26 ring-black absolute right-9 z-10 mt-6 flex w-56 origin-top-right  flex-col 
									rounded-md bg-white p-5 shadow-lg ring-opacity-5 focus:outline-none lg:right-4'
		>
			<li
				className='cursor-pointer'
				onClick={() => {
					changeSession(rolUser, idOrganization, roleId);
					changeMainAccount();
					dispatch(cleanArtworkImage());
					dispatch(getAppUser());
				}}
			>
				<ButtonLinkText route='/' text={t('VerCuenta')} />
			</li>
			<li
				className='cursor-pointer'
				onClick={() => {
					changeSession(rolUser, idOrganization, roleId);
				}}
			>
				<ButtonLinkText route='/' text={t('EditarCuenta')} />
			</li>
		</ul>
	);
}
