import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	ENDPOINTS_APPUSER,
	ENDPOINTS_ORGANIZATION,
} from '../../helpers/routes';
import { fetchPostDataWithUser } from '../../hooks/fetchPostDataWithUser';
import { fetchPostData } from '../../hooks/fetchPostData';
import { updateTabsObject } from '../../helpers/SingleViewHelpers';
import { fetchPutData } from '../../hooks/fetchPutData';
import { fetchGetData } from '../../hooks/fetchGetData';
import { fetchDeleteElement } from '../../hooks/fetchDeleteElement';
import { userProfileTabs } from '../../data/Perfil';

//get AppUser
export const getAppUser = createAsyncThunk('appUser/fetchAppUser', async () => {
	let { app_user } = localStorage;
	return await fetchPostDataWithUser(
		`${ENDPOINTS_APPUSER.GET_APPUSER}${app_user}/`
	);
});

//put AppUser
export const putAppUser = createAsyncThunk(
	'artists/putAppUser',
	async (input) => {
		let { app_user } = localStorage;
		return await fetchPutData(
			`${ENDPOINTS_APPUSER.PUT_APPUSER}${app_user}/`,
			input
		);
	}
);
//put AppUser password
export const putAppUserPassword = createAsyncThunk(
	'artists/putAppUserPassword',
	async (input) => {
		let { app_user } = localStorage;
		return await fetchPutData(
			`${ENDPOINTS_APPUSER.PUT_APPUSER_PASSWORD}${app_user}/`,
			input
		);
	}
);
//post App User Login
export const app_user_login = createAsyncThunk(
	'appUser/appUserLogin',
	async (values) => {
		return await fetchPostData(ENDPOINTS_APPUSER.LOGIN, values);
	}
);

export const addNewUser = createAsyncThunk(
	'appUser/addUser',
	async (values) => {
		return await fetchPostDataWithUser(
			ENDPOINTS_ORGANIZATION.ADD_USER_TO_ORGANIZATION,
			values
		);
	}
);

export const getUserOrganizations = createAsyncThunk(
	'appUser/getUserOrganizations',
	async () => {
		let { app_user } = localStorage;
		return await fetchGetData(
			`${ENDPOINTS_ORGANIZATION.GET_ORGANIZATIONS_WHERE_IAM}${app_user}/`
		);
	}
);

export const deleteUserFromOrganization = createAsyncThunk(
	'appUser/deleteUserFromOrganization',
	async (roleId) => {
		return await fetchDeleteElement(
			`${ENDPOINTS_ORGANIZATION.DELETE_USER_FROM_ORGANIZATION}${roleId}/`
		);
	}
);

// Send email to request password recovery

export const recoveryPasswordByEmail = createAsyncThunk(
	'appUser/recoveryPasswordByEmail',

	async (values) => {
		return await fetchPostData(
			ENDPOINTS_APPUSER.RECOVERY_PASSWORD_BY_EMAIL,
			values
		);
	}
);

// Restore password by token

export const restorePasswordByToken = createAsyncThunk(
	'appUser/restorePasswordByToken',

	async (input) => {
		const { values, token } = input;
		return await fetchPutData(
			`${ENDPOINTS_APPUSER.CONFIRM_RESTORE_PASSWORD}${token}`,
			values
		);
	}
);

const initialStateUserProfileTabs = userProfileTabs;

const initialProfileUser = {
	first_name: '',
	last_name: '',
	email: '',
};

const initialUserRoles = [
	{
		label: 'AdministradorCoPropietario',
		description: 'TieneAccesoCompletoParaAdministrar',
		value: '2',
	},
	{
		label: 'Editor',
		description: 'PuedeAdministrarCompletamenteLaInfo',
		value: '3',
	},
	{
		label: 'Lector',
		description: 'PuedeCrearOResponderMensajes',
		value: '4',
	},
	{
		label: 'UsuarioTemporal',
		description: 'AsignaUnUsuarioTemporal',
		value: '5',
	},
];

const initialUserProfileTabs = updateTabsObject(
	initialStateUserProfileTabs,
	initialProfileUser
);

const initialState = {
	appUser: initialProfileUser,
	appUserConfig: initialProfileUser,
	userProfileTabs: initialUserProfileTabs,
	initialStateTabs: initialStateUserProfileTabs,
	userRoles: initialUserRoles,
	userOrganizations: [],
	appUserChange: 0,
	status: null,
};

export const appUserSlice = createSlice({
	name: 'appUser',
	initialState,
	reducers: {
		getSingleUser: (state, { payload }) => {
			state.appUser = payload;
		},
		resetAppUser: (state) => {
			state.appUser = initialProfileUser;
			state.appUserConfig = initialProfileUser;
			state.userProfileTabs = initialUserProfileTabs;
			state.appUserChange = 0;
			state.status = null;
		},
	},
	extraReducers: {
		//get AppUser

		[getAppUser.pending]: (state, action) => {
			state.status = 'loading';
			state.userProfileTabs = updateTabsObject(
				state.initialStateTabs,
				initialProfileUser
			);
		},
		[getAppUser.rejected]: (state, action) => {
			state.status = 'failed';
		},
		[getAppUser.fulfilled]: (state, { payload }) => {
			let Single = payload.app_user;

			state.appUser = {
				...state.appUser,
				...Single,
			};
			state.appUserConfig = Single;
			state.userProfileTabs = updateTabsObject(state.userProfileTabs, Single);
			state.status = 'success';
		},

		//post App User Login
		[app_user_login.pending]: (state, action) => {
			state.status = 'loading';
		},
		[app_user_login.fulfilled]: (state, { payload }) => {
			if (payload.code === 200) {
				const app_user = payload.app_user;
				state.appUser = app_user;
				localStorage.setItem('token', app_user.token);
				localStorage.setItem('app_user', JSON.stringify(app_user.app_user_id));
				localStorage.setItem(
					'role_user_name',
					JSON.stringify(app_user.role_user_name)
				);
				localStorage.setItem(
					'organization_user_id',
					JSON.stringify(app_user.organization_user_id)
				);
				localStorage.setItem(
					'role_user_id',
					JSON.stringify(app_user.role_user_id)
				);
				localStorage.setItem(
					'unknown_values',
					JSON.stringify(app_user.unknown_values)
				);
				state.status = 'success';
			}
			state.status = 'success';
		},
		[app_user_login.rejected]: (state, action) => {
			state.status = 'failed';
		},
		//put Single Artist
		[putAppUser.pending]: (state, action) => {
			state.status = 'loading';
		},
		[putAppUser.fulfilled]: (state, { payload }) => {
			state.appUserChange += 1;
			state.status = 'success';
		},
		[putAppUser.rejected]: (state, action) => {
			state.status = 'failed';
		},

		//put appUser password
		[putAppUserPassword.pending]: (state, action) => {
			state.status = 'loading';
		},
		[putAppUserPassword.fulfilled]: (state, { payload }) => {
			state.appUserChange += 1;
			state.status = 'success';
		},
		[putAppUserPassword.rejected]: (state, action) => {
			state.status = 'failed';
		},

		//add new user
		[addNewUser.pending]: (state, action) => {
			state.status = 'loading';
		},

		//get user organizations
		[getUserOrganizations.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getUserOrganizations.fulfilled]: (state, { payload }) => {
			state.userOrganizations = payload;
			state.status = 'success';
		},

		//delete user from organization
		[deleteUserFromOrganization.pending]: (state, action) => {
			state.status = 'loading';
		},
		[deleteUserFromOrganization.fulfilled]: (state, action) => {
			state.status = 'success';
		},

		// Send email to recover password

		[recoveryPasswordByEmail.pending]: (state, action) => {
			state.status = 'loading';
		},
		[recoveryPasswordByEmail.fulfilled]: (state, { payload }) => {
			state.status = 'success';
		},
		[recoveryPasswordByEmail.rejected]: (state, action) => {
			state.status = 'failed';
		},

		// Restore password by toke

		[restorePasswordByToken.pending]: (state, action) => {
			state.status = 'loading';
		},
		[restorePasswordByToken.fulfilled]: (state, { payload }) => {
			state.status = 'success';
		},
		[restorePasswordByToken.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});

export const { getSingleUser, resetAppUser } = appUserSlice.actions;

export default appUserSlice.reducer;
