import React from 'react';

const EditExpertInputText = ({
	inputDesign,
	inputValue,
	onChangeAction,
	onBlurAction,
	placeholderText,
	inputType = 'text',
}) => {
	return (
		<input
			placeholder={placeholderText}
			className={`${inputDesign}`}
			type={inputType}
			value={inputValue}
			onChange={onChangeAction}
			onBlur={onBlurAction}
		/>
	);
};

export default EditExpertInputText;
