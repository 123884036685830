//3rd party
import PropTypes from 'prop-types';

//UI
import TemplateWithNavBar from './TemplateWithNavBar';

function ViewManagement({ children }) {
	return (
		<div className='m-0 h-full w-full p-0 '>
			<TemplateWithNavBar>{children}</TemplateWithNavBar>
		</div>
	);
}

ViewManagement.propTypes = {
	/** Children that goes in the wrapper*/
	children: PropTypes.array,
};

export default ViewManagement;
