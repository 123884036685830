// 3rd party
import { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { format } from 'date-fns';

//BL
import {
	dateTimeLongFormat,
	handleTimeZoneOffset,
} from '../../../BusinessLogic/helpers/dateFormats';
import { useTranslation } from 'react-i18next';
import { downloadDocument } from '../../../BusinessLogic/helpers/downloadMedia';
import { getPortfolioBySlug } from '../../../BusinessLogic/redux/reducers/portfolio';
import { useGetSingleCall } from '../../../BusinessLogic/hooks/query/useQueryCalls';

import {
	usePutPostulationStatusArtist,
	usePutPostulationStatusOrganization,
} from '../../../BusinessLogic/hooks/query/useQueryPostulation';

//UI
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import { ReactComponent as Share } from '../../../UI/assets/icons/ShareNetwork.svg';
import { ReactComponent as Save } from '../../../UI/assets/icons/Save.svg';
import { ReactComponent as StarIcon } from '../../../UI/assets/icons/StarIcon.svg';
import { ReactComponent as MoneyIcon } from '../../../UI/assets/icons/MoneyIcon.svg';
import CardPostulationSingle from '../../../UI/molecules/Card/CardPostulationSingle';
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import CardDropdown from '../../../UI/molecules/Card/CardDropdown';
import CardTextIcon from '../../../UI/molecules/Card/CardTextIcon';
import ButtonLinkBack from '../../../UI/atoms/Buttons/Link/ButtonLinkBack';
import ButtonLinkBackText from '../../../UI/molecules/Buttons/Link/ButtonLinkBackText';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import CardLink from '../../../UI/molecules/Card/CardLink';
import SharePortfolio from '../portfolio/SharePortfolio';
import CheckGreen from '../../../UI/assets/icons/CheckGreen.svg';
import DownloadYellow from '../../../UI/assets/icons/DownloadYellow.svg';
import { ReactComponent as DotsVertical } from '../../../UI/assets/icons/DotsVertical.svg';
import { ReactComponent as DownloadBlack } from '../../../UI/assets/icons/DownloadBlack.svg';
import { ReactComponent as LoadingSpinnerSmallBlack } from '../../../UI/assets/icons/loadingSpinnerSmallBlack.svg';
import CrossRed from '../../../UI/assets/icons/CrossRed.svg';
import ButtonFabAdmin from '../../../UI/atoms/Buttons/FAB/ButtonFabAdmin';
import TextContentPrincipal from '../../../UI/atoms/Text/Content/TextContentPrincipal';
import PopupIconAndOptions from '../../../UI/organisms/PopUp/PopupIconAndOptions';
import DownloadableCatalogue from './DownloadableCatalogue';
import DownloadableForm from './DownloadableForm';
import DownloadablePortfolio from './DownloadablePortfolio';
import DownloadableExpertPortfolio from '../../../UI/templates/PortfolioTemplates/DownloadableExpertPortfolio/DownloadableExpertPortfolio';

const SubmittedPostulation = ({ postulationSingle }) => {
	const dispatch = useDispatch();

	const { singleCall } = useGetSingleCall(postulationSingle?.call_id);

	const putPostulationStatusMutationOrganization =
		usePutPostulationStatusOrganization();
	const putPostulationStatusMutationArtist = usePutPostulationStatusArtist();

	const { appUser } = useSelector((state) => state.appUser);
	const [showPopupDownload, setShowPopupDownload] = useState(false);

	const [showSharePostulation, setShowSharePostulation] = useState(false);
	const [portfolioData, setPortfolioData] = useState();
	const [showReviewedButton, setShowReviewedButton] = useState(false);

	const isArtistUser = appUser?.role_company === 'ARTIST';
	const isCatalogueArtistUser = appUser?.role_company === 'ARTIST_CATALOGATION';

	const isGalleryUser = appUser?.role_company === 'GALLERY';

	const { t } = useTranslation();

	const changePostulationStatus = async (status) => {
		if (isArtistUser || isCatalogueArtistUser || isGalleryUser) {
			await putPostulationStatusMutationArtist.mutateAsync({
				status,
				postulation_id: postulationSingle.id,
			});
		} else {
			await putPostulationStatusMutationOrganization.mutateAsync({
				status,
				postulation_id: postulationSingle.id,
			});
		}
	};

	useEffect(() => {
		if (
			postulationSingle.status === 'SUBMITTED' &&
			appUser.role_company === 'ORGANIZATION'
		) {
			changePostulationStatus('READ');
		}
		// eslint-disable-next-line
	}, [postulationSingle.status, appUser.role_company]);

	useEffect(() => {
		if (
			Array.isArray(postulationSingle?.portfolios) &&
			postulationSingle.portfolios.length > 0
		) {
			const slug =
				postulationSingle.portfolios[postulationSingle.portfolios.length - 1]
					.portfolio_slug;
			dispatch(getPortfolioBySlug(slug)).then((data) =>
				setPortfolioData(data.payload.portfolio)
			);
		}
	}, [dispatch, postulationSingle.portfolios]);

	const getBenefitIcon = (benefitType) => {
		switch (benefitType) {
			case 'ECONOMICO':
				return <MoneyIcon />;
			default:
				return <StarIcon />;
		}
	};

	const buttonDownloadableForm = postulationSingle.forms?.length > 0 && (
		<PDFDownloadLink
			document={<DownloadableForm formOptions={postulationSingle} />}
			fileName={`Postulación - ${postulationSingle.organization_user_name}`}
			className='flex flex-row place-items-center whitespace-nowrap rounded-md px-2'
		>
			{({ loading }) => (
				<div
					className={`flex flex-row place-items-center gap-2 whitespace-nowrap rounded-md ${
						loading && 'cursor-wait opacity-80'
					}`}
				>
					{loading ? <LoadingSpinnerSmallBlack /> : <DownloadBlack />}
					<TextContentSecond text={t('DescargarFormulario')} className='flex' />
				</div>
			)}
		</PDFDownloadLink>
	);

	const buttonDownloadableCatalogue = (
		<PDFDownloadLink
			document={
				<DownloadableCatalogue
					artworks={postulationSingle.artworks}
					artist={postulationSingle}
				/>
			}
			fileName={`Catalogo - ${postulationSingle.organization_user_name}`}
			className='flex flex-row place-items-center gap-2 whitespace-nowrap rounded-md px-2'
		>
			{({ loading }) => (
				<div
					className={`flex flex-row place-items-center gap-2 whitespace-nowrap rounded-md ${
						loading && 'cursor-wait opacity-80'
					}`}
				>
					{loading ? <LoadingSpinnerSmallBlack /> : <DownloadBlack />}
					<TextContentSecond text={t('DescargarCatalogo')} className='flex' />
				</div>
			)}
		</PDFDownloadLink>
	);

	const handleTypePortfolio = () => {
		if (portfolioData?.type === 'COLLECTION') {
			return <DownloadablePortfolio portfolio={portfolioData} />;
		} else if (portfolioData?.type === 'EXPERT') {
			return (
				<DownloadableExpertPortfolio expertPortfolioData={portfolioData} />
			);
		}
	};

	const buttonDownloadablePortfolio = (
		<PDFDownloadLink
			document={handleTypePortfolio()}
			fileName={`Portafolio - ${postulationSingle.organization_user_name}`}
			className='flex flex-row place-items-center gap-2 whitespace-nowrap rounded-md px-2'
		>
			{({ loading }) => (
				<div
					className={`flex flex-row place-items-center gap-2 whitespace-nowrap rounded-md ${
						loading && 'cursor-wait opacity-80'
					}`}
				>
					{loading ? <LoadingSpinnerSmallBlack /> : <DownloadBlack />}
					<TextContentSecond text={t('DescargarPortafolio')} className='flex' />
				</div>
			)}
		</PDFDownloadLink>
	);

	const buttonDownloadableCV = postulationSingle.documents?.document_name && (
		<button
			className={`flex flex-row place-items-center gap-2 whitespace-nowrap rounded-md px-2`}
			onClick={() => {
				postulationSingle.documents &&
					downloadDocument(
						postulationSingle.documents.document_url,
						`Hoja de vida - ${postulationSingle.organization_user_name}`
					);
			}}
		>
			<DownloadBlack />
			<TextContentSecond text={t('DescargarCV')} className='flex' />
		</button>
	);

	const postulationStatusText = () => {
		switch (postulationSingle.status) {
			case 'SUBMITTED':
				return {
					text: 'Nueva',
					color: 'text-blue-500',
				};
			case 'READ':
				return {
					text: 'Leída',
					color: 'text-gray-500',
				};
			case 'APPROVED':
				return {
					text: 'Aprobada',
					color: 'text-green-500',
				};
			case 'SAVED':
				return {
					text: 'Guardada',
					color: 'text-purple-500',
				};
			case 'REJECTED':
				return {
					text: 'Rechazada',
					color: 'text-red-500',
				};
			case 'REVIEWED':
				return {
					text: 'Revisada',
					color: 'text-light_green-50',
				};
			default:
				return '';
		}
	};

	let defaultImage =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';

	const previousPage = sessionStorage.getItem('previousPage');

	if (appUser.role_company === 'ORGANIZATION') {
		return (
			<TemplateWithNavBar>
				<div className='relative flex w-full flex-col'>
					<div className='mt-8 flex justify-between'>
						<ButtonLinkBack
							textBack={
								previousPage === 'singleCall' && `Regresar a la convocatoria`
							}
							redirect={
								previousPage === 'singleCall'
									? `/convocatorias/${singleCall.route_slug}`
									: '/postulaciones'
							}
							design='md:hidden'
						/>
						<ButtonLinkBackText
							textBack={
								previousPage === 'singleCall' && `Regresar a la convocatoria`
							}
							design='hidden md:block md:w-1/4'
							textBackDesign='pl-3 text-purple-50'
							redirect={
								previousPage === 'singleCall'
									? `/convocatorias/${singleCall.route_slug}`
									: '/postulaciones'
							}
							arrowLeftColor='purple'
						/>
						<TextMainTitle
							title={singleCall?.name}
							textColor='text-purple-50'
							color='bg-purple-50'
							design='text-center flex flex-col'
						/>
						<div className='flex md:w-1/4 md:justify-end'>
							{postulationSingle.status !== 'APPROVED' &&
								postulationSingle.status !== 'REJECTED' && (
									<div>
										<Save
											className={`cursor-pointer ${
												postulationSingle.status === 'SAVED' &&
												'fill-purple-500'
											}`}
											onClick={() => {
												if (postulationSingle.status === 'SAVED') {
													changePostulationStatus('READ');
												} else {
													changePostulationStatus('SAVED');
												}
											}}
										/>
									</div>
								)}
						</div>
					</div>
					<div className='flex w-full flex-col px-4 md:px-8 md:py-6 lg:flex-row lg:justify-between lg:gap-14'>
						<div className='flex w-full flex-wrap py-6 xxs:flex-nowrap md:items-center lg:py-2'>
							<div className='flex w-full items-center gap-4 '>
								<div className='h-16 w-16 md:h-20 md:w-20 '>
									<LazyLoadImage
										alt={postulationSingle.name ?? ''}
										placeholderSrc={
											postulationSingle.artist_main_image
												? `${postulationSingle.artist_main_image}_small`
												: defaultImage
										}
										src={
											postulationSingle.artist_main_image
												? `${postulationSingle.artist_main_image}_small`
												: defaultImage
										}
										width='100%'
										height='100%'
										effect='blur'
										wrapperClassName='overflow-hidden w-16 aspect-square rounded-full flex items-center lazyLoadingImages'
										className='object-cover'
										delayTime='0'
										threshold='800'
									/>
								</div>
								<div className='flex w-max flex-col items-center justify-between gap-2 lg:w-full lg:flex-row lg:justify-around lg:gap-10 '>
									<TextContentPrincipal
										text={postulationSingle.organization_user_name}
										style={{ fontWeight: 700 }}
									/>
									<TextContentSecond
										design={`break-all lg:mt-1 w-full text-left lg:text-right lg:w-max`}
										text={`Creado el ${format(
											new Date(
												handleTimeZoneOffset(
													postulationSingle.status_updated_at
												) ?? postulationSingle.updated_at
											),
											'dd/MM/yyyy'
										)}  `}
									/>
								</div>
							</div>
							<div className='ml-2 flex h-full items-center xxs:ml-0'>
								<TextContentPrincipal
									text={`${t(postulationStatusText().text)}`}
									design={`flex mt-3 md:mt-0 md:px-10 ${
										postulationStatusText().color
									}`}
									style={{ fontWeight: 400 }}
								/>
							</div>
						</div>
						{postulationSingle.status !== 'APPROVED' &&
							postulationSingle.status !== 'REJECTED' && (
								<section className='flex w-full items-center justify-center  pb-4 lg:pb-0'>
									<ButtonFabAdmin
										icon={CheckGreen}
										label={t('Aprobar')}
										textColor={'text-green-50'}
										containerDesign={'lg:w-28 lg:h-10 lg:px-8 lg:flex-row'}
										action={() => {
											changePostulationStatus('APPROVED');
										}}
									/>
									<ButtonFabAdmin
										icon={CrossRed}
										label={t('Rechazar')}
										textColor={'text-strong_red-50'}
										containerDesign={
											'mx-3 sm:mx-6 lg:mx-3 lg:w-28 lg:h-10 lg:px-8 lg:flex-row'
										}
										action={() => {
											changePostulationStatus('REJECTED');
										}}
									/>
									<ButtonFabAdmin
										icon={DownloadYellow}
										label={`${t('Descargar')}`}
										textColor={'text-strong_yellow-50'}
										containerDesign={'lg:w-28 lg:h-10 lg:px-8 lg:flex-row'}
										action={() => {
											setShowPopupDownload(!showPopupDownload);
										}}
									/>
									<div className='relative ml-3 h-10 w-14 cursor-pointer  sm:ml-6 lg:mx-3 lg:w-28 lg:flex-row'>
										<div className='w-20 pt-1 lg:w-28'>
											<DotsVertical
												className='rounded-full  duration-1000 hover:bg-gray-200'
												data-test={'iconForReviewedButton'}
												onClick={() => {
													setShowReviewedButton(!showReviewedButton);
												}}
											/>
										</div>

										{showReviewedButton && (
											<div className='absolute -bottom-14 left-0  lg:-bottom-12  '>
												<ButtonFabAdmin
													label={`${t('Revisada')}`}
													textColor={'text-light_green-50'}
													containerDesign={
														'w-[65px] sm:w-20 lg:w-28 lg:h-10  lg:flex-row'
													}
													action={() => {
														changePostulationStatus('REVIEWED');
													}}
												/>
											</div>
										)}
									</div>
									{showPopupDownload && (
										<PopupIconAndOptions
											buttonsPopup={
												<>
													{buttonDownloadableForm && buttonDownloadableForm}
													{postulationSingle?.call_type === 'CATALOGUE' &&
														buttonDownloadableCatalogue}
													{postulationSingle?.portfolio_completed ===
														'Completed' &&
														portfolioData &&
														buttonDownloadablePortfolio}
													{buttonDownloadableCV && buttonDownloadableCV}
												</>
											}
											showPopup={false}
											setShowPopup={setShowPopupDownload}
											designPopup='!right-0'
										/>
									)}
								</section>
							)}
					</div>

					<div className='mb-8 mt-4 flex w-full flex-col gap-4'>
						{((Array.isArray(postulationSingle.artworks) &&
							postulationSingle.artworks.length > 0) ||
							(Array.isArray(postulationSingle.portfolios) &&
								postulationSingle.portfolios.length > 0) ||
							postulationSingle.forms?.length > 0 ||
							postulationSingle.documents?.document_name) && (
							<section>
								<TextTitleSub
									title='Documentos Anexados'
									textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2 mb-4'
									style={{
										fontWeight: '700',
									}}
								/>
								<div className='flex w-full flex-col gap-4'>
									{Array.isArray(postulationSingle.artworks) &&
										postulationSingle.artworks.length > 0 && (
											<CardLink
												title={'Catalogo'}
												redirect={`/postulaciones/${postulationSingle.id}/catalogo`}
												redirectText={'Ver catálogo'}
												text={`${postulationSingle.artworks?.length} obras adjuntadas`}
												isShowArrowOnMobile={true}
											/>
										)}
									{Array.isArray(postulationSingle.portfolios) &&
										postulationSingle.portfolios.length > 0 && (
											<CardLink
												title={'Portafolio'}
												redirectText={'Ver portafolio'}
												redirect={`/portafolio/${
													postulationSingle.portfolios[
														postulationSingle.portfolios.length - 1
													].portfolio_slug
												}`}
												text={`Portafolio anexado: ${
													postulationSingle.portfolios[
														postulationSingle.portfolios.length - 1
													].portfolio_slug
												}`}
												isShowArrowOnMobile={true}
											/>
										)}
									{postulationSingle.forms?.length > 0 && (
										<CardLink
											title={'Formulario'}
											text={`Diligenciado`}
											redirectText={'Ver formulario'}
											redirect={`/postulaciones/${postulationSingle.id}/formulario`}
											isShowArrowOnMobile={true}
										/>
									)}
									{postulationSingle.documents?.document_name && (
										<CardLink
											title={'Curriculum vitae (CV)'}
											redirectText={'Ver documento'}
											text={`Documento adjuntado: ${postulationSingle.documents?.document_name}`}
											redirect={`/postulaciones/${postulationSingle.id}/cv`}
											isShowArrowOnMobile={true}
										/>
									)}
								</div>
							</section>
						)}
					</div>
				</div>
				{showSharePostulation && (
					<div
						className='fixed right-0 top-0 z-40 flex h-full w-full cursor-pointer bg-black-50/70 bg-opacity-70 shadow-xl'
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<SharePortfolio
							title={'Compartir postulación'}
							share={showSharePostulation}
							setShare={setShowSharePostulation}
							designTwo={
								'fixed inset-0 bg-black-50 bg-opacity-75 transition-opacity'
							}
						/>
					</div>
				)}
			</TemplateWithNavBar>
		);
	}
	return (
		<TemplateWithNavBar>
			<>
				<div className='relative flex w-full flex-col'>
					<div className='mt-8 flex justify-between'>
						<ButtonLinkBack
							textBack={
								previousPage === 'singleCall' && `Regresar a la convocatoria`
							}
							redirect={
								previousPage === 'singleCall'
									? `/convocatorias/${singleCall.route_slug}`
									: '/postulaciones'
							}
							design='md:hidden'
						/>
						<ButtonLinkBackText
							textBack={
								previousPage === 'singleCall' && `Regresar a la convocatoria`
							}
							design='hidden md:block md:w-1/4'
							textBackDesign='pl-3 text-purple-50'
							redirect={
								previousPage === 'singleCall'
									? `/convocatorias/${singleCall.route_slug}`
									: '/postulaciones'
							}
							arrowLeftColor='purple'
						/>
						<TextMainTitle
							title={singleCall.name}
							textColor='text-purple-50'
							color='bg-purple-50'
							design='text-center flex flex-col'
						/>
						<div className='flex md:w-1/4 md:justify-end'>
							<div
								className='w-max'
								onClick={() => setShowSharePostulation(true)}
							>
								<Share className='cursor-pointer' />
							</div>
						</div>
					</div>

					<CardPostulationSingle
						logo={singleCall.call_organization_image}
						title={singleCall.title}
						image={singleCall.main_picture_url}
						organization={singleCall.call_organization_name}
						country={singleCall.country}
						city={singleCall.city}
						status={postulationSingle.status}
						hasAlliance={singleCall.has_datarte_alliance}
					/>

					<div className='mt-4 flex w-full flex-col gap-4'>
						<section>
							<TextTitleSub
								title='Descripción'
								textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2'
								style={{
									fontWeight: '700',
								}}
							/>
							<TextContentSecond
								text={singleCall?.sub_header}
								design={'break-words whitespace-pre-line w-full py-4'}
							/>
						</section>
						<section>
							<TextTitleSub
								title='Detalles'
								textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2 mb-4'
								style={{
									fontWeight: '700',
								}}
							/>

							{singleCall?.has_benefit && (
								<CardDropdown
									title={'Beneficios'}
									titleDesign={'text-purple-50 font-bold'}
									titleStyle={{
										fontWeight: '700',
									}}
									disableDropdown={true}
								>
									<div className='flex w-full grid-cols-3 flex-col flex-wrap md:grid md:gap-4'>
										{singleCall.benefits?.map((benefit, index) => (
											<div
												key={index}
												className='flex w-full items-start gap-4'
											>
												<div className='flex aspect-square w-16 flex-col items-center justify-start py-4'>
													{getBenefitIcon(benefit.benefit_type)}
												</div>
												<CardTextIcon
													title={benefit.benefit_name}
													description={benefit.benefit_description}
													componentDesign='w-full'
												/>
											</div>
										))}
									</div>
								</CardDropdown>
							)}

							<CardDropdown
								title={'Fechas importantes'}
								titleDesign={'text-purple-50'}
								titleStyle={{
									fontWeight: '700',
								}}
								disableDropdown
							>
								<div className='flex w-full grid-cols-3 flex-col flex-wrap md:grid md:gap-4'>
									<CardTextIcon
										title={'Apertura'}
										description={dateTimeLongFormat(singleCall.opens_at)}
										componentDesign='w-full'
									/>
									<CardTextIcon
										title={'Cierre'}
										description={dateTimeLongFormat(singleCall.closes_at)}
										componentDesign='w-full'
									/>
									<CardTextIcon
										title={'Resultados'}
										description={dateTimeLongFormat(singleCall.results_at)}
										componentDesign='w-full'
									/>
								</div>
							</CardDropdown>

							{singleCall?.judge_is_public && singleCall.has_judge && (
								<CardDropdown
									title={'Jurados'}
									titleDesign={'text-purple-50'}
									titleStyle={{
										fontWeight: '700',
									}}
								>
									<div className='flex w-full grid-cols-3 flex-col flex-wrap md:grid md:gap-4'>
										{singleCall.judges?.map((judge, index) => (
											<CardTextIcon
												title={judge.judge_name}
												description={judge.judge_description}
												image={judge.judge_main_picture}
												componentDesign='w-full'
												key={index}
											/>
										))}
									</div>
								</CardDropdown>
							)}
						</section>

						{(singleCall.call_organization_name ||
							singleCall.call_organization_description ||
							singleCall.call_organization_email ||
							singleCall.call_organization_phone ||
							singleCall.call_organization_website) && (
							<section>
								<TextTitleSub
									title='Organizado por'
									textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2 mb-4'
									style={{
										fontWeight: '700',
									}}
								/>
								<div className='flex items-center gap-4 py-2'>
									{singleCall.call_organization_image &&
										singleCall.call_organization_name && (
											<LazyLoadImage
												alt={singleCall.call_organization_name}
												src={singleCall.call_organization_image}
												effect='blur'
												wrapperClassName='w-12 aspect-square rounded-full flex items-center lazyLoadingImages overflow-hidden'
												className='max-h-full object-contain'
												delayTime='0'
												threshold='800'
											/>
										)}
									{singleCall.call_organization_name && (
										<TextContentSecond
											text={singleCall.call_organization_name}
										/>
									)}
								</div>
								{singleCall.call_organization_description && (
									<div className='flex w-full flex-col gap-4 py-2'>
										<TextContentSecond
											text={'Descripción'}
											style={{ fontWeight: 700 }}
										/>
										<TextContentSecond
											text={singleCall.call_organization_description}
										/>
									</div>
								)}
								<div className='flex w-full grid-cols-3 flex-col flex-wrap md:grid md:gap-6'>
									{singleCall.call_organization_email && (
										<CardTextIcon
											title={'Email'}
											description={singleCall.call_organization_email}
											descriptionDesign={'text-sky-300'}
										/>
									)}
									{singleCall.call_organization_phone && (
										<CardTextIcon
											title={'Contacto'}
											description={singleCall.call_organization_phone}
											descriptionDesign={'text-sky-300'}
										/>
									)}
									{singleCall.call_organization_website && (
										<CardTextIcon
											title={'Página web'}
											description={singleCall.call_organization_website}
											descriptionDesign={'text-sky-300'}
										/>
									)}
								</div>
							</section>
						)}

						{((Array.isArray(postulationSingle.artworks) &&
							postulationSingle.artworks.length > 0) ||
							(Array.isArray(postulationSingle.portfolios) &&
								postulationSingle.portfolios.length > 0) ||
							singleCall.has_form ||
							postulationSingle.documents?.document_name) && (
							<section>
								<TextTitleSub
									title='Documentos Anexados'
									textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2 mb-4'
									style={{
										fontWeight: '700',
									}}
								/>
								<div className='flex w-full flex-col gap-4'>
									{postulationSingle.artworks && (
										<CardLink
											title={'Catalogo'}
											text={`Diligenciado`}
											redirect={`/postulaciones/${postulationSingle.id}/catalogo`}
											redirectText={'Ver catálogo'}
											designRedirect='w-1/4 md:w-auto'
										/>
									)}
									{Array.isArray(postulationSingle.portfolios) &&
										postulationSingle.portfolios.length > 0 && (
											<CardLink
												title={'Portafolio'}
												text={`Portafolio anexado: ${
													postulationSingle.portfolios[
														postulationSingle.portfolios.length - 1
													].portfolio_slug
												}`}
												redirectText={'Ver portafolio'}
												redirect={`/portafolio/${
													postulationSingle.portfolios[
														postulationSingle.portfolios.length - 1
													].portfolio_slug
												}`}
												designRedirect='w-1/4 md:w-auto'
											/>
										)}
									{singleCall.has_form && (
										<CardLink
											title={'Formulario'}
											text={`Diligenciado`}
											redirectText={'Ver formulario'}
											redirect={`/postulaciones/${postulationSingle.id}/formulario`}
											designRedirect='w-1/4 md:w-auto'
										/>
									)}
									{singleCall.has_cv && (
										<CardLink
											title={'Curriculum vitae (CV)'}
											text={`Documento adjuntado: ${postulationSingle.documents?.document_name}`}
											redirectText={'Ver documento'}
											redirect={`/postulaciones/${postulationSingle.id}/cv`}
											designRedirect='w-1/4 md:w-auto'
										/>
									)}
								</div>
							</section>
						)}

						<section className='pb-10 pt-4'>
							<TextTitleSub
								title='Términos y Condiciones'
								textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2 mb-4'
								style={{
									fontWeight: '700',
								}}
							/>
							<a
								href={'https://www.datarte.art/tycdatarte'}
								target='_blank'
								rel='noreferrer'
							>
								<TextContentSecond
									text={'Términos y condiciones de uso.'}
									design={'text-[#34B3E5] underline'}
								/>
							</a>
						</section>
					</div>
				</div>
				{showSharePostulation && (
					<div
						className='fixed right-0 top-0 z-40 flex h-full w-full cursor-pointer bg-black-50/70 bg-opacity-70 shadow-xl'
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<SharePortfolio
							title={'Compartir postulación'}
							share={showSharePostulation}
							setShare={setShowSharePostulation}
							designTwo={
								'fixed inset-0 bg-black-50 bg-opacity-75 transition-opacity'
							}
						/>
					</div>
				)}
			</>
		</TemplateWithNavBar>
	);
};

export default SubmittedPostulation;
