import packageInfo from '../package.json';
//BL
import AppRouter from './BusinessLogic/routers/AppRouter';
import { useCheckStorageVersion } from './BusinessLogic/hooks/useCheckStorageVersion';
//UI
import MainProvider from './UI/templates/providers/MainProvider';

/** @description This is a functional component for redux, redux persist configuration and rooter called
 *  @version 1.0.0
 *  @since 04/02/2022
 *  @author ITLOOKSSIMPLE
 *
 *  @function
 *  @name App
 *  @returns {Component} Returns the configuration and router
 **/

const App = () => {
	let version = packageInfo.version;
	useCheckStorageVersion(version);
	return (
		<MainProvider>
			<AppRouter />
		</MainProvider>
	);
};

export default App;
