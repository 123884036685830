//3rd party
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

//UI
import PortfolioArtworks from './PortfolioArtworks';

/**
 * @description used to display subcollections related to the portfolio
 */

export default function PortfolioSubcollection({
	subcollection,
	image_quality,
	artistName,
}) {
	return (
		<div>
			<div className='mb-10 mt-2 md:mt-5'>
				<h3 className='mb-8 text-center font-louisGeorgeCafe text-xl font-semibold md:text-3xl'>
					{subcollection.name}
				</h3>
				<div className='flex w-full flex-col-reverse md:flex-col'>
					<p className='w-full whitespace-pre-line text-left text-base font-light text-gray-800 md:mb-8 md:text-xl'>
						{subcollection.description}
					</p>
					<div className='mb-8 flex w-full flex-row justify-center md:mb-0'>
						{!subcollection?.main_picture?.includes('default_image') && (
							<LazyLoadImage
								src={subcollection.main_picture}
								placeholderSrc={subcollection.main_picture + '_small'}
								alt={subcollection.name}
								effect='blur'
								wrapperClassName='w-full'
								className='max-h-screen w-full object-contain'
							/>
						)}
					</div>
				</div>
			</div>
			<PortfolioArtworks
				artworks={subcollection.elements}
				image_quality={image_quality}
				artistName={artistName}
			/>
		</div>
	);
}

PortfolioSubcollection.propTypes = {
	/** array that passes all the data to the next section  */
	subcollection: PropTypes.object,
	/** determines the image quality of the artworks images */
	image_quality: PropTypes.string,
	//** Name of the artist */
	artistName: PropTypes.string,
};
