//Third parties
import PropTypes from 'prop-types';

import { Field } from 'formik';
import { ListItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

//BL

//UI
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';

const InputField = ({ item, key, errors, onBlurEvent }) => {
	const { t } = useTranslation();

	const renderByField = () => {
		switch (item.field) {
			case 'slug':
				return (
					<div className='flex w-40 md:w-56'>
						<span
							className={
								'body-Text2 h-6 w-8  bg-light_grey-50 text-right text-xs text-grey-50 md:text-sm'
							}
						>
							.../
						</span>
						<Field
							name={item.field}
							type={item.type ?? 'text'}
							className='body-Text2 h-6 w-full rounded border-none bg-light_grey-75 px-1 lowercase text-grey-50'
							onBlur={onBlurEvent}
						/>
					</div>
				);
			default:
				return (
					<Field
						name={item.field}
						type={item.type ?? 'text'}
						className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-75 px-1 text-grey-50 md:w-56'
					/>
				);
		}
	};

	return (
		<div key={key}>
			<ListItem
				style={{
					paddingLeft: '0px',
					paddingRight: '0px',
					justifyContent: 'space-between',
				}}
			>
				<div className='w-32'>
					<TextContentSecond text={t(item.label)} style={{ fontWeight: 400 }} />
				</div>
				{renderByField()}
			</ListItem>
			{errors && <div className='ml-auto text-red-400'>{t(errors)}</div>}
		</div>
	);
};

InputField.propTypes = {
	/** item for the the section*/
	item: PropTypes.object,
	/** key*/
	key: PropTypes.number,
	/** this error string is generated from te yup validation schema in case of a missing requerid field*/
	errors: PropTypes.string,
	/** this function wil be run when slug field change*/
	onBlurEvent: PropTypes.string,
};

export default InputField;
