//3rd party
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

// BL

import { handleTimeZoneOffset } from '../../../BusinessLogic/helpers/dateFormats';

//UI
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import CardOrganization from '../../molecules/Card/CardOrganization';

/**
 * @description render a section with its title and a grid of calls
 * @param {*} param0
 * @returns
 */
function SectionPostulationsGrid({ data, redirect }) {
	const { t } = useTranslation();
	return (
		<div>
			{!(Array.isArray(data) && data.length > 0) ? (
				<div className='flex justify-center py-8'>
					<TextContentSecond text={t('NoHayPostulacionesPorElMomento')} />
				</div>
			) : (
				<div
					className={
						'mt-10 flex w-full flex-col gap-y-11 px-6 md:mt-4 md:grid md:grid-cols-2 md:gap-x-36 md:px-12 lg:px-22 xl:px-32'
					}
					data-test='postulations-grid'
				>
					{Array.isArray(data) &&
						data.length >= 1 &&
						data.map((postulation, index) => {
							return (
								<CardOrganization
									key={index}
									id={postulation.id}
									name={postulation.call_name}
									date={format(
										new Date(
											handleTimeZoneOffset(postulation.status_updated_at) ??
												postulation.created_at
										),
										'dd/MM/yyyy'
									)}
									status={postulation.status}
									image={postulation.call_main_picture_url}
									redirect={redirect}
								/>
							);
						})}
				</div>
			)}
		</div>
	);
}

SectionPostulationsGrid.propTypes = {
	/** Has the data to show the postulations information */
	data: PropTypes.array,
	/** URL link to redirect */
	redirect: PropTypes.string,
};

export default SectionPostulationsGrid;
