//3rd parties
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//BL
import { plantillas } from '../../../BusinessLogic/data/Plantilla';
import { setUniqueIdCollection } from '../../../BusinessLogic/redux/reducers/portfolio';

//UI
import ImagesSectionPlantillas from '../../../UI/organisms/Image/ImagesSectionPlantillas';
import ButtonFabPrincipalUrl from '../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';

/**
 * @description manages the Plantilla All page.
 * @param {*} param0
 * @returns
 */
export default function PlantillaAll() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { uniqueIdChosen } = useSelector((state) => state.portfolios);

	useEffect(() => {
		dispatch(setUniqueIdCollection(null));
	}, [dispatch]);

	return (
		<TemplateWithNavBar section={'Portafolios'}>
			<NavbarSecondaryNew
				title={t('plantillas')}
				color='bg-yellow-50'
				textColor='text-yellow-50'
				redirect='/portafolios/plantillas'
				textBack={t('RegresarPlantillas')}
				textBackDesign='pl-3 text-yellow-50'
				showDots={false}
				arrowLeftColor='yellow'
				containerDesign={'pb-3 md:pb-5'}
			/>
			<div className='mb-8 flex w-full justify-center'>
				<TextContentSecond
					text={t('Paso2')}
					paragraphDesign={'text-yellow-50 text-2xl font-bold'}
				/>
			</div>
			<ImagesSectionPlantillas
				data={plantillas}
				redirect='plantilla'
				changeView={false}
			/>
			{uniqueIdChosen && (
				<ButtonFabPrincipalUrl
					label={t('Continuar')}
					url='/plantilla/colecciones/automatic'
					icon={false}
				/>
			)}
		</TemplateWithNavBar>
	);
}
