// 3rd Party
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

// UI
import DownloadableExpertSlideCover from '../../../organisms/Portfolio/ExpertSlides/DownloadableExpertSlides/DownloadableExpertSlideCover';
import DownloadableExpertSlideTextBlockAndImage from '../../../organisms/Portfolio/ExpertSlides/DownloadableExpertSlides/DownloadableExpertSlideTextBlockAndImage';
import DownloadableExpertSlideArtworksGroup from '../../../organisms/Portfolio/ExpertSlides/DownloadableExpertSlides/DownloadableExpertSlideArtworksGroup';
import DownloadableExpertSlideBackCover from '../../../organisms/Portfolio/ExpertSlides/DownloadableExpertSlides/DownloadableExpertSlideBackCover';
import DownloadableExpertSlideTextBlock from '../../../organisms/Portfolio/ExpertSlides/DownloadableExpertSlides/DownloadableExpertSlideTextBlock';
import DownloadableExpertSlideFullScreenImage from '../../../organisms/Portfolio/ExpertSlides/DownloadableExpertSlides/DownloadableExpertSlideFullScreenImage';
import DownloadableExpertSlideArtworkIndividual from '../../../organisms/Portfolio/ExpertSlides/DownloadableExpertSlides/DownloadableExpertSlideArtworkIndividual';

/**
 * @description Download expert portfolio
 * @param
 * @returns
 */

const styles = StyleSheet.create({
	page: {
		padding: '32px 40px',
	},
});

export default function DownloadableExpertPortfolio({ expertPortfolioData }) {
	const isMainSlideCover = (slide) => slide.order === 1;
	const expertPortfolioSlides = expertPortfolioData?.slides;

	const artworkFieldsToShow = {
		showTitle: expertPortfolioData?.show_title,
		showDate: expertPortfolioData?.show_date,
		showMeasures: expertPortfolioData?.show_measures,
		showPrices: expertPortfolioData?.show_prices,
		showTechnique: expertPortfolioData?.show_technique,
	};

	return (
		<Document>
			{expertPortfolioSlides &&
				Array.isArray(expertPortfolioSlides) &&
				[...expertPortfolioSlides]
					.sort((a, b) => a.order - b.order)
					.map((slide, index) => (
						<Page key={index} style={styles.page} wrap={true}>
							{slide.type === 'cover' && (
								<DownloadableExpertSlideCover
									slideItems={slide.slideItems}
									isMainCover={isMainSlideCover(slide)}
								/>
							)}
							{slide.type === 'artworksGroup' && (
								<DownloadableExpertSlideArtworksGroup
									artworksGroup={slide.slideItems}
									artworkFieldsToShow={artworkFieldsToShow}
								/>
							)}
							{slide.type === 'artworkIndividual' && (
								<DownloadableExpertSlideArtworkIndividual
									artworkElements={slide.slideItems}
									artworkFieldsToShow={artworkFieldsToShow}
								/>
							)}
							{slide.type === 'fullScreenImage' && (
								<DownloadableExpertSlideFullScreenImage
									imageElement={slide.slideItems}
								/>
							)}
							{slide.type === 'textBlockAndImage' && (
								<DownloadableExpertSlideTextBlockAndImage
									slideItems={slide.slideItems}
								/>
							)}
							{slide.type === 'backCover' && (
								<DownloadableExpertSlideBackCover
									slideItems={slide.slideItems}
								/>
							)}
							{slide.type === 'textBlock' && (
								<DownloadableExpertSlideTextBlock
									slideItems={slide.slideItems}
								/>
							)}
						</Page>
					))}
		</Document>
	);
}

DownloadableExpertPortfolio.propTypes = {
	// expert portfolio data
	expertPortfolioData: PropTypes.object,
};
