//3rd
import React, { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

//BL
import { useDispatch, useSelector } from 'react-redux';
import { getAllArtworksImages } from '../../../BusinessLogic/redux/reducers/artworkImage';
import { useState } from 'react';
import {
	getCollection,
	getCollections,
	postCollection,
	updateNewCollection,
} from '../../../BusinessLogic/redux/reducers/collection';

//UI
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import { ReactComponent as Cross } from '../../../UI/assets/icons/Cross.svg';
import { ReactComponent as Check } from '../../../UI/assets/icons/Check.svg';
import TextContentPrincipal from '../../../UI/atoms/Text/Content/TextContentPrincipal';
import ButtonFabPrincipalUrl from '../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';

const AllImages = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const { id } = useParams();
	const { allArtworksImages } = useSelector((state) => state.artworkImage);
	const { newCollection, collectionSingle } = useSelector(
		(state) => state.collections
	);
	const isNewCollection = id ? false : true;
	const isSubcollection = newCollection.collection_type === 'SUB_COLLECTION';
	const redirect = isNewCollection
		? isSubcollection
			? `/colecciones/${newCollection.parent_id}/new`
			: '/colecciones/new'
		: `/colecciones/${id}`;

	const initialImageSelected = isNewCollection
		? {
				picture_name: newCollection.picture_name,
				picture_url: newCollection.picture_url,
				picture_id: newCollection.picture_id,
		  }
		: {
				picture_name: collectionSingle?.images[0].name,
				picture_url: collectionSingle?.main_picture_url,
				picture_id: collectionSingle?.main_picture_id,
		  };

	const [selectedImage, setSelectedImage] = useState(initialImageSelected);
	const [isSavingImage, setIsSavingImage] = useState(false);

	useEffect(() => {
		if (allArtworksImages?.length <= 0) {
			dispatch(getAllArtworksImages());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleImageSelection = (image) => {
		selectedImage?.image_id !== image.image_id
			? setSelectedImage({
					picture_name: image.name,
					picture_url: image.url,
					picture_id: image.image_id,
			  })
			: setSelectedImage({
					picture_name: null,
					picture_url: null,
					picture_id: null,
			  });
	};

	const saveImageSelection = () => {
		setIsSavingImage(true);
		if (isNewCollection) {
			selectedImage.picture_id
				? dispatch(
						updateNewCollection({
							...newCollection,
							picture_name: selectedImage.picture_name,
							picture_url: selectedImage.picture_url,
							picture_id: selectedImage.picture_id,
							large_file: null,
							medium_file: null,
							small_file: null,
							url_file: null,
						})
				  )
				: dispatch(
						updateNewCollection({
							...newCollection,
							picture_name: null,
							picture_url: null,
							picture_id: null,
						})
				  );
			navigate(redirect);
		} else {
			if (selectedImage.picture_id !== collectionSingle?.main_picture_id) {
				const values = {
					type: 'COLLECTION',
					type_id: id,
					organization_user_id: collectionSingle.organization_user_id,
					file_type: 'IMAGE',
					picture_id: selectedImage.picture_id,
					new_collection: false,
				};
				dispatch(postCollection(values)).finally(() => {
					dispatch(getCollection(id));
					dispatch(getCollections());
					navigate(redirect);
				});
			} else {
				navigate(redirect);
			}
		}
	};

	return (
		<TemplateWithNavBar
			design={`mx-5 md:mx-0 ${isSavingImage && 'opacity-60'}`}
		>
			<NavbarSecondaryNew
				title={t('SeleccionarImagen')}
				textColor='text-green-50'
				color='bg-green-50'
				showDots={false}
				textBackDesign='pl-3 text-green-50'
				arrowLeftColor='green'
				redirect={redirect}
			/>
			<div className='pb-8 md:h-5/6 md:px-8'>
				<div className='mx-auto flex flex-row items-center justify-between py-3 md:mx-0 md:w-1/4'>
					<TextContentPrincipal
						design={'text-grey-50'}
						text={t(`SeleccionarUnaImagen`)}
						style={{ fontWeight: 400 }}
					/>
					<div className='flex flex-row gap-4'>
						<Cross
							className='h-6 w-6 cursor-pointer'
							onClick={() => navigate(redirect)}
						/>
						<Check
							className='h-6 w-6 cursor-pointer'
							onClick={() => saveImageSelection()}
						/>
					</div>
				</div>
				<div className='flex h-full flex-col justify-end gap-4 md:flex-row'>
					{selectedImage.picture_id && (
						<div className='flex h-40 w-full overflow-y-hidden overflow-x-scroll bg-light_grey-75 md:h-screen md:w-1/4 md:overflow-x-hidden'>
							<div className='mx-auto flex max-h-full w-3/5 flex-row flex-nowrap justify-center gap-5 p-4 md:flex-col md:justify-start'>
								<div
									style={{ aspectRatio: '1/1' }}
									className='cursor-pointer border-4 border-solid border-yellow-50'
									onClick={() => handleImageSelection(selectedImage)}
								>
									<LazyLoadImage
										alt={selectedImage.picture_name}
										src={selectedImage.picture_url + '_small'}
										height='100%'
										width='100%'
										effect='blur'
										wrapperClassName={`overflow-hidden flex items-center lazyLoadingImages lazy-load-image-background blur lazy-load-image-loaded`}
										className='max-h-full max-w-full '
										delayTime='0'
										threshold='800'
										style={{
											objectFit: 'contain',
											aspectRatio: '1/1',
										}}
									/>
								</div>
							</div>
						</div>
					)}
					<div
						className={` h-45 flex flex-col gap-4 ${
							selectedImage.picture_id ? 'md:w-3/4' : 'md:w-full'
						}`}
					>
						<div className={`grid w-full grid-cols-3 gap-3 md:grid-cols-7`}>
							{allArtworksImages.map((image) => (
								<div
									key={image.image_id}
									className={`cursor-pointer border-4 border-solid
										${
											selectedImage?.picture_id === image.image_id
												? 'border-yellow-50'
												: 'border-transparent'
										}
										`}
									style={{ aspectRatio: '1/1' }}
									onClick={() => handleImageSelection(image)}
								>
									<LazyLoadImage
										alt={image.name}
										src={image.url + '_small'}
										width='100%'
										effect='blur'
										wrapperClassName={`overflow-hidden flex items-center lazyLoadingImages lazy-load-image-background blur lazy-load-image-loaded`}
										className='max-h-full max-w-full '
										delayTime='0'
										threshold='800'
										style={{
											objectFit: 'contain',
											aspectRatio: '1/1',
										}}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<ButtonFabPrincipalUrl
				label={t('Crear')}
				url={location.pathname}
				action={() => saveImageSelection()}
			/>
		</TemplateWithNavBar>
	);
};

export default AllImages;
