import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// how to guide => https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb
// plus this => https://lokalise.com/blog/how-to-internationalize-react-application-using-i18next/

//translation files
import { TRANSLATIONS_ES } from './BusinessLogic/translations/translations_es';
import { TRANSLATIONS_EN } from './BusinessLogic/translations/translations_en';
import { TRANSLATIONS_FR } from './BusinessLogic/translations/translations_fr';

const options = {
	order: ['cookie', 'localStorage', 'sessionStorage'],
	lookupLocalStorage: 'lng',
};
i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)

	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: 'es',
		debug: true,
		detection: options,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			en: {
				translation: TRANSLATIONS_EN,
			},
			es: {
				translation: TRANSLATIONS_ES,
			},
			fr: {
				translation: TRANSLATIONS_FR,
			},
		},
	});
export default i18n;
