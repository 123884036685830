import { useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';

//BL

// UI
import EditablePortfolioTopNavbar from '../../molecules/Portfolio/LayoutComponents/EditablePortfolioTopNavbar';
import EditablePortfolioLateralBar from '../../molecules/Portfolio/LayoutComponents/EditablePortfolioLateralBar';
import EditablePortfolioSubNavbar from '../../molecules/Portfolio/LayoutComponents/EditablePortfolioSubNavbar';
import EditablePortfolioBottomBarDesktop from '../../molecules/Portfolio/LayoutComponents/EditablePortfolioBottomBarDesktop';
import EditablePortfolioBottomBarMobile from '../../molecules/Portfolio/LayoutComponents/EditablePortfolioBottomBarMobile';

const ExpertPortfolioEditionLayout = ({
	showReorderView,
	setShowReorderView,
	duplicateSlideAction,
	deleteSlideAction,
	children,
}) => {
	const location = useLocation();
	const currentLocation = location.pathname;

	const {
		singlePortfolio,
		expertPortfolioViewAndSelection,
		showSlideModalExpertPortfolio,
	} = useSelector((state) => state.portfolios);

	const isConfigurationPage = currentLocation?.includes('configuracion');
	const isAddSectionPage = currentLocation?.includes('secciones/agregar');

	return (
		<main className='h-full min-h-screen'>
			<div className='fixed z-[60] w-full'>
				<EditablePortfolioTopNavbar />
				{!isConfigurationPage && (
					<EditablePortfolioSubNavbar portfolioName={singlePortfolio?.name} />
				)}
			</div>

			<div>
				{!isConfigurationPage && !isAddSectionPage && (
					<EditablePortfolioLateralBar
						showReorderView={showReorderView}
						setShowReorderView={setShowReorderView}
						duplicateSlideAction={duplicateSlideAction}
						deleteSlideAction={deleteSlideAction}
						currentView={expertPortfolioViewAndSelection.currentView}
						showSlideModalExpertPortfolio={
							showSlideModalExpertPortfolio?.isVisible
						}
					/>
				)}
				<div className='min-h-screen w-full overflow-x-hidden bg-light_grey-75 md:pl-16'>
					<div className='mt-24 overflow-y-auto md:pb-14'>{children}</div>
				</div>
			</div>
			{!isConfigurationPage && !isAddSectionPage && (
				<>
					<EditablePortfolioBottomBarDesktop
						currentLocation={currentLocation}
					/>

					<EditablePortfolioBottomBarMobile
						currentView={expertPortfolioViewAndSelection.currentView}
						showReorderView={showReorderView}
						setShowReorderView={setShowReorderView}
						duplicateSlideAction={duplicateSlideAction}
						deleteSlideAction={deleteSlideAction}
						showSlideModalExpertPortfolio={
							showSlideModalExpertPortfolio?.isVisible
						}
					/>
				</>
			)}
		</main>
	);
};

ExpertPortfolioEditionLayout.protoTypes = {
	//** Boolean state that determines if the slides are being displayed in the reorder view */
	showReorderView: PropTypes.bool,
	/** Function to update the boolean state that determines if the slides are being displayed in the reorder view */
	setShowReorderView: PropTypes.func,
	/** Function to run at confirming to duplicate a slide */
	duplicateSlideAction: PropTypes.fun,
	/** Function to run at confirming to delete a slide */
	deleteSlideAction: PropTypes.fun,
};

export default ExpertPortfolioEditionLayout;
