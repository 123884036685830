import React from 'react';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import { useTranslation } from 'react-i18next';

export default function TextTitleListContent({ listLabels }) {
	const { t } = useTranslation();
	return (
		<div className='hidden h-8 w-full grid-cols-5 gap-2 md:grid md:px-8 lg:px-28'>
			<div className='invisible w-14 md:mr-8 lg:mr-4'></div>
			<TextContentSecond
				design='md:w-36 lg:w-52'
				text={t(listLabels[0])}
				style={{ fontWeight: 700 }}
			/>
			<TextContentSecond
				design='md:w-36 lg:w-48'
				text={t(listLabels[1])}
				style={{ fontWeight: 700 }}
			/>
			<TextContentSecond
				design='md:w-40 lg:w-48'
				text={t(listLabels[2])}
				style={{ fontWeight: 700 }}
			/>
			<TextContentSecond
				design='md:w-32'
				text={t(listLabels[3])}
				style={{ fontWeight: 700 }}
			/>
		</div>
	);
}
