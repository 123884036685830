//3rd party
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//BL
import { fetchPostDataWithUser } from '../../../BusinessLogic/hooks/fetchPostDataWithUser';
import { ENDPOINTS_APPUSER } from '../../../BusinessLogic/helpers/routes';

//UI
import UnderlineHr from '../../../UI/atoms/UnderLine/UnderlineHr';
import ButtonLinkTextSubmit from '../../../UI/atoms/Buttons/Link/ButtonLinkTextSubmit';
import TextTitlePrincipal from '../../../UI/atoms/Text/Titles/TextTitlePrincipal';
import ButtonLinkMainLogo from '../../../UI/atoms/Buttons/Link/ButtonLinkMainLogo';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import FigureCirclePerfil from '../../../UI/atoms/Figure/FigureCirclePerfil';
import ButtonLinkText from '../../../UI/atoms/Buttons/Link/ButtonLinkText';
import TextContentThird from '../../../UI/atoms/Text/Content/TextContentThird';
import ButtonLinkMessage from '../../../UI/atoms/Buttons/Link/ButtonLinkMessage';
import UserManagementNavigation from '../../../UI/molecules/userManagement/UserManagementNavigation';
import SecondNavbarManagement from '../../../UI/organisms/Navbar/NavbarSecondManagement';
import ViewManagement from '../../../UI/templates/ViewManagement';

export default function UserView() {
	const [invited, setInvited] = useState([{}]);
	const { id } = useParams();
	const [section, setSection] = useState('Configuración');

	useEffect(() => {
		fetchPostDataWithUser(ENDPOINTS_APPUSER.GET_APPUSER + `${id}/`)
			.then((data) => setInvited(data))
			.catch((error) => console.error(error));
	}, [id]);
	return (
		<ViewManagement>
			<div className='hidden lg:flex lg:h-full lg:w-full'>
				<UserManagementNavigation
					className='hidden justify-center lg:block lg:flex lg:h-full lg:w-56 lg:flex-col lg:pt-4'
					section={section}
					setSection={setSection}
				/>
				<div className='lg:px-30 lg:flex lg:h-full lg:w-full lg:flex-col lg:pt-7'>
					<div className='flex flex-row justify-between px-6 pt-6 pb-1 lg:hidden lg:pl-4'>
						<SecondNavbarManagement
							title='Editar Perfil'
							text='Información de la cuenta'
							path={`/management/configuracion/user/${id}`}
						/>
						<ButtonLinkTextSubmit
							text='Guardar'
							design='edit body-Text2 md:text-base md:font-light ml-8 text-green-50'
							style={{ fontWeight: 600 }}
						/>
					</div>
					<TextTitlePrincipal
						title='Administrar roles'
						textColor='text-green-50'
						color='text-green-50'
					/>
					<div className='hidden justify-between p-6 md:flex '>
						<div className='flex text-center	'>
							<div>
								<FigureCirclePerfil
									initial='J'
									design='w-16 h-16 rounded-full flex items-center justify-center bg-yellow-50'
								/>
							</div>
							<div className=' flex flex-col'>
								<div className='flex items-center'>
									<div className='mr-3 h-8 w-8 lg:hidden'>
										<ButtonLinkMainLogo />
									</div>
								</div>
								<div className='flex flex-col text-left		'>
									<TextContentSecond
										invited={invited}
										text='juliana@itlookssimple.com'
										design='mt-4 ml-4'
										style={{ fontWeight: 600 }}
									/>
									<TextContentSecond
										text='Co - Administrador'
										design='mt-2 ml-4'
									/>
								</div>
							</div>
						</div>
						<div className='flex items-center	'>
							<ButtonLinkText
								route='/management/configuracion/user/editar/12'
								text='Editar'
								design='body-Text2 text-green-50  text-center'
								style={{ fontWeight: 700 }}
							/>
						</div>
					</div>
					<UnderlineHr design='h-px border-none bg-yellow-50 lg:bg-grey-50 mt-6 lg:mt-0' />
					<div className='hidden lg:block'>
						<div className='flex w-full flex-row p-4'>
							<TextContentSecond
								text='Nombre de usuario'
								design='w-3/6'
								style={{ fontWeight: 600 }}
							/>
							<TextContentSecond
								text={invited.first_name + ' ' + invited.last_name}
								design='w-3/6 text-grey-50'
							/>
						</div>
						<div className='flex w-full flex-row p-4'>
							<TextContentSecond
								text='Usuario Datarte'
								design='w-3/6'
								style={{ fontWeight: 600 }}
							/>
							<TextContentSecond
								text={invited.email}
								design='w-3/6 text-grey-50'
							/>
						</div>
						<div className='flex w-full flex-row p-4'>
							<TextContentSecond
								text='Email de la cuenta'
								design='w-3/6'
								style={{ fontWeight: 600 }}
							/>
							<TextContentSecond
								text={invited.email}
								design='w-3/6 text-grey-50'
							/>
						</div>
					</div>
					<UnderlineHr design='h-px border-none bg-yellow-50 lg:bg-grey-50 mt-6 lg:mt-0' />
				</div>
			</div>
			<div className='lg:hidden	'>
				<div className='m-4 flex justify-between'>
					<SecondNavbarManagement
						title={invited.name}
						text={invited.name}
						path='/management/configuracion/rolesypermisos'
					/>
					<FigureCirclePerfil
						design='w-16 h-16 rounded-full flex items-center justify-center bg-yellow-50'
						initial={invited.first_letter}
					/>
				</div>
				<UnderlineHr design='hr-configuration mt-5 mb-5' />
				<div className='flex justify-between px-7	pb-5'>
					<TextContentThird
						design='text-configuration'
						text='Detalles de contacto'
					/>
					<ButtonLinkText
						text='Editar'
						route={`/management/configuracion/user/editar/${id}`}
						design='edit font-semibold	ml-8'
					/>
				</div>
				<div className='flex items-center justify-between	px-7	'>
					<div className='flex flex-col '>
						<TextContentThird
							design='email-text font-light	'
							text={invited.email}
						/>
						<TextContentThird
							design='email-text font-normal	'
							text={invited.email}
						/>
						<TextContentThird
							design='email-text font-light	'
							text={invited.role_name}
						/>
					</div>
					<ButtonLinkMessage />
				</div>
			</div>
		</ViewManagement>
	);
}
