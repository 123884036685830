// 3rd party
import { useState, useEffect } from 'react';
import { Dropzone, FileItem } from '@dropzone-ui/react';
import PropTypes from 'prop-types';
// UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';

/**
 * @description Drag and Drop component to upload images
 * @param {*} param0
 * @returns
 */

const DropZoneModalDocument = ({
	showDropZoneModal,
	dropZoneFiles,
	clearDropzone,
	maxFiles,
	updateFiles,
	handleDelete,
}) => {
	const [isMobile, setIsMobile] = useState(false);

	if (showDropZoneModal) {
		document.body.classList.add('overflow-hidden');
	} else {
		document.body.classList.remove('overflow-hidden');
	}

	useEffect(() => {
		const changeInViewport = () => {
			if (window.innerWidth > 768) {
				setIsMobile(false);
			} else {
				setIsMobile(true);
			}
		};
		window.addEventListener('resize', changeInViewport);
		return () => {
			window.removeEventListener('resize', changeInViewport);
		};
	});

	return (
		<div className='fixed top-0 right-0 z-20 h-full w-full bg-black-50 bg-opacity-70'>
			<div className='m-auto flex h-full w-11/12 items-center md:w-1/2'>
				<div className='flex w-full select-none flex-col gap-4'>
					<Dropzone
						className='p-4 md:h-72'
						onChange={updateFiles}
						value={dropZoneFiles}
						localization={'ES-es'}
						header={false}
						minHeight={'195px'}
						maxHeight={'480px'}
						label={
							isMobile
								? 'Haga clic para cargar el documento.'
								: 'Suelte el documento aquí o haga clic para cargar.'
						}
						accept={'application/pdf'}
						maxFiles={maxFiles}
						maxFileSize={104857600}
						view={'grid'}
					>
						{dropZoneFiles?.map((file) => (
							<FileItem
								{...file}
								key={file.id}
								onDelete={() => handleDelete(file.id, file.file.name)}
								alwaysActive
								localization={'ES-es'}
								preview
								info
								resultOnTooltip
							/>
						))}
					</Dropzone>
					<div className='flex justify-center'>
						<ButtonFab
							size={dropZoneFiles?.length < 1 ? 'medium' : 'large'}
							label={dropZoneFiles?.length < 1 ? 'Cerrar' : 'Cargar documento'}
							action={() => {
								clearDropzone();
								document.body.classList.remove('overflow-hidden');
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DropZoneModalDocument;

DropZoneModalDocument.propTypes = {
	/* variable that represents if the modal is visible */
	showDropZoneModal: PropTypes.bool,
	/* Function to handle the deletion of the file in the dropzone and the file in the files to upload*/
	handleDelete: PropTypes.func,
	/* Function to handle the update of the file in the dropzone and the file in the files to upload*/
	updateFiles: PropTypes.func,
	/* array of images to be uploaded */
	dropZoneFiles: PropTypes.array,
	/**  maximum number of files allowed in the dropzone */
	maxFiles: PropTypes.number,
	/**  function to clear the dropzone file and close the modal*/
	clearDropzone: PropTypes.func,
};

DropZoneModalDocument.defaultProps = {
	maxFiles: 1,
};
