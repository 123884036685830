import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	value: { payload: '' },
	chip: {},
	showFilter: false,
	filterArray: {},
	filterArraySorted: {},
	filterArrayByClick: {},
	applyFilterByClick: false,
	filterChange: 0,
	nameFilter: '',
	arrayVariables: [],
	arrayFilterVariables: [],
	dynamicFilter: {},
	updateList: 0,
	filteredElements: [],
	baseElements: [],
	minimum: '',
	maximum: '',
	approved: false,
};

export const filterSlice = createSlice({
	name: 'FilterConfiguration',
	initialState,
	reducers: {
		addConfigurationInput(state, { payload }) {
			state.value = { ...state.value, payload };
		},
		filterDisplay: (state, action) => {
			state.showFilter = action.payload;
		},
		cleanAllFilters: (state, action) => {
			state.filterChange = 0;
			state.filterArray = {};
			state.filterArraySorted = {};
			state.filterArrayByClick = {};
			state.nameFilter = '';
			state.arrayVariables = [];
			state.arrayFilterVariables = [];
			state.dynamicFilter = {};
			state.filteredElements = state.baseElements;
			state.applyFilterByClick = false;
		},
		setFilterChanged: (state, action) => {
			let aux = state.filterChange;
			state.filterChange = aux + 1;
		},
		setFilterArray: (state, { payload }) => {
			state.filterArray = payload;
		},
		setFilterArraySorted: (state, { payload }) => {
			state.filterArraySorted = payload;
		},
		setFilterArrayByClick: (state, { payload }) => {
			state.filterArrayByClick = payload;
		},
		setApplyFilterArrayByClick: (state, { payload }) => {
			state.applyFilterByClick = !state.applyFilterByClick;
		},
		setMixMaxFilterArrayByClick: (state, { payload }) => {
			state.minimum = payload.minimum;
			state.maximum = payload.maximum;
		},
		setNewVariableToArray: (state, { payload }) => {
			let auxArray = state.arrayVariables;
			auxArray.push(payload);
			state.arrayVariables = auxArray;
		},
		setNewVariableToFilterArray: (state, { payload }) => {
			let auxArray = state.arrayFilterVariables;
			let thisValueExists = auxArray.indexOf(payload);
			if (thisValueExists === -1) {
				auxArray.push(payload);
				state.arrayFilterVariables = auxArray;
			}
		},
		deleteValueFromFilterArrayVariables: (state, { payload }) => {
			state.arrayFilterVariables = payload;
		},
		deleteValueFromMainArray: (state, { payload }) => {
			state.filterArray = payload;
		},
		deleteValueFromArrayVariables: (state, { payload }) => {
			let auxArray = state.arrayVariables;
			auxArray = auxArray.filter((value) => value !== payload['value']);
			state.arrayVariables = auxArray;
		},
		setDynamicFilter: (state, { payload }) => {
			state.dynamicFilter = payload;
		},
		setNameFilter: (state, { payload }) => {
			state.nameFilter = payload;
		},
		setUpdateList: (state, action) => {
			let aux = state.updateList;
			state.updateList = aux + 1;
		},
		setFilteredElements: (state, { payload }) => {
			state.filteredElements = payload;
		},
		setBaseElements: (state, { payload }) => {
			state.baseElements = payload;
		},
		CleanFilterArrayByClick: (state, { payload }) => {
			state.filterArrayByClick = false;
			state.minimum = '';
			state.maximum = '';
			state.filteredElements = state.baseElements;
			state.approved = false;
		},
		setApproved: (state, { payload }) => {
			if (payload === '') {
				state.approved = false;
			} else {
				state.approved = !state.approved;
			}
		},
	},
});

export const {
	addConfigurationInput,
	filterDisplay,
	cleanAllFilters,
	setFilterChanged,
	setFilterArray,
	setFilterArraySorted,
	setFilterArrayByClick,
	setApplyFilterArrayByClick,
	setMixMaxFilterArrayByClick,
	setNewVariableToArray,
	setNewVariableToFilterArray,
	deleteValueFromArrayVariables,
	deleteValueFromMainArray,
	deleteValueFromFilterArrayVariables,
	setDynamicFilter,
	setNameFilter,
	setUpdateList,
	setFilteredElements,
	setBaseElements,
	CleanFilterArrayByClick,
	setApproved,
} = filterSlice.actions;

export default filterSlice.reducer;
