//3rd
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

//UI
import TextContentSecond from '../Content/TextContentSecond';

/**
 * @description renders the label and style that represents the status of a call
 * @param {*} param0
 * @returns
 */
const CallStatusLabel = ({ status, design, style }) => {
	const { appUser } = useSelector((state) => state.appUser);

	const getLabelInformation = () => {
		switch (status) {
			case 'OPEN':
				return {
					text: 'Activa',
					textColor: 'text-green-500',
				};
			case 'CLOSED':
				return {
					text: 'Finalizada',
					textColor: 'text-red-500',
				};
			case 'SUBMITTED':
				return {
					text: 'Enviada',
					textColor: 'text-blue-500',
				};
			case 'DRAFT':
				return {
					text: 'Borrador',
					textColor: 'text-gray-500',
				};
			case 'APPROVED':
				return { text: 'Aprobada', textColor: 'text-green-500' };
			case 'REJECTED':
				return { text: 'Rechazada', textColor: 'text-red-500' };
			case 'REVIEWED':
				return { text: 'Revisada', textColor: 'text-light_green-50' };
			case 'READ':
				return {
					text: `${appUser.role_company === 'GALLERY' ? 'Leída' : 'Enviada'}`,
					textColor: `${
						appUser.role_company === 'GALLERY'
							? 'text-gray-500'
							: 'text-blue-500'
					}`,
				};
			case 'SAVED':
				return {
					text: `${
						appUser.role_company === 'GALLERY' ? 'Guardada' : 'Enviada'
					}`,
					textColor: `${
						appUser.role_company === 'GALLERY'
							? 'text-purple-500'
							: 'text-blue-500'
					}`,
				};
			default:
				return {
					text: '-',
					textColor: 'text-blue-500',
				};
		}
	};

	return (
		<TextContentSecond
			text={getLabelInformation().text}
			design={`${getLabelInformation().textColor} ${design}`}
			style={style}
		/>
	);
};

CallStatusLabel.propTypes = {
	//** Key that represents the status of the call */
	status: PropTypes.string,
	/** Tailwind class*/
	design: PropTypes.string,
	/** React Style object*/
	style: PropTypes.object,
};

CallStatusLabel.defaultProps = {
	style: {
		fontWeight: '700',
	},
};

export default CallStatusLabel;
