//3rth party
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//BL

import { getContacts } from '../../../BusinessLogic/redux/reducers/contact';

//UI
import FilterContact from './FilterContact';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import SecondaryNavbar from '../../../UI/organisms/Navbar/NavbarSecondary';
import ImagesSectionWithFilter from '../../../UI/organisms/Image/ImageSectionWithFilter';
import ButtonChangeView from '../../../UI/molecules/Buttons/ButtonChangeView';
import FilterButton from '../../../UI/atoms/Buttons/Link/FilterButton';
import ButtonFabPrincipalUrl from '../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import { transformRolesText } from '../../../BusinessLogic/helpers/transformContactRolesData';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

export default function AllContacts() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { value } = useSelector((state) => state.artistFilter);
	const { contacts, contactChanges, initialContactChanges } = useSelector(
		(state) => state.contacts
	);

	useEffect(() => {
		if (!contacts) {
			dispatch(getContacts());
		}
	}, [contacts, dispatch, contactChanges, initialContactChanges]);

	const [changeView, setChangeView] = useState(true);

	const [filterArray, setFilterArray] = useState({});
	const [filterChange, setFilterChange] = useState(0);
	const [nameFilter, setNameFilter] = useState(value.payload);

	const contactsDataTransformed =
		contacts && contacts.length > 0
			? contacts.map((contact) => {
					return {
						...contact,
						role: transformRolesText(contact.roles),
					};
			  })
			: [];

	const listLabels = ['Nombre', 'Rol', 'Email', 'Teléfono'];

	return (
		<div>
			{contacts && (
				<TemplateWithNavBar section={'Contactos'}>
					<SecondaryNavbar
						title={t('Contactos')}
						textColor='text-purple-50'
						color='bg-purple-50'
						redirect='home'
						hidden='invisible'
						changeView={changeView}
						setChangeView={setChangeView}
						Filter={FilterContact}
						data={contactsDataTransformed}
						filterArray={filterArray}
						setFilterArray={setFilterArray}
						filterChange={filterChange}
						setFilterChange={setFilterChange}
						nameFilter={nameFilter}
						setNameFilter={setNameFilter}
					/>
					<div className='mb-5 flex h-12 w-full items-center justify-between px-4 md:hidden'>
						<div className='invisible w-16'></div>
						<TextMainTitle
							title={t('Contactos')}
							textColor='text-purple-50'
							color='bg-purple-50'
						/>
						<div className='flex flex-row'>
							<ButtonChangeView
								changeView={changeView}
								setChangeView={setChangeView}
							/>
							<FilterButton
								Filter={FilterContact}
								data={contactsDataTransformed}
								filterArray={filterArray}
								setFilterArray={setFilterArray}
								filterChange={filterChange}
								setFilterChange={setFilterChange}
								nameFilter={nameFilter}
								setNameFilter={setNameFilter}
							/>
						</div>
					</div>
					<ImagesSectionWithFilter
						data={contactsDataTransformed}
						redirect='contactos'
						filterChange={filterChange}
						filterArray={filterArray}
						nameFilter={nameFilter}
						changeView={changeView}
						listLabels={listLabels}
						truncate
						newEntityLink='/contactos/new'
					/>
					<ButtonFabPrincipalUrl label={t('Crear')} url='/contactos/new' />
				</TemplateWithNavBar>
			)}
		</div>
	);
}
