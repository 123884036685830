import React from 'react';
import ButtonLinkText from '../../atoms/Buttons/Link/ButtonLinkText';
import UnderlineHr from '../../atoms/UnderLine/UnderlineHr';

export default function UserManagementNavigation({ className, section }) {
	return (
		<div className={className}>
			<ButtonLinkText
				route={`/management/perfil`}
				text='Perfil'
				design='pl-8 mb-5'
				section={section}
			/>
			<ButtonLinkText
				route={`/management/organization`}
				text='Organizaciones Vinculadas'
				design='pl-8 mb-5 text-left'
				section={section}
			/>
			<ButtonLinkText
				route={`/management/configuracion/rolesypermisos`}
				text='Configuración'
				design='pl-8 mb-5'
				section={section}
			/>
			<ButtonLinkText
				route={`/iniciar-sesion`}
				text='Cerrar Sesión'
				design='pl-8 mb-5'
			/>
			<UnderlineHr design='bg-grey-50' />
		</div>
	);
}
