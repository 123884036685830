//3rd party

import { AnimatePresence } from 'framer-motion';
import { PropTypes } from 'prop-types';

//UI
import ArtworkSelectionView from '../LayoutSpecificViews/ArtworkSelectionView';
import ArtworksSectionView from '../LayoutSpecificViews/ArtworksSectionView';
import ImageSelectionView from '../LayoutSpecificViews/ImageSelectionView';
import SectionsView from '../LayoutSpecificViews/SectionsView';
import SideModalExpertPortfolio from '../LayoutSpecificViews/SideModalExpertPortfolio';

/**
 * It's a component that renders a lateral bar with a list of buttons
 * @returns A component that renders a lateral bar.
 */

const EditablePortfolioLateralBar = ({
	showReorderView,
	setShowReorderView,
	duplicateSlideAction,
	deleteSlideAction,
	currentView,
	showSlideModalExpertPortfolio,
}) => {
	return (
		<div className='fixed z-50 hidden h-full w-20 select-none bg-middle_grey-50 pt-24 shadow transition-all duration-300 md:block'>
			<AnimatePresence>
				{showSlideModalExpertPortfolio ? (
					<SideModalExpertPortfolio />
				) : (
					<>
						{currentView === 'imageSelectionView' && <ImageSelectionView />}
						{currentView === 'artworksSectionView' && <ArtworksSectionView />}
						{currentView === 'artworkSelectionView' && <ArtworkSelectionView />}
						{currentView === 'sectionsView' && (
							<SectionsView
								showReorderView={showReorderView}
								setShowReorderView={setShowReorderView}
								duplicateSlideAction={duplicateSlideAction}
								deleteSlideAction={deleteSlideAction}
							/>
						)}
					</>
				)}
			</AnimatePresence>
		</div>
	);
};

EditablePortfolioLateralBar.protoTypes = {
	//** Boolean state that determines if the slides are being displayed in the reorder view */
	showReorderView: PropTypes.bool,
	/** Function to update the boolean state that determines if the slides are being displayed in the reorder view */
	setShowReorderView: PropTypes.func,
	/** Function to run at confirming to duplicate a slide */
	duplicateSlideAction: PropTypes.fun,
	/** Function to run at confirming to delete a slide */
	deleteSlideAction: PropTypes.fun,
};

export default EditablePortfolioLateralBar;
