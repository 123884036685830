/* -- Third Party -- */
import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// BL
import putDataAndImage from '../../../../BusinessLogic/helpers/putDataAndImage';
import { ENDPOINTS_ARTWORK } from '../../../../BusinessLogic/helpers/routes';
import postImage from '../../../../BusinessLogic/helpers/postImage';
import { getArtworks } from '../../../../BusinessLogic/redux/reducers/artwork';
import { getArtworkImage } from '../../../../BusinessLogic/redux/reducers/artworkImage';
import { artworkImageInitialValues } from '../../../../BusinessLogic/data/ArtworkImageSingle';

/* UI */
import Add from '../../../../UI/assets/icons/add.svg';
import TextMainTitle from '../../../../UI/molecules/Text/TextMainTitle';
import FieldTextFormik from '../../../../UI/molecules/Field/FieldTextFormik';
import ButtonFabExtendedPrimary from '../../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import SecondaryNavbarSpecific from '../../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import ImageSectionForm from '../../../../UI/organisms/Image/ImageSectionForm';
import SectionForm from '../../../../UI/organisms/Section/SectionForm';
import ViewForm from '../../../../UI/templates/ViewForm';

export default function ArtworkImagesForm({ action }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { organization_user_id, unknown_values } = localStorage;
	const { artworkImage } = useSelector((state) => state.artworkImage);
	const { singleArtwork, status } = useSelector((state) => state.artworks);
	const unknown = JSON.parse(unknown_values);
	const [editProperty, setEditProperty] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (action === 'edit') {
			dispatch(getArtworkImage(artworkImage.id));
			dispatch(getArtworks());
		} else {
			dispatch(getArtworks());
		}
	}, [action, dispatch, singleArtwork.id, editProperty, artworkImage.id]);

	useEffect(() => {
		if (status !== 'loading') {
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [status]);

	function getEditValues(inValues, colSingle) {
		const final = {};
		for (const key in inValues) {
			final[key] = colSingle[key];
		}
		return final;
	}
	//define the variable for the dropwdown input boxes
	const [initialValues] = useState(() => {
		if (action === 'edit') {
			return getEditValues(artworkImageInitialValues, artworkImage);
		} else {
			return {
				...artworkImageInitialValues,
				type_id: singleArtwork.id,
				organization_user_id: organization_user_id,
				main_picture_id: unknown.picture_id,
			};
		}
	});

	/* form details */
	const validationSchema = Yup.object({
		name: Yup.string()
			.min(2, 'Must be at least 2 characters long')
			.required('Este campo es obligatorio.'),
	});

	return (
		<ViewForm loading={loading} setLoading={setLoading}>
			<SecondaryNavbarSpecific
				title={action === 'edit' ? 'Editar Imagen' : 'Nueva Imagen'}
				textColor='text-yellow-50'
				color='bg-yellow-50'
				hide='md:invisible'
			/>
			<Formik
				enableReinitialize
				initialValues={{
					...initialValues,
					type: 'ARTWORK',
					type_id: singleArtwork.id,
				}}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					if (action === 'edit') {
						values = {
							...values,
							name_image: values.name,
							description_image: values.description,
						};
						putDataAndImage(
							ENDPOINTS_ARTWORK.PUT_ARTWORK_IMAGE + artworkImage.id + '/',
							values
						).then(() => {
							setEditProperty(!editProperty);
							setTimeout(() => {
								navigate(-1);
								setLoading(false);
							}, 500);
						});
					} else {
						values = {
							...values,
							name_image: values.name,
							description_image: values.description,
						};
						postImage(ENDPOINTS_ARTWORK.POST_ARTWORK_IMAGE, values).then(() => {
							setEditProperty(!editProperty);
							setTimeout(() => {
								navigate(-1);
								setLoading(false);
							}, 500);
						});
					}
				}}
			>
				{(formProps) => (
					<Form>
						<List>
							<div className='mx-auto w-fit-content md:hidden'>
								<TextMainTitle
									title={action === 'edit' ? 'Editar Imagen' : 'Nueva Imagen'}
									textColor='text-yellow-50'
									color='bg-yellow-50'
								/>
							</div>
							<div className='mb-20 px-4 pb-8 md:flex md:flex-row md:justify-around'>
								<ImageSectionForm
									action={action}
									url={artworkImage.main_picture_url}
									alt={artworkImage.name}
									formProps={formProps}
								/>
								<div className='mt-8 flex w-full flex-col items-center md:w-96'>
									<SectionForm
										title='Identificación'
										textColor='text-yellow-50'
										color='bg-yellow-50'
									>
										<FieldTextFormik label='Nombre' dbName='name' type='Text' />

										<FieldTextFormik
											label='Detalles'
											dbName='description'
											type='Text'
										/>
									</SectionForm>
									<ButtonFabExtendedPrimary icon={Add} text='Guardar' />
								</div>
							</div>
						</List>
					</Form>
				)}
			</Formik>
		</ViewForm>
	);
}
