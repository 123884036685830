//3rd parties
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//BL

//UI
import InputCustomName from '../../atoms/Inputs/InputCustomName';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

/**
 * @description basic field used for all the formic calculations
 * @param {*} param0
 * @returns
 */

export default function FieldDateFormik({
	label,
	dbName,
	className,
	errorMessageDesign,
	errors,
	dateTime,
}) {
	const { t } = useTranslation();
	return (
		<div className={`${className} flex w-full items-center justify-between`}>
			<div className='w-max md:w-32'>
				<TextContentSecond className='w-full' text={label} />
			</div>

			<Field
				name={dbName}
				type={dateTime ? 'datetime-local' : 'date'}
				as={InputCustomName}
			/>
			{errors && (
				<div className={`w-40 md:w-56 ${errorMessageDesign}`}>{t(errors)}</div>
			)}
		</div>
	);
}

FieldDateFormik.propTypes = {
	/** Text displayed for content identification */
	label: PropTypes.string,
	/** Tailwind design of the label */
	dbname: PropTypes.string,
	/** Tailwind design of the main container */
	className: PropTypes.string,
	/** Tailwind design of the ErrorMessage*/
	errorMessageDesign: PropTypes.string,
	/** this error string is generated from te yup validation schema in case of a missing requerid field*/
	errors: PropTypes.string,
	/** Determines if the date input should include time */
	dateTime: PropTypes.bool,
};

FieldDateFormik.defaultProps = {
	dateTime: false,
};
