//3rd party
import { useState, useEffect } from 'react';
import { Dropzone, FileItem } from '@dropzone-ui/react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

//BL
import {
	resizeFileMedium,
	resizeFileSmall,
} from '../../../BusinessLogic/helpers/imageSize';

export default function ImageSectionForm({
	formProps,
	dropzoneLabel,
	mobileDropzoneLabel,
	containerDesign,
	initialDropZoneFiles,
	isDropzoneClickable,
}) {
	const { t } = useTranslation();
	const [isMobile, setIsMobile] = useState(false);

	const [dropZoneFiles, setDropZoneFiles] = useState(initialDropZoneFiles);
	const updateFiles = async (incomingFiles) => {
		setDropZoneFiles(incomingFiles);
		let file = incomingFiles[0].file;
		let smallPicture;
		let mediumPicture;
		let largePicture;
		try {
			smallPicture = await resizeFileSmall(file);
			mediumPicture = await resizeFileMedium(file);
			largePicture = file;
			formProps.setFieldValue('small_file', smallPicture);
			formProps.setFieldValue('medium_file', mediumPicture);
			formProps.setFieldValue('large_file', largePicture);
			formProps.setFieldValue('name_image', file.name);
			formProps.setFieldValue('url_file', URL.createObjectURL(file));
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (initialDropZoneFiles?.length > 0) updateFiles(initialDropZoneFiles);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialDropZoneFiles]);

	useEffect(() => {
		if (formProps.values.url_file) {
			let fileRequest = new XMLHttpRequest();
			fileRequest.open('GET', formProps.values.url_file);
			fileRequest.responseType = 'blob';
			fileRequest.send();
			fileRequest.onload = function () {
				if (fileRequest.status === 200) {
					const file = new File(
						[fileRequest.response],
						formProps.values.name_image,
						{ type: 'image/png' }
					);
					const files = [
						{
							errors: [],
							file: file,
							id: 1,
							valid: true,
						},
					];
					updateFiles(files);
				}
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDelete = (id) => {
		setDropZoneFiles(dropZoneFiles.filter((x) => x.id !== id));
	};

	useEffect(() => {
		const changeInViewport = () => {
			if (window.innerWidth > 768) {
				setIsMobile(false);
			} else {
				setIsMobile(true);
			}
		};
		window.addEventListener('resize', changeInViewport);
		return () => {
			window.removeEventListener('resize', changeInViewport);
		};
	});

	return (
		<div
			className={`mx-auto flex flex-col items-center md:w-1/2 ${containerDesign}`}
		>
			<Dropzone
				className='p-4'
				onChange={updateFiles}
				value={dropZoneFiles}
				localization={'ES-es'}
				minHeight={'195px'}
				maxHeight={'300px'}
				label={isMobile ? t(mobileDropzoneLabel) : t(dropzoneLabel)}
				accept={'image/*'}
				maxFiles={1}
				maxFileSize={10485760}
				view={'grid'}
				clickable={isDropzoneClickable}
			>
				{dropZoneFiles.map((file) => (
					<FileItem
						{...file}
						key={file.id}
						onDelete={handleDelete}
						alwaysActive
						localization={'ES-es'}
						preview
						info
						resultOnTooltip
					/>
				))}
			</Dropzone>
		</div>
	);
}

ImageSectionForm.propTypes = {
	/** Formik props */
	formProps: PropTypes.object,
	/** Label to display in the Dropzone component*/
	dropzoneLabel: PropTypes.string,
	/** Label to display in the Dropzone component in mobile versions*/
	mobileDropzoneLabel: PropTypes.string,
	/**  Style container component*/
	containerDesign: PropTypes.string,
	/**Initial dropzone files array */
	initialDropZoneFiles: PropTypes.array,
	/**Determines if the dropzone should be enabled */
	dropzoneClickable: PropTypes.bool,
};

ImageSectionForm.defaultProps = {
	dropzoneLabel: 'SoltarOClickImagen',
	mobileDropzoneLabel: 'ClickCargarImagen',
	initialDropZoneFiles: [],
	isDropzoneClickable: true,
};
