import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPostImage } from '../../hooks/fetchPostImage';

export const createImage = createAsyncThunk(
	'Element/createImage',
	async (values) => {
		return await fetchPostImage(values);
	}
);

const initialState = {
	elementInfo: '',
	section: '',
	backendAnswer: '',
	createdImageCounter: 0,
	status: null,
};

export const ImageSlice = createSlice({
	name: 'elements',
	initialState,
	reducers: {
		setSection: (state, { payload }) => {
			state.section = payload;
		},
	},
	extraReducers: {
		[createImage.pending]: (state, action) => {
			state.status = 'loading';
		},
		[createImage.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
			state.createdImageCounter += 1;
		},
		[createImage.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});

export const { setSection } = ImageSlice.actions;

export default ImageSlice.reducer;
