import { isVideoUrl } from '../../../BusinessLogic/helpers/isVideoUrl';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import ImageAndTitle from '../../molecules/Image/ImageAndTitle';
import ImageListContent from '../../molecules/Image/ImageListContent';
import TextWithLink from '../../molecules/Text/TextWithLink';

const ArtworksGridView = ({
	artworksData,
	redirect,
	changeView,
	emptyDataMessage,
	emptyDataLink,
	designGrid = '',
}) => {
	return (
		<div className='container mx-auto'>
			{Array.isArray(artworksData) && artworksData.length === 0 ? (
				<div className='mx-auto flex w-11/12'>
					{emptyDataMessage ? (
						<TextContentSecond text={emptyDataMessage} />
					) : (
						<TextWithLink
							emptyEntityMessage='No hay portafolios. Por favor crea uno.'
							redirect={emptyDataLink}
						/>
					)}
				</div>
			) : (
				<div
					className={
						changeView
							? 'flex flex-col justify-center px-8'
							: `grid w-full grid-cols-3 justify-center gap-4  sm:grid-cols-5 md:grid-cols-7 ${designGrid}`
					}
				>
					{artworksData.map((singleArtwork, index) => (
						<div key={index}>
							{changeView ? (
								<ImageListContent
									key={singleArtwork?.id}
									id={singleArtwork?.id}
									imagen={
										isVideoUrl(singleArtwork)
											? singleArtwork?.video_url
											: singleArtwork?.main_picture_url
									}
									title={singleArtwork?.name}
									redirect={redirect}
									isVideoImage={isVideoUrl(singleArtwork)}
								/>
							) : (
								<ImageAndTitle
									key={singleArtwork?.id}
									id={singleArtwork?.id}
									imagen={
										isVideoUrl(singleArtwork)
											? singleArtwork?.video_url
											: singleArtwork?.main_picture_url
									}
									title={singleArtwork?.name}
									redirect={redirect}
									imageAlt={''}
									isVideoImage={isVideoUrl(singleArtwork)}
								/>
							)}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default ArtworksGridView;
