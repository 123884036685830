import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';

// BL
import { updateSlideElement } from '../../../BusinessLogic/redux/reducers/editablePortfolio';
import { updateElementPosition } from '../../../BusinessLogic/helpers/editablePortfolio';

// UI
import ImageElementArtwork from '../../atoms/Elements/ImageElementArtwork';
import TextElementArtwork from '../../atoms/Elements/TextElementArtwork';

/**
 * This component is responsible for displaying the artwork element on the slide.
 * @returns a component that is a draggable element.
 */
const ElementArtwork = ({
	elementType,
	picture,
	name,
	technique,
	year,
	height,
	measure,
	design,
	indexElement,
	currentPositionPercentage,
	positionPictureDraggable,
	positionTextDraggable,
	slideContainerWidth,
	slideContainerHeight,
	outsideSlideBoundary,
	hasSelectionBorder,
	setSelectedElementByIndex,
	selectedElementByIndex,
}) => {
	const dispatch = useDispatch();
	const { editablePortfolioSlide } = useSelector(
		(state) => state.editablePortfolio
	);
	const artworkElementDragHandler = (e) => {
		var rect = e.target.getBoundingClientRect();
		return { left: rect.left, top: rect.top };
	};
	const widthElement = `${slideContainerWidth}px`;
	const heightElement = `${(slideContainerHeight * 70) / 100}px`;

	const textSize = () => {
		return `${(slideContainerWidth * 3) / 100}px`;
	};
	const selectedElement = useRef();

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			if (
				selectedElementByIndex === indexElement &&
				!selectedElement.current.contains(e.target)
			) {
				setSelectedElementByIndex(null);
			}
		};
		document.addEventListener('click', checkIfClickedOutside);

		return () => {
			document.removeEventListener('click', checkIfClickedOutside);
		};
	}, [indexElement, selectedElementByIndex, setSelectedElementByIndex]);

	return (
		<>
			{elementType === 'elementArtworkComplete' && (
				<>
					<Draggable
						position={positionPictureDraggable}
						bounds='#slideContainer'
						onDrag={(e, data) => {
							!outsideSlideBoundary &&
								dispatch(
									updateSlideElement(
										updateElementPosition(
											data,
											indexElement,
											artworkElementDragHandler(e),
											'picturePosition',
											editablePortfolioSlide,
											currentPositionPercentage
										)
									)
								);
						}}
					>
						<div
							className={`absolute top-[2%] left-[2%] w-fit cursor-move ${
								hasSelectionBorder && 'border-2 border-dashed border-grey-50'
							}`}
							ref={selectedElement}
							onClick={() => setSelectedElementByIndex(indexElement)}
							onTouchStart={() => {
								setSelectedElementByIndex(indexElement);
							}}
						>
							<ImageElementArtwork
								widthElement={widthElement}
								heightElement={heightElement}
								picture={picture}
								name={name}
								height={height}
								indexElement={indexElement}
							/>
						</div>
					</Draggable>

					<Draggable
						position={positionTextDraggable}
						bounds='#slideContainer'
						onDrag={(e, data) => {
							!outsideSlideBoundary &&
								dispatch(
									updateSlideElement(
										updateElementPosition(
											data,
											indexElement,
											artworkElementDragHandler(e),
											'textPosition',
											editablePortfolioSlide,
											currentPositionPercentage
										)
									)
								);
						}}
					>
						<div
							className={`absolute top-[71%] left-[2%] flex w-fit cursor-move content-center ${
								hasSelectionBorder && 'border-2 border-dashed border-grey-50'
							}`}
							ref={selectedElement}
							onTouchStart={() => {
								setSelectedElementByIndex(indexElement);
							}}
							onClick={() => setSelectedElementByIndex(indexElement)}
						>
							<TextElementArtwork
								name={name}
								technique={technique}
								year={year}
								measure={measure}
								design={design}
								textSize={textSize()}
								indexElement={indexElement}
							/>
						</div>
					</Draggable>
				</>
			)}
			{/* {elementType === 'artworkElementText' && (
				<Draggable
					position={positionTextDraggable}
					bounds='#slideContainer'
					onStop={(e, data) =>
						dispatch(
							updateSlideElement(
								updateElementPosition(
									data,
									indexElement,
									artworkElementDragHandler(e),
									'textPosition',
									editablePortfolioSlide,
									currentPositionPercentage
								)
							)
						)
					}
				>
					<div className={`flex ml-[11px] w-fit content-center cursor-move`}>
						<TextElementArtwork
							name={name}
							technique={technique}
							year={year}
							measure={measure}
							design={design}
							textSize={textSize}
							indexElement={indexElement}
						/>
					</div>
				</Draggable>
			)} */}
			{elementType === 'artworkElementPicture' && (
				<Draggable
					position={positionPictureDraggable}
					bounds='#slideContainer'
					onDrag={(e, data) => {
						!outsideSlideBoundary &&
							dispatch(
								updateSlideElement(
									updateElementPosition(
										data,
										indexElement,
										artworkElementDragHandler(e),
										'picturePosition',
										editablePortfolioSlide,
										currentPositionPercentage
									)
								)
							);
					}}
				>
					<div
						className={`absolute top-[2%] left-[2%] w-fit cursor-move ${
							hasSelectionBorder && 'border-2 border-dashed border-grey-50'
						} `}
						ref={selectedElement}
						onTouchStart={() => {
							setSelectedElementByIndex(indexElement);
						}}
						onClick={() => {
							setSelectedElementByIndex(indexElement);
						}}
					>
						<ImageElementArtwork
							picture={picture}
							name={name}
							height={height}
							indexElement={indexElement}
							heightElement={heightElement}
						/>
					</div>
				</Draggable>
			)}
		</>
	);
};
ElementArtwork.propTypes = {
	/**  Pass a string information about the type (elementArtworkComplete or artworkElementText or artworkElementPicture) of artwork element */
	elementType: PropTypes.string,
	/** Pass the url of the image to the component */
	picture: PropTypes.string,
	/** Pass the Text to the component */
	name: PropTypes.string,
	/** Pass the text technique to the component */
	technique: PropTypes.string,
	/** Pass the year text to the component */
	year: PropTypes.string,
	/** Pass the text measure to the component */
	measure: PropTypes.string,
	/** Pass the width to the component */
	width: PropTypes.string,
	/** Pass the height to the component */
	height: PropTypes.string,
	/** Pass the design through the class */
	design: PropTypes.string,
	/** Fixed component positioning (does not allow the component to be moved) */
	position: PropTypes.object,
	/** Default positioning of the moveable component */
	defaultPosition: PropTypes.object,
	/** Assign font size to text */
	textSize: PropTypes.string,
	/* A unique identifier for each artwork element. */
	indexElement: PropTypes.number,
	/* A function that is passed to the component to set the current position of the artwork element to a percentage value  */
	currentPositionPercentage: PropTypes.func,
	/**Boolean prop to know if the mouse is outside Slide Boundary */
	outsideSlideBoundary: PropTypes.bool,
};

export default ElementArtwork;
