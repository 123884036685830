import * as Yup from 'yup';

//Single album tabs information
const collectionSingleTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'labels',
				field: 'identification',
				information: [
					{ label: 'Nombre', value: 'Lorem Ipsum', field: 'name' },
					{ label: 'Fecha', value: '23/03/2022', field: 'dates', type: 'date' },
				],
			},
			{
				title: 'Descripción',
				type: 'text',
				field: 'description',
				information: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris suscipit leo id magna scelerisque, et rutrum sapien cursus. Consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris suscipit leo id magna scelerisque, et rutrum sapien cursus. Consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris suscipit leo id magna scelerisque, et rutrum sapien cursus. Consectetur adipiscing elit.`,
			},
		],
	},
	{
		tab: 'Subcolecciones',
		type: 'Subcolecciones',
		content: [
			{
				title: 'colecciones',
				type: 'images',
				field: 'sub_collections',
				information: [],
			},
		],
	},
	{
		tab: 'Obras',
		type: 'Artworks',
		content: [
			{
				title: 'Obras',
				type: 'images',
				field: 'collection_artworks',
				information: [],
			},
		],
	},
	{
		tab: 'Documentos',
		type: 'Documents',
		content: [
			{
				title: 'Documentos',
				type: 'images',
				field: 'collection_documents',
				information: [],
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const galleryCollectionSingleTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'labels',
				field: 'identification',
				information: [
					{ label: 'Nombre', value: 'Lorem Ipsum', field: 'name' },
					{ label: 'Fecha', value: '23/03/2022', field: 'dates', type: 'date' },
				],
			},
			{
				title: 'Autor',
				type: 'labels',
				information: [
					{
						label: 'Artista',
						value: '',
						field: 'artist_id',
						type: 'dropdown',
						options: [],
						hideDefaultOption: true,
					},
				],
			},
			{
				title: 'Descripción',
				type: 'text',
				field: 'description',
				information: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris suscipit leo id magna scelerisque, et rutrum sapien cursus. Consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris suscipit leo id magna scelerisque, et rutrum sapien cursus. Consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris suscipit leo id magna scelerisque, et rutrum sapien cursus. Consectetur adipiscing elit.`,
			},
		],
	},
	{
		tab: 'Subcolecciones',
		type: 'Subcolecciones',
		content: [
			{
				title: 'colecciones',
				type: 'images',
				field: 'sub_collections',
				information: [],
			},
		],
	},
	{
		tab: 'Obras',
		type: 'Artworks',
		content: [
			{
				title: 'Obras',
				type: 'images',
				field: 'collection_artworks',
				information: [],
			},
		],
	},
	{
		tab: 'Documentos',
		type: 'Documents',
		content: [
			{
				title: 'Documentos',
				type: 'images',
				field: 'collection_documents',
				information: [],
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const singleCollectionButtons = [
	'Identificación',
	'SubColecciones',
	'Obras',
	'Documentos',
	'Imágenes',
];

const collectionValidationSchema = [
	//this needs to have the same order than the buttons
	[
		//and the same order than the appearance of the content sections
		//far from elegant
		{
			name: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.required('NombreEsNecesario'),
			description: Yup.string().max(10000, 'NumeroMáximoCaracteres').nullable(),
		},
	],
];

const collectionArtistFormValidationSchema = Yup.object({
	name: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('NombreEsNecesario'),
	description: Yup.string().max(10000, 'NumeroMáximoCaracteres').nullable(),
});

const collectionGalleryFormValidationSchema = Yup.object({
	name: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('NombreEsNecesario'),
	description: Yup.string().max(10000, 'NumeroMáximoCaracteres').nullable(),
	artist_id: Yup.string().required('DebeSeleccionarUnaOpción'),
});

const filterCollectionGalleryInitialValues = {
	collection: '',
	artist_id: '',
};

const filterCollectionArtistInitialValues = {
	collection: '',
};

export {
	collectionSingleTabs,
	singleCollectionButtons,
	collectionValidationSchema,
	collectionArtistFormValidationSchema,
	collectionGalleryFormValidationSchema,
	galleryCollectionSingleTabs,
	filterCollectionGalleryInitialValues,
	filterCollectionArtistInitialValues,
};
