//3rd
import { Field } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';

//UI
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

/**
 * @description Multiple options selector for a Formik form
 * @param {*} param0
 * @returns
 */
const RadioSelectFormik = ({
	label,
	dbName,
	options,
	className,
	labelListDesign,
	inputType,
}) => {
	return (
		<div className={className}>
			<TextContentSecond text={label} style={{ fontWeight: 400 }} />
			<div className={labelListDesign}>
				{options.map((option, i) => (
					<label key={i} className='mb-2 flex cursor-pointer flex-row gap-2'>
						<Field
							type={inputType}
							name={dbName}
							value={option.value}
							className={`h-4 w-4 appearance-none rounded-sm border-2 
									border-green-50 after:relative 
									after:-top-1/2 after:text-white checked:bg-green-50 checked:after:content-['✓']`}
						/>
						<TextContentSecond text={option.label} />
					</label>
				))}
			</div>
		</div>
	);
};

RadioSelectFormik.propTypes = {
	/**Label of the field*/
	label: PropTypes.string,
	/**Formik name prop*/
	dbName: PropTypes.string,
	/**Multiselect field options*/
	options: PropTypes.array,
	/**Class name of the component*/
	className: PropTypes.string,
	/**Tailwind classes of the options container*/
	labelListDesign: PropTypes.string,
	/**Type of the field */
	inputType: PropTypes.string,
};

RadioSelectFormik.defaultProps = {
	className: 'flex flex-row gap-2',
	labelListDesign: 'flex flex-col md:flex-row gap-2',
	inputType: 'radio',
};

export default RadioSelectFormik;
