import { ENDPOINTS_PICTURES } from '../helpers/routes';
import { submitErrorToSlack } from '../helpers/SlackIntegrations';

export const fetchPutArrayImages = async (values) => {
	const { token } = localStorage;
	let endpoint = '';

	return await fetch(`${ENDPOINTS_PICTURES.POST_ARRAY_IMAGES}`, {
		method: 'PUT',
		body: JSON.stringify(values),
		headers: {
			content_type: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
		.then(async (res) => {
			const response = await res.json();
			endpoint = res.url;
			if (res.status >= 500) {
				submitErrorToSlack(endpoint, response, 'PUT');
			}
			return response;
		})
		.catch((error) => {
			if (error.response.status >= 500) {
				submitErrorToSlack(endpoint, error, 'PUT');
			}
		});
};
