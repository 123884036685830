//3rd party
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//UI
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import textBlankEntityGenerator from '../../../BusinessLogic/helpers/textBlankEntityGenerator';

/**
 * @description componente render a text plus another text with a link
 * @param {*} param0
 */
function TextWithLink({
	text,
	emptyEntityMessage,
	designClickMessage,
	clickMessage,
	redirect,
	saveLastSectionURL,
	saveURLHistory,
	TextContentSecondParagraphDesign,
	onActionClick,
}) {
	const handleSaveURLHistory = () => {
		saveURLHistory();
	};
	const { t } = useTranslation();

	return (
		<div className='flex flex-row'>
			<TextContentSecond
				text={
					emptyEntityMessage
						? `${t(textBlankEntityGenerator(emptyEntityMessage))}, `
						: `${t(text)}`
				}
				design='pr-1'
				paragraphDesign={TextContentSecondParagraphDesign}
			/>
			<div
				onClick={() => {
					saveLastSectionURL && handleSaveURLHistory();
					onActionClick && onActionClick();
				}}
			>
				<Link to={redirect}>
					<TextContentSecond
						text={t(clickMessage)}
						design={`w-max ${designClickMessage}`}
						paragraphDesign={TextContentSecondParagraphDesign}
					/>
				</Link>
			</div>
		</div>
	);
}

TextWithLink.propTypes = {
	/* simple text to render */
	text: PropTypes.string,
	/* click message to render */
	clickMessage: PropTypes.string,
	/* special text message that only shows when a entity is empty */
	emptyEntityMessage: PropTypes.string,
	/* styles to use in the click message */
	designClickMessage: PropTypes.string,
	/** String for the redirect*/
	redirect: PropTypes.string,
	/**  bool that handle to save the actual specific location */
	saveLastSectionURL: PropTypes.bool,
	/* function that execute to save the actual url in redux state */
	saveURLHistory: PropTypes.func,
	/* String prop to add style to the paragraph inside TextContentSecond component */
	TextContentSecondParagraphDesign: PropTypes.string,
	/* function to be run on the click event */
	onActionClick: PropTypes.func,
};

TextWithLink.defaultProps = {
	designClickMessage: 'text-green-50',
	redirect: '/',
	clickMessage: 'HazClicAquí',
	saveLastSectionURL: false,
};

export default TextWithLink;
