// 3rd Party
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import 'react-lazy-load-image-component/src/effects/blur.css';

// UI
import ExpertSlideItemText from '../../../molecules/Portfolio/ExpertSlideItems/ExpertSlideItemText';
import ModalImage from '../../../organisms/PopUp/ModalImage';

//BL
import { artworkImageAlt } from '../../../../BusinessLogic/helpers/altImageHelper';
import ReactPlayer from 'react-player';

export default function ExpertSlideItemArtwork({
	artworkElements,
	designContainer,
	isScaled,
	isVideoArtwork,
	videoUrl,
}) {
	const location = useLocation();

	const { portfolioPublic } = useSelector((state) => state.portfolios);
	const [showModalImage, setShowModalImage] = useState(false);

	const defaultImage =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';
	const showTitle = portfolioPublic?.show_title;
	const showDate = portfolioPublic?.show_date;
	const showTechnique = portfolioPublic?.show_technique;
	const showMeasures = portfolioPublic?.show_measures;
	const showPrices = portfolioPublic?.show_prices;

	const smallImageUrl = (imageUrl) => {
		if (imageUrl) {
			return imageUrl + '_small';
		}
	};
	const mediumImageUrl = (imageUrl) => {
		if (imageUrl) {
			return imageUrl + '_medium';
		}
	};

	const altTextForImage = () => {
		const artworkName = artworkElements?.find(
			(artwork) => artwork.type === 'title'
		)?.content;
		const artworkDescription = artworkElements?.find(
			(artwork) => artwork.type === 'description'
		)?.content;
		const artistName = portfolioPublic.name;
		return artworkImageAlt(artworkName, artistName, artworkDescription);
	};

	const pathnameArray = location.pathname.split('/');

	const isNotPortfolioExpertSectionsView =
		pathnameArray[pathnameArray.length - 1] !== 'secciones';

	const isNotPortfolioExpertAddSectionsView =
		pathnameArray[pathnameArray.length] !== 'secciones' &&
		pathnameArray[pathnameArray.length - 1] !== 'agregar';

	const renderImage = (element, index) => {
		if (isVideoArtwork && !isScaled) {
			return (
				<ReactPlayer
					url={videoUrl}
					width={'100%'}
					height={'100%'}
					className='aspect-video h-full w-auto overflow-hidden'
					controls
				/>
			);
		}
		if (isVideoArtwork && videoUrl) {
			return (
				<ReactPlayer
					url={videoUrl}
					width={'100%'}
					height={'100%'}
					controls={false}
					light={true}
					className='pointer-events-none aspect-square'
				/>
			);
		}
		return (
			<>
				<LazyLoadImage
					effect='blur'
					placeholderSrc={element?.content && smallImageUrl(element.content)}
					key={index}
					alt={altTextForImage()}
					src={
						(element?.content && mediumImageUrl(element.content)) ??
						defaultImage
					}
					className={`mx-auto h-full w-full cursor-pointer shadow-lg ${
						isScaled ? 'mb-1' : ' mb-4'
					}`}
					onClick={() => setShowModalImage(true)}
				/>
				{showModalImage &&
					isNotPortfolioExpertSectionsView &&
					isNotPortfolioExpertAddSectionsView && (
						<ModalImage
							mainPicture={element?.content}
							name={altTextForImage()}
							setShowModalImage={setShowModalImage}
							showModalImage={showModalImage}
						/>
					)}
			</>
		);
	};

	return (
		<div className={`flex flex-col ${designContainer}`}>
			{artworkElements?.map((element, index) => (
				<div key={index}>
					{element.type === 'image_url' && renderImage(element, index)}
					{element.type === 'title' && showTitle && element.content && (
						<ExpertSlideItemText
							key={index}
							text={element.content}
							designContainer={`flex mb-0 ${
								isScaled ? 'text-[10px] flex-col mt-1' : 'text-sm mt-2'
							}`}
						/>
					)}
					{element.type === 'date' && showDate && element.content && (
						<ExpertSlideItemText
							key={index}
							text={element.content}
							designContainer={`flex mb-0 ${
								isScaled ? 'text-[10px] flex-col' : 'text-sm'
							}`}
						/>
					)}
					{element.type === 'technique' &&
						!isVideoArtwork &&
						showTechnique &&
						element.content && (
							<ExpertSlideItemText
								key={index}
								text={element.content}
								designContainer={`flex mb-0 ${
									isScaled ? 'text-[10px] flex-col' : 'text-sm'
								}`}
							/>
						)}
					{element.type === 'measures' &&
						!isVideoArtwork &&
						showMeasures &&
						element.content && (
							<ExpertSlideItemText
								key={index}
								text={element.content}
								designContainer={`flex mb-0 ${
									isScaled ? 'text-[10px] flex-col' : 'text-sm'
								}`}
							/>
						)}
					{element.type === 'price' &&
						!isVideoArtwork &&
						showPrices &&
						element.content && (
							<ExpertSlideItemText
								key={index}
								text={element.content}
								designContainer={`flex mb-0 ${
									isScaled ? 'text-[10px] flex-col' : 'text-sm'
								}`}
							/>
						)}
				</div>
			))}
			{isVideoArtwork && videoUrl && !isScaled && (
				<a href={videoUrl} target='_blank' rel='noreferrer'>
					<ExpertSlideItemText
						text={videoUrl}
						designContainer={`flex mb-0 ${
							isScaled ? 'text-[10px] flex-col' : 'text-sm !text-cyan-600'
						}`}
						designText={isScaled ? '' : 'text-cyan-600'}
					/>
				</a>
			)}
		</div>
	);
}

ExpertSlideItemArtwork.propTypes = {
	// artwork data
	artworkElements: PropTypes.array,
	// container styles
	designContainer: PropTypes.string,
	//Boolean that determines if the slide is scaled for a preview
	isScaled: PropTypes.bool,
};
