/* 3rd party */
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PropTypes } from 'prop-types';

/* BL */

/* UI */
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import Left from '../../assets/icons/CaretLeftSmall.svg';
import Right from '../../assets/icons/CaretRightSmall.svg';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * @description renders the a document preview with total pages and navigation
 * @param {*} param0
 * @returns
 */
export default function DocumentPreview({ pdfPreview }) {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
		setPageNumber(1);
	};

	const changePage = (offset) => {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	};

	const previousPage = () => {
		changePage(-1);
	};

	const nextPage = () => {
		changePage(1);
	};

	return (
		<div className='relative mx-auto flex w-full flex-col py-6 md:w-1/2 xl:w-1/3'>
			<div className='flex justify-center'>
				<Document file={pdfPreview} onLoadSuccess={onDocumentLoadSuccess}>
					<Page
						pageNumber={pageNumber}
						width='400'
						className='hidden rounded-xl shadow-xl md:flex'
						renderMode='svg'
					/>
					<Page
						pageNumber={pageNumber}
						height='400'
						className='rounded-xl shadow-xl md:hidden'
						renderMode='svg'
					/>
				</Document>
			</div>

			<div className='flex flex-col items-center justify-center'>
				<p className='my-4'>
					Página {pageNumber} de {numPages}
				</p>

				<div className='flex w-28 flex-row items-center justify-between'>
					<ButtonFab
						colour='green'
						icon={Left}
						label=''
						size='small'
						disabled={pageNumber <= 1}
						action={() => {
							previousPage();
						}}
					/>
					<ButtonFab
						colour='green'
						icon={Right}
						label=''
						size='small'
						action={() => {
							nextPage();
						}}
						disabled={pageNumber >= numPages}
					/>
				</div>
			</div>
		</div>
	);
}

DocumentPreview.propTypes = {
	/* string url of the pdf location */
	pdfPreview: PropTypes.string,

	/** Boolean prop  used to determine whether the button is disabled */
	disabled: PropTypes.bool,
};
