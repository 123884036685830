import { useTranslation } from 'react-i18next';
import { ReactComponent as BenefitCheckPaymentIcon } from '../../assets/icons/BenefitCheckPayment.svg';

const PaymentBenefit = ({ benefit, isPremium = false }) => {
	const { t } = useTranslation();
	const benefitColor = isPremium ? 'fill-green-50' : 'fill-grey-50';

	return (
		<li className=' grid grid-cols-[auto,1fr] items-center gap-2'>
			<BenefitCheckPaymentIcon className={benefitColor} />
			{t(benefit)}
		</li>
	);
};

export default PaymentBenefit;
