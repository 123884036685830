import React from 'react';

const EditExpertTextarea = ({
	textareaRef,
	textareaValue,
	onChangeAction,
	textareaDesign,
	placeholderText,
	onBlurAction,
}) => {
	return (
		<textarea
			placeholder={placeholderText}
			ref={textareaRef}
			className={`mx-[5%] mt-2 h-auto overflow-hidden p-1 text-justify text-xs font-light text-grey-70 xs:text-sm md:text-base ${textareaDesign} `}
			value={textareaValue}
			onChange={onChangeAction}
			onBlur={onBlurAction}
		/>
	);
};

export default EditExpertTextarea;
