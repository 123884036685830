//3rd party components
import PropTypes from 'prop-types';

/**
 * @description secondary text title
 * @param {*} param0
 * @returns
 */

export default function TextTitleSub({ textColor, title, design, style }) {
	return (
		<h2 className={` h2-Subtitle ${design} ${textColor}`} style={style}>
			{title}
		</h2>
	);
}

TextTitleSub.propTypes = {
	/** Tailwind Colour of the text */
	textColor: PropTypes.string,
	/** Text in the component */
	title: PropTypes.string,
	/** Tailwind design */
	design: PropTypes.string,
	/** Style */
	style: PropTypes.object,
};
