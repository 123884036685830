// 3rd Party
import { Form, Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// BL
import {
	editFormLabelValues,
	editFormValidationSchema,
	editFormDefaultValuesToShow,
	editFormAccessValues,
} from '../../../../BusinessLogic/data/ExpertPortfolioData';
import {
	getExpertPortfolio,
	putExpertPortfolio,
	postPortfolioSlugRepeat,
} from '../../../../BusinessLogic/redux/reducers/portfolio';

// UI
import ExpertPortfolioEditionLayout from '../../../../UI/layouts/portfolio/ExpertPortfolioEditionLayout';
import TextTitlePrincipal from '../../../../UI/atoms/Text/Titles/TextTitlePrincipal';
import TextContentSecond from '../../../../UI/atoms/Text/Content/TextContentSecond';
import { ReactComponent as Pencil } from '../../../../UI/assets/icons/pencil.svg';
import ButtonFab from '../../../../UI/atoms/Buttons/FAB/ButtonFab';
import Cross from '../../../../UI/assets/icons/Cross.svg';
import Check from '../../../../UI/assets/icons/whiteCheck.svg';
import LoadingSpinnerSmallWhite from '../../../../UI/assets/icons/loadingSpinnerSmallWhite.svg';
import { stringToSlug } from '../../../../BusinessLogic/helpers/textFormat';
import ExpertSlideCover from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideCover';
import ExpertSlideTextBlockAndImage from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideTextBlockAndImage';
import ExpertSlideTextBlock from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideTextBlock';
import ExpertSlideArtworkIndividual from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideArtworkIndividual';
import ExpertSlideArtworksGroup from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideArtworksGroup';
import ExpertSlideBackCover from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideBackCover';
import ExpertSlideFullScreenImage from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideFullScreenImage';

export default function ExpertPortfolioConfiguration() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { portfolioId } = useParams();
	const { singlePortfolio } = useSelector((state) => state.portfolios);
	const [showEditButtons, setShowEditButtons] = useState(
		editFormDefaultValuesToShow
	);
	const [formConfigurationInitialValues, setFormConfigurationInitialValues] =
		useState({});
	const [isLoadingExpertPortfolioData, setIsLoadingExpertPortfolioData] =
		useState(true);
	const [isSlugRepeat, setIsSlugRepeat] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (portfolioId !== singlePortfolio?.id) {
			dispatch(getExpertPortfolio(portfolioId))
				.then(({ payload }) => {
					const generalPortfolioData = {
						name: payload.name,
						slug: payload.slug,
						permissions: payload.permission,
					};
					setFormConfigurationInitialValues(generalPortfolioData);
					if (!payload.id) {
						throw new Error('Empty data');
					}
				})
				.catch(() => {
					alert(t('NoFuePosibleCargarElPortfolioExperto'));
					navigate('/');
				})
				.finally(() => setIsLoadingExpertPortfolioData(false));
		}
	}, [dispatch, navigate, portfolioId, singlePortfolio.id, t]);

	const onHandleEditInput = (showEditOption, fieldName) => {
		setShowEditButtons({
			...showEditButtons,
			[fieldName]: showEditOption,
		});
	};

	const onHandleSubmit = (values) => {
		if (!values) return;
		let putExpertPortfolioData = {
			id: singlePortfolio.id,
			values: {
				permissions: values.permissions,
				name: values.name,
			},
		};

		const isSlugChanged = singlePortfolio.slug !== stringToSlug(values.slug);

		setIsLoading(true);

		if (isSlugChanged) {
			dispatch(postPortfolioSlugRepeat(values.slug)).then(({ payload }) => {
				if (!payload.exist) {
					putExpertPortfolioData.values.slug = values.slug;
					dispatch(putExpertPortfolio(putExpertPortfolioData))
						.catch((e) => console.error(e))
						.finally(() => setIsLoading(false));
					setShowEditButtons(editFormDefaultValuesToShow);
				} else {
					setIsSlugRepeat(true);
					setIsLoading(false);
				}
			});
		} else {
			dispatch(putExpertPortfolio(putExpertPortfolioData))
				.catch((e) => console.error(e))
				.finally(() => setIsLoading(false));
			setShowEditButtons(editFormDefaultValuesToShow);
		}
	};

	const formFieldDropdownPermission = (fieldName, formProps) => (
		<Field
			name={fieldName}
			disabled={!showEditButtons[fieldName]}
			type='text'
			as='select'
			onChange={(e) => {
				formProps.setFieldValue(fieldName, e.target.value);
			}}
			className={`w-full rounded p-1 font-light text-black-50 disabled:bg-transparent disabled:text-black-50 disabled:opacity-100 ${
				!showEditButtons[fieldName] && 'appearance-none'
			}`}
		>
			{editFormAccessValues?.map((slugValue, index) => (
				<option key={index} value={slugValue.value}>
					{t(slugValue.label)}
				</option>
			))}
		</Field>
	);

	const formFieldBasic = (fieldName) => (
		<Field
			name={fieldName}
			disabled={!showEditButtons[fieldName]}
			className={`w-full rounded p-1 font-light disabled:bg-light_grey-75`}
			onKeyUp={() => {
				fieldName === 'slug' && setIsSlugRepeat(false);
			}}
		/>
	);

	const renderFirstSlide = (slide) => {
		if (!slide) {
			return null;
		}
		switch (slide?.type) {
			case 'cover':
				return <ExpertSlideCover slideData={slide} isEdition={false} />;
			case 'textBlock':
				return <ExpertSlideTextBlock slideData={slide} isEdition={false} />;
			case 'textBlockAndImage':
				return (
					<ExpertSlideTextBlockAndImage slideData={slide} isEdition={false} />
				);
			case 'artworkIndividual':
				return (
					<ExpertSlideArtworkIndividual slideData={slide} isEdition={false} />
				);
			case 'artworksGroup':
				return <ExpertSlideArtworksGroup isEdition={false} slideData={slide} />;
			case 'backCover':
				return <ExpertSlideBackCover isEdition={false} slideData={slide} />;
			case 'fullScreenImage':
				return (
					<ExpertSlideFullScreenImage isEdition={false} slideData={slide} />
				);
			default:
				return null;
		}
	};

	return (
		<ExpertPortfolioEditionLayout>
			{isLoadingExpertPortfolioData && (
				<div className='flex h-screen w-screen items-center justify-center'>
					<TextContentSecond
						text={`...${t('Cargando')}`}
						design='text-black !text-base lg:mt-8 mb-4'
					/>
				</div>
			)}
			{!isLoadingExpertPortfolioData && (
				<div className='mx-auto mb-16 max-w-[85%]'>
					<TextTitlePrincipal
						design='text-center text-lg mb-6 underline md:px-[20%]'
						title={t('ConfiguraciónDelPortafolio')}
						textColor='text-yellow-50'
					/>
					<TextContentSecond
						text={t('LosCambiosSeGuardanAutomáticamente')}
						design='text-black !text-base lg:mt-8 mb-4'
					/>
					<div className='lg:flex lg:justify-between'>
						<div className='mt-8 max-h-[400px] overflow-scroll bg-white py-8 lg:w-[50%]'>
							{renderFirstSlide(singlePortfolio?.slides[0])}
						</div>
						<Formik
							validationSchema={editFormValidationSchema}
							initialValues={formConfigurationInitialValues}
							onSubmit={onHandleSubmit}
						>
							{(formProps) => (
								<Form className='pt-9 lg:w-2/5'>
									{Object.keys(showEditButtons)?.map((fieldName, index) => (
										<div className='mb-4 flex justify-start' key={index}>
											<div className='flex w-[90%] items-center justify-between pr-4'>
												<TextContentSecond
													text={`${t(editFormLabelValues[fieldName])}:`}
													design='!font-normal'
												/>
												<div
													className={`flex w-1/2 flex-col items-start justify-start `}
												>
													{fieldName === 'permissions' ? (
														<>
															{formFieldDropdownPermission(
																fieldName,
																formProps
															)}
														</>
													) : (
														<>{formFieldBasic(fieldName)}</>
													)}
													{fieldName === 'slug' && isSlugRepeat && (
														<TextContentSecond
															text={t('PortafolioSlugRepetido')}
															design='text-red-400'
														/>
													)}
													{formProps.errors[fieldName] && (
														<TextContentSecond
															text={t(formProps.errors[fieldName])}
															design='text-red-400'
														/>
													)}
												</div>
											</div>
											{!showEditButtons[fieldName] && (
												<button
													onClick={() => onHandleEditInput(true, fieldName)}
												>
													<Pencil />
												</button>
											)}
										</div>
									))}
									{Object.values(showEditButtons).includes(true) && (
										<div className='my-8 flex w-full justify-center gap-x-4'>
											<ButtonFab
												label={`${t('Cancelar')}`}
												colour='white'
												action={() => {
													setIsSlugRepeat(false);
													formProps.resetForm();
													setShowEditButtons(editFormDefaultValuesToShow);
												}}
												icon={Cross}
											/>
											<ButtonFab
												colour='green'
												label={`${!isLoading ? t('Guardar') : t('Cargando')}`}
												icon={`${
													!isLoading ? Check : LoadingSpinnerSmallWhite
												}`}
												type='submit'
												disabled={isLoading}
											/>
										</div>
									)}
								</Form>
							)}
						</Formik>
					</div>
				</div>
			)}
		</ExpertPortfolioEditionLayout>
	);
}
