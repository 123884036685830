export const VIEWALL_ES = {
	Añadir: 'Añadir',
	FILTROS: 'FILTROS',
	LIMPIAR: 'LIMPIAR',
	Año: 'Año',
	BuscarEnDatarte: 'Buscar en Datarte',
	NohayConvocatoriasPorElMomento: 'No hay convocatorias por el momento.',
	Convocatorias: 'Convocatorias',
	RegresarAConvocatorias: 'Regresar a Convocatorias',
	TodasLasPostulaciones: 'Todas las Postulaciones',
	PostulacionesNuevas: 'Postulaciones Nuevas',
	PostulacionesAprobadas: 'Postulaciones Aprobadas',
	PostulacionesRechazadas: 'Postulaciones Rechazadas',
	DescargarImágenes: 'Descargar Imágenes',
	DescargarDatos: 'Descargar Datos',
	NoHayPostulacionesPorElMomento: 'No hay postulaciones por el momento.',
	Configuración: 'Configuración',
	OrganizacionesVinculadas: 'Organizaciones Vinculadas',
	AdministrarPlan: 'Administrar Plan',
	CerrarSesión: 'Cerrar Sesión',
	Perfil: 'Perfil',
	IdentificaciónUsuario: 'Identificación Usuario',
	InformaciónUsuario: 'Información Usuario',
	NoHayInformaciónDisponible: 'No hay información disponible',
	VeaLasCuentasAdjuntas:
		'Vea las cuentas adjuntas en este sitio y qué roles se les asignaron.',
	VerCuenta: 'Ver Cuenta',
	EditarCuenta: 'Editar Cuenta',
	RolEnLaCuenta: 'Rol en la cuenta',
	PlanCuenta: 'Plan Cuenta:',
	PlanAcceso: 'Plan Acceso:',
	Administrador: 'Administrator',
	AñadirRolesYPermisos: 'Añadir roles y permisos',
	RolesYPermisos: 'Roles y permisos',
	SeleccionaUnoOMásRoles:
		'Seleccione uno o más roles para las personas que está invitando. Para cambiar roles, vaya a administrar roles y permisos.',
	MiraQuiénesPuedenTrabajar:
		'Mira quiénes pueden trabajar en este sitio y qué roles se les asignaron.',
	RolesAsignados: 'Roles asignados',
	ParaInvitarAMúltiplesPersonas:
		'Para invitar a múltiples personas, ingresa cada email separado por una coma.',
	AdministradorCoPropietario: 'Administrador Co-propietario',
	UsuarioTemporal: 'Usuario temporal',
	TieneAccesoCompletoParaAdministrar:
		'Tiene acceso completo para administrar, editar y publicar obras de arte y más.',
	PuedeAdministrarCompletamenteLaInfo:
		'Puede administrar completamente la información de la aplicación, pero no otras áreas de su cuenta.',
	PuedeCrearOResponderMensajes:
		'Puede crear o responder mensajes u ofertas sin editar nada en su cuenta.',
	AsignaUnUsuarioTemporal:
		'Asigna un usuario temporal y una contraseña para mostrar completamente la información de la aplicación sin editar nada en su cuenta.',
	EnvíarAEsteContactoUnaInvitación:
		'Enviar a este contacto una invitación por email para unirse a mi cuenta.',
	VERMÁS: 'Ver más',
	plantillas: 'Plantillas',
	portafolioBásico: 'Portafolio Básico',
	RegresarPlantillas: 'Regresar a Plantillas',
	OrdenaTuCreatividad: 'Ordena tu creatividad',
	CatalogaTusObras: 'Cataloga tus obras',
	TusObrasQuedanRegistradas:
		'Tus obras quedan registradas, archivadas, organizadas y digitalizadas para que la creación de tu portafolio se pueda dar de la forma más sencilla posible.',
	AhorraTiempo: 'Ahorra tiempo',
	CreaTusColecciones: 'Crea tus colecciones',
	crearTusPropiasColecciones:
		'En Datarte puedes crear tus propias colecciones agrupando tus obras de manera	fácil y rápida.',
	CreaTusPortafolios: 'Crea tus portafolios',
	CreaYDiseñaTuPortafolio:
		'Crea y diseña tu portafolio de obras seleccionadas para acceder a las postulaciones fácilmente y tener nuevas oportunidades de difusión de tu obra',
	ObténEstímulos: 'Obtén estímulos',
	Oportunidades: 'Oportunidades',
	AplicaAConvocatoriasFácilmente:
		'Aplica a convocatorias fácilmente con tu portafolio creado previamente en la app. Impulsa tu carrera aplicando a nuevas oportunidades.',
	OptimizaTuSelección: 'Optimiza tu selección',
	VisualizarYGestionarLasPostulaciones:
		'Visualizar y gestionar las postulaciones',
	CreaTuConvocatoria:
		'Crea tu convocatoria y gestiona todas las postulaciones fácilmente en un solo lugar.',
	ParaCrearUnaConvocatoria: 'Para crear una convocatoria, ',
	HazClickAquí: 'haz click aquí.',
	Filtros: 'Filtros',
	MásRecientesPrimero: 'Más recientes primero',
	MásAntiguasPrimero: 'Más antiguas primero',
	NombreConvocatoriaAZ: 'Nombre de la convocatoria (A-Z)',
	NombreConvocatoriaZA: 'Nombre de la convocatoria (Z-A)',
	FiltrarPor: 'Filtrar por',
	Categoría: 'Categoría',
	Exposición: 'Exposición',
	V2: 'V2',
	ResidenciaArtística: 'Residencia artística',
	RevisiónDePortafolios: 'Revisión de portafolios',
	Pintura: 'Pintura',
	Escultura: 'Escultura',
	Dibujo: 'Dibujo',
	Performance: 'Performance',
	Videoarte: 'Videoarte',
	Otra: 'Otra',
	NombreDeConvocatoria: 'Nombre de la convocatoria',
	Organización: 'Organización',
	FechaCierre: 'Fecha de cierre',
	LimpiarFiltro: 'Limpiar filtro',
	Aplicar: 'Aplicar',
	ArtistaAZ: 'Artista (A-Z)',
	ArtistaZA: 'Artista (Z-A)',
	EstadoPostulación: 'Estado',
	Nueva: 'Nueva',
	Aprobada: 'Aprobada',
	Rechazada: 'Rechazada',
	Leída: 'Leída',
	Guardada: 'Guardada',
	FechaAplicación: 'Fecha de aplicación',
	NombreArtista: 'Nombre del artista',
	LoQueEstaPasando: 'Lo que está pasando',
	RecursosDatarte: 'Recursos Datarte',
	AperturaDeConvocatoria: 'Apertura de convocatoria',
	FinalizaciónDeConvocatoria: 'Finalización de convocatoria',
	ResultadosDeConvocatoria: 'Resultados de convocatoria',
	EventosImportantes: 'Eventos importantes',
	leerMás: 'Leer más',
	visualizarPortafolio: 'Visualizar Portafolio',
	editarPortafolio: 'Editar Portafolio',
	compartirPortafolio: 'Compartir Portafolio',
	eliminarPortafolio: 'Eliminar Portafolio',
};
