//3rd party
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//BL
import {
	deleteElement,
	getInfoElementToDelete,
	setSection,
} from '../../../BusinessLogic/redux/reducers/deleteElement';
import { urlDeleteElement } from '../../../BusinessLogic/helpers/urlDeleteElement';
import { cleanData } from '../../../BusinessLogic/helpers/cleanData';
import { getArtist } from '../../../BusinessLogic/redux/reducers/artist';

//UI
import { ReactComponent as ShareNetwork } from '../../../UI/assets/icons/ShareNetwork.svg';
import PopupSmall from '../PopUp/PopupSmall';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import ButtonWithIcon from '../../molecules/Buttons/WithIcon/ButtonWithIcon';
import { ReactComponent as DotsVertical } from '../../assets/icons/DotsVertical.svg';
import ViewModal from '../../templates/ViewModal';
import ButtonLinkBackText from '../../molecules/Buttons/Link/ButtonLinkBackText';
import ButtonLinkBack from '../../atoms/Buttons/Link/ButtonLinkBack';
import TextMainTitle from '../../molecules/Text/TextMainTitle';

/**
 * @name NavbarSecondaryNew
 * @description Secondary NAVBAR New.
 * @param {*} param0
 * @returns
 */

const NavbarSecondaryNew = ({
	title,
	color,
	textColor,
	redirect,
	showShare,
	showExtraOptions,
	textPopup,
	popupIcon,
	modalTitle,
	modalTextOne,
	modalTextTwo,
	id,
	section,
	showDots,
	Filter,
	data,
	titleContainer,
	textBack,
	textBackDesign,
	backAction,
	arrowLeftColor,
	onDeleteAction,
	containerDesign,
	isNotAutomaticRedirect,
	optionalIcon,
	handleClickOptionalIcon,
	handleDeleteError,
	setShowModalCannotDelete,
}) => {
	const ref = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [dotOption, setDotOption] = useState(false);
	const [showConfirmationMenu, setShowConfirmationMenu] = useState(false);
	const [confirmDeleteElement, setConfirmDeleteElement] = useState(false);

	const { appUser } = useSelector((state) => state.appUser);

	const mainArtistId = appUser?.main_artist_id;

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			if (dotOption && ref.current && !ref.current.contains(e.target)) {
				setDotOption(false);
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [dotOption]);

	const { getInfoElementUrl, deleteElementUrl } = urlDeleteElement(section, id);

	useEffect(() => {
		if (showConfirmationMenu) {
			dispatch(setSection(section));
			dispatch(getInfoElementToDelete(getInfoElementUrl));
		}
	}, [dispatch, getInfoElementUrl, section, showConfirmationMenu]);

	useEffect(() => {
		if (confirmDeleteElement) {
			dispatch(deleteElement(deleteElementUrl))
				.then((payload) => {
					if (
						payload.error &&
						payload?.error.message.includes('5') &&
						handleDeleteError
					) {
						handleDeleteError();
					}
					if (
						setShowModalCannotDelete &&
						payload.error &&
						payload?.error.message.includes('409')
					) {
						setShowModalCannotDelete(true);
					} else {
						cleanData(section, dispatch);
						if (onDeleteAction) {
							onDeleteAction();
						}
						if (section === 'colecciones') {
							navigate('/colecciones');
						} else if (section === 'portafolios') {
							navigate('/portafolios');
						} else if (section === 'obras') {
							navigate('/obras');
						} else {
							navigate(-1);
						}
					}
				})
				.finally(() => {
					setConfirmDeleteElement(false);
					if (section === 'obras' || section === 'documentos') {
						dispatch(getArtist({ id: mainArtistId }));
					}
				});
		}
		//eslint-disable-next-line
	}, [confirmDeleteElement]);

	return (
		<>
			<div
				className={`flex w-full items-start justify-between  md:py-10 ${containerDesign}`}
			>
				<ButtonLinkBack
					redirect={redirect}
					design='w-1/5 md:hidden'
					isNotAutomaticRedirect={isNotAutomaticRedirect}
					action={backAction}
				/>
				<ButtonLinkBackText
					design='hidden md:block md:w-1/4'
					textBack={t(textBack)}
					textBackDesign={textBackDesign}
					isNotAutomaticRedirect={isNotAutomaticRedirect}
					redirect={redirect}
					action={backAction}
					arrowLeftColor={arrowLeftColor}
				/>

				<TextMainTitle
					title={title}
					textColor={textColor}
					color={color}
					design={titleContainer}
				/>
				{Filter && section !== 'convocatorias' && (
					<Filter collectionAll={data} />
				)}

				{optionalIcon ? (
					<div className='relative w-1/5 md:w-1/4'>
						<div
							className='absolute right-0 cursor-pointer'
							onClick={handleClickOptionalIcon}
						>
							{optionalIcon}
						</div>
					</div>
				) : (
					<div
						className={`${
							showDots ? 'flex' : 'invisible'
						} w-1/5 flex-row justify-end md:w-1/4`}
						ref={ref}
					>
						<ButtonWithIcon
							design='cursor-pointer'
							icon={<DotsVertical />}
							showBoolean={dotOption}
							setShowBoolean={setDotOption}
						/>

						{dotOption && (
							<PopupSmall
								design='w-max bg-white shadow_box p-1 px-2 rounded-lg right-3 mt-3 pr-12'
								ref={ref}
							>
								<div
									className='flex w-full cursor-pointer flex-row items-center justify-between'
									onClick={() => {
										setShowConfirmationMenu(true);
										setDotOption(false);
									}}
								>
									<ButtonWithIcon
										icon={popupIcon}
										figureDesign='flex items-center justify-center '
									/>
									<div className='flex '>
										<TextContentSecond
											text={textPopup}
											design='text-strong_red-50'
										/>
									</div>
								</div>
							</PopupSmall>
						)}
						{showExtraOptions && <ShareNetwork />}
						{
							showShare && <ShareNetwork /> //add here the new share componente by Javier
						}
					</div>
				)}
			</div>

			{showConfirmationMenu && (
				<ViewModal
					setConfirmDeleteElement={setConfirmDeleteElement}
					closeMenu={setShowConfirmationMenu}
					modalTitle={modalTitle}
					modalTextOne={modalTextOne}
					modalTextTwo={modalTextTwo}
				/>
			)}
		</>
	);
};

NavbarSecondaryNew.propTypes = {
	//come from the TextMainTitles
	/** Text of the title */
	title: PropTypes.string,
	/** Color of the HR below the title. Updates the class name attribute  */
	color: PropTypes.string,
	/** Color of the text of the title. Updates the class name attribute  */
	textColor: PropTypes.string,
	/** If exists, redirects to this string. Otherwise, goes back on the history  */
	redirect: PropTypes.string,
	/** ShowsExtra Options  */
	showExtraOptions: PropTypes.bool,
	/** ShowsShare button*/
	showShare: PropTypes.bool,
	/** text that gonna show in the small popup */
	textPopup: PropTypes.string,
	/* icon that gonna show in the small popup */
	popupIcon: PropTypes.node,
	/* string with the modal title display */
	modalTitle: PropTypes.string,
	/* string that asking to confirm delete the entity */
	modalTextOne: PropTypes.string,
	/* string that show number of entities to delete with a message */
	modalTextTwo: PropTypes.string,
	/* title container styles that adjust to the design */
	titleContainer: PropTypes.string,
	/* text next to the arrow to specify the section to return */
	textBack: PropTypes.string,
	/* styles apply to the text next to arrow figure */
	textBackDesign: PropTypes.string,
	/* action trigger when ButtonLinkBack is clicked */
	backAction: PropTypes.func,
	/* determine the color of the arrowLeft asset */
	arrowLeftColor: PropTypes.string,
	/* determines if the dots button should be displayed */
	showDots: PropTypes.bool,
	/** action trigger when deleting an entity */
	onDeleteAction: PropTypes.func,
	/* styles applied to the navbar container */
	containerDesign: PropTypes.string,
	/* determine whether to return to the previous URL in ButtonLinkBackText component */
	isNotAutomaticRedirect: PropTypes.bool,
	/*Optional icon to be displayed on the component*/
	optionalIcon: PropTypes.object,
	/*Function to be executed when the optional icon is clicked*/
	handleClickOptionalIcon: PropTypes.func,
	/**Function that determines the action to follow in case the delete fetch fails */
	handleDeleteError: PropTypes.func,
};

NavbarSecondaryNew.defaultProps = {
	showShare: false,
	showExtraOptions: false,
	textPopup: 'Eliminar',
	showDots: true,
	titleContainer: 'text-center flex flex-col',
	showOptions: true,
};

export default NavbarSecondaryNew;
