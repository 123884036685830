// 3rd Party
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useRef, useState } from 'react';

//BL
import { useTextareaSize } from '../../../../BusinessLogic/hooks/useTextareaSizeHook';
import { putElementOnBlur } from '../../../../BusinessLogic/helpers/expertPortfolioHelpers';

// UI
import EditExpertTextarea from '../../../atoms/Portfolio/ExpertElements/EditExpertTextarea';
import EditExpertInputText from '../../../atoms/Portfolio/ExpertElements/EditExpertInputText';
import ExpertSlideElementText from '../../../atoms/Portfolio/ExpertSlideElements/ExpertSlideElementText';
import ExpertSlideElementTitle from '../../../atoms/Portfolio/ExpertSlideElements/ExpertSlideElementTitle';
import {
	setCurrentViewExpertPortfolioAndSelection,
	setShowSlideModalExpertPortfolio,
} from '../../../../BusinessLogic/redux/reducers/portfolio';

export default function ExpertSlideTextBlockAndImage({
	slideData,
	isEdition,
	isScaled,
}) {
	const dispatch = useDispatch();
	const { expertPortfolioViewAndSelection } = useSelector(
		(state) => state.portfolios
	);

	const currentViewExpertPortfolio =
		expertPortfolioViewAndSelection.currentView;
	const slideItems = slideData?.slideItems;
	const titleElements = slideItems?.find(
		(item) => item.type === 'title'
	)?.slideItemElements;

	const textareaRef = useRef(null);
	useTextareaSize(textareaRef);

	const mainTitleElement = slideData?.slideItems
		?.find((element) => element.type === 'title')
		?.slideItemElements?.find((element) => element.type === 'title');
	const textContentElement = slideData?.slideItems
		?.find((element) => element.type === 'text')
		?.slideItemElements?.find((element) => element.type === 'text');
	const imageElement = slideData?.slideItems
		?.find((element) => element.type === 'image')
		?.slideItemElements?.find((element) => element.type === 'image_url');

	const [slideContent, setSlideContent] = useState({
		title: mainTitleElement?.content,
		text: textContentElement?.content,
	});

	const handleClickOnImageElement = () => {
		dispatch(
			setShowSlideModalExpertPortfolio({
				isVisible: false,
				modalView: '',
			})
		);
		if (currentViewExpertPortfolio === 'imageSelectionView') {
			dispatch(
				setCurrentViewExpertPortfolioAndSelection({
					currentView: 'editionView',
					selectedElementId: null,
					selectedItemId: null,
					selectedSlideId: null,
				})
			);
		} else {
			dispatch(
				setCurrentViewExpertPortfolioAndSelection({
					currentView: 'imageSelectionView',
					selectedElementId: imageElement.slideItemElementId,
					selectedItemId: slideData?.slideItems?.find(
						(element) => element.type === 'image'
					),
					selectedSlideId: slideData?.slideId,
				})
			);
		}
	};

	if (!slideData) return null;
	if (isEdition) {
		return (
			<section className='flex w-full flex-col gap-2 border-b-2 border-dashed p-2 py-4 pb-8'>
				{mainTitleElement && (
					<p className='text-right text-xs opacity-70'>
						Sección - {slideContent.title}
					</p>
				)}
				{mainTitleElement && (
					<EditExpertInputText
						placeholderText={'Titulo '}
						inputDesign={
							'text-xl font-bold uppercase font-louisGeorgeCafe md:text-2xl md:text-start mx-[5%]'
						}
						inputValue={slideContent.title}
						onChangeAction={(e) =>
							setSlideContent({ ...slideContent, title: e.target.value })
						}
						onBlurAction={(e) =>
							mainTitleElement &&
							putElementOnBlur(
								dispatch,
								e.target.value,
								mainTitleElement.slideItemElementId,
								mainTitleElement.content
							)
						}
					/>
				)}

				<div className='mx-[5%] flex flex-col gap-8 md:flex-row'>
					{imageElement && (
						<LazyLoadImage
							src={imageElement?.content}
							className={`mx-auto h-max cursor-pointer px-[5%] md:order-2 md:my-auto md:w-1/2 md:px-0 ${
								imageElement?.slideItemElementId ===
									expertPortfolioViewAndSelection.selectedElementId &&
								'border-4 border-yellow-50'
							}`}
							onClick={handleClickOnImageElement}
						/>
					)}
					{textContentElement && (
						<EditExpertTextarea
							placeholderText={'Agrega un párrafo'}
							textareaDesign={'min-h-[180px] md:mx-0 md:w-1/2'}
							textareaRef={textareaRef}
							textareaValue={slideContent.text}
							onChangeAction={(e) =>
								setSlideContent({ ...slideContent, text: e.target.value })
							}
							onBlurAction={(e) =>
								textContentElement &&
								putElementOnBlur(
									dispatch,
									e.target.value,
									textContentElement.slideItemElementId,
									textContentElement.content
								)
							}
						/>
					)}
				</div>
			</section>
		);
	}

	return (
		<section
			className={`mx-[5%] flex flex-col ${
				isScaled ? 'gap-1' : 'gap-2 md:gap-4'
			}`}
		>
			{titleElements?.map((element, index) => {
				switch (element.type) {
					case 'title':
						return (
							<ExpertSlideElementTitle
								title={element.content}
								designTitle={`font-bold uppercase ${
									isScaled ? 'text-xs pt-[5%]' : 'text-2xl'
								}`}
								key={index}
							/>
						);
					case 'sub_title':
						return (
							<ExpertSlideElementTitle
								title={element.content}
								designTitle={`font-bold uppercase ${
									isScaled ? 'text-xs scale-90' : 'text-2xl'
								}`}
								key={index}
							/>
						);
					default:
						return null;
				}
			})}

			<div className='flex w-full flex-col gap-4 md:flex-row-reverse'>
				<LazyLoadImage
					src={imageElement?.content}
					wrapperClassName='justify-start md:w-1/2'
					className={`mx-auto max-h-[350px] object-contain`}
				/>
				<ExpertSlideElementText
					text={textContentElement?.content}
					designText={`md:w-1/2 ${isScaled ? 'mb-[5%] text-[10px]' : 'mb-2'}`}
				/>
			</div>
		</section>
	);
}

ExpertSlideTextBlockAndImage.propTypes = {
	// contains the data to show
	slideItems: PropTypes.array,
	// Is edition view or public view
	isEdition: PropTypes.bool,
	//Boolean that determines if the slide is scaled for a preview
	isScaled: PropTypes.bool,
};
