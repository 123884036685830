import BlankImage from '../../UI/assets/images/BlankImage.png';

const plantillas = [
	{
		id: 2,
		name: 'PortafolioBásico',
		main_picture_url: BlankImage,
	},
];

export { plantillas };
