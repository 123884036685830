//3rd parties
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

//UI
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import TextContentThird from '../../atoms/Text/Content/TextContentThird';
import FigureCirclePerfil from '../../atoms/Figure/FigureCirclePerfil';

export default function ImageListContent({
	id,
	imagen,
	title,
	redirect,
	imageFormat,
	email,
	rol,
	phone,
	isImageWithoutFormat,
	isVideoImage,
}) {
	let image_format = '';
	let final_image = '';
	if (imageFormat === 'small') {
		image_format = '_medium';
		final_image = imagen + image_format;
	}

	if (isImageWithoutFormat) {
		final_image = imagen;
	}

	const renderImage = () => {
		if (isVideoImage && imagen) {
			return (
				<div className='h-12 w-12 overflow-hidden rounded-full object-cover md:h-14 md:w-14'>
					<ReactPlayer
						url={imagen}
						width={'100%'}
						height={'100%'}
						controls={false}
						light={true}
						className='pointer-events-none'
						playIcon={<></>}
					/>
				</div>
			);
		}
		if (imagen) {
			return (
				<img
					className='h-12 w-12 rounded-full object-cover md:h-14 md:w-14 '
					src={final_image}
					alt={title}
				/>
			);
		}
		return (
			<FigureCirclePerfil
				initial={title.charAt(0).toUpperCase()}
				design='bg-green-50 text-white rounded-full h-12 w-12 md:h-14 md:w-14 flex items-center justify-center'
			/>
		);
	};

	return (
		<div>
			<Link
				to={{
					pathname: `/${redirect}/${id}`,
				}}
			>
				<div className='flex w-full flex-row items-center gap-2 py-2 md:grid md:grid-cols-5 md:px-4 lg:px-20'>
					<div className='mr-4 md:mr-0'>{renderImage()}</div>
					<div className='flex h-12 flex-col justify-center md:hidden'>
						<TextContentSecond
							design='text-grey-50 text-left md:w-36 lg:w-52 line-clamp-1'
							text={title}
							style={{ fontWeight: 600 }}
							truncate
						/>
						<TextContentThird
							design='text-grey-50 text-left lg:w-40 md:hidden'
							text={rol}
						/>
					</div>
					<TextContentSecond
						design='hidden md:block text-grey-50 text-left w-full truncate'
						text={title}
						style={{ fontWeight: 600 }}
						truncate
					/>
					<TextContentSecond
						design='hidden md:block text-grey-50 text-left w-full'
						text={rol}
						truncate
					/>
					<TextContentSecond
						design='hidden md:block text-grey-50 text-left w-full truncate'
						text={email}
						truncate
					/>
					<TextContentSecond
						design='hidden md:block text-grey-50 text-left w-full'
						text={phone}
						truncate
					/>
				</div>
			</Link>
		</div>
	);
}

ImageListContent.propTypes = {
	/** id of the image */
	id: PropTypes.string,
	/** url of the picture */
	imagen: PropTypes.string,
	/** none */
	title: PropTypes.string,
	/** none */
	redirect: PropTypes.string,
	/** none */
	imageFormat: PropTypes.string,
	/** none */
	email: PropTypes.string,
	/** none */
	rol: PropTypes.string,
	/** none */
	phone: PropTypes.string,
	/** none */
	name: PropTypes.string,
	/** none */
	lastName: PropTypes.string,
	/** Boolean prop  used to determine whether the TextContentSecond.js has a truncate class */
	truncate: PropTypes.bool,
};

ImageListContent.defaultProps = {
	imageFormat: 'small',
};
