import * as Yup from 'yup';

export const passwordRecoverySchema = Yup.object().shape({
	email: Yup.string()
		.email('EmailValidación')
		.required('EmailRequeridoRegistro'),
});

export const resetPasswordSchema = Yup.object().shape({
	password: Yup.string()
		.required('ContraseñaRequeridaRegistro')
		.min(8, 'ContraseñaMínimoCaracteres'),
	confirm_password: Yup.string()
		.required('ContraseñaConfirmación')
		.when('password', (password, schema) => {
			return schema.test({
				test: (confirm_password) => confirm_password === password,
				message: 'ContraseñaNoConcuerda',
			});
		}),
});
