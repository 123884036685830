/* React */
import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

/* BL */
import { useDispatch, useSelector } from 'react-redux';
import {
	singleDocumentButtons,
	catalogueArtistSingleDocumentButtons,
	documentValidationSchema,
} from '../../../BusinessLogic/data/DocumentSingle2';
import {
	getDocument,
	putDocument,
	organizeImageGrid,
	getDocuments,
} from '../../../BusinessLogic/redux/reducers/document';
import { ENDPOINT_FILES } from '../../../BusinessLogic/helpers/routes';
import { createImage } from '../../../BusinessLogic/redux/reducers/createImage';
import { deleteFile } from '../../../BusinessLogic/redux/reducers/deleteElement';
import { compressAndResizeImage } from '../../../BusinessLogic/helpers/imageSize';
import {
	backSectionURL,
	saveSectionURL,
} from '../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../BusinessLogic/helpers/textGoBackGenerator';

/* UI */
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import NewTagButtons from '../../../UI/molecules/Buttons/Tag/NewTagButtons';
import TabImageAndEdit from '../../../UI/organisms/Tabs/TabImageAndEdit';
import TabInfoAndEdit from '../../../UI/organisms/Tabs/TabInfoAndEdit';
import { ReactComponent as Delete } from '../../../UI/assets/icons/Delete.svg';
import DocumentPreview from '../../../UI/molecules/Document/DocumentPreview';
import ImageAndTitle from '../../../UI/molecules/Image/ImageAndTitle';
import ButtonFabExtendeNav from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendeNav';
import ViewSingleSkeleton from '../../../UI/templates/SkeletonTemplates/ViewSingleSkeleton';
import { useTranslation } from 'react-i18next';

/**
 * @description renders the single document section. It has an Image carousel and singleDocumentTabs.
 * @param {*} param0
 * @returns
 */
export default function DocumentSingle() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const location = useLocation();

	const {
		singleDocument,
		documentSingleTabs,
		catalogueArtistDocumentSingleTabs,
		documents,
		status,
	} = useSelector((state) => state.documents);

	const { appUser } = useSelector((state) => state.appUser);

	const { elementInfo } = useSelector((state) => state.deleteElement);
	const [indexInfo, setIndexInfo] = useState(0);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);

	const { t } = useTranslation();

	const isArtistUser = appUser.role_company === 'ARTIST';

	useEffect(() => {
		if (!singleDocument || `${singleDocument.id}` !== id) {
			dispatch(getDocument(id));
		}
		//eslint-disable-next-line
	}, []);

	function onSubmitAction(values) {
		dispatch(putDocument(values)).then(() => {
			dispatch(getDocuments());
			dispatch(getDocument(id));
		});
	}
	const checkRedirect = () => {
		let redirect = '/documentos';
		if (navigationHistoryURL?.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL?.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL?.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage = 'RegresarDocumentos';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	const saveURLHistory = () => {
		dispatch(saveSectionURL(location.pathname));
	};

	const createImagesFunction = async (imagesArray) => {
		for await (const image of imagesArray) {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			let values = {
				small_file,
				medium_file,
				large_file,
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
				type: 'DOCUMENT',
				type_id: id,
				organization_user_id: singleDocument.organization_user_id,
				file_type: 'IMAGE',
			};
			await dispatch(createImage(values));
		}
		dispatch(getDocument(id));
	};

	const deleteImagesFunction = (imageId) => {
		let values = {
			element_id: singleDocument.id,
			element: 'DOCUMENT',
			url: `${ENDPOINT_FILES.DELETE_FILE}${imageId}/`,
		};
		dispatch(deleteFile(values)).then(() => {
			dispatch(getDocument(id));
		});
	};

	const submitOrganizeImageGrid = (sortableImages) => {
		let values = {
			images: sortableImages,
			type: 'DOCUMENT',
			type_id: id,
		};
		dispatch(organizeImageGrid(values)).then(() => {
			dispatch(getDocument(id));
			dispatch(getDocuments());
		});
	};

	const renderSwitch = () => {
		switch (
			isArtistUser
				? documentSingleTabs[indexInfo].type
				: catalogueArtistDocumentSingleTabs[indexInfo].type
		) {
			case 'Information':
				return (
					<TabInfoAndEdit
						information={
							isArtistUser
								? documentSingleTabs[indexInfo]
								: catalogueArtistDocumentSingleTabs[indexInfo]
						}
						indexInfo={indexInfo}
						colorHr='light_green'
						onSubmitAction={onSubmitAction}
						id={parseInt(id)}
						validationSchema={
							documentValidationSchema[indexInfo]
								? documentValidationSchema[indexInfo]
								: null
						}
					/>
				);
			case 'Artworks':
				return (
					<TabImageAndEdit
						information={
							isArtistUser
								? documentSingleTabs[indexInfo]
								: catalogueArtistDocumentSingleTabs[indexInfo]
						}
						indexInfo={indexInfo}
						color='light_green'
						AddIconLink={
							isArtistUser
								? `/${documentSingleTabs[indexInfo].tab.toLowerCase()}/new`
								: `/${catalogueArtistDocumentSingleTabs[
										indexInfo
								  ].tab.toLowerCase()}/new`
						}
						saveLastSectionURL={true}
						saveURLHistory={saveURLHistory}
						objectFitProp='contain'
					/>
				);
			case 'Images':
				return (
					<TabImageAndEdit
						information={
							isArtistUser
								? documentSingleTabs[indexInfo]
								: catalogueArtistDocumentSingleTabs[indexInfo]
						}
						indexInfo={indexInfo}
						color='light_green'
						createImagesFunction={createImagesFunction}
						deleteImagesFunction={deleteImagesFunction}
						submitOrganizeImageGrid={submitOrganizeImageGrid}
						AddIconLink={
							isArtistUser
								? `/${documentSingleTabs[indexInfo].tab.toLowerCase()}/new`
								: `/${catalogueArtistDocumentSingleTabs[
										indexInfo
								  ].tab.toLowerCase()}/new`
						}
						saveLastSectionURL={true}
						saveURLHistory={saveURLHistory}
					/>
				);

			default:
				return <h1>No hay documentos disponible</h1>;
		}
	};

	return (
		<div>
			{!singleDocument && status === 'loading' && (
				<ViewSingleSkeleton
					numberOfTitleAndFields={1}
					numberOfTabs={2}
					numberOfSingleRow={5}
					designCaption={'hidden'}
					designOfBottomTitleAndField={'hidden'}
				/>
			)}
			{singleDocument && (
				<TemplateWithNavBar section='documentos'>
					<NavbarSecondaryNew
						title={singleDocument.name}
						color='bg-light_green-50'
						textColor='text-light_green-50'
						textPopup={`${t('EliminarDocumento')}`}
						popupIcon={<Delete />}
						modalTitle={`${t('EliminarDocumento')}`}
						modalTextOne={`${t('SeguroQueDeseasEliminarEsteDocumento')}`}
						modalTextTwo={elementInfo}
						id={parseInt(id)}
						section='documentos'
						redirect={checkRedirect()}
						textBack={CheckTextBack()}
						textBackDesign='pl-3 text-light_green-50'
						backAction={() => returnBackAction()}
						arrowLeftColor='light_green'
					/>
					<ButtonFabExtendeNav
						id={parseInt(id)}
						listItems={documents?.length ? documents : []}
						section='documentos'
					/>
					<div className='mx-2 flex w-full flex-col md:flex-row md:justify-around md:gap-2 lg:gap-0'>
						{(() => {
							switch (singleDocument.file_type) {
								case 'DOCUMENT':
									return (
										<DocumentPreview pdfPreview={singleDocument.document_url} />
									);

								case 'VIDEO':
									return (
										<div className='my-2 flex justify-around overflow-hidden p-2 md:w-1/3 md:flex-row'>
											<video
												controls
												className='sm:h-1/3'
												src={singleDocument.document_url}
												poster
											></video>
										</div>
									);

								default:
									return (
										<div className='flex w-full justify-center p-6 md:w-1/3 '>
											<ImageAndTitle
												imagen={singleDocument.document_url}
												objectFitProp={'scale-down'}
											/>
										</div>
									);
							}
						})()}

						<div className='flex w-full flex-col px-2 md:w-1/2 xl:w-2/3'>
							<NewTagButtons
								buttonList={
									isArtistUser
										? singleDocumentButtons
										: catalogueArtistSingleDocumentButtons
								}
								indexInfo={indexInfo}
								setIndexInfo={setIndexInfo}
							/>

							<div className='min-h-screen bg-light_grey-50 py-4'>
								{renderSwitch()}
							</div>
						</div>
					</div>
				</TemplateWithNavBar>
			)}
		</div>
	);
}

DocumentSingle.propTypes = {};

DocumentSingle.defaultProps = {};
