import { Routes, Route } from 'react-router-dom';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';

//UI
import PortfolioAnaMariaVelasco from '../../Pages/sections/portfolioPublic/PortfolioAnaMariaVelasco';
import PortfolioPublicView from '../../Pages/sections/portfolioPublic/PortfolioPublicView';

/**
 * @description This router manages the routes for the management of the porfolio.
 * It allows to create, view and edit
 * @returns
 */

export const PortfolioPublicRouter = () => {
	return (
		<Routes>
			<Route
				exact
				path='/anamaria-velasco'
				element={<PortfolioAnaMariaVelasco />}
			/>
			<Route exact path='/:slug' element={<PortfolioPublicView />} />
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default PortfolioPublicRouter;
