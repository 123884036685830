//3rd party
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

//BL
import { ENDPOINTS_ORGANIZATION } from '../../../BusinessLogic/helpers/routes';
import { fetchGetData } from '../../../BusinessLogic/hooks/fetchGetData';

//UI
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import AddRoleAndPermission from '../../../UI/molecules/userManagement/AddRoleAndPermission';
import ManagamentOrganization from '../../../UI/organisms/Managament/ManagamentOrganization';
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import ViewManagement from '../../../UI/templates/ViewManagement';

export default function Organizations() {
	const { app_user } = localStorage;
	const [myOrganizations, setMyOrganizations] = useState([{}]);
	const { t } = useTranslation();

	useEffect(() => {
		fetchGetData(
			`${ENDPOINTS_ORGANIZATION.GET_ORGANIZATIONS_WHERE_IAM}${app_user}/`
		)
			.then((data) => setMyOrganizations(data))
			.catch((error) => console.error(error));
	}, [app_user]);

	return (
		<ViewManagement>
			<div className='md:flex md:flex-col'>
				<SecondaryNavbarSpecific
					title={t('Cuentas')}
					textColor='text-green-50'
					color='bg-green-50'
					hide='invisible'
				/>
				<div className='mx-auto hidden w-fit-content'>
					<TextMainTitle
						title={t('Cuentas')}
						textColor='text-green-50'
						color='bg-green-50'
					/>
				</div>
				<div className='px-6 pb-4'>
					<TextContentSecond text={t('VeaLasCuentasAdjuntas')} />
				</div>
				<AddRoleAndPermission />
				<section className='mx-auto mb-16 flex h-full w-11/12 flex-col items-center lg:p-14'>
					<div className='w-full lg:flex lg:w-full lg:flex-col lg:items-center 	'>
						{myOrganizations.map((organization) => (
							<ManagamentOrganization
								nameOrganization={organization.organization_name}
								emailUsuario={organization.app_user_email}
								rolUser={organization.role_name}
								roleId={organization.role_id}
								accountType='----'
								accountRole={organization.role_name}
								idOrganization={organization.organization_user_id}
								FirstLetter={organization.first_letter}
							/>
						))}
					</div>
				</section>
			</div>
		</ViewManagement>
	);
}
