//3rd
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//UI
import FilterSidebar from './FilterSidebar';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';

//BL
import {
	getCities,
	getCountries,
} from '../../../BusinessLogic/redux/reducers/staticData';
import { getArtistDropdown } from '../../../BusinessLogic/redux/reducers/artist';

/**
 * @description render the filter for the search by categories and the clear filter and apply buttons
 * @param {*} param0
 * @returns
 */

const FilterAllPostulation = ({
	initialFilterParameters,
	filterParameters,
	setFilterParameters,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { countries, cities } = useSelector((state) => state.staticData);
	const { appUser } = useSelector((state) => state.appUser);
	const { artistDropdown } = useSelector((state) => state.artists);

	const [showFilter, setShowFilter] = useState(false);

	const isGalleryUser = appUser.role_company === 'GALLERY';
	const allArtistList = artistDropdown?.allArtist;

	useEffect(() => {
		if (!countries) {
			dispatch(getCountries());
		}
		if (filterParameters.country) {
			dispatch(getCities(filterParameters.country));
		}
	}, [dispatch, filterParameters.country, countries]);

	useEffect(() => {
		if (isGalleryUser) {
			if (Object.keys(artistDropdown).length === 0) {
				dispatch(getArtistDropdown());
			}
		}
	}, [dispatch, artistDropdown, isGalleryUser]);

	return (
		<FilterSidebar showFilter={showFilter} setShowFilter={setShowFilter}>
			<Formik
				initialValues={filterParameters}
				onSubmit={(values) => {
					setFilterParameters(values);
					setShowFilter(false);
				}}
				onReset={() => {
					setFilterParameters(initialFilterParameters);
					setShowFilter(false);
				}}
			>
				{(formProps) => (
					<div className='flex flex-col gap-3 px-3 pt-8'>
						<div className='flex flex-col gap-3'>
							<TextContentSecond
								text={t('OrdenarPor')}
								style={{ fontWeight: 500 }}
							/>
							<Field name='sorting'>
								{({ field }) => (
									<select
										{...field}
										className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
									>
										<option value=''></option>
										<option value='date_ascending'>
											{t('MásRecientesPrimero')}
										</option>
										<option value='date_descending'>
											{t('MásAntiguasPrimero')}
										</option>
										<option value='name_ascending'>
											{t('NombreConvocatoriaAZ')}
										</option>
										<option value='name_descending'>
											{t('NombreConvocatoriaZA')}
										</option>
									</select>
								)}
							</Field>
						</div>
						<div className='flex flex-col gap-3'>
							<TextContentPrincipal
								text={t('FiltrarPor')}
								style={{ fontWeight: 550 }}
								design='my-3'
							/>
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={t('Categoría')}
									style={{ fontWeight: 500 }}
								/>
								<Field name='category'>
									{({ field }) => (
										<select
											{...field}
											className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
										>
											<option value=''></option>
											<option value='Exposición'>{t('Exposición')}</option>
											<option value='Residencia Artística'>
												{t('ResidenciaArtística')}
											</option>
											<option value='Revisión de Portafolios'>
												{t('RevisiónDePortafolios')}
											</option>
										</select>
									)}
								</Field>
							</div>
							{isGalleryUser && (
								<div className='flex flex-col gap-3'>
									<TextContentSecond
										text={`${t('Artista')}:`}
										design='!font-medium'
									/>
									<Field
										name='artist_id'
										as='select'
										className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
									>
										<option value=''></option>
										{Array.isArray(allArtistList) &&
											allArtistList?.map((artistItem, index) => (
												<option key={index} value={artistItem.value}>
													{artistItem?.label}
												</option>
											))}
									</Field>
								</div>
							)}
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={t('Técnica')}
									style={{ fontWeight: 500 }}
								/>
								<Field name='technique'>
									{({ field }) => (
										<select
											{...field}
											className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
										>
											<option value=''></option>
											<option value='Pintura'>{t('Pintura')}</option>
											<option value='Escultura'>{t('Escultura')}</option>
											<option value='Dibujo'>{t('Dibujo')}</option>
											<option value='Performance'>{t('Performance')}</option>
											<option value='Videoarte'>{t('Videoarte')}</option>
											<option value='Otra'>{t('Otra')}</option>
										</select>
									)}
								</Field>
							</div>
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={t('País')}
									style={{ fontWeight: 500 }}
								/>
								<Field name='country'>
									{({ field }) => (
										<select
											{...field}
											onChange={(e) => {
												formProps.setFieldValue('country', e.target.value);
												if (e.target.value) {
													dispatch(getCities(e.target.value));
												}
											}}
											className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
										>
											<option value=''></option>
											{(countries ?? []).map((country, i) => (
												<option key={i} value={country.value}>
													{country.label}
												</option>
											))}
										</select>
									)}
								</Field>
							</div>
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={t('Ciudad')}
									style={{ fontWeight: 500 }}
								/>
								<Field name='city'>
									{({ field }) => (
										<select
											{...field}
											className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
										>
											<option value=''></option>
											{(cities ?? []).map((city, i) => (
												<option key={i} value={city.label}>
													{city.label}
												</option>
											))}
										</select>
									)}
								</Field>
							</div>
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={t('NombreDeConvocatoria')}
									style={{ fontWeight: 500 }}
								/>
								<Field
									type='input'
									name='name'
									className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
								/>
							</div>
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={t('Organización')}
									style={{ fontWeight: 500 }}
								/>
								<Field
									type='input'
									name='organization'
									className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
								/>
							</div>
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={t('FechaCierre')}
									style={{ fontWeight: 500 }}
								/>
								<Field
									type='date'
									name='date'
									className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
								/>
							</div>
						</div>
						<div className='my-5 flex flex-row justify-center gap-5'>
							<ButtonFab
								label={t('LimpiarFiltro')}
								size={'medium'}
								colour='white'
								action={() => formProps.resetForm()}
							/>
							<ButtonFab
								label={t('Aplicar')}
								size={'medium'}
								action={() => formProps.submitForm()}
							/>
						</div>
					</div>
				)}
			</Formik>
		</FilterSidebar>
	);
};

FilterAllPostulation.propTypes = {
	//** Initial values of the filter form */
	initialFilterParameters: PropTypes.object,
	//** Current values of the filter form */
	filterParameters: PropTypes.object,
	/** Function to update the current values of the filter form  */
	setFilterParameters: PropTypes.func,
};

FilterAllPostulation.defaultProps = {
	initialFilterParameters: {},
	filterParameters: {},
};

export default FilterAllPostulation;
