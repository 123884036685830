// 3rd Party
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

//BL

// UI
import EditExpertInputText from '../../../atoms/Portfolio/ExpertElements/EditExpertInputText';
import EditExpertTextarea from '../../../atoms/Portfolio/ExpertElements/EditExpertTextarea';
import { useRef, useState } from 'react';
import { useTextareaSize } from '../../../../BusinessLogic/hooks/useTextareaSizeHook';
import ExpertSlideElementTitle from '../../../atoms/Portfolio/ExpertSlideElements/ExpertSlideElementTitle';
import ExpertSlideElementText from '../../../atoms/Portfolio/ExpertSlideElements/ExpertSlideElementText';
import { putElementOnBlur } from '../../../../BusinessLogic/helpers/expertPortfolioHelpers';

export default function ExpertSlideTextBlock({
	slideData,
	isEdition,
	isScaled,
}) {
	const dispatch = useDispatch();
	const slideItems = slideData?.slideItems;
	const titleElements = slideItems?.find(
		(item) => item.type === 'title'
	)?.slideItemElements;

	const textareaRef = useRef(null);
	useTextareaSize(textareaRef);

	const mainTitleElement = slideData?.slideItems
		?.find((element) => element.type === 'title')
		?.slideItemElements?.find((element) => element.type === 'title');
	const textContentElement = slideData?.slideItems
		?.find((element) => element.type === 'text')
		?.slideItemElements?.find((element) => element.type === 'text');

	const [slideContent, setSlideContent] = useState({
		title: mainTitleElement?.content,
		text: textContentElement?.content,
	});

	if (isEdition) {
		return (
			<section className='flex w-full flex-col gap-2 border-b-2 border-dashed p-2 py-4 pb-8'>
				{slideContent.title && (
					<p className='text-right text-xs opacity-70'>
						Sección - {slideContent.title}
					</p>
				)}
				{mainTitleElement && (
					<EditExpertInputText
						placeholderText={'Titulo '}
						inputDesign={
							'text-xl font-bold uppercase font-louisGeorgeCafe md:text-2xl md:text-start mx-[5%]'
						}
						inputValue={slideContent.title}
						onChangeAction={(e) =>
							setSlideContent({ ...slideContent, title: e.target.value })
						}
						onBlurAction={(e) => {
							mainTitleElement &&
								putElementOnBlur(
									dispatch,
									e.target.value,
									mainTitleElement?.slideItemElementId,
									mainTitleElement?.content
								);
						}}
					/>
				)}
				{textContentElement && (
					<EditExpertTextarea
						placeholderText={'Agrega un párrafo'}
						textareaRef={textareaRef}
						textareaValue={slideContent.text}
						onChangeAction={(e) =>
							setSlideContent({ ...slideContent, text: e.target.value })
						}
						onBlurAction={(e) => {
							textContentElement &&
								putElementOnBlur(
									dispatch,
									e.target.value,
									textContentElement?.slideItemElementId,
									textContentElement?.content
								);
						}}
					/>
				)}
			</section>
		);
	}

	return (
		<section
			className={`mx-[5%] flex flex-col ${
				isScaled ? 'gap-1' : 'gap-2 md:gap-4'
			}`}
		>
			{titleElements?.map((element, index) => {
				switch (element.type) {
					case 'title':
						return (
							<ExpertSlideElementTitle
								title={element?.content}
								designTitle={`font-bold uppercase ${
									isScaled ? 'text-xs pt-[5%]' : 'text-2xl'
								}`}
								key={index}
							/>
						);
					case 'sub_title':
						return (
							<ExpertSlideElementTitle
								title={element?.content}
								designTitle={`font-bold uppercase ${
									isScaled ? 'text-xs scale-90' : 'text-2xl'
								}`}
								key={index}
							/>
						);
					default:
						return null;
				}
			})}
			<ExpertSlideElementText
				text={textContentElement?.content}
				designText={`${isScaled ? 'mb-[5%] text-[10px]' : 'mb-2'}`}
			/>
		</section>
	);
}

ExpertSlideTextBlock.propTypes = {
	// contains the data to show
	slideItems: PropTypes.array,
	//Boolean that determines if the slide is editable
	isEdition: PropTypes.bool,
	//Boolean that determines if the slide is scaled for a preview
	isScaled: PropTypes.bool,
};
