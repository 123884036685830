import React from 'react';

import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//UI
import InputCustomName from '../../atoms/Inputs/InputCustomName';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

const FieldURLFormik = ({
	label,
	dbName,
	labelDesign,
	designInput,
	errorMessageDesign,
	errors,
	handleOnBlur,
	handleUpdateEditingCall,
	formProps,
	handleOnFocus,
}) => {
	const { t } = useTranslation();
	const changeToLowerCase = (e) => {
		return formProps.setFieldValue(dbName, e.target.value.toLowerCase());
	};
	return (
		<>
			<div className='flex justify-between'>
				<div className={labelDesign}>
					<TextContentSecond text={label} />
				</div>
				<div className='flex flex-col'>
					<div className='flex w-40 flex-col md:w-56'>
						<div className='flex w-full'>
							<span className='body-Text2 pt-1  text-right lowercase'>
								.../
							</span>
							<Field
								designInput={designInput}
								name={dbName}
								type='text'
								as={InputCustomName}
								onBlur={(e) => {
									handleOnBlur && handleOnBlur(e.target.value);
									handleUpdateEditingCall && handleUpdateEditingCall();
								}}
								onChange={(e) => changeToLowerCase(e)}
								onFocus={handleOnFocus}
							/>
						</div>

						<div className='w-full'>
							{errors && (
								<div className={`${errorMessageDesign}`}>{t(errors)}</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

FieldURLFormik.propTypes = {
	/** Text displayed on the string */
	label: PropTypes.string,
	/** Tailwind design of the label */
	labelDesign: PropTypes.string,
	/** Tailwind design for the input */
	designInput: PropTypes.string,
	/** This fields connects the data. Please use the same name that on the database for simplicity */
	dbName: PropTypes.string,
	/** Tailwind design for the the ErrorMessage*/
	errorMessageDesign: PropTypes.string,
	/** Function prop to be perform in the onBlur event*/
	handleOnBlur: PropTypes.func,
	/** Function to Update Editing Call  to be perform in the onBlur event*/
	handleUpdateEditingCall: PropTypes.func,
	/** Object with the formProps  from the parent component formik*/
	formProps: PropTypes.object,
	/** Function prop to be perform in the onFocus event*/
	handleOnFocus: PropTypes.func,
};

FieldURLFormik.defaultProps = {
	errorMessageDesign: 'text-red-400 body-Text3',
	inputDesign: 'flex w-40 md:w-56',
};

export default FieldURLFormik;
