//3rd
import { Field } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description Renders a green checkbox customizable input
 * @name
 * @returns
 */
const InputGreenCheckbox = ({ name, value }) => {
	return (
		<Field
			type='radio'
			name={name}
			value={value}
			className={`h-4 w-4 appearance-none rounded-sm border-2 
						border-green-50 after:relative 
						after:-top-1/2 after:text-white checked:bg-green-50 checked:after:content-['✓']`}
		/>
	);
};

InputGreenCheckbox.propTypes = {
	/**Determines de field name */
	name: PropTypes.string,
	/**Determines de field value */
	value: PropTypes.string,
};

export default InputGreenCheckbox;
