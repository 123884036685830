import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * This function is a React component that renders an input element with a placeholder and a type
 * @returns A component that is a text input.
 */
const expandNodeToScrollHeight = (node) => {
	if (!node?.style || !node?.scrollHeight) return;
	node.style.height = 'inherit';
	node.style.height = `${node.scrollHeight}px`;
};

const InputElementText = ({
	placeholder,
	textSize,
	type,
	textElementInputHandler,
	typeInput,
	slideContainerWidth,
	elementText,
	value,
}) => {
	const textareaRef = useRef();

	useEffect(() => {
		expandNodeToScrollHeight(textareaRef.current);
	}, []);

	function handleKeyDown(e) {
		expandNodeToScrollHeight(e.target);
	}

	const fontSizeTitle = `${(slideContainerWidth * 5) / 100}px`;
	const fontSizeSubtitle = `${(slideContainerWidth * 3.5) / 100}px`;
	const fontSizeSimpleText = `${(slideContainerWidth * 2.5) / 100}px`;

	return typeInput === 'textarea' ? (
		<textarea
			ref={textareaRef}
			onKeyDown={handleKeyDown}
			id='elementTextAreaInput'
			onChange={textElementInputHandler}
			type={type}
			placeholder={placeholder}
			value={value}
			className={`h-full w-full resize rounded-md bg-transparent px-1 focus:outline-none focus:ring-1 focus:ring-grey-50`}
			style={{
				fontSize: fontSizeSimpleText,
				width: `${(slideContainerWidth * 90) / 100}px`,
			}}
		/>
	) : (
		<input
			id='elementTextInput'
			size={`${value.length}`}
			maxLength='30'
			type={type}
			onChange={textElementInputHandler}
			placeholder={placeholder}
			value={value}
			className={`rounded-md bg-red-200 bg-transparent py-1 focus:outline-none focus:ring-1 focus:ring-grey-50`}
			style={{
				fontSize: elementText === 'title' ? fontSizeTitle : fontSizeSubtitle,
			}}
		/>
	);
};
InputElementText.propTypes = {
	type: PropTypes.string,
	/** Pass the placeholder text to the component */
	placeholder: PropTypes.string,
	/** Assign font size to placeholder */
	placeholderFontSize: PropTypes.string,
	/* Function that takes the value of the input field and updates the text content of the element in the slide */
	textElementInputHandler: PropTypes.func,
	/* identifies if the input is of type textarea or input */
	typeInput: PropTypes.string,
};

InputElementText.defaultProps = {
	type: 'text',
};
export default InputElementText;
