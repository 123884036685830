/* -- Third Party -- */
import { useState } from 'react';
import List from '@material-ui/core/List';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* BL */
import {
	artistInitialValues,
	artistFormValidationSchema,
} from '../../../BusinessLogic/data/ArtistSingleData';
import {
	getArtists,
	postArtist,
} from '../../../BusinessLogic/redux/reducers/artist';
import { backSectionURL } from '../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../BusinessLogic/helpers/textGoBackGenerator';
import { getArtworkDropdown } from '../../../BusinessLogic/redux/reducers/artwork';

/* UI */
import Check from '../../../UI/assets/icons/whiteCheck.svg';
import FieldTextFormik from '../../../UI/molecules/Field/FieldTextFormik';
import FieldTextAreaFormik from '../../../UI/molecules/Field/FieldTextAreaFormik';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import ImageSectionForm from '../../../UI/organisms/Image/ImageSectionForm';
import SectionForm from '../../../UI/organisms/Section/SectionForm';
import ViewForm from '../../../UI/templates/ViewForm';
import LoadingText from '../../../UI/molecules/Loading/LoadingText';

/**
 * @description Artist Form to manage new artist and edit
 * @param {*} param0
 * @returns
 */
export default function ArtistForm() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { navigationHistoryURL } = useSelector((state) => state.navigation);
	const [showSaving, setShowSaving] = useState(false);

	const { organization_user_id, unknown_values } = localStorage;
	const unknown = JSON.parse(unknown_values);

	//define the variable for the dropwdown input boxes
	const initialValues = {
		...artistInitialValues,
		type: 'ARTIST',
		type_id: 0,
		file_type: 'IMAGE',
		organization_user_id: organization_user_id,
		main_picture_id: unknown.picture_id,
	};

	const checkRedirect = () => {
		let redirect = '/artistas';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage =
			navigationHistoryURL[navigationHistoryURL.length - 1] === '/'
				? 'RegresarHome'
				: 'RegresarArtistas';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	const handleOnSubmit = (values) => {
		setShowSaving(true);
		dispatch(postArtist(values))
			.then((res) => {
				navigate(`/artistas/${res.payload.id}`);
				dispatch(getArtists()).then(() => dispatch(getArtworkDropdown()));
			})
			.finally(() => setShowSaving(false));
	};

	return (
		<ViewForm>
			<div className={showSaving && 'opacity-60'}>
				<SecondaryNavbarSpecific
					title={t('NuevoArtista')}
					textColor='text-purple-50'
					color='bg-purple-50'
					hide='md:invisible'
					redirect={checkRedirect()}
					textBack={CheckTextBack()}
					textBackDesign='pl-3 text-purple-50'
					arrowLeftColor='purple'
					backAction={() => returnBackAction()}
				/>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={artistFormValidationSchema}
					onSubmit={handleOnSubmit}
				>
					{(formProps) => (
						<Form className='mb-20'>
							<List>
								<div className='mx-auto flex w-11/12 flex-col gap-4 md:flex-row lg:w-4/5 lg:gap-12'>
									<ImageSectionForm
										formProps={formProps}
										dropzoneLabel={'SoltarOClickImagenPortadaArtista'}
										mobileDropzoneLabel={'ClickCargarImagenPortadaArtista'}
									/>
									<div className='mx-auto flex w-full flex-col md:w-1/2'>
										<SectionForm
											title={t('Identificación')}
											textColor='text-purple-50'
											color='bg-purple-50'
										>
											<FieldTextFormik
												label={t('Nombre')}
												dbName='first_name'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.first_name}
											/>
											<FieldTextFormik
												label={t('Apellido')}
												dbName='last_name'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.last_name}
											/>
										</SectionForm>

										<SectionForm
											title={t('Biografía')}
											textColor='text-purple-50'
											color='bg-purple-50'
										>
											<FieldTextAreaFormik
												label='biography'
												dbName='biography'
												errors={formProps.errors?.biography}
											/>
										</SectionForm>
										<SectionForm
											title={t('Educación')}
											textColor='text-purple-50'
											color='bg-purple-50'
										>
											<FieldTextAreaFormik
												label='education'
												dbName='education'
												errors={formProps.errors?.education}
											/>
										</SectionForm>
										<SectionForm
											title={t('Exposiciones')}
											textColor='text-purple-50'
											color='bg-purple-50'
										>
											<FieldTextAreaFormik
												label='exhibitions'
												dbName='exhibitions'
												errors={formProps.errors?.exhibitions}
											/>
										</SectionForm>
										<SectionForm
											title={t('Publicaciones')}
											textColor='text-purple-50'
											color='bg-purple-50'
										>
											<FieldTextAreaFormik
												label='publications'
												dbName='publications'
												errors={formProps.errors?.publications}
											/>
										</SectionForm>
										{showSaving ? (
											<LoadingText text={t('Guardando')} />
										) : (
											<ButtonFabExtendedPrimary
												icon={Check}
												className='mx-auto text-white'
												text={t('Guardar')}
											/>
										)}
									</div>
								</div>
							</List>
						</Form>
					)}
				</Formik>
			</div>
		</ViewForm>
	);
}
