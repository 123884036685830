const ENDPOINTS_ONBOARDING = {
	GET_ONBOARDING_ARTIST: '/api/onboarding/artist/',
	GET_ONBOARDING_ORGANIZATION: '/api/onboarding/museum/',
};

const ENDPOINTS_APPUSER = {
	GET_APPUSER: '/api/app_user/',
	PUT_APPUSER: '/api/app_user/edit/',
	PUT_APPUSER_PASSWORD: '/api/app_user/password/',
	CHANGE_ROL_APP_USER: '/api/app_user/edit_role/',
	CHANGE_MAIN_ACCOUNT: '/api/app_user/main_account/',
	LOGIN: '/api/app_user/login/',
	RECOVERY_PASSWORD_BY_EMAIL: '/api/app_user/restore/',
	CONFIRM_RESTORE_PASSWORD: '/api/app_user/confirm_restore_password/',
	VALIDATE_EMAIL_EXISTENCE: '/api/app_user/validate_email/',
};

const ENDPOINTS_ARTIST = {
	GET_ARTISTS: '/api/artist/organization_user/',
	GET_ARTISTS_WITH_LABELS: '/api/artist/organization_user_with_labels/',
	GET_ARTIST: '/api/artist/',
	POST_ARTIST: '/api/artist/',
	PUT_ARTIST: '/api/artist/',
	GET_PUBLIC_ARTIST: '/api/artist/public/',
	GET_ARTIST_INFO: '/api/artist/artist_info/',
	DELETE_ARTIST: '/api/artist/',
};

const ENDPOINTS_ARTWORK = {
	GET_ARTWORK_LOCATION: '/api/artwork/artwork_location/organization_user/',
	GET_ARTWORKS: '/api/artwork/organization_user/',
	GET_ARTWORK: '/api/artwork/specific/',
	POST_ARTWORK: '/api/artwork/',
	PUT_ARTWORK: '/api/artwork/',
	GET_ARTWORK_INFO: '/api/artwork/artwork_info/',
	DELETE_ARTWORK: '/api/artwork/',
	GET_ARTWORK_IMAGE: '/api/file/image/',
	POST_ARTWORK_IMAGE: '/api/image/',
	PUT_ARTWORK_IMAGE: '/api/image/',
	PUT_ARTWORK_IMAGE_MAIN: '/api/artwork_image/',
	GET_PUBLIC_ARTWORK: '/api/artwork/public/specific/',
	GET_PUBLIC_ARTWORK_IMAGE: '/api/file/image/public/artwork/',
	GET_ALL_ARTWORKS_IMAGES: '/api/file/images/organization_users/',
};

const ENDPOINTS_CALL = {
	POST_CALL: '/api/call/',
	GET_CALL_ARTIST_ALL: '/api/call/all/',
	GET_CALL_ORGANIZATION_ALL: '/api/call/organization_user/',
	GET_CALL: '/api/call/',
	DELETE_CALL: '/api/call/delete_call/',
	PUT_CALL: '/api/call/edit/',
	GET_CALL_FORM: '/api/call/form/',
	POST_CALL_FORM: '/api/call/form/',
	PUT_CALL_STATUS: '/api/call/status/',
	GET_CALL_PUBLIC: '/api/call/call_public/',
	DELETE_CALL_FORM_ELEMENT: '/api/call/form/',
	DELETE_CALL_BENEFIT_ELEMENT: '/api/call/benefit/',
};

const ENDPOINTS_CITY = {
	GET_CITIES: '/api/country/city/',
	GET_CITIES_WITH_LABELS: '/api/country/city_with_labels/',
	GET_CITIES_ALL: '/api/cities/all/',
};

const ENDPOINTS_COLLECTION = {
	POST_COLLECTION: '/api/collection/',
	POST_COLLECTION_ALBUM: '/api/collection/add_collection_element/',
	GET_COLLECTION: '/api/collection/get_collection/',
	GET_COLLECTIONS: '/api/collection/organization_user/',
	GET_COLLECTION_ALBUM: '/api/collection/get_collection_element/',
	PUT_COLLECTION: '/api/collection/',
	GET_PUBLIC_COLLECTION: '/api/collection/collections/get_collection/',
	GET_PUBLIC_COLLECTION_ALBUM: '/api/collection/public/get_collection_element/',
	GET_PUBLIC_COLLECTIONS: '/api/collection/public/organization_user/',
	DELETE_COLLECTION: '/api/collection/',
	GET_COLLECTION_INFO: '/api/collection/collection_info/',
	PUT_ORDER_ARTWORKS: '/api/collection/artworks/order/',
	PUT_ORDER_SUBCOLLECTIONS: '/subcollection/order/',
};

const ENDPOINTS_CURRENCY = {
	GET_CURRENCIES: '/api/currencies_with_labels/',
};

const ENDPOINTS_COUNTRY = {
	GET_COUNTRIES: '/api/countries/',
	GET_COUNTRIES_WITH_LABELS: '/api/countries_with_labels/',
};

const ENDPOINTS_DOCUMENT = {
	GET_DOCUMENTS: '/api/document/organization_user/',
	GET_DOCUMENT: '/api/document/',
	POST_DOCUMENT: '/api/document/',
	PUT_DOCUMENT: '/api/document/',
	DELETE_DOCUMENT: '/api/document/',
	GET_INFO_DOCUMENT: '/api/document/document_info/',
	GET_PUBLIC_DOCUMENT: '/api/document/public/',
};

const ENDPOINT_FILES = {
	POST_FILE_PDF: '/api/file/document/',
	PUT_FILE_PDF: '/api/file/document/',
	POST_FILE_VIDEO: '/api/file/video/',
	PUT_FILE_VIDEO: '/api/file/video/',
	DELETE_FILE: '/api/file/',
};

const ENDPOINTS_LOCATION = {
	GET_LOCATIONS_GENERAL: '/api/location_general/organization_user/',
	GET_LOCATION_GENERAL: '/api/location_general/',
	POST_LOCATION_GENERAL: '/api/location_general/',
	PUT_LOCATION_GENERAL: '/api/location_general/',
	GET_LOCATIONS_GENERAL_INFO: '/api/location_general/location_general_info/',
	DELETE_LOCATION_GENERAL: '/api/location_general/',
	GET_LOCATION_GENERAL_with_LABELS:
		'/api/location_general/organization_user_with_labels/',

	GET_LOCATIONS_ROOMS: '/api/location_room/location_general_id/',
	GET_LOCATIONS_ROOMS_WITH_LABELS:
		'/api/location_room/location_general_id_with_labels/',
	GET_LOCATION_ROOM: '/api/location_room/',
	POST_LOCATION_ROOM: '/api/location_room/',
	PUT_LOCATION_ROOM: '/api/location_room/',
	GET_LOCATION_ROOM_INFO: '/api/location_room/location_room_info/',
	DELETE_LOCATION_ROOM: '/api/location_room/',

	GET_LAST_LOCATIONS: '/api/location/room/',
	GET_LOCATIONS_WITH_LABELS: '/api/location/location_room_with_labels/',
	GET_LAST_LOCATION: '/api/location/',
	POST_LAST_LOCATION: '/api/location/',
	PUT_LAST_LOCATION: '/api/location/',
	GET_INFO_LOCATION: '/api/location/location_info/',
	DELETE_UBICATION: '/api/location/',
};

const ENDPOINTS_MEASURE = {
	GET_MEASURES: '/api/measure/all_with_labels/',
};

const ENDPOINTS_PERMISSION = {
	GET_PERMISSION: '/api/permission/type_id/',
	POST_PERMISSION: '/api/permission/',
	PUT_PERMISSION: '/api/permission/type_id/',
	CHECK_PASSWORD: '/api/permission/password/',
};

const ENDPOINTS_PERSON = {
	GET_OWNER_CONTACTS: '/api/contact/person_owner_contact/organization_user/',
	GET_OWNER_CONTACTS_WITH_LABELS:
		'/api/contact/person_owner_contact/organization_user_with_labels/',
	GET_LOCATION_CONTACTS_WITH_LABELS:
		'/api/contact/person_location_contact/organization_user_with_labels/',
	GET_CONTACTS: '/api/contact/organization_user/',
	GET_CONTACT: '/api/contact/',
	POST_CONTACT: '/api/contact/',
	PUT_CONTACT: '/api/contact/',
	DELETE_CONTACT: '/api/contact/',
	GET_CONTACT_INFO: '/api/contact/contact_info/',
	GET_CONTACT_ROLES: '/api/contact/roles/',
};

const ENDPOINTS_EXPOSITION = {
	POST_EXPOSITION: '/api/exposition/',
	GET_EXPOSITION_INFO: '/api/exposition/exposition_info/',
	GET_EXPOSITIONS: '/api/exposition/organization_user/',
	GET_EXPOSITIONS_WITH_LABELS: '/api/exposition/organization_user_with_labels/',
	GET_EXPOSITION: '/api/exposition/',
	PUT_EXPOSITION: '/api/exposition/',
	GET_PUBLIC_EXPOSITION: '/api/exposition/public/',
	DELETE_EXPOSITION: '/api/exposition/',
};

const ENDPOINTS_PICTURES = {
	POST_PICTURE: '/api/file/image/',
	POST_MAIN_PICTURE: '/api/file/main/',
	POST_ARRAY_IMAGES: '/api/file/images/order/',
};

const ENDPOINTS_POSTULATION = {
	POST_POSTULATION: '/api/postulation/',
	GET_ORGANIZATION_ALL_POSTULATION: '/api/postulation/all/gallery/',
	GET_POSTULATION_ALL_ARTIST: '/api/postulation/all/artist/',
	POST_POSTULATION_ARTIST: '/api/postulation/artist/',
	GET_POSTULATION_CALL: '/api/postulation/call_id/',
	GET_POSTULATION: '/api/postulation/',
	DELETE_POSTULATION: '/api/postulation/',
	PUT_POSTULATION: '/api/postulation/',
	POST_POSTULATION_NEW: '/api/postulation/new/',
	GET_POSTULATION_DATA: 'api/postulation/data/',
	POST_POSTULATION_APPLICATION_FORM: '/api/postulation/application/form/',
	PUT_POSTULATION_APPLICATION_FORM: '/api/postulation/application/form/edit/',
	POST_POSTULATION_APPLICATION_FORM_DOCUMENT:
		'/api/postulation/application/form/document/',
	DELETE_POSTULATION_FORM_DOCUMENT:
		'/api/postulation/application/form/document/',
	POST_POSTULATION_DOCUMENT: '/api/postulation/document/',
	GET_POSTULATION_DOCUMENT: '/api/postulation/document/',
	DELETE_POSTULATION_DOCUMENT: '/api/postulation/document/',
	GET_POSTULATION_DATA_BY_CALL_AND_ORGANIZATION_USER_ID:
		'/api/postulation/application/call/',
	POST_CREATE_POSTULATION: '/api/postulation/create/',
	PUT_POSTULATION_STATUS: '/api/postulation/status/',
	POST_POSTULATION_CATALOGUE: '/api/postulation/catalogue/',
	PUT_POSTULATION_CATALOGUE: '/api/postulation/catalogue/edit/',
	POST_POSTULATION_PORTFOLIO: '/api/postulation/portfolio/',
};

const ENDPOINTS_POSTULATION_ITEM = {
	PUT_POSTULATION: '/api/postulation_item/select/',
};

const ENDPOINTS_PORTFOLIO = {
	POST_PORTFOLIO: '/api/portfolio/v1/',
	POST_PORTFOLIO_V2: '/api/portfolio/v2/',
	GET_PORTFOLIOS: '/api/portfolio/organization_user/',
	PUT_PORTFOLIO: '/api/portfolio/v1/',
	GET_PORTFOLIO: '/api/portfolio/v1/',
	GET_PORTFOLIO_V2: '/api/portfolio/v2/',
	GET_PORTFOLIO_BY_SLUG: '/api/portfolio/v1/',
	GET_PORTFOLIO_BY_SLUG_V2: '/api/portfolio/v2/',
	GET_INFO_PORTFOLIO: '/api/portfolio/v1/portfolio_info/',
	DELETE_PORTFOLIO: '/api/portfolio/v1/',
	POST_PORTFOLIO_SLIDE: '/api/portfolio/slide/',
	DELETE_PORTFOLIO_SLIDE: '/api/portfolio/slide/',
	GET_PORTFOLIO_SLIDE: '/api/portfolio/slide/',
	PUT_PORTFOLIO_SLIDE: '/api/portfolio/slide/',
	POST_SLUG_REPEAT: '/api/portfolio/slugs_portfolio/',
	///EXPERT - SLIDE
	GET_EXPERT_PORTFOLIO_SLIDES: '/api/portfolio/portfolio_expert/slide/',
	POST_EXPERT_PORTFOLIO_SLIDE: '/api/portfolio/portfolio_expert/slide/',
	PUT_EXPERT_PORTFOLIO_SLIDES_ORDER:
		'/api/portfolio/portfolio_expert/slide_order/',
	DELETE_EXPERT_PORTFOLIO_SLIDE: '/api/portfolio/portfolio_expert/slide/',
	///EXPERT - SLIDE ITEM
	GET_EXPERT_PORTFOLIO_SLIDES_ITEM:
		'/api/portfolio/portfolio_expert/slide_item/',
	POST_EXPERT_PORTFOLIO_SLIDE_ITEM:
		'/api/portfolio/portfolio_expert/slide_item/',
	POST_EXPERT_PORTFOLIO_SLIDE_ARTWORK_ITEM:
		'/api/portfolio/portfolio_expert/slide_item_artwork/',
	PUT_EXPERT_PORTFOLIO_SLIDE_ARTWORK_ITEM_TO_ARTWORK:
		'/api/portfolio/portfolio_expert/slide_item_artwork/',
	PUT_EXPERT_PORTFOLIO_SLIDE_ITEMS_ORDER:
		'/api/portfolio/portfolio_expert/slide_item_order/',
	DELETE_EXPERT_PORTFOLIO_SLIDES_ITEM:
		'/api/portfolio/portfolio_expert/slide_item/',
	//EXPERT - SLIDE ITEM ELEMENT
	GET_EXPERT_PORTFOLIO_SLIDE_ITEM_ELEMENT:
		'/api/portfolio/portfolio_expert/slide_item_element/',
	POST_EXPERT_PORTFOLIO_SLIDE_ITEM_ELEMENT:
		'/api/portfolio/portfolio_expert/slide_item_element/',
	PUT_EXPERT_PORTFOLIO_SLIDE_ITEM_ELEMENT:
		'/api/portfolio/portfolio_expert/slide_item_element/',
	DELETE_EXPERT_PORTFOLIO_SLIDE_ITEM_ELEMENT:
		'/api/portfolio/portfolio_expert/slide_item_element/',
};

const ENDPOINTS_ORGANIZATION = {
	ADD_USER_TO_ORGANIZATION: '/api/app_user/organization_user/invite/',
	GET_USERS_ORGANIZATION: '/api/organization_user/app_user/',
	GET_ORGANIZATIONS_WHERE_IAM: '/api/app_user/organization_info/',
	DELETE_USER_FROM_ORGANIZATION: '/api/app_user/role/',
};

const ENDPOINT_UNKNOWNVALUES = {
	GET_UNKNOWNVALUES: '/api/app_user/',
};

const ENDPOINT_CALENDAR = {
	GET_ALL_CALENDAR_EVENTS: '/api/calendar/all_dates',
};
const ENDPOINT_RESOURCES = {
	GET_ALL_RESOURCES: '/api/resources_articles/all_articles',
};

const ENDPOINTS_STRIPE = {
	POST_CREATE_CHECKOUT_SESSION: '/api/stripes/create_checkout_session/',
	POST_CREATE_FREE_SUBSCRIPTION: '/api/stripes/create_free_subscription/',
	GET_ALL_PRODUCTS: '/api/stripes/products/',
	PUT_OR_DELETE_SUBSCRIPTION: '/api/stripes/',
	POST_WEBHOOK: '/api/stripes/webhook/',
	DELETE_SUBSCRIPTION: (organizationUserId) =>
		`/api/stripes/${organizationUserId}`,
};

export {
	ENDPOINTS_APPUSER,
	ENDPOINTS_ARTIST,
	ENDPOINTS_ARTWORK,
	ENDPOINTS_CALL,
	ENDPOINTS_CITY,
	ENDPOINTS_COLLECTION,
	ENDPOINTS_CURRENCY,
	ENDPOINTS_COUNTRY,
	ENDPOINTS_DOCUMENT,
	ENDPOINTS_EXPOSITION,
	ENDPOINT_FILES,
	ENDPOINTS_LOCATION,
	ENDPOINTS_MEASURE,
	ENDPOINTS_PERMISSION,
	ENDPOINTS_PERSON,
	ENDPOINTS_PICTURES,
	ENDPOINTS_POSTULATION,
	ENDPOINTS_POSTULATION_ITEM,
	ENDPOINTS_PORTFOLIO,
	ENDPOINTS_ORGANIZATION,
	ENDPOINT_UNKNOWNVALUES,
	ENDPOINTS_ONBOARDING,
	ENDPOINT_CALENDAR,
	ENDPOINT_RESOURCES,
	ENDPOINTS_STRIPE,
};
