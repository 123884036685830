import { removeUserSession } from '../../../../Pages/authentication/servicesAuthentication';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetAppUser } from '../../../../BusinessLogic/redux/reducers/appUser';

/**
 * It's a button that when clicked, removes the user's session
 * @returns
 */
export const ButtonSignOut = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<button
			className={'body-Text2'}
			onClick={() => {
				dispatch(resetAppUser());
				removeUserSession(navigate);
			}}
		>
			{t('CerrarSesión')}
		</button>
	);
};
