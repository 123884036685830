// 3rd Party
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

// BL
import { postExpertPortfolio } from '../../../BusinessLogic/redux/reducers/portfolio';
import {
	configurationFormLabelValues,
	configurationFormInitialValues,
} from '../../../BusinessLogic/data/ExpertPortfolioData';

// UI
import ViewForm from '../../../UI/templates/ViewForm';
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import ButtonSwitch from '../../../UI/atoms/Buttons/ButtonSwitch';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';

export default function TemplateConfigurations() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { appUser } = useSelector((state) => state.appUser);
	const { portfolioDataCreation } = useSelector((state) => state.portfolios);

	const [isLoading, setIsLoading] = useState(false);

	const expertPortfolioGeneralPostData = {
		name: `${appUser.first_name} ${appUser.last_name}`,
		type: portfolioDataCreation.portfolioType,
		permissions: 'PUBLIC',
		organization_user_id: localStorage.organization_user_id,
		type_id: `${portfolioDataCreation.idCollection}`,
		description: 'default description',
	};

	const handleSubmit = (values) => {
		const postExpertPortfolioData = {
			...expertPortfolioGeneralPostData,
			...values,
		};
		setIsLoading(true);
		dispatch(postExpertPortfolio(postExpertPortfolioData))
			.then((res) => {
				navigate(
					`/portafolios/portafolio-experto/${res.payload.single_portfolio.id}/edicion`
				);
			})
			.catch(() => alert(t('FormularioNoEnviado')))
			.finally(() => setIsLoading(false));
	};

	return (
		<ViewForm>
			<SecondaryNavbarSpecific
				title={t('NuevoPortafolio')}
				textColor='text-yellow-50'
				color='bg-yellow-50'
				hide='invisible'
				design='mb-8 md:mb-0'
				textBack={t('RegresarColecciones')}
				textBackDesign='pl-3 text-yellow-50'
				arrowLeftColor='yellow'
			/>
			<Formik
				initialValues={configurationFormInitialValues}
				onSubmit={handleSubmit}
			>
				{(formProps) => (
					<Form>
						<div className='mx-auto mt-8 max-w-[560px]'>
							<TextContentSecond
								design='mb-12 !text-base'
								text={t('InformaciónDeObra')}
							/>
							{Object.keys(formProps.values).map((key, index) => (
								<div className='mb-2 flex justify-between' key={index}>
									<TextContentSecond
										design='mb-4 max-w-[70%]'
										text={t(configurationFormLabelValues[key])}
									/>
									<ButtonSwitch
										switched={formProps.values[key] === '1'}
										onSwitchAction={() =>
											formProps.setFieldValue(
												key,
												formProps.values[key] === '1' ? '0' : '1'
											)
										}
									/>
								</div>
							))}
						</div>
						<div className='mx-auto mt-16 mb-4 flex max-w-[1020px] justify-end'>
							<ButtonFab
								colour='green'
								label={!isLoading ? t('Crear') : `${t('Guardando')}...`}
								type='submit'
								disabled={isLoading}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</ViewForm>
	);
}
