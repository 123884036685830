import React from 'react';
import PropTypes from 'prop-types';

const TextSubTitleSkeleton = ({
	designTextSubTitle,
	designTextSubTitleIconRight,
}) => {
	return (
		<div className='mb-5 py-3 pr-3'>
			<div className='flex w-full flex-row justify-between'>
				<div
					className={`w-42 skeleton-animation-background-light h-6 animate-skeleton rounded 
  bg-middle_grey-50 ${designTextSubTitle}`}
				></div>
				<div
					className={`skeleton-animation-background-light h-6 w-6 animate-skeleton rounded 
  bg-middle_grey-50  ${designTextSubTitleIconRight}`}
				></div>
			</div>
			<div
				className={`skeleton-animation-background-light mt-1 h-1 w-full animate-skeleton rounded 
        bg-middle_grey-50`}
			></div>
		</div>
	);
};

TextSubTitleSkeleton.propTypes = {
	designTextSubTitle: PropTypes.string,
	designTextSubTitleIconRight: PropTypes.string,
};

export default TextSubTitleSkeleton;
