export const SINGLE_EN = {
	Identificación: 'Identification',
	Descripción: 'Description',
	Imágenes: 'Images',
	Contacto: 'Contact',
	Biografía: 'Biography',
	Educación: 'Education',
	Residencias: 'Residences',
	Exposiciones: 'Exhibitions',
	Publicaciones: 'Publications',
	ProyectosEspeciales: 'Special Projects',
	Nombres: 'First name',
	Nombre: 'Name',
	Apellidos: 'Last name',
	PaisDeOrigen: 'Country',
	Teléfono: 'Phone number',
	Email: 'Email',
	PaginaWeb: 'Website',
	Código: 'Code',
	OtroCodigo: 'Code other',
	TipoDeObjeto: 'Object type',
	BiDimensional: 'Bi-dimesional',
	TriDimensional: 'Tri-dimensional',
	Diferencias: 'Differences',
	Artista: 'Artist',
	Autor: 'Author',
	Observaciones: 'Observations',
	Fecha: 'Date',
	FichaTecnica: 'Data sheet',
	DimensionesObjeto: 'Object dimensions',
	UnidadDeMedida: 'Unit of measure',
	Altura: 'Height',
	Largo: 'Length',
	Ancho: 'Width',
	Peso: 'Weight',
	DimensionesBase: 'Base dimensions',
	Componentes: 'Components',
	TieneSubcomponentes: 'Does it have subcomponents ?',
	Si: 'Yes',
	No: 'No',
	NumeroDeComponentes: 'Number of components',
	Detalles: 'Details',
	TecnicaYMateriales: 'Technique and Materials',
	Técnica: 'Technique',
	Medida: 'Measure',
	Soporte: 'Bracket',
	Materiales: 'Materials',
	EstadoDeConservacion: 'Preservation status',
	Otros: 'Other',
	Propietario: 'Owner',
	Estatus: 'Status',
	Conservado: 'Preserved',
	Vendido: 'Sold',
	Donado: 'Donated',
	Disponible: 'Available',
	Desconocido: 'Unknown',
	Ubicación: 'Location',
	Lugar: 'Place',
	Sala: 'Hall',
	Avalúo: 'Valuation',
	Precio: 'Price',
	Moneda: 'Currency',
	DigitalizacionDeLaObra: 'Artwork scan',
	FechaDeElaboracion: 'Elaboration date',
	UltimaActualizacion: 'Last update',
	Archivos: 'Files',
	Subcolecciones: 'Sub collections',
	OtroNumeroTelefono: 'Another phone number',
	Pertenece: 'Belongs to',
	Título: 'Title',
	Acceso: 'Access',
	Resolución: 'Resolution',
	Eliminar: 'Delete',
	RegresarHome: 'Back to Home',
	RegresarArtistas: 'Back to Artists',
	RegresarPerfil: 'Back to Profile',
	RegresarObras: 'Back to Artworks',
	RegresarConvocatorias: 'Back to Calls',
	RegresarContactos: 'Back to Contacts',
	RegresarDocumentos: 'Back to Documents',
	RegresarExposiciones: 'Back to Exhibitions',
	RegresarSala: 'Back to Room',
	RegresarLugares: 'Back to Places',
	RegresarLugar: 'Back to Place',
	RegresarPortafolios: 'Back to Portfolios',
	EliminarPerfil: 'Delete Profile',
	SeguroQueDeseasEliminarEstePerfil: 'Are you sure to delete this profile?',
	EliminarObra: 'Delete Artwork',
	SeguroQueDeseasEliminarEstaObra: 'Are you sure to delete this artwork?',
	EliminarColeccion: 'Delete Collection',
	SeguroQueDeseasEliminarEstaColeccion:
		'Are you sure to delete this collection?',
	EliminarContacto: 'Delete Contact',
	SeguroQueDeseasEliminarEsteContacto: 'Are you sure to delete this contact?',
	EliminarDocumento: 'Delete Document',
	SeguroQueDeseasEliminarEsteDocumento: 'Are you sure to delete this document?',
	EliminarExposición: 'Delete Exposition',
	SeguroQueDeseasEliminarEstaExposición:
		'Are you sure to delete this exposition?',
	EliminarUbicación: 'Delete Location',
	SeguroQueDeseasEliminarEstaUbicación: 'Are you sure to delete this location?',
	EliminarLugar: 'Delete Place',
	SeguroQueDeseasEliminarEsteLugar: 'Are you sure to delete this place?',
	EliminarSala: 'Delete Room',
	SeguroQueDeseasEliminarEstaSala: 'Are you sure to delete this room',
	EliminarPortafolio: 'Delete Portfolio',
	SeguroQueDeseasEliminarEstePortafolio:
		'Are you sure to delete this portfolio?',
	EliminarSelección: 'Delete selection',
	SeguroQueDeseasEliminarLasObrasSeleccionadas:
		'Are you sure to delete the selected artworks?',
	SeguroQueDeseasEliminarLosDocumentosSeleccionados:
		'Are you sure you want to delete the selected documents?',
	Perfil: 'Profile',
	perfil: 'profile',
	Artistas: 'Artists',
	Home: 'Home',
	Obras: 'Artworks',
	Obra: 'Artwork',
	obra: 'artwork',
	Convocatorias: 'Calls',
	contacto: 'contact',
	Contactos: 'Contacts',
	Documento: 'Document',
	documento: 'document',
	Exposicion: 'Exhibition',
	exposicion: 'exhibition',
	Lugares: 'Places',
	lugar: 'place',
	sala: 'room',
	Salas: 'Rooms',
	Portafolio: 'Portfolio',
	portafolio: 'portfolio',
	Portafolios: 'Portfolios',
	Rechazar: 'Reject',
	Aprobar: 'Approve',
	Aceptar: 'Accept',
	Coleccion: 'Collection',
	coleccion: 'collection',
	Colecciones: 'Collections',
	CambiarImagenPortada: 'Change cover photo',
	Visualizar: 'View',
	WhatsApp: 'WhatsApp',
	Instagram: 'Instagram',
	Facebook: 'Facebook',
	Twitter: 'Twitter',
	LinkedIn: 'LinkedIn',
	Rol: 'Role',
	URL: 'URL',
	Dirección: 'Address',
	País: 'Country',
	Ciudad: 'City',
	CódigoPostal: 'Zip code',
	Ubicaciones: 'Locations',
	Piso: 'Floor',
	ubicacion: 'location',
	Cancelar: 'Cancel',
	Guardar: 'Save',
	NoHayInformaciónDisponible: 'No information',
	AñadirNuevaImagen: 'Add new image',
	AñadirObra: 'Add artwork',
	DescargarImagen: 'Download image',
	EliminarImágenes: 'Delete images',
	OrganizarImágenes: 'Organize images',
	OrganizarObras: 'Organize artworks',
	OrganizarSubColecciones: 'Organize sub collections',
	Roles: 'Roles',
	Usuario: 'User',
	RolesYPermisos: 'Roles and permissions',
	RolesAsignados: 'Assigned roles',
	EliminarUsuario: 'Delete user',
	SeguroQueDeseasEliminarEsteUsuario: 'Are you sure to delete the user?',
	SeleccionarTodasLasObras: 'Select all artworks',
	ObrasSeleccionadas: 'Selected artworks',
	NoHayExposicionesDisponibles: 'No exhibitions available',
	DocumentosSeleccionados: 'Selected Documents',
	DocumentoSeleccionado: 'Selected Document',
	EliminarDocumentos: 'Delete Documents',
	Descargar: 'Download',
	EliminarImagen: 'Delete Image',
	SeguroQueDeseasEliminarEstaImagen:
		'Are you sure you want to delete this image?',
	ImágenesSeleccionadas: 'Selected Image',
	DescargarDocumentos: 'Download Documents',
	ParaCrearNuevaImagen: 'To create a new image, ',
	ParaCrearNuevoArtista: 'To create a new artist',
	ParaCrearNuevaObra: 'To create a new artwork',
	ParaCrearNuevoDocumento: 'To create a new document',
	ParaCrearNuevaColección: 'To create a new collection',
	ParaCrearNuevaSubColección: 'To create a new sub collection',
	ParaCrearNuevoLugar: 'To create a new place',
	ParaCrearNuevaSala: 'To create a new room',
	ParaCrearNuevaUbicación: 'To create a new location',
	ParaCrearNuevoContacto: 'To create a new contact',
	ParaCrearNuevaExposición: 'To create a new exposition',
	ParaCrearNuevoPortafolio: 'To create a new portfolio',
	HazClicEnElBotón: 'click the button',
	HazClicAquí: 'click here.',
	SubColecciones: 'Sub collections',
	BorrarCambios: 'Discard changes',
	AlVolverALaSecciónAnteriorVasABorrarTodosLosCambiosQueAcabasDeHacer:
		'If you come back to the previous section all the changes made will be discarded',
	SeguroQueDeseasEliminarLosCambios: 'Are you sure to discard the changes?',
	RegresarAConfiguraciónDeCuenta: 'Back to account settings',
	EstadoPropietario: 'Status',
	DescargarCatalogo: 'Download catalogue',
	DescargarFormulario: 'Download form',
	DescargarPortafolio: 'Descargar portfolio',
	DescargarCV: 'Download CV',
	HojaDeVida: 'CV',
	MostrarPrecios: 'Show prices',
	cover: 'Cover',
	textBlockAndImage: 'Text block and image',
	textBlock: 'Text block',
	fullScreenImage: 'Fullscreen image',
	artworksGroup: 'Artworks group',
	artworkIndividual: 'Artwork individual',
	backCover: 'Back Cover',
	EliminarSección: 'Delete section',
	SeguroDeseasEliminarEstaSección: 'Are you sure to delete this section ?',
	IniciaSesiónParaPostularte: 'Log in to Datarte to apply for the call',
	Revisada: 'Reviewed',
	NoPuedesBorrarEstePortafolio: 'You can not delete this portfolio',
	EstePortafolioHaceParteDeUnaDeTusConvocatorias:
		'This portfolio cannot be deleted, as your application was saved for future projects outside of the original call.',
	ParaPoderAplicarDebesRegistrarte:
		'In order to apply to this call, you must register with Datarte or enter your account.',
	PorQuéTenerUnaCuentaEnDatarte: 'Why have an account in Datarte?',
	CrearConvocatoriasPersonalizadas: 'Create custom calls.',
	AdaptarLaConvocatoriaATusNecesidades:
		'Addapt the call to your needs and of your comunnity.',
	SolicitarPerfilDelArtistaDeAcuerdoConNecesidadesDeLaConvocatoria:
		'Request the artist profile according to the needs of the call.',
	EscogerSiElArtistaPostulaPortafolioOCatálogo:
		'Choose if the artist postulates by portfolio or catalogue.',
	SolicitarDocumentosAdjuntosDelArtista: 'Request artists attached documents ',
	CrearFormulariosPersonalizados: 'Create custom forms.',
	HacerModificacionesYSeguimiento: 'Modify and trace in real time.',
	RevisarLosPerfilesPostulados: 'Review postulated profiles in just one click',
	EnvíarNotificacionesDeManeraAutomatizada: 'Send notifications automatically.',
	GestionaTusConvocatoriasEnUnSoloLugar: 'Manage your calls in just one place.',
	CrearConvocatoria: 'Create call',
	ComoVanTusConvocatorias: 'How are your calls going',
	PostulacionesRecibidas: 'Postulations',
	LoSentimosTodavíaNoTienesPostulaciones: 'You have no postulations yet',
	AnteriorConvocatoria: 'Previous call',
	SiguienteConvocatoria: 'Next call',
	AplicaAConvocatoriasIlimitadasDesdeLaApp:
		'Apply to unlimited calls from the app.',
	DescubreNuevasOportunidadesCadaSemana:
		'Discover new opportunities every week.',
	CreaPortafoliosVirtualesPersonalizados:
		'Create personalized virtual portfolios.',
	OrganizaTusObrasPorColeccionesEnLaNube:
		'Organize your works by collections in the cloud.',
	Paso1: 'Step 1',
	Paso2: 'Step 2',
	Paso3: 'Step 3',
	SeleccionaLaColecciónQueQuieresUtilizarEnTuPortafolio:
		'Choose the collection you want to use in your portfolio.',
	SoloSeVisualizaránColeccionesConObrasAsociadasAEstaCuenta:
		'Only artworks associated to this account will be displayed.',
	SeleccioneElArtista: 'Select the artist',
	SeleccioneElArtistaAPostular: 'Select the artist to postulate in this call.',
	DebesCrearObrasOrganización:
		'To postulate this artist to this call, it is necessary to upload their artworks to Datarte.',
	CrearObras: 'Create artworks',
	ParaAplicarALaConvocatoriaDebeCrearArtista:
		'To postulate to this call, it is necessary to create artists and upload their artworks to Datarte.',
	CrearArtista: 'Create artist',
};
