//3rd parties
import { ListItem } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//UI
import InputCustomName from '../../atoms/Inputs/InputCustomName';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

import { ReactComponent as EyeGrey } from '../../assets/icons/EyeGrey.svg';
import { ReactComponent as EyeSlashGrey } from '../../assets/icons/EyeSlashGrey.svg';

/**
 * @description  formik field used for passwords fields.
 * @param {*} param0
 * @returns
 */

export default function FieldPasswordFormik({
	label,
	dbName,
	type,
	labelDesign,
	errorMessageDesign,
	errors,
	setPasswordVisibility,
	passwordVisibility,
	setConfirmPasswordVisibility,
	confirmPasswordVisibility,
	inputClass,
}) {
	const { t } = useTranslation();

	const selectedFieldPassword = () => {
		if (dbName === 'password') {
			setPasswordVisibility(!passwordVisibility);
		} else if (dbName === 'confirm_password') {
			setConfirmPasswordVisibility(!confirmPasswordVisibility);
		}
	};
	return (
		<ListItem
			style={{
				paddingLeft: '0px',
				paddingRight: '0px',
				justifyContent: 'space-between',
			}}
		>
			<div className={labelDesign}>
				<TextContentSecond text={label} />
			</div>

			<div className='flex flex-col'>
				{inputClass ? (
					<div className='relative'>
						<Field
							name={dbName}
							type={type}
							as={InputCustomName}
							className={inputClass}
						/>

						<div
							className='absolute -right-[1.2rem] bottom-[0.25rem] w-[15px] cursor-pointer'
							onClick={() => selectedFieldPassword()}
						>
							{passwordVisibility || confirmPasswordVisibility ? (
								<EyeGrey />
							) : (
								<EyeSlashGrey />
							)}
						</div>
					</div>
				) : (
					<div className='relative'>
						<Field name={dbName} type={type} as={InputCustomName} />

						<div
							className='absolute -right-[1.2rem] bottom-[0.25rem] w-[15px] cursor-pointer'
							onClick={() => selectedFieldPassword()}
						>
							{passwordVisibility || confirmPasswordVisibility ? (
								<EyeGrey />
							) : (
								<EyeSlashGrey />
							)}
						</div>
					</div>
				)}
				{errors && (
					<div className={`w-40 md:w-56 ${errorMessageDesign}`}>
						{t(errors)}
					</div>
				)}
			</div>
		</ListItem>
	);
}

FieldPasswordFormik.propTypes = {
	/**Type of  formik field */
	type: PropTypes.string,
	/** Text displayed on the string */
	label: PropTypes.string,
	/** Tailwind design of the label */
	labelDesign: PropTypes.string,
	/** This fields connects the data. Please use the same name that on the database for simplicity */
	dbName: PropTypes.string,
	/** Tailwind design of the ErrorMessage*/
	errorMessageDesign: PropTypes.string,
	/** this error string is generated from te yup validation schema in case of a missing requerid field*/
	errors: PropTypes.string,
	/* A function to set passwordVisibility to a bool value in the parent component. */
	setPasswordVisibility: PropTypes.func,
	/*  bool value to set if the password is or not visible */
	passwordVisibility: PropTypes.bool,
	/* A function to set confirmPasswordVisibility to a bool value in the parent component. */
	setConfirmPasswordVisibility: PropTypes.func,
	/*  bool value to set if confirm password is or not visible */
	confirmPasswordVisibility: PropTypes.bool,
};

FieldPasswordFormik.defaultProps = {
	type: 'password',
	labelDesign: 'w-max',
};
