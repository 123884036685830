import React from 'react';
import PropTypes from 'prop-types';

import TemplateWithNavbarSkeleton from './TemplateWithNavbarSkeleton';
import SkeletonImages from '../../molecules/Skeleton/SkeletonImages';

const ViewAllSkeleton = ({
	numberOfImages,
	designSkeletonImages,
	designSkeletonTitle1,
	designSkeletonTitle2,
	designSkeletonTitle3,
	designSkeletonTitle4,
}) => {
	return (
		<div>
			<TemplateWithNavbarSkeleton
				designSkeletonTitle1={designSkeletonTitle1}
				designSkeletonTitle2={designSkeletonTitle2}
				designSkeletonTitle3={designSkeletonTitle3}
				designSkeletonTitle4={designSkeletonTitle4}
			/>
			<SkeletonImages
				numberOfImages={numberOfImages}
				design={designSkeletonImages}
			/>
		</div>
	);
};

ViewAllSkeleton.propTypes = {
	/** Number of  images to be displayed */
	numberOfImages: PropTypes.number,
	/** TailWind className for TemplateWithNavbarSkeleton component*/
	designSkeletonImages: PropTypes.string,
	/** TailWind className for TemplateWithNavbarSkeleton component*/
	designSkeletonTitle1: PropTypes.string,
	/** TailWind className for TemplateWithNavbarSkeleton component*/
	designSkeletonTitle2: PropTypes.string,
	/** TailWind className for TemplateWithNavbarSkeleton component*/
	designSkeletonTitle3: PropTypes.string,
	/** TailWind className for TemplateWithNavbarSkeleton component*/
	designSkeletonTitle4: PropTypes.string,
};

export default ViewAllSkeleton;
