import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

//UI
import {
	FilterInput,
	ActiveFilters,
} from '../../../UI/organisms/Filter/Filter';
import { ReactComponent as FilterIcon } from '../../../UI/assets/icons/Filter.svg';

export default function FilterCollection({
	filterArray,
	setFilterArray,
	filterChange,
	setFilterChange,
	nameFilter,
	setNameFilter,
}) {
	const [showFilter, setShowFilter] = useState(false);
	const { t } = useTranslation();
	const expandedFilter = (
		<div className='fixed right-0 top-0 z-40 h-full w-3/4 overflow-x-auto bg-white p-8 lg:w-2/5'>
			<div className='expandedFilter__header'>
				<div
					className='expandedFilter__erase'
					onClick={() => {
						setFilterArray({});
						setFilterChange(filterChange + 1);
						setShowFilter(false);
					}}
				></div>
			</div>
			<FilterInput
				filterLabel={t('Colecciones')}
				filterVariable='name'
				nameFilter={nameFilter}
				setNameFilter={setNameFilter}
				setShowFilter={setShowFilter}
			/>
			<ActiveFilters
				filterArray={filterArray}
				setFilterArray={setFilterArray}
				filterChange={filterChange}
				setFilterChange={setFilterChange}
			/>
		</div>
	);

	return (
		<>
			<div onClick={() => setShowFilter(true)}>
				<FilterIcon />
			</div>
			{showFilter ? expandedFilter : ''}
		</>
	);
}
