import React from 'react';
import SkeletonTitle from '../../UI/molecules/Skeleton/SkeletonTitle';
import SkeletonImages from '../../UI/molecules/Skeleton/SkeletonImages';
import PropTypes from 'prop-types';
import TemplateWithNavbarSkeleton from '../../UI/templates/SkeletonTemplates/TemplateWithNavbarSkeleton';

const HomeSkeleton = ({ numberOfImagesTop, numberOfImagesBottom }) => {
	return (
		<div>
			<TemplateWithNavbarSkeleton designSkeletonTitle3={'invisible'} />

			<SkeletonImages numberOfImages={numberOfImagesTop} />
			<SkeletonTitle
				designSkeletonTitle2={'lg:w-64'}
				designSkeletonTitle3={'invisible'}
			/>
			<SkeletonImages numberOfImages={numberOfImagesBottom} />
		</div>
	);
};

HomeSkeleton.defaultProps = {
	numberOfImagesTop: 0,
	numberOfImagesBottom: 0,
};

HomeSkeleton.propTypes = {
	/** Number of images  displayed in the top of the component*/
	numberOfImagesTop: PropTypes.number,
	/** Number of images  displayed in the bottom of the component*/
	numberOfImagesBottom: PropTypes.number,
};

export default HomeSkeleton;
