//3rd party components
import { Link, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';

//BL
import { changeCollectionState } from '../../../BusinessLogic/redux/reducers/collection';
import { setUniqueIdCollection } from '../../../BusinessLogic/redux/reducers/portfolio';

//UI
import 'react-lazy-load-image-component/src/effects/blur.css';
import TextContentThird from '../../atoms/Text/Content/TextContentThird';
import { ReactComponent as RectanglePlantilla } from '../../assets/icons/RectanglePlantilla.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/Check.svg';
import { ReactComponent as RedCross } from '../../assets/icons/crossInRedCircle.svg';
import ModalImage from '../../organisms/PopUp/ModalImage';
import { saveSectionURL } from '../../../BusinessLogic/redux/reducers/navigation';

/**
 * @description Molecule that has the image information.
 * @changes Has a dispatch (BL) in a molecule. We should remove
 * @param {*} param0
 * @returns
 */

export default function ImageAndTitle({
	id,
	imagen,
	title,
	sectionTitle,
	redirect,
	imageFormat,
	isPublic,
	selectionSquare,
	showDownloadOptions,
	addCheckToImage,
	DeleteImageButton,
	containerRef,
	objectFitProp,
	roundedBorder,
	aspectRatioProp,
	showDeleteOption,
	saveLastSectionURL,
	isImageWithoutFormat,
	designTitle,
	designContainer,
	imageAlt,
	isVideoImage,
}) {
	const location = useLocation();
	const dispatch = useDispatch();
	const { uniqueIdCollection } = useSelector((state) => state.portfolios);
	const [selectPlantilla, setSelectPlantilla] = useState(0);
	const [imageActive, setImageActive] = useState(false);
	const imageToDownload = useRef(null);
	const [showModalImage, setShowModalImage] = useState(false);

	let image_format;

	if (imageFormat === 'small') {
		image_format = '_medium';
	} else {
		image_format = '';
	}

	let defaultImage =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';

	let redirectPath = redirect
		? isPublic
			? `/${redirect}/public/${id}`
			: `/${redirect}/${id}`
		: '';

	const toggleDownloableImage = () => {
		setImageActive(!imageActive);
		addCheckToImage(imagen, imageActive);
	};

	useEffect(() => {
		setImageActive(false);
	}, [showDownloadOptions]);

	if (sectionTitle === 'Imágenes' || sectionTitle === 'Images') {
		redirectPath = '';
	}

	const handleLoadImageSrc = () => {
		if (imagen) {
			return imagen + (isImageWithoutFormat ? '' : image_format);
		} else {
			return defaultImage;
		}
	};

	return (
		<div
			className={`relative ${designContainer}`}
			onClick={() => {
				dispatch(changeCollectionState(true));
				if (saveLastSectionURL) dispatch(saveSectionURL(location.pathname));
			}}
		>
			<div className='relative flex flex-col items-center'>
				<Link
					className='relative w-full'
					to={{
						pathname: redirectPath,
					}}
				>
					<div ref={containerRef}>
						{isVideoImage ? (
							<ReactPlayer
								url={imagen}
								width={'100%'}
								height={'100%'}
								controls={false}
								light={true}
								className='pointer-events-none aspect-square'
							/>
						) : (
							<LazyLoadImage
								alt={imageAlt ?? title}
								src={handleLoadImageSrc()}
								width='100%'
								height='100%'
								effect='blur'
								wrapperClassName={`overflow-hidden flex items-center lazyLoadingImages`}
								delayTime='0'
								threshold='800'
								style={{
									objectFit: objectFitProp,
									aspectRatio: aspectRatioProp,
									borderRadius: roundedBorder,
								}}
								ref={imageToDownload}
								onClick={() => {
									if (
										sectionTitle === 'Imágenes' ||
										sectionTitle === 'Images'
									) {
										setShowModalImage(true);
									}
								}}
							/>
						)}
					</div>
					{showDownloadOptions && (
						<div
							onClick={() => toggleDownloableImage()}
							className=' absolute right-0 top-0 flex h-full w-full  cursor-pointer select-none justify-end p-2 '
						>
							<div
								className={` m-2 flex h-1/6 w-1/6 justify-center rounded-full border-2 ${
									imageActive ? 'bg-white' : ''
								}`}
							>
								{imageActive && showDownloadOptions && (
									<p className='flex h-full w-full place-content-center rounded-full text-white'>
										<CheckIcon className='h-full w-full' />
									</p>
								)}
							</div>
						</div>
					)}
					{showDeleteOption && (
						<div
							onClick={() => DeleteImageButton(id)}
							className=' absolute right-0 top-0 flex h-full w-full cursor-pointer select-none justify-end'
						>
							<div className={`m-2 flex h-1/6 w-1/6 justify-center`}>
								<RedCross className='h-full w-full' />
							</div>
						</div>
					)}
				</Link>
				{(selectionSquare || title) && (
					<div className='mb-4 mt-2 flex w-full flex-row items-center justify-between'>
						{selectionSquare && (
							<RectanglePlantilla
								className='mr-2 cursor-pointer'
								key={id}
								fill={
									uniqueIdCollection === selectPlantilla ? '#9CBDB9' : 'none'
								}
								onClick={(e) => {
									e.preventDefault();
									dispatch(setUniqueIdCollection(id));
									setSelectPlantilla(id);
								}}
							/>
						)}
						<Link
							to={{
								pathname: redirectPath,
							}}
							className={`${
								(sectionTitle === 'Imágenes' || sectionTitle === 'Images') &&
								'hidden'
							}`}
						>
							{title && (
								<div
									onClick={(e) => {
										if (selectionSquare) {
											e.preventDefault();
											dispatch(setUniqueIdCollection(id));
											setSelectPlantilla(id);
										}
									}}
								>
									<TextContentThird
										design={`pt-2 pr-1 text-grey-50 text-left line-clamp-2 words-break ${designTitle}`}
										text={title}
									/>
								</div>
							)}
						</Link>
					</div>
				)}
				{showModalImage && (
					<ModalImage
						mainPicture={imagen}
						name={title}
						showModalImage={showModalImage}
						setShowModalImage={setShowModalImage}
					/>
				)}
			</div>
		</div>
	);
}

ImageAndTitle.propTypes = {
	/** Id of the image. This id is used for navigation */
	id: PropTypes.number,
	/** URL link to the image */
	imagen: PropTypes.string,
	/** Title on the card */
	title: PropTypes.string,
	/** String for the redirect*/
	redirect: PropTypes.string,
	/** Manages the format of the document */
	imageFormat: PropTypes.string,
	/** Checks if the element is public*/
	isPublic: PropTypes.bool,
	/* useref hook that catch the width and height of the imageAndTitle container */
	containerRef: PropTypes.func,
	/*  this bool displays a small square below the image which can be selected */
	selectionSquare: PropTypes.bool,
	/* bool used to display a small circle on top left of the image that indicates if the image will be downloaded */
	showDownloadOptions: PropTypes.bool,
	/*function that when the image is clicked add a check icon in the download circle */
	addCheckToImage: PropTypes.func,
	/*function that sends the image id that will be deleted */
	DeleteImageButton: PropTypes.func,
	/* string that defines the object fit css property */
	objectFitProp: PropTypes.string,
	/* string that defines the rounder border css property */
	roundedBorder: PropTypes.string,
	/**  bool that handle to save the actual specific location */
	saveLastSectionURL: PropTypes.bool,
	/* define if image src has or not format */
	isImageWithoutFormat: PropTypes.bool,
	/* Tailwind classes for title */
	designTitle: PropTypes.string,
	/* Tailwind classes for component container */
	designContainer: PropTypes.string,
	/**Alt for the image */
	imageAlt: PropTypes.string,
};

ImageAndTitle.defaultProps = {
	imageFormat: 'small',
	selectionSquare: false,
	objectFitProp: 'cover',
	roundedBorder: '0px',
	aspectRatioProp: '1/1',
	saveLastSectionURL: false,
};
