//3rd party components
import PropTypes from 'prop-types';

/**
 * @description used to document
 * @param {*} param0
 * @returns
 */

export default function TextTitlePrincipal({
	textColor,
	title,
	design,
	style,
	underline,
}) {
	return (
		<h1 className={`${textColor} h1-Principal ${design}`} style={style}>
			{title}
			{underline && <hr />}
		</h1>
	);
}

TextTitlePrincipal.propTypes = {
	/** Tailwind Colour of the text */
	textColor: PropTypes.string,
	/** Text in the component */
	title: PropTypes.string,
	/** Tailwind design */
	design: PropTypes.string,
	/** Style */
	style: PropTypes.string,
};
