/* -- Third Party -- */
import { Field, ErrorMessage } from 'formik';
import List from '@material-ui/core/List';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

//UI
import Send from '../../../UI/assets/icons/Send.svg';
import UnderlineHr from '../../../UI/atoms/UnderLine/UnderlineHr';
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import RadioItem from '../../../UI/molecules/userManagement/RadioItem';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import FormDiv from '../../../UI/organisms/FormDiv';
import { addNewUser } from '../../../BusinessLogic/redux/reducers/appUser';
import CheckboxItem from '../../../UI/molecules/userManagement/CheckboxItem';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

/**
 * @description render invitation view with a form for a new user in the organization
 * @param {*} param0
 * @returns
 */
export default function UserNewForm() {
	const navigate = useNavigate();
	const validationSchema = Yup.object({
		emails: Yup.string()
			.min(2, 'Must be at least 2 characters long')
			.required('An email name is required.'),
	});
	const { t } = useTranslation();
	const errorMessageDesign = 'text-red-400 body-Text3';
	const { app_user, organization_user_id } = localStorage;
	const { userRoles } = useSelector((state) => state.appUser);
	const dispatch = useDispatch();

	const CustomNameInputEmail = ({
		name,
		type,
		value,
		field,
		form,
		...props
	}) => (
		<div>
			<input
				className='email-back w-full text-xs	opacity-50'
				type={type}
				name={name}
				value={value}
				{...field}
				{...props}
			/>
		</div>
	);

	return (
		<TemplateWithNavBar>
			<Formik
				enableReinitialize
				initialValues={{
					emails: '',
					role_id: '2',
					pickedSendEmail: ['1'],
					app_user_id: app_user,
					organization_user_id: organization_user_id,
				}}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					const newValues = {
						...values,
						pickedSendEmail: values.pickedSendEmail.length,
					};
					dispatch(addNewUser(newValues)).then((data) => {
						if (data === 'Dont have access to create account') {
							alert('No tienes permisos');
						} else navigate('/management/configuracion');
					});
				}}
			>
				{() => (
					<Form>
						<div className='md:flex md:flex-col'>
							<SecondaryNavbarSpecific
								title={t('Configuración')}
								textColor='text-green-50'
								color='bg-green-50'
								hide='invisible'
								textBack={t('RegresarAConfiguraciónDeCuenta')}
								arrowLeftColor='green'
								textBackDesign='pl-3 text-green-50'
							/>
							<div className='mx-auto w-fit-content md:hidden'>
								<TextMainTitle
									title={t('Configuración')}
									textColor='text-green-50'
									color='bg-green-50'
								/>
							</div>

							<List>
								<div className='w-fit-content px-6'>
									<TextTitleSub
										design='font-light'
										title='Email(s)*'
										textColor='text-green-50'
									/>
									<UnderlineHr design='bg-green-50 hr-configuration' />
								</div>
								<FormDiv design='mx-6'>
									<TextContentSecond
										design='pt-4'
										text={t('ParaInvitarAMúltiplesPersonas')}
									/>
									<Field
										name='emails'
										type='Text'
										placeholder='abc@correo.com'
										as={CustomNameInputEmail}
									></Field>
									<div className={errorMessageDesign}>
										<ErrorMessage name='emails' />
									</div>
								</FormDiv>

								<div className='w-fit-content px-6 pt-6'>
									<TextTitleSub
										design=''
										title='Role(s)*'
										textColor='text-green-50'
									/>
									<UnderlineHr design='bg-green-50 hr-configuration' />
								</div>

								<FormDiv design='mx-6'>
									<div className='mb-4'>
										<TextContentSecond
											design='pt-4'
											text={t('SeleccionaUnoOMásRoles')}
										/>
									</div>

									<div
										className='RolRadioGroup'
										role='group'
										aria-labelledby='my-radio-group'
									>
										{userRoles.map(({ label, description, value }) => (
											<RadioItem
												design='body-Text2 text-grey-50'
												type='radio'
												name='role_id'
												value={value}
												label={t(label)}
												paragraph={t(description)}
											/>
										))}
									</div>
									<ErrorMessage name='role_id' />

									<div>
										<CheckboxItem
											design='body-Text2'
											type='checkbox'
											name='pickedSendEmail'
											value={'1'}
											label={t('EnvíarAEsteContactoUnaInvitación')}
										/>
									</div>
								</FormDiv>
								<div className='mb-24 flex w-full justify-center '>
									<ButtonFabExtendedPrimary
										icon={Send}
										className='text-white	'
										text={t('Enviar')}
										colorClass='bg-green-50'
									/>
								</div>
							</List>
						</div>
					</Form>
				)}
			</Formik>
		</TemplateWithNavBar>
	);
}
