import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as GreyGridView } from '../../../assets/icons/GreyGridView.svg';
import { ReactComponent as CrossGrey } from '../../../assets/icons/CrossGrey.svg';
import TextContentThird from '../../../atoms/Text/Content/TextContentThird';
import {
	setCurrentViewExpertPortfolioAndSelection,
	setShowSlideModalExpertPortfolio,
} from '../../../../BusinessLogic/redux/reducers/portfolio';

const ArtworksSectionView = () => {
	const dispatch = useDispatch();

	const { expertPortfolioViewAndSelection } = useSelector(
		(state) => state.portfolios
	);

	return (
		<div className='flex w-full justify-start gap-4 px-2 md:flex-col md:p-0 md:pt-4'>
			<div className='my-auto flex h-10 w-10 cursor-pointer rounded-full bg-white shadow transition-transform duration-300 hover:scale-105 md:hidden'>
				<CrossGrey
					className='mx-auto h-10 w-10 self-center p-2'
					onClick={() => {
						dispatch(
							setCurrentViewExpertPortfolioAndSelection({
								currentView: 'editionView',
								selectedElementId: null,
								selectedItemId: null,
								selectedSlideId: null,
							})
						);
					}}
				/>
			</div>
			<div className='my-2 h-12 w-[0.5px] bg-white shadow md:hidden'></div>
			<div
				className='flex cursor-pointer flex-col rounded-sm p-2 transition-colors duration-300 hover:bg-light_grey-75'
				onClick={() => {
					//Add a single artwork
					dispatch(
						setShowSlideModalExpertPortfolio({
							isVisible: true,
							modalView: 'addArtwork',
						})
					);
				}}
			>
				<CrossGrey className='mx-auto h-6 w-6 rotate-45' />
				<TextContentThird
					text={'Añadir obras'}
					design='text-grey-50 break-words w-min text-center text-xs text-[10px] md:mx-auto my-auto'
					style={{ lineHeight: '12px' }}
				/>
			</div>
			<div
				className='flex cursor-pointer flex-col rounded-sm p-2 transition-colors duration-300 hover:bg-light_grey-75'
				onClick={() => {
					if (expertPortfolioViewAndSelection.isReordering) {
						dispatch(
							setCurrentViewExpertPortfolioAndSelection({
								...expertPortfolioViewAndSelection,
								isReordering: false,
							})
						);
					} else {
						dispatch(
							setCurrentViewExpertPortfolioAndSelection({
								...expertPortfolioViewAndSelection,
								isReordering: true,
							})
						);
					}
				}}
			>
				<GreyGridView className='mx-auto h-6 w-6 ' />
				<TextContentThird
					text={'Ordenar'}
					design='text-grey-50 break-words w-min text-center text-xs text-[10px] my-auto md:mx-auto'
					style={{ lineHeight: '12px' }}
				/>
			</div>
		</div>
	);
};

export default ArtworksSectionView;
