import ReactLoading from 'react-loading';

/**
 *
 * @description Simpler loader used in the porfolios
 */

const PortfolioLoading = () => {
	return (
		<div
			className='m-auto flex w-20 items-center justify-center'
			style={{ minHeight: 'calc(100vh - 240px)' }}
		>
			<div>
				<div
					className='m-auto'
					style={{ width: '60px' }}
					data-testid='loading-spinner'
				>
					<ReactLoading
						type={'spokes'}
						color={'#9cbdb9'}
						height={'60px'}
						width={'60px'}
					/>
				</div>
				<p className='mt-6 text-center text-xl'>Cargando...</p>
			</div>
		</div>
	);
};

export default PortfolioLoading;
