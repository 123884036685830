//3rd
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

//BL
import { expertPortfolioDefaultSlides } from '../../../../BusinessLogic/data/PortfolioSingle';
import { postExpertPortfolioSlide } from '../../../../BusinessLogic/redux/reducers/portfolio';

//UI
import ButtonFab from '../../../../UI/atoms/Buttons/FAB/ButtonFab';
import ExpertPortfolioEditionLayout from '../../../../UI/layouts/portfolio/ExpertPortfolioEditionLayout';
import PortfolioSlidePreview from '../../../../UI/molecules/Portfolio/PortfolioSlidePreview';

/**
 * @description Renders the add new slide view of an expert portfolio
 */
const ExpertPortfolioAddSlides = () => {
	const { portfolioId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [selectedSlide, setSelectedSlide] = useState(null);

	const handleSelectSlide = (slide) => {
		slide !== selectedSlide ? setSelectedSlide(slide) : setSelectedSlide(null);
	};

	const handleCreateSlide = () => {
		dispatch(
			postExpertPortfolioSlide({
				portfolioId,
				portfolioType: 'EXPERT',
				slideType: selectedSlide.type,
			})
		).then(() => {
			navigate(`/portafolios/portafolio-experto/${portfolioId}/secciones`);
		});
	};

	return (
		<ExpertPortfolioEditionLayout>
			<div
				className={`mx-auto grid w-5/6 max-w-6xl grid-cols-1 gap-5 pt-5 sm:grid-cols-2 lg:grid-cols-3 ${
					selectedSlide ? 'mb-12' : 'mb-48'
				}`}
			>
				{expertPortfolioDefaultSlides.map((slide, index) => (
					<div key={index} onClick={() => handleSelectSlide(slide)}>
						<PortfolioSlidePreview
							slide={slide}
							slideDesign={
								slide === selectedSlide &&
								'outline outline-offset-2 outline-green-50'
							}
							slideLabel={`${t(slide.type)}`}
						/>
					</div>
				))}
			</div>
			{selectedSlide && (
				<div className='mt-8 mb-48 flex w-full flex-wrap items-center justify-center gap-8'>
					<ButtonFab
						label={t('Cancelar')}
						size={'xlarge'}
						colour={'white'}
						action={() =>
							navigate(
								`/portafolios/portafolio-experto/${portfolioId}/secciones`
							)
						}
					/>
					<ButtonFab
						label={t('Guardar')}
						size={'xlarge'}
						action={() => handleCreateSlide()}
					/>
				</div>
			)}
		</ExpertPortfolioEditionLayout>
	);
};

ExpertPortfolioAddSlides.protoTypes = {};

export default ExpertPortfolioAddSlides;
