// 3rd parties
import React from 'react';
import PropTypes from 'prop-types';

// UI
import TextSubTitle from '../../molecules/Text/TextSubTitle';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

/**
 * @description renders a section with its title and text content
 * @param {*} param0
 * @returns
 */

const SectionTitleAndText = ({
	sectionTitle,
	text,
	colorHr,
	design,
	setTurnEdit,
	turnEdit,
	hideEdit,
	hasInviteUser,
}) => {
	return (
		<div className={`flex w-full flex-col pb-4 ${design}`}>
			<TextSubTitle
				text={sectionTitle}
				colour={colorHr}
				hasAdd={false}
				setTurnEdit={setTurnEdit}
				hasEdit={turnEdit === true ? false : true}
				hideEdit={hideEdit}
				hasInviteUser={hasInviteUser}
			/>

			<div className='flex flex-col'>
				<TextContentSecond
					text={text}
					design={'text-grey-50 break-words whitespace-pre-line'}
				/>
			</div>
		</div>
	);
};

export default SectionTitleAndText;

SectionTitleAndText.propTypes = {
	/* title of the section with information */
	sectionTitle: PropTypes.string,
	/* string with section's text */
	text: PropTypes.string,
	/* string with the color of the hr */
	colorHr: PropTypes.string,
	/** Tailwind classes for design */
	design: PropTypes.string,
	/** Trigger a function when the edit is clicked */
	setTurnEdit: PropTypes.func,
	/** Trigger edit mode in the section */
	turnEdit: PropTypes.bool,
	/* shows or not the Edit feature */
	hideEdit: PropTypes.bool,
	/* handle the feature to invite a user in the field section */
	hasInviteUser: PropTypes.bool,
};

SectionTitleAndText.defaultProps = {
	hideEdit: false,
	hasInviteUser: false,
};
