import React from 'react';
import PropTypes from 'prop-types';

/**
 * It returns a div with a className that is either `bg-green-50 text-white` or `bg-white text-grey-50`
 * depending on the value of the `selection` prop
 * @returns A div with a className that is either bg-green-50 text-white or bg-white text-grey-50.
 */
const SelectionContainer = ({ design, selection, children, action }) => {
	return (
		<div
			className={
				selection
					? 'bg-green-50 text-white ' + design
					: 'bg-white text-grey-50 ' + design
			}
			onClick={action}
		>
			{children}
		</div>
	);
};

SelectionContainer.propTypes = {
	/** Tailwind classes for component design.*/
	design: PropTypes.string,
	/** Boolean value to determine if the component is selected or not */
	selection: PropTypes.bool,
	/** Children component to render */
	children: PropTypes.element,
	/** Trigger a function to execute on onClick */
	action: PropTypes.func,
};

export default SelectionContainer;
