import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import TextSubTitle from '../../molecules/Text/TextSubTitle';
import TextSingleRowNew from '../../molecules/Text/TextSingleRowNew';
import ButtonLinkText from '../../atoms/Buttons/Link/ButtonLinkText';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import { ENDPOINTS_STRIPE } from '../../../BusinessLogic/helpers/routes';
import { fetchDeleteDataWithUser } from '../../../BusinessLogic/hooks/fetchDeleteWithUser';
import WarningModal from '../../../Pages/sections/postulation/WarningModal';
import { ModalPostulation } from '../PopUp/ModalPostulation';
import { getAppUser } from '../../../BusinessLogic/redux/reducers/appUser';

const ViewPlan = ({ sectionTitle, colorHr }) => {
	const dispatch = useDispatch();
	const { appUser } = useSelector((state) => state.appUser);
	const { t } = useTranslation();
	const [showWarningModal, setShowWarningModal] = useState(false);
	const [showPlanCanceledModal, setShowPlanCanceledModal] = useState(false);

	const organizationUserId = appUser.organization_user_id;
	const activePlan = appUser.subscription_type;
	const isActivePlanFree = activePlan === 'PlanGratis';

	const onCancelPlan = async () => {
		if (isActivePlanFree) return;

		await fetchDeleteDataWithUser(
			ENDPOINTS_STRIPE.DELETE_SUBSCRIPTION(organizationUserId)
		);
		dispatch(getAppUser());

		setShowWarningModal(false);
		setShowPlanCanceledModal(true);
	};

	const onClickCancelPlan = () => {
		if (isActivePlanFree) return;

		setShowWarningModal(true);
	};

	return (
		<div>
			{showWarningModal && (
				<WarningModal
					title={t('CancelarPlanAnual')}
					textPrimary={t('SeguroCancelarPlan')}
					textSecondary={t('SiCancelasPlan')}
					closeModalFunction={setShowWarningModal}
					mainButtonTitle={t('Aceptar')}
					secondaryButtonTitle={t('Cancelar')}
					mainButtonAction={onCancelPlan}
				/>
			)}

			{showPlanCanceledModal && (
				<ModalPostulation
					actionClose={() => setShowPlanCanceledModal(false)}
					modalTitle={t('PlanCancelado')}
					modalText={t('PlanCanceladoText')}
					buttonLabel={t('Continuar')}
				/>
			)}
			<TextSubTitle
				hideEdit
				hasAdd={false}
				text={sectionTitle}
				colour={colorHr}
			/>
			<TextSingleRowNew
				label={t('Usuario')}
				value={appUser?.first_name}
				labelWeight={{ fontWeight: 400 }}
			/>
			<TextSingleRowNew
				label={t('PlanActivo')}
				value={t(appUser.subscription_type)}
				labelWeight={{ fontWeight: 400 }}
			/>
			<div className='mb-4 flex items-center justify-end'>
				<div className='w-1/3 sm:w-1/2'></div>
				<ButtonLinkText
					route={'/management/configuracion/planes'}
					design='w-1/2 md:w-[60%] text-[#34B3E5]'
					text={t('CambiaPlan')}
				/>
			</div>
			{!isActivePlanFree && (
				<>
					<TextSingleRowNew
						label={t('FechaDeInicio')}
						value={appUser?.subscription_created_at}
						labelWeight={{ fontWeight: 400 }}
					/>
					<TextSingleRowNew
						label={t('FechaDeVencimiento')}
						value={appUser?.subscription_expiration_date}
						labelWeight={{ fontWeight: 400 }}
					/>
				</>
			)}
			{!isActivePlanFree && (
				<div className='mt-10 flex justify-end'>
					<ButtonFab
						label={t('CancelarPlan')}
						colour='red'
						action={onClickCancelPlan}
						type='button'
						size={'medium'}
					/>
				</div>
			)}
		</div>
	);
};

ViewPlan.propTypes = {
	/* title of the section with information */
	sectionTitle: PropTypes.string,
	/* string with the color of the hr */
	colorHr: PropTypes.string,
};

export default ViewPlan;
