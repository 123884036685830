import ReactPlayer from 'react-player';
//BL
import {
	NavigationData,
	headerTitle,
	headerText,
	lasierra,
	picDescriptionOne,
	ContentTextOne,
	mariaPic,
	outer,
	headerTextTwo,
	sierranevada,
	picDescriptionTwo,
	sierraheart,
	ContentTextTwo,
	santaMone,
	santaMtwo,
	picDescriptionThree,
	ContentTextThree,
	ladyandkid,
	ContentTextFour,
	portraitsierra,
	picDescriptionFour,
	inner,
	ContentTextFive,
	videopagamento,
	landscapes,
	ContentTextSix,
	ImageMakingWaterFromCrystals,
	picDescription5,
	ImageMyBloodIsGold,
	picDescription6,
	ImageTheHopOfWhatWas,
	picDescription7,
	ContentText7,
	ImageKnittingRocks,
	picDescription8,
	ImageTheWatersThoughts,
	picDescription9,
	ImageP4n1,
	ContentP4n1,
	VideoP4n2,
	ImageP4n3,
	DecriptionP4n3,
	ContentP4n4,
	ImageP4n4,
	DecriptionP4n4,
	ContentP4n5,
	ImageP4n5,
	DecriptionP4n5,
	VideoP4n6,
	ContentP4n7,
	ImageP4n8,
	DecriptionP4n8,
	ContentP4n8b,
	ContentP4n9,
	ImageP4n9,
	DecriptionP4n9,
	ImageP4n10,
	DecriptionP4n10,
	ImageP4n11,
	DecriptionP4n11,
	ImageP4n12,
	DecriptionP4n12,
	ImageP4n13,
	DecriptionP4n13,
	ContentP4n14,
	VideoP4n15,
	ImageP4n16,
	DecriptionP4n16,
	ContentP4n17,
	ImageP4n18,
	DecriptionP4n18,
	ImageP4n19,
	DecriptionP4n19,
	ImageP4n20,
	DecriptionP4n20,
	ImageP4n21,
	DecriptionP4n21,
	ContentP4n22,
	VideoP4n23,
	ContentP4n24,
	ImageP4n25,
	DecriptionP4n25,
	ImageP4n26,
	DecriptionP4n26,
	ImageP4n27,
	DecriptionP4n27,
	ImageP4n28,
	ContentP4n29,
	ImageP4n30,
	DecriptionP4n30,
	ImageP5n1,
	ContentP5n1,
	VideoP5n2,
	ImageP5n3,
	DescriptionP5n3,
	ImageP5n4,
	DescriptionP5n4,
	ImageP5n5,
	DescriptionP5n5,
	ContentP5n6,
	ImageP6n1,
	ContentP6n1,
	ImageP6n2,
	DescriptionP6n2,
	VideoP6n3,
	ImageP6n4,
	DescriptionP6n4,
	ImageP6n5,
	DescriptionP6n5,
	ContentP6n6,
	ImageP6n7,
	DescriptionP6n7,
	ImageP6n8,
	DescriptionP6n8,
	ContentP6n9,
	ImageP6n10,
	DescriptionP6n10,
	ContentP6n11,
	ImageP6n12,
	DescriptionP6n12,
	ImageP6n13,
	DescriptionP6n13,
	ImageP6n14,
	DescriptionP6n14,
	ImageP6n15,
	DescriptionP6n15,
	VideoP5n16,
	ImageP6n18,
	DescriptionP6n18,
	ImageP6n19,
	DescriptionP6n19,
	ImageP6n20,
	DescriptionP6n20,
	contentP6n21,
	ImageP6n21,
	DescriptionP6n21,
	ImageP7n1,
	ImageP7n2,
	ContentP7n2,
	ImageP7n3,
	ImageP7n4,
	ContentP7n4,
	ImageP7n5a,
	ImageP7n5b,
	ContentP7n5,
	ImageP7n6a,
	ImageP7n6b,
	ContentP7n8,
	ImageP7n9,
	DescriptionP7n9,
	ImageP7n10,
	DescriptionP7n10,
	ImageP7n11,
	DescriptionP7n11,
	ContentP7n12,
	ImageP7n13,
	DescriptionP7n13,
	ImageP7n14,
	DescriptionP7n14,
	ContentP7n15,
	ImageP7n15,
	DescriptionP7n15,
} from '../../../BusinessLogic/data/DataPortfolioAnaMariaVelasco';
//UI
import fbicon from '../../../UI/assets/images/icon_insta.png';
import redicon from '../../../UI/assets/images/icon_red.png';

const PortfolioAnaMariaVelasco = () => {
	return (
		<div className='check anamaria-velasco'>
			{/* First Section */}
			<FirstLayout
				url={'anamaria'}
				text={headerText}
				title={headerTitle}
				navbar={true}
			>
				<ContentWithTitlePic picture={lasierra} picDesc={picDescriptionOne} />

				<LineContentImage title={ContentTextOne} picture={mariaPic} />
			</FirstLayout>

			{/* Second Section */}
			<FirstLayout url={'outer'} text={headerTextTwo} picture={outer}>
				<ContentWithTitlePic
					picture={sierranevada}
					picDesc={picDescriptionTwo}
				/>
				<TextContent
					text={ContentTextTwo}
					textContentWeight='texto text-left w-3/5 md:w-4/5'
					textContainerClass='mb-20 flex flex-col justify-center items-center'
				/>
				<TwoPics image1={santaMone} image2={santaMtwo} />
				<ContentWithTitlePic
					picture={sierraheart}
					picDesc={picDescriptionThree}
				/>
				<TextContent
					text={ContentTextThree}
					textContentWeight='texto text-justify md:text-left w-3/5 md:w-3/4 '
					textContainerClass='mb-20 flex flex-col justify-center items-center'
				/>
				<LineContentImage
					title={ContentTextFour}
					picture={ladyandkid}
					imageLocation='left'
				/>
				<ContentWithTitlePic
					picture={portraitsierra}
					picDesc={picDescriptionFour}
				/>
			</FirstLayout>

			{/* Third Section */}
			<FirstLayout url={'inner'} picture={inner}>
				<VideoWithText text={ContentTextFive} url={videopagamento} />
				<LineContentImage
					title={ContentTextSix}
					picture={landscapes}
					imageLocation='left'
				/>
				<LineImageDescription
					picture={ImageMakingWaterFromCrystals}
					imageLocation='right'
					picDesc={picDescription5}
				/>
				<LineImageDescription
					picture={ImageMyBloodIsGold}
					imageLocation='left'
					picDesc={picDescription6}
				/>
				<LineImageDescription
					picture={ImageTheHopOfWhatWas}
					imageLocation='right'
					picDesc={picDescription7}
				/>
				<TextContent
					text={ContentText7}
					textContentWeight='texto text-center md:text-left w-3/5 md:w-3/4'
					textContainerClass='mb-32 flex flex-col justify-center items-center'
				/>
				<LineImageDescription
					picture={ImageKnittingRocks}
					imageLocation='left'
					picDesc={picDescription8}
				/>
				<LineImageDescription
					picture={ImageTheWatersThoughts}
					imageLocation='right'
					picDesc={picDescription9}
				/>
			</FirstLayout>

			{/* Fourth Section */}
			<FirstLayout url={'secret'} text={ContentP4n1} picture={ImageP4n1}>
				<VideoBanner
					url={VideoP4n2}
					VideoContainer='flex flex-col justify-center items-center w-full h-80 md:h-full max-w-full  mb-20'
				/>

				<ContentWithTitlePic picture={ImageP4n3} picDesc={DecriptionP4n3} />
				<LineContentImage
					title={ContentP4n4}
					picture={ImageP4n4}
					picDesc={DecriptionP4n4}
					imageLocation='right'
				/>
				<LineContentImage
					title={ContentP4n5}
					picture={ImageP4n5}
					picDesc={DecriptionP4n5}
					imageLocation='left'
				/>

				<VideoBanner
					url={VideoP4n6}
					VideoContainer='flex flex-col justify-center items-center w-full h-80 md:h-full max-w-full mb-20'
				/>

				<TextContent
					text={ContentP4n7}
					textContentWeight='texto text-center md:text-left w-1/2 md:w-3/4'
					textContainerClass='mb-32 md:mb-20 flex flex-col justify-center items-center'
				/>
				<ContentWithTitlePic picture={ImageP4n8} picDesc={DecriptionP4n8} />
				<TextContent
					text={ContentP4n8b}
					textContentWeight='texto text-center md:text-left w-1/2 md:w-3/4'
					textContainerClass='mb-32 md:mb-20 flex flex-col justify-center items-center'
				/>
				<LineContentImage
					title={ContentP4n9}
					picture={ImageP4n9}
					picDesc={DecriptionP4n9}
					imageLocation='right'
				/>

				<TwoPics
					image1={ImageP4n10}
					image2={ImageP4n11}
					picDesc1={DecriptionP4n10}
					picDesc2={DecriptionP4n11}
				/>
				<TwoPics
					image1={ImageP4n12}
					image2={ImageP4n13}
					picDesc1={DecriptionP4n12}
					picDesc2={DecriptionP4n13}
				/>
				<TextContent
					text={ContentP4n14}
					textContentWeight='texto md:text-left text-center'
					textContainerClass='mb-32 w-4/5 m-auto md:mb-20'
				/>

				<VideoBanner
					url={VideoP4n15}
					VideoContainer='flex flex-col justify-center items-center w-full h-80 max-w-full mb-20'
				/>

				<ContentWithTitlePic picture={ImageP4n16} picDesc={DecriptionP4n16} />
				<TextContent
					text={ContentP4n17}
					textContentWeight='texto text-left'
					textContainerClass='mb-32 w-4/5 m-auto md:mb-20'
				/>
				<TwoPics
					image1={ImageP4n18}
					image2={ImageP4n19}
					picDesc1={DecriptionP4n18}
					picDesc2={DecriptionP4n19}
				/>
				<TwoPics
					image1={ImageP4n20}
					image2={ImageP4n21}
					picDesc1={DecriptionP4n20}
					picDesc2={DecriptionP4n21}
				/>
				<TextContent
					text={ContentP4n22}
					textContentWeight='texto text-left'
					textContainerClass='mb-32 w-4/5 m-auto md:mb-20'
				/>

				<VideoBanner
					url={VideoP4n23}
					VideoContainer='flex flex-col justify-center items-center w-full h-80 max-w-full mb-20'
				/>

				<TextContent
					text={ContentP4n24}
					textContentWeight='texto text-left'
					textContainerClass='mb-32 w-4/5 m-auto md:mb-20'
				/>
				<TwoPics
					image1={ImageP4n25}
					image2={ImageP4n26}
					picDesc1={DecriptionP4n25}
					picDesc2={DecriptionP4n26}
				/>
				<LargeImageWithDesc
					picture={ImageP4n27}
					picDesc={DecriptionP4n27}
					imageLocation='right'
				/>
				<LargeImageWithDesc
					picture={ImageP4n28}
					picDesc={DecriptionP4n27}
					imageLocation='left'
				/>
				<TextContent
					text={ContentP4n29}
					textContentWeight='texto text-left'
					textContainerClass='mb-12 w-4/5 m-auto'
				/>
				<ContentWithTitlePic picture={ImageP4n30} picDesc={DecriptionP4n30} />
			</FirstLayout>

			{/* Fifth Section */}
			<FirstLayout url={'Offering'} text={ContentP5n1} picture={ImageP5n1}>
				<VideoBanner
					url={VideoP5n2}
					VideoContainer='flex flex-col justify-center items-center w-full h-80 md:h-full max-w-full mb-20'
				/>

				<LineImageDescription
					picture={ImageP5n3}
					imageLocation='left'
					picDesc={DescriptionP5n3}
				/>
				<TwoPics
					image1={ImageP5n4}
					image2={ImageP5n5}
					picDesc1={DescriptionP5n4}
					picDesc2={DescriptionP5n5}
					alignment='right'
				/>
				<TextContent
					text={ContentP5n6}
					textContentWeight='texto text-left w-4/5'
					textContainerClass='mb-32 flex flex-col justify-center items-center'
				/>
			</FirstLayout>

			{/* Six Section */}
			<FirstLayout url={'EarlyWorks'} text={ContentP6n1} picture={ImageP6n1}>
				<ContentWithTitlePic picture={ImageP6n2} picDesc={DescriptionP6n2} />

				<VideoBanner
					url={VideoP6n3}
					VideoContainer='flex flex-col justify-center items-center h-600 mb-20'
				/>

				<TextContent
					text={ContentP6n6}
					textContentWeight='sub_titulo text-center md:text-left w-3/5 m-auto md:w-3/4'
					textContainerClass='mb-28'
				/>
				<TwoPics
					image1={ImageP6n4}
					image2={ImageP6n5}
					picDesc1={DescriptionP6n4}
					picDesc2={DescriptionP6n5}
				/>
				<TwoPics
					image1={ImageP6n7}
					image2={ImageP6n8}
					picDesc1={DescriptionP6n7}
					picDesc2={DescriptionP6n8}
				/>
				<TextContent
					text={ContentP6n9}
					textContentWeight='texto text-left w-3/4'
					textContainerClass='mb-32 flex flex-col justify-center items-center pr-60 sm:pr-0 md:mb-20'
				/>

				<ContentWithTitlePic picture={ImageP6n10} picDesc={DescriptionP6n10} />
				<TextContent
					text={ContentP6n11}
					textContentWeight='texto text-left w-1/2 md:w-3/4'
					textContainerClass='mb-32 flex flex-col justify-center items-center md:mb-20'
				/>
				<TwoPics
					image1={ImageP6n12}
					image2={ImageP6n13}
					picDesc1={DescriptionP6n12}
					picDesc2={DescriptionP6n13}
				/>
				<TwoPics
					image1={ImageP6n14}
					image2={ImageP6n15}
					picDesc1={DescriptionP6n14}
					picDesc2={DescriptionP6n15}
				/>

				<VideoBanner
					url={VideoP5n16}
					VideoContainer='flex flex-col justify-center items-center h-600 mb-60 md:mb-40'
				/>

				<TwoPics
					image1={ImageP6n19}
					image2={ImageP6n18}
					picDesc1={DescriptionP6n19}
					picDesc2={DescriptionP6n18}
				/>
				<LineImageDescription
					picture={ImageP6n20}
					imageLocation='left'
					picDesc={DescriptionP6n20}
				/>

				<LineContentImage
					title={contentP6n21}
					picture={ImageP6n21}
					picDesc={DescriptionP6n21}
					imageLocation='right'
				/>
			</FirstLayout>

			{/* 7th Section */}
			<FirstLayout url={'LineDrawings'} picture={ImageP7n1}>
				<LineContentImage
					title={ContentP7n2}
					picture={ImageP7n2}
					imageLocation='left'
				/>
				<TwoPics image1={ImageP7n3} image2={ImageP7n4} alignment='left' />
				<TextContent
					text={ContentP7n4}
					textContentWeight='texto text-left w-3/5 md:w-3/4'
					textContainerClass='mb-32 flex flex-col justify-center items-center md:mb-20'
				/>
				<TwoPics image1={ImageP7n5a} image2={ImageP7n5b} alignment='right' />
				<TextContent
					text={ContentP7n5}
					textContentWeight='texto text-left w-3/5 md:w-3/4'
					textContainerClass='mb-32 flex flex-col justify-center items-center md:mb-20'
				/>
				<TwoPics image1={ImageP7n6a} image2={ImageP7n6b} alignment='' />
				<TextContent
					text={ContentP7n8}
					textContentWeight='texto text-center md:text-left w-3/5 md:w-3/4'
					textContainerClass='mb-32 flex flex-col justify-center items-center md:mb-20'
				/>
				<TwoPics
					image1={ImageP7n9}
					image2={ImageP7n10}
					picDesc1={DescriptionP7n9}
					picDesc2={DescriptionP7n10}
				/>
				<ContentWithTitlePic picture={ImageP7n11} picDesc={DescriptionP7n11} />
				<TextContent
					text={ContentP7n12}
					textContentWeight='texto text-center md:text-left w-3/5 md:w-3/4'
					textContainerClass='mb-32 flex flex-col justify-center items-center md:mb-20'
				/>
				<TwoPics
					image1={ImageP7n13}
					image2={ImageP7n14}
					picDesc1={DescriptionP7n13}
					picDesc2={DescriptionP7n14}
				/>
				<LineContentImage
					title={ContentP7n15}
					picture={ImageP7n15}
					picDesc={DescriptionP7n15}
				/>
			</FirstLayout>
		</div>
	);
};

export default PortfolioAnaMariaVelasco;

function FirstLayout({ children, text, title, picture, navbar = false, url }) {
	return (
		<div>
			<HeaderMasterSimple
				text={text}
				title={title}
				picture={picture}
				url={url}
			/>
			<main>{children}</main>
			{navbar && <Navbar />}
		</div>
	);
}

function HeaderMasterSimple({ text, title, picture, url }) {
	return (
		<header className='' id={url}>
			{picture && (
				<img alt='header pic' className='mt-40 w-full rounded' src={picture} />
			)}
			<div className='center m-auto mb-16 w-4/5 '>
				{title && <h1 className='titulo pt-12 pb-4 text-center'>{title}</h1>}
				{text && (
					<div className='my-8'>
						<p className='texto'>{text}</p>
					</div>
				)}
			</div>
		</header>
	);
}

function Navbar() {
	return (
		<footer className='animated fixed bottom-0 z-20 w-full'>
			<nav className='back_color flex w-screen flex-wrap justify-between py-4'>
				<div className='mx-auto flex w-full flex-wrap items-center justify-between px-4'>
					<div className='text_color pt-1 text-xs text-opacity-50'>
						Created in <a href='https://www.datarte.art/'>Datarte.art</a>
					</div>
					<div className='flex space-x-4 lg:hidden'>
						{NavigationData.map((item, index) => (
							<a key={index} href={item['url']}>
								<span className='text_color rounded-md px-4 py-2 text-sm font-medium hover:text-green-400'>
									{item['label']}
								</span>
							</a>
						))}
					</div>
					<div className='flex flex-row'>
						<a href='https://www.instagram.com/velascoartstudio/'>
							<img alt='instagram icon' className='h-5 px-1' src={fbicon} />
						</a>
						<a href='http://www.anamariavelasco.com/'>
							<img alt='red icon' className='h-5 px-1' src={redicon} />
						</a>
					</div>
				</div>
			</nav>
		</footer>
	);
}
function LineContentImage({
	title,
	picture,
	picDesc,
	imageLocation = 'right',
}) {
	return (
		<div
			className={`${
				imageLocation === 'left' ? 'flex-row-reverse' : ''
			} 2-xl:flex-row m-auto flex w-4/5 justify-between md:w-4/5 md:flex-col-reverse md:items-center md:justify-center md:pr-0 `}
		>
			<div className='mb-16 flex w-7/12 flex-col justify-between md:mb-20 md:w-full '>
				<h1
					className={`${
						imageLocation === 'left' ? 'pl-8 md:pl-0' : 'pr-8 md:pr-4'
					}  texto m-auto text-left md:pr-0`}
				>
					{title}
				</h1>
			</div>
			<div className='mb-20 w-5/12 md:mb-16 md:w-full md:px-1'>
				<div className='flex flex-row justify-end md:flex-col'>
					<div className='flex flex-col'>
						<img
							alt='pic anamaria'
							className='mb-4 max-h-96 rounded sm:max-h-full md:max-w-full'
							src={picture}
						/>
						<div className='flex w-60 flex-col md:w-full'>
							{picDesc &&
								picDesc.map((text, index) => (
									<p key={index} className='text-left text-xs'>
										{text}
									</p>
								))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
function ContentWithTitlePic({ title, picture, picDesc }) {
	return (
		<div className='m-auto flex w-4/5 flex-col items-center justify-center'>
			<div className='mb-20'>
				<h1 className='sub_titulo mb-4 text-left'>{title}</h1>
				<div className='flex flex-col items-center justify-center '>
					<img className='mb-4 rounded' src={picture} alt='anamaria' />
				</div>
				<div className='flex w-64 flex-col'>
					{picDesc &&
						picDesc.map((text, index) => (
							<p key={index} className='text-left text-xs'>
								{text}
							</p>
						))}
				</div>
			</div>
		</div>
	);
}
function TextContent({ text, textContentWeight, textContainerClass }) {
	return (
		<div className={`${textContainerClass}`}>
			<p className={`${textContentWeight}`}>{text}</p>
		</div>
	);
}
function TwoPics({ image1, image2, picDesc1, picDesc2, alignment = 'none' }) {
	return (
		<div
			className={`m-auto mb-16 flex w-4/5 flex-row md:mx-auto md:w-full md:flex-col md:items-center ${
				alignment === 'left' && `ml-40 w-7/12 lg:w-4/5`
			} ${alignment === 'right' && `mr-40 w-7/12 lg:w-4/5`}`}
		>
			<div className='w-3/6 pr-8 md:mb-8 md:w-4/5 md:pr-0'>
				<div className='flex flex-row justify-start md:justify-center'>
					<div>
						<img
							alt='anamaria'
							className='mb-4 max-h-96 rounded md:mx-auto md:max-h-64 md:object-cover'
							src={image1}
						/>
						<div className='flex w-60 flex-col md:mb-12 md:w-full'>
							{picDesc1 &&
								picDesc1.map((text, index) => (
									<p key={index} className='text-left text-xs'>
										{text}
									</p>
								))}
						</div>
					</div>
				</div>
			</div>
			<div className='w-3/6 pl-8 md:w-4/5 md:pl-0'>
				<div className='flex flex-row justify-end md:justify-center'>
					<div>
						<img
							alt='anamaria'
							className='mb-4 max-h-96 rounded md:mx-auto md:max-h-64 md:object-cover'
							src={image2}
						/>
						<div className='flex w-80 flex-col md:mt-4 md:w-full'>
							{picDesc2 &&
								picDesc2.map((text, index) => (
									<p key={index} className='text-left text-xs'>
										{text}
									</p>
								))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
function VideoWithText({ text, url, open }) {
	return (
		<div className='mb-20 flex flex-row items-center justify-center md:mb-4 md:flex-col-reverse'>
			<div className='texto mx-20 ml-48 mb-20 w-3/5 pb-36 text-left md:mx-auto md:w-4/5 md:pb-0'>
				<p>{text}</p>
			</div>
			<VideoBanner
				open={open}
				url={url}
				VideoContainer='w-full max-h-full mb-20 md:mt-16'
			/>
		</div>
	);
}
function VideoBanner({ url, VideoContainer, text, open }) {
	let mediaQuery = window.matchMedia('(max-width : 1024px)');

	return (
		<div
			className={`${VideoContainer} m-auto flex flex-col items-center justify-center`}
		>
			<ReactPlayer
				className='react-player fixed-bottom items-center	'
				url={url}
				width={mediaQuery?.matches ? '80%' : '50%'}
				height='100%'
				controls={open ? false : true}
			/>
			<div className='flex w-1/2 flex-row-reverse'>
				<p>{text}</p>
			</div>
		</div>
	);
}
function LineImageDescription({
	title,
	picture,
	picDesc,
	imageLocation = 'right',
}) {
	return (
		<div
			className={`${
				imageLocation === 'left' ? 'flex-row-reverse' : ''
			} m-auto flex w-4/5 flex-row justify-end md:flex-col-reverse md:items-center md:justify-center`}
		>
			<div
				className={`${
					imageLocation === 'left' ? 'pl-4 md:mr-24' : 'pr-4 md:mr-12'
				} mb-20 flex w-60 flex-col justify-end pb-4 md:w-4/5`}
			>
				<h1 className='title w-60 text-left md:w-4/5'>{title}</h1>
				<div className='flex w-60 flex-col md:w-full'>
					{picDesc &&
						picDesc.map((text, index) => (
							<p key={index} className='text-left text-xs'>
								{text}
							</p>
						))}
				</div>
			</div>
			<div className='mb-20 w-8/12 md:mb-0 md:w-full '>
				<div className=''>
					<img alt='anamaria' className='mb-4 rounded' src={picture} />
				</div>
			</div>
		</div>
	);
}
function LargeImageWithDesc({ picture, picDesc, imageLocation = 'right' }) {
	return (
		<div
			className={`${
				imageLocation === 'left' ? 'flex-row-reverse' : ''
			} m-auto mb-12 flex w-4/5 flex-row justify-between md:flex-col md:items-center`}
		>
			<div className='w-10/12'>
				<img alt='anamaria' className='mb-4 rounded ' src={picture} />
			</div>
			<div className='ml-4 flex w-2/12 flex-col justify-end pb-4 md:ml-6 md:w-11/12'>
				{picDesc &&
					picDesc.map((text, index) => (
						<p key={index} className='text-left text-xs'>
							{text}
						</p>
					))}
			</div>
		</div>
	);
}
