import React, { useState } from 'react';
import {
	format,
	sub,
	add,
	startOfMonth,
	endOfMonth,
	differenceInDays,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import es from 'date-fns/locale/es';
import PropTypes from 'prop-types';

// BL
import {
	daysSpanish,
	daysEnglish,
	eventsData,
} from '../../../BusinessLogic/data/Calendar';

// UI
import CalendarCell from '../../molecules/Calendar/CalendarCell';
import { ReactComponent as ArrowRightGrey } from '../../assets/icons/ArrowRightGrey.svg';
import { ReactComponent as ArrowLeftGrey } from '../../assets/icons/ArrowLeftGrey.svg';

const Calendar = ({ calendarEvents }) => {
	const { t } = useTranslation();
	const [currentDate, setCurrentDate] = useState(new Date());

	const lng = localStorage.getItem('lng');

	const monthAndYearSpanish = format(currentDate, 'MMMM yyyy', { locale: es });
	const monthAndYearEnglish = format(currentDate, 'MMMM yyyy');
	const firstDateOfTheMonth = startOfMonth(currentDate);
	const lastDateOfTheMonth = endOfMonth(currentDate);
	const days = lng === 'es' ? daysSpanish : daysEnglish;

	const daysOfTheMonth =
		differenceInDays(lastDateOfTheMonth, firstDateOfTheMonth) + 1;

	const datesOfTheMonthArray = Array.from(
		{ length: daysOfTheMonth },
		(item, index) => index + 1
	);

	const initialDay = firstDateOfTheMonth.getDay();
	const finalDay = 6 - lastDateOfTheMonth.getDay();
	const initialEmptyDatesArray = Array.from({ length: initialDay }, () => '');
	const finalEmptyDatesArray = Array.from({ length: finalDay }, () => '');

	const handleFullDate = (date) => {
		const fullDate =
			date < 10
				? `${format(currentDate, 'MM/yyyy')}/0${date}`
				: `${format(currentDate, 'MM/yyyy')}/${date}`;

		return fullDate;
	};

	const handlePreviousMonth = () => {
		setCurrentDate(sub(currentDate, { months: 1 }));
	};

	const handleNextMonth = () => {
		setCurrentDate(add(currentDate, { months: 1 }));
	};

	return (
		<div className=' flex w-full flex-col text-[20px] font-bold'>
			<div className='grid h-full min-h-[60vh] grid-cols-7 items-center  justify-center rounded-xl bg-light_grey-75 p-4 py-8 text-center '>
				<CalendarCell
					cellDesign='cursor-pointer col-span-1 lg:col-span-2'
					onClick={() => handlePreviousMonth()}
				>
					<ArrowLeftGrey />
				</CalendarCell>
				<CalendarCell cellDesign='col-span-5 lg:col-span-3 text-[16px]  sm:text-[20px] text-green-50 '>
					{lng === 'es'
						? monthAndYearSpanish.charAt(0).toUpperCase() +
						  monthAndYearSpanish.slice(1)
						: monthAndYearEnglish}
				</CalendarCell>
				<CalendarCell
					cellDesign='cursor-pointer col-span-1 lg:col-span-2'
					onClick={() => handleNextMonth()}
				>
					<ArrowRightGrey />
				</CalendarCell>
				<div className=' col-span-7 border-b-2 border-green-50'></div>
				{days.map((day, index) => (
					<div key={index}>
						<CalendarCell children={day} cellDesign=' font-bold text-base' />
					</div>
				))}

				{initialEmptyDatesArray.map((date, index) => (
					<div key={index} className='h-full'>
						<CalendarCell cellDesign=' h-full' />
					</div>
				))}

				{datesOfTheMonthArray.map((date, index) => (
					<div key={index} className='select-none'>
						<CalendarCell
							children={date}
							cellDesign='font-normal text-sm'
							fullDate={handleFullDate(date)}
							calendarEvents={calendarEvents}
							typeOfContent='number'
						/>
					</div>
				))}

				{finalEmptyDatesArray.map((date, index) => (
					<div key={index} className='h-full'>
						<CalendarCell cellDesign=' h-full' />
					</div>
				))}
				<div className='col-span-7 pl-[10px] lg:pl-8'>
					{eventsData.map((event, index) => (
						<div className='flex flex-col' key={index}>
							<div className='flex flex-row items-center'>
								<span
									className={`inline-block h-[12px] w-[12px] rounded-full  ${event.color}`}
								/>

								<div className='p-1 text-left text-sm font-normal'>
									{t(event.typeOfEvent)}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

Calendar.propTypes = {
	/**Array prop with the calendar events*/
	calendarEvents: PropTypes.array,
};

export default Calendar;
