//Third parties
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

//BL

//UI
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import TextSubTitle from '../../../UI/molecules/Text/TextSubTitle';
import Cross from '../../assets/icons/Cross.svg';
import Check from '../../assets/icons/whiteCheck.svg';
import InputCase from '../../molecules/Field/InputCase';

/**
 * @description section that uses formik to edit the section's fields
 * @param {*} param0
 * @returns
 */

const SectionEditTitleAndFields = ({
	colorHr,
	sectionTitle,
	informationArray,
	onSubmitAction,
	initialValues,
	setTurnEdit,
	id,
	validationSchema,
}) => {
	const { t } = useTranslation();

	const validationSchemaObject = Yup.object(validationSchema);

	return (
		<div className='flex w-full flex-col '>
			<TextSubTitle
				text={t(sectionTitle)}
				hasEdit={false}
				hasAdd={false}
				colour={colorHr}
			/>
			<div>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={validationSchemaObject}
					onSubmit={(values) => {
						let input = { values, id };
						onSubmitAction(input);
						setTurnEdit(false);
					}}
				>
					{(formProps) => (
						<Form>
							{informationArray.map((item, i) => (
								<InputCase item={item} key={i} formProps={formProps} />
							))}
							<div className='my-5 flex w-full flex-row justify-center gap-3'>
								<ButtonFab
									label={t('Cancelar')}
									colour='white'
									action={() => setTurnEdit(false)}
									icon={Cross}
								/>
								<ButtonFab
									colour='green'
									label={t('Guardar')}
									icon={Check}
									type='submit'
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

SectionEditTitleAndFields.propTypes = {
	/** title of the section*/
	sectionTitle: PropTypes.string,
	/** info used to create the report*/
	informationArray: PropTypes.array,
	/** action on submit */
	onSubmitAction: PropTypes.func,
	/** arrays of objects with labels and fields*/
	initialValues: PropTypes.object,
	/** Color of Header in Subtitle */
	colorHr: PropTypes.string,
	/** check */
	setTurnEdit: PropTypes.func,
	/** id of the element */
	id: PropTypes.number,
	/* ValidationSchema used to manage error messages */
	validationSchema: PropTypes.object,
};

export default SectionEditTitleAndFields;
