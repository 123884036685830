import { t } from 'i18next';
import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Formik } from 'formik';

//BL
import { usePostPostulationPortfolio } from '../../../BusinessLogic/hooks/query/useQueryPostulation';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import UnderlineHr from '../../atoms/UnderLine/UnderlineHr';
import CardPortfolio from '../../molecules/Card/CardPortfolio';
import NavbarSecondaryNew from '../../organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../TemplateWithNavBar';
import { ReactComponent as PortfolioWhite } from '../../assets/icons/PortfolioWhite.svg';
import CheckWhite from '../../assets/icons/CheckWhite.svg';

/**
 * @description renders the view of a preselected portfolio postulation
 */
const PortfolioSelection = ({
	portfolios,
	preselectedPortfolio,
	postulationSingle,
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const postPostulationPortfolioMutation = usePostPostulationPortfolio();

	return (
		<TemplateWithNavBar>
			<NavbarSecondaryNew
				title={t('PortafolioRequerido')}
				textColor='text-purple-50'
				color='bg-purple-50'
				showDots={false}
				redirect={`/postulaciones/${postulationSingle.id}`}
				textBack={`${t('RegresarConvocatoria')}`}
				textBackDesign='pl-3 text-purple-50'
				arrowLeftColor='purple'
				containerDesign={'px-0'}
			/>
			<div className='flex w-full gap-4 py-8'>
				<div className='flex h-14 w-14 items-center justify-center rounded-full bg-green-50 p-4'>
					<PortfolioWhite />
				</div>
				<div className='flex w-full flex-col gap-2'>
					<TextTitleSub
						title={t('PortafolioLibre')}
						design={'text-purple-50'}
					/>
					<TextContentPrincipal
						text={t('ParaAplicarAEstaConvocatoriaDebesSeleccionarUnPortafolio')}
					/>
				</div>
			</div>
			<div className='flex w-full flex-col py-4'>
				<TextTitleSub
					title={t('PortafoliosDisponiblesEnDatarte')}
					textColor='text-purple-50'
				/>
				<div className='mr-6'>
					<UnderlineHr design={'mr-6'} colour={'purple'} />
				</div>
				<TextContentPrincipal
					design={'py-4'}
					text={`${
						portfolios?.length === 0
							? ''
							: t('SeleccionaElPortafolioQueDeseasAdjuntar')
					}`}
				/>
			</div>
			<Formik
				initialValues={preselectedPortfolio}
				onSubmit={(values) => {
					const selectedPortfolio = portfolios.find(
						(portfolio) => portfolio.slug === values.slug
					);
					postPostulationPortfolioMutation
						.mutateAsync({
							postulation_id: postulationSingle.id,
							portfolio_id: selectedPortfolio.id,
						})
						.then(() => {
							navigate(`/postulaciones/${postulationSingle.id}`);
						})
						.catch((err) => console.error(err));
				}}
			>
				{(formProps) => (
					<form className='relative'>
						{Array.isArray(portfolios) && portfolios.length > 0 ? (
							<div className='grid w-full auto-cols-auto gap-4 sm:grid-cols-2 md:grid-cols-3'>
								{portfolios?.map((portfolio) => (
									<CardPortfolio
										key={portfolio.id}
										name={portfolio?.name}
										image={portfolio.main_picture_url}
										action={() =>
											navigate(`/portafolio/${portfolio.slug}`, {
												from: location.pathname,
											})
										}
										showCheckboxInput={true}
										checkboxInputName={'slug'}
										checkboxInputValue={portfolio.slug}
									/>
								))}
							</div>
						) : (
							<>
								<TextContentPrincipal
									design={'text-red-500'}
									text={t('NoTienesPortafoliosEnDatarteParaAplicar')}
								/>
								<div className='flex w-full justify-center py-10'>
									<Link to={'/portafolios/plantillas'}>
										<ButtonFab
											size='large'
											label={t('CrearPortafolio')}
											colour='white'
											design={'shadow-md w-max'}
										/>
									</Link>
								</div>
							</>
						)}
						{formProps.values.slug !== preselectedPortfolio.slug && (
							<div className='sticky bottom-[5rem] my-5 flex w-full flex-row justify-center py-3'>
								<ButtonFab
									label={t('SeleccionarPortafolio')}
									icon={CheckWhite}
									size='large'
									action={() => formProps.submitForm()}
								/>
							</div>
						)}
					</form>
				)}
			</Formik>
		</TemplateWithNavBar>
	);
};

export default PortfolioSelection;
