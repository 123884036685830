/**
 * @description function that allows to clean a name without spaces, lowercase and spaces
 * @param {*} param0
 * @returns
 */
function BuildLink(portfolioName, userInfo) {
	let name = portfolioName;
	let nameWhitoutSpaces = name.replace(/ /g, '');
	let nameLowerCase = nameWhitoutSpaces.toLowerCase();
	let cleanName = nameLowerCase.replace('.', '');

	return userInfo.first_name + userInfo.last_name + '_' + cleanName;
}

export default BuildLink;
