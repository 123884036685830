import React from 'react';
import PropTypes from 'prop-types';

const TextContentSecondSkeleton = ({ designTextContentSecondSkeleton }) => {
	return (
		<p
			className={`${designTextContentSecondSkeleton} skeleton-animation-background-light mr-3 animate-skeleton rounded 
  bg-middle_grey-50`}
		></p>
	);
};

TextContentSecondSkeleton.defaultProps = {
	designTextContentSecondSkeleton: 'w-44 md:w-52 h-3',
};

TextContentSecondSkeleton.propTypes = {
	/** Tailwind class for design*/
	designTextContentSecondSkeleton: PropTypes.string,
};

export default TextContentSecondSkeleton;
