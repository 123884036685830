// 3rd party
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// BL
import PositionHandler from '../../../../BusinessLogic/helpers/positionHandler';

/* UI */
import ButtonFabSecondary from '../../../atoms/Buttons/FAB/ButtonFabSecondary';
import NextImg from '../../../../UI/assets/icons/nextImg.svg';
import ArrowLeftWhite from '../../../../UI/assets/icons/ArrowLeftWhite.svg';

/**
 * @description Managed the button navigation of the APP.
 * @param {*} param0
 * @returns
 */
function ButtonFabExtendeNav({ id, listItems, section }) {
	const navigate = useNavigate();

	const key = 'id';

	const uniqueListItems = Array.isArray(listItems)
		? [...new Map(listItems.map((item) => [item[key], item]))?.values()]
		: [listItems];
	const itemsLength = uniqueListItems.length;
	let position = uniqueListItems.map((e) => e.id).indexOf(Number(id));
	let nextImage = 0;

	return (
		<div className='flex w-full flex-row px-4'>
			<div className='flex w-full flex-row justify-between'>
				{position > 0 && (
					<ButtonFabSecondary
						icon={ArrowLeftWhite}
						action={() =>
							PositionHandler(
								navigate,
								itemsLength,
								position,
								nextImage,
								'previous',
								uniqueListItems,
								section
							)
						}
						design='z-10 fixed bottom-28 md:bottom-16'
					/>
				)}
				{position < itemsLength - 1 && (
					<ButtonFabSecondary
						icon={NextImg}
						action={() =>
							PositionHandler(
								navigate,
								itemsLength,
								position,
								nextImage,
								'next',
								uniqueListItems,
								section
							)
						}
						design='z-10 pr-4 fixed bottom-28 md:bottom-20 right-0'
					/>
				)}
			</div>
		</div>
	);
}

ButtonFabExtendeNav.propTypes = {
	/** section where belong the single card information nad used in the url */
	section: PropTypes.string,
	/** id of the element where this section appears */
	id: PropTypes.number,
	/** items that appear on the list.*/
	listItems: PropTypes.array,
};

ButtonFabExtendeNav.defaultProps = {
	listItems: [],
};

export default ButtonFabExtendeNav;
