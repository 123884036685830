const isDev = process.env.REACT_APP_MODE === 'development';

export const paymentsOptions = {
	artist: {
		monthly: [
			{
				name: 'PlanGratis',
				role: 'ArtistaIndividual',
				price: '0 US$',
				duration: 'PorSiempre',
				subtitle: 'EncuentraNuevasOportunidadesYPostulateALasConvocatorias',
				benefits: {
					common: [
						'AplicaAConvocatoriasIlimitadasDesdeLaApp',
						'DescubreNuevasOportunidadesCadaSemana',
						'CreaPortafoliosVirtualesPersonalizados',
						'OrganizaTusObrasPorColeccionesEnLaNube',
					],
				},
				isRedirect: true,
				price_id: isDev
					? process.env.REACT_APP_PLAN_FREE_DEV
					: process.env.REACT_APP_PLAN_FREE_PROD,
				notes: ['AplicanTerminosCondiciones'],
			},
		],
		annual: [
			{
				name: 'PlanPremium',
				role: 'ArtistaIndividual',
				price: '120 US$',
				duration: 'PorAnualidad',
				subtitle:
					'TeAyudamosAAumentarLasPosibilidadesDeGanarteLasOportunidades',
				benefits: {
					premium: [
						{ title: 'ApoyoEnLasOportunidadesQueQuieresGanarte' },

						'MentoriaDeApoyo',
						'ChatDeApoyo',
						'AccesoAnticipadoYPrioritarioDeLasOportunidades',

						{ title: 'OptimizacionDeTuPerfilComoArtista' },
						'CursosVirtualesEnVivo',
						'CursosVirtualesPregrabadosConLecturasYHojasDeTrabajo',
						'NewsletterConLasUltimasTendenciasReferentesDestacadosYPreciosActualesDeMercado',
						'NewsletterConListaDeEventosYExposiciones',
						'MentoriaGrupalDeSeguimientoCreativo',
					],
				},
				footer: ['HazteMiembro', 'Aceptamos10MiembrosNuevosAlMes'],
				isRedirect: true,
				price_id: isDev
					? process.env.REACT_APP_PLAN_PREMIUM_ANNUAL_DEV
					: process.env.REACT_APP_PLAN_PREMIUM_ANNUAL_PROD,
				notes: [
					'AplicanTerminosCondiciones',
					'EstaSuscripcionSeraRenovada',
					'UnUnicoPago',
				],
			},
		],
	},
};
