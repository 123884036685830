import { useState } from 'react';

//UI
import CatalogueSelection from '../../../UI/templates/postulation/CatalogueSelection';
import CatalogueConfirmation from '../../../UI/templates/postulation/CatalogueConfirmation';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetPostulationSingle } from '../../../BusinessLogic/hooks/query/useQueryPostulation';
import PortfolioLoading from '../../../UI/organisms/Loading/PortfolioLoading';

/**
 * @description Allows the user to select and save the artworks to include in their call application.
 * @param
 * @returns
 */

const PostulationCatalogue = () => {
	const { id: postulationId } = useParams();

	const { postulationSingle, isLoading } =
		useGetPostulationSingle(postulationId);

	const { appUser } = useSelector((state) => state.appUser);

	const initialPreselectedArtworkIds =
		postulationSingle?.artworks?.map((artwork) => artwork.id) || [];

	const [CatalogueView, setCatalogueView] = useState(
		initialPreselectedArtworkIds.length > 0
			? 'confirmationView'
			: 'selectionView'
	);
	const [preselectedArtworkIds, setPreselectedArtworkIds] = useState(
		initialPreselectedArtworkIds
	);
	if (isLoading) {
		return <PortfolioLoading />;
	}

	if (appUser.role_company === 'ORGANIZATION') {
		return (
			<CatalogueConfirmation
				setCatalogueView={setCatalogueView}
				setPreselectedArtworkIds={setPreselectedArtworkIds}
				postulationSingle={postulationSingle}
			/>
		);
	}

	return (
		<>
			{CatalogueView === 'selectionView' && (
				<CatalogueSelection
					setCatalogueView={setCatalogueView}
					setPreselectedArtworkIds={setPreselectedArtworkIds}
					preselectedArtworkIds={preselectedArtworkIds}
					postulationSingle={postulationSingle}
				/>
			)}
			{CatalogueView === 'confirmationView' && (
				<CatalogueConfirmation
					setCatalogueView={setCatalogueView}
					preselectedArtworkIds={preselectedArtworkIds}
					postulationSingle={postulationSingle}
				/>
			)}
		</>
	);
};

export default PostulationCatalogue;
