//3rd party
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

//UI
import { ListItem } from '@material-ui/core';
import ListItemText from '../../../UI/atoms/Inputs/ListItemText';

/**
 * @description Select element for Formik
 * @param {*} param0
 * @returns
 */

const FieldSelectFormik = ({
	label,
	dbName,
	options,
	key,
	onChangeAction,
	errors,
	designErrors,
	formProps,
	defaultOptionValue,
	hideDefaultOption = false,
}) => {
	const { t } = useTranslation();
	return (
		<>
			<ListItem
				key={key}
				style={{
					paddingLeft: '0px',
					paddingRight: '0px',
					justifyContent: 'space-between',
				}}
			>
				<ListItemText
					primary={label}
					design='body-Text2'
					style={{ fontWeight: 400 }}
				/>
				{onChangeAction ? (
					<Field
						name={dbName}
						type='text'
						as='select'
						onChange={(e) => {
							formProps.setFieldValue(dbName, e.target.value);
							onChangeAction(e, formProps);
						}}
						className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-75 px-1 md:w-56'
					>
						{!hideDefaultOption && (
							<option key={1000000} value={defaultOptionValue ?? ''}>
								{'Selecciona una opción'}
							</option>
						)}

						{options?.length > 0 &&
							options?.map((o, id) => (
								<option key={id} value={o.value}>
									{o.label}
								</option>
							))}
					</Field>
				) : (
					<Field
						name={dbName}
						type='text'
						as='select'
						className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-75 px-1 md:w-56'
					>
						{!hideDefaultOption && (
							<option key={1000000} value={defaultOptionValue ?? ''}>
								{'Selecciona una opción'}
							</option>
						)}
						{options?.length > 0 &&
							options.map((o, id) => (
								<option key={id} value={o.value}>
									{o.label}
								</option>
							))}
					</Field>
				)}
			</ListItem>
			{errors && (
				<div className={`ml-auto text-red-400 ${designErrors ?? ''}`}>
					{t(errors)}
				</div>
			)}
		</>
	);
};

FieldSelectFormik.propTypes = {
	/** label of the select */
	label: PropTypes.string,
	/** This fields connects the data. Please use the same name that on the database for simplicity */
	dbname: PropTypes.string,
	/** Array with the options that will get displayed */
	options: PropTypes.array,
	/** key to differentiate in a list */
	key: PropTypes.number,
	/** Action when the field changes. Useful for dynamic sections */
	onChangeAction: PropTypes.func,
	/** this error string is generated from te yup validation schema in case of a missing requerid field*/
	errors: PropTypes.string,
	/** value to default option */
	defaultOptionValue: PropTypes.number,
};

FieldSelectFormik.defaultProps = {
	onChangeAction: null,
};

export default FieldSelectFormik;
