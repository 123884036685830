// 3rd Party
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { putElementOnBlur } from '../../../../BusinessLogic/helpers/expertPortfolioHelpers';

// UI
import EditExpertInputText from '../../../atoms/Portfolio/ExpertElements/EditExpertInputText';
import ExpertSlideElementText from '../../../atoms/Portfolio/ExpertSlideElements/ExpertSlideElementText';

export default function ExpertSlideBackCover({
	slideData,
	isEdition,
	isScaled,
}) {
	const dispatch = useDispatch();
	const slideItems = slideData?.slideItems;

	const mainTitleElement = slideItems
		?.find((element) => element.type === 'title')
		?.slideItemElements?.find((element) => element.type === 'title');
	const subtitleElement = slideItems
		?.find((element) => element.type === 'title')
		?.slideItemElements?.find((element) => element.type === 'subtitle');

	const [slideContent, setSlideContent] = useState({
		title: mainTitleElement?.content,
		subtitle: subtitleElement?.content,
	});

	if (isEdition) {
		return (
			<section className='flex flex-col place-items-end gap-2  p-2 py-4 pb-8'>
				{mainTitleElement && (
					<p className='mb-16 text-right text-xs opacity-70'>
						Sección - Contraportada
					</p>
				)}
				{mainTitleElement && (
					<EditExpertInputText
						placeholderText={'Nombre'}
						inputDesign={
							'text-base font-bold font-louisGeorgeCafe text-right mr-2 w-2/3 md:w-[55%] md:text-xl'
						}
						inputValue={slideContent.title}
						onChangeAction={(e) =>
							setSlideContent({ ...slideContent, title: e.target.value })
						}
						onBlurAction={(e) =>
							mainTitleElement &&
							putElementOnBlur(
								dispatch,
								e.target.value,
								mainTitleElement.slideItemElementId,
								mainTitleElement.content
							)
						}
					/>
				)}
				{subtitleElement && (
					<EditExpertInputText
						placeholderText={'Email'}
						inputDesign={
							'text-xs text-right mr-2 md:text-base w-2/3 md:w-[55%]'
						}
						inputValue={slideContent.subtitle}
						onChangeAction={(e) =>
							setSlideContent({ ...slideContent, subtitle: e.target.value })
						}
						onBlurAction={(e) =>
							subtitleElement &&
							putElementOnBlur(
								dispatch,
								e.target.value,
								subtitleElement.slideItemElementId,
								subtitleElement.content
							)
						}
					/>
				)}
			</section>
		);
	}

	return (
		<section
			className={`mx-[5%] flex flex-col gap-1 text-right md:gap-2 ${
				isScaled && 'mt-2'
			}`}
		>
			<ExpertSlideElementText
				text={mainTitleElement?.content}
				designText={`font-black ${isScaled && 'text-[10px]'}`}
			/>
			<ExpertSlideElementText
				text={subtitleElement?.content}
				designText={`${isScaled && 'text-[10px]'}`}
			/>
		</section>
	);
}

ExpertSlideBackCover.propTypes = {
	// contains the data to show
	slideItems: PropTypes.array,
	//Boolean that determines if the slide is editable
	isEdition: PropTypes.bool,
	//Boolean that determines if the slide is scaled for a preview
	isScaled: PropTypes.bool,
};
