// 3rd Party
import PropTypes from 'prop-types';

// Ui
import EditExpertArtwork from '../../../molecules/Portfolio/ExpertItems/EditExpertArtwork';
import ExpertSlideItemArtwork from '../../../molecules/Portfolio/ExpertSlideItems/ExpertSlideItemArtwork';

export default function ExpertSlideArtworkIndividual({
	slideData,
	isEdition,
	isScaled,
}) {
	const artworkElement = slideData?.slideItems?.find(
		(element) => element.type === 'artwork'
	);

	const isVideoArtwork =
		artworkElement?.object_type === 'VIDEO' ||
		artworkElement?.object_type === 'PERFORMANCE' ||
		artworkElement?.object_type === 'ANIMATION';

	if (isEdition) {
		return (
			<section
				className={`border-black mx-[5%] flex flex-col gap-y-8 border-b-2 pb-8`}
			>
				{artworkElement && (
					<p className='text-right text-xs opacity-70'>Sección - Obra</p>
				)}
				{artworkElement && (
					<EditExpertArtwork artworkItemData={artworkElement} />
				)}
			</section>
		);
	}
	return (
		<section
			className={`mx-[5%] flex flex-col ${
				isScaled ? 'gap-1' : 'gap-2 md:gap-4'
			}`}
		>
			<ExpertSlideItemArtwork
				artworkElements={artworkElement?.slideItemElements}
				designContainer={`md:max-w-[70%] ${
					isScaled && 'md:max-w-[50%] max-w-[50%]'
				}`}
				isScaled={isScaled}
				isVideoArtwork={artworkElement?.video_url && isVideoArtwork}
				videoUrl={artworkElement?.video_url}
			/>
		</section>
	);
}

ExpertSlideArtworkIndividual.propTypes = {
	// contains artwork data to show
	artworkElements: PropTypes.array,
	// Is edition view or public view
	isEdition: PropTypes.bool,
	//Boolean that determines if the slide is scaled for a preview
	isScaled: PropTypes.bool,
};
