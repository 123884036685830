const lng =
	window.localStorage.getItem('lng') &&
	window.localStorage.getItem('lng').slice(0, 2);

const measure = (height, length, width) => {
	switch (lng) {
		case 'es':
			return `Medida: ${height ? height : '00'} x ${length ? length : '00'} x ${
				width ? width : '00'
			} cm`;
		// case 'en':
		// 	return `Measure: ${height ? height : '00'} x ${
		// 		length ? length : '00'
		// 	} x ${width ? width : '00'} cm`;
		// case 'fr':
		// 	return `Measure: ${height ? height : '00'} x ${
		// 		length ? length : '00'
		// 	} x ${width ? width : '00'} cm`;
		default:
			return `Medida: ${height ? height : '00'} x ${length ? length : '00'} x ${
				width ? width : '00'
			} cm`;
	}
};

const technique = (technique) => {
	switch (lng) {
		case 'es':
			return `Técnica: ${technique ? technique : ''}`;
		// case 'en':
		// 	return `Technique: ${technique}. `;
		// case 'fr':
		// 	return `Technique: ${technique}.`;
		default:
			return `Técnica: ${technique ? technique : ''}`;
	}
};

const date = (date) => {
	switch (lng) {
		case 'es':
			return `Año: ${date ? date : '0000'}`;
		// case 'en':
		// 	return `Year: ${date ? date : '0000'}`;
		// case 'fr':
		// 	return `Year: ${date ? date : '0000'}`;
		default:
			return `Año: ${date ? date : '0000'}`;
	}
};

export const onSubmitArtworkElement = (artwork, selection) => {
	const valuesElementArtwork = {
		type: 'artwork',
		artworkId: artwork.artwork_id,
		elementType: selection.elementType,
		textPosition: { left: 30, top: 80 },
		positionTextDraggable: { x: 20, y: 0 },
		picturePosition: { top: 2 },
		positionPictureDraggable: { x: 20, y: 8 },
		picture: [
			{
				url: artwork.main_picture_url,
			},
		],
		text: [
			{
				name: artwork.name,
				height: artwork.dimensions_height,
				length: artwork.dimensions_length,
				weight: artwork.dimensions_weight,
				width: artwork.dimensions_width,
				measure: measure(
					artwork.dimensions_height,
					artwork.dimensions_length,
					artwork.dimensions_width
				),
				technique: technique(artwork.technique),
				year: date(artwork.date),
			},
		],
	};
	return valuesElementArtwork;
};

export const onSubmitTextElement = (selection) => {
	const valuesTextElement = {
		type: 'Text',
		textId: null,
		width: null,
		height: null,
		content: selection.content,
		placeholder: selection.placeholder,
		elementText: selection.elementText,
		positionDraggable: selection.positionDraggable,
		position: {},
		typeInput: selection.typeInput ?? 'input',
		fontSize: selection.fontSize,
	};
	return valuesTextElement;
};

export const updateElementPosition = (
	data,
	indexElement,
	currentPosition,
	positionName,
	editablePortfolioSlide,
	currentPositionPercentage
) => {
	switch (positionName) {
		case 'picturePosition':
			return [
				...editablePortfolioSlide.content.map((element, index) => {
					if (index !== indexElement) {
						return element;
					} else {
						return {
							...element,
							picturePosition: currentPositionPercentage(currentPosition),
							positionPictureDraggable: { x: data.x, y: data.y },
						};
					}
				}),
			];
		case 'textPosition':
			return [
				...editablePortfolioSlide.content.map((element, index) => {
					if (index !== indexElement) {
						return element;
					} else {
						return {
							...element,
							textPosition: currentPositionPercentage(currentPosition),
							positionTextDraggable: { x: data.x, y: data.y },
						};
					}
				}),
			];

		case 'position':
			return [
				...editablePortfolioSlide.content.map((element, index) => {
					if (index !== indexElement) {
						return element;
					} else {
						return {
							...element,
							position: currentPositionPercentage(currentPosition),
							positionDraggable: { x: data.x, y: data.y },
						};
					}
				}),
			];
		default:
			return [
				...editablePortfolioSlide.content.map((element, index) => {
					if (index !== indexElement) {
						return element;
					} else {
						return {
							...element,
							position: currentPositionPercentage(currentPosition),
							positionDraggable: { x: data.x, y: data.y },
						};
					}
				}),
			];
	}
};

export const updateElementTextContent = (
	editablePortfolioSlide,
	indexElement,
	textContent
) => {
	return [
		...editablePortfolioSlide.content.map((element, index) => {
			if (index !== indexElement) {
				return element;
			} else {
				return {
					...element,
					content: textContent,
				};
			}
		}),
	];
};
