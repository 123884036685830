import React from 'react';
import PropTypes from 'prop-types';
import SkeletonImage from '../../atoms/Skeleton/SkeletonImage';

const SkeletonImages = ({ numberOfImages, design }) => {
	let skeletonImagesArray = [];

	for (let i = 0; i < numberOfImages; i++) {
		skeletonImagesArray.push(
			<div key={i}>
				<SkeletonImage />
			</div>
		);
	}
	return (
		<>
			{<div className={design}>{skeletonImagesArray}</div>}
			<div className='mt-1 grid w-full grid-cols-3 gap-2'>
				<div
					className={`skeleton-animation-background-light col-start-2 col-end-3 hidden 
                        h-3 animate-skeleton
                        rounded-lg bg-light_grey-50`}
				></div>
			</div>
		</>
	);
};

SkeletonImages.defaultProps = {
	numberOfImages: 0,
	design:
		'w-full grid grid-cols-3 md:grid-cols-5 lg:grid-cols-4 lg:gap-8  mt-8 gap-2 md:gap-2',
};

SkeletonImages.propTypes = {
	/** Number of skeleton images displayed inside images component */
	numberOfImages: PropTypes.number,
	design: PropTypes.string,
};

export default SkeletonImages;
