/* -- Third Party -- */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//BL
import { stringToSlug } from '../../../BusinessLogic/helpers/textFormat';
import BuildLink from '../../../BusinessLogic/helpers/buildLink';
import { postPortfolio } from '../../../BusinessLogic/redux/reducers/portfolio';
import { getCollection } from '../../../BusinessLogic/redux/reducers/collection';
import {
	portfolioFormValidationSchemaWithPassword,
	portfolioEditFormValidationSchema,
} from '../../../BusinessLogic/data/PortfolioSingle';

//UI
import PortfolioEditForm from '../../../UI/organisms/Portfolio/PortfolioEditForm';
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import PortfolioEditImageAndVisualize from '../../../UI/organisms/Portfolio/PortfolioEditImageAndVisualize';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

/**
 * @description Page that managed the edition of the form
 * @param {*} action
 * @returns
 */
const PortfolioNew = ({ action }) => {
	//init
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [currentPortfolio, setCurrentPortfolio] = useState([{}]);
	const [currentPortfolioAux, setCurrentPortfolioAux] = useState('');
	const [slug, setSlug] = useState('');

	//getPortfolio
	const { uniqueIdCollection, currentPlantilla } = useSelector(
		(state) => state.portfolios
	);
	const { collections } = useSelector((state) => state.collections);
	const { appUser } = useSelector((state) => state.appUser);

	useEffect(() => {
		dispatch(getCollection(uniqueIdCollection));
	}, [dispatch, uniqueIdCollection]);

	useEffect(() => {
		if (action === 'new') {
			let currentPortfolioAux = collections.filter(
				(element) => element.id === uniqueIdCollection
			)[0];
			setCurrentPortfolio(currentPortfolioAux);

			setCurrentPortfolioAux(currentPortfolioAux.name);
		}
	}, [dispatch, action, collections, uniqueIdCollection]);

	useEffect(() => {
		if (currentPortfolioAux !== '') {
			setSlug(BuildLink(currentPortfolioAux, appUser));
		}
	}, [currentPortfolioAux, appUser]);

	const handleSubmit = (values) => {
		dispatch(postPortfolio(values))
			.then((res) => {
				if (res.payload.id) {
					navigate(`/portafolios/${res.payload.id}`);
				} else {
					if (res.payload.response.data.code === 403) {
						alert('You must change the slug');
					}
				}
			})
			.catch((error) => console.log({ error }));
	};
	return (
		<TemplateWithNavBar>
			<SecondaryNavbarSpecific
				title={t('CrearPortafolio')}
				textColor='text-yellow-50'
				color='bg-yellow-50'
				hide='md:invisible'
				design='mb-8 md:mb-0'
				textBack={t('RegresarColecciones')}
				textBackDesign='pl-3 text-yellow-50'
				arrowLeftColor='yellow'
			/>
			<div className='flex flex-col px-4 md:flex-row md:px-0 lg:gap-8'>
				<PortfolioEditImageAndVisualize
					alt={currentPortfolio.name}
					image={currentPortfolio.main_picture_url}
					link={currentPlantilla}
					wrapperClassName='w-60 h-60 lg:w-80 lg:h-80'
					buttonAction={() => navigate('/plantilla/portafolio_basico/')}
				/>
				<div className='flex flex-col md:flex-row'>
					<PortfolioEditForm
						currentPortfolioName={currentPortfolio.name}
						validationSchema={portfolioEditFormValidationSchema}
						validationSchemaWithPassword={
							portfolioFormValidationSchemaWithPassword
						}
						onSubmit={handleSubmit}
						slug={typeof slug === 'string' ? stringToSlug(slug) : slug}
						type_id={uniqueIdCollection}
					/>
				</div>
			</div>
		</TemplateWithNavBar>
	);
};

PortfolioNew.propTypes = {
	/** Manages the actions of the form. Can be edit or new */
	action: PropTypes.string,
};

PortfolioNew.defaultProps = {
	action: 'new',
};

export default PortfolioNew;
