//3rd party
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useEffect } from 'react';

// UI
import { ReactComponent as ClosedCrossIcon } from '../../assets/icons/CrossWhite.svg';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

/**
 * @description Modal
 */

const ModalPortfolio = ({
	portfolioData,
	setShowMobileModal,
	popupOptions,
	title,
}) => {
	useEffect(() => {
		document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	}, []);

	return (
		<div
			className=' fixed top-0 right-0 z-40 flex h-full w-full cursor-pointer bg-black-50/70 bg-opacity-70 shadow-xl sm:hidden'
			onClick={(e) => {
				e.stopPropagation();
				setShowMobileModal(false);
			}}
		>
			<div className='m-auto flex'>
				<div className='color-white absolute top-10 right-[5%] cursor-pointer'>
					<ClosedCrossIcon />
				</div>
				<div
					className='mx-auto flex w-[90%] flex-col rounded-t-md'
					onClick={(e) => e.stopPropagation()}
				>
					<LazyLoadImage
						alt={portfolioData.name}
						src={
							portfolioData.main_picture_url ??
							'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image'
						}
						width='100%'
						height='100%'
						delayTime='0'
						threshold='300'
						className=' max-h-[70vh] cursor-default select-none'
					/>
					<div className=' flex w-full flex-col rounded-b-md bg-white shadow-lg '>
						<div
							className='flex select-none flex-col justify-between '
							onClick={() => setShowMobileModal(false)}
						>
							<p
								className={`flex w-full  place-items-center gap-2  whitespace-nowrap border-b-2 py-4  pl-[5%] text-xl text-strong_yellow-50`}
								key={0}
							>
								{title}
							</p>
							{popupOptions.map((option, i) => (
								<button
									className={`flex w-full 
                                        flex-row  place-items-center justify-center gap-2 whitespace-nowrap border-b-2 py-4 last-of-type:rounded-b-md last-of-type:border-b-0 hover:bg-gray-200`}
									key={i + 1}
									onClick={option.action}
								>
									{option.icon}
									<TextContentSecond
										className=''
										style={{ fontSize: '16px' }}
										text={option.label}
									/>
								</button>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalPortfolio;
