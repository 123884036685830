//3rd party
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//internal
import ButtonLinkText from '../../atoms/Buttons/Link/ButtonLinkText';
import { ButtonSignOut } from '../../atoms/Buttons/Link/ButtonSignOut';
import FigureCirclePerfil from '../../atoms/Figure/FigureCirclePerfil';
import UnderlineHr from '../../atoms/UnderLine/UnderlineHr';
import DataPerfil from '../../molecules/userManagement/DataPerfil';

/**
 * @description:
 * Shows the menu on the top right side.
 * It has all the links
 * It fetches data to get user email and name
 * @changes We need to remove the fetch from here. This is why it's so slow. It should be a reducer and the components only displays data.
 * @param {*} param0
 * @returns {component}
 */

export default function ManagamentMenuPerfil({ action }) {
	const { t } = useTranslation();
	const { appUser } = useSelector((state) => state.appUser);

	const roleCompany = appUser.role_company;
	const isArtistUser = roleCompany === 'ARTIST';

	const isAppUser = appUser && appUser.token;

	return (
		<div className='fixed inset-0 z-20' onClick={action}>
			<div className='shadow_box absolute right-8 top-14 z-20 w-72 rounded-xl bg-light_grey-50 '>
				{isAppUser && (
					<>
						<div className='m-0 flex h-44 flex-col items-center p-5'>
							<FigureCirclePerfil
								initial={
									appUser.first_name.charAt(0) + appUser.last_name.charAt(0)
								}
								design='bg-green-50 text-white rounded-full h-14 w-14 flex items-center justify-center mr-0'
							/>
							<DataPerfil
								name={appUser.first_name + ' ' + appUser.last_name}
								email={appUser.email}
								designName='mb-0.5'
								designEmail='mt-0.5 mb-0.5'
								designPerfil='cursor-pointer'
							/>
						</div>
						<UnderlineHr design='h-0.5 border-none' />
						<div className='flex flex-col items-baseline justify-between space-y-5 p-8'>
							<ButtonLinkText
								route={
									appUser.token
										? '/management/configuracion'
										: '/iniciar-sesion'
								}
								text={t('Configuración')}
								design='text-left mb-0 cursor-pointer text-base font-light'
							/>
							{isArtistUser && (
								<ButtonLinkText
									route={
										appUser.token
											? '/management/configuracion?tab=2'
											: '/iniciar-sesion'
									}
									text={t('AdministrarPlan')}
									design='text-left mb-0 cursor-pointer text-base font-light'
								/>
							)}
							<ButtonSignOut />
						</div>
					</>
				)}
				{!isAppUser && (
					<div className='m-0 flex h-32 flex-col items-center p-5'>
						<FigureCirclePerfil
							initial=''
							design='bg-green-50 text-white rounded-full h-14 w-14 flex items-center justify-center mr-0'
						/>
						<ButtonLinkText
							route={'/iniciar-sesion'}
							text={t('IniciarSesion')}
							design='text-left mb-0 cursor-pointer text-base font-ligh mt-5'
						/>
					</div>
				)}
			</div>
		</div>
	);
}

ManagamentMenuPerfil.propTypes = {
	/** Passes the action when click on the component */
	action: PropTypes.func,
};

ManagamentMenuPerfil.defaultProps = {};
