export const ONBOARDING_ES = {
	CompletaEstosPasos:
		'Sigue estos pasos para descubrir cómo funciona Datarte y posteriormente aplicar a la convocatoria desde tu cuenta.',
	BienvenidoADatarte: 'Bienvenido(a) a Datarte',
	ParaDisfrutarDeDatarte: 'Para disfrutar de Datarte',
	Crea1Obra: 'Crea 1 obra.',
	Crea1Colección: 'Crea 1 colección.',
	Crea1Portafolio: 'Crea 1 portafolio.',
	Crea1Artista: 'Crea 1 artista.',
	Saltar: 'Saltar',
	SaltarPasos: 'Saltar pasos',
	EscribeTuBiografía: 'Escribe tu biografía:',
	HazteConocer: 'Hazte conocer',
	PlataformasVirtuales:
		'las plataformas de arte virtuales son espacios de visibilización para todo tipo de artistas',
	TamañoMáximoSoportadoDeLaImagenEsDe100MB:
		'Nota: el tamaño máximo soportado de la imagen es de 100MB.',
	OfreceInformaciónSobreQuiénEres:
		'Ofrece información sobre tu vida, dónde naciste, estudios, sobre tus obras, tus ideas y lo que te inspira. También puedes incluir tus “mayores éxitos”.',
	OpcionesAvanzadas: 'Opciones avanzadas',
	TituloDeLaObra: 'Título de la obra',
	AñadirImagenDePerfil: 'Añadir imagen de perfil',
	ImagenAgregada: 'Imagen agregada:',
	AñadirImagenDeObra: 'Añadir imagen de obra',
	AgregaTuPrimeraObra:
		'Agrega tus obras para posteriormente aplicar a las convocatorias',
	AgregaTuSegundaObra: 'Agrega tu segunda obra',
	Felicitaciones: '¡Felicitaciones!',
	YaCreasteTuPortafolio: '¡Ya creaste tu primer portafolio virtual!',
	RápidoYSencillo:
		'Para aplicar a las convocatorias vas a necesitar agregar tu portafolio virtual creado en Datarte.',
	Finalizar: 'Finalizar',
	Empezar: 'Empezar',
	OrdenaTuCreatividad: 'Ordena tu creatividad',
	estaPáginaNoEstáDisponible: 'esta página no está disponible',
	LoSentimosEstaPáginaNoEstáDisponible:
		' Lo sentimos, la dirección web que ha introducido no se encuentra disponible.',
	VolverA: 'Volver a ',
	ExploraNuevasOportunidadesEnNuestraAplicación:
		'Crea tu perfil de artista para posteriormente compartirlo en las convocatorias.',
};
