//3rd parties
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// BL
import { postPortfolioV2 } from '../../../BusinessLogic/redux/reducers/portfolioV2';
import {
	clearEditablePortfolioSlideContent,
	setEditablePortfolioView,
} from '../../../BusinessLogic/redux/reducers/editablePortfolio';
import {
	setIdOfPortfolioToEdit,
	setIdOfPortfolioSlideToEdit,
} from '../../../BusinessLogic/redux/reducers/editablePortfolio';
import {
	emptyAllFieldsPortfolioDataCreation,
	setPortfolioDataCreationPortfolioType,
	getPortfolios,
	setPortfolioDataCreationType,
} from '../../../BusinessLogic/redux/reducers/portfolio';

//UI
import ButtonFabPrincipalUrl from '../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import ImageAndInfo from '../../../UI/molecules/Image/ImageAndInfo';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import DefaultImageAutomaticPortfolio from '../../../UI/assets/images/DefaultImageAutomaticPortfolio.png';
import PortfolioExpertImage from '../../../UI/assets/images/PortfolioExpert.png';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

const PlantillaSelectionPortfolio = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [portfolioTemplate, setPortfolioTemplate] = useState(null);

	const { first_name, last_name } = useSelector(
		(state) => state.appUser.appUser
	);

	useEffect(() => {
		dispatch(emptyAllFieldsPortfolioDataCreation());
		// eslint-disable-next-line
	}, []);

	const handleSubmitPortfolioNew = async () => {
		const values = {
			user_name: `${first_name} ${last_name}`,
			organization_user_id: localStorage.organization_user_id,
		};
		await dispatch(postPortfolioV2(values)).then((res) => {
			if (res.payload.portfolio_id) {
				dispatch(setEditablePortfolioView('canvasEdition'));
				dispatch(setIdOfPortfolioToEdit(res.payload.portfolio_id));
				dispatch(setIdOfPortfolioSlideToEdit(res.payload.slide_id));
				dispatch(clearEditablePortfolioSlideContent());
				dispatch(getPortfolios());
				navigate(portfolioTemplate.route);
			} else {
				navigate('/portafolios/plantillas');
				alert(res.payload.message);
			}
		});
	};

	const handleAutomaticPortfolio = () => {
		if (portfolioTemplate?.name === 'AUTOMATIC') {
			return setPortfolioTemplate(null);
		}
		setPortfolioTemplate({
			name: 'AUTOMATIC',
			route: '/plantilla',
		});
	};

	const handleExpertPortfolio = () => {
		if (portfolioTemplate?.name === 'EXPERT') {
			return setPortfolioTemplate(null);
		}
		setPortfolioTemplate({
			name: 'EXPERT',
			route: '/plantilla/colecciones/expert',
		});
	};

	const onClickCreatePortfolio = async () => {
		if (portfolioTemplate?.name === 'editableTemplate') {
			await handleSubmitPortfolioNew();
		} else {
			navigate(portfolioTemplate.route);
			dispatch(setPortfolioDataCreationPortfolioType(portfolioTemplate.name));
			dispatch(setPortfolioDataCreationType('COLLECTION'));
		}
	};

	return (
		<TemplateWithNavBar section={'Portafolios'}>
			<NavbarSecondaryNew
				title={t('NuevoPortafolio')}
				color='bg-yellow-50'
				textColor='text-yellow-50'
				redirect='/portafolios'
				textBack={t('RegresarPortafolios')}
				textBackDesign='pl-3 text-yellow-50'
				showDots={false}
				arrowLeftColor='yellow'
				containerDesign={'pb-3 md:pb-5'}
			/>
			<div className='mb-3 flex w-full justify-center'>
				<TextContentSecond
					text={t('Paso1')}
					paragraphDesign={'text-yellow-50 text-2xl font-bold'}
				/>
			</div>
			<div className='mx-auto w-[95%] md:w-[90%]'>
				<div className=' py-8 md:py-4 '>
					<TextContentSecond
						text={t('EscogeQueTipoDePortafolioQuieresCrear')}
						paragraphDesign='text-base mb-2 font-light'
					/>
				</div>
				<div className='mt-4 flex flex-wrap gap-8'>
					<div className='flex h-[85%] max-w-[180px] flex-col justify-end'>
						<TextContentSecond
							text={t('RecomendadoPorDatarte')}
							design='text-green-50 mb-4 whitespace-nowrap'
						/>
						<div onClick={handleExpertPortfolio}>
							<ImageAndInfo
								title={t('PlantillaExpertos')}
								sub_header={t('PortafolioCreadoPorArtistas')}
								design='relative mx-auto'
								titleDesign='font-bold text-sm pb-2'
								textContainerDesign='py-4 w-full'
								lazyLoadImageBorder={portfolioTemplate?.name === 'EXPERT'}
								imagen={PortfolioExpertImage}
								imageFormat={false}
							/>
						</div>
					</div>
					<div
						className='flex h-[85%] max-w-[180px] flex-col justify-end md:pt-8'
						onClick={handleAutomaticPortfolio}
					>
						<ImageAndInfo
							title={t('PortafolioAutomático')}
							sub_header={t('PortafolioAutomatizado')}
							design='relative  mx-auto'
							titleDesign='font-bold text-sm pb-2'
							textContainerDesign='py-4 w-full'
							lazyLoadImageBorder={portfolioTemplate?.name === 'AUTOMATIC'}
							imagen={DefaultImageAutomaticPortfolio}
							imageFormat={false}
						/>
					</div>
				</div>
			</div>
			{portfolioTemplate && (
				<div onClick={onClickCreatePortfolio}>
					<ButtonFabPrincipalUrl label={t('Crear')} icon={false} />
				</div>
			)}
		</TemplateWithNavBar>
	);
};

export default PlantillaSelectionPortfolio;
