import PropTypes from 'prop-types';

/**
 * @description Hr element used in underlined.
 * Trying to make it consistent in style
 * @param {*} param0
 * @returns
 */

export default function UnderlineHr({ colour, design }) {
	// manages the colour
	let hrColor = '';
	if (colour === 'yellow') {
		hrColor = 'bg-yellow-50';
	} else if (colour === 'purple') {
		hrColor = 'bg-purple-50';
	} else if (colour === 'green') {
		hrColor = 'bg-green-50';
	} else if (colour === 'light_green') {
		hrColor = 'bg-light_green-50';
	} else if (colour === 'blue') {
		hrColor = 'bg-blue-500';
	}
	return <hr className={`mt-px h-0.5 w-full ${design} ${hrColor}`}></hr>;
}

UnderlineHr.propTypes = {
	/** Colours used on the  */
	colour: PropTypes.string,

	/** Tailwind Class of the hr */
	design: PropTypes.string,
};

UnderlineHr.defaultProps = {
	colour: 'yellow',
	design: '',
};
