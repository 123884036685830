//3rd
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//BL
import textBlankEntityGenerator from '../../../BusinessLogic/helpers/textBlankEntityGenerator';
import { downloadDocument } from '../../../BusinessLogic/helpers/downloadMedia';
import ViewModal from '../../templates/ViewModal';

//UI
import TextSubTitle from '../../molecules/Text/TextSubTitle';
import DocumentTypes from '../../../Pages/sections/document/DocumentTypes';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import { ReactComponent as AddBlack } from '../../assets/icons/AddBlack.svg';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import Cross from '../../assets/icons/Cross.svg';
import DownloadIcon from '../../assets/icons/download.svg';

/**
 * @description render a section with its title and a grid of documents
 * @param {*} param0
 * @returns
 */
function SectionDocumentGrid({
	sectionTitle,
	colorHr,
	documents,
	setTurnEdit,
	AddIconLink,
	saveLastSectionURL,
	saveURLHistory,
	hasDots,
	hasEdit,
	setHasEdit,
	dotsOptionsInTab,
	showDotsPopup,
	setShowEditPopup,
	showEditPopup,
	setShowDotsPopup,
	editIconOptions,
	showDownloadOptions,
	setShowDownloadOptions,
	setShowDeleteOption,
	showDeleteOption,
	deleteDocumentFunction,
}) {
	const sectionName = documents[0].title.toLowerCase();
	const [downloadArray, setDownloadArray] = useState([]);
	const [selectedDocuments, setSelectedDocuments] = useState(0);
	const [selectedVideos, setSelectedVideos] = useState(0);
	const [selectedImages, setSelectedImages] = useState(0);
	const idDocument = useRef();
	const { t } = useTranslation();

	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [confirmDeleteElement, setConfirmDeleteElement] = useState(false);

	const addCheckToImage = (imageToDownload, imageActive) => {
		if (!imageActive) {
			setDownloadArray([...downloadArray, imageToDownload]);
			setSelectedImages((selectedImages) => selectedImages + 1);
		}

		if (imageActive) {
			setSelectedImages((selectedImages) => selectedImages - 1);
			downloadArray.forEach((image) => {
				if (image === imageToDownload) {
					downloadArray.splice(image, 1);
				}
			});
		}
	};

	const addCheckToDocument = (documentToDownload, documentActive) => {
		if (!documentActive) {
			setDownloadArray([...downloadArray, documentToDownload]);
			setSelectedDocuments((selectedDocuments) => selectedDocuments + 1);
		}

		if (documentActive) {
			setSelectedDocuments((selectedDocuments) => selectedDocuments - 1);
			downloadArray.forEach((document) => {
				if (document === documentToDownload) {
					downloadArray.splice(document, 1);
				}
			});
		}
	};
	const addCheckToVideo = (videoToDownload, videoActive) => {
		if (!videoActive) {
			setDownloadArray([...downloadArray, videoToDownload]);
			setSelectedVideos((selectedVideos) => selectedVideos + 1);
		}

		if (videoActive) {
			setSelectedVideos((selectedVideos) => selectedVideos - 1);
			downloadArray.forEach((video) => {
				if (video === videoToDownload) {
					downloadArray.splice(video, 1);
				}
			});
		}
	};

	const cancelButtonAction = () => {
		setShowDownloadOptions(false);
		setShowDeleteOption(false);
	};

	useEffect(() => {
		setDownloadArray([]);
		setSelectedDocuments(0);
		setSelectedVideos(0);
		setSelectedImages(0);
	}, [showDownloadOptions]);

	const downloadButtonFunction = () => {
		downloadArray.forEach((documents, i) => {
			downloadDocument(documents, `Document ${i}`);
		});
	};

	const deleteDocumentButton = (id) => {
		idDocument.current = id;
		setShowConfirmationModal(true);
	};

	useEffect(() => {
		if (confirmDeleteElement) {
			deleteDocumentFunction(idDocument.current);
		}
		setConfirmDeleteElement(false);
	}, [confirmDeleteElement, deleteDocumentFunction]);

	const AllDocumentsSelected =
		selectedDocuments + selectedVideos + selectedImages;

	return (
		<div className='flex flex-col'>
			<TextSubTitle
				colour={colorHr}
				design=''
				text={sectionTitle}
				hasEdit={hasEdit}
				setHasEdit={setHasEdit}
				setTurnEdit={setTurnEdit}
				AddIconLink={AddIconLink}
				saveLastSectionURL={saveLastSectionURL}
				saveURLHistory={saveURLHistory}
				hasDots={hasDots}
				editIconOptions={editIconOptions}
				setShowEditPopup={setShowEditPopup}
				showEditPopup={showEditPopup}
				dotsOptionsInTab={dotsOptionsInTab}
				showDotsPopup={showDotsPopup}
				setShowDotsPopup={setShowDotsPopup}
			/>
			<div>
				{showConfirmationModal && (
					<ViewModal
						modalTitle={t('EliminarDocumento')}
						modalTextOne={t('SeguroQueDeseasEliminarEsteDocumento')}
						setConfirmDeleteElement={setConfirmDeleteElement}
						closeMenu={setShowConfirmationModal}
					/>
				)}
				{showDownloadOptions && (
					<TextContentSecond
						design={'mb-4'}
						text={`${AllDocumentsSelected}
							${
								AllDocumentsSelected < 2
									? t('DocumentoSeleccionado')
									: t('DocumentosSeleccionados')
							}
						`}
					/>
				)}
				{documents[0].information.length < 1 ? (
					<div className='flex flex-row flex-wrap'>
						<TextContentSecond
							text={`${t(textBlankEntityGenerator(sectionTitle))}, `}
							design='pr-1'
						/>
						<div className='flex flex-row'>
							<TextContentSecond text={t('HazClicEnElBotón')} design='pr-1' />
							<AddBlack />
						</div>
					</div>
				) : (
					<div className='grid w-full grid-cols-3 justify-center gap-4 md:grid-cols-4'>
						{documents[0].information.map((item, i) => {
							let typeDocument = item.file_type ? item.file_type : item.type;
							const document = {
								...item,
								type: typeDocument,
							};
							return (
								<DocumentTypes
									document={document}
									redirect={sectionName}
									key={i}
									saveLastSectionURL={saveLastSectionURL}
									showDownloadOptions={showDownloadOptions}
									addCheckToDocument={addCheckToDocument}
									addCheckToVideo={addCheckToVideo}
									addCheckToImage={addCheckToImage}
									deleteDocumentButton={deleteDocumentButton}
									showDeleteOption={showDeleteOption}
								/>
							);
						})}
					</div>
				)}
				{(showDownloadOptions || showDeleteOption) && (
					<div className='my-5 flex w-full flex-row justify-center gap-3'>
						<ButtonFab
							label={t('Cancelar')}
							colour='white'
							action={() => {
								cancelButtonAction();
								setHasEdit(true);
							}}
							icon={Cross}
						/>
						{showDownloadOptions && (
							<ButtonFab
								colour='green'
								label={t('Descargar')}
								icon={DownloadIcon}
								action={() => downloadButtonFunction()}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

SectionDocumentGrid.propTypes = {
	/* title of the section */
	sectionTitle: PropTypes.string,
	/* string with the color of the hr */
	colorHr: PropTypes.string,
	/** data used for the documents */
	documents: PropTypes.array,
	/**  makes visible edit icons */
	setTurnEdit: PropTypes.func,
	/* url used in the add icon */
	AddIconLink: PropTypes.string,
	/**  bool that handle to save the actual specific location */
	saveLastSectionURL: PropTypes.bool,
	/* function that execute to save the actual url in redux state */
	saveURLHistory: PropTypes.func,
	/**  bool that determines if there is dots icon or not  */
	hasDots: PropTypes.func,
	/**  array with the options of the dots dropdown  */
	dotsOptionsInTab: PropTypes.array,
	/**  bool that determines visibility of the dots icon pop up   */
	showDotsPopup: PropTypes.bool,
	/**  function that makes visible or not the dots icon pop up  */
	setShowDotsPopup: PropTypes.func,
	/**  bool that determines visibility of the download options  */
	showDownloadOptions: PropTypes.bool,
	/**  function that makes visible or not the download options  */
	setShowDownloadOptions: PropTypes.func,
	/**  bool that determines visibility of the edit icon pop up   */
	showEditPopup: PropTypes.bool,
	/**  function that makes visible or not the edit icon pop up  */
	setShowEditPopup: PropTypes.func,
	/**  array with the options of the edit dropdown  */
	editIconOptions: PropTypes.array,
	/**  bool that determines visibility of the delete option */
	setShowDeleteOption: PropTypes.bool,
	/**  function that makes visible or not the delete options  */
	showDeleteOption: PropTypes.func,
	/**  bool that determines if there is edit icon or not*/
	hasEdit: PropTypes.bool,
	/**  function that makes visible or not the edit button  */
	setHasEdit: PropTypes.func,
	/**  function that delete a single document in Documents Tab  */
	deleteDocumentFunction: PropTypes.func,
};

SectionDocumentGrid.defaultProps = {
	saveLastSectionURL: false,
};

export default SectionDocumentGrid;
