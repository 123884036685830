import React from 'react';
import PropTypes from 'prop-types';

import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';

export default function TextSubsectionTitle({
	title,
	color,
	textColor,
	style,
	classNameContainer,
	classNameHr,
}) {
	return (
		<div className={classNameContainer} style={style}>
			<TextTitleSub title={title} textColor={textColor} />
			<hr className={` ${classNameHr} ${color}`} />
		</div>
	);
}

TextSubsectionTitle.propTypes = {
	/** Text in the component */
	title: PropTypes.string,
	/** color of the hr */
	color: PropTypes.string,
	/** Color of the text */
	textColor: PropTypes.string,
	/** Style */
	style: PropTypes.string,
	/** Tailwind classes for the container div of the component */
	classNameContainer: PropTypes.string,
	/** Tailwind classes for the hr of the component */
	classNameHr: PropTypes.string,
};

TextSubsectionTitle.defaultProps = {
	classNameContainer: 'text-center	flex flex-col mx-auto mb-5 w-full ',
	classNameHr: 'w-full mb-8 ',
};
