//3rd party
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

//UI
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import ImageArtworkInfo from '../../molecules/Image/ImageArtworkInfo';

import {
	setBaseElements,
	setFilteredElements,
} from '../../../BusinessLogic/redux/reducers/filter';
import { multiPropsFiltersSorted } from '../Filter/filterComponents.js/helpers/helperfunction';
import { isVideoUrl } from '../../../BusinessLogic/helpers/isVideoUrl';

/**
 * @description render a grid section of postulations with details of the artowrk
 * @param {*} param0
 * @returns
 */

function SectionPostulationArtistGrid({ artworks }) {
	const dispatch = useDispatch();

	const {
		filterArraySorted,
		filteredElements,
		applyFilterByClick,
		filterArrayByClick,
		minimum,
		maximum,
		approved,
	} = useSelector((state) => state.filter);

	useEffect(() => {
		if (!artworks?.message) {
			dispatch(
				setFilteredElements(
					multiPropsFiltersSorted(
						artworks,
						filterArraySorted,
						filterArrayByClick,
						applyFilterByClick,
						minimum,
						maximum,
						approved
					)
				)
			);
			dispatch(setBaseElements(artworks));
		}
	}, [
		applyFilterByClick,
		artworks,
		dispatch,
		filterArraySorted,
		approved,
		filterArrayByClick,
		maximum,
		minimum,
	]);

	return (
		<div className={'mb-4'}>
			{filteredElements === undefined ? (
				<div className='flex justify-center'>
					<TextContentSecond text='No hay obras del artista para postular.' />
				</div>
			) : (
				<div
					className={
						'mx-auto grid w-11/12 grid-cols-2 gap-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 2xl:grid-cols-8'
					}
				>
					{artworks?.map((artwork, index) => (
						<ImageArtworkInfo
							key={index}
							imagen={
								isVideoUrl(artwork)
									? artwork?.video_url
									: artwork?.main_picture_url
							}
							title={artwork.name}
							date={artwork.date}
							dimensionsHeight={artwork.dimensions_height}
							dimensionsWidth={artwork.dimensions_width}
							dimensionsLength={artwork.dimensions_length}
							technique={artwork.technique}
							price={artwork.price}
							measure={artwork.measure}
							currency={artwork.currency}
							postulationItemId={artwork.postulation_item_id}
							materials={artwork.materials}
							isVideoImage={isVideoUrl(artwork)}
						/>
					))}
				</div>
			)}
		</div>
	);
}

SectionPostulationArtistGrid.propTypes = {
	/** Has the data to show the artwork information */
	artworks: PropTypes.array,
};

export default SectionPostulationArtistGrid;
