// 3rd Party
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// BL
import { setPortfolioDataCreationType } from '../../../BusinessLogic/redux/reducers/portfolio';

//UI
import ButtonFabPrincipalUrl from '../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import ImageAndInfo from '../../../UI/molecules/Image/ImageAndInfo';
import PopupSmall from '../../../UI/organisms/PopUp/PopupSmall';
import { ReactComponent as EyeIcon } from '../../../UI/assets/icons/Eye.svg';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

export default function TemplatesExpert() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const ref = useRef(null);
	const dispatch = useDispatch();

	const [collectionTemplate, setCollectionTemplate] = useState(null);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			if (showModal && ref.current && !ref.current.contains(e.target)) {
				setShowModal(false);
			}
		};
		document.addEventListener('mousedown', checkIfClickedOutside);
		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [showModal]);

	const handleCollection = (e) => {
		if (!ref.current.contains(e.target)) {
			if (collectionTemplate?.name === 'COLLECTION') {
				return setCollectionTemplate(null);
			}
			setCollectionTemplate({
				name: 'COLLECTION',
				route: '/plantilla/colecciones/expert',
			});
		}
	};

	const onClickButtonContinue = () => {
		navigate(collectionTemplate.route);
		dispatch(setPortfolioDataCreationType(collectionTemplate.name));
	};

	const redirectToPortfolio = () => {
		navigate('/plantilla/portafolio_basico');
	};

	return (
		<TemplateWithNavBar section={'Portafolios'}>
			<NavbarSecondaryNew
				title={t('NuevoPortafolio')}
				color='bg-yellow-50'
				textColor='text-yellow-50'
				redirect='/portafolios/plantillas'
				textBack={t('RegresarPlantillas')}
				textBackDesign='pl-3 text-yellow-50'
				showDots={false}
				arrowLeftColor='yellow'
			/>
			<div className='mx-auto w-[95%] md:w-[90%]'>
				<TextContentSecond text={t('SeleccioneMétodo')} design='py-8 md:py-4' />
				<div className='mt-4 flex flex-wrap items-end gap-8'>
					<div
						className='relative flex h-[85%] max-w-[200px] flex-col justify-end'
						onClick={handleCollection}
					>
						<div ref={ref}>
							<button
								className='absolute top-3 right-3 z-10 flex h-8 w-8 flex-col items-center justify-evenly bg-white'
								onClick={() => setShowModal(!showModal)}
							>
								<div className='h-1 w-1 rounded-full bg-gray-500' />
								<div className='h-1 w-1 rounded-full bg-gray-500' />
								<div className='h-1 w-1 rounded-full bg-gray-500' />
							</button>
							{showModal && (
								<PopupSmall design='top-6 right-4 bg-white font-light rounded-xl'>
									<div
										className='flex cursor-pointer items-center gap-x-1 rounded-xl py-2 px-2 text-xs'
										onClick={redirectToPortfolio}
									>
										<EyeIcon />
										<p>{t('VisualizarPortafolio')}</p>
									</div>
								</PopupSmall>
							)}
						</div>
						<ImageAndInfo
							title={t('Colecciones')}
							sub_header={t('PortafolioDesdeCero')}
							design='relative mx-auto'
							titleDesign='font-bold !text-sm pb-2'
							textContainerDesign='py-4 w-full'
							lazyLoadImageBorder={collectionTemplate?.name === 'COLLECTION'}
							imageFormat={false}
						/>
					</div>
				</div>
			</div>
			{collectionTemplate && (
				<div onClick={onClickButtonContinue}>
					<ButtonFabPrincipalUrl label={t('Continuar')} icon={false} />
				</div>
			)}
		</TemplateWithNavBar>
	);
}
