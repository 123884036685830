import { ListItem } from '@material-ui/core';
import { ErrorMessage, Field } from 'formik';
import InputCustomName from '../../atoms/Inputs/InputCustomName';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

export default function FieldTextFormikManagement({
	label,
	dbName,
	type = 'Text',
}) {
	return (
		<div>
			<ListItem
				className='flex flex-row'
				style={{
					paddingTop: '16px',
					paddingBottom: '16px',
					paddingLeft: '0px',
					paddingRight: '0px',
				}}
			>
				<div className='flex w-full justify-start'>
					<TextContentSecond text={label} style={{ fontWeight: 500 }} />
				</div>
				<div className='self-start'>
					<Field name={dbName} type={type} as={InputCustomName}></Field>
				</div>
			</ListItem>
			<ErrorMessage name={dbName} />
		</div>
	);
}
