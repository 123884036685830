import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

//UI
import { ReactComponent as ArrowRightGreen } from '../../assets/icons/ArrowRightGreen.svg';
import { ReactComponent as WarningRed } from '../../assets/icons/WarningRed.svg';
import { ReactComponent as CheckSquareGreen } from '../../assets/icons/CheckSquareGreen.svg';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

const CardLink = ({
	title,
	text,
	redirect,
	showErrorWarning,
	designContainer,
	titleDesign,
	redirectAction,
	redirectText,
	externalRedirect,
	titleStyle,
	showValidIcon,
	designRedirect,
	isShowArrowOnMobile,
}) => {
	return (
		<div
			className={`flex w-full items-center justify-between gap-2 py-1 ${designContainer}`}
		>
			<div className='flex flex-col gap-2'>
				<TextContentPrincipal
					design={titleDesign}
					text={title}
					style={titleStyle}
				/>
				<TextContentSecond
					className='whitespace-pre-line break-words text-xs font-light'
					text={text}
				/>
			</div>
			<div className={`flex ${designRedirect}`}>
				{showValidIcon && <CheckSquareGreen />}
				{showErrorWarning && <WarningRed />}
				<Link
					to={redirect}
					target={`${externalRedirect ? '_blank' : '_self'}`}
					onClick={() => redirectAction && redirectAction()}
				>
					{!redirectText && redirect && (
						<ArrowRightGreen data-test='arrow-icon' />
					)}
					{isShowArrowOnMobile && (
						<div className='sm:hidden'>
							<ArrowRightGreen data-test='arrow-icon' />
						</div>
					)}
					{redirectText && redirect && (
						<TextContentPrincipal
							design={`text-xs font-light break-words whitespace-pre-line underline text-blue-500 ${
								isShowArrowOnMobile && 'hidden sm:block'
							}`}
							text={`${redirectText}`}
						/>
					)}
				</Link>
			</div>
		</div>
	);
};

CardLink.propTypes = {
	/** Text in the component */
	title: PropTypes.string,
	/** text is the paragraph of our component */
	text: PropTypes.string,
	/** redirects to the section that we pass*/
	redirect: PropTypes.string,
	/** Determines if the error warning should be shown*/
	showErrorWarning: PropTypes.bool,
	/** styles for the component container */
	designContainer: PropTypes.string,
	/** styles for component title */
	titleDesign: PropTypes.string,
	/** Action when redirect */
	redirectAction: PropTypes.func,
	/** Title style */
	titleStyle: PropTypes.object,
	/** Boolean that determines if the valid icon should be displayed */
	showValidIcon: PropTypes.bool,
	/** Styles to redirect container */
	designRedirect: PropTypes.string,
};

CardLink.defaultProps = {
	showErrorWarning: false,
	titleStyle: { fontWeight: 600 },
};

export default CardLink;
