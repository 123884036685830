import TextContentThird from '../../atoms/Text/Content/TextContentThird';

export function DetailsOrganization({
	title,
	data,
	className,
	designOne,
	designTwo,
	style,
}) {
	return (
		<div className={className}>
			<TextContentThird text={title} design={designOne} style={style} />
			<TextContentThird text={data} design={designTwo} />
		</div>
	);
}
