import { fetchPostData } from '../hooks/fetchPostData';
import { fetchPostVideo } from '../hooks/fetchPostVideo';

export default async function postDataAndVideo(ENDPOINT, values) {
	const res = await fetchPostVideo(values);
	values = {
		...values,
		main_picture_id: res.id,
	};
	return fetchPostData(ENDPOINT, values);
}
