import * as Yup from 'yup';

//Single location general tabs information
const singleLocationGeneralTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'labels',
				field: 'identification',
				information: [
					{ label: 'Nombre', value: 'Lorem Ipsum', field: 'name' },
					{ label: 'Detalles', value: 'Lorem Ipsum', field: 'details' },
					{
						label: 'Contacto',
						value: 1,
						field: 'contact_person_id',
						type: 'dropdown',
						options: [{ value: 1, label: 'Test' }],
					},
				],
			},
			{
				title: 'Ubicación',
				type: 'cityCountry',
				field: 'identification',
				information: [
					{ label: 'Dirección', value: 'address', field: 'address' },
					{ label: 'CódigoPostal', value: 'postcode', field: 'postcode' },
					{
						label: 'País',
						value: '',
						field: 'country_id',
						type: 'dropdown',
						options: [],
					},
					{
						label: 'Ciudad',
						value: '',
						field: 'city_id',
						type: 'dropdown',
						options: [],
					},
				],
			},
		],
	},
	{
		tab: 'Obras',
		type: 'Obras',
		content: [
			{
				title: 'Obras',
				type: 'images',
				field: 'artworks',
				information: [],
			},
		],
	},
	{
		tab: 'Salas',
		type: 'Salas',
		content: [
			{
				title: 'Salas',
				type: 'images',
				field: 'locations_room',
				information: [],
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const singleLocationGeneralButtons = [
	'Identificación',
	'Obras',
	'Salas',
	'Imágenes',
];

const LocationGeneralValidationSchema = [
	//this needs to have the same order than the buttons
	[
		//and the same order than the appearance of the content sections
		//far from elegant
		{
			name: Yup.string().required('NombreEsNecesario'),
		},
	],
];

const locationGeneralFormValidationSchema = Yup.object({
	name: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('NombreEsNecesario'),
	address: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
	country_id: Yup.string().required('PaísEsNecesario'),
	city_id: Yup.string().required('CiudadEsNecesario'),
	contact_person_id: Yup.string().required('ContactoEsNecesario'),
});

export {
	singleLocationGeneralTabs,
	singleLocationGeneralButtons,
	LocationGeneralValidationSchema,
	locationGeneralFormValidationSchema,
};
