//3rd party components
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

//icons
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import UnderlineHr from '../../atoms/UnderLine/UnderlineHr';
import PopupIconAndOptions from '../../organisms/PopUp/PopupIconAndOptions';
import { ReactComponent as Pencil } from '../../assets/icons/pencil.svg';
import { ReactComponent as Add } from '../../assets/icons/add.svg';
import { ReactComponent as DotsVertical } from '../../assets/icons/DotsVertical.svg';
import { ReactComponent as AddProfileGrey } from '../../assets/icons/AddProfileGrey.svg';

/**
 * @description render a text subtitle and feature to add , edit, others
 * @param {*} param0
 * @returns
 */

const TextSubTitle = ({
	text,
	section,
	colour,
	setTurnEdit,
	turnEdit,
	hasEdit,
	hasAdd,
	hasDots,
	style,
	AddIconLink,
	showDotsPopup,
	setShowDotsPopup,
	dotsOptionsInTab,
	dotsOptionsInArtworksTab,
	editIconOptions,
	showEditPopup,
	setShowEditPopup,
	saveLastSectionURL,
	saveURLHistory,
	hasInviteUser,
	hideEdit,
	isDefaultGeneralCollection,
}) => {
	const location = useLocation();
	const isCollectionPage = location.pathname.includes('colecciones');
	const isDocumentTab = text === 'Documentos' || text === 'Documents';
	const isImagenTab = text === 'Imágenes' || text === 'Images';
	const isArtworkTab = text === 'Obras' || text === 'Artworks';
	const isArtworkTabInCollectionPage = isCollectionPage && isArtworkTab;

	//manage the color
	let textColor = '';
	if (colour === 'yellow') {
		textColor = 'text-yellow-50';
	} else if (colour === 'purple') {
		textColor = 'text-purple-50';
	} else if (colour === 'green') {
		textColor = 'text-green-50';
	} else if (colour === 'light_green') {
		textColor = 'text-light_green-50';
	}

	const handleIconAction = () => {
		if (isImagenTab) {
			setShowEditPopup(!showEditPopup);
			setShowDotsPopup(false);

			return;
		}
		if (isDocumentTab) {
			setShowEditPopup(!showEditPopup);
			return;
		}
		setTurnEdit(!turnEdit);
	};
	const handleSaveURLHistory = () => {
		saveURLHistory();
	};
	return (
		<div className={` mx-auto mb-5 w-full py-3`} style={style}>
			<div className='flex flex-row justify-between'>
				<TextTitleSub
					title={text}
					textColor={textColor}
					design='text-left h-8 leading-8'
				/>
				<div className='flex flex-row align-middle'>
					{hasEdit && showEditPopup && (
						<PopupIconAndOptions
							popupOptions={editIconOptions}
							design={'hover:bg-gray-100'}
							showPopup={showEditPopup}
							setShowPopup={setShowEditPopup}
						/>
					)}

					{hasEdit && !hideEdit && !isDocumentTab && (
						<div className='flex h-8 w-8 cursor-pointer rounded-full hover:bg-gray-200'>
							{isImagenTab ? (
								<Add
									className='m-auto'
									aria-label='add icon'
									onClick={() => handleIconAction()}
								/>
							) : (
								<Pencil
									className='m-auto'
									aria-label='pencil icon'
									onClick={() => handleIconAction()}
								/>
							)}
						</div>
					)}
					{(hasAdd || isArtworkTabInCollectionPage) &&
						!isDefaultGeneralCollection && (
							<div
								onClick={() =>
									saveLastSectionURL && saveURLHistory && handleSaveURLHistory()
								}
							>
								<Link
									to={AddIconLink}
									className='flex h-8 w-8 cursor-pointer justify-center rounded-full hover:bg-gray-200'
								>
									<Add
										className='my-auto'
										aria-label='add icon'
										data-test='section-title-add-icon'
									/>{' '}
								</Link>
							</div>
						)}
					{hasDots && showDotsPopup && (
						<PopupIconAndOptions
							popupOptions={
								section === 'Collection'
									? dotsOptionsInArtworksTab
									: dotsOptionsInTab
							}
							design={'hover:bg-gray-100'}
							showPopup={showDotsPopup}
							setShowPopup={setShowDotsPopup}
						/>
					)}
					{hasDots && (
						<DotsVertical
							className='cursor-pointer rounded-full hover:bg-gray-200'
							aria-label='Dots icon'
							onClick={() => {
								setShowDotsPopup(!showDotsPopup);
								setShowEditPopup(false);
							}}
						/>
					)}
					{hasInviteUser && (
						<div className='rounded-full hover:bg-gray-200'>
							<Link to={'/management/configuracion/user/nuevo'}>
								<AddProfileGrey />
							</Link>
						</div>
					)}
				</div>
			</div>
			<UnderlineHr colour={colour} />
		</div>
	);
};

TextSubTitle.propTypes = {
	/** Text in the component */
	text: PropTypes.string,
	/** colour dropdown */
	colour: PropTypes.string,
	/** Tailwind design */
	design: PropTypes.string,
	/** Style */
	style: PropTypes.string,
	/** Shows an edit if true */
	hasEdit: PropTypes.bool,
	/** Shows an add icon if true */
	hasAdd: PropTypes.bool,
	/**  bool that handle to save the actual specific location */
	saveLastSectionURL: PropTypes.bool,
	/* function that execute to save the actual url in redux state */
	saveURLHistory: PropTypes.func,
	/* handle the feature to invite a user in the field section */
	hasInviteUser: PropTypes.bool,
	/* shows or not the Edit feature */
	hideEdit: PropTypes.bool,
	/**Boolean prop that determines if the subtitle is located on default general collection*/
	isDefaultGeneralCollection: PropTypes.bool,
};

TextSubTitle.defaultProps = {
	hasEdit: true,
	hasAdd: true,
	saveLastSectionURL: false,
	hasInviteUser: false,
	hideEdit: false,
};

export default TextSubTitle;
