/* Icons */
import { ReactComponent as Share } from '../../../../UI/assets/icons/ShareNetwork.svg';

export default function ButtonLinkShare({
	design,
	redirect,
	popUpShare,
	setPopUpShare,
	isPublic,
}) {
	return (
		<div
			onClick={() =>
				redirect === 'colecciones' && !isPublic && setPopUpShare(!popUpShare)
			}
		>
			<figure className='h-8 w-8'>
				<Share fill='none' stroke={design} />
			</figure>
		</div>
	);
}
