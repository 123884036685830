//variables used to manage the dynamic forms
//javacript utils - move this to a generic file
//this allows to manipulate dictionaries and can be useful in other scenarios
function get_unique_fields_from_key_in_dict(dict, key) {
	//gets unique fields for a key in dict
	let unique_values = [...new Set(dict.map((x) => x[key]))];
	return unique_values;
}

function get_unique_values_from_key_in_dict(dict, key) {
	//gets unique values for a key in dict
	let unique_values = [...new Map(dict.map((x) => [x[key], x])).values()];
	return unique_values;
}

function get_values_equal_to_y_in_dict(dict, key, y) {
	//gets values in dict that have a specific key equal to y
	return dict.filter(function (loc) {
		return loc[key] === y;
	});
}

function getArtworkFilterList(artworksData, filterValue) {
	let listValues = [];
	if (Array.isArray(artworksData) && artworksData.length > 0) {
		listValues = [...new Set(artworksData.map((item) => item[filterValue]))];
	}
	listValues = listValues.filter((item) => item !== '');

	if (filterValue === 'date') {
		listValues = listValues.map((item) => {
			let date = new Date(item);
			let year = date.getFullYear();

			if (!isNaN(year) && year >= 1000 && year <= 9999) {
				return year;
			}
			return null;
		});
	}
	if (filterValue === 'materials') {
		if (typeof navigator !== 'undefined') {
			const defaultLocale = navigator.language || navigator.userLanguage;

			listValues = listValues.map((item) => {
				let localizedItem = item?.toLocaleString(defaultLocale, {
					sensitivity: 'base',
				});

				return localizedItem;
			});
		}
	}

	listValues = listValues.filter((item) => item !== undefined);
	listValues = listValues.filter((item) => item !== null);
	listValues.sort();

	return listValues;
}

export {
	get_unique_fields_from_key_in_dict,
	get_unique_values_from_key_in_dict,
	get_values_equal_to_y_in_dict,
	getArtworkFilterList,
};
