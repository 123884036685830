//3rd
import { Field, Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//UI
import FilterSidebar from './FilterSidebar';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

const FilterCallPostulations = ({
	initialFilterParameters,
	filterParameters,
	setFilterParameters,
}) => {
	const { t } = useTranslation();
	const [showFilter, setShowFilter] = useState(false);

	return (
		<FilterSidebar showFilter={showFilter} setShowFilter={setShowFilter}>
			<Formik
				initialValues={filterParameters}
				onSubmit={(values) => {
					setFilterParameters(values);
					setShowFilter(false);
				}}
				onReset={() => {
					setFilterParameters(initialFilterParameters);
					setShowFilter(false);
				}}
			>
				{(formProps) => (
					<div className='flex flex-col gap-3 px-3 pt-8'>
						<div className='flex flex-col gap-3'>
							<TextContentSecond
								text={t('OrdenarPor')}
								style={{ fontWeight: 500 }}
							/>
							<Field name='sorting'>
								{({ field }) => (
									<select
										{...field}
										className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
									>
										<option value=''></option>
										<option value='date_ascending'>
											{t('MásRecientesPrimero')}
										</option>
										<option value='date_descending'>
											{t('MásAntiguasPrimero')}
										</option>
										<option value='name_ascending'>{t('ArtistaAZ')}</option>
										<option value='name_descending'>{t('ArtistaZA')}</option>
									</select>
								)}
							</Field>
						</div>
						<div className='flex flex-col gap-3'>
							<TextContentPrincipal
								text={t('FiltrarPor')}
								style={{ fontWeight: 550 }}
								design='my-3'
							/>
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={t('EstadoPostulación')}
									style={{ fontWeight: 500 }}
								/>
								<Field name='status'>
									{({ field }) => (
										<select
											{...field}
											className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
										>
											<option value=''></option>
											<option value='SUBMITTED'>{t('Nueva')}</option>
											<option value='APPROVED'>{t('Aprobada')}</option>
											<option value='REJECTED'>{t('Rechazada')}</option>
											<option value='REVIEWED'>{t('Revisada')}</option>
											<option value='READ'>{t('Leída')}</option>
											<option value='SAVED'>{t('Guardada')}</option>
										</select>
									)}
								</Field>
							</div>
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={t('FechaAplicación')}
									style={{ fontWeight: 500 }}
								/>
								<Field
									type='date'
									name='date'
									className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
								/>
							</div>
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={t('NombreArtista')}
									style={{ fontWeight: 500 }}
								/>
								<Field
									type='input'
									name='name'
									className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
								/>
							</div>
						</div>
						<div className='my-5 flex flex-row justify-center gap-5'>
							<ButtonFab
								label={t('LimpiarFiltro')}
								size={'medium'}
								colour='white'
								action={() => formProps.resetForm()}
							/>
							<ButtonFab
								label={t('Aplicar')}
								size={'medium'}
								action={() => formProps.submitForm()}
							/>
						</div>
					</div>
				)}
			</Formik>
		</FilterSidebar>
	);
};

FilterCallPostulations.propTypes = {
	//** Initial values of the filter form */
	initialFilterParameters: PropTypes.object,
	//** Current values of the filter form */
	filterParameters: PropTypes.object,
	/** Function to update the current values of the filter form  */
	setFilterParameters: PropTypes.func,
};

FilterCallPostulations.defaultProps = {
	initialFilterParameters: {},
	filterParameters: {},
};

export default FilterCallPostulations;
