// 3rd Party
import { Image, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

// BL
import { getHttpRequestDefaultOptions } from '../../../../../BusinessLogic/helpers/getHttpRequestOptions';

const styles = StyleSheet.create({
	image: {
		objectFit: 'contain',
		height: '100%',
		width: '100%',
	},
});

export default function DownloadableExpertSlideItemImage({
	imageURL,
	stylesImage,
}) {
	return (
		<Image
			src={getHttpRequestDefaultOptions(imageURL)}
			style={[styles.image, stylesImage]}
		/>
	);
}

DownloadableExpertSlideItemImage.propTypes = {
	// image url
	imageURL: PropTypes.string,
	// css styles for image
	stylesImage: PropTypes.object,
};
