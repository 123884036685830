import TitleFormSections from '../../atoms/Text/Titles/TitleFormSections';

export default function SectionForm({
	children,
	title,
	color,
	textColor,
	containerDesign,
}) {
	return (
		<div className={`mb-8 w-full ${containerDesign}`}>
			<TitleFormSections title={title} textColor={textColor} color={color} />
			{children}
		</div>
	);
}
