//BL
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_PORTFOLIO } from '../../helpers/routes';
import { fetchGetData } from '../../hooks/fetchGetData';
import { fetchPostData } from '../../hooks/fetchPostData';
import { fetchPutData } from '../../hooks/fetchPutData';
import { updateTabsObject } from '../../helpers/SingleViewHelpers';
import { portfolioSingleTabs } from '../../data/PortfolioSingle';
import { fetchDeleteElement } from '../../hooks/fetchDeleteElement';
import { stringToSlug } from '../../helpers/textFormat';
import { sortItemsByOrder } from '../../helpers/sortData';

//1. post portfolios
export const postPortfolio = createAsyncThunk(
	'portfolio/postPortfolio',
	async (values) => {
		return await fetchPostData(ENDPOINTS_PORTFOLIO.POST_PORTFOLIO, values);
	}
);

//2. get portfolios by organizations
export const getPortfolios = createAsyncThunk(
	'portfolio/getPortfolios',
	async () => {
		const { organization_user_id } = localStorage;
		return await fetchGetData(
			`${ENDPOINTS_PORTFOLIO.GET_PORTFOLIOS}${organization_user_id}/`
		);
	}
);

//3. put portfolios
export const putPortfolio = createAsyncThunk(
	'portfolio/putPortfolio',
	async (input) => {
		const { id, values } = input;
		return await fetchPutData(
			`${ENDPOINTS_PORTFOLIO.PUT_PORTFOLIO}${id}/`,
			values
		);
	}
);

//4. get portfolio
export const getPortfolio = createAsyncThunk(
	'portfolio/getPortfolio',
	async (id) => {
		return await fetchGetData(`${ENDPOINTS_PORTFOLIO.GET_PORTFOLIO}${id}/`);
	}
);

//5. get portfolios by slug
export const getPortfolioBySlug = createAsyncThunk(
	'portfolio/getPortfolioBySlug',
	async (slug) => {
		return await fetchGetData(
			`${ENDPOINTS_PORTFOLIO.GET_PORTFOLIO_BY_SLUG_V2}${slug}/`
		);
	}
);

//6. delete portfolio by id
export const deletePortfolioById = createAsyncThunk(
	'portfolio/deletePortfolioById',
	async (id) => {
		return await fetchDeleteElement(
			`${ENDPOINTS_PORTFOLIO.DELETE_PORTFOLIO}${id}/`
		);
	}
);

//7. post expert portfolio
export const postExpertPortfolio = createAsyncThunk(
	'portfolio/postExpertPortfolio',
	async (values) => {
		return await fetchPostData(ENDPOINTS_PORTFOLIO.POST_PORTFOLIO_V2, values);
	}
);

// --- SLIDES

//GET EXPERT SLIDES
export const getExpertPortfolioSlides = createAsyncThunk(
	'portfolio/getSlides',
	async (id) => {
		return await fetchGetData(
			`${ENDPOINTS_PORTFOLIO.GET_EXPERT_PORTFOLIO_SLIDES}${id}`
		);
	}
);
//PUT ORDER - EXPERT SLIDES
export const putExpertPortfolioSlidesOrder = createAsyncThunk(
	'portfolio/putSlidesOrder',
	async (values) => {
		const { portfolioId, newOrderedSlidesValues } = values;
		return await fetchPutData(
			`${ENDPOINTS_PORTFOLIO.PUT_EXPERT_PORTFOLIO_SLIDES_ORDER}${portfolioId}`,
			newOrderedSlidesValues
		);
	}
);
//POST EXPERT SLIDE
export const postExpertPortfolioSlide = createAsyncThunk(
	'portfolio/postSlide',
	async (values) => {
		return await fetchPostData(
			`${ENDPOINTS_PORTFOLIO.POST_EXPERT_PORTFOLIO_SLIDE}`,
			values
		);
	}
);
//DELETE EXPERT SLIDE
export const deleteExpertPortfolioSlide = createAsyncThunk(
	'portfolio/deleteSlide',
	async (id) => {
		return await fetchDeleteElement(
			`${ENDPOINTS_PORTFOLIO.DELETE_EXPERT_PORTFOLIO_SLIDE}${id}`
		);
	}
);
// --- ITEM
//POST EXPERT ITEM
export const postExpertPortfolioSlideItem = createAsyncThunk(
	'portfolio/postExpertPortfolioSlideItem',
	async (values) => {
		return await fetchPostData(
			`${ENDPOINTS_PORTFOLIO.POST_EXPERT_PORTFOLIO_SLIDES_ITEM}`,
			values
		);
	}
);
//POST EXPERT ARTWORK ITEM
export const postExpertPortfolioSlideArtworkItem = createAsyncThunk(
	'portfolio/postExpertPortfolioSlideArtworkItem',
	async (values) => {
		return await fetchPostData(
			`${ENDPOINTS_PORTFOLIO.POST_EXPERT_PORTFOLIO_SLIDE_ARTWORK_ITEM}`,
			values
		);
	}
);
//PUT EXPERT ARTWORK ITEM TO ARTWORK

export const putExpertPortfolioArtworkToArtwork = createAsyncThunk(
	'portfolio/putExpertPortfolioArtworkToArtwork',
	async ({ itemId, ArtworkId }) => {
		return await fetchPutData(
			`${ENDPOINTS_PORTFOLIO.PUT_EXPERT_PORTFOLIO_SLIDE_ARTWORK_ITEM_TO_ARTWORK}${itemId}`,
			{ ArtworkId }
		);
	}
);
//PUT ORDER ITEMS - EXPERT SLIDE ITEM
export const putExpertPortfolioSlideItemsOrder = createAsyncThunk(
	'portfolio/putExpertPortfolioSlideItemsOrder',
	async (values) => {
		const { slideId, newOrderedItems } = values;
		return await fetchPutData(
			`${ENDPOINTS_PORTFOLIO.PUT_EXPERT_PORTFOLIO_SLIDE_ITEMS_ORDER}${slideId}`,
			newOrderedItems
		);
	}
);
//DELETE EXPERT ITEM
export const deleteExpertPortfolioSlideItem = createAsyncThunk(
	'portfolio/deleteExpertPortfolioSlideItem',
	async (id) => {
		return await fetchDeleteElement(
			`${ENDPOINTS_PORTFOLIO.DELETE_EXPERT_PORTFOLIO_SLIDES_ITEM}${id}`
		);
	}
);
// --- ELEMENT
//POST EXPERT ITEM ELEMENT
export const postExpertPortfolioSlideItemElement = createAsyncThunk(
	'portfolio/postExpertPortfolioSlideItemElement',
	async (values) => {
		return await fetchPostData(
			`${ENDPOINTS_PORTFOLIO.PUT_EXPERT_PORTFOLIO_SLIDE_ITEM_ELEMENT}`,
			values
		);
	}
);
//PUT EXPERT ITEM ELEMENT
export const putExpertPortfolioSlideItemElement = createAsyncThunk(
	'portfolio/putExpertPortfolioSlideItemElement',
	async ({ id, values }) => {
		return await fetchPutData(
			`${ENDPOINTS_PORTFOLIO.PUT_EXPERT_PORTFOLIO_SLIDE_ITEM_ELEMENT}${id}`,
			values
		);
	}
);

//8. put expert portfolio
export const putExpertPortfolio = createAsyncThunk(
	'portfolio/updateExpertPortfolio',
	async (input) => {
		const { id, values } = input;
		return await fetchPutData(
			`${ENDPOINTS_PORTFOLIO.POST_PORTFOLIO_V2}${id}/`,
			values
		);
	}
);

//9. get expert portfolio
export const getExpertPortfolio = createAsyncThunk(
	'portfolio/getExpertPortfolio',
	async (id) => {
		return await fetchGetData(`${ENDPOINTS_PORTFOLIO.GET_PORTFOLIO_V2}${id}/`);
	}
);

//10. post portfolio (also work with expert portfolio) slug repeat
export const postPortfolioSlugRepeat = createAsyncThunk(
	'portfolio/postPortfolioSlugRepeat',
	async (slug) => {
		const checkSlug = {
			slug: slug,
		};
		return await fetchPostData(ENDPOINTS_PORTFOLIO.POST_SLUG_REPEAT, checkSlug);
	}
);

const initialStatePortfolioTabs = portfolioSingleTabs;

const initialSinglePortfolio = {
	created_at: '2021-12-08 09:20:42',
	id: 7,
	image_quality: 'HIGH',
	information: {
		content: [
			{
				label: 'Title',
				value: 'Años 70s a los 80s (Colección privada)',
			},
			{
				label: 'Url',
				value: 'claravaldenebro_70a80s',
			},
			{
				label: 'Acceso',
				value: 'Ninguno',
			},
			{
				label: 'Resolución',
				value: 'HIGH',
			},
		],
	},
	is_deleted: false,
	main_picture_url:
		'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5228',
	name: 'Años 70s a los 80s (Colección privada)',
	organization_user_id: 31,
	slug: 'claravaldenebro_70a80s',
	type: 'COLLECTION',
	type_id: 109,
	updated_at: null,
};

const initialPortfolioSingleTabs = updateTabsObject(
	portfolioSingleTabs,
	initialSinglePortfolio
);

const initialState = {
	initialStateTabs: initialStatePortfolioTabs,
	singlePortfolio: initialSinglePortfolio,
	portfolioSingleTabs: initialPortfolioSingleTabs,
	portfolioDataCreation: {},
	portfolios: null,
	portfolioPublic: {},
	loadingPortfolio: false,
	portfolioPreview: false,
	portfoliosChanges: 0,
	uniqueIdChosen: null,
	uniqueIdCollection: null,
	portfolioChanges: 0,
	initialPortfolioChanges: 0,
	currentPlantilla: '/plantilla/portafolio_basico',
	portfolioPreviewName: null,
	portfolioPreviewSlug: null,
	slides: null,
	expertPortfolioViewAndSelection: {
		currentView: null,
		selectedElementId: null,
		selectedItemId: null,
		selectedSlideId: null,
		isReordering: null,
	},
	showSlideModalExpertPortfolio: {
		isVisible: false,
		modalView: '',
	},
};

export const portfolioSlice = createSlice({
	name: 'portfolios',
	initialState,
	reducers: {
		setUniqueIdChosen: (state, { payload }) => {
			state.uniqueIdChosen = payload;
		},
		setUniqueIdCollection: (state, { payload }) => {
			state.uniqueIdCollection = payload;
			state.portfolioPreview = true;
		},
		setCurrentPlantilla: (state, { payload }) => {
			state.currentPlantilla = payload;
		},
		deleteAllPortfolio: (state, { payload }) => {
			state.portfolios = null;
		},
		increasePortfolioChanges: (state, { payload }) => {
			state.portfolioChanges += 1;
		},
		increaseInitialPortfolioChanges: (state, { payload }) => {
			state.initialPortfolioChanges = state.portfolioChanges;
		},
		setPortfolioPreviewName: (state, { payload }) => {
			state.portfolioPreviewName = payload;
		},
		setPortfolioDataCreationIdCollection: (state, { payload }) => {
			state.portfolioDataCreation.idCollection = payload;
		},
		setPortfolioDataCreationPortfolioType: (state, { payload }) => {
			state.portfolioDataCreation.portfolioType = payload;
		},
		setPortfolioDataCreationType: (state, { payload }) => {
			state.portfolioDataCreation.type = payload;
		},
		emptyAllFieldsPortfolioDataCreation: (state) => {
			state.portfolioDataCreation.idCollection = null;
			state.portfolioDataCreation.portfolioType = null;
			state.portfolioDataCreation.type = null;
		},
		setPortfolioPreviewSlug: (state, { payload }) => {
			if (typeof payload === 'string') {
				state.portfolioPreviewSlug = stringToSlug(payload.toLowerCase());
			} else {
				state.portfolioPreviewSlug = payload;
			}
		},
		setCurrentViewExpertPortfolioAndSelection: (state, { payload }) => {
			state.expertPortfolioViewAndSelection = payload;
		},
		setShowSlideModalExpertPortfolio: (state, { payload }) => {
			state.showSlideModalExpertPortfolio = payload;
		},
		setShowModalCreation: (state, { payload }) => {
			state.singlePortfolio.show_modal_creation = payload;
		},
	},

	extraReducers: {
		//1. post portfolios
		[postPortfolio.pending]: (state, action) => {
			state.status = 'loading';
			state.loadingPortfolio = true;
			state.portfolioPreview = false;
		},
		[postPortfolio.fulfilled]: (state, { payload }) => {
			state.portfolios = null;
			state.status = 'success';
			state.portfolioChanges += 1;
			state.loadingPortfolio = false;
			state.portfolioPreview = false;
			state.portfolioPreviewName = null;
			state.portfolioPreviewSlug = null;
		},
		[postPortfolio.rejected]: (state, action) => {
			state.status = 'failed';
			state.loadingPortfolio = false;
		},

		//2. get portfolios by organization id
		[getPortfolios.pending]: (state, action) => {
			state.status = 'loading';
			state.loadingPortfolio = true;
			state.portfolioPreview = false;
		},
		[getPortfolios.fulfilled]: (state, { payload }) => {
			let portfolios = payload.portfolios;
			const defaultPortfolio =
				Array.isArray(payload) &&
				payload.portfolios.find((portfolio) => portfolio.is_general_portfolio);
			if (defaultPortfolio) {
				const filteredPortfolios = portfolios.filter(
					(portfolio) => portfolio !== defaultPortfolio
				);
				portfolios = [defaultPortfolio, ...filteredPortfolios];
			}
			state.portfolios = portfolios;
			state.status = 'success';
			state.loadingPortfolio = false;
			state.portfolioPreview = false;
		},
		[getPortfolios.rejected]: (state, action) => {
			state.status = 'failed';
			state.loadingPortfolio = false;
		},

		//3. put portfolios
		[putPortfolio.pending]: (state, action) => {
			state.status = 'loading';
			state.loadingPortfolio = true;
		},
		[putPortfolio.fulfilled]: (state, { payload }) => {
			state.singlePortfolio = payload;
			state.status = 'success';
			state.loadingPortfolio = false;
			state.portfolioChanges += 1;
		},
		[putPortfolio.rejected]: (state, action) => {
			state.status = 'failed';
			state.loadingPortfolio = false;
		},

		//4. get portfolio by id
		[getPortfolio.pending]: (state, action) => {
			state.portfolioSingleTabs = updateTabsObject(
				state.initialStateTabs,
				initialSinglePortfolio
			);
			state.status = 'loading';
			state.loadingPortfolio = true;
		},
		[getPortfolio.fulfilled]: (state, { payload }) => {
			let Single = payload;
			state.singlePortfolio = Single;
			state.portfolioSingleTabs = updateTabsObject(
				state.portfolioSingleTabs,
				Single
			);
			state.status = 'success';
			state.loadingPortfolio = false;
		},
		[getPortfolio.rejected]: (state, action) => {
			state.status = 'failed';
			state.loadingPortfolio = false;
		},

		//5. get portfolio by slug
		[getPortfolioBySlug.pending]: (state, action) => {
			state.status = 'loading';
			state.loadingPortfolio = true;
		},
		[getPortfolioBySlug.fulfilled]: (state, { payload }) => {
			state.portfolioPublic = payload?.portfolio;
			state.status = 'success';
			state.loadingPortfolio = false;
		},
		[getPortfolioBySlug.rejected]: (state, action) => {
			state.status = 'failed';
			state.loadingPortfolio = false;
		},

		//6. de;ete portfolio by by
		[deletePortfolioById.pending]: (state) => {
			state.status = 'loading';
		},
		[deletePortfolioById.fulfilled]: (state) => {
			state.status = 'success';
		},
		[deletePortfolioById.rejected]: (state) => {
			state.status = 'failed';
		},

		//7. post expert portfolio
		[postExpertPortfolio.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.portfolios = payload.all_portfolio;
			state.singlePortfolio = payload.single_portfolio;
			state.singlePortfolio.show_modal_creation = true;
			state.portfolioDataCreation = {};
		},
		[postExpertPortfolio.pending]: (state) => {
			state.status = 'loading';
		},
		[postExpertPortfolio.rejected]: (state) => {
			state.status = 'failed';
		},

		//8. put expert portfolio
		[putExpertPortfolio.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.portfolios = payload.all_portfolio;
			state.singlePortfolio = payload.single_portfolio;
		},
		[putExpertPortfolio.pending]: (state) => {
			state.status = 'loading';
		},
		[putExpertPortfolio.rejected]: (state) => {
			state.status = 'failed';
		},

		//9. get portfolio by slug
		[getExpertPortfolio.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getExpertPortfolio.fulfilled]: (state, { payload }) => {
			state.singlePortfolio = payload;
			state.status = 'success';
		},
		[getExpertPortfolio.rejected]: (state, action) => {
			state.status = 'failed';
		},

		//Get expert portfolio Slides
		[getExpertPortfolioSlides.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getExpertPortfolioSlides.fulfilled]: (state, { payload }) => {
			state.slides = sortItemsByOrder(payload);
			state.status = 'success';
		},
		[getExpertPortfolioSlides.rejected]: (state, action) => {
			state.status = 'failed';
		},

		//Delete expert portfolio slide
		[deleteExpertPortfolioSlide.pending]: (state) => {
			state.status = 'loading';
		},
		[deleteExpertPortfolioSlide.fulfilled]: (state, { payload }) => {
			state.singlePortfolio = payload;
			state.slides = sortItemsByOrder(payload.slides);
			state.status = 'success';
		},
		[deleteExpertPortfolioSlide.rejected]: (state) => {
			state.status = 'failed';
		},

		//Create expert portfolio slide
		[postExpertPortfolioSlide.pending]: (state) => {
			state.status = 'loading';
		},
		[postExpertPortfolioSlide.fulfilled]: (state, { payload }) => {
			state.singlePortfolio = payload.single_portfolio;
			state.slides = sortItemsByOrder(payload.single_portfolio.slides);
			state.status = 'success';
		},
		[postExpertPortfolioSlide.rejected]: (state) => {
			state.status = 'failed';
		},

		//Put expert portfolio slides reordered
		[putExpertPortfolioSlidesOrder.pending]: (state) => {
			state.status = 'loading';
		},
		[putExpertPortfolioSlidesOrder.fulfilled]: (state, { payload }) => {
			state.singlePortfolio = payload.single_portfolio;
			state.slides = sortItemsByOrder(payload.single_portfolio.slides);
			state.status = 'success';
		},
		[putExpertPortfolioSlidesOrder.rejected]: (state) => {
			state.status = 'failed';
		},
		//POST EXPERT - SLIDE ITEM
		[postExpertPortfolioSlideItem.pending]: (state) => {
			state.status = 'loading';
		},
		[postExpertPortfolioSlideItem.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.singlePortfolio = payload.single_portfolio;
			state.slides = sortItemsByOrder(payload.single_portfolio.slides);
		},
		[postExpertPortfolioSlideItem.rejected]: (state) => {
			state.status = 'failed';
		},
		//POST EXPERT - SLIDE ARTWORK ITEM
		[postExpertPortfolioSlideArtworkItem.pending]: (state) => {
			state.status = 'loading';
		},
		[postExpertPortfolioSlideArtworkItem.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.singlePortfolio = payload.single_portfolio;
			state.slides = sortItemsByOrder(payload.single_portfolio.slides);
		},
		[postExpertPortfolioSlideArtworkItem.rejected]: (state) => {
			state.status = 'failed';
		},
		//PUT EXPERT - SLIDE ARTWORK ITEM TO ARTWORK
		[putExpertPortfolioArtworkToArtwork.pending]: (state) => {
			state.status = 'loading';
		},
		[putExpertPortfolioArtworkToArtwork.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.singlePortfolio = payload.single_portfolio;
			state.slides = sortItemsByOrder(payload.single_portfolio.slides);
		},
		[putExpertPortfolioArtworkToArtwork.rejected]: (state) => {
			state.status = 'failed';
		},
		//PUT EXPERT ITEM ORDER - SLIDE ITEM ORDER
		[putExpertPortfolioSlideItemsOrder.pending]: (state) => {
			state.status = 'loading';
		},
		[putExpertPortfolioSlideItemsOrder.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.singlePortfolio = payload.single_portfolio;
			state.slides = sortItemsByOrder(payload.single_portfolio.slides);
		},
		[putExpertPortfolioSlideItemsOrder.rejected]: (state) => {
			state.status = 'failed';
		},
		//Delete EXPERT - SLIDE ITEM
		[deleteExpertPortfolioSlideItem.pending]: (state) => {
			state.status = 'loading';
		},
		[deleteExpertPortfolioSlideItem.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.singlePortfolio = payload;
			state.slides = sortItemsByOrder(payload.slides);
		},
		[deleteExpertPortfolioSlideItem.rejected]: (state) => {
			state.status = 'failed';
		},
		//POST EXPERT - SLIDE ITEM ELEMENT
		[postExpertPortfolioSlideItemElement.pending]: (state, action) => {
			state.status = 'loading';
		},
		[postExpertPortfolioSlideItemElement.fulfilled]: (state, { payload }) => {
			state.status = 'success';
		},
		[postExpertPortfolioSlideItemElement.rejected]: (state) => {
			state.status = 'failed';
		},
		//PUT EXPERT - SLIDE ITEM ELEMENT
		[putExpertPortfolioSlideItemElement.pending]: (state) => {
			state.status = 'loading';
		},
		[putExpertPortfolioSlideItemElement.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.singlePortfolio = payload.single_portfolio;
			state.slides = sortItemsByOrder(payload.single_portfolio.slides);
			state.portfolios = null;
		},
		[putExpertPortfolioSlideItemElement.rejected]: (state) => {
			state.status = 'failed';
		},
	},
});

export const {
	setUniqueIdChosen,
	setUniqueIdCollection,
	setCurrentPlantilla,
	deleteAllPortfolio,
	increasePortfolioChanges,
	increaseInitialPortfolioChanges,
	setPortfolioDataCreationIdCollection,
	setPortfolioDataCreationPortfolioType,
	setPortfolioDataCreationType,
	emptyAllFieldsPortfolioDataCreation,
	setPortfolioPreviewName,
	setPortfolioPreviewSlug,
	setCurrentViewExpertPortfolioAndSelection,
	setShowSlideModalExpertPortfolio,
	setShowModalCreation,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
