//3rd parties
import PropTypes from 'prop-types';

//icons
import ListView from '../../../UI/assets/icons/ListView.svg';
import GreyGridView from '../../../UI/assets/icons/GreyGridView.svg';

/**
 * @description Button to change from table to list in the view all sections.
 * @param {*} param0
 * @returns
 */

export default function ButtonChangeView({ changeView, setChangeView }) {
	return (
		<button className='w-8' onClick={() => setChangeView(!changeView)}>
			<img
				className={changeView ? 'block' : 'hidden'}
				src={GreyGridView}
				alt='icon grid'
			/>
			<img
				className={!changeView ? 'block' : 'hidden'}
				src={ListView}
				alt='icon list'
			/>
		</button>
	);
}

ButtonChangeView.propTypes = {
	/** Boolaen to change the view.
	 * False => grid.
	 * True => list  */
	changeView: PropTypes.bool,
	/** Don't know what it does */
	setChangeView: PropTypes.func,
};
