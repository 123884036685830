import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPostMainImage } from '../../hooks/fetchPostMainImage';

export const createMainImage = createAsyncThunk(
	'Element/createMainImage',
	async (values) => {
		return await fetchPostMainImage(values);
	}
);

const initialState = {
	elementInfo: '',
	section: '',
	backendAnswer: '',
	createdImageCounter: 0,
	status: null,
};

export const ImageSlice = createSlice({
	name: 'elements',
	initialState,
	reducers: {
		setSection: (state, { payload }) => {
			state.section = payload;
		},
	},
	extraReducers: {
		[createMainImage.pending]: (state, action) => {
			state.status = 'loading';
		},
		[createMainImage.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
			state.createdImageCounter += 1;
		},
		[createMainImage.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});

export const { setSection } = ImageSlice.actions;

export default ImageSlice.reducer;
