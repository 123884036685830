import { fetchPostData } from '../hooks/fetchPostData';
import { fetchPostFile } from '../hooks/fetchPostFile';

export default async function postDataAndFile(ENDPOINT, values) {
	const res = await fetchPostFile(values);
	values = {
		...values,
		main_picture_id: res.id,
	};
	return fetchPostData(ENDPOINT, values);
}
