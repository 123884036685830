//Third parties
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

//BL
import { fetchGetData } from '../../../BusinessLogic/hooks/fetchGetData';
import { ENDPOINTS_LOCATION } from '../../../BusinessLogic/helpers/routes';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextSubTitle from '../../molecules/Text/TextSubTitle';
import Cross from '../../assets/icons/Cross.svg';
import Check from '../../assets/icons/whiteCheck.svg';
import FieldSelectFormik from '../../molecules/Field/FieldSelectFormik';

/**
 * @description section that uses formik to edit the Locations
 * @param {*} param0
 * @returns
 */

const SectionEditLocation = ({
	colorHr,
	sectionTitle,
	onSubmitAction,
	setTurnEdit,
	id,
}) => {
	const { organization_user_id } = localStorage;
	const { singleArtwork } = useSelector((state) => state.artworks);
	const [generalLocations, setGeneralLocations] = useState([]);
	const [roomLocations, setRoomLocations] = useState([]);
	const [locations, setLocations] = useState([]);

	const initialValues = {
		location_general_id: singleArtwork.location_general_id,
		location_room_id: singleArtwork.location_room_id,
		location_id: singleArtwork.location_id,
	};

	useEffect(() => {
		fetchGetData(
			`${ENDPOINTS_LOCATION.GET_LOCATION_GENERAL_with_LABELS}${organization_user_id}/`
		).then((data) => setGeneralLocations(data));
		fetchGetData(
			`${ENDPOINTS_LOCATION.GET_LOCATIONS_ROOMS_WITH_LABELS}${singleArtwork.location_general_id}/`
		).then((data) => setRoomLocations(data));
		fetchGetData(
			`${ENDPOINTS_LOCATION.GET_LOCATIONS_WITH_LABELS}${singleArtwork.location_room_id}/`
		).then((data) => setLocations(data));
	}, [singleArtwork, organization_user_id]);

	const handleLocationGeneralChange = (e) => {
		setRoomLocations([]);
		setLocations([]);
		const currentLocationGeneral = e.target.value;
		if (currentLocationGeneral === '') return;

		fetchGetData(
			`${ENDPOINTS_LOCATION.GET_LOCATIONS_ROOMS_WITH_LABELS}${currentLocationGeneral}/`
		).then((data) => setRoomLocations(data));
	};

	const handleLocationRoomChange = (e) => {
		const currentLocationRoom = e.target.value;
		if (currentLocationRoom === '') return;
		fetchGetData(
			`${ENDPOINTS_LOCATION.GET_LOCATIONS_WITH_LABELS}${currentLocationRoom}/`
		).then((data) => setLocations(data));
	};

	return (
		<div className='flex w-full flex-col '>
			<TextSubTitle
				text={sectionTitle}
				hasEdit={false}
				hasAdd={false}
				colour={colorHr}
			/>
			<div>
				<Formik
					initialValues={initialValues}
					//validationSchema={validationSchemaObject}
					onSubmit={(values) => {
						let input = { values, id };
						onSubmitAction(input);
						setTurnEdit(false);
					}}
				>
					{(formProps) => (
						<Form>
							<FieldSelectFormik
								label={'Lugar'}
								dbName={'location_general_id'}
								options={generalLocations}
								key={1}
								className='mx-2'
								onChangeAction={handleLocationGeneralChange}
								formProps={formProps}
							/>

							<FieldSelectFormik
								label={'Sala'}
								dbName={'location_room_id'}
								options={roomLocations}
								key={2}
								className='mx-2'
								onChangeAction={handleLocationRoomChange}
								formProps={formProps}
							/>
							<FieldSelectFormik
								label={'Ubicación'}
								dbName={'location_id'}
								options={locations}
								key={3}
								className='mx-2'
							/>

							<div className='my-5 flex w-full flex-row justify-center gap-3'>
								<ButtonFab
									label='Cancelar'
									colour='white'
									action={() => setTurnEdit(false)}
									icon={Cross}
								/>
								<ButtonFab
									colour='green'
									label='Guardar'
									icon={Check}
									type='submit'
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

SectionEditLocation.propTypes = {
	/** title of the section*/
	sectionTitle: PropTypes.string,
	/** action on submit */
	onSubmitAction: PropTypes.func,
	/** Color of Header in Subtitle */
	colorHr: PropTypes.string,
	/** check */
	setTurnEdit: PropTypes.func,
	/** id of the element */
	id: PropTypes.number,
	/** object used for validation */
	validationSchemaObject: PropTypes.object,
	/* ValidationSchema used to manage error messages */
	validationSchema: PropTypes.object,
};

export default SectionEditLocation;
