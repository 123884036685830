//3rd party
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

//UI
import 'react-lazy-load-image-component/src/effects/blur.css';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import TextContentThird from '../../atoms/Text/Content/TextContentThird';
import { ReactComponent as Heart } from '../../assets/icons/Heart.svg';
import TextSingleRowSmall from '../Text/TextSingleRowSmall';
import ModalImage from '../../organisms/PopUp/ModalImage';
import ReactPlayer from 'react-player';

/**
 * @description render a image with details of the artwork.
 * @param {*} param0
 * @returns
 */
function ImageArtworkInfo({
	imagen,
	title,
	date,
	dimensionsHeight,
	dimensionsWidth,
	dimensionsLength,
	technique,
	price,
	measure,
	currency,
	useLike,
	likeAction,
	like,
	postulationItemId,
	materials,
	hasHeart,
	isVideoImage,
}) {
	const [showModalImage, setShowModalImage] = useState(false);

	const defaultImage =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';
	const imageFormat = '_medium';

	let artworkHeight =
		dimensionsHeight && dimensionsHeight !== null
			? measure?.distance
				? dimensionsHeight + measure?.distance
				: dimensionsHeight + ' x '
			: '';
	let artworkWidth =
		dimensionsWidth && dimensionsWidth !== null
			? measure?.distance
				? dimensionsWidth + measure?.distance
				: dimensionsWidth + ' x '
			: '';
	let artworkLength =
		dimensionsLength && dimensionsLength !== null
			? measure?.distance
				? dimensionsLength + measure?.distance
				: dimensionsLength
			: '';
	const sign = currency?.sign ? currency.sign : '$';

	return (
		<div className='flex flex-col gap-2'>
			<div
				className=' cursor-pointer'
				onClick={() => {
					setShowModalImage(true);
				}}
			>
				{isVideoImage ? (
					<ReactPlayer
						url={imagen}
						width={'100%'}
						height={'100%'}
						controls={false}
						light={true}
						className='pointer-events-none aspect-square'
					/>
				) : (
					<LazyLoadImage
						alt={title}
						src={imagen ? imagen + imageFormat : defaultImage}
						width='100%'
						height='100%'
						effect='blur'
						wrapperClassName={`overflow-hidden flex items-center lazyLoadingImages`}
						delayTime='0'
						threshold='800'
						style={{ objectFit: 'contain', aspectRatio: '1/1' }}
					/>
				)}
			</div>
			<div className='flex w-full flex-row' style={{ minHeight: '40px' }}>
				<TextContentPrincipal
					design='flex line-clamp-2 w-10/12 self-center words-break'
					px-1
					text={title}
					style={{ fontWeight: 700 }}
				/>
				{hasHeart && (
					<div className='my-auto flex w-2/12'>
						<Heart
							className='heartClass cursor-pointer'
							fill={like ? '#FF0000' : '#FFFFFF'}
							onClick={() => (useLike ? likeAction(postulationItemId) : '')}
						/>
					</div>
				)}
			</div>

			{(price ||
				date ||
				technique ||
				materials ||
				(artworkHeight && artworkWidth && artworkLength)) && (
				<div className='flex flex-col gap-1 px-1'>
					{typeof price === 'number' && price > 0 && (
						<TextContentThird
							design='text-left line-clamp-2'
							text={`${sign} ${price.toLocaleString('en-US')}`}
							style={{ fontWeight: 600 }}
						/>
					)}
					{date && (
						<TextSingleRowSmall
							label='Año'
							value={date?.slice(0, 4)}
							labelWeight={{ fontWeight: 600 }}
							valueDesign='line-clamp-1'
							labelDesign='pr-1'
						/>
					)}
					{artworkHeight && artworkWidth && artworkLength && (
						<div className='flex flex-col gap-1'>
							<TextContentThird
								text='Dimensiones:'
								style={{ fontWeight: 600 }}
							/>
							<TextContentThird
								text={`${artworkHeight} ${artworkWidth} ${artworkLength}`}
							/>
						</div>
					)}
					{technique && (
						<TextSingleRowSmall
							label='Técnica'
							value={technique}
							labelWeight={{ fontWeight: 600 }}
							valueDesign='line-clamp-1'
							labelDesign='pr-1'
						/>
					)}
					{materials && (
						<TextSingleRowSmall
							label='Materiales'
							value={materials}
							labelWeight={{ fontWeight: 600 }}
							valueDesign='line-clamp-1'
							labelDesign='pr-1'
						/>
					)}
				</div>
			)}
			{showModalImage && (
				<ModalImage
					mainPicture={imagen}
					name={title}
					showModalImage={showModalImage}
					setShowModalImage={setShowModalImage}
					isVideoImage={isVideoImage}
				/>
			)}
		</div>
	);
}

ImageArtworkInfo.propTypes = {
	/** url string of the picture artwork*/
	imagen: PropTypes.string,
	/* title name of the artwork */
	title: PropTypes.string,
	/* date of the artwork */
	date: PropTypes.string,
	/* dimension height of the artwork */
	dimensionsHeight: PropTypes.number,
	/* dimension width of the artwork */
	dimensionsWidth: PropTypes.number,
	/* dimension lenght of the artwork */
	dimensionsLength: PropTypes.number,
	/* technique used in the artwork */
	technique: PropTypes.string,
	/* price of the artwork */
	price: PropTypes.number,
	/* measure of the artwork */
	measure: PropTypes.object,
	/* currency and sign of the artwork */
	currency: PropTypes.object,
	/* identifier of the artwork */
	useLike: PropTypes.bool,
	/* Ability to give like to the element*/
	likeAction: PropTypes.bool,
	/* property that determine the red or white color of the heart */
	like: PropTypes.bool,
	/* id of the element artwork to postulate */
	postulationItemId: PropTypes.number,
	/* material used in the artwork */
	materials: PropTypes.string,
};

ImageArtworkInfo.defaultProps = {
	useLike: false,
};

export default ImageArtworkInfo;
