import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// UI
import { ReactComponent as SeeMore } from '../../../../UI/assets/icons/seeMore.svg';

export default function ButtonLinkSeeMore({ link, design }) {
	const { t } = useTranslation();
	return (
		<Link to={link}>
			<div
				className={`float-right flex  cursor-pointer flex-row  text-gray-500 ${design} `}
			>
				<div className='-ml-7 flex w-fit justify-center align-middle'>
					<h3 className='relative left-11 rounded-sm  text-sm '>
						{t('VERMÁS')}
					</h3>

					<SeeMore fill='none' className='ml-2 pt-1' />
				</div>
			</div>
		</Link>
	);
}

ButtonLinkSeeMore.propTypes = {
	/*  String prop with the link passed to the component  */
	link: PropTypes.string,
	/* String prop with the tailwind classnames for the component*/
	design: PropTypes.string,
};
