import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	postulations: null,
	callPostulations: null,
	singlePostulation: null,
	selectedArtworks: [],
	selectedPortfolio: null,
	loadingPostulation: false,
	postulationsChanges: 0,
	is_organization_admin: false,
	postulationData: {},
	postulationDocument: false,
	postulationStatusDocument: false,
	postulationsCallId: null,
};

export const postulationSlice = createSlice({
	name: 'postulation',
	initialState,
	reducers: {
		checkIfOrganizationAdmin: (state, { payload }) => {
			if (
				payload.appUser_organization_user_id ===
				payload.call_organization_user_id
			) {
				state.is_organization_admin = true;
			} else {
				state.is_organization_admin = false;
			}
		},
		updateSelectedArtworks: (state, { payload }) => {
			state.selectedArtworks = payload;
		},
		updateSelectedPortfolio: (state, { payload }) => {
			state.selectedPortfolio = payload;
		},
		updatePostulationsCallId: (state, { payload }) => {
			state.postulationsCallId = payload;
		},
	},
});

export const {
	checkIfOrganizationAdmin,
	updateSelectedArtworks,
	updateSelectedPortfolio,
	updatePostulationsCallId,
} = postulationSlice.actions;

export default postulationSlice.reducer;
