import { submitErrorToSlack } from '../helpers/SlackIntegrations';

export const fetchPostDataWithUser = async (url, FirstValues) => {
	const { token, organization_user_id } = localStorage;
	let endpoint = '';
	const values = {
		organization_user_id: Number(organization_user_id),
		...FirstValues,
	};
	return fetch(url, {
		method: 'POST',
		cache: 'no-cache',
		body: JSON.stringify(values),
		headers: {
			content_type: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
		.then(async (res) => {
			const response = await res.json();
			endpoint = res.url;
			if (res.status >= 500) {
				submitErrorToSlack(endpoint, response, 'POST');
			}
			return response;
		})
		.catch((error) => {
			if (error.response.status >= 500) {
				submitErrorToSlack(endpoint, error, 'GET');
			}
		});
};
