import React, { useRef, useEffect } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';

// Bl

// UI
import ElementArtwork from '../../molecules/Element/ElementArtwork';
import ElementText from '../../molecules/Element/ElementText';

/**
 * It renders a preview of the slide that is being edited
 * @returns A component that renders a slide preview
 */
const EditablePortfolioSlide = ({
	showSlideBeside,
	setSelectedElementByIndex,
	selectedElementByIndex,
	contentSlideToEdit,
}) => {
	const [containerSlidePosition, setContainerSlidePosition] = useState({});
	const [outsideSlideBoundary, setOutsideSlideBoundary] = useState(false);

	const currentPositionPercentage = (currentElementPosition) => {
		const leftPercentagePosition =
			((currentElementPosition.left - containerSlidePosition.left) /
				slideContainerWidth) *
			100;
		const topPercentagePosition =
			((currentElementPosition.top - containerSlidePosition.top) /
				slideContainerHeight) *
			100;

		return {
			left: Number(leftPercentagePosition.toFixed(1)),
			top: Number(topPercentagePosition.toFixed(1)),
		};
	};

	const slideContainerRef = useRef();
	const slideContainerWidth = String(slideContainerRef?.current?.offsetWidth);
	const slideContainerHeight = String(slideContainerRef?.current?.offsetHeight);
	const getSlideContainerPosition = () => {
		if (slideContainerRef.current) {
			let rect = slideContainerRef.current.getBoundingClientRect();
			setContainerSlidePosition({ left: rect.left, top: rect.top });
			return { left: rect.left, top: rect.top };
		}
	};

	useEffect(() => {
		if (slideContainerRef.current) {
			getSlideContainerPosition();
			window.addEventListener('resize', () => getSlideContainerPosition());
		}
	}, []);

	const renderSlidePreview = (element, indexElement) => {
		switch (element?.type) {
			case 'artwork':
				return (
					<ElementArtwork
						elementType={element.elementType}
						picture={element.picture[0].url}
						name={element.text[0].name}
						width={element.picture[0].width}
						technique={element.text[0].technique}
						year={element.text[0].year}
						measure={element.text[0].measure}
						design={element.text[0].textAlign}
						textSize={'text-sm'}
						indexElement={indexElement}
						currentPositionPercentage={currentPositionPercentage}
						nameSize={element.nameSize}
						picturePosition={element.picturePosition}
						textPosition={element.textPosition}
						positionPictureDraggable={element.positionPictureDraggable}
						positionTextDraggable={element.positionTextDraggable}
						slideContainerWidth={slideContainerWidth}
						slideContainerHeight={slideContainerHeight}
						outsideSlideBoundary={outsideSlideBoundary}
						hasSelectionBorder={indexElement === selectedElementByIndex}
						setSelectedElementByIndex={setSelectedElementByIndex}
						selectedElementByIndex={selectedElementByIndex}
					/>
				);
			case 'Text':
				return (
					<ElementText
						outsideSlideBoundary={outsideSlideBoundary}
						textContent={element.content}
						textSize={element.fontSize}
						indexElement={indexElement}
						currentPositionPercentage={currentPositionPercentage}
						position={element.position}
						positionDraggable={element.positionDraggable}
						hasSelectionBorder={indexElement === selectedElementByIndex}
						placeholder={element.placeholder}
						typeInput={element.typeInput}
						slideContainerWidth={slideContainerWidth}
						elementText={element.elementText}
						setSelectedElementByIndex={setSelectedElementByIndex}
						selectedElementByIndex={selectedElementByIndex}
					/>
				);
			default:
				<div />;
		}
	};
	return (
		<div
			className={`
				relative flex h-[calc(100vh-116px)] items-center justify-center md:pl-[103px]
				${
					showSlideBeside
						? 'md:pl-[calc(50vh+103px)] lg:pl-[calc(68vh+103px)]'
						: 'md:pl-[103px]'
				}
			`}
		>
			<div
				id='slideContainer'
				ref={slideContainerRef}
				onMouseLeave={() => setOutsideSlideBoundary(true)}
				onMouseEnter={() => setOutsideSlideBoundary(false)}
				className={`
					relative h-[85vw] w-[85vw] sm:h-[calc(85vh-116px)] sm:w-[calc(85vh-116px)]
					${
						showSlideBeside
							? 'sm:max-h-[calc(40vw-103px)] sm:max-w-[calc(40vw-103px)]'
							: 'sm:max-h-[calc(85vw-103px)] sm:max-w-[calc(85vw-103px)]'
					}
					aspect-square rounded-sm bg-white
				`}
			>
				{contentSlideToEdit?.map((element, index) => (
					<>{renderSlidePreview(element, index)}</>
				))}
			</div>
		</div>
	);
};

EditablePortfolioSlide.propTypes = {
	/**Determines if the slide should be displayed beside when the lateral bar is open*/
	showSlideBeside: PropTypes.bool,
};

EditablePortfolioSlide.defaultProps = {
	showSlideBeside: false,
};

export default EditablePortfolioSlide;
