import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { paymentsOptions } from '../../../BusinessLogic/data/paymentsOptions';
import PaymentCard from './PaymentCard';
import { ReactComponent as Logo } from '../../assets/icons/logoDatarteNegro.svg';

export default function Payments() {
	const { t } = useTranslation();
	const { appUser } = useSelector((state) => state.appUser);

	const userRole = appUser.role_company;
	const isArtistUser = userRole === 'ARTIST';
	const allPayments = [
		...paymentsOptions.artist.monthly,
		...paymentsOptions.artist.annual,
	];

	if (!isArtistUser) {
		return <Navigate to='/' />;
	}

	return (
		<div className='min-h-screen'>
			<main
				className={`min-h-full px-2 md:overflow-x-hidden md:px-4 lg:px-8 2xl:px-10`}
			>
				<div className='mx-auto mt-3  text-center md:px-5 '>
					<Logo className='mx-auto mb-5 mt-10' />
					<h2 className=' text-xl font-bold text-green-50'>
						{t('CreaTuCuentaDeArtista')}
					</h2>
					<h3 className=' text-base font-light text-green-50'>
						{t('SeleccionaUnPlan')}
					</h3>
					<div className='mx-auto mt-8 flex w-full max-w-6xl flex-col justify-center gap-5 pb-8 text-center md:flex-row'>
						{allPayments.map((payment, index) => (
							<PaymentCard key={index} className='flex-1' {...payment} />
						))}
					</div>
				</div>
			</main>
		</div>
	);
}
