// 3rd Party
import PropTypes from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';

// UI
import DownloadableExpertSlideItemTitle from '../../../../molecules/Portfolio/ExpertSlideItems/DownloadableExpertSlideItems/DownloadableExpertSlideItemTitle';

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		height: '100%',
		width: '100%',
		marginBottom: '24px',
	},
	containerTitle: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	title: {
		fontSize: '12px',
		marginBottom: '10px',
		fontFamily: 'Helvetica-Bold',
	},
	subtitle: {
		fontSize: '12px',
		marginBottom: '10px',
		fontFamily: 'Helvetica',
	},
});

export default function DownloadableExpertSlideBackCover({ slideItems }) {
	const titleElements = slideItems?.find(
		(element) => element.type === 'title'
	)?.slideItemElements;

	return (
		<View style={styles.container}>
			<DownloadableExpertSlideItemTitle
				titleElements={titleElements}
				stylesTitle={styles.title}
				stylesSubtitle={styles.subtitle}
				stylesTitleContainer={styles.containerTitle}
			/>
		</View>
	);
}

DownloadableExpertSlideBackCover.propTypes = {
	// contains the back cover data to show
	slideItems: PropTypes.array,
};
