//3rd parties
import PropTypes from 'prop-types';

//Interntal components
import TextContentSecond from '../../Text/Content/TextContentSecond';

/**
 * @name ButtonFab
 * @description simple button to add an item
 *
 * Button Fab follos the figma rules.
 * @param {*} param0
 * @returns
 */

const ButtonFab = ({
	label,
	icon,
	size,
	colour,
	action,
	design,
	style,
	type,
	disabled,
	styleText,
	iconDesign,
	ButtonClassName,
	paragraphDesign,
}) => {
	//manage button size
	let ButtonSize = '';
	if (size === 'xsmall') {
		ButtonSize = 'w-10 rounded-full h-10';
	} else if (size === 'small') {
		ButtonSize = 'w-12 rounded-full h-12';
	} else if (size === 'medium') {
		ButtonSize = 'w-24 rounded h-10';
	} else if (size === 'large') {
		ButtonSize = 'w-48 rounded h-10';
	} else if (size === 'xlarge') {
		ButtonSize = 'w-80 rounded h-10';
	} else if (size === 'fitContent') {
		ButtonSize = 'w-fit ';
	}

	//manage button colour
	let ButtonColour = '';
	let TextColour = '';
	if (colour === 'white') {
		ButtonColour = 'bg-white';
		TextColour = 'text-black';
	} else if (colour === 'green') {
		ButtonColour = 'bg-green-50';
		TextColour = 'text-white';
	} else if (colour === 'background') {
		ButtonColour = '';
		TextColour = 'text-black';
	} else if (colour === 'red') {
		ButtonColour = 'bg-white';
		TextColour = 'text-red-500';
	}

	return (
		<div className={design} style={style}>
			<button
				className={`relative before:absolute before:inset-0 before:z-10 
			lg:before:h-full lg:before:w-full lg:active:before:shadow-[inset_0px_4px_4px_1px_rgba(100,100,100,0.32)] ${
				colour === 'green' ? 'lg:hover:opacity-60' : 'lg:hover:bg-[#DEDFE2]'
			} ${ButtonColour} ${ButtonSize} ${ButtonClassName} `}
				onClick={action}
				type={type}
				disabled={disabled}
			>
				{icon && <img className={iconDesign} src={icon} alt='icon' />}
				<TextContentSecond
					text={label}
					design={`normal-case ${TextColour}`}
					style={styleText}
					paragraphDesign={paragraphDesign}
				/>
			</button>
		</div>
	);
};

ButtonFab.propTypes = {
	/** Passes the text to the component */
	label: PropTypes.string,
	/** Icon passed to the component. If there's no icon it disappears.*/
	icon: PropTypes.string,
	/** Manages the size of the component */
	size: PropTypes.string,
	/** Manages the colour of the component */
	colour: PropTypes.string,
	/** Action Triggered on Click */
	action: PropTypes.func,
	/** Tailwind className of the main container */
	design: PropTypes.string,
	/** Style of the maint container */
	style: PropTypes.object,
	/** Type of the button. Used for submit */
	type: PropTypes.string,
	/** Boolean prop  used to determine whether the button is disabled */
	disabled: PropTypes.bool,
	/** inline style to the text */
	styleText: PropTypes.object,
	/** String prop with the tailwind classes for the button */
	ButtonClassName: PropTypes.string,
	/** String  prop  with the style for the TextContentSecond paragraph*/
	paragraphDesign: PropTypes.string,
};

ButtonFab.defaultProps = {
	label: 'Crear',
	colour: 'green',
	size: 'medium',
	design: '',
	type: 'button',
	ButtonClassName:
		'z-10  flex justify-center items-center cursor-pointer shadow',
};

export default ButtonFab;
