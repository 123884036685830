import { fetchPutImage } from '../hooks/fetchPutImage';
import { fetchPutData } from '../hooks/fetchPutData';

export default async function putDataAndImage(ENDPOINT, values) {
	const res = await fetchPutImage(values);
	values = {
		...values,
		main_picture_id: res.id,
	};
	fetchPutData(ENDPOINT, values);
}
