//3rd
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//UI
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import ButtonWithTextIcon from '../../molecules/Buttons/WithIcon/ButtonWithTextIcon';
import { ReactComponent as Check } from '../../../UI/assets/icons/Check.svg';
import { ReactComponent as ImageChangeGray } from '../../../UI/assets/icons/ImageChangeGray.svg';

const ModalSelectImage = ({
	setShowModal,
	selectImageAction,
	uploadImageAction,
}) => {
	const { t } = useTranslation();

	return (
		<div className='fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black-50 bg-opacity-80 '>
			<div className='relative my-6 mx-auto flex w-80 flex-col gap-2 p-6 md:p-0'>
				<div className='relative flex w-full flex-col items-center rounded-lg border-0 bg-white pt-5 shadow-lg'>
					<TextContentPrincipal
						text={t('ImágenPortada')}
						design={'my-3 px-3'}
						style={{ fontWeight: '600' }}
					/>
					<TextContentSecond
						text={t('SeleccionaImagenPortadaColección')}
						design={'text-center mb-3 px-3'}
					/>
					<div className='w-full'>
						<ButtonWithTextIcon
							text={t('SeleccionarImagenObra')}
							design={'w-full flex flex-row justify-start border-t-2'}
							contentDesign={
								'w-full flex flex-row-reverse justify-end gap-2 px-3 py-1'
							}
							iconDesign={'w-7 h-7 flex justify-center items-center'}
							icon={<Check />}
							action={() => selectImageAction()}
						/>
						<ButtonWithTextIcon
							text={t('CargarImagenPortada')}
							design={'w-full flex flex-row justify-start border-t-2'}
							contentDesign={
								'w-full flex flex-row-reverse justify-end gap-2 px-3 py-1'
							}
							iconDesign={'w-7 h-7 flex justify-center items-center'}
							icon={<ImageChangeGray />}
							action={() => uploadImageAction()}
						/>
					</div>
				</div>
				<ButtonWithTextIcon
					text={t('Cancelar')}
					design={
						'border-0 rounded-lg shadow-lg relative flex flex-col items-center w-full bg-white p-3'
					}
					textStyle={{ fontWeight: '600' }}
					contentDesign={'p-0'}
					action={() => setShowModal(false)}
				/>
			</div>
		</div>
	);
};

ModalSelectImage.propTypes = {
	/** Function to update the state that shows the modal*/
	setShowModal: PropTypes.func,
	/** Function to run when clicking select image option*/
	selectImageAction: PropTypes.func,
	/** Function to run when clicking upload image option*/
	uploadImageAction: PropTypes.func,
};

export default ModalSelectImage;
