export const NAVBAR_ES = {
	Catalogación: 'Catalogación',
	Difusión: 'Difusión',
	Convocatoria: 'Convocatoria',
	Perfil: 'Perfil',
	Artistas: 'Artistas',
	Obras: 'Obras',
	Documentos: 'Documentos',
	Lugares: 'Lugares',
	Contactos: 'Contactos',
	Exposiciones: 'Exposiciones',
	Colecciones: 'Colecciones',
	Portafolios: 'Portafolios',
	ConvocatoriasAbiertas: 'Convocatorias Abiertas',
	MisPostulaciones: 'Mis postulaciones',
	Postulaciones: 'Postulaciones',
	Catalogo: 'Catálogo',
	TuPlanEstaActivo: 'Tu plan está activo',
	TuPlanSeHaActivadoExitosamente:
		'Ahora puedes comenzar a disfrutar de la web app de Datarte y acceder a todos los beneficios adicionales.',
	hazteMiembro: '¡Házte miembro!',
};
