//3rd
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

//UI
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import CardSwitch from '../../../UI/molecules/Card/CardSwitch';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import Cross from '../../../UI/assets/icons/Cross.svg';
import Check from '../../../UI/assets/icons/whiteCheck.svg';

//BL
import { updateEditingCall } from '../../../BusinessLogic/redux/reducers/call';
import { usePutCall } from '../../../BusinessLogic/hooks/query/useQueryCalls';

/**
 * @description Renders the cv view of a call
 * @param {*} param0
 * @returns
 */
const CallCV = () => {
	const { t } = useTranslation();
	const { slug } = useParams();
	const { editingCall } = useSelector((state) => state.call);
	const [hasCV, setHasCV] = useState(editingCall.has_cv);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const putCallMutation = usePutCall();

	const handleSave = () => {
		dispatch(
			updateEditingCall({
				...editingCall,
				has_cv: hasCV,
			})
		);

		putCallMutation
			.mutateAsync({
				...editingCall,
				has_cv: hasCV,
			})
			.then(() => {
				navigate(`/convocatorias/${slug}`);
			});
	};

	return (
		<TemplateWithNavBar>
			<NavbarSecondaryNew
				title={'Curriculum vitae'}
				textColor='text-purple-50 w-max'
				color='bg-purple-50'
				showDots={false}
				redirect={`/convocatorias/${slug}`}
				textBack={`${t('RegresarConvocatoria')}`}
				textBackDesign='pl-3 text-purple-50'
				arrowLeftColor='purple'
			/>
			<div className='w-full'>
				<CardSwitch
					mainText={'Requerido'}
					secondText={
						'Se cargará el curriculum vitae del artista en formato PDF'
					}
					switched={hasCV}
					setSwitched={setHasCV}
				/>
				{hasCV !== editingCall.has_cv && (
					<div className='mb-16 mt-8 flex w-full flex-row justify-center gap-3'>
						<ButtonFab
							label={t('Cancelar')}
							colour='white'
							action={() => navigate(`/convocatorias/${slug}`)}
							icon={Cross}
						/>
						<ButtonFab
							colour='green'
							label={t('Guardar')}
							icon={Check}
							type='submit'
							action={() => handleSave()}
						/>
					</div>
				)}
			</div>
		</TemplateWithNavBar>
	);
};

export default CallCV;
