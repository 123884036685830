import { useState } from 'react';
import FigureCirclePerfil from '../../atoms/Figure/FigureCirclePerfil';
import UserMenu from '../../molecules/userManagement/UserMenu';
import UnderlineHr from '../../atoms/UnderLine/UnderlineHr';
import ButtonLinkOpenMenu from '../../atoms/Buttons/Link/ButtonLinkOpenMenu';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

export default function ManagamentUser({
	userName,
	email,
	rol,
	appUserId,
	firstLetter,
	date,
}) {
	const [clickedOutside, setClickedOutside] = useState(false);

	return (
		<div className='flex flex-col items-center md:mx-4 md:items-start '>
			<div className='flex items-center	justify-center	'>
				<div>
					<FigureCirclePerfil
						design='w-11 h-11 leading-loose rounded-full flex items-center justify-center'
						initial={firstLetter}
					/>
				</div>
				<div className='mx-8 flex	flex-col	'>
					<TextContentSecond
						design='text-left'
						text={email}
						style={{ fontWeight: 700 }}
					/>
					<TextContentSecond design='text-left' text={rol} />
				</div>
				<div
					className='items-center 	'
					onClick={() => setClickedOutside(!clickedOutside)}
				>
					<ButtonLinkOpenMenu />
					{clickedOutside && <UserMenu appUserId={appUserId} />}
				</div>
			</div>
			<UnderlineHr design='w-68 md:w-full bg-grey-50 h-px border-none mx-4 md:mx-0 my-6 ' />
		</div>
	);
}
