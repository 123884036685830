/* React */
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/* BL */
import {
	putLocationGeneral,
	organizeImageGrid,
	getLocationGeneral,
	getLocationsGeneral,
} from '../../../../BusinessLogic/redux/reducers/locationGeneral';
import {
	singleLocationGeneralButtons,
	LocationGeneralValidationSchema,
} from '../../../../BusinessLogic/data/SingleLocationGeneral2';
import { ENDPOINT_FILES } from '../../../../BusinessLogic/helpers/routes';

import { deleteFile } from '../../../../BusinessLogic/redux/reducers/deleteElement';
import { createImage } from '../../../../BusinessLogic/redux/reducers/createImage';
import { compressAndResizeImage } from '../../../../BusinessLogic/helpers/imageSize';
import textGoBackGenerator from '../../../../BusinessLogic/helpers/textGoBackGenerator';
import {
	backSectionURL,
	saveSectionURL,
} from '../../../../BusinessLogic/redux/reducers/navigation';

/* UI */
import { ReactComponent as Delete } from '../../../../UI/assets/icons/Delete.svg';
import TemplateWithNavBar from '../../../../UI/templates/TemplateWithNavBar';
import NavbarSecondaryNew from '../../../../UI/organisms/Navbar/NavbarSecondaryNew';
import ImageAndTitle from '../../../../UI/molecules/Image/ImageAndTitle';
import NewTagButtons from '../../../../UI/molecules/Buttons/Tag/NewTagButtons';
import TabInfoAndEdit from '../../../../UI/organisms/Tabs/TabInfoAndEdit';
import TabImageAndEdit from '../../../../UI/organisms/Tabs/TabImageAndEdit';
import ButtonFabExtendeNav from '../../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendeNav';

/**
 * @description renders the single document section with a carousel.
 * @param {*} param0
 * @returns
 */
function LocationSingleGeneral() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const location = useLocation();
	const { t } = useTranslation();

	const { singleLocationGeneral, locationGeneralSingleTabs, locationsGeneral } =
		useSelector((state) => state.locationsGeneral);
	const { elementInfo } = useSelector((state) => state.deleteElement);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);

	const [indexInfo, setIndexInfo] = useState(0);

	useEffect(() => {
		if (!singleLocationGeneral || `${singleLocationGeneral.id}` !== id) {
			dispatch(getLocationGeneral(id));
		}
		//eslint-disable-next-line
	}, []);

	function onSubmitAction(values) {
		dispatch(putLocationGeneral(values)).then(() => {
			dispatch(getLocationGeneral(id));
			dispatch(getLocationsGeneral());
		});
	}

	const checkRedirect = () => {
		let redirect = '/lugares';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage = 'RegresarLugares';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	const saveURLHistory = () => {
		dispatch(saveSectionURL(location.pathname));
	};

	const createImagesFunction = async (imagesArray) => {
		for await (const image of imagesArray) {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			let values = {
				small_file,
				medium_file,
				large_file,
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
				type: 'LOCATION_GENERAL',
				type_id: id,
				organization_user_id: singleLocationGeneral.organization_user_id,
				file_type: 'IMAGE',
			};
			await dispatch(createImage(values));
		}
		dispatch(getLocationGeneral(id));
	};

	const deleteImagesFunction = (imageId) => {
		let values = {
			element_id: singleLocationGeneral.id,
			element: 'LOCATION_GENERAL',
			url: `${ENDPOINT_FILES.DELETE_FILE}${imageId}/`,
		};
		dispatch(deleteFile(values)).then(() => {
			dispatch(getLocationGeneral(id));
		});
	};

	const submitOrganizeImageGrid = (sortableImages) => {
		let values = {
			images: sortableImages,
			type: 'LOCATION_GENERAL',
			type_id: id,
		};
		dispatch(organizeImageGrid(values)).then(() => {
			dispatch(getLocationGeneral(id));
			dispatch(getLocationsGeneral());
		});
	};

	const renderSwitch = () => {
		switch (locationGeneralSingleTabs[indexInfo].type) {
			case 'Information':
				return (
					<TabInfoAndEdit
						information={locationGeneralSingleTabs[indexInfo]}
						indexInfo={indexInfo}
						colorHr='purple'
						onSubmitAction={onSubmitAction}
						id={parseInt(id)}
						validationSchema={
							LocationGeneralValidationSchema[indexInfo] ?? null
						}
					/>
				);
			case 'Obras':
				return (
					<TabImageAndEdit
						information={locationGeneralSingleTabs[indexInfo]}
						indexInfo={indexInfo}
						color='purple'
						AddIconLink={`/obras/new`}
						saveLastSectionURL={true}
						saveURLHistory={saveURLHistory}
						objectFitProp='contain'
					/>
				);
			case 'Salas':
				return (
					<TabImageAndEdit
						information={locationGeneralSingleTabs[indexInfo]}
						indexInfo={indexInfo}
						color='purple'
						AddIconLink={`/salas/new`}
						saveLastSectionURL={true}
						saveURLHistory={saveURLHistory}
					/>
				);
			case 'Images':
				return (
					<TabImageAndEdit
						information={locationGeneralSingleTabs[indexInfo]}
						indexInfo={indexInfo}
						colorHr='purple'
						AddIconLink={`/${locationGeneralSingleTabs[
							indexInfo
						].tab.toLowerCase()}/new`}
						deleteImagesFunction={deleteImagesFunction}
						createImagesFunction={createImagesFunction}
						submitOrganizeImageGrid={submitOrganizeImageGrid}
					/>
				);
			default:
				return <h1>No hay documentos disponible</h1>;
		}
	};

	return (
		<>
			{singleLocationGeneral && (
				<TemplateWithNavBar section='lugares'>
					<NavbarSecondaryNew
						title={singleLocationGeneral.name}
						color='bg-purple-50'
						textColor='text-purple-50'
						redirect={checkRedirect()}
						textPopup={`${t('EliminarLugar')}`}
						popupIcon={<Delete />}
						modalTitle={`${t('EliminarLugar')}`}
						modalTextOne={`${t('SeguroQueDeseasEliminarEsteLugar')}`}
						modalTextTwo={elementInfo}
						id={parseInt(id)}
						section='lugares'
						textBack={CheckTextBack()}
						textBackDesign='pl-3 text-purple-50'
						backAction={() => returnBackAction()}
					/>
					<ButtonFabExtendeNav
						id={parseInt(id)}
						listItems={locationsGeneral.length ? locationsGeneral : []}
						section='lugares'
					/>
					<div className='flex w-full flex-col items-center justify-center gap-8 md:flex-row md:items-start'>
						<div className='flex w-full justify-center p-6 sm:w-[70%] md:w-1/3'>
							<ImageAndTitle
								imagen={singleLocationGeneral.main_picture_url}
								objectFitProp={'scale-down'}
							/>
						</div>

						<div className='flex w-full flex-col md:w-2/3'>
							<NewTagButtons
								buttonList={singleLocationGeneralButtons}
								indexInfo={indexInfo}
								setIndexInfo={setIndexInfo}
							/>

							<div className='min-h-screen bg-light_grey-50 py-4'>
								{renderSwitch()}
							</div>
						</div>
					</div>
				</TemplateWithNavBar>
			)}
		</>
	);
}

LocationSingleGeneral.propTypes = {};

LocationSingleGeneral.defaultProps = {};

export default LocationSingleGeneral;
