import { useState } from 'react';
//3rd party
import PropTypes from 'prop-types';
import FloatingWhatsApp from 'react-floating-whatsapp';

//ui
import logoDatarte from '../../../UI/assets/icons/logoDatarteBlanco.svg';
import { ReactComponent as ShareNetworkWhite } from '../../../UI/assets/icons/ShareNetworkWhite.svg';
import SharePortfolio from '../../../Pages/sections/portfolio/SharePortfolio';

export default function PortfolioFooter({
	hasSubCollection,
	subcollection,
	hasWhatsApp,
	WhatsAppData,
}) {
	const [showSharePortfolio, setShowSharePortfolio] = useState(false);
	return (
		<div className='sticky bottom-0 left-0 flex h-12 w-full flex-row justify-between bg-green-50 p-2'>
			<div className='flex flex-row'>
				<img src={logoDatarte} alt='datarte.art' />
				<a
					href='https://www.datarte.art'
					className=' ml-4 pt-1.5 text-sm text-white'
				>
					Creado en Datarte
				</a>
			</div>
			<div className='hidden w-1/2 flex-row justify-between lg:flex'>
				{hasSubCollection &&
					subcollection.map((subcollection) => (
						<h4 className='align-center flex leading-8 text-white'>
							{subcollection.name}
						</h4>
					))}
			</div>
			<div className='pt-1'>
				{hasWhatsApp && (
					<FloatingWhatsApp
						phoneNumber={WhatsAppData.phoneNumber}
						accountName={WhatsAppData.accountName}
						statusMessage={WhatsAppData.statusMessage}
						chatMessage={WhatsAppData.chatMessage}
						avatar={WhatsAppData.avatar}
						height={425}
					/>
				)}
			</div>
			<button
				onClick={() => {
					setShowSharePortfolio(!showSharePortfolio);
				}}
			>
				<ShareNetworkWhite />
			</button>
			{showSharePortfolio && (
				<SharePortfolio
					designTwo={'hidden'}
					designOne={'absolute bottom-6 right-6'}
					setShare={setShowSharePortfolio}
					share={showSharePortfolio}
				/>
			)}
		</div>
	);
}

PortfolioFooter.propTypes = {
	/** checks if it has a subscollections */
	hasSubCollection: PropTypes.bool,

	/** Sends the data */
	portfolio: PropTypes.object,

	/** checks if we should show the whatsApp */
	hasWhatsApp: PropTypes.bool,

	/** Data fot whatsApp */
	WhatsAppData: PropTypes.object,
};

PortfolioFooter.defaultProps = {
	hasWhatsApp: false,
};
