import { useNavigate, useParams } from 'react-router';

import { ReactComponent as PageGrey } from '../../../assets/icons/PageGrey.svg';
const EditionView = () => {
	const navigate = useNavigate();
	const { portfolioId } = useParams();

	return (
		<div className='flex w-full justify-end p-2'>
			<div className='flex h-12 w-12 cursor-pointer rounded-full bg-white shadow-md transition-transform duration-300 hover:scale-105'>
				<PageGrey
					className='mx-auto h-8 w-8 self-center'
					onClick={() => {
						navigate(
							`/portafolios/portafolio-experto/${portfolioId}/secciones`
						);
					}}
				/>
			</div>
		</div>
	);
};

export default EditionView;
