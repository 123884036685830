// 3rd Party
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	title: {
		fontSize: '20px',
		fontFamily: 'Helvetica-Bold',
	},
	subtitle: {
		fontSize: '20px',
		fontFamily: 'Helvetica-Bold',
		marginBottom: '12px',
	},
});

export default function ExpertSlideItemTitle({
	titleElements,
	stylesTitle,
	stylesSubtitle,
	stylesTitleContainer,
}) {
	return (
		<View style={stylesTitleContainer}>
			{titleElements?.map((element, index) => (
				<View key={index}>
					{element.type === 'title' && (
						<Text style={[styles.title, stylesTitle]}>{element?.content}</Text>
					)}
					{element.type === 'subtitle' && (
						<Text style={[styles.subtitle, stylesSubtitle]}>
							{element?.content}
						</Text>
					)}
				</View>
			))}
		</View>
	);
}

ExpertSlideItemTitle.propTypes = {
	// elements that compose the itemTitle
	titleElements: PropTypes.array,
	// css styles for title
	stylesTitle: PropTypes.object,
	// css styles for subtitle
	stylesSubtitle: PropTypes.object,
	// css styles for component container
	stylesTitleContainer: PropTypes.object,
};
