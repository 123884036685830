//3rd party
import { PropTypes } from 'prop-types';

//BL

//UI

const ButtonFabExtendedPrimary = ({
	text,
	icon,
	colorClass,
	className,
	typeButton,
	action,
	style,
	isDisabled,
}) => {
	return (
		<button
			className={`${className} ${colorClass} shadow_box relative mt-5 h-9 w-48 
			rounded border-white text-xs before:absolute before:inset-0 before:z-10 hover:opacity-60 lg:before:h-full lg:before:w-full lg:active:before:shadow-[inset_0px_4px_4px_1px_rgba(100,100,100,0.32)]`}
			type={typeButton}
			onClick={action}
			style={style}
			disabled={isDisabled}
		>
			<div className='flex flex-row items-center justify-center p-1 '>
				{icon !== '' && <img src={icon} alt='add' className='mr-1' />}
				{text}
			</div>
		</button>
	);
};

ButtonFabExtendedPrimary.propTypes = {
	/** text written in the button */
	text: PropTypes.string,
	/** Icon shown in the doc */
	icon: PropTypes.object,
	/** ClassName used for the button - this needs to be refactored */
	colorClass: PropTypes.string,
	/** ClassName used for the button */
	className: PropTypes.string,
	/** type of the button */
	typeButton: PropTypes.object,
	/** function triggered when the button is clicked */
	action: PropTypes.func,
	/** style of the button */
	style: PropTypes.string,
};

ButtonFabExtendedPrimary.defaultProps = {
	text: 'GUARDAR',
	icon: '',
	colorClass: 'bg-green-50',
	className: 'text-white',
	typeButton: 'submit',
};

export default ButtonFabExtendedPrimary;
