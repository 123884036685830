import axios from 'axios';

export const api = axios.create();

api.interceptors.request.use(
	(config) => {
		const { token } = localStorage;
		config.headers['Authorization'] = `Bearer ${token}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
