import React from 'react';
import TextContentThird from '../../atoms/Text/Content/TextContentThird';

const VideoPlaceholder = ({ text }) => {
	return (
		<div className='flex aspect-square w-full flex-col items-center justify-end border-2 border-dashed border-gray-500'>
			{text && (
				<TextContentThird
					text={text}
					design={'text-grey-50 py-2 text-center'}
				/>
			)}
		</div>
	);
};

export default VideoPlaceholder;
