//3rd party
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PropTypes from 'prop-types';

/**
 *
 * @description component that renders the artist information in portfolio public view
 */

const PortfolioArtistInformation = ({ artist }) => {
	const { biography, first_name, main_picture_url, exhibitions } = artist ?? {};

	const showProfilePicture =
		main_picture_url &&
		main_picture_url !==
			'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_contact';
	const showBiography = (biography ?? '') !== '';
	const showExhibitions = (exhibitions ?? '') !== '';

	return (
		<div className='flex w-full flex-col gap-5 md:gap-8'>
			{(showBiography || showProfilePicture) && (
				<div className='flex w-full flex-col'>
					{showBiography && (
						<h4 className='mt-2 mb-8 text-center font-louisGeorgeCafe text-xl font-semibold uppercase  md:text-left md:text-4xl'>
							Biografía
						</h4>
					)}
					<div className='flex w-full flex-col-reverse justify-center gap-8 md:flex-row'>
						{showBiography && (
							<p className='w-full whitespace-pre-line text-left text-lg font-light text-gray-800 md:text-2xl'>
								{biography}
							</p>
						)}
						{showProfilePicture && (
							<LazyLoadImage
								src={main_picture_url}
								alt={first_name}
								placeholderSrc={main_picture_url + '_small'}
								wrapperClassName='w-fit md:max-w-lg'
								effect='blur'
								className='max-w-full'
								delayTime='0'
								threshold='300'
								visibleByDefault={true}
							/>
						)}
					</div>
				</div>
			)}
			{showExhibitions && (
				<div className='flex w-full flex-col'>
					<h5 className='mt-2 mb-8 text-left font-louisGeorgeCafe text-lg font-semibold md:text-3xl'>
						Exposiciones
					</h5>
					<p className='w-full whitespace-pre-line text-left text-base font-light text-gray-800 md:text-xl'>
						{exhibitions}
					</p>
				</div>
			)}
		</div>
	);
};

PortfolioArtistInformation.propTypes = {
	/** Object with the information of the main artist of the portfolio*/
	artist: PropTypes.object,
};

export default PortfolioArtistInformation;
