import { fetchPostData } from '../hooks/fetchPostData';
import { ENDPOINTS_PERMISSION } from './routes';

export const checkPassword = (portfolio, password, setAccess) => {
	const values = {
		...portfolio,
		password,
		type_id: portfolio.collection_id,
	};
	fetchPostData(ENDPOINTS_PERMISSION.CHECK_PASSWORD, values).then((res) => {
		if (res.error) {
			return alert('Lo siento, no cuentas con permisos :(');
		}
		setAccess('itHasAccess');
	});
};
