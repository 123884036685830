// 3rd Party
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { useDispatch, useSelector } from 'react-redux';

//BL
import { useTextareaSize } from '../../../../BusinessLogic/hooks/useTextareaSizeHook';

// UI
import EditExpertInputText from '../../../atoms/Portfolio/ExpertElements/EditExpertInputText';
import EditExpertTextarea from '../../../atoms/Portfolio/ExpertElements/EditExpertTextarea';
import ExpertSlideElementTitle from '../../../atoms/Portfolio/ExpertSlideElements/ExpertSlideElementTitle';
import ExpertSlideElementText from '../../../atoms/Portfolio/ExpertSlideElements/ExpertSlideElementText';
import { putElementOnBlur } from '../../../../BusinessLogic/helpers/expertPortfolioHelpers';
import {
	setCurrentViewExpertPortfolioAndSelection,
	setShowSlideModalExpertPortfolio,
} from '../../../../BusinessLogic/redux/reducers/portfolio';

export default function ExpertSlideCover({ slideData, isEdition, isScaled }) {
	const dispatch = useDispatch();

	const { expertPortfolioViewAndSelection } = useSelector(
		(state) => state.portfolios
	);

	const currentViewExpertPortfolio =
		expertPortfolioViewAndSelection.currentView;

	const slideItems = slideData?.slideItems;

	const titleElements = slideItems?.find(
		(element) => element.type === 'title'
	)?.slideItemElements;

	const textareaRef = useRef(null);

	const mainTitleElement = slideItems
		?.find((element) => element.type === 'title')
		?.slideItemElements?.find((element) => element.type === 'title');

	const subtitleElement = slideItems
		?.find((element) => element.type === 'title')
		?.slideItemElements?.find((element) => element.type === 'subtitle');
	const textContentElement = slideItems
		?.find((item) => item.type === 'text')
		?.slideItemElements?.find((element) => element.type === 'text');
	const imageElement = slideItems
		?.find((element) => element.type === 'image')
		?.slideItemElements?.find((element) => element.type === 'image_url');

	const imageUrl = imageElement?.content;

	const smallImageUrl = imageUrl && imageUrl + '_small';
	const mediumImageUrl = imageUrl && imageUrl + '_medium';

	const [slideContent, setSlideContent] = useState({
		title: mainTitleElement?.content,
		subtitle: subtitleElement?.content,
		text: textContentElement?.content,
	});

	useTextareaSize(textareaRef, slideContent.text);

	const handleClickOnImageElement = () => {
		dispatch(
			setShowSlideModalExpertPortfolio({
				isVisible: false,
				modalView: '',
			})
		);
		if (currentViewExpertPortfolio === 'imageSelectionView') {
			dispatch(
				setCurrentViewExpertPortfolioAndSelection({
					currentView: 'editionView',
					selectedElementId: null,
					selectedItemId: null,
					selectedSlideId: null,
				})
			);
		} else {
			dispatch(
				setCurrentViewExpertPortfolioAndSelection({
					selectedElementId: imageElement.slideItemElementId,
					currentView: 'imageSelectionView',
					selectedItemId: slideItems?.find(
						(element) => element.type === 'image'
					),
					selectedSlideId: slideData.slideId,
				})
			);
		}
	};

	if (!slideData) return null;

	if (isEdition) {
		return (
			<section className='flex flex-col gap-1 border-b-2 border-dashed p-2 py-4 pb-8'>
				{mainTitleElement && (
					<p className='text-right text-xs opacity-70'>Sección - Portada</p>
				)}

				{mainTitleElement && (
					<EditExpertInputText
						placeholderText={'Agrega un Título'}
						inputDesign={
							'text-xl font-louisGeorgeCafe font-bold uppercase md:text-2xl text-center'
						}
						inputValue={slideContent?.title || ''}
						onBlurAction={(e) => {
							mainTitleElement &&
								putElementOnBlur(
									dispatch,
									e.target.value,
									mainTitleElement.slideItemElementId,
									mainTitleElement.content
								);
						}}
						onChangeAction={(e) =>
							setSlideContent({ ...slideContent, title: e.target.value })
						}
					/>
				)}
				{subtitleElement && (
					<EditExpertInputText
						placeholderText={'Agrega un subtítulo'}
						inputDesign={
							'text-base font-louisGeorgeCafe font-bold md:text-xl text-center'
						}
						inputValue={slideContent?.subtitle || ''}
						onBlurAction={(e) =>
							subtitleElement &&
							putElementOnBlur(
								dispatch,
								e.target.value,
								subtitleElement.slideItemElementId,
								subtitleElement.content
							)
						}
						onChangeAction={(e) =>
							setSlideContent({ ...slideContent, subtitle: e.target.value })
						}
					/>
				)}
				{textContentElement && (
					<EditExpertTextarea
						placeholderText={'Agrega una descripción'}
						textareaRef={textareaRef}
						textareaValue={slideContent?.text || ''}
						onBlurAction={(e) =>
							textContentElement &&
							putElementOnBlur(
								dispatch,
								e.target.value,
								textContentElement.slideItemElementId,
								textContentElement.content
							)
						}
						onChangeAction={(e) =>
							setSlideContent({ ...slideContent, text: e.target.value })
						}
					/>
				)}

				{imageElement && (
					<LazyLoadImage
						effect='blur'
						src={mediumImageUrl}
						placeholderSrc={smallImageUrl}
						className={`mx-auto mt-2 cursor-pointer px-[5%] md:w-1/2 md:px-0
					${
						imageElement?.slideItemElementId ===
							expertPortfolioViewAndSelection.selectedElementId &&
						'border-4 border-yellow-50'
					} 
					`}
						onClick={handleClickOnImageElement}
					/>
				)}
			</section>
		);
	}

	return (
		<section
			className={`mx-[5%] flex flex-col ${
				isScaled ? 'gap-1' : 'gap-2 md:gap-4'
			}`}
		>
			{Array.isArray(titleElements) &&
				titleElements.length > 0 &&
				titleElements.map((element, index) => {
					switch (element.type) {
						case 'title':
							return (
								<ExpertSlideElementTitle
									title={element.content}
									designTitle={`font-bold uppercase ${
										isScaled ? 'text-xs pt-[5%]' : 'text-2xl'
									}`}
									key={index}
								/>
							);
						case 'subtitle':
							return (
								<ExpertSlideElementTitle
									title={element.content}
									designTitle={`font-bold ${
										isScaled ? 'text-xs scale-90' : 'text-2xl'
									}`}
									key={index}
								/>
							);
						default:
							return null;
					}
				})}

			<ExpertSlideElementText
				text={textContentElement?.content}
				designText={`${isScaled ? 'mb-[5%] text-[10px]' : 'mb-12'}`}
			/>

			<LazyLoadImage
				effect='blur'
				src={mediumImageUrl}
				placeholderSrc={smallImageUrl}
			/>
		</section>
	);
}

ExpertSlideCover.propTypes = {
	// contains the data to show
	slideData: PropTypes.object,
	// Is edition view or public view
	isEdition: PropTypes.bool,
	//Boolean that determines if the slide is scaled for a preview
	isScaled: PropTypes.bool,
};
