//3rd
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

//UI
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import CardSwitch from '../../../UI/molecules/Card/CardSwitch';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import { ReactComponent as PortfolioWhiteIcon } from '../../../UI/assets/icons/PortfolioWhite.svg';
import Cross from '../../../UI/assets/icons/Cross.svg';
import Check from '../../../UI/assets/icons/whiteCheck.svg';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import UnderlineHr from '../../../UI/atoms/UnderLine/UnderlineHr';

//BL
import { updateEditingCall } from '../../../BusinessLogic/redux/reducers/call';
import { Field, Form, Formik } from 'formik';
import { portfolioTypeOptions } from '../../../BusinessLogic/data/call';
import { usePutCall } from '../../../BusinessLogic/hooks/query/useQueryCalls';

/**
 * @description Renders the portfolio view of a call
 * @param {*} param0
 * @returns
 */
const CallPortfolio = () => {
	const { t } = useTranslation();
	const { slug } = useParams();
	const { editingCall } = useSelector((state) => state.call);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [hasPortfolio, setHasPortfolio] = useState(editingCall.has_portfolio);
	const putCallMutation = usePutCall();

	const handleSubmit = (values) => {
		dispatch(
			updateEditingCall({
				...values,
				has_portfolio: hasPortfolio,
			})
		);

		putCallMutation
			.mutateAsync({
				...values,
				has_portfolio: hasPortfolio,
			})
			.then(() => {
				navigate(`/convocatorias/${slug}`);
			});
	};

	return (
		<TemplateWithNavBar>
			<NavbarSecondaryNew
				title={'Portafolio'}
				textColor='text-purple-50 w-max'
				color='bg-purple-50'
				showDots={false}
				redirect={`/convocatorias/${slug}`}
				textBack={`${t('RegresarConvocatoria')}`}
				textBackDesign='pl-3 text-purple-50'
				arrowLeftColor='purple'
			/>
			<Formik
				initialValues={editingCall}
				onSubmit={(values) => {
					handleSubmit(values);
				}}
			>
				{(formProps) => (
					<Form>
						<div className='w-full'>
							<CardSwitch
								mainText={'Requerido'}
								secondText={
									'Puede conocer mejor el trabajo de los artistas si envían su portafolio.'
								}
								switched={hasPortfolio}
								setSwitched={setHasPortfolio}
							/>
							{hasPortfolio && (
								<div>
									<div className='mt-2 flex flex-col gap-1 py-2 '>
										<TextTitleSub
											title={'Tipo de portafolio'}
											textColor={'text-purple-50'}
										/>
										<UnderlineHr colour={'purple'} />
									</div>
									<TextContentSecond
										design={'my-2'}
										text={
											'Escoge el tipo de portafolio que quieres que los artistas envíen a la convocatoria.'
										}
									/>
									<div
										className={`flex ${
											editingCall.type === 'CATALOGUE'
												? 'flex-col'
												: 'flex-col-reverse'
										}`}
									>
										{portfolioTypeOptions.map((option) => (
											<label
												className={`my-3 flex cursor-pointer flex-row justify-start gap-2 p-2 ${
													formProps.values.type === option.value &&
													'bg-gray-200'
												}`}
											>
												<div className='flex aspect-square h-9 w-9 justify-center rounded-full bg-green-50'>
													<PortfolioWhiteIcon />
												</div>
												<div className='flex w-full flex-col gap-2'>
													<TextContentSecond
														text={option.title}
														design='text-purple-50'
														style={{ fontWeight: '700' }}
													/>
													<TextContentSecond text={option.subtitle} />
													<TextContentSecond text={option.description} />
												</div>
												<div className='flex flex-col items-end justify-between'>
													<Field
														name='type'
														type='radio'
														value={option.value}
														className={`h-5 w-5 cursor-pointer appearance-none rounded-full border-2 border-green-50 after:relative after:-top-1/4 after:text-white checked:bg-green-50 checked:after:content-['✓']`}
													/>
													{option.preview && (
														<Link
															to={option.preview}
															className='text-cyan-500 underline'
														>
															Visualizar
														</Link>
													)}
												</div>
											</label>
										))}
									</div>
								</div>
							)}
							{(hasPortfolio !== editingCall.has_portfolio ||
								formProps.values.type !== formProps.initialValues.type) && (
								<div className='mt-8 mb-16 flex w-full flex-row justify-center gap-3'>
									<ButtonFab
										label={t('Cancelar')}
										colour='white'
										action={() => navigate(`/convocatorias/${slug}`)}
										icon={Cross}
										type='button'
									/>
									<ButtonFab
										colour='green'
										label={t('Guardar')}
										icon={Check}
										type='submit'
										action={() => formProps.submitForm()}
									/>
								</div>
							)}
						</div>
					</Form>
				)}
			</Formik>
		</TemplateWithNavBar>
	);
};

export default CallPortfolio;
