import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_LOCATION } from '../../helpers/routes';
import { fetchGetData } from '../../hooks/fetchGetData';
import { singleLastLocationTabs } from '../../data/SingleLastLocation2';
import { updateTabsObject } from '../../helpers/SingleViewHelpers';
import { fetchPutData } from '../../hooks/fetchPutData';
import { fetchPutArrayImages } from '../../hooks/fetchPutArrayImages';

export const getLastLocation = createAsyncThunk(
	'lastLocations/fetchLastLocation',
	async (id) => {
		return await fetchGetData(`${ENDPOINTS_LOCATION.GET_LAST_LOCATION}${id}/`);
	}
);

export const putLastLocation = createAsyncThunk(
	'lastLocations/putlastLocations',
	async (input) => {
		const { values, id } = input;
		return await fetchPutData(
			`${ENDPOINTS_LOCATION.PUT_LAST_LOCATION}${id}/`,
			values
		);
	}
);

export const organizeImageGrid = createAsyncThunk(
	'lastLocations/organizeImageGrid',
	async (values) => {
		return await fetchPutArrayImages(values);
	}
);
const initialLastLocation = {
	name: 'ubicacion',
	details: 'piso',
	obras: [],
	main_picture_url:
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
};
const initialLastLocationSingleTabs = updateTabsObject(
	singleLastLocationTabs,
	initialLastLocation
);

const initialState = {
	singleLastLocation: null,
	status: null,
	lastLocationSingleTabs: initialLastLocationSingleTabs,
};

export const lastLocationSlice = createSlice({
	name: 'LastLocation',
	initialState,
	reducers: {},
	extraReducers: {
		[getLastLocation.pending]: (state) => {
			state.status = 'loading';
		},
		[getLastLocation.fulfilled]: (state, { payload }) => {
			let Single = payload;
			state.singleLastLocation = Single;
			state.lastLocationSingleTabs = updateTabsObject(
				state.lastLocationSingleTabs,
				Single
			);
			state.status = 'success';
		},
		[getLastLocation.rejected]: (state) => {
			state.status = 'failed';
		},
		[putLastLocation.pending]: (state) => {
			state.status = 'loading';
		},
		[putLastLocation.fulfilled]: (state) => {
			state.status = 'success';
		},
		[putLastLocation.rejected]: (state) => {
			state.status = 'failed';
		},
		// organize grid of Images
		[organizeImageGrid.pending]: (state) => {
			state.status = 'loading';
		},
		[organizeImageGrid.fulfilled]: (state, { payload }) => {
			state.status = 'success';
		},
		[organizeImageGrid.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});

// export const {  } = lastLocationSlice.actions;

export default lastLocationSlice.reducer;
