import React from 'react';
import SkeletonNavbarDesktop from '../../../molecules/Skeleton/SkeletonNavbarDesktop';
import SkeletonNavbarMobile from '../../../molecules/Skeleton/SkeletonNavbarMobile';

const NavbarNewSkeleton = () => {
	return (
		<div>
			<SkeletonNavbarDesktop />
			<SkeletonNavbarMobile />
		</div>
	);
};

export default NavbarNewSkeleton;
