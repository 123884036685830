import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_LOCATION } from '../../helpers/routes';
import { fetchGetData } from '../../hooks/fetchGetData';
import { singleRoomTabs } from '../../data/SingleLocationRoom2';
import { updateTabsObject } from '../../helpers/SingleViewHelpers';
import { fetchPutData } from '../../hooks/fetchPutData';
import { fetchPutArrayImages } from '../../hooks/fetchPutArrayImages';

export const getLocationRoom = createAsyncThunk(
	'locationsRoom/fetchLocationRoom',
	async (id) => {
		return await fetchGetData(`${ENDPOINTS_LOCATION.GET_LOCATION_ROOM}${id}/`);
	}
);

export const putLocationsRoom = createAsyncThunk(
	'locationsRoom/putLocationsRoom',
	async (input) => {
		const { values, id } = input;
		return await fetchPutData(
			`${ENDPOINTS_LOCATION.PUT_LOCATION_ROOM}${id}/`,
			values
		);
	}
);

export const organizeImageGrid = createAsyncThunk(
	'locationsRoom/organizeImageGrid',
	async (values) => {
		return await fetchPutArrayImages(values);
	}
);

const initialLocationRoom = {
	name: 'sala',
	floor: '',
	details: 'varios detalles',
	obras: [],
	last_locations: [],
	main_picture_url:
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
};
const initialLocationRoomSingleTabs = updateTabsObject(
	singleRoomTabs,
	initialLocationRoom
);

const initialState = {
	singleLocationRoom: null,
	status: null,
	locationRoomSingleTabs: initialLocationRoomSingleTabs,
};

export const locationRoomSlice = createSlice({
	name: 'locationRoom',
	initialState,
	reducers: {},
	extraReducers: {
		[getLocationRoom.pending]: (state) => {
			state.status = 'loading';
		},
		[getLocationRoom.fulfilled]: (state, { payload }) => {
			let Single = payload;
			state.singleLocationRoom = Single;
			state.locationRoomSingleTabs = updateTabsObject(
				state.locationRoomSingleTabs,
				Single
			);
			state.status = 'success';
		},
		[getLocationRoom.rejected]: (state) => {
			state.status = 'failed';
		},
		[putLocationsRoom.pending]: (state) => {
			state.status = 'loading';
		},
		[putLocationsRoom.fulfilled]: (state) => {
			state.status = 'success';
		},
		[putLocationsRoom.rejected]: (state) => {
			state.status = 'failed';
		},
		// organize grid of Images
		[organizeImageGrid.pending]: (state) => {
			state.status = 'loading';
		},
		[organizeImageGrid.fulfilled]: (state) => {
			state.status = 'success';
		},
		[organizeImageGrid.rejected]: (state) => {
			state.status = 'failed';
		},
	},
});

// export const {  } = locationRoomSlice.actions;

export default locationRoomSlice.reducer;
