import ButtonLinkAddRole from '../../atoms/Buttons/Link/ButtonLinkAddRole';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import { useTranslation } from 'react-i18next';

export default function AddRoleAndPermission({ design }) {
	const { t } = useTranslation();
	return (
		<div
			className={`${design} flex flex-row items-center justify-between px-6 pb-6`}
		>
			<TextContentPrincipal text={t('AñadirRolesYPermisos')} />
			<ButtonLinkAddRole />
		</div>
	);
}
