import { submitErrorToSlack } from '../helpers/SlackIntegrations';

export const fetchDeleteDataWithUser = async (url) => {
	const { token } = localStorage;
	let endpoint = '';

	return fetch(url, {
		method: 'DELETE',
		cache: 'no-cache',
		headers: {
			content_type: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
		.then(async (res) => {
			const response = await res.json();
			endpoint = res.url;
			if (res.status >= 500) {
				submitErrorToSlack(endpoint, response, 'DELETE');
			}
			return response;
		})
		.catch((error) => {
			if (error.response.status >= 500) {
				submitErrorToSlack(endpoint, error, 'DELETE');
			}
		});
};
