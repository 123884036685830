//3rd party
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';

// BL
import { updateSlideElement } from '../../../BusinessLogic/redux/reducers/editablePortfolio';
import { updateElementPosition } from '../../../BusinessLogic/helpers/editablePortfolio';
import { updateElementTextContent } from '../../../BusinessLogic/helpers/editablePortfolio';
// UI
import InputElementText from '../../atoms/Inputs/InputElementText';
import { useRef } from 'react';
import { useEffect } from 'react';

/**
 * It renders a draggable text element that can be edited
 * @returns A function that returns a component.
 */
const ElementText = ({
	outsideSlideBoundary,
	textContent,
	textSize,
	indexElement,
	currentPositionPercentage,
	positionDraggable,
	hasSelectionBorder,
	position,
	typeInput,
	slideContainerWidth,
	elementText,
	placeholder,
	setSelectedElementByIndex,
	selectedElementByIndex,
}) => {
	const dispatch = useDispatch();

	const handleDrag = (e, data) => {
		dispatch(
			updateSlideElement(
				updateElementPosition(
					data,
					indexElement,
					textElementDragHandler(e),
					'position',
					editablePortfolioSlide,
					currentPositionPercentage
				)
			)
		);
	};

	const { editablePortfolioSlide } = useSelector(
		(state) => state.editablePortfolio
	);
	const textElementDragHandler = (e) => {
		var rect = e.target.getBoundingClientRect();
		return { left: rect.left, top: rect.top };
	};

	const textElementInputHandler = (e) => {
		dispatch(
			updateSlideElement(
				updateElementTextContent(
					editablePortfolioSlide,
					indexElement,
					e.target.value
				)
			)
		);
	};

	const selectedElement = useRef();

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			if (
				selectedElementByIndex === indexElement &&
				!selectedElement.current.contains(e.target)
			) {
				setSelectedElementByIndex(null);
			}
		};
		document.addEventListener('click', checkIfClickedOutside);

		return () => {
			document.removeEventListener('click', checkIfClickedOutside);
		};
	}, [indexElement, selectedElementByIndex, setSelectedElementByIndex]);

	return (
		<>
			<Draggable
				position={positionDraggable}
				bounds='#slideContainer'
				onDrag={(e, data) => {
					!outsideSlideBoundary && handleDrag(e, data);
				}}
			>
				<div
					className={` absolute cursor-move ${
						hasSelectionBorder && 'border-2 border-dashed border-grey-50'
					}`}
					ref={selectedElement}
					onTouchStart={(e) => {
						setSelectedElementByIndex(indexElement);
					}}
					onClick={() => {
						setSelectedElementByIndex(indexElement);
					}}
				>
					<InputElementText
						typeInput={typeInput}
						placeholder={placeholder}
						value={textContent}
						textSize={textSize}
						textElementInputHandler={textElementInputHandler}
						slideContainerWidth={slideContainerWidth}
						elementText={elementText}
					/>
				</div>
			</Draggable>
		</>
	);
};

ElementText.propTypes = {
	/** Pass the text to the component */
	textContent: PropTypes.string,
	/** Assign font size to text */
	textSize: PropTypes.string,
	/* A unique identifier for each artwork element. */
	indexElement: PropTypes.number,
	/* A function that is passed to the component to set the current position of the artwork element to a percentage value  */
	currentPositionPercentage: PropTypes.func,
	/**Boolean prop to know if the mouse is outside Slide Boundary */
	outsideSlideBoundary: PropTypes.bool,
};

export default ElementText;
