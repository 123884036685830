export const messageDeleteElement = (
	section,
	info = [
		{
			artworks: 0,
			images: 0,
			documents: 0,
			portfolios: 0,
			is_part_postulations: false,
		},
	]
) => {
	switch (section) {
		case 'artistas':
			return `También se eliminarán las imágenes (${info.images}), obras (${info.artworks}), colecciones (${info.collections}), portafolios (${info.portfolios}), documentos (${info.documents}) relacionados al artista.`;

		case 'obras':
			if (info.is_part_postulations) {
				return '¿Estás seguro de que quieres eliminar esta obra que ya forma parte del portafolio con el que te postulaste a una convocatoria y quedaste aprobado o guardado?';
			} else {
				return `También se eliminarán las imágenes (${info.images}) relacionadas a la obra.`;
			}

		case 'contactos':
			return `"También se eliminarán las imágenes (${info.images}) relacionadas al contacto."`;

		case 'documentos':
			return `También se eliminarán PDF, imágenes y videos relacionadas al documento.`;

		case 'colecciones':
			return `También se eliminarán los portafolios (${info.portfolios}) relacionados a esta colección.`;

		case 'lugares':
			return `También se eliminarán los espacios (${info.espacios}) e imágenes (${info.images}) relacionadas al lugar, las obras relacionadas a este lugar van a ser asignadas a un lugar desconocido.`;

		case 'salas':
			return `También se eliminarán las ubicaciones relacionadas con esta sala.`;

		case 'ubicacion':
			return '¿ Seguro que deseas eliminar esta ubicacion?';

		case 'portafolios':
			return '¿ Seguro que deseas eliminar este portafolio ?';

		default:
			return '';
	}
};
