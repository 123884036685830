export const portfolioTypeOptions = [
	{
		title: 'Catálogo prediseñado en Datarte',
		subtitle: 'Datarte creó un formato para esto.',
		description:
			'Los artistas deberán escoger sus obras y Datarte creará una plantilla con estas obras.',
		value: 'CATALOGUE',
		preview: '',
	},
	{
		title: 'Portafolio libre',
		subtitle: 'Portafolio creado libremente por el artista.',
		description: 'El artistas escogerá el formato que quiera.',
		value: 'FREE',
		preview: '/plantilla/portafolio_basico',
	},
];
