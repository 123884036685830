import React from 'react';

const SkeletonNavbarMobile = () => {
	return (
		<div className='flex h-20 w-full items-center justify-between  px-2 md:hidden'>
			<div className='skeleton-animation-background-light h-8 w-8  animate-skeleton bg-light_grey-50 '></div>
			<div className='skeleton-animation-background-light h-8 w-8 animate-skeleton bg-light_grey-50 '></div>
			<div className='skeleton-animation-background-light h-8 w-8 animate-skeleton rounded-full bg-light_grey-50'></div>
		</div>
	);
};

export default SkeletonNavbarMobile;
