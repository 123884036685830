// 3rd party
import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
//UI
import { ReactComponent as Close } from '../../assets/icons/CrossCircle.svg';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import { ReactComponent as LeftArrow } from '../../assets/icons/CaretLeft.svg';
import ButtonWithIcon from '../../molecules/Buttons/WithIcon/ButtonWithIcon';
import MainLogoDesktop from '../../molecules/MainLogoDesktop';
import logoDatarte from '../../assets/icons/logoDatarte.svg';

/**
 * @description SubMenu Modal that show in mobile device
 * @param {*} param0
 * @returns
 */

export default function SubMenu({
	subMenuClose,
	subMenuItems,
	title,
	setShowMenu,
	handleCloseMenu,
}) {
	const { t } = useTranslation();
	return (
		<div className='fixed top-0 left-0 z-20 flex h-screen w-full flex-col justify-between bg-green-50 p-4 sm:p-12'>
			<div className='h-1/6'>
				<Close onClick={() => setShowMenu(false)} className='cursor-pointer' />
			</div>
			<div className='flex h-3/5 w-fit-content flex-col gap-10'>
				<div className='flex w-full flex-row'>
					<div className='mr-4'>
						<LeftArrow
							className='h-6 w-6 cursor-pointer'
							onClick={() => subMenuClose(false)}
						/>
					</div>
					<TextTitleSub
						textColor='text-white'
						title={title}
						design='font-bold'
					/>
				</div>
				<div className='z-10 flex w-full flex-col justify-between gap-8 px-4 text-center text-white'>
					{subMenuItems.map((item) => (
						<div
							className='flex flex-row items-center'
							onClick={() => handleCloseMenu()}
						>
							<ButtonWithIcon icon={item.icon} design='mr-4' />
							<Link to={item.url}>{t(item.name)}</Link>
						</div>
					))}
				</div>
			</div>
			<div className='flex h-1/4 w-full flex-col-reverse justify-between'>
				<MainLogoDesktop
					design='self-center pb-8'
					image={logoDatarte}
					alt='datarte.art'
					action={() => handleCloseMenu()}
				/>
			</div>
		</div>
	);
}

SubMenu.propTypes = {
	/** array of objects that contents the name of the option and nested options */
	subMenuItems: PropTypes.array,
	/** title of the option with suboptions selected */
	title: PropTypes.string,
	/** this function that close the Menu principal */
	setShowMenu: PropTypes.func,
	/** this function that close the Sub-menu and return to principal menu */
	subMenuClose: PropTypes.func,
	/* function that close the menu and clear history log url */
	handleCloseMenu: PropTypes.func,
};

SubMenu.defaultProps = {};
