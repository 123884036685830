// UI
import ExpertSlideCover from '../../organisms/Portfolio/ExpertSlides/ExpertSlideCover';
import ExpertSlideTextBlockAndImage from '../../organisms/Portfolio/ExpertSlides/ExpertSlideTextBlockAndImage';
import ExpertSlideTextBlock from '../../organisms/Portfolio/ExpertSlides/ExpertSlideTextBlock';
import ExpertSlideArtworkIndividual from '../../organisms/Portfolio/ExpertSlides/ExpertSlideArtworkIndividual';
import ExpertSlideArtworksGroup from '../../organisms/Portfolio/ExpertSlides/ExpertSlideArtworksGroup';
import ExpertSlideBackCover from '../../organisms/Portfolio/ExpertSlides/ExpertSlideBackCover';
import ExpertSlideFullScreenImage from '../../organisms/Portfolio/ExpertSlides/ExpertSlideFullScreenImage';
import { sortItemsByOrder } from '../../../BusinessLogic/helpers/sortData';

/**
 * @description Public View of the Expert Portfolio
 * @returns
 */

const ExpertPortfolio = ({ portfolio }) => {
	const portfolioSlides = portfolio?.slides;
	const slidesHaveContent =
		Array.isArray(portfolioSlides) && portfolioSlides.length > 0;

	return (
		<div className='h-full w-full bg-white pb-16 pt-12 md:pb-8'>
			<div className='mx-auto flex max-w-[90%] flex-col gap-8 bg-white py-8 md:max-w-[70%] md:pb-8 2xl:max-w-[50%]'>
				{slidesHaveContent &&
					sortItemsByOrder([...portfolioSlides]).map((slide, index) => {
						switch (slide.type) {
							case 'cover':
								return (
									<ExpertSlideCover
										key={index}
										slideData={slide}
										isEdition={false}
									/>
								);
							case 'textBlock':
								return (
									<ExpertSlideTextBlock
										key={index}
										slideData={slide}
										isEdition={false}
									/>
								);
							case 'textBlockAndImage':
								return (
									<ExpertSlideTextBlockAndImage
										key={index}
										slideData={slide}
										isEdition={false}
									/>
								);
							case 'artworkIndividual':
								return (
									<ExpertSlideArtworkIndividual
										key={index}
										slideData={slide}
										isEdition={false}
									/>
								);
							case 'artworksGroup':
								return (
									<ExpertSlideArtworksGroup
										key={index}
										isEdition={false}
										slideData={slide}
									/>
								);
							case 'backCover':
								return (
									<ExpertSlideBackCover
										key={index}
										isEdition={false}
										slideData={slide}
									/>
								);
							case 'fullScreenImage':
								return (
									<ExpertSlideFullScreenImage
										key={index}
										isEdition={false}
										slideData={slide}
									/>
								);

							default:
								return null;
						}
					})}
			</div>
		</div>
	);
};

export default ExpertPortfolio;
