//3rd party
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//BL
import { dateShortFormat } from '../../../BusinessLogic/helpers/dateFormats';
import { useGetAllPostulationsByRole } from '../../../BusinessLogic/hooks/query/useQueryPostulation';
import {
	filterPostulationGalleryInitialValues,
	filterPostulationArtistInitialValues,
} from '../../../BusinessLogic/data/PostulationData';

//UI
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import SectionPostulationsGrid from '../../../UI/organisms/Section/SectionPostulationsGrid';
import FilterAllPostulation from '../../../UI/molecules/Filters/FilterAllPostulation';
import PortfolioLoading from '../../../UI/organisms/Loading/PortfolioLoading';

/**
 * @description render all postulations of the artist or organization
 * @param {*} param0
 * @returns
 */

const AllPostulation = () => {
	const { t } = useTranslation();
	const { appUser } = useSelector((state) => state.appUser);

	const { postulations } = useGetAllPostulationsByRole({
		organization_user_id: appUser.organization_user_id,
		role_company: appUser.role_company,
	});

	const [postulationsFiltered, setPostulationsFiltered] =
		useState(postulations);

	const isGalleryUser = appUser.role_company === 'GALLERY';
	const isArtistUser = appUser.role_company === 'ARTIST';
	const isCatalogueArtistUser = appUser.role_company === 'ARTIST_CATALOGATION';
	const filterInitialValues = () => {
		if (isGalleryUser) {
			return filterPostulationGalleryInitialValues;
		} else if (isArtistUser || isCatalogueArtistUser) {
			return filterPostulationArtistInitialValues;
		}
	};

	const [filterParameters, setFilterParameters] = useState(
		filterInitialValues()
	);

	const sortPostulations = (parameter, postulationsToSort) => {
		switch (parameter) {
			case 'date_ascending':
				return [...postulationsToSort].sort((a, b) => {
					const dateA = new Date(a?.updated_at ?? '');
					const dateB = new Date(b?.updated_at ?? '');
					return dateB - dateA;
				});
			case 'date_descending':
				return [...postulationsToSort].sort((a, b) => {
					const dateA = new Date(a?.updated_at ?? '');
					const dateB = new Date(b?.updated_at ?? '');
					return dateA - dateB;
				});
			case 'name_ascending':
				return [...postulationsToSort].sort((a, b) => {
					const nameA = a?.call_name ?? '';
					const nameB = b?.call_name ?? '';
					return nameA.localeCompare(nameB);
				});
			case 'name_descending':
				return [...postulationsToSort].sort((a, b) => {
					const nameA = a?.call_name ?? '';
					const nameB = b?.call_name ?? '';
					return nameB.localeCompare(nameA);
				});
			default:
				return postulationsToSort;
		}
	};

	useEffect(() => {
		let sortedAndFilteredPostulations = postulations;

		if (filterParameters?.category) {
			sortedAndFilteredPostulations = sortedAndFilteredPostulations.filter(
				(postulation) =>
					(postulation.category ?? '').includes(filterParameters.category)
			);
		}

		if (filterParameters?.technique) {
			sortedAndFilteredPostulations = sortedAndFilteredPostulations.filter(
				(postulation) =>
					(postulation.technique ?? '').includes(filterParameters.technique)
			);
		}

		if (filterParameters?.country) {
			sortedAndFilteredPostulations = sortedAndFilteredPostulations.filter(
				(postulation) =>
					String(postulation.country_id) === filterParameters.country
			);
		}

		if (filterParameters?.city) {
			sortedAndFilteredPostulations = sortedAndFilteredPostulations.filter(
				(postulation) =>
					postulation.city?.localeCompare(filterParameters.city, 'en', {
						sensitivity: 'base',
					}) === 0
			);
		}

		if (filterParameters?.name) {
			sortedAndFilteredPostulations = sortedAndFilteredPostulations.filter(
				(postulation) =>
					postulation.call_name
						?.toLocaleLowerCase()
						.includes(filterParameters.name.toLocaleLowerCase())
			);
		}

		if (filterParameters?.organization) {
			sortedAndFilteredPostulations = sortedAndFilteredPostulations.filter(
				(postulation) =>
					postulation.call_organizer_name
						?.toLocaleLowerCase()
						.includes(filterParameters.organization.toLocaleLowerCase())
			);
		}

		if (filterParameters?.date) {
			sortedAndFilteredPostulations = sortedAndFilteredPostulations.filter(
				(postulation) =>
					dateShortFormat(`${filterParameters.date} 00:00:00`) ===
					dateShortFormat(postulation.closes_at)
			);
		}

		if (filterParameters?.sorting) {
			sortedAndFilteredPostulations = sortPostulations(
				filterParameters.sorting,
				sortedAndFilteredPostulations
			);
		}

		if (filterParameters?.artist_id) {
			sortedAndFilteredPostulations = sortedAndFilteredPostulations.filter(
				(postulation) =>
					Number(postulation.artist_id) === Number(filterParameters.artist_id)
			);
		}

		setPostulationsFiltered(sortedAndFilteredPostulations);
	}, [filterParameters, postulations]);

	return (
		<TemplateWithNavBar>
			{!postulations ? (
				<PortfolioLoading />
			) : (
				<div className='mb-4 min-h-[50vh]'>
					<div className='flex w-full items-center'>
						<NavbarSecondaryNew
							title={t('MisPostulaciones')}
							color='bg-light_green-50'
							textColor='text-light_green-50'
							redirect='/convocatorias'
							section='convocatorias'
							showDots={false}
							textBack={t('RegresarAConvocatorias')}
							textBackDesign='pl-3 text-light_green-50'
							arrowLeftColor='light_green'
						/>
						<FilterAllPostulation
							initialFilterParameters={filterInitialValues()}
							filterParameters={filterParameters}
							setFilterParameters={setFilterParameters}
						/>
					</div>
					<SectionPostulationsGrid
						data={postulationsFiltered}
						redirect='postulaciones'
					/>
				</div>
			)}
		</TemplateWithNavBar>
	);
};

export default AllPostulation;
