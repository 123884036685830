import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_ARTIST, ENDPOINTS_COLLECTION } from '../../helpers/routes';
import { fetchGetData } from '../../hooks/fetchGetData';
import { fetchGetDataWithValues } from '../../hooks/fetchGetDataWithValues';
import {
	collectionSingleTabs,
	galleryCollectionSingleTabs,
} from '../../data/CollectionSingle';
import {
	updateDropdowns,
	updateTabsObject,
} from '../../helpers/SingleViewHelpers';
import { fetchPutData } from '../../hooks/fetchPutData';
import { fetchPutArrayImages } from '../../hooks/fetchPutArrayImages';
import postDataAndImage from '../../helpers/postDataAndImage';

export const getCollections = createAsyncThunk(
	'collections/fetchCollections',
	async () => {
		const { organization_user_id } = localStorage;
		return await fetchGetData(
			`${ENDPOINTS_COLLECTION.GET_COLLECTIONS}${organization_user_id}/`
		);
	}
);

export const getCollection = createAsyncThunk(
	'collections/fetchCollection',
	async (id) => {
		const { organization_user_id } = localStorage;
		const data = {
			organization_user_id: organization_user_id,
			current_collection_id: id,
		};
		return await fetchGetDataWithValues(
			ENDPOINTS_COLLECTION.GET_COLLECTION,
			data
		);
	}
);

export const getAlbums = createAsyncThunk(
	'collections/fetchAlbums',
	async (id) => {
		const { organization_user_id } = localStorage;
		const data = { organization_user_id: organization_user_id };
		return await fetchGetDataWithValues(
			`${ENDPOINTS_COLLECTION.GET_COLLECTION_ALBUM}${id}/`,
			data
		);
	}
);

export const getPublicAlbums = createAsyncThunk(
	'collections/fetchAlbums',
	async (id) => {
		return await fetchGetData(
			`${ENDPOINTS_COLLECTION.GET_PUBLIC_COLLECTION_ALBUM}${id}/`
		);
	}
);

export const getPublicCollections = createAsyncThunk(
	'collections/fetchCollections',
	async (id) => {
		return await fetchGetData(
			`${ENDPOINTS_COLLECTION.GET_PUBLIC_COLLECTIONS}${id}/`
		);
	}
);

export const getPublicCollection = createAsyncThunk(
	'collections/fetchCollection',
	async (id) => {
		return await fetchGetData(
			`${ENDPOINTS_COLLECTION.GET_PUBLIC_COLLECTION}${id}/`
		);
	}
);

export const postCollection = createAsyncThunk(
	'collections/postCollection',

	async (values) => {
		return await postDataAndImage(ENDPOINTS_COLLECTION.POST_COLLECTION, values);
	}
);

export const postCollectionAlbumFiles = createAsyncThunk(
	'collections/postAlbumFiles',
	async (input) => {
		const { values, id } = input;
		return await fetchGetDataWithValues(
			ENDPOINTS_COLLECTION.POST_COLLECTION_ALBUM + id + '/',
			values
		);
	}
);

export const putCollection = createAsyncThunk(
	'collections/putCollection',
	async (input) => {
		const { values, id } = input;

		return await fetchPutData(
			`${ENDPOINTS_COLLECTION.PUT_COLLECTION}${id}/`,
			values
		);
	}
);

export const organizeImageGrid = createAsyncThunk(
	'collections/organizeImageGrid',
	async (values) => {
		return await fetchPutArrayImages(values);
	}
);

export const organizeArtworkGrid = createAsyncThunk(
	'collections/organizeArtworkGrid',
	async (values) => {
		return await fetchPutData(
			`${ENDPOINTS_COLLECTION.PUT_ORDER_ARTWORKS}${values.id}/`,
			values
		);
	}
);

export const organizeSubCollections = createAsyncThunk(
	'collections/organizeSubCollections',
	async (values) => {
		const { organization_user_id, sort_subcollections } = values;
		return await fetchPutData(
			`${ENDPOINTS_COLLECTION.POST_COLLECTION}${values.id}${ENDPOINTS_COLLECTION.PUT_ORDER_SUBCOLLECTIONS}`,
			{
				organization_user_id,
				sort_subcollections,
			}
		);
	}
);

export const getGalleryArtistsList = createAsyncThunk(
	'collection/getOrganizationArtistList',
	async () => {
		const { organization_user_id } = localStorage;
		const allArtists = await fetchGetData(
			`${ENDPOINTS_ARTIST.GET_ARTISTS_WITH_LABELS}${organization_user_id}/`
		);

		return allArtists;
	}
);

const initialSingleCollection = {
	name: 'colleccion',
	description: 'aqui descripcion',
	dates: '2022-01-01',
	main_picture_url:
		'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user19/1673',
	main_picture_id: 1,
};
const initialCollectionSingleTabs = updateTabsObject(
	collectionSingleTabs,
	initialSingleCollection
);

const initialNewCollection = {
	name: '',
	description: '',
	dates: '',
	organization_user_id: '',
	type: 'COLLECTION',
	type_id: 0,
	collection_type: 'COLLECTION',
	file_type: 'IMAGE',
	artwork: [],
	document: [],
	parent_id: '',
};

const initialState = {
	collections: [],
	collectionSingle: null,
	subCollecNavi: [],
	newCollection: initialNewCollection,
	album: [],
	collectionState: true,
	status: null,
	collectionSingleTabs: initialCollectionSingleTabs,
	galleryCollectionSingleTabs: galleryCollectionSingleTabs,
	galleryArtistsList: [],
};

export const collectionSlice = createSlice({
	name: 'collections',
	initialState,
	reducers: {
		changeCollectionState: (state, { payload }) => {
			state.collectionState = payload;
		},
		saveCollectionNav: (state, { payload }) => {
			state.subCollecNavi = payload;
		},
		getCollectionEdit: (state, { payload }) => {
			state.collectionSingle = payload;
		},
		cleanCollectionEdit: (state) => {
			state.collectionSingle = [];
		},
		updateNewCollection: (state, { payload }) => {
			state.newCollection = payload;
		},
		cleanNewCollection: (state) => {
			state.newCollection = initialNewCollection;
		},
		resetCollectionSingle: (state) => {
			state.collectionSingle = null;
		},
	},
	extraReducers: {
		[getCollections.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getCollections.fulfilled]: (state, { payload }) => {
			let collections = payload;
			const defaultCollection =
				Array.isArray(payload) &&
				payload.find((collection) => collection.is_general_collection);
			if (defaultCollection) {
				const filteredCollections = collections.filter(
					(collection) => collection !== defaultCollection
				);
				collections = [defaultCollection, ...filteredCollections];
			}
			state.collections = collections;
			state.status = 'success';
		},
		[getCollections.rejected]: (state, action) => {
			state.status = 'failed';
		},
		[getAlbums.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getAlbums.fulfilled]: (state, { payload }) => {
			state.album = payload;
			state.status = 'success';
		},
		[getAlbums.rejected]: (state, action) => {
			state.status = 'failed';
		},
		[getCollection.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getCollection.fulfilled]: (state, { payload }) => {
			let Single = payload.collection_information;
			state.collectionSingle = Single;
			state.status = 'success';
			state.collectionSingleTabs = updateTabsObject(
				state.collectionSingleTabs,
				Single
			);
			state.galleryCollectionSingleTabs = updateTabsObject(
				state.galleryCollectionSingleTabs,
				Single
			);
			state.galleryCollectionSingleTabs = updateDropdowns(
				state.galleryCollectionSingleTabs,
				state.galleryArtistsList,
				0,
				1,
				0
			);
		},
		[getCollection.rejected]: (state, action) => {
			state.status = 'failed';
		},
		// get organization artists list
		[getGalleryArtistsList.pending]: (state) => {
			state.status = 'loading';
		},
		[getGalleryArtistsList.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.galleryArtistsList = payload;
		},
		[getGalleryArtistsList.rejected]: (state) => {
			state.status = 'failed';
		},
		// put collection
		[putCollection.pending]: (state, action) => {
			state.status = 'loading';
		},
		[putCollection.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.collectionChanges += 1;
		},
		[putCollection.rejected]: (state, action) => {
			state.status = 'failed';
		},
		//Post collection
		[postCollection.pending]: (state, action) => {
			state.status = 'loading';
		},
		[postCollection.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
			state.collectionChanges += 1;
		},
		[postCollection.rejected]: (state, action) => {
			state.status = 'failed';
		},

		//Post collection album files
		[postCollectionAlbumFiles.pending]: (state, action) => {
			state.status = 'loading';
		},
		[postCollectionAlbumFiles.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
			state.collectionChanges += 1;
		},
		[postCollectionAlbumFiles.rejected]: (state, action) => {
			state.status = 'failed';
		},

		// organize grid of Images
		[organizeImageGrid.pending]: (state, action) => {
			state.status = 'loading';
		},
		[organizeImageGrid.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
			state.documentChanges += 1;
		},
		[organizeImageGrid.rejected]: (state, action) => {
			state.status = 'failed';
		},
		// organize grid of Artworks
		[organizeArtworkGrid.pending]: (state, action) => {
			state.status = 'loading';
		},
		[organizeArtworkGrid.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
			state.documentChanges += 1;
		},
		[organizeArtworkGrid.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});

export const {
	changeCollectionState,
	saveCollectionNav,
	getCollectionEdit,
	cleanCollectionEdit,
	updateNewCollection,
	cleanNewCollection,
	resetCollectionSingle,
} = collectionSlice.actions;

export default collectionSlice.reducer;
