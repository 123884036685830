import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//UI
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import UnderlineHr from '../../atoms/UnderLine/UnderlineHr';
import InputGreenCheckbox from '../../atoms/Inputs/InputGreenCheckbox';

const CardPortfolio = ({
	name,
	image,
	action,
	colorLineHr,
	designContainer,
	showCheckboxInput,
	checkboxInputName,
	checkboxInputValue,
}) => {
	const { t } = useTranslation();
	let defaultImage =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';
	return (
		<div className={`flex w-full flex-col py-4 ${designContainer}`}>
			<LazyLoadImage
				alt={name}
				src={image ?? defaultImage}
				effect='blur'
				wrapperClassName={`max-h-full max-w-full lazyLoadingImages`}
				delayTime='0'
				threshold='800'
				className='object-cover'
			/>
			<div className='flex w-full flex-col gap-6'>
				<div className='flex w-full items-center justify-between py-2'>
					<label className='flex flex-row items-center gap-2'>
						{showCheckboxInput && (
							<InputGreenCheckbox
								name={checkboxInputName}
								value={checkboxInputValue}
							/>
						)}
						<TextContentPrincipal text={name} style={{ fontWeight: 400 }} />
					</label>
					<div onClick={action}>
						<TextContentPrincipal
							text={t('VerPortafolio')}
							design={'text-blue-500 cursor-pointer'}
							style={{ fontWeight: 400 }}
						/>
					</div>
				</div>
			</div>
			<UnderlineHr design={'w-full mt-4'} colour={colorLineHr ?? 'purple'} />
		</div>
	);
};

export default CardPortfolio;

CardPortfolio.propTypes = {
	/* name that identifies the portfolio */
	name: PropTypes.string,
	/* styles for our component */
	designContainer: PropTypes.string,
	/* bottom line color */
	colorLineHr: PropTypes.string,
	/** function triggered when the button is clicked */
	action: PropTypes.func,
	/**Src of the image of the card */
	image: PropTypes.string,
	/** Determines if the checkbox input should be displayed */
	showCheckboxInput: PropTypes.bool,
	/** Determines the field name of the checkbox input*/
	checkboxInputName: PropTypes.string,
	/** Determines the field value of the checkbox input */
	checkboxInputValue: PropTypes.string,
};

CardPortfolio.defaultProps = {
	showCheckboxInput: false,
};
