//BL
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_PORTFOLIO } from '../../helpers/routes';
import { fetchGetData } from '../../hooks/fetchGetData';
import { fetchPostData } from '../../hooks/fetchPostData';
import { fetchPutData } from '../../hooks/fetchPutData';

export const postPortfolioV2 = createAsyncThunk(
	'portfolio/postPortfolioV2',

	async (values) => {
		return await fetchPostData(ENDPOINTS_PORTFOLIO.POST_PORTFOLIO_V2, values);
	}
);

export const getPortfolios = createAsyncThunk(
	'portfolio/getPortfolios',
	async () => {
		const { organization_user_id } = localStorage;
		return await fetchGetData(
			`${ENDPOINTS_PORTFOLIO.GET_PORTFOLIOS}${organization_user_id}/`
		);
	}
);

export const putPortfolioV2 = createAsyncThunk(
	'portfolio/putPortfolioV2',
	async (input) => {
		const { id, values } = input;
		return await fetchPutData(
			`${ENDPOINTS_PORTFOLIO.PUT_PORTFOLIO}${id}/`,
			values
		);
	}
);

export const getPortfolioV2 = createAsyncThunk(
	'portfolio/getPortfolio',
	async (id) => {
		return await fetchGetData(`${ENDPOINTS_PORTFOLIO.GET_PORTFOLIO_V2}${id}/`);
	}
);

export const getPortfolioBySlugV2 = createAsyncThunk(
	'portfolio/getPortfolioBySlug',
	async (slug) => {
		return await fetchGetData(
			`${ENDPOINTS_PORTFOLIO.GET_PORTFOLIO_BY_SLUG_V2}${slug}/`
		);
	}
);

const initialSinglePortfolioV2 = {
	created_at: null,
	updated_at: null,
	id: null,
	image_quality: 'HIGH',
	information: {
		content: [],
	},
};

const initialState = {
	portfolios: [],
	portfolioId: null,
	loadingPortfolio: false,
	portfolioPreview: false,
	portfoliosChanges: 0,
	singlePortfolioV2: initialSinglePortfolioV2,
	status: null,
};

export const portfolioV2Slice = createSlice({
	name: 'portfolioV2',
	initialState,
	reducers: {
		setPortfolioId: (state, { payload }) => {
			state.portfolioId = payload;
		},
		deleteAllPortfolio: (state, { payload }) => {
			state.portfolios = [];
		},
	},

	extraReducers: {
		[postPortfolioV2.pending]: (state, action) => {
			state.status = 'loading';
		},
		[postPortfolioV2.fulfilled]: (state, { payload }) => {
			state.status = 'success';
		},
		[postPortfolioV2.rejected]: (state, action) => {
			state.status = 'failed';
		},

		//2. get portfolios by organization id
		[getPortfolios.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getPortfolios.fulfilled]: (state, { payload }) => {
			state.portfolios = payload;
			state.status = 'success';
		},
		[getPortfolios.rejected]: (state, action) => {
			state.status = 'failed';
		},

		//3. put portfolios
		[putPortfolioV2.pending]: (state, action) => {
			state.status = 'loading';
		},
		[putPortfolioV2.fulfilled]: (state, { payload }) => {
			state.singlePortfolio = payload;
			state.status = 'success';
		},
		[putPortfolioV2.rejected]: (state, action) => {
			state.status = 'failed';
		},

		//4. get portfolio by id
		[getPortfolioV2.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getPortfolioV2.fulfilled]: (state, { payload }) => {
			let Single = payload;
			state.singlePortfolioV2 = Single;
			state.status = 'success';
		},
		[getPortfolioV2.rejected]: (state, action) => {
			state.status = 'failed';
		},

		//5. get portfolio by slug
		[getPortfolioBySlugV2.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getPortfolioBySlugV2.fulfilled]: (state, { payload }) => {
			state.portfolioPublic = payload;
			state.status = 'success';
		},
		[getPortfolioBySlugV2.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});

export const {
	setPortfolioId,
	increasePortfolioChanges,
	increaseInitialPortfolioChanges,
} = portfolioV2Slice.actions;

export default portfolioV2Slice.reducer;
