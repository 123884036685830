export const SINGLE_ES = {
	Identificación: 'Identificación',
	Descripción: 'Descripción',
	Imágenes: 'Imágenes',
	Contacto: 'Contacto',
	Biografía: 'Biografía',
	Educación: 'Educación',
	Residencias: 'Residencias',
	Exposiciones: 'Exposiciones',
	Publicaciones: 'Publicaciones',
	ProyectosEspeciales: 'Proyectos Especiales',
	Nombres: 'Nombres',
	Nombre: 'Nombre',
	Apellidos: 'Apellidos',
	PaisDeOrigen: 'País de origen',
	Teléfono: 'Teléfono',
	Email: 'Email',
	PaginaWeb: 'Website',
	Código: 'Código',
	OtroCodigo: 'Otro código',
	TipoDeObjeto: 'Tipo de objeto',
	BiDimensional: 'Bi-dimensional',
	TriDimensional: 'Tri-dimensional',
	Diferencias: 'Diferencias',
	Artista: 'Artista',
	Autor: 'Autor',
	Observaciones: 'Observaciones',
	Fecha: 'Fecha',
	FichaTecnica: 'Ficha técnica',
	DimensionesObjeto: 'Dimensiones objeto',
	UnidadDeMedida: 'Unidad de medida',
	Altura: 'Altura',
	Largo: 'Largo',
	Ancho: 'Ancho',
	Peso: 'Peso',
	DimensionesBase: 'Dimensiones base',
	Componentes: 'Componentes',
	TieneSubcomponentes: '¿Tiene subcomponentes?',
	Si: 'Sí',
	No: 'No',
	NumeroDeComponentes: 'Número de componentes',
	Detalles: 'Detalles',
	TecnicaYMateriales: 'Técnica y Materiales',
	Técnica: 'Técnica',
	Medida: 'Medida',
	Soporte: 'Soporte',
	Materiales: 'Materiales',
	EstadoDeConservacion: 'Estado de conservación',
	Otros: 'Otros',
	Propietario: 'Propietario',
	Estatus: 'Estatus',
	Conservado: 'Conservado',
	Vendido: 'Vendido',
	Donado: 'Donado',
	Disponible: 'Disponible',
	Desconocido: 'Desconocido',
	Ubicación: 'Ubicación',
	Lugar: 'Lugar',
	Sala: 'Sala',
	Avalúo: 'Avalúo',
	Precio: 'Precio',
	Moneda: 'Moneda',
	DigitalizacionDeLaObra: 'Digitalización de la obra',
	FechaDeElaboracion: 'Fecha de elaboración',
	UltimaActualizacion: 'Última actualización',
	Archivos: 'Archivos',
	Subcolecciones: 'Sub colecciones',
	OtroNumeroTelefono: 'Otro número de teléfono',
	Pertenece: 'Pertenece',
	Título: 'Título',
	Acceso: 'Acceso',
	Resolución: 'Resolución',
	Eliminar: 'Eliminar',
	RegresarHome: 'Regresar a Home',
	RegresarArtistas: 'Regresar a Artistas',
	RegresarPerfil: 'Regresar a Perfil',
	RegresarObras: 'Regresar a Obras',
	RegresarConvocatorias: 'Regresar a Convocatorias',
	RegresarContactos: 'Regresar a Contactos',
	RegresarDocumentos: 'Regresar a Documentos',
	RegresarExposiciones: 'Regresar a Exposiciones',
	RegresarSala: 'Regresar a Sala',
	RegresarLugares: 'Regresar a Lugares',
	RegresarLugar: 'Regresar a Lugar',
	RegresarPortafolios: 'Regresar a Portafolios',
	EliminarPerfil: 'Eliminar Perfil',
	SeguroQueDeseasEliminarEstePerfil: '¿Seguro que deseas eliminar este perfil?',
	EliminarObra: 'Eliminar Obra',
	SeguroQueDeseasEliminarEstaObra: '¿Seguro que deseas eliminar esta obra?',
	EliminarColeccion: 'Eliminar Colección',
	SeguroQueDeseasEliminarEstaColeccion:
		'¿Seguro que deseas eliminar esta colección?',
	EliminarContacto: 'Eliminar Contacto',
	SeguroQueDeseasEliminarEsteContacto:
		'¿Seguro que deseas eliminar este contacto',
	EliminarDocumento: 'Eliminar Documento',
	SeguroQueDeseasEliminarEsteDocumento:
		'¿Seguro que deseas eliminar este documento',
	EliminarExposición: 'Eliminar Exposición',
	SeguroQueDeseasEliminarEstaExposición:
		'¿Seguro que deseas eliminar esta exposición?',
	EliminarUbicación: 'Eliminar Ubicación',
	SeguroQueDeseasEliminarEstaUbicación:
		'¿Seguro que deseas eliminar esta ubicación',
	EliminarLugar: 'Eliminar Lugar',
	SeguroQueDeseasEliminarEsteLugar: '¿Seguro que deseas eliminar este lugar',
	EliminarSala: 'Eliminar Sala',
	SeguroQueDeseasEliminarEstaSala: '¿Seguro que deseas eliminar esta sala',
	EliminarPortafolio: 'Eliminar Portafolio',
	SeguroQueDeseasEliminarEstePortafolio:
		'¿Seguro que deseas eliminar este portafolio?',
	EliminarSelección: 'Eliminar selección',
	SeguroQueDeseasEliminarLasObrasSeleccionadas:
		'¿Seguro que deseas eliminar las obras seleccionadas?',
	SeguroQueDeseasEliminarLosDocumentosSeleccionados:
		'¿Seguro que deseas eliminar los documentos seleccionados?',
	Perfil: 'Perfil',
	perfil: 'perfil',
	Artistas: 'Artistas',
	Home: 'Home',
	Obras: 'Obras',
	Obra: 'Obra',
	obra: 'obra',
	Convocatorias: 'Calls',
	contacto: 'contacto',
	Contactos: 'Contactos',
	Documento: 'Documento',
	documento: 'documento',
	Exposicion: 'Exposición',
	exposicion: 'exposición',
	Lugares: 'Lugares',
	lugar: 'lugar',
	sala: 'sala',
	Salas: 'Salas',
	Portafolio: 'Portafolio',
	portafolio: 'portafolio',
	Portafolios: 'Portafolios',
	Rechazar: 'Rechazar',
	Aprobar: 'Aprobar',
	Aceptar: 'Aceptar',
	Coleccion: 'Colección',
	coleccion: 'colección',
	Colecciones: 'Colecciones',
	CambiarImagenPortada: 'Cambiar imagen de portada',
	Visualizar: 'Visualizar',
	WhatsApp: 'WhatsApp',
	Instagram: 'Instagram',
	Facebook: 'Facebook',
	Twitter: 'Twitter',
	LinkedIn: 'LinkedIn',
	Rol: 'Rol',
	URL: 'URL',
	Dirección: 'Dirección',
	País: 'País',
	Ciudad: 'Ciudad',
	CódigoPostal: 'Código postal',
	Ubicaciones: 'Ubicaciones',
	Piso: 'Piso',
	ubicacion: 'ubicación',
	Cancelar: 'Cancelar',
	Guardar: 'Guardar',
	NoHayInformaciónDisponible: 'No hay información disponible',
	AñadirNuevaImagen: 'Añadir nueva imagen',
	AñadirObra: 'Añadir obra',
	DescargarImagen: 'Descargar imagen',
	EliminarImágenes: 'Eliminar Imágenes',
	OrganizarImágenes: 'Organizar Imágenes',
	OrganizarObras: 'Organizar obras',
	OrganizarSubColecciones: 'Organizar sub colecciones',
	SeleccionarTodasLasObras: 'Seleccionar todas las obras',
	ObrasSeleccionadas: 'Obras seleccionadas',
	RegresarAExposiciones: 'Regresar a Exposiciones',
	NuevaExposición: 'Nueva exposición',
	FechaDeFinalización: 'Fecha de Finalización',
	Roles: 'Roles',
	Usuario: 'Usuario',
	RolesYPermisos: 'Roles y permisos',
	RolesAsignados: 'Roles asignados',
	EliminarUsuario: 'Eliminar usuario',
	SeguroQueDeseasEliminarEsteUsuario:
		'¿Seguro que deseas eliminar este usuario?',
	DocumentosSeleccionados: 'Documentos Seleccionadas',
	DocumentoSeleccionado: 'Documento Seleccionado',
	EliminarDocumentos: 'Eliminar Documentos',
	Descargar: 'Descargar',
	EliminarImagen: 'Eliminar Imagen',
	SeguroQueDeseasEliminarEstaImagen: '¿Seguro que deseas eliminar esta imagen?',
	ImágenesSeleccionadas: 'Imágenes Seleccionadas',
	DescargarDocumentos: 'Descargar Documentos',
	NoHayExposicionesDisponibles: 'No hay exposiciones disponibles',
	ParaCrearNuevaImagen: 'Para crear una nueva imagen, ',
	ParaCrearNuevoArtista: 'Para crear un nuevo artista',
	ParaCrearNuevaObra: 'Para crear una nueva obra',
	ParaCrearNuevoDocumento: 'Para crear un nuevo documento',
	ParaCrearNuevaColección: 'Para crear una nueva colección',
	ParaCrearNuevaSubColección: 'Para crear una nueva sub colección',
	ParaCrearNuevoLugar: 'Para crear un nuevo lugar',
	ParaCrearNuevaSala: 'Para crear una nueva sala',
	ParaCrearNuevaUbicación: 'Para crear una nueva ubicación',
	ParaCrearNuevoContacto: 'Para crear un nuevo contacto',
	ParaCrearNuevaExposición: 'Para crear una nueva exposición',
	ParaCrearNuevoPortafolio: 'Para crear un nuevo portafolio',
	HazClicEnElBotón: 'haz clic en el botón',
	HazClicAquí: 'haz clic aquí.',
	SubColecciones: 'Sub colecciones',
	BorrarCambios: 'Borrar cambios',
	AlVolverALaSecciónAnteriorVasABorrarTodosLosCambiosQueAcabasDeHacer:
		'Al volver a la sección anterior vas a borrar todos los cambios que acabas de hacer',
	SeguroQueDeseasEliminarLosCambios: '¿Seguro que deseas eliminar los cambios?',
	RegresarAConfiguraciónDeCuenta: 'Regresar a configuración de cuenta',
	EstadoPropietario: 'Estado',
	DescargarCatalogo: 'Descargar catalogo',
	DescargarFormulario: 'Descargar formulario',
	DescargarPortafolio: 'Descargar portafolio',
	DescargarCV: 'Descargar CV',
	HojaDeVida: 'Hoja de vida',
	MostrarPrecios: 'Mostrar precios',
	cover: 'Portada',
	textBlockAndImage: 'Bloque de texto e imagen',
	textBlock: 'Bloque de texto',
	fullScreenImage: 'Imagen de pantalla',
	artworksGroup: 'Colección de obras',
	artworkIndividual: 'Obra individual',
	backCover: 'Contraportada',
	EliminarSección: 'Eliminar sección',
	SeguroDeseasEliminarEstaSección: '¿Seguro que deseas eliminar esta sección?',
	IniciaSesiónParaPostularte:
		'Inicia sesión en Datarte para postularte a la convocatoria',
	Revisada: 'Revisada',
	NoPuedesBorrarEstePortafolio: 'No puedes borrar este portafolio',
	EstePortafolioHaceParteDeUnaDeTusConvocatorias:
		'Este portafolio no puede ser eliminado, ya que tu postulación fue guardada para proyectos futuros fuera de la convocatoria original.',
	ParaPoderAplicarDebesRegistrarte:
		'Para poder aplicar a esta convocatoria debes registrarte en Datarte o ingresar a tu cuenta.',
	PorQuéTenerUnaCuentaEnDatarte: '¿Por qué tener una cuenta en Datarte?',
	CrearConvocatoriasPersonalizadas: 'Crea convocatorias personalizadas.',
	AdaptarLaConvocatoriaATusNecesidades:
		'Adapta la convocatoria a tus necesidades y la de tu comunidad.',
	SolicitarPerfilDelArtistaDeAcuerdoConNecesidadesDeLaConvocatoria:
		'Solicita perfil del artista de acuerdo con las necesidades de la convocatoria.',
	EscogerSiElArtistaPostulaPortafolioOCatálogo:
		'Escoge si el artista postula con portafolio o con catálogo.',
	SolicitarDocumentosAdjuntosDelArtista:
		'Solicita documentos adjuntos del artista.',
	CrearFormulariosPersonalizados: 'Crea formularios personalizados.',
	HacerModificacionesYSeguimiento:
		'Haz modificaciones y seguimiento en tiempo real.',
	RevisarLosPerfilesPostulados:
		'Revisa los perfiles postulados con un solo click.',
	EnvíarNotificacionesDeManeraAutomatizada:
		'Envía notificaciones de manera automatizada.',
	GestionaTusConvocatoriasEnUnSoloLugar:
		'Gestiona tus convocatorias en un solo lugar.',
	CrearConvocatoria: 'Crear convocatoria',
	ComoVanTusConvocatorias: 'Cómo van tus convocatorias',
	PostulacionesRecibidas: 'Postulaciones recibidas',
	LoSentimosTodavíaNoTienesPostulaciones:
		'Lo sentimos, todavía no tienes postulaciones.',
	AnteriorConvocatoria: 'Anterior convocatoria',
	SiguienteConvocatoria: 'Siguiente convocatoria',
	AplicaAConvocatoriasIlimitadasDesdeLaApp:
		'Aplica a convocatorias ilimitadas desde la app.',
	DescubreNuevasOportunidadesCadaSemana:
		'Descubre nuevas oportunidades cada semana.',
	CreaPortafoliosVirtualesPersonalizados:
		'Crea portafolios virtuales personalizados.',
	OrganizaTusObrasPorColeccionesEnLaNube:
		'Organiza tus obras por colecciones en la nube.',
	Paso1: 'Paso 1',
	Paso2: 'Paso 2',
	Paso3: 'Paso 3',
	SeleccionaLaColecciónQueQuieresUtilizarEnTuPortafolio:
		'Selecciona la colección que quieres utilizar en tu portafolio.',
	SoloSeVisualizaránColeccionesConObrasAsociadasAEstaCuenta:
		'Solo se visualizarán colecciones con obras asociadas a esta cuenta.',
	SeleccioneElArtista: 'Seleccione el artista',
	SeleccioneElArtistaAPostular:
		'Seleccione el artista al cual va a postular en esta convocatoria',
	DebesCrearObrasOrganización:
		'Para aplicar a esta convocatoria con este artista es necesario primero subir sus obras en Datarte.',
	CrearObras: 'Crear obras',
	ParaAplicarALaConvocatoriaDebeCrearArtista:
		'Para aplicar a esta convocatoria es necesario primero crear un artista y subir sus obras en Datarte.',
	CrearArtista: 'Crear artista',
};
