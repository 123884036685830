// 3rd party
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

//BL
import { resetHistoryURL } from '../../../BusinessLogic/redux/reducers/navigation';

//UI
import { ReactComponent as Perfil } from '../../assets/icons/UserCircle.svg';
import { ReactComponent as Hamburger } from '../../assets/icons/Hamburger.svg';
import ButtonLinkMainLogo from '../../atoms/Buttons/Link/ButtonLinkMainLogo';
import ManagamentMenuPerfil from '../../organisms/Managament/ManagamentMenuPerfil';
import ButtonWithIcon from '../Buttons/WithIcon/ButtonWithIcon';
import Menu from '../../organisms/Menu/Menu';
import ButtonHeaderPayments from '../Buttons/ButtonHeaderPayments';

/**
 * @description Main Nav bar for mobile with everything on the top
 * @param {*} param0
 * @returns
 */
export default function NavbarMobileTop({
	section,
	setSection,
	options,
	normalState,
	rotateState,
}) {
	const dispatch = useDispatch();
	const [showPerfil, setShowPerfil] = useState(false);
	const [showMenu, setShowMenu] = useState(false);

	const handleCloseMenu = () => {
		dispatch(resetHistoryURL());
	};

	return (
		<div className='flex h-20 items-center justify-between px-5 md:hidden md:h-32'>
			<div className='flex-[0.8]'>
				<ButtonWithIcon
					icon={<Hamburger />}
					showBoolean={showMenu}
					setShowBoolean={setShowMenu}
				/>
			</div>
			<div className='flex flex-1 items-center justify-between gap-x-4'>
				<ButtonLinkMainLogo action={() => handleCloseMenu()} />

				<div className='flex  items-center justify-between gap-x-4'>
					<ButtonHeaderPayments />
					<ButtonWithIcon
						icon={<Perfil />}
						showBoolean={showPerfil}
						setShowBoolean={setShowPerfil}
					/>
					{showPerfil && (
						<ManagamentMenuPerfil action={() => setShowPerfil(false)} />
					)}
					{showMenu && (
						<Menu
							options={options}
							setShowMenu={setShowMenu}
							section={section}
							setSection={setSection}
							normalState={normalState}
							rotateState={rotateState}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

NavbarMobileTop.propTypes = {
	/** array of objects that contents the name of the option and nested options */
	option: PropTypes.string,
	/** Compares with the string to know if has been selected */
	section: PropTypes.string,
	/** normal direction of the arrow */
	normalState: PropTypes.string,
	/** change direction of the arrow */
	rotateState: PropTypes.string,
};

NavbarMobileTop.defaultProps = {};
