/* -- Third Party -- */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, ErrorMessage } from 'formik';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

//BL
import {
	ENDPOINTS_APPUSER,
	ENDPOINTS_CITY,
	ENDPOINTS_COUNTRY,
} from '../../../BusinessLogic/helpers/routes';
import putDataAndImage from '../../../BusinessLogic/helpers/putDataAndImage';
import { fetchGetData } from '../../../BusinessLogic/hooks/fetchGetData';
import { fetchPostDataWithUser } from '../../../BusinessLogic/hooks/fetchPostDataWithUser';

//UI
import { ListItem } from '@material-ui/core';
import Add from '../../../UI/assets/icons/add.svg';
import ListItemText from '../../../UI/atoms/Inputs/ListItemText';
import FieldTextFormikManagement from '../../../UI/molecules/Field/FieldTextFormikManagement';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import ImageSectionForm from '../../../UI/organisms/Image/ImageSectionForm';
import SectionForm from '../../../UI/organisms/Section/SectionForm';
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

export default function PerfilForm() {
	const { app_user, organization_user_id, role_user_id } = localStorage;
	const navigate = useNavigate();
	const [appUser, setAppUser] = useState([{ id: '', name: '' }]);
	const [countries, setCountries] = useState([{ id: '', name: '' }]);
	const [cities, setCities] = useState([{ id: '', name: '' }]);
	const [countryId, setCountryId] = useState(1);

	//Query GET to the API
	useEffect(() => {
		fetchPostDataWithUser(ENDPOINTS_APPUSER.GET_APPUSER + `${app_user}/`).then(
			(data) => setAppUser(data)
		);
		fetchGetData(ENDPOINTS_COUNTRY.GET_COUNTRIES).then((data) =>
			setCountries(data)
		);
	}, [app_user]);

	useEffect(() => {
		fetchGetData(ENDPOINTS_CITY.GET_CITIES + `${countryId}/`).then((data) =>
			setCities(data)
		);
	}, [countryId]);

	const validationSchema = Yup.object({
		first_name: Yup.string()
			.min(2, 'Must be at least 2 characters long')
			.required('A first name is required.'),
		last_name: Yup.string()
			.min(2, 'Must be at least 2 characters long')
			.required('A last name is required.'),
		email: Yup.string()
			.min(2, 'Must be at least 2 characters long')
			.required('An email name is required.'),
	});

	const handleCountry = (e) => {
		const idCountry = e.target.value;
		setCountryId(idCountry);
	};

	return (
		<TemplateWithNavBar>
			<Formik
				enableReinitialize
				initialValues={{
					...appUser,
					organization_user_id: organization_user_id,
					role_id: role_user_id,
					type: 'PERSON',
					type_id: '0',
				}}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					putDataAndImage(
						ENDPOINTS_APPUSER.PUT_APPUSER + `${app_user}/`,
						values
					).then(() => {
						setTimeout(() => {
							navigate(-1);
						}, 500);
					});
				}}
			>
				{(formProps) => (
					<div className='pb-20'>
						<Form>
							<SecondaryNavbarSpecific
								title='Editar Perfil'
								textColor='text-green-50'
								color='bg-green-50'
								hide='invisible'
							/>
							<div className='mx-auto w-fit-content md:hidden'>
								<TextMainTitle
									title='Editar Perfil'
									textColor='text-green-50'
									color='bg-green-50'
								/>
							</div>
							<div className='mb-20 px-4 pb-8 md:flex md:flex-row md:justify-around'>
								<ImageSectionForm
									action='edit'
									url={appUser.main_picture_url}
									alt={appUser.name}
									formProps={formProps}
								/>
								<div className='mt-8 flex w-full flex-col items-center md:w-96'>
									<SectionForm
										title='Identificación de usuario'
										textColor='text-green-50'
										color='bg-green-50'
									>
										<FieldTextFormikManagement
											label='Nombre'
											dbName='first_name'
											type='Text'
										/>
										<FieldTextFormikManagement
											label='Apellido'
											dbName='last_name'
											type='Text'
										/>
										<FieldTextFormikManagement
											label='Compañia'
											dbName='company'
											type='Text'
										/>
									</SectionForm>

									<SectionForm
										title='Información de la cuenta'
										textColor='text-green-50'
										color='bg-green-50'
									>
										<FieldTextFormikManagement
											label='Contraseña'
											dbName='password'
											type='Text'
										/>
									</SectionForm>

									<SectionForm
										title='Información de usuario'
										textColor='text-green-50'
										color='bg-green-50'
									>
										<FieldTextFormikManagement
											label='Número de teléfono'
											dbName='phone_number'
											type='tel'
										/>
										<FieldTextFormikManagement
											label='Direccion'
											dbName='address'
											type='Text'
										/>

										<ListItem
											style={{
												paddingLeft: '0px',
												paddingRight: '0px',
												justifyContent: 'space-between',
											}}
										>
											<ListItemText
												primary='Rol en la compañia'
												design='body-Text2'
												style={{ fontWeight: 400 }}
											/>
											<Field
												name='role_company'
												type='Text'
												as='select'
												className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-75 md:w-56'
											>
												<option value='CURADOR'>CURADOR</option>
												<option value='MUSEO'>MUSEO</option>
												<option value='GALERIA'>GALERIA</option>
												<option value='ARTISTA'>ARTISTA</option>
											</Field>
										</ListItem>
										<ErrorMessage name='role_company' />

										<ListItem
											style={{
												paddingLeft: '0px',
												paddingRight: '0px',
												justifyContent: 'space-between',
											}}
										>
											<ListItemText
												primary='País'
												design='body-Text2'
												style={{ fontWeight: 400 }}
											/>
											<Field name='country_id' type='Text' as='select'>
												{({ field }) => (
													<select
														{...field}
														onClick={(e) => handleCountry(e)}
														className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-75 md:w-56'
													>
														{countries.map((country) => (
															<option value={country.id} key={country.id}>
																{country.name}
															</option>
														))}
													</select>
												)}
											</Field>
										</ListItem>
										<ErrorMessage name='country_id' />

										<ListItem
											style={{
												paddingLeft: '0px',
												paddingRight: '0px',
												justifyContent: 'space-between',
											}}
										>
											<ListItemText
												primary='Ciudad'
												design='body-Text2'
												style={{ fontWeight: 400 }}
											/>
											<Field
												name='city_id'
												type='Text'
												as='select'
												className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-75 md:w-56'
											>
												{cities.map((city) => (
													<option key={city.id} value={city.id}>
														{city.name}
													</option>
												))}
											</Field>
										</ListItem>
										<ErrorMessage name='city_id' />
									</SectionForm>
									<ButtonFabExtendedPrimary icon={Add} text='Guardar' />
								</div>
							</div>
						</Form>
					</div>
				)}
			</Formik>
		</TemplateWithNavBar>
	);
}
