// 3rd Party
import { useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';


// BL
import {
	setEditablePortfolioView,
	setIdOfPortfolioToEdit,
} from '../../../BusinessLogic/redux/reducers/editablePortfolio';
import { urlDeleteElement } from '../../../BusinessLogic/helpers/urlDeleteElement';
import { deleteElement } from '../../../BusinessLogic/redux/reducers/deleteElement';
import { getPortfolios } from '../../../BusinessLogic/redux/reducers/portfolio';



// UI
import { ReactComponent as DotsVertical } from '../../assets/icons/DotsVertical.svg';
import ModalPortfolio from '../../organisms/PopUp/ModalPortfolio';
import PopupIconAndOptions from '../../organisms/PopUp/PopupIconAndOptions';
import { ReactComponent as EyeBlack } from '../../../UI/assets/icons/EyeBlack.svg';
import { ReactComponent as PencilBlack } from '../../../UI/assets/icons/PencilBlack.svg';
import { ReactComponent as ShareNetworkBlack } from '../../../UI/assets/icons/ShareNetworkBlack.svg';
import { ReactComponent as Faders } from '../../../UI/assets/icons/FadersHorizontalBlack.svg';
import GeneralPortfolioCover from '../../assets/images/DefaultPortfolioCover.png';
import SharePortfolio from '../../../Pages/sections/portfolio/SharePortfolio';
import { ReactComponent as Delete } from '../../../UI/assets/icons/Delete.svg';
import ViewModal from '../../templates/ViewModal';
import { ModalPostulation } from '../../organisms/PopUp/ModalPostulation';


const HorizontalImageWithTitle = ({
	elementData,
	displayPortfolioOptions,
	designContainer,
	designImage,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [showDotsModal, setShowDotsModal] = useState(false);
	const [showShareModal, setShowShareModal] = useState(false);
	const [showMobileModal, setShowMobileModal] = useState(false);
	const [showDeleteConfimationModal, setShowDeleteConfimationModal] =
		useState(false);
	const [showCannotDeletePortafolioModal, setShowCannotDeletePortafolioModal] =
		useState(false);


	const dotsRef = useRef();

	const isMobile = window.innerWidth < 640;

	useEffect(() => {
		const hideModalsWhenResize = () => {
			setShowDotsModal(false);
			setShowShareModal(false);
			setShowMobileModal(false);
		};
		window.addEventListener('resize', hideModalsWhenResize);

		return () => window.removeEventListener('resize', hideModalsWhenResize);
	}, []);


	const idItem = elementData.id;

	const section = 'portafolios';

	const { deleteElementUrl: deletePortfolioUrl } = urlDeleteElement(
		section,
		idItem
	);

	const handleDeleteError = () => {
		setShowCannotDeletePortafolioModal(true);
	};

	const handleDeletePortfolioFromCard = () => {
		dispatch(deleteElement(deletePortfolioUrl)).then((payload) => {
			
			if (payload.error) {
				const isServerError = payload.error.message.includes('5');
				const isRestrictionClientError = payload.error.message.includes('409');

				const deleteError =
					payload.error && (isServerError || isRestrictionClientError);

				deleteError && handleDeleteError();
			} else {
				dispatch(getPortfolios());
			}
		});
	};

	const isCollectionPortfolio = elementData.type === 'COLLECTION';

	const handleConfigurationPortfolioOnClick = () => {
		switch (elementData.type) {
			case 'EXPERT':
				navigate(`/portafolios/portafolio-experto/${elementData.id}/edicion`);
				break;
			case 'COLLECTION':
				navigate(`/portafolios/${elementData.id}`);
				break;
			default:
				dispatch(setIdOfPortfolioToEdit(elementData.id));
				dispatch(setEditablePortfolioView('canvasEdition'));
				navigate(`/portafolios/editar`);
				break;
		}
	};

	const dotsOptionsInPortfolioDots = [
		{
			label: t('visualizarPortafolio'),
			action: () => navigate(`/portafolio/${elementData.slug}`),
			icon: <EyeBlack className='h-6 w-6' />,
		},
		{
			label: t('editarPortafolio'),
			action: handleConfigurationPortfolioOnClick,
			icon: isCollectionPortfolio ? (
				<Faders className='h-6 w-6' />
			) : (
				<PencilBlack className='h-6 w-6' />
			),
		},
		{
			label: t('compartirPortafolio'),
			action: () => {
				setShowShareModal(true);
			},
			icon: <ShareNetworkBlack className='h-6 w-6' />,
		},

		{
			label: t('eliminarPortafolio'),
			action: () => {
				setShowDeleteConfimationModal(true);
			},
			icon: <Delete className='h-6 w-6' />,
		},
	];

	const highlightDots = () => {
		dotsRef.current.classList.add('bg-gray-200', 'scale-105');

		setTimeout(() => {
			dotsRef.current.classList.remove('bg-gray-200', 'scale-105');
		}, 1000);
	};

	const handleLoadImageSrc = () => {
		if (elementData?.is_general_portfolio) return GeneralPortfolioCover;

		return (
			elementData.main_picture_url ||
			'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image'
		);
	};

	return (
		<div
			className={`flex flex-col gap-2 border-b-2 pb-4 sm:border-0 sm:pb-0 ${designContainer}`}
			onClick={() => setShowMobileModal(true)}
		>
			<LazyLoadImage
				src={handleLoadImageSrc()}
				alt={elementData.name}
				className={`h-[20vh] w-full select-none rounded-sm object-cover shadow-md md:h-[25vh] ${designImage}`}
				onClick={highlightDots}
			/>

			<div className='flex flex-row justify-between'>
				<p className='words-break  my-auto text-sm line-clamp-2'>
					{elementData.name}
				</p>

				<div>
					{displayPortfolioOptions && showDotsModal && (
						<div className='mr-6'>
							<PopupIconAndOptions
								popupOptions={dotsOptionsInPortfolioDots}
								design={'hover:bg-gray-100'}
								showPopup={showDotsModal}
								setShowPopup={setShowDotsModal}
								slug={elementData.slug}
							/>
						</div>
					)}
					{displayPortfolioOptions && (
						<DotsVertical
							className={`hidden cursor-pointer rounded-full duration-1000 hover:bg-gray-200 sm:flex`}
							ref={dotsRef}
							aria-label='Dots icon'
							onClick={(e) => {
								e.stopPropagation();
								setShowDotsModal(!showDotsModal);
							}}
						/>
					)}
					{isMobile && displayPortfolioOptions && showMobileModal && (
						<ModalPortfolio
							showMobileModal={showMobileModal}
							setShowMobileModal={setShowMobileModal}
							portfolioData={elementData}
							popupOptions={dotsOptionsInPortfolioDots}
							title={elementData.name}
						/>
					)}
					{displayPortfolioOptions && showShareModal && (
						<div
							className='fixed right-0 top-0 z-40 flex h-full w-full cursor-pointer bg-black-50/70 bg-opacity-70 shadow-xl'
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<SharePortfolio
								urlToShare={`${window.location.origin}/portafolio/${elementData.slug}`}
								setShare={setShowShareModal}
								share={showShareModal}
								designTwo={
									'fixed inset-0 bg-black-50 bg-opacity-75 transition-opacity'
								}
							/>
						</div>
					)}

					{displayPortfolioOptions && showDeleteConfimationModal && (
						<ViewModal
							setConfirmDeleteElement={handleDeletePortfolioFromCard}
							closeMenu={setShowDeleteConfimationModal}
							modalTitle={`${t('EliminarPortafolio')}`}
							modalTextOne={`${t('SeguroQueDeseasEliminarEstePortafolio')}`}
						/>
					)}

					{/* This modal shows up in case of error when delete Portafolio */}
					{showCannotDeletePortafolioModal && (
						<ModalPostulation
							modalTitle={t('NoPuedesBorrarEstePortafolio')}
							modalText={t('EstePortafolioHaceParteDeUnaDeTusConvocatorias')}
							buttonLabel={t('Cerrar')}
							actionClose={() => setShowCannotDeletePortafolioModal(false)}
							modalClassName='text-center'
						/>
					)}

				</div>
			</div>
		</div>
	);
};

export default HorizontalImageWithTitle;
