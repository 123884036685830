import { createSlice } from '@reduxjs/toolkit';

const initialEditingCall = {
	benefits: [],
	category: '',
	city: '',
	closes_at: null,
	copy_page: '',
	country_id: 47,
	form: [],
	form_id: null,
	has_benefit: false,
	has_cv: false,
	has_form: false,
	has_judge: false,
	has_portfolio: false,
	header: '',
	judge_is_public: false,
	judge: [],
	main_picture_url: '',
	name: '',
	opens_at: null,
	requirements: '',
	results_at: null,
	sub_header: '',
	status: 'DRAFT',
	technique: '',
	main_image_uploaded_before_submit: [],
	organizer_image_uploaded_before_submit: [],
	call_organizer: [
		{
			name: '',
			description: '',
			email: '',
			phone: '',
			website: '',
			address: '',
			country_id: 47,
			city: '',
			postal_code: '',
		},
	],
};

const initialState = {
	calls: null,
	singleCall: null,
	loadingCall: false,
	callChanges: 0,
	singleCallForm: [],
	editingCall: initialEditingCall,
	singleCallPublic: null,
	formTemporaryCallSlug: null,
};

export const callSlice = createSlice({
	name: 'call',
	initialState,
	reducers: {
		updateEditingCall: (state, { payload }) => {
			state.editingCall = payload;
		},
		updateFormTemporaryCallSlug: (state, { payload }) => {
			state.formTemporaryCallSlug = payload;
		},
		cleanFormTemporaryCallSlug: (state) => {
			state.formTemporaryCallSlug = null;
		},
		updateMainImageUploadedBeforeSubmit: (state, { payload }) => {
			state.editingCall.main_image_uploaded_before_submit = payload;
		},
		updateOrganizerImageUploadedBeforeSubmit: (state, { payload }) => {
			state.editingCall.organizer_image_uploaded_before_submit = payload;
		},
		cleanEditingCall: (state) => {
			state.editingCall = initialEditingCall;
		},
	},
});

export const {
	updateEditingCall,
	updateFormTemporaryCallSlug,
	cleanFormTemporaryCallSlug,
	updateMainImageUploadedBeforeSubmit,
	updateOrganizerImageUploadedBeforeSubmit,
	cleanEditingCall,
} = callSlice.actions;

export default callSlice.reducer;
