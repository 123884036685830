import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_ONBOARDING } from '../../helpers/routes';
import { fetchGetData } from '../../hooks/fetchGetData';

export const getOnboardingArtist = createAsyncThunk(
	'onboarding/getOnboardingArtist',
	async (id) => {
		const { organization_user_id } = localStorage;
		return await fetchGetData(
			ENDPOINTS_ONBOARDING.GET_ONBOARDING_ARTIST +
				`${organization_user_id ?? id}/`
		);
	}
);

export const getOnboardingOrganization = createAsyncThunk(
	'onboarding/getOnboardingOrganization',
	async () => {
		const { organization_user_id } = localStorage;
		return await fetchGetData(
			ENDPOINTS_ONBOARDING.GET_ONBOARDING_ORGANIZATION +
				`${organization_user_id}/`
		);
	}
);

const initialState = {
	onboarding: null,
	onboardingStatus: 'loading',
	onboardingChanges: 0,
	onboardingFirstLogged: false,
};

export const onboardingSlice = createSlice({
	name: 'onboarding',
	initialState,
	reducers: {
		getOnboarding: (state, { payload }) => {
			state.onboarding = payload;
			state.onboardingStatus = 'success';
		},
		cleanOnboarding: (state, { payload }) => {
			state.onboarding = null;
			state.onboardingStatus = 'success';
		},
		updateOnboardingFirstLogged: (state, { payload }) => {
			state.onboardingFirstLogged = payload;
		},
	},

	extraReducers: {
		[getOnboardingArtist.pending]: (state, action) => {
			state.onboardingStatus = 'loading';
		},
		[getOnboardingArtist.fulfilled]: (state, { payload }) => {
			state.onboarding = payload;
			state.onboardingStatus = 'success';
		},
		[getOnboardingArtist.rejected]: (state, action) => {
			state.onboardingStatus = 'failed';
		},
		[getOnboardingOrganization.pending]: (state, action) => {
			state.onboardingStatus = 'loading';
		},
		[getOnboardingOrganization.fulfilled]: (state, { payload }) => {
			state.onboarding = payload;
			state.onboardingStatus = 'success';
		},
		[getOnboardingOrganization.rejected]: (state, action) => {
			state.onboardingStatus = 'failed';
		},
	},
});

export const { getOnboarding, cleanOnboarding, updateOnboardingFirstLogged } =
	onboardingSlice.actions;

export default onboardingSlice.reducer;
