//3rd party
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//UI
import { ReactComponent as RadioButtonSquare } from '../../assets/icons/RadioButtonSquare.svg';

/**
 *
 * @description render a custom input checkbox with a text
 */
function CheckBoxWithTextTermsOfUse({ design, setPolicyAccept, policyAccept }) {
	const { t } = useTranslation();
	const [isChecked, setIsChecked] = useState(false);
	return (
		<div className={design}>
			<label className='mr-3'>
				<input
					className='customCheckBox'
					type='checkbox'
					onChange={() => {
						setIsChecked(!isChecked);
						if (setPolicyAccept) setPolicyAccept(!policyAccept);
					}}
				/>
				<RadioButtonSquare
					className={`cursor-pointer border-2 border-green-50 ${
						isChecked && 'bg-green-50'
					}`}
					stroke={isChecked && 'bg-black'}
				/>
			</label>
			<p className='body-Text3 text-justify'>
				{`${t('Consentimiento')} `}
				<span
					className='cursor-pointer text-green-50'
					onClick={() =>
						window.open('https://www.datarte.art/tycdatarte', '_blank')
					}
				>
					{t('TérminosDeUso')}
				</span>
				{` ${t('PolíticasSeguridad')} `}
			</p>
		</div>
	);
}

CheckBoxWithTextTermsOfUse.propTypes = {
	/* tailwind  container design */
	design: PropTypes.string,
	/* setState function to change policy value */
	setPolicyAccept: PropTypes.func,
	/* bool policy value  that trigger a action in other component*/
	policyAccept: PropTypes.bool,
};
CheckBoxWithTextTermsOfUse.defaultProps = {
	design: 'flex flex-row',
};

export default CheckBoxWithTextTermsOfUse;
