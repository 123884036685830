import * as Yup from 'yup';
//Single last location tabs information
const singleLastLocationTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'labels',
				field: 'identification',
				information: [
					{ label: 'Nombre', value: 'name', field: 'name' },
					{ label: 'Piso', value: 'details', field: 'details' },
				],
			},
		],
	},
	{
		tab: 'Obras',
		type: 'Images',
		content: [
			{
				title: 'Obras',
				type: 'images',
				field: 'artworks',
				information: [],
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const singleLastLocationButtons = ['Identificación', 'Obras', 'Imágenes'];

const singleLastLocationValidationSchema = [
	//this needs to have the same order than the buttons
	[
		//and the same order than the appearance of the content sections
		//far from elegant
		{
			name: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.required('NombreEsNecesario'),
			details: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.required('DetalleEsNecesario'),
		},
	],
];

const lastLocationFormValidationSchema = Yup.object({
	name: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('NombreEsNecesario'),
	details: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('DetalleEsNecesario'),
});

const lastLocationInitialValues = {
	id: '',
	name: '',
	details: '',
	location_id: 1,
	main_picture_url: '',
	main_picture_id: '',
	organization_user_id: 6,
	name_image: '',
	description_image: '',
};

export {
	singleLastLocationTabs,
	singleLastLocationButtons,
	singleLastLocationValidationSchema,
	lastLocationFormValidationSchema,
	lastLocationInitialValues,
};
