//3rd party
import PropTypes from 'prop-types';

//UI
import ImageLoginDesktop from '../assets/images/ImageLoginDesktop.jpg';
import ImageFullScreen from '../molecules/Image/ImageFullScreen';
import { ReactComponent as Logo } from '../assets/icons/logoDatarteNegro.svg';
import SelectorLanguage from '../molecules/Selector/SelectorLanguaje';
import { ReactComponent as Location } from '../../UI/assets/icons/LocationGreen.svg';
/**
 * @description render template with a imagen and a container with logo and any content
 * @param {*} param0
 * @returns
 */
export default function AuthenticationTemplate({
	children,
	image,
	placeholderSrc,
}) {
	return (
		<div className='flex flex-row'>
			<ImageFullScreen
				image={image}
				alt='Datarte Login Screen'
				className='sticky top-0 hidden h-screen md:block md:w-1/2 lg:w-3/5'
				placeholderSrc={placeholderSrc}
			/>
			<div className='relative flex  w-full flex-col justify-center gap-4 py-10 md:w-1/2 lg:w-2/5'>
				<div className='absolute  top-8 flex w-full flex-row justify-end px-4'>
					<div className='flex items-center'>
						<Location className='ml-2 md:ml-0' />
					</div>
					<SelectorLanguage
						languageLabels={{
							ES: 'Español',
							EN: 'English',
						}}
						titleColor={'text-green-50'}
						letterColor={'text-white w-full text-left px-2'}
						popupDesign={' bg-green-50 text-white  rounded right-1 w-[80px]'}
						showGreenArrow={true}
						titleStyle={{ fontWeight: '300', fontSize: '14px' }}
						labelsStyle={{ fontWeight: '300', fontSize: '14px' }}
					/>
				</div>
				<Logo className='mx-auto' />
				{children}
			</div>
		</div>
	);
}

AuthenticationTemplate.propTypes = {
	/* jpg image to render */
	image: PropTypes.element,
	/** Source of a placeholder image*/
	placeholderSrc: PropTypes.string,
};

AuthenticationTemplate.defaultProps = {
	image: ImageLoginDesktop,
};
