//3rd parties
import PropTypes from 'prop-types';

/**
 * @description style for text content.
 * css class is body-Text2
 * @param {*} param0
 * @returns
 */

export default function TextContentSecond({
	text,
	design,
	style,
	truncate,
	paragraphDesign,
}) {
	return (
		<p
			className={`${paragraphDesign}  ${truncate && 'truncate'}  ${design}`}
			style={style}
		>
			{text}
		</p>
	);
}

TextContentSecond.propTypes = {
	/** Texts that takes this style */
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
		PropTypes.element,
	]),
	/** Tailwind Classname*/
	design: PropTypes.string,
	/** React Style */
	style: PropTypes.object,
	/** truncate */
	truncate: PropTypes.bool,
	/** String  prop  with the style for the paragraph*/
	paragraphDesign: PropTypes.string,
};

TextContentSecond.defaultProps = {
	truncate: false,
	paragraphDesign: 'body-Text2',
};
