/* React */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* BL */
import {
	getExposition,
	putExposition,
	organizeImageGrid,
	getExpositions,
} from '../../../BusinessLogic/redux/reducers/exposition';
import {
	buttonsExposition,
	expositionValidationSchema,
} from '../../../BusinessLogic/data/ExpositionSingle';
import { ENDPOINT_FILES } from '../../../BusinessLogic/helpers/routes';
import { deleteFile } from '../../../BusinessLogic/redux/reducers/deleteElement';
import { createImage } from '../../../BusinessLogic/redux/reducers/createImage';
import { createMainImage } from '../../../BusinessLogic/redux/reducers/createMainImage';
import { compressAndResizeImage } from '../../../BusinessLogic/helpers/imageSize';
import {
	backSectionURL,
	saveSectionURL,
} from '../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../BusinessLogic/helpers/textGoBackGenerator';

/* UI */
import NewTagButtons from '../../../UI/molecules/Buttons/Tag/NewTagButtons';
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import ImageCarousel from '../../../UI/molecules/Image/ImageCarousel';
import TabImageAndEdit from '../../../UI/organisms/Tabs/TabImageAndEdit';
import TabInfoAndEdit from '../../../UI/organisms/Tabs/TabInfoAndEdit';
import TabDocumentAndEdit from '../../../UI/organisms/Tabs/TabDocumentAndEdit';
import { ReactComponent as Delete } from '../../../UI/assets/icons/Delete.svg';
import ButtonFabExtendeNav from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendeNav';
import ViewSingleSkeleton from '../../../UI/templates/SkeletonTemplates/ViewSingleSkeleton';

/**
 * @description renders the single exposition section. It has an Image carousel and exposiionSingleTabs.
 * @param {*} param0
 * @returns
 */

const ExpositionSingle = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const location = useLocation();

	const { expositions, singleExposition, expositionSingleTabs, status } =
		useSelector((state) => state.expositions);

	const { elementInfo } = useSelector((state) => state.deleteElement);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);
	const { appUser } = useSelector((state) => state.appUser);
	const { t } = useTranslation();
	const [indexInfo, setIndexInfo] = useState(0);

	useEffect(() => {
		if (!singleExposition || `${singleExposition.id}` !== id) {
			dispatch(getExposition(id));
		}
		//eslint-disable-next-line
	}, []);

	const onSubmitAction = (values) => {
		values.values.ending_date = new Date(Date.parse(values.values.ending_date))
			.toISOString()
			.substring(0, 10);
		values.values.initial_date = new Date(
			Date.parse(values.values.initial_date)
		)
			.toISOString()
			.substring(0, 10);

		dispatch(putExposition(values)).then(() => {
			dispatch(getExposition(id));
			dispatch(getExpositions());
		});
	};
	const createImagesFunction = async (imagesArray) => {
		for await (const image of imagesArray) {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			let values = {
				small_file,
				medium_file,
				large_file,
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
				type: 'EXPOSITION',
				type_id: id,
				organization_user_id: singleExposition.organization_user_id,
				file_type: 'IMAGE',
			};
			await dispatch(createImage(values));
		}
		dispatch(getExposition(id));
	};
	const createMainImageFunction = (mainImageArray) => {
		mainImageArray.forEach(async (image) => {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			let values = {
				small_file,
				medium_file,
				large_file,
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
				type: 'EXPOSITION',
				type_id: id,
				organization_user_id: singleExposition.organization_user_id,
				file_type: 'IMAGE',
			};
			dispatch(createMainImage(values)).then(() => dispatch(getExposition(id)));
		});
	};

	const deleteImagesFunction = (imageId) => {
		let values = {
			element_id: singleExposition.id,
			element: 'EXPOSITION',
			url: `${ENDPOINT_FILES.DELETE_FILE}${imageId}/`,
		};
		dispatch(deleteFile(values)).then(() => dispatch(getExposition(id)));
	};

	const submitOrganizeImageGrid = (sortableImages) => {
		let values = {
			images: sortableImages,
			type: 'EXPOSITION',
			type_id: id,
		};
		dispatch(organizeImageGrid(values)).then(() => {
			dispatch(getExpositions());
			dispatch(getExposition(id));
		});
	};

	const checkRedirect = () => {
		let redirect =
			appUser.role_company === 'EXPOSITION' ? '/' : '/exposiciones';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage = 'RegresarExposiciones';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return resultMessage;
	};

	const saveURLHistory = () => {
		dispatch(saveSectionURL(location.pathname));
	};
	const renderSwitch = () => {
		switch (expositionSingleTabs[indexInfo].type) {
			case 'Information':
				return (
					<TabInfoAndEdit
						information={expositionSingleTabs[indexInfo]}
						onSubmitAction={onSubmitAction}
						indexInfo={indexInfo}
						colorHr='green'
						id={parseInt(id)}
						validationSchema={
							expositionValidationSchema[indexInfo]
								? expositionValidationSchema[indexInfo]
								: null
						}
					/>
				);
			case 'Images':
				return (
					<TabImageAndEdit
						information={expositionSingleTabs[indexInfo]}
						indexInfo={indexInfo}
						colorHr='purple'
						color='green'
						AddIconLink={`/${expositionSingleTabs[
							indexInfo
						].tab.toLowerCase()}/new`}
						deleteImagesFunction={deleteImagesFunction}
						createImagesFunction={createImagesFunction}
						submitOrganizeImageGrid={submitOrganizeImageGrid}
						saveLastSectionURL={
							expositionSingleTabs[indexInfo].tab !== 'Imágenes' ? true : false
						}
						saveURLHistory={saveURLHistory}
					/>
				);
			case 'Documents':
				return (
					<TabDocumentAndEdit
						information={expositionSingleTabs[indexInfo]}
						indexInfo={indexInfo}
						color='yellow'
						saveLastSectionURL={true}
						saveURLHistory={saveURLHistory}
					/>
				);
			default:
				return <h1>{t('NoHayExposicionesDisponibles')}</h1>;
		}
	};

	return (
		<div>
			{!singleExposition && status === 'loading' && (
				<ViewSingleSkeleton numberOfSingleRow={6} designCaption={'h-22'} />
			)}
			{singleExposition && (
				<TemplateWithNavBar section='Perfil'>
					<NavbarSecondaryNew
						title={singleExposition.name}
						color='bg-green-50'
						textColor='text-green-50'
						redirect={checkRedirect()}
						textPopup={t('EliminarExposición')}
						popupIcon={<Delete />}
						modalTitle={t('EliminarExposición')}
						modalTextOne={t('SeguroQueDeseasEliminarEstaExposición')}
						modalTextTwo={elementInfo}
						id={parseInt(id)}
						textBack={CheckTextBack()}
						textBackDesign='pl-3 text-green-50'
						backAction={() => returnBackAction()}
						section='exposiciones'
						arrowLeftColor='green'
					/>
					{appUser.role_company !== 'EXPOSITION' && (
						<ButtonFabExtendeNav
							id={parseInt(id)}
							listItems={expositions.length ? expositions : []}
							section='exposiciones'
						/>
					)}
					<div className='mx-2 flex w-full flex-col md:mt-10 md:flex-row md:gap-2 lg:gap-0'>
						<ImageCarousel
							images={expositionSingleTabs[1].content[0].information}
							mainPicture={singleExposition.main_picture_url}
							createMainImageFunction={createMainImageFunction}
						/>
						<div className='flex w-full flex-col px-2 md:w-2/3 xl:w-2/3'>
							<NewTagButtons
								buttonList={buttonsExposition}
								indexInfo={indexInfo}
								setIndexInfo={setIndexInfo}
							/>

							<div className='min-h-screen bg-light_grey-50 py-4'>
								{renderSwitch()}
							</div>
						</div>
					</div>
				</TemplateWithNavBar>
			)}
		</div>
	);
};

export default ExpositionSingle;

ExpositionSingle.defaultProps = {};
