import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns';
import es from 'date-fns/locale/es';

// UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextContentThird from '../../atoms/Text/Content/TextContentThird';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

const CalendarEventCard = ({ calendarEvent }) => {
	const { t } = useTranslation();
	const eventDate = new Date(calendarEvent.date_event);

	const lng = localStorage.getItem('lng');

	const dayMonthAndYear = isValid(eventDate)
		? format(eventDate, 'eeee dd MMMM yyyy', {
				locale: lng === 'es' ? es : undefined,
		  })
		: '';

	const handleSwitchEventType = (calendarEvent) => {
		switch (calendarEvent.calendar_event_type_id) {
			case 1:
				return (
					<TextContentThird
						text={t('FinalizaciónDeConvocatoria')}
						design='rounded-full bg-yellow-50  w-fit px-2 text-[12px] lg:text-sm'
					/>
				);
			case 2:
				return (
					<TextContentThird
						text={t('AperturaDeConvocatoria')}
						design='rounded-full bg-green-50 w-fit px-2 text-[12px] lg:text-sm'
					/>
				);
			case 3:
				return (
					<TextContentThird
						text={t('ResultadosDeConvocatoria')}
						design='rounded-full bg-purple-50  w-fit px-2 text-[12px] lg:text-sm'
					/>
				);
			case 4:
				return (
					<TextContentThird
						text={t('EventosImportantes')}
						design='rounded-full bg-light_green-50  w-fit px-2 text-[12px] lg:text-sm'
					/>
				);
			default:
				return <div></div>;
		}
	};
	return (
		<div className='flex w-full  justify-between rounded bg-light_grey-75 px-4 py-4 md:px-8'>
			<div className='flex flex-col'>
				<TextContentThird
					text={
						dayMonthAndYear.charAt(0).toUpperCase() + dayMonthAndYear.slice(1)
					}
					design='py-1'
				/>
				<TextContentSecond
					text={calendarEvent.organization_name}
					design='py-1 capitalize'
				/>
				<TextContentSecond
					text={calendarEvent.title}
					design='py-1 !font-medium'
				/>
				<div className=' py-1'>{handleSwitchEventType(calendarEvent)}</div>
			</div>

			<div className='flex min-w-[45px]  flex-col justify-between'>
				<ButtonFab
					colour='background'
					size='fitContent'
					label={t('VERMÁS')}
					ButtonClassName='underline text-[#34B3E5] rounded-[16px] body-Text3'
					paragraphDesign='body-Text3'
					action={() => window.open(calendarEvent.link, '_blank')}
				/>
			</div>
		</div>
	);
};

CalendarEventCard.propTypes = {
	/** Object prop with the calendar event*/
	calendarEvent: PropTypes.object,
};
export default CalendarEventCard;
