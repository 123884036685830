//3rd parties
import PropTypes from 'prop-types';

//BL

//UI
import VideoBanner from '../../../UI/molecules/Video/VideoBanner';
import PortfolioHeader from '../../../UI/organisms/PortfolioPlanilla/PortfolioHeader';
import PortfolioArtworks from '../../../UI/organisms/PortfolioPlanilla/PortfolioArtworks';
import PortfolioLoading from '../../../UI/organisms/Loading/PortfolioLoading';
import PortfolioFooter from '../../../UI/organisms/PortfolioPlanilla/PortfolioFooter';

/**
 *
 * @description custom plantilla for Clara's portfolio
 */

const PlantillaCollectionClara = ({ portfolio, showLoading, WhatsAppData }) => {
	const artistFirstName = portfolio?.artist?.first_name;
	const artistLastName = portfolio?.artist?.last_name;
	const portfolioArtistName = `${artistFirstName ?? ''} ${
		artistLastName ?? ''
	}`;
	return (
		<div>
			{showLoading ? (
				<PortfolioLoading />
			) : (
				<div>
					<div className='mx-4 mb-16 mt-16 min-h-screen md:mx-16'>
						<PortfolioHeader
							artist_name={portfolioArtistName}
							name={portfolio.name}
							description={portfolio.description}
							main_picture={portfolio.main_picture}
							showMainImage={typeof portfolio.artworks !== 'undefined'}
						/>
						<VideoBanner url='https://art-catalogue.s3.us-east-2.amazonaws.com/clara_videos/video+expo+(1).mp4' />
						<PortfolioArtworks
							artworks={portfolio.artworks}
							image_quality={portfolio.image_quality}
						/>
					</div>

					<PortfolioFooter
						portfolio={null}
						hasSubCollection={false}
						hasWhatsApp={true}
						WhatsAppData={WhatsAppData}
					/>
				</div>
			)}
		</div>
	);
};

PlantillaCollectionClara.propTypes = {
	/** Portfolio used for the array */
	portfolio: PropTypes.object,
	/** Portfolio used for the array */
	showLoading: PropTypes.bool,
	/** Data passed to the whasApp location */
	WhatsAppData: PropTypes.object,
};

PlantillaCollectionClara.defaultProps = {
	showLoading: false,
};

export default PlantillaCollectionClara;
