// 3rd party
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// UI

import SectionTitleAndText from '../Section/SectionTitleAndText';
import SectionEditTitleAndText from '../Section/SectionEditTitleAndText';

/**
 * @description renders a only the title and text sections (static and edit)
 * @param {*} param0
 * @returns
 */

const ViewTitleAndText = ({
	indexInfo,
	information,
	sectionTitle,
	colorHr,
	field,
	onSubmitAction,
	id,
	validationSchema,
	hideEdit,
	hasInviteUser,
}) => {
	const [turnEdit, setTurnEdit] = useState(false);

	useEffect(() => {
		setTurnEdit(false);
	}, [indexInfo]);

	return (
		<div>
			{turnEdit ? (
				<SectionEditTitleAndText
					colorHr={colorHr}
					sectionTitle={sectionTitle}
					field={field}
					initialText={information}
					setTurnEdit={setTurnEdit}
					turnEdit={turnEdit}
					onSubmitAction={onSubmitAction}
					validationSchema={validationSchema}
					id={id}
				/>
			) : (
				<SectionTitleAndText
					colorHr={colorHr}
					sectionTitle={sectionTitle}
					text={information}
					setTurnEdit={setTurnEdit}
					turnEdit={turnEdit}
					hideEdit={hideEdit}
					hasInviteUser={hasInviteUser}
				/>
			)}
		</div>
	);
};

export default ViewTitleAndText;

ViewTitleAndText.propTypes = {
	/* string with the text of the section*/
	information: PropTypes.string,
	/* number that representes the tab that is displayed */
	indexInfo: PropTypes.number,
	/* title of the section with information */
	sectionTitle: PropTypes.string,
	/* string with the color of the hr */
	colorHr: PropTypes.string,
	/* string with name of the field to be edited related to the database */
	field: PropTypes.string,
	/* ValidationSchema used to manage error messages */
	validationSchema: PropTypes.object,
	/* shows or not the Edit feature */
	hideEdit: PropTypes.bool,
	/* handle the feature to invite a user in the field section */
	hasInviteUser: PropTypes.bool,
};

ViewTitleAndText.defaultProps = {
	hideEdit: false,
	hasInviteUser: false,
};
