import { useMutation } from '@tanstack/react-query';
import { ENDPOINTS_APPUSER } from '../../helpers/routes';
import { fetchPostData } from '../fetchPostData';

const postValidateEmailExistence = async (email) => {
	const endpoint = ENDPOINTS_APPUSER.VALIDATE_EMAIL_EXISTENCE;
	const values = {
		email,
	};

	const response = await fetchPostData(endpoint, values);

	const dataToReturn = response?.show_plan;

	return dataToReturn;
};

export const usePostValidateEmailExistence = () => {
	return useMutation({
		mutationFn: (email) => {
			return postValidateEmailExistence(email);
		},
	});
};
