function downloadImage(src, title) {
	fetch(src)
		.then((response) => response.blob())
		.then((blob) => {
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = title;
			link.click();
		})
		.catch((error) => {
			console.error('Error:', error);
		});
}

function downloadDocument(src, title) {
	fetch(src)
		.then((response) => response.blob())
		.then((blob) => {
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = title;
			link.click();
		})
		.catch((error) => {
			console.error('Error:', error);
		});
}

export { downloadDocument };
export { downloadImage };
