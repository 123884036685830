import { useEffect } from 'react';
export const useTextareaSize = (textareaRef, textareaValue) => {
	useEffect(() => {
		if (textareaRef && textareaRef.current) {
			textareaRef.current.style.height = '1px';
			textareaRef.current.style.height = `${
				30 + textareaRef.current.scrollHeight
			}px`;
		}
	}, [textareaRef, textareaValue]);
};
