export const ONBOARDING_EN = {
	CompletaEstosPasos:
		'Follow these steps to discover how Datarte works and later apply to the call from your account',
	BienvenidoADatarte: 'Welcome to Datarte',
	ParaDisfrutarDeDatarte: 'To enjoy Datarte',
	Crea1Obra: 'Create 1 artwork.',
	Crea1Colección: 'Create 1 collection.',
	Crea1Portafolio: 'Create 1 portfolio.',
	Crea1Artista: 'Create 1 artist.',
	Saltar: 'Skip',
	SaltarPasos: 'Skip steps',
	EscribeTuBiografía: 'Write your biography:',
	HazteConocer: 'Make yourself known',
	PlataformasVirtuales:
		'virtual art platforms are visibility spaces for all kinds of artists',
	TamañoMáximoSoportadoDeLaImagenEsDe100MB:
		'Note: the maximum supported image size is 100MB.',
	OfreceInformaciónSobreQuiénEres:
		'Offer information about your life, where you were born, studies, your artworks, your ideas and what inspires you.',
	OpcionesAvanzadas: 'Advanced options',
	AñadirImagenDePerfil: 'Add profile image',
	ImagenAgregada: 'Added image:',
	AñadirImagenDeObra: 'Add artwork image',
	AgregaTuPrimeraObra: 'Add your artworks to later apply to the calls',
	AgregaTuSegundaObra: 'Add your second artwork',
	Felicitaciones: 'Congratulations!',
	YaCreasteTuPortafolio:
		'You have already created your first virtual portfolio!',
	RápidoYSencillo:
		'To apply to the calls you will need to add your virtual portfolio created in Datarte.',
	Finalizar: 'Finalize',
	Empezar: 'Start',
	OrdenaTuCreatividad: 'Order your creativity',
	TituloDeLaObra: 'Title of the artwork',
	estaPáginaNoEstáDisponible: 'this page is not available',
	LoSentimosEstaPáginaNoEstáDisponible:
		'Sorry, the web address you entered is not available.',
	VolverA: 'To return to ',
	ExploraNuevasOportunidadesEnNuestraAplicación:
		'Create your artist profile to later share it in the calls.',
};
