import { useNavigate, useParams } from 'react-router-dom';
//UI
import { ReactComponent as PageGrey } from '../../../assets/icons/PageGrey.svg';
import TextContentThird from '../../../atoms/Text/Content/TextContentThird';

const EditablePortfolioBottomBarDesktop = ({ currentLocation }) => {
	const navigate = useNavigate();
	const { portfolioId } = useParams();

	const isSectionView = currentLocation.includes('/secciones');

	const handleClickToSections = () => {
		if (!isSectionView) {
			navigate(`/portafolios/portafolio-experto/${portfolioId}/secciones`);
		} else {
			navigate(`/portafolios/portafolio-experto/${portfolioId}/edicion`);
		}
	};

	return (
		<div className=' fixed bottom-0 z-50 hidden w-full items-center justify-end border-t-2 border-gray-200 bg-white p-1 transition-all duration-300 md:flex'>
			<div onClick={handleClickToSections}>
				<TextContentThird
					text={
						isSectionView ? 'Retornar a edición' : 'Visualización de secciones'
					}
					design='cursor-pointer'
				/>
			</div>
			<div
				className=' relative flex cursor-pointer p-1 transition-transform hover:scale-105'
				onClick={handleClickToSections}
			>
				<PageGrey className='mx-auto h-8 w-8' />
				<div className='absolute top-[14px] left-[25%] h-4 w-4 text-center'>
					<p className=' text-xs text-grey-50'>{'1'}</p>
				</div>
			</div>
		</div>
	);
};

export default EditablePortfolioBottomBarDesktop;
