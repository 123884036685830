//3rd
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// BL
import { getCollections } from '../../../BusinessLogic/redux/reducers/collection';
import { changeCollectionState } from '../../../BusinessLogic/redux/reducers/collection';
import { setCollectionOriginPath } from '../../../BusinessLogic/redux/reducers/routesHistory';
import { setPortfolioPreviewName } from '../../../BusinessLogic/redux/reducers/portfolio';
import { setPortfolioPreviewSlug } from '../../../BusinessLogic/redux/reducers/portfolio';
import {
	filterCollectionGalleryInitialValues,
	filterCollectionArtistInitialValues,
} from '../../../BusinessLogic/data/CollectionSingle';

// UI
import FilterCollection from '../collection/FilterCollection';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import SecondaryNavbar from '../../../UI/organisms/Navbar/NavbarSecondary';
import ImagesSectionWithFilter from '../../../UI/organisms/Image/ImageSectionWithFilter';
import ButtonChangeView from '../../../UI/molecules/Buttons/ButtonChangeView';
import ButtonLinkBack from '../../../UI/atoms/Buttons/Link/ButtonLinkBack';
import ButtonFabPrincipalUrl from '../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import FilterAllCollections from '../../../UI/molecules/Filters/FilterAllCollections';

/**
 * @description renders Collection All in plantilla section.
 * @param {*} param0
 * @returns
 */
export default function TemplateCollectionSelection() {
	const { t } = useTranslation();
	const { portfolio_type } = useParams();
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const { value } = useSelector((state) => state.artistFilter);
	const { collections } = useSelector((state) => state.collections);
	const { uniqueIdCollection, portfolioDataCreation } = useSelector(
		(state) => state.portfolios
	);
	const { appUser } = useSelector((state) => state.appUser);

	const [filterArray, setFilterArray] = useState({});
	const [filterChange, setFilterChange] = useState(0);
	const [nameFilter, setNameFilter] = useState(value.payload);
	const [changeView, setChangeView] = useState(false);
	const [collectionsToDisplay, setCollectionsToDisplay] = useState(null);

	const isGalleryUser = appUser.role_company === 'GALLERY';
	const isArtistUser = appUser.role_company === 'ARTIST';
	const isCatalogueArtistUser = appUser.role_company === 'ARTIST_CATALOGATION';

	function filterInitialValues() {
		if (isGalleryUser) {
			return filterCollectionGalleryInitialValues;
		} else if (isArtistUser || isCatalogueArtistUser) {
			return filterCollectionArtistInitialValues;
		}
	}

	const [valuesFilterCollections, setValuesFilterCollections] = useState(
		filterInitialValues()
	);

	const switchRedirect = () => {
		switch (portfolio_type) {
			case 'automatic':
				return '/portafolios/new';
			case 'expert':
				return '/plantilla/configuraciones';
			default:
				return '';
		}
	};

	const switchGoBack = () => {
		switch (portfolio_type) {
			case 'automatic':
				return '/plantilla';
			case 'expert':
				return '/portafolios/plantillas';
			default:
				return '';
		}
	};

	const switchSubtitleText = () => {
		switch (portfolio_type) {
			case 'automatic':
				return t('Paso3');
			case 'expert':
				return t('Paso2');
			default:
				return '';
		}
	};

	const filterCollectionsToAutomaticAndExpert = () => {
		switch (portfolio_type) {
			case 'expert':
				return collections.filter(
					(collection) =>
						collection.has_artworks && !collection.is_general_collection
				);
			default:
				return collections.filter(
					(collection) => !collection.is_general_collection
				);
		}
	};

	useEffect(() => {
		dispatch(setPortfolioPreviewName(null));
		dispatch(setPortfolioPreviewSlug(null));
	}, [dispatch]);

	useEffect(() => {
		if (!collections) {
			dispatch(getCollections());
			dispatch(changeCollectionState(true));
		}
		//eslint-disable-next-line
	}, []);

	const handleFilterCollection = (collectionsBase) => {
		if (collectionsBase) {
			let dataFiltered = collectionsBase;
			Object.keys(valuesFilterCollections)?.forEach((field) => {
				if (valuesFilterCollections[field] !== '') {
					if (field === 'collection') {
						dataFiltered = dataFiltered.filter((item) =>
							item?.name
								.toLowerCase()
								.includes(valuesFilterCollections[field].toLowerCase())
						);
					} else {
						dataFiltered = dataFiltered?.filter((item) => {
							if (typeof item[field] === 'number') {
								return item[field] === Number(valuesFilterCollections[field]);
							} else {
								return item[field] === valuesFilterCollections[field];
							}
						});
					}
				}
			});
			return dataFiltered;
		} else {
			return collectionsBase;
		}
	};

	useEffect(() => {
		if (Array.isArray(collections)) {
			let collectionsFiltered;
			collectionsFiltered = filterCollectionsToAutomaticAndExpert();
			collectionsFiltered = handleFilterCollection(collectionsFiltered);
			if (collectionsFiltered.length > 0) {
				setCollectionsToDisplay(collectionsFiltered);
			} else {
				setCollectionsToDisplay({
					code: 0,
					textEmpty: 'NoHayColecciones',
					textEmptyLink: 'CrearUnaColecciónAquí',
				});
			}
		} else {
			setCollectionsToDisplay({
				code: 0,
				textEmpty: 'NoHayColecciones',
				textEmptyLink: 'CrearUnaColecciónAquí',
			});
		}
		//eslint-disable-next-line
	}, [collections, valuesFilterCollections]);

	return (
		<TemplateWithNavBar>
			{collectionsToDisplay && (
				<>
					<SecondaryNavbar
						title={t('Colecciones')}
						textColor='text-yellow-50'
						color='bg-yellow-50'
						redirect={switchGoBack()}
						changeView={changeView}
						setChangeView={setChangeView}
						Filter={FilterCollection}
						data={collectionsToDisplay}
						filterArray={filterArray}
						setFilterArray={setFilterArray}
						filterChange={filterChange}
						setFilterChange={setFilterChange}
						nameFilter={nameFilter}
						setNameFilter={setNameFilter}
						textBack={t('RegresarPlantillas')}
						textBackDesign='pl-3 text-yellow-50'
						arrowLeftColor='yellow'
						showFilter={false}
						showchangeView={false}
						containerDesign={'md:h-auto pt-10 pb-5'}
					>
						<FilterAllCollections
							valuesFilterCollections={valuesFilterCollections}
							setValuesFilterCollections={setValuesFilterCollections}
							valuesFilterInitialValues={filterInitialValues()}
						/>
					</SecondaryNavbar>
					<div className='mb-3 flex h-12 w-full items-center justify-between md:hidden'>
						<ButtonLinkBack />
						<TextMainTitle
							title={t('Colecciones')}
							textColor='text-yellow-50'
							color='bg-yellow-50'
						/>
						<div className='flex flex-row'>
							<ButtonChangeView
								changeView={changeView}
								setChangeView={setChangeView}
							/>
							<FilterAllCollections
								valuesFilterCollections={valuesFilterCollections}
								setValuesFilterCollections={setValuesFilterCollections}
								valuesFilterInitialValues={filterInitialValues()}
							/>
						</div>
					</div>
					<div className='mb-3 flex w-full justify-center'>
						<TextContentSecond
							text={switchSubtitleText()}
							paragraphDesign={'text-yellow-50 text-2xl font-bold'}
						/>
					</div>
					{Array.isArray(collectionsToDisplay) &&
						collectionsToDisplay.length > 0 && (
							<div className='flex w-full flex-col justify-center px-4 py-8 md:py-4 '>
								<TextContentSecond
									text={t(
										'SeleccionaLaColecciónQueQuieresUtilizarEnTuPortafolio'
									)}
									paragraphDesign='text-base mb-2 font-light'
								/>
								<TextContentSecond
									text={t(
										'SoloSeVisualizaránColeccionesConObrasAsociadasAEstaCuenta'
									)}
									paragraphDesign='font-thin text-base'
								/>
							</div>
						)}
					<ImagesSectionWithFilter
						data={collectionsToDisplay}
						filterChange={filterChange}
						filterArray={filterArray}
						nameFilter={nameFilter}
						changeView={changeView}
						selectionSquare={true}
						newEntityLink={'/colecciones'}
						selectionWithBorder={true}
						designContainerCards='!grid-cols-1 xs:!grid-cols-2 sm:!grid-cols-4 lg:!grid-cols-6'
						designWrapperImageItem='!h-[150px]'
						onEmptyDataAction={() =>
							dispatch(setCollectionOriginPath(pathname))
						}
					/>
					{uniqueIdCollection && portfolioDataCreation.idCollection && (
						<div>
							<ButtonFabPrincipalUrl
								label={t('Continuar')}
								url={switchRedirect()}
								icon={false}
							/>
						</div>
					)}
				</>
			)}
		</TemplateWithNavBar>
	);
}
