export const NAVBAR_EN = {
	Catalogación: 'Cataloging',
	Difusión: 'Diffusion',
	Convocatoria: 'Calls',
	Perfil: 'Profile',
	Artistas: 'Artists',
	Obras: 'Artworks',
	Documentos: 'Documents',
	Lugares: 'Places',
	Contactos: 'Contacts',
	Exposiciones: 'Exhibitions',
	Colecciones: 'Collections',
	Portafolios: 'Portfolios',
	ConvocatoriasAbiertas: 'Open Calls',
	MisPostulaciones: 'My applications',
	Postulaciones: 'Applications',
	Catalogo: 'Catalogue',
	TuPlanEstaActivo: 'Your plan is active',
	TuPlanSeHaActivadoExitosamente:
		'You can now start enjoying the Datarte web app and access all the additional benefits.',
	hazteMiembro: 'Become a member!',
};
