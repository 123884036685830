//3rd
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PropTypes from 'prop-types';

//UI
import TextTitlePrincipal from '../../atoms/Text/Titles/TextTitlePrincipal';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

/**
 * @description manages the resources section.
 * @returns
 */
const SectionResources = ({ resources }) => {
	const { t } = useTranslation();
	const isValidResourcesArray = resources && Array.isArray(resources);
	return (
		<div className='mb-8 flex flex-col'>
			<TextTitlePrincipal
				title={t('RecursosDatarte')}
				design='text-left w-full text-[20px] mt-4 py-8'
				textColor='text-purple-50'
			/>
			{isValidResourcesArray &&
				resources?.map((resource, index) => (
					<div className='my-2 h-fit' key={index}>
						<div
							key={resource.id}
							className='relative mt-4 mb-10 grid h-80 grid-cols-1 items-center  px-2 md:grid-cols-2'
						>
							<LazyLoadImage
								src={resource.image_url}
								effect='blur'
								wrapperClassName={`overflow-hidden lazyLoadingImages h-full md:h-[90%] lg:h-full`}
								className='object-cover'
								delayTime='0'
								threshold='800'
							/>

							<div className='flex  h-full flex-col justify-center bg-light_grey-75 p-2  md:h-[90%] md:p-16 lg:h-full'>
								<TextContentPrincipal
									text={resource.title}
									design='pt-8 !font-bold'
								/>
								<TextContentSecond
									text={resource.content}
									design='pt-3 md:pt-8'
								/>
								<div className='mt-2 flex justify-end md:mt-8'>
									<ButtonFab
										size={'fitContent'}
										colour={'green'}
										label={t('leerMás')}
										ButtonClassName={'rounded-[16px] px-4 py-1'}
										action={() => window.open(resource.link, '_blank')}
									/>
								</div>
							</div>
						</div>
					</div>
				))}
		</div>
	);
};

SectionResources.propTypes = {
	//Array with the resources data
	resources: PropTypes.array,
};

export default SectionResources;
