//3rd parties
import PropTypes from 'prop-types';

//UI

/**
 *
 * @description a button with an icon. It can turn a boolen true or false on click.
 * @returns
 */

const ButtonWithIcon = ({
	icon,
	design,
	showBoolean,
	setShowBoolean,
	figureDesign,
}) => {
	return (
		<button
			className={`${design} rounded-full lg:hover:bg-gray-200`}
			data-test='button-with-icon'
		>
			<figure
				className={`h-8 w-8 ${figureDesign}`}
				onClick={() => setShowBoolean(!showBoolean)}
			>
				{icon}
			</figure>
		</button>
	);
};

ButtonWithIcon.propTypes = {
	/** Icon that appears in the doc */
	icon: PropTypes.object,
	/** Design of the container */
	design: PropTypes.string,
	/** Boolean to show or hide something*/
	showBoolean: PropTypes.bool,
	/** Function to show or hide something*/
	setShowBoolean: PropTypes.func,
	/** string tat can change the aligment of the figure or size*/
	figureDesign: PropTypes.string,
};

ButtonWithIcon.defaultProps = {};

export default ButtonWithIcon;
