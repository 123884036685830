import * as Yup from 'yup';

const expositionSingleTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'cityCountry',
				field: 'identification',
				information: [
					{ label: 'Título', value: '', field: 'name' },
					{
						label: 'FechaDeInicio',
						value: '',
						field: 'initial_date',
						type: 'date',
					},
					{
						label: 'FechaFinalización',
						value: '',
						field: 'ending_date',
						type: 'date',
					},
					{ label: 'Lugar', value: '', field: 'location' },
					{
						label: 'País',
						value: '',
						field: 'country_id',
						type: 'dropdown',
						options: [],
					},
					{
						label: 'Ciudad',
						value: '',
						field: 'city_id',
						type: 'dropdown',
						options: [],
					},
				],
			},
			{
				title: 'Descripción',
				type: 'text',
				field: 'description',
				information: '',
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const expositionSingleButtons = ['Identificación', 'Imágenes'];

const expositionValidationSchema = [
	[
		{
			name: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.required('TituloRequerido'),
			location: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.required('LugarRequerido'),
			description: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
			date: Yup.date().nullable(),
			country_id: Yup.string().required('PaísEsNecesario'),
			city_id: Yup.string().required('CiudadEsNecesario'),
		},
	],
];

const expositionFormValidationSchema = Yup.object({
	name: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('TituloRequerido'),
	location: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('LugarRequerido'),
	description: Yup.string()
		.max(1000, 'NumeroMáximoCaracteres')
		.required('DescripciónRequerida')
		.nullable(),
	date: Yup.date().nullable(),
	country_id: Yup.string().required('PaísEsNecesario'),
	city_id: Yup.string().required('CiudadEsNecesario'),
});

const expositionInitialValues = {
	name: '',
	initial_date: '',
	ending_date: '',
	description: '',
	location: '',
	country_id: '',
	city_id: '',
};

const buttonsExposition = ['Identificación', 'Imágenes'];

export {
	expositionInitialValues,
	expositionSingleTabs,
	expositionSingleButtons,
	expositionValidationSchema,
	expositionFormValidationSchema,
	buttonsExposition,
};
