/* 3rd party */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

/* BL */
import { addConfigurationChip } from '../../../BusinessLogic/redux/reducers/artistFilter';
import { multiPropsFilters } from '../Filter/Filter';

/* UI */
import ImageListContent from '../../molecules/Image/ImageListContent';
import DocumentTypes from '../../../Pages/sections/document/DocumentTypes';
import TextWithLink from '../../molecules/Text/TextWithLink';

/**
 * @description render all documents of the user
 * @param {*} param0
 * @returns
 */
function DocumentSectionWithFilter({
	data,
	filterChange,
	filterArray,
	nameFilter,
	redirect,
	changeView,
	newEntityLink,
}) {
	const dispatch = useDispatch();
	const [filteredDocument, setFilteredDocument] = useState(data);

	useEffect(() => {
		if (Array.isArray(data)) {
			setFilteredDocument(multiPropsFilters(data, filterArray));
		}
	}, [data, filterArray, filterChange]);

	useEffect(() => {
		const data = JSON.parse(JSON.stringify(filterArray));
		dispatch(addConfigurationChip(data));
	}, [data, dispatch, filterArray, filterChange]);

	function changeViewRender(changeView, document) {
		switch (changeView) {
			case true:
				return (
					<div>
						<ImageListContent
							key={document.id}
							id={document.id}
							imagen={document.main_picture_url}
							title={document.name}
							name={document.first_name}
							lastName={document.last_name}
							email={document.email}
							rol={document.role}
							phone={document.phone_number}
							redirect={redirect}
						/>
					</div>
				);

			case false:
				return (
					<div>
						<DocumentTypes document={document} redirect={redirect} />
					</div>
				);

			default:
				break;
		}
	}

	return (
		<div className='xs:container xs:mx-auto'>
			{data.code === 0 ? (
				<div className='flex w-11/12 xs:mx-auto'>
					<TextWithLink
						emptyEntityMessage={data.message}
						redirect={newEntityLink}
					/>
				</div>
			) : (
				<div>
					<div
						className={
							changeView
								? 'flex flex-col justify-center px-8'
								: 'grid w-full grid-cols-3 justify-center gap-x-2 gap-y-0 px-4 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7'
						}
					>
						{Array.isArray(filteredDocument) &&
							filteredDocument
								?.filter(
									(document) =>
										document?.name
											.toLowerCase()
											.indexOf(nameFilter.toLowerCase()) >= 0
								)
								?.map((document) => changeViewRender(changeView, document))}
					</div>
				</div>
			)}
		</div>
	);
}

DocumentSectionWithFilter.propTypes = {
	/* array of object with the data to render */
	data: PropTypes.array,
	/* handle the filter state */
	filterChange: PropTypes.number,
	/* array with the filter objects */
	filterArray: PropTypes.object,
	/* name to filter */
	nameFilter: PropTypes.string,
	/* url redirect to specific document */
	redirect: PropTypes.string,
	/* boolean that represent the state of the changeview */
	changeView: PropTypes.bool,
	/* link to new entity create */
	newEntityLink: PropTypes.string,
};

DocumentSectionWithFilter.defaultProps = {
	nameFilter: '',
};

export default DocumentSectionWithFilter;
