import { Routes, Route } from 'react-router-dom';
import SignUp from '../../Pages/authentication/signup/SignUp';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';
import SignupForm from '../../UI/organisms/SignUp/SignupForm';

export default function AuthRoutes() {
	return (
		<div>
			<Routes>
				<Route path='/' element={<SignUp />} />

				<Route exact path='/:user_type' element={<SignupForm />} />
				<Route path='*' element={<PageNotFound />} />
			</Routes>
		</div>
	);
}
