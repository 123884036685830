import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_ARTWORK } from '../../helpers/routes';
import { fetchGetData } from '../../hooks/fetchGetData';
import { fetchPutData } from '../../hooks/fetchPutData';

export const getArtworkImage = createAsyncThunk(
	'images/fetchArtworkIamges',
	async (id) => {
		return await fetchGetData(`${ENDPOINTS_ARTWORK.GET_ARTWORK_IMAGE}${id}/`);
	}
);

export const putArtworkImage = createAsyncThunk(
	'ArtworkImage/putArtworkImage',
	async (values) => {
		return await fetchPutData(
			`${ENDPOINTS_ARTWORK.PUT_ARTWORK_IMAGE_MAIN}${values.id}/`,
			values
		);
	}
);

export const getPublicArtworkImage = createAsyncThunk(
	'images/fetchArtworkIamges',
	async (id) => {
		return await fetchGetData(
			`${ENDPOINTS_ARTWORK.GET_PUBLIC_ARTWORK_IMAGE}${id}/`
		);
	}
);

export const getAllArtworksImages = createAsyncThunk(
	'images/getAllArtworksImages',
	async () => {
		const { organization_user_id } = localStorage;
		return await fetchGetData(
			`${ENDPOINTS_ARTWORK.GET_ALL_ARTWORKS_IMAGES}${organization_user_id}/`
		);
	}
);

const initialState = {
	artworkImage: [{}],
	allArtworksImages: [],
	status: null,
};

export const ArtworkImageSlice = createSlice({
	name: 'ArtworkImage',
	initialState,
	reducers: {
		cleanArtworkImage: (state, { payload }) => {
			state.artworkImage = [{ false: false }];
		},
	},
	extraReducers: {
		[getArtworkImage.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getArtworkImage.fulfilled]: (state, { payload }) => {
			state.artworkImage = payload;
			state.status = 'success';
		},
		[getArtworkImage.rejected]: (state, action) => {
			state.status = 'failed';
		},
		[putArtworkImage.pending]: (state, action) => {
			state.status = 'loading';
		},
		[putArtworkImage.fulfilled]: (state, { payload }) => {
			state.artworkImage = payload;
			state.status = 'success';
		},
		[putArtworkImage.rejected]: (state, action) => {
			state.status = 'failed';
		},
		[getAllArtworksImages.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getAllArtworksImages.fulfilled]: (state, { payload }) => {
			state.allArtworksImages = payload?.pictures;
			state.status = 'success';
		},
		[getAllArtworksImages.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});
export const { cleanArtworkImage } = ArtworkImageSlice.actions;

export default ArtworkImageSlice.reducer;
