export const FORM_FR = {
	Email: 'Email',
	Contraseña: 'Password',
	Ingresar: 'LOGIN',
	CheckCuenta: '¿You do not have an account?',
	Regístrate: 'Sign up',
	EmailRequerido: 'Please add an email.',
	EmailValidación: 'Please add a valid email.',
	ContraseñaRequerida: 'Please add a password.',
	OlvidasteTuContraseña: 'Did you forget your password?',
	IngresaTuEmailYTeAyudaremos: 'Enter your email and we will help you',
	Solicitar: 'Request',
	EligePerfil: 'Choose your profile',
	IngresaDatos: 'Enter your data',
	CrearCuenta: 'Create an account',
	Artistas: 'Artists',
	PlanArtistasInformación:
		'For artists looking to organize their works and participate in calls',
	Galerías: 'Galleries',
	PlanGaleríasInformación:
		'For art galleries that wish to apply their artists to the calls',
	Continuar: 'Continue',
	PrimerNombreRequerido: 'Please add a name.',
	ApellidoRequerido: 'Please add a surname.',
	EmailRequeridoRegistro: 'An email is required.',
	ContraseñaRequeridaRegistro: 'A password is required.',
	ContraseñaMínimoCaracteres:
		'The password must have a minimum of 8 characters.',
	ContraseñaConfirmación: 'Please confirm your password.',
	ContraseñaNoConcuerda: 'Passwords do not match.',
	Nombres: 'Names',
	Apellidos: 'Surnames',
	ConfirmarContraseña: 'Confirm Password',
	Consentimiento: 'Yes, I have read and consent to the',
	TérminosDeUso: 'Terms of Use',
	PolíticasSeguridad:
		'of the site and to the procedure, treatment and transfer of my personal data in accordance with the provisions of the Privacy Policy',
	PolíticasSeguridadRequerido: 'Please accept the Terms and Conditions of use.',
	DirecciónRequerida: 'Please add an address.',
	TeléfonoRequerido: 'Please add a phone.',
	País: 'Country',
	Ciudad: 'City',
	Teléfono: 'Telephone',
	Dirección: 'Address',
	Moneda: 'Currency',
	Medidas: 'Measures',
	CreandoCuenta: 'Creating your account',
	RegresarArtistas: 'Back to Artists',
	NuevoArtista: 'New artist',
	Identificación: 'Identificación',
	Nombre: 'Name',
	Apellido: 'Surname',
	Biografía: 'Biography',
	Educación: 'Education',
	Exposiciones: 'Expositions',
	Publicaciones: 'Publications',
	Guardando: 'Saving',
	Guardar: 'Save',
	ClickCargarImagen: 'Click to load the image',
	SoltarOClickImagen: 'Drop or click here to load the image.',
	RegresarObras: 'Back to Works',
	NuevaObra: 'New Artwork',
	Título: 'Title',
	Código: 'Code',
	OtroCódigo: 'Other Code',
	Tipo: 'Type',
	BiDimensional: 'Two-Dimensional',
	TriDimensional: 'Three-Dimensional',
	Fecha: 'Date',
	PaísDeOrigen: 'Country of origin',
	Diferencias: 'Differences',
	Descripción: 'Description',
	Autor: 'Author',
	Observaciones: 'Observations',
	Propietario: 'Owner',
	Estado: 'State',
	Conservado: 'Conserved',
	Vendido: 'Sold out',
	Donado: 'Donated',
	Disponible: 'Available',
	Desconocido: 'Unknown',
	DimensionesObjeto: 'Dimensions(object)',
	Dimensiones: 'Dimensions',
	AlturaCm: 'Height (cm)',
	LargoCm: 'Length (cm)',
	AnchoCm: 'Width (cm)',
	PesoKg: 'Weight (kg)',
	DimensionesConBase: 'Dimensions(with base)',
	TieneBase: 'Has base',
	No: 'No',
	SI: 'Yes',
	AlturaDeBaseCm: 'Base height (cm)',
	LargoDeBaseCm: 'Base length (cm)',
	AnchoDeBaseCm: 'Base width (cm)',
	PesoDeBaseKg: 'Base weight (kg)',
	TécnicaMateriales: 'Technique and Materials',
	Técnica: 'Technique',
	Soporte: 'Support',
	Materiales: 'Materials',
	Componentes: 'Components',
	TieneComponentes: 'Does it have components?',
	NúmeroDeComponentes: 'Number of components',
	DetallesDeLosComponentes: 'Component Details',
	EstadoDeConservación: 'State of conservation',
	Ubicación: 'Location',
	UbicaciónGeneral: 'General Location',
	Cuarto: 'Room',
	UbicaciónNombre: 'Location Name',
	Avalúo: 'Appraisal',
	EsconderCatalogaciónAvanzada: 'Hide advanced cataloging',
	VerCatalogaciónAvanzada: 'See advanced cataloging',
	RegresarColecciones: 'Back to Collections',
	NuevaColección: 'New collection',
	SubColección: 'Subcollection',
	RegresarContactos: 'Back to Contacts',
	NuevoContacto: 'New contact',
	Rol: 'Role',
	Photographer: 'Photographer',
	LocationContact: 'Location Contact',
	Owner: 'Owner',
	NúmeroTeléfono: 'Phone number',
	Otro: 'Other',
	RegresarDocumentos: 'Back to Documents',
	NuevoDocumento: 'New Document',
	Pertenece: 'Belongs',
	Ninguno: 'None',
	Artista: 'Artist',
	Obras: 'Artworks',
	UnArtista: 'an artist',
	UnaObra: 'an artwork',
	RegresarExposiciones: 'Back to Expositions',
	NuevaExposición: 'New Expositions',
	FechaDeInicio: 'Start date',
	FechaFinalización: 'End date',
	Lugar: 'Place',
	RegresarLugares: 'Back to Places',
	NuevoLugar: 'New Place',
	Detalles: 'Details',
	Contacto: 'Contact',
	RegresarLugar: 'Back to Place',
	NuevaSala: 'New Room',
	Piso: 'Floor',
	RegresarSala: 'Back to Room',
	NuevaUbicación: 'New Location',
	CrearPortafolio: 'Create Portfolio',
	VisualizarPortafolio: 'View Portfolio',
	PersonalizaTuPortafolio: 'Customize your Portfolio',
	Titulo: 'Title',
	URL: 'URL',
	Acceso: 'Access',
	Público: 'Public',
	PúblicoConClave: 'Public with key',
	Privado: 'Private',
	Resolución: 'Resolution',
	Alta: 'High',
	Media: 'Medium',
	Baja: 'Low',
	RegresarArtista: 'Back to Artist',
	RegresarColección: 'Back to Collection',
	RegresarSubcolección: 'Back to Subcollection',
	RegresarObra: 'Back to Artwork',
	RegresarDocumento: 'Back to Document',
	RegresarUbicación: 'Back to Location',
	SeleccionarObras: 'Select artworks',
	Seleccionar: 'Select',
	ObraSeleccionada: 'Selected artwork',
	ObrasSeleccionadas: 'Selected artworks',
	ObrasDisponiblesEnDatarte: 'Artworks available in Datarte',
	PortafoliosDisponiblesEnDatarte: 'Available portfolios in Datarte',
	DocumentoSeleccionado: 'Selected document',
	DocumentosSeleccionados: 'Selected documents',
	RegresarNuevaColección: 'Back to new collection',
	RegresarNuevaSubColección: 'Back to Subcollection',
	ArchivosSeleccionados: 'selected files',
	Texto: 'Text',
	Galería: 'Gallery',
	AgregarElementos: 'Add elements',
	Imagen: 'Image',
	Completo: 'Complete',
	EscogeLaObra: 'Choose the artwork',
	AgregarAPágina: 'Add to page',
	NombreDebeSerTexto: 'Name must be a text',
	NombreEsNecesario: 'Name is necessary.',
	DebeSerUnNúmero: 'It must be a number without any other character.',
	NumeroMáximoCaracteres: 'The maximum number of characters is 1000',
	NumeroMáximoCaracteresDosMil: 'The maximum number of characters is 2000',
	DimensionDebeSerPositiva: 'The dimension must be a positive number.',
	DimensionEsNecesaria: 'The dimension is necessary.',
	NumeroNoSerMenorCero: 'The number cannot be less than 0',
	AvalúoPositivo: 'The appraisal must be a positive number.',
	AvalúoNecesario: 'The appraisal is necessary.',
	RequiereDosCaracteres: 'Requires at least 2 characters.',
	NombreRequerido: 'The name is required.',
	DetalleEsNecesario: 'A detail is necessary.',
	PaísEsNecesario: 'A country is necessary.',
	CiudadEsNecesario: 'A city is necessary.',
	ContactoEsNecesario: 'A contact is necessary.',
	PisoNecesario: 'A floor is necessary.',
	ApellidoNecesario: 'A last name is required.',
	EmailNecesario: 'An email is necessary.',
	TituloRequerido: 'Title is required.',
	LugarRequerido: 'Location is required.',
	DescripciónRequerida: 'Requires a description',
	UrlObligatorio: 'The URL is required.',
	UrlAlfanuméricaYGuiones: 'Only alphanumeric characters and hyphens (-).',
	ClaveRequerida: 'A password is required.',
	ClaveMuyCorta:
		'The password is very short - it must be at least 8 characters long.',
	ConfirmarClave: 'Please confirm the password.',
	ClavesNoConcuerdan: 'The passwords do not match.',
	FechaValida: 'A valid date must be entered',
	TeléfonoValido: 'A valid phone is required.',
	CorreoValido: 'A valid email must be entered.',
	NuevaContraseñaRequerida: 'A new password is required.',
	ConfirmarNuevaContraseña: 'Please confirm your new password.',
	ContraseñaMuyCorta:
		'The password is very short - it must be at least 8 characters long.',
	PulsarElTextoDelEjemploParaAgregarloALaPágina:
		'Click sample text to add to page',
	AgregarUnTítulo: 'Add a title',
	AgregarUnSubtítulo: 'Add a subtitle',
	AñadirUnPoquitoDeTexto: 'Add a bit of text',
	UsadoRecientemente: 'Recently used',
	NuevoPortafolio: 'New Portfolio',
	EscogeQueTipoDePortafolioQuieresCrear:
		'Choose what type of portfolio you want to create',
	LienzoEnBlanco: 'Blank canvas',
	PortafolioDesdeCero:
		'You can create a portfolio with your collection information and edit all the details.',
	PlantillaPredefinida:
		'You can choose a predefined template to add your artwork and texts.',
	PlantillaEditable: 'Editable template',
	PortafolioAutomático: 'Automatic portfolio',
	PortafolioBásico: 'Basic portfolio',
	HazClicParaCargarElArchivo: 'Click to upload the file',
	SueltaOHazClickAquíParaCargarElArchivo:
		'Drop or click here to upload the file',
	SoltarOClickImagenPortadaColeccion:
		'Drop or click here to load the cover image of the collection',
	ClickCargarImagenPortadaColeccion:
		'Click to load the cover image of the collection',
	SoltarOClickImagenPortadaObra:
		'Drop or click here to load the image of the artwork',
	ClickCargarImagenPortadaObra: 'Click to load the cover image of the artwork',
	SoltarOClickImagenPortadaLugar:
		'Drop or click here to load the cover image of the place',
	ClickCargarImagenPortadaLugar: 'Click to load the cover image of the place',
	SoltarOClickImagenPortadaContacto:
		'Drop or click here to load the cover image of the contact',
	ClickCargarImagenPortadaContacto:
		'Click to load the cover image of the contact',
	SoltarOClickImagenPortadaExposicion:
		'Drop or click here to load the cover image of the exposition',
	ClickCargarImagenPortadaExposicion:
		'Click to load the cover image of the exposition',
	SoltarOClickImagenPortadaArtista:
		'Drop or click here to load the cover image of the artist',
	ClickCargarImagenPortadaArtista:
		'Click to load the cover image of the artist',
	CatalogoDeObras: 'Catalogue of artworks',
	ParaAplicarAEstaConvocatoriaDebesSeleccionar:
		'To apply to this call you must select your works uploaded to Datarte.',
	SeleccionaLasObrasQueQuieres:
		'Select the works you want to attach to the call.',
	ObrasMasDisponibles: 'More artworks available',
	obrasSeleccionadas: 'selected artworks',
	OrdenarPor: 'Sort by:',
	SeleccionaUno: '---Select one---',
	ObrasAprobadas: 'Approved artworks',
	MasNuevoAAntiguo: 'Newer to older',
	MasAntiguoANuevo: 'Older to newer',
	PrecioDeMenorAMayor: 'Lowest to highest',
	PrecioDeMayorAMenor: 'Highest to lowest',

	PortafolioRequerido: 'Required portfolio',
	PortafolioLibre: 'Free portfolio',
	NoTienesPortafoliosEnDatarteParaAplicar:
		'You have no portfolios in Datarte. To apply please create a portfolio.',
	ParaAplicarAEstaConvocatoriaDebesSeleccionarUnPortafolio:
		'To apply to this call you must select a portfolio created in Datarte.',
	SeleccionaElPortafolioQueDeseasAdjuntar:
		'Select the portfolio you want to attach to the call.',
	RegresarConvocatoria: 'Back to the call',
	SeleccionarPortafolio: 'Select portfolio',
	VerPortafolio: 'See portfolio',
	RegresarAPostulación: 'Back to postulation',
	RestablecerContraseña: 'Restore password',
	NuevaContraseña: 'New Password:',
	ConfirmarLaNuevaContraseña: 'Confirm new password:',
	CambiarMiContraseña: 'Change my password',
	ParaAplicarAEstaConvocatoria:
		'In order to apply to this call you must register in Datarte or log in to your account. Once you do, you can apply in the "Call" section',
	IngresaATuCuenta: 'Log in to your account',
	Postularse: 'Apply',
	CampoRequerido: 'This field is required',
	EsteEmailEsInvalido: 'This email is invalid',
	HemosEnviadoLasInstrucciones:
		'We have sent password reset instructions to your',
	correoElectrónico: 'email address.',
	NecesitasAyuda: 'Need help?',
	YaEnviamosLasInstrucciones:
		'We have already sent password reset instructions to your email address.',
	EsteCorreoElectrónicoYaTieneUnaSolicitud:
		'This email already has a password change request, please wait for it to expire to try again.',
	ClickEn: 'Click on:',
	NoSePuedeCambiarLaContraseña:
		'The password cannot be changed, it has already been reset another time. If you need to repeat this step, try it.',
	CambioDeContraseñaRealizadoCorrectamente: 'Password change successful.',
	SeHaProducidoUnError: 'An error has occurred',
	solicitudCambioDeContraseñaACaducado:
		'This password change request has expired. If you need to repeat this step, try it.',
	CuentaCreadaExitosamente:
		'This account has been created successfully, you can now enjoy the benefits it offers you ',
	Cerrar: 'Close',
	CuentaCreada: 'Account created',
	PostularteEnLasConvocatorias: 'and apply in the calls.',
	VuelveAIntentarloOSelecciona: 'Try again or select ',
	ContraseñaIncorrecta: 'Wrong Password: ',
	ParaCambiarla: 'to change it.',
	Error: 'Error',
	DatosDeRegistroIncorrectos: 'Incorrect login information.',
	SeleccionarImágenPortada: 'Select cover image',
	ImágenPortada: 'Cover image',
	SeleccionaImagenPortadaColección: 'Select the cover image of the collection',
	SeleccionarImagenObra: 'Select image from an artwork',
	CargarImagenPortada: 'Upload a cover image',
	SeleccionarImagen: 'Select image',
	SeleccionarUnaImagen: 'Select an image',
	CargandoImagen: 'Loading image',
	Empresa: 'Business',
	RegresarFormulario: 'Back to form',
	PortafolioSlugRepetido:
		'This URL is associated with another portfolio, please use another one',
	PlantillaExpertos: 'Template experts',
	PortafolioCreadoPorArtistas:
		'This portfolio was created by artists for artists. It is an editable portfolio.',
	RecomendadoPorDatarte: 'Recommended by Datarte',
	SeleccioneMétodo: 'Select which method you like to start your portfolio',
	InformaciónDeObra:
		'Choose the information of the work you want to include in the portfolio.',
	Crear: 'Create',
	AñoInvalido: 'Invalid year',
	Precios: 'Prices',
	FormularioNoEnviado: 'The form could not be submitted, please try again.',
	ConfiguraciónDelPortafolio: 'Portfolio setup',
	LosCambiosSeGuardanAutomáticamente:
		'All changes already made will be saved automatically.',
	AccesoRequerido: 'The type of access is required',
	NoFuePosibleCargarElPortfolioExperto: 'Failed to load the expert portfolio',
	Cargando: 'Loading',
	Organizaciones: 'Organizations',
	EscogeLosCamposDeObras:
		'Choose the fields of works that you want to show in your portfolio',
	DatosDeObras: 'Artworks data',

	TuCuentaSeHaCreadoExitosamente: 'Your account has been successfully created.',
	OrganizaciónTuCuentaSeHaCreadoExitosamente:
		'Your account has been successfully created. You can now start using the Datarte app and manage your calls.',
	GaleríaTuCuentaSeHaCreadoExitosamente:
		'Your account has been successfully created. You can now start using the Datarte app and apply artists in the calls for proposals.',

	PlanesModalTitulo:
		'To apply to the Datarte calls for applications, choose a plan',
	PlanesModalTexto: 'Enjoy the benefits offered by Datarte',
	PlanesModalBoton: 'See plans',

	HemosCreadoUnPortafolio: 'We have created a portfolio from your collection.',
	ParaEditarTuPortafolio:
		'To edit your portfolio, click on the different elements. Remember: changes will be saved automatically',
	NoHayColecciones:
		'To create a new portfolio you need to have a collection created.',
	CrearUnaColecciónAquí: 'Create a collection here.',
	AutomáticamenteGenerado: 'Automatically generated',
	CreamosEstaColecciónGeneral:
		'We created this collection with all the works linked to this account. Automatically update any changes you make.',
	CreamosEstePortafolioGeneral:
		'We create this portfolio with the data that the complete collection has. Automatically update any changes you make.',
	PlanOrganizaciónInformación:
		'For residences, museums, art galleries, cultural centers and others who want to create and promote their calls',

	DebeSeleccionarUnaOpción: 'You must select an option',
	GaleríasDeArte: 'Art galleries',
	SeleccionaUnaOpción: 'Select an option',
	Codigo: 'Code',
	OtroCodigo: 'Other code',
	Sala: 'Room',
	NoHayResultados: 'There are no results that match your search criteria',
	tamañoMinimoPermitido: 'The minimum size allowed is 1 MB.',
	NotaTamañoSoportado:
		'Note: The maximum supported image size is 10MB and the recommended minimum size is 1MB.',
	URLVideo: 'URL Video',
	UnURLEsNecesario: 'URL is required',
	Duración: 'Duration',
	AutorVideo: 'video holder',
	Aspecto: 'Aspect',
	NúmeroDeSerie: 'Serial number',
	ArchivosPermitidosVideo: 'Valid videos: Youtube, Vimeo, Dailymotion.',
	ArtistaNoPuedeEliminarse: 'It is not possible to delete this artist',
	ArtistaNoPuedeEliminarseDescription:
		'This artist is nominated for a call, therefore, it cannot be deleted',
	ColecciónNoPuedeEliminarse: 'It is not possible to delete this collection',
	ColecciónNoPuedeEliminarseDescription:
		'This collection is part of a postulation, therefore it cannot be deleted',
	ObraNoPuedeEliminarse: 'It is not possible to delete this work',
	ObraNoPuedeEliminarseDescription:
		'This work is part of a postulation, therefore, it cannot be deleted',
	PortafolioNoPuedeEliminarse: 'It is not possible to delete this portfolio',
	PortafolioNoPuedeEliminarseDescription:
		'This portfolio is part of a postulation, therefore it cannot be deleted',
	ExpertPorfolioSlideNoPuedeEliminarse:
		'It is not possible to delete this section',
	ExpertPorfolioSlideNoPuedeEliminarseDescripción:
		'An expert portfolio cannot run out of sections, it must contain at least one',
	URLInvalido: 'Invalid URL',

	FechaCierreRequerida: 'Closing date required',
	IniciarSesion: 'Log in',
};
