/**
 * @description generate text message for the goBack feature .
 * @param {*} param0
 * @returns
 */

function textGoBackGenerator(urlList, defaultValue, textGoBackProfile) {
	let message = defaultValue;
	if (urlList.length === 0) return message;
	else {
		const sectionList = {
			artistas: textGoBackProfile,
			obras: 'RegresarObra',
			documentos: 'RegresarDocumento',
			album: 'RegresarColección',
			lugares: 'RegresarLugar',
			salas: 'RegresarSala',
			ubicaciones: 'RegresarUbicación',
			exposiciones: 'RegresarExposiciones',
		};
		const keys = Object.keys(sectionList);
		keys.forEach((section) => {
			if (urlList[urlList.length - 1]?.includes(section))
				message = sectionList[section];
		});
		if (
			message === 'RegresarColección' &&
			urlList[urlList.length - 2]?.includes('album')
		)
			message = 'RegresarSubcolección';
		if (urlList[urlList.length - 1] === '/') message = 'RegresarHome';
		return message;
	}
}

export default textGoBackGenerator;
