//3rd parties
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactPlayer from 'react-player';
/**
 * @description header de la planilla
 */

export default function PortfolioHeader({
	artist_name,
	name,
	description,
	showMainImage,
	main_picture,
	video_url,
}) {
	const isDefaultImage = main_picture?.includes('default_image');

	return (
		<div className='flex flex-col justify-items-center'>
			<h2 className='mt-6 text-center font-louisGeorgeCafe text-3xl font-semibold uppercase md:text-5xl'>
				{artist_name}
			</h2>
			<h1 className='mb-8 mt-2 text-center font-louisGeorgeCafe text-xl font-semibold uppercase md:text-3xl'>
				{name}
			</h1>
			<p className='mb-8 w-full whitespace-pre-line text-left text-lg font-light text-gray-800 md:text-2xl'>
				{description}
			</p>
			{showMainImage && (
				<div className='flex w-full flex-row justify-center'>
					{video_url ? (
						<ReactPlayer
							url={video_url}
							width={'100%'}
							height={'100%'}
							className='aspect-video h-full w-auto overflow-hidden'
							controls
						/>
					) : (
						!isDefaultImage && (
							<LazyLoadImage
								alt={name}
								src={main_picture}
								placeholderSrc={main_picture + '_small'}
								wrapperClassName='w-full'
								className='max-h-screen w-full object-contain'
								visibleByDefault={true}
								effect={'blur'}
							/>
						)
					)}
				</div>
			)}
		</div>
	);
}

PortfolioHeader.propTypes = {
	/** Name of the artist */
	artist_name: PropTypes.string,
	/** Name of the portfolio */
	name: PropTypes.string,
	/** Description of the portfolio */
	description: PropTypes.string,
	/** Show the main picture? */
	showMainImage: PropTypes.bool,
	/** Url for the main picture  */
	main_picture: PropTypes.string,
};

PortfolioHeader.defaultProps = {
	showMainImage: true,
};
