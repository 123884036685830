import { ReactComponent as TextGrey } from '../../../UI/assets/icons/TextGrey.svg';
// import { ReactComponent as CameraGrey } from '../../../UI/assets/icons/CameraGrey.svg';
// import { ReactComponent as UserGrey } from '../../../UI/assets/icons/UserGrey.svg';
// import { ReactComponent as NoteGrey } from '../../../UI/assets/icons/NoteGrey.svg';
import { ReactComponent as ArtWorkGrey } from '../../../UI/assets/icons/ArtWorkGrey.svg';

const EditablePortfolioButtons = [
	{ name: 'Texto', icon: <TextGrey /> },
	// { name: 'Galería', icon: <CameraGrey /> },
	{ name: 'Obras', icon: <ArtWorkGrey /> },
	// { name: 'Perfil', icon: <UserGrey /> },
	// { name: 'Documentos', icon: <NoteGrey /> },
];

const editablePortfolioArtworkButtons = ['Completo', 'Imagen'];

const completeArtworkSelected = {
	picturePosition: {},
	textAlign: 'text-left',
	texPosition: {},
	elementType: 'elementArtworkComplete',
};

const textArtworkLeftSelected = {
	elementType: 'artworkElementText',
	textAlign: 'text-left',
	texPosition: {},
};

const textArtworkRightSelected = {
	elementType: 'artworkElementText',
	textAlign: 'text-right',
	texPosition: '',
};

const imageSelected = {
	elementType: 'artworkElementPicture',
	picturePosition: {},
};

export {
	EditablePortfolioButtons,
	completeArtworkSelected,
	textArtworkLeftSelected,
	textArtworkRightSelected,
	imageSelected,
	editablePortfolioArtworkButtons,
};
