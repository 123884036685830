import { Routes, Route } from 'react-router-dom';

//BL
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';
import PlantillaAll from '../../Pages/sections/plantilla/PlantillaAll';
import TemplateCollectionSelection from '../../Pages/sections/plantilla/TemplateCollectionSelection';
import TemplateCollectionPage from '../../Pages/sections/plantilla/TemplateCollectionPage';
import TemplatesExpert from '../../Pages/sections/plantilla/TemplatesExpert';
import TemplateConfigurations from '../../Pages/sections/plantilla/TemplateConfigurations';

/**
 * @description This router manages the routes for the management of the plantilla.
 * It allows to select plantillas and view specific
 * @returns
 */

export default function PlantillaRouter() {
	return (
		<Routes>
			<Route
				exact
				path='/'
				element={
					<PrivateRoute>
						<PlantillaAll />
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path='/colecciones/:portfolio_type'
				element={
					<PrivateRoute>
						<TemplateCollectionSelection />
					</PrivateRoute>
				}
			/>

			<Route
				exact
				path='/configuraciones'
				element={
					<PrivateRoute>
						<TemplateConfigurations />
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path='/experto'
				element={
					<PrivateRoute>
						<TemplatesExpert />
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path='/portafolio_basico'
				element={
					<PrivateRoute>
						<TemplateCollectionPage />
					</PrivateRoute>
				}
			/>
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
}
