const getBrowserInformation = async () => {
	const isWindowAvailable = typeof window !== 'undefined' && window.document;

	const persistRoot =
		isWindowAvailable &&
		window.localStorage &&
		(await JSON.parse(localStorage.getItem('persist:root')));

	const appUser =
		persistRoot && (await JSON.parse(persistRoot.appUser).appUser);
	let currentDate = new Date(Date.now());

	const userAgent = isWindowAvailable && window.navigator.userAgent;

	const browserInfo = userAgent.match(
		/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
	);
	const browser = browserInfo ? browserInfo[1] : 'Unknown';
	const version = browserInfo ? browserInfo[2] : 'Unknown';

	const currentLocation = isWindowAvailable && window.location;

	const osInfo = userAgent.match(/(win|mac|linux|android|iphone|ipad|ipod)/i);
	const os = osInfo ? osInfo[1] : 'Unknown';

	const networkConnection = isWindowAvailable && window.navigator.connection;

	const connectionType = networkConnection
		? networkConnection.effectiveType
		: 'Unknown';
	const rtt = networkConnection ? networkConnection.rtt : 'N/A';
	const downlink = networkConnection ? networkConnection.downlink : 'N/A';

	return {
		appUser,
		currentDate,
		currentLocation,
		os,
		connectionType,
		rtt,
		downlink,
		browser,
		version,
	};
};

export const submitErrorToSlack = async (endpoint, error, fetchType) => {
	const {
		appUser,
		currentDate,
		currentLocation,
		os,
		connectionType,
		rtt,
		downlink,
		browser,
		version,
	} = await getBrowserInformation();

	let message = `
	🚧 ${currentDate.toString()}
	${appUser ? `- App user: ${appUser?.name} - email: (${appUser?.email})` : ''}
	- App user: #${appUser?.app_user_id} - (${appUser?.email})
	- Endpoint: ${fetchType} : ${endpoint}
	- Error: ${error.message}
	- URL: ${currentLocation}
	- OS: ${os}
	- Network Connection: ${connectionType}
	- Round-Trip Time: ${rtt} ms
  	- Downlink Speed: ${downlink} Mbps
	- Browser: ${browser}  ${version}
	- Error in fetch
	`;

	let url =
		'https://hooks.slack.com/services/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';
	if (process.env.NODE_ENV === 'production') {
		await fetch(url, {
			method: 'POST',
			mode: 'no-cors',
			body: JSON.stringify({ text: message }),
			headers: {
				content_type: 'application/json',
			},
		});
	}
};

export const alertRenderErrorToSlack = async (error, userWithError) => {
	const {
		currentDate,
		currentLocation,
		os,
		connectionType,
		rtt,
		downlink,
		browser,
		version,
	} = await getBrowserInformation();

	let message = `
	🚨 ${currentDate.toString()}
	- App user: #${userWithError?.app_user_id} - (${userWithError?.email})
	- URL: ${currentLocation}
	- Error: ${error.stack.split('(http')[0]}
	- OS: ${os}
	- Network Connection: ${connectionType}
	- Round-Trip Time: ${rtt} ms
  	- Downlink Speed: ${downlink} Mbps
	- Browser: ${browser}  ${version}
	- App Crashed! 
	`;

	let url =
		'https://hooks.slack.com/services/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';
	if (process.env.NODE_ENV === 'production') {
		await fetch(url, {
			method: 'POST',
			mode: 'no-cors',
			body: JSON.stringify({ text: message }),
			headers: {
				content_type: 'application/json',
			},
		});
	}
};
