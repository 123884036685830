// 3rd Party
import { View, StyleSheet } from '@react-pdf/renderer';

// UI
import DownloadableExpertSlideItemTitle from '../../../../molecules/Portfolio/ExpertSlideItems/DownloadableExpertSlideItems/DownloadableExpertSlideItemTitle';
import DownloadableExpertSlideItemText from '../../../../molecules/Portfolio/ExpertSlideItems/DownloadableExpertSlideItems/DownloadableExpertSlideItemText';

const styles = StyleSheet.create({
	container: {},
});

export default function DownloadableExpertSlideTextBlock({ slideItems }) {
	const titleElements = slideItems?.find(
		(item) => item.type === 'title'
	)?.slideItemElements;

	const textContent = slideItems
		.find((item) => item.type === 'text')
		?.slideItemElements?.find((element) => element.type === 'text');

	return (
		<View style={styles.container}>
			<DownloadableExpertSlideItemTitle
				titleElements={titleElements}
				stylesTitle={{ marginBottom: '20px' }}
			/>
			<DownloadableExpertSlideItemText text={textContent?.content} />
		</View>
	);
}
