import { useRef } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
//BL
import store from '../../../BusinessLogic/redux/store/store';
import { useHideTawkChat } from '../../../BusinessLogic/hooks/useHideTawkChat';

const persistor = persistStore(store);
const queryClient = new QueryClient();

const MainProvider = ({ children }) => {
	const tawkMessengerRef = useRef();
	const isChatVisible = useHideTawkChat();
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<QueryClientProvider client={queryClient}>
					{children}
					{isChatVisible && (
						<TawkMessengerReact
							propertyId='636b6df8daff0e1306d680cf'
							widgetId='1ghdqb52u'
							ref={tawkMessengerRef}
						/>
					)}
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</PersistGate>
		</Provider>
	);
};

export default MainProvider;
