const lng =
	window.localStorage.getItem('lng') &&
	window.localStorage.getItem('lng').slice(0, 2);

const titleTextSelectedLanguage = () => {
	switch (lng) {
		case 'es':
			return 'Agregar un título';
		// case 'en':
		// 	return 'Add a title';
		// case 'fr':
		// 	return 'Add a title';
		default:
			return 'Agregar un título';
	}
};

const titleTextSelected = {
	content: '',
	maxLength: '50',
	fontSize: 5,
	fontWeight: 'font-bold',
	fontColor: 'text-grey-50',
	position: { left: 3.3, top: 8.1 },
	positionDraggable: { x: 20, y: 50 },
	elementText: 'title',
	placeholder: titleTextSelectedLanguage(),
};

const subtitleTextSelectedLanguage = () => {
	switch (lng) {
		case 'es':
			return 'Agregar un subtítulo';
		// case 'en':
		// 	return 'Add a subtitle';
		// case 'fr':
		// 	return 'Add a subtitle';
		default:
			return 'Agregar un subtítulo';
	}
};
const subtitleTextSelected = {
	placeholder: subtitleTextSelectedLanguage(),
	content: '',
	maxLength: '50',
	fontWeight: 'font-light',
	fontSize: 3.5,
	fontColor: 'text-grey-50',
	elementText: 'subtitle',
	position: { left: 3.3, top: 16.3 },
	positionDraggable: { x: 20, y: 100 },
};

const textSimpleSelectedLanguage = () => {
	switch (lng) {
		case 'es':
			return 'Añadir un párrafo';
		// case 'en':
		// 	return 'Add a paragraph';
		// case 'fr':
		// 	return 'Add a paragraph';
		default:
			return 'Añadir un párrafo';
	}
};

const textSimpleSelected = {
	placeholder: textSimpleSelectedLanguage(),
	content: '',
	maxLength: '50',
	fontWeight: 'font-light',
	fontSize: 2.5,
	fontColor: 'text-grey-50',
	typeInput: 'textarea',
	elementText: 'simpleText',
	position: { left: 3.3, top: 24.4 },
	positionDraggable: { x: 20, y: 150 },
};

export { titleTextSelected, subtitleTextSelected, textSimpleSelected };
