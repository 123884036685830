import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

// BL
import { recoveryPasswordByEmail } from '../../../BusinessLogic/redux/reducers/appUser';
import { passwordRecoverySchema } from '../../../BusinessLogic/data/PasswordRecoveryData';
// UI
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import ModalWithTextAndLink from '../../templates/ModalWithTextAndLink';
import SpinnerWhite from '../../assets/icons/loadingSpinnerSmallWhite.svg';

const PasswordRecoveryForm = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { status } = useSelector((state) => state.appUser);
	const [isTheEmailSentSuccessfully, setIsTheEmailSentSuccessfully] =
		useState(false);
	const [showModalWithTextAndLink, setShowModalWithTextAndLink] =
		useState(false);

	const [isTheEmailAndTokenAlreadySent, setIsTheEmailAndTokenAlreadySent] =
		useState(false);

	const handleSubmit = (values, formOptions) => {
		dispatch(recoveryPasswordByEmail(values)).then((res) => {
			let response = res.payload;

			let isUserNotExist = res?.payload?.response?.status === 404;
			if (isUserNotExist) {
				formOptions.setFieldError('email', 'EmailValidación');
			} else {
				if (response?.status) {
					if (
						response?.status === 'valid_token' ||
						response?.status === 'used_token'
					) {
						setShowModalWithTextAndLink(true);
						setIsTheEmailAndTokenAlreadySent(true);
					}
					if (
						response?.status === 'email_sent' ||
						response?.status === 'expired_token'
					) {
						setIsTheEmailSentSuccessfully(true);
						setShowModalWithTextAndLink(true);
					}
				}
			}
		});
	};
	return (
		<div className='py-8'>
			<TextTitleSub
				title={t('OlvidasteTuContraseña')}
				design={'w-full text-center text-green-50'}
			/>
			<p className='w-full text-center text-[16px] font-light leading-7 text-green-50'>
				{t('IngresaTuEmailYTeAyudaremos')}
			</p>
			{isTheEmailSentSuccessfully ? (
				<>
					<p className='body-Text2 px-[10%] py-6 text-center md:px-[20%]'>
						{t('HemosEnviadoLasInstrucciones')}{' '}
						<span className='font-semibold'>{t('correoElectrónico')}</span>
					</p>
					<div className='flex flex-col gap-2 px-[10%] py-8 md:px-[20%]'>
						<h3 className='text-[14px] font-normal leading-[18px]'>
							{t('NecesitasAyuda')}
						</h3>
						<h3 className='text-[14px] font-normal leading-[18px] text-green-50 underline'>
							<a href='mailto: contacto@datarte.art'>contacto@datarte.art</a>
						</h3>
					</div>
				</>
			) : (
				<Formik
					enableReinitialize
					validationSchema={passwordRecoverySchema}
					initialValues={{ email: '' }}
					onSubmit={(values, formOptions) => {
						handleSubmit(values, formOptions);
					}}
				>
					<Form>
						<div className='mx-[5%] flex justify-around py-8 md:mx-[15%] '>
							<p className='body-Text2'>{t('Email')}</p>
							<div className='flex w-1/2  flex-col'>
								<Field
									className='body-Text2  h-6 rounded border-none bg-light_grey-75'
									type='email'
									name='email'
								/>
								<ErrorMessage
									name='email'
									render={(msg) => (
										<div className='body-Text3 text-red-400'>{t(msg)}</div>
									)}
								/>
							</div>
						</div>
						<div className='mt-8 flex justify-center'>
							<ButtonFab
								disabled={status === 'loading' ? true : false}
								icon={status === 'loading' ? SpinnerWhite : ''}
								label={t('Solicitar')}
								type='submit'
								size={'large'}
							/>
						</div>
					</Form>
				</Formik>
			)}
			{isTheEmailAndTokenAlreadySent && showModalWithTextAndLink && (
				<ModalWithTextAndLink
					thereIsALink={false}
					thereIsAQuestionAndEmail={true}
					setShowModalWithTextAndLink={setShowModalWithTextAndLink}
					text={'EsteCorreoElectrónicoYaTieneUnaSolicitud'}
				/>
			)}
		</div>
	);
};

export default PasswordRecoveryForm;
