import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//BL
import {
	clearEditablePortfolioSlideContent,
	createPortfolioSlide,
	deleteSlide,
	getPortfolioToEdit,
	setIdOfPortfolioSlideToEdit,
	updateSlideElement,
} from '../../../BusinessLogic/redux/reducers/editablePortfolio';
// UI
import { ReactComponent as Cross } from '../../assets/icons/Cross.svg';
//import { ReactComponent as CopyGrey } from '../../assets/icons/CopyGrey.svg';
import { ReactComponent as CrossWithCircle } from '../../assets/icons/CrossGreyWhiteCircle.svg';
import { ReactComponent as DeleteCanGrey } from '../../assets/icons/DeleteCanGrey.svg';
//import { ReactComponent as FilePlusGrey } from '../../assets/icons/FilePlusGrey.svg';
import { ReactComponent as PageGrey } from '../../../UI/assets/icons/PageGrey.svg';

const PageSelection = ({ isMobile, setShowSpecificView, portfolioData }) => {
	const dispatch = useDispatch();

	const { idOfPortfolioSlideToEdit, singleEditablePortfolio } = useSelector(
		(state) => state.editablePortfolio
	);

	const [selectedSlideId, setSelectedSlideId] = useState(
		idOfPortfolioSlideToEdit
	);

	const handleUpdateEditablePortfolioSlide = (id) => {
		const slides = singleEditablePortfolio?.slides;
		const filterSlideToEdit = slides?.filter((slide) => slide.slide_id === id);
		dispatch(updateSlideElement(filterSlideToEdit[0].content));
	};

	const numberOfSlides = portfolioData?.slides?.length;

	const createNewSlide = async () => {
		const values = {
			portfolio_id: portfolioData.id,
			content: [],
			order: '',
		};
		await dispatch(createPortfolioSlide(values))
			.then(async (res) => {
				const newId = res.payload.id;
				await dispatch(getPortfolioToEdit(portfolioData.id));
				dispatch(clearEditablePortfolioSlideContent());
				dispatch(setIdOfPortfolioSlideToEdit(newId));
				setShowSpecificView('canvasEdition');
			})
			.catch((error) => console.error(error));
	};
	const deleteSelectedSlide = async (selectedSlideId) => {
		await dispatch(deleteSlide(selectedSlideId))
			.then(() => dispatch(getPortfolioToEdit(portfolioData.id)))
			.then(async (res) => {
				const firstSlideOfPortfolio = res.payload.slides[0].slide_id;
				dispatch(setIdOfPortfolioSlideToEdit(firstSlideOfPortfolio));
				handleUpdateEditablePortfolioSlide(firstSlideOfPortfolio);
			})
			.catch((error) => console.error(error));
	};

	return (
		<>
			{!isMobile && (
				<div className='relative top-0 left-0 flex w-full flex-row justify-end gap-4 bg-white p-1'>
					<div className='w-[80%]'></div>
					<div>
						{/* TO FIX: When the features are defined, uncomment them. 
					<FilePlusGrey className=' h-8 w-8 mx-auto hover:scale-105 transition-transform cursor-pointer' />
					<CopyGrey className=' h-8 w-8 mx-auto hover:scale-105 transition-transform cursor-pointer' /> */}
						<DeleteCanGrey
							onClick={() => deleteSelectedSlide(selectedSlideId)}
							className=' mx-auto h-8 w-8 cursor-pointer transition-transform hover:scale-105'
						/>
					</div>
				</div>
			)}
			<div className='mx-auto mt-12 w-[90%]'>
				<div className='grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
					{portfolioData?.slides?.map((slide, index) => (
						<div
							key={index}
							className='flex cursor-pointer flex-col gap-2 text-center'
							onClick={() => {
								setSelectedSlideId(slide.slide_id);
								dispatch(setIdOfPortfolioSlideToEdit(slide.slide_id));
								handleUpdateEditablePortfolioSlide(slide.slide_id);
							}}
						>
							<div
								className={`relative ${
									selectedSlideId === slide.slide_id && 'bg-green-50'
								} mx-auto h-32 w-32 rounded-lg transition-transform duration-500 hover:scale-105 sm:h-40 sm:w-40`}
							>
								<div className='absolute top-2 right-2 h-28 w-28 rounded-lg bg-white shadow-xl sm:h-36 sm:w-36'></div>
							</div>
							<p>Página {index + 1}</p>
						</div>
					))}
					{/* <div
						className='flex flex-col text-center gap-2 cursor-pointer'
						onClick={() => setCurrentSlideToEdit(2)}
					>
						<div className='relative rounded-lg w-32 h-32 sm:w-40 sm:h-40 mx-auto hover:scale-105 transition-transform duration-500'>
							<div className='absolute bg-white rounded-lg w-28 h-28 sm:w-36 sm:h-36 shadow-xl top-2 right-2'></div>
						</div>
						<p>Página {2}</p>
					</div> */}

					<div
						className='relative mx-auto flex h-32 w-32  cursor-pointer items-center justify-center rounded-lg bg-light_grey-100 shadow-xl transition-transform duration-500 hover:scale-105 sm:h-40 sm:w-40'
						onClick={() => createNewSlide()}
					>
						<Cross className='h-8 w-8 rotate-45' />
					</div>
				</div>

				<div className='fixed left-0 bottom-0 w-full'>
					<div className='flex w-full flex-row justify-evenly gap-4 bg-middle_grey-50 sm:gap-8 lg:justify-end lg:bg-white '>
						{isMobile ? (
							<>
								<div className='m-3 flex border-r-2 border-white pr-6 '>
									<CrossWithCircle
										className=' my-auto h-10 w-10 transition-transform hover:scale-105'
										onClick={() => setShowSpecificView('canvasEdition')}
									/>
								</div>

								<div className='m-3 flex cursor-pointer flex-col transition-transform hover:scale-105'>
									<DeleteCanGrey
										className=' mx-auto h-8 w-8 '
										onClick={() => deleteSelectedSlide(selectedSlideId)}
									/>
									<p className=' text-xs font-light text-grey-50'>Eliminar</p>
								</div>
								{/* TO FIX: When the features are defined, uncomment them. 
								<div className='flex flex-col m-3 hover:scale-105 transition-transform'>
									<CopyGrey className=' h-8 w-8 mx-auto ' />
									<p className=' text-xs  text-grey-50 font-light'>Duplicar</p>
								</div> 
								<div className='flex flex-col m-3 hover:scale-105 transition-transform'>
									<FilePlusGrey className=' h-8 w-8 mx-auto ' />
									<p className=' text-xs  text-grey-50 font-light'>Agregar</p>
								</div>
								*/}
							</>
						) : (
							<div
								className=' relative m-1 flex cursor-pointer flex-col rounded-md bg-middle_grey-50 p-1 transition-transform hover:scale-105'
								onClick={() => setShowSpecificView('canvasEdition')}
							>
								<PageGrey className='mx-auto h-8 w-8' />
								<div className='absolute top-[14px] left-[25%] h-4 w-4 text-center'>
									<p className=' text-xs text-grey-50'>{numberOfSlides}</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default PageSelection;
