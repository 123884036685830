// 3rd Party
import PropTypes from 'prop-types';

export default function ExpertSlideElementTitle({ title, designTitle }) {
	return <h1 className={`text-center ${designTitle}`}>{title}</h1>;
}

ExpertSlideElementTitle.propTypes = {
	title: PropTypes.string,
	designTitle: PropTypes.string,
};
