export const NAVBAR_FR = {
	Catalogación: 'Catalogage',
	Difusión: 'Diffusion',
	Convocatoria: 'Appeler',
	Perfil: 'Profil',
	Artistas: 'Artistes',
	Obras: 'Usine',
	Documentos: 'Documents',
	Lugares: 'Emplacements',
	Contactos: 'Contacts',
	Exposiciones: 'Exposition',
	Colecciones: 'Collections',
	Portafolios: 'Portefeuille',
	ConvocatoriasAbiertas: ' Appels',
	MisPostulaciones: 'My applications',
	Postulaciones: 'Applications',
	Catalogo: 'Catalogue',
	TuPlanEstaActivo: 'Your plan is active',
	TuPlanSeHaActivadoExitosamente:
		'You can now start enjoying the Datarte web app and access all the additional benefits.',
	hazteMiembro: 'Become a member!',
};
