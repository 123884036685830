/**
 * @description create a data structure like userProfileTabs for the role tab .
 * @param {Array} data array of data that contains all the user that are working in the same app user
 * @returns
 */
function roleUserDataBuilder(data) {
	const roleUserDataTemplate = data.map((item) => ({
		first_letter: item.first_letter,
		type: 'roles_assigned',
		organization_user_id: item.organization_user_id,
		user_account_id: item.user_account_id,
		userData: [
			{
				label: 'Usuario',
				value: item.organization_name,
				value_name: item.organization_name,
				field: 'organization_name',
			},
			{
				label: 'Rol',
				value: String(item.role_id),
				value_name: item.role_name,
				field: 'role_id',
			},
		],
	}));
	return roleUserDataTemplate;
}

export default roleUserDataBuilder;
