// 3rd Party
import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';

// BL
import { putElementOnBlur } from '../../../../BusinessLogic/helpers/expertPortfolioHelpers';
import {
	setCurrentViewExpertPortfolioAndSelection,
	setShowSlideModalExpertPortfolio,
} from '../../../../BusinessLogic/redux/reducers/portfolio';

// UI
import EditExpertInputText from '../../../atoms/Portfolio/ExpertElements/EditExpertInputText';
import ExpertSlideItemText from '../ExpertSlideItems/ExpertSlideItemText';

const EditExpertArtwork = ({ artworkItemData, isReordering }) => {
	const dispatch = useDispatch();

	const { expertPortfolioViewAndSelection, singlePortfolio } = useSelector(
		(state) => state.portfolios
	);

	const showTitle = singlePortfolio?.show_title;
	const showDate = singlePortfolio?.show_date;
	const showTechnique = singlePortfolio?.show_technique;
	const showMeasures = singlePortfolio?.show_measures;
	const showPrices = singlePortfolio?.show_prices;

	const currentViewExpertPortfolio =
		expertPortfolioViewAndSelection.currentView;

	const artworkTitleElement = artworkItemData?.slideItemElements?.find(
		(element) => element.type === 'title'
	);
	const artworkDateElement = artworkItemData?.slideItemElements?.find(
		(element) => element.type === 'date'
	);
	const artworkPriceElement = artworkItemData?.slideItemElements?.find(
		(element) => element.type === 'price'
	);
	const artworkMeasuresElement = artworkItemData?.slideItemElements?.find(
		(element) => element.type === 'measures'
	);
	const artworkTechniqueElement = artworkItemData?.slideItemElements?.find(
		(element) => element.type === 'technique'
	);
	const imageElement = artworkItemData?.slideItemElements?.find(
		(element) => element.type === 'image_url'
	);
	const currentYear = new Date().getFullYear();

	const imageUrl = imageElement?.content;

	const hasVideoUrl = artworkItemData?.video_url;

	const smallImageUrl = imageUrl && imageUrl + '_small';
	const mediumImageUrl = imageUrl && imageUrl + '_medium';

	const isVideoArtwork =
		artworkItemData?.object_type === 'VIDEO' ||
		artworkItemData?.object_type === 'PERFORMANCE' ||
		artworkItemData?.object_type === 'ANIMATION';

	const [artworkContent, setArtworkContent] = useState({
		title: artworkTitleElement?.content,
		date: artworkDateElement?.content,
		technique: artworkTechniqueElement?.content,
		measures: artworkMeasuresElement?.content,
		price: artworkPriceElement?.content,
	});
	useEffect(() => {
		setArtworkContent({
			title: artworkTitleElement?.content,
			date: artworkDateElement?.content,
			technique: artworkTechniqueElement?.content,
			measures: artworkMeasuresElement?.content,
			price: artworkPriceElement?.content,
		});
		//eslint-disable-next-line
	}, [artworkItemData]);

	const handleClickArtwork = (event) => {
		if (isReordering) return;
		event.stopPropagation();
		dispatch(
			setShowSlideModalExpertPortfolio({
				isVisible: false,
				modalView: '',
			})
		);
		const selectedIsSameId =
			currentViewExpertPortfolio === 'artworkSelectionView' &&
			imageElement.slideItemElementId ===
				expertPortfolioViewAndSelection.selectedElementId;
		const selectedIsNotSameId =
			currentViewExpertPortfolio === 'artworkSelectionView' &&
			imageElement.slideItemElementId !==
				expertPortfolioViewAndSelection.selectedElementId;
		const thereIsNoSelectedArtwork =
			currentViewExpertPortfolio !== 'artworkSelectionView';

		if (selectedIsSameId) {
			dispatch(
				setCurrentViewExpertPortfolioAndSelection({
					currentView: 'editionView',
					selectedElementId: null,
					selectedItemId: null,
					selectedSlideId: null,
					artworkId: null,
				})
			);
		} else if (selectedIsNotSameId || thereIsNoSelectedArtwork) {
			dispatch(
				setCurrentViewExpertPortfolioAndSelection({
					selectedElementId: imageElement?.slideItemElementId,
					currentView: 'artworkSelectionView',
					selectedItemId: artworkItemData.slideItemId,
					selectedSlideId: artworkItemData.slideId,
					artworkId: artworkItemData.artworkId,
				})
			);
		}
	};

	return (
		<div
			className={`flex w-full flex-col items-start md:w-full lg:w-[65%] ${
				isReordering && 'animate-shake border-2 border-dashed border-grey-50'
			}`}
			onClick={(e) => isReordering && e.preventDefault()}
		>
			<div
				className={`h-full w-full cursor-pointer overflow-hidden ${
					imageElement?.slideItemElementId ===
						expertPortfolioViewAndSelection.selectedElementId &&
					'border-4 border-yellow-50'
				}`}
				onClick={handleClickArtwork}
			>
				{hasVideoUrl ? (
					<ReactPlayer
						url={hasVideoUrl}
						width={'100%'}
						height={'100%'}
						controls={false}
						light={true}
						className='pointer-events-none aspect-square'
					/>
				) : (
					<LazyLoadImage
						effect='blur'
						src={mediumImageUrl}
						placeholderSrc={smallImageUrl}
						className={`w-full`}
					/>
				)}
			</div>

			<div className='mt-4 w-full'>
				<div className='text-black/50p-1 flex flex-col rounded-lg bg-zinc-50/50 text-sm'>
					{showTitle && (
						<div className='flex gap-1 font-louisGeorgeCafe'>
							<EditExpertInputText
								inputDesign={
									'w-full text-base font-bold uppercase text-start md:text-lg'
								}
								placeholderText={'Título'}
								inputValue={artworkContent.title}
								onChangeAction={(e) =>
									setArtworkContent({
										...artworkContent,
										title: e.target.value,
									})
								}
								onBlurAction={(e) =>
									artworkTitleElement &&
									putElementOnBlur(
										dispatch,
										e.target.value,
										artworkTitleElement.slideItemElementId,
										artworkTitleElement.content
									)
								}
							/>
						</div>
					)}
					{showDate && (
						<div className='flex gap-1'>
							<EditExpertInputText
								inputDesign={
									'w-full text-sm md:text-base text-gray-700 text-start'
								}
								placeholderText={currentYear}
								inputValue={artworkContent.date}
								onChangeAction={(e) => {
									if (e.target.value <= currentYear) {
										setArtworkContent({
											...artworkContent,
											date: e.target.value,
										});
									}
								}}
								inputType='number'
								onBlurAction={(e) =>
									artworkDateElement &&
									putElementOnBlur(
										dispatch,
										e.target.value,
										artworkDateElement.slideItemElementId,
										artworkDateElement.content
									)
								}
							/>
						</div>
					)}
					{showMeasures && !isVideoArtwork && (
						<div className='flex gap-1'>
							<EditExpertInputText
								inputDesign={
									'w-full text-sm md:text-base text-gray-700 text-start'
								}
								placeholderText={'0x0x0cm'}
								inputValue={artworkContent.measures}
								onChangeAction={(e) =>
									setArtworkContent({
										...artworkContent,
										measures: e.target.value,
									})
								}
								onBlurAction={(e) =>
									artworkMeasuresElement &&
									putElementOnBlur(
										dispatch,
										e.target.value,
										artworkMeasuresElement.slideItemElementId,
										artworkMeasuresElement.content
									)
								}
							/>
						</div>
					)}
					{showTechnique && !isVideoArtwork && (
						<div className='flex gap-1'>
							<EditExpertInputText
								inputDesign={
									'w-full text-sm md:text-base text-gray-700 text-start'
								}
								inputValue={artworkContent.technique}
								placeholderText={'Técnica'}
								onChangeAction={(e) =>
									setArtworkContent({
										...artworkContent,
										technique: e.target.value,
									})
								}
								onBlurAction={(e) =>
									artworkTechniqueElement &&
									putElementOnBlur(
										dispatch,
										e.target.value,
										artworkTechniqueElement.slideItemElementId,
										artworkTechniqueElement.content
									)
								}
							/>
						</div>
					)}
					{showPrices && !isVideoArtwork && (
						<div className='flex gap-1'>
							<EditExpertInputText
								inputDesign={
									'w-full text-sm md:text-base text-gray-700 text-start'
								}
								placeholderText={'Precio'}
								inputValue={artworkContent.price}
								onChangeAction={(e) =>
									setArtworkContent({
										...artworkContent,
										price: e.target.value,
									})
								}
								onBlurAction={(e) =>
									artworkPriceElement &&
									putElementOnBlur(
										dispatch,
										e.target.value,
										artworkPriceElement.slideItemElementId,
										artworkPriceElement.content
									)
								}
							/>
						</div>
					)}
					{isVideoArtwork && artworkItemData?.video_url && (
						<ExpertSlideItemText
							text={artworkItemData?.video_url}
							designContainer={`flex mb-0 text-sm break-all bg-white`}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default EditExpertArtwork;
