// 3rd party
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

//UI
import { ReactComponent as DA } from '../../../../UI/assets/icons/DA.svg';

/**
 *
 * @description Logo that when clicked goes to the home /
 * @returns
 */

export default function ButtonLinkMainLogo({ className, design, action }) {
	const navigate = useNavigate();

	return (
		<div className={className} onClick={action}>
			<figure
				className='h-8 w-8'
				onClick={() => {
					navigate('/');
				}}
			>
				<DA fill='none' stroke={design} />
			</figure>
		</div>
	);
}

ButtonLinkMainLogo.propTypes = {
	/** Tailwind main container classname */
	className: PropTypes.string,
	/** Stroke attribute */
	design: PropTypes.string,
	/* received function to do something, like a close a menu */
	action: PropTypes.func,
};
