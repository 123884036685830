//3rd party
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

//BL
import { getLocationsGeneral } from '../../../../BusinessLogic/redux/reducers/locationGeneral';

//UI
import FilterLocationGeneral from './FilterLocationGeneral';
import FilterButton from '../../../../UI/atoms/Buttons/Link/FilterButton';
import TextMainTitle from '../../../../UI/molecules/Text/TextMainTitle';
import ButtonChangeView from '../../../../UI/molecules/Buttons/ButtonChangeView';
import SecondaryNavbar from '../../../../UI/organisms/Navbar/NavbarSecondary';
import ImagesSectionWithFilter from '../../../../UI/organisms/Image/ImageSectionWithFilter';
import ButtonFabPrincipalUrl from '../../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import ViewAllSkeleton from '../../../../UI/templates/SkeletonTemplates/ViewAllSkeleton';
import TemplateWithNavBar from '../../../../UI/templates/TemplateWithNavBar';

export default function AllLocationsGeneral() {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { value } = useSelector((state) => state.artistFilter);
	const { locationsGeneral, status } = useSelector(
		(state) => state.locationsGeneral
	);

	const [changeView, setChangeView] = useState(false);
	const [filterArray, setFilterArray] = useState({});
	const [filterChange, setFilterChange] = useState(0);
	const [nameFilter, setNameFilter] = useState(value.payload);

	useEffect(() => {
		if (!locationsGeneral) {
			dispatch(getLocationsGeneral());
		}
		//eslint-disable-next-line
	}, []);

	return (
		<div>
			{!locationsGeneral && status === 'loading' && (
				<ViewAllSkeleton
					numberOfImages={locationsGeneral?.length}
					designSkeletonTitle4={'invisible'}
					designSkeletonImages={
						'w-full px-4 grid grid-cols-3 sm:grid-cols-5 md:grid-cols-7 2xl:grid-cols-9 gap-x-2 gap-y-0 justify-center'
					}
				/>
			)}
			{locationsGeneral && (
				<TemplateWithNavBar>
					<SecondaryNavbar
						title={t('Lugares')}
						textColor='text-purple-50'
						color='bg-purple-50'
						redirect='home'
						hidden='invisible'
						changeView={changeView}
						setChangeView={setChangeView}
						Filter={FilterLocationGeneral}
						data={locationsGeneral}
						filterArray={filterArray}
						setFilterArray={setFilterArray}
						filterChange={filterChange}
						setFilterChange={setFilterChange}
						nameFilter={nameFilter}
						setNameFilter={setNameFilter}
					/>
					<div className='flex w-full flex-col items-center justify-between px-4 xs:mb-5 xs:h-12 xs:flex-row md:hidden'>
						<div className='invisible w-16'></div>
						<TextMainTitle
							title={t('Lugares')}
							textColor='text-purple-50'
							color='bg-purple-50'
						/>
						<div className='my-3 flex w-full flex-row justify-between xs:my-0 xs:w-auto'>
							<ButtonChangeView
								changeView={changeView}
								setChangeView={setChangeView}
							/>
							<FilterButton
								Filter={FilterLocationGeneral}
								data={locationsGeneral}
								filterArray={filterArray}
								setFilterArray={setFilterArray}
								filterChange={filterChange}
								setFilterChange={setFilterChange}
								nameFilter={nameFilter}
								setNameFilter={setNameFilter}
							/>
						</div>
					</div>
					<ImagesSectionWithFilter
						data={locationsGeneral}
						redirect='lugares'
						filterChange={filterChange}
						filterArray={filterArray}
						nameFilter={nameFilter}
						changeView={changeView}
						newEntityLink='/lugares/new'
					/>
					<ButtonFabPrincipalUrl label={t('Crear')} url='/lugares/new' />
				</TemplateWithNavBar>
			)}
		</div>
	);
}
