// 3rd Party
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

// BL
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';

// UI
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';
import AllArtists from '../../Pages/sections/artist/AllArtists';
import ArtistForm from '../../Pages/sections/artist/ArtistForm';
import ArtistSingle from '../../Pages/sections/artist/ArtistSingle';

export const ArtistRouter = () => {
	const { appUser } = useSelector((state) => state.appUser);

	return (
		<Routes>
			{appUser.role_company === 'GALLERY' && (
				<>
					<Route
						path='/'
						element={
							<PrivateRoute>
								<AllArtists />
							</PrivateRoute>
						}
					/>
					<Route
						path='/crear'
						element={
							<PrivateRoute>
								<ArtistForm />
							</PrivateRoute>
						}
					/>
				</>
			)}
			{appUser.role_company !== 'ORGANIZATION' && (
				<Route
					path='/:id'
					element={
						<PrivateRoute>
							<ArtistSingle />
						</PrivateRoute>
					}
				/>
			)}
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default ArtistRouter;
