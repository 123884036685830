//3rd party
import PropTypes from 'prop-types';

//UI
import { ReactComponent as CheckCircleIconGreen } from '../../assets/icons/checkCircleGreen.svg';
import { ReactComponent as CheckCircleIconWhite } from '../../assets/icons/checkCircleWhite.svg';

/**
 * @description Render a selectable option (artist and gallery)
 * @param {*} param0
 * @returns
 */

export default function SignupChooseTypeProfile({
	roleCompany,
	title,
	text,
	isSelected,
	updateRoleCompany,
}) {
	return (
		<div
			onClick={() => updateRoleCompany(roleCompany)}
			className={`my-5 cursor-pointer rounded-md border border-green-50
								 p-2 py-3 px-5 ${isSelected ? 'bg-green-50 text-white' : 'text-green-50'}`}
		>
			<div className='flex flex-row items-center justify-between pb-3'>
				<div className='text-xl font-bold'>{title}</div>
				{isSelected ? <CheckCircleIconWhite /> : <CheckCircleIconGreen />}
			</div>
			<div className='py-3 text-justify text-sm font-light'>{text}</div>
		</div>
	);
}

SignupChooseTypeProfile.propTypes = {
	roleCompany: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	isSelected: PropTypes.bool,
	updateRoleCompany: PropTypes.func,
};

SignupChooseTypeProfile.defaultProps = {
	roleCompany: '',
	title: '',
	text: '',
	isSelected: false,
	updateRoleCompany: () => {},
};
