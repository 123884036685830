//3rd party
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

// BL
import { getArtistDropdown } from '../../../BusinessLogic/redux/reducers/artist';
import { getArtworkFilterList } from '../../../BusinessLogic/helpers/artworkData';
import { getArtworks } from '../../../BusinessLogic/redux/reducers/artwork';

//UI
import FilterSidebar from '../../../UI/molecules/Filters/FilterSidebar';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';

export default function FilterArtwork({
	valuesFilterArtworks,
	setValuesFilterArtworks,
	valuesFilterInitialValues,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { artistDropdown } = useSelector((state) => state.artists);
	const { artworks } = useSelector((state) => state.artworks);
	const { appUser } = useSelector((state) => state.appUser);

	const allArtistList = artistDropdown?.allArtist;

	const isGalleryUser = appUser.role_company === 'GALLERY';
	const isCatalogueArtistUser = appUser.role_company === 'ARTIST_CATALOGATION';
	const isAppUserNijole = String(appUser.app_user_id) === String(3);

	const materialsList = getArtworkFilterList(artworks ?? [], 'materials');
	const dateList = getArtworkFilterList(artworks ?? [], 'date');
	const codeList = getArtworkFilterList(artworks ?? [], 'code');
	const otherCodeList = getArtworkFilterList(artworks ?? [], 'code_other');
	const locationGeneralList = getArtworkFilterList(
		artworks ?? [],
		'location_general_name'
	);
	const locationRoomList = getArtworkFilterList(
		artworks ?? [],
		'location_room_name'
	);

	const [showFilter, setShowFilter] = useState(false);

	useEffect(() => {
		if (Object.keys(artistDropdown).length === 0) {
			dispatch(getArtistDropdown());
		}
	}, [artistDropdown, dispatch]);

	useEffect(() => {
		if (!artworks) {
			dispatch(getArtworks());
		}
	}, [artworks, dispatch]);

	return (
		<FilterSidebar showFilter={showFilter} setShowFilter={setShowFilter}>
			<Formik
				initialValues={valuesFilterArtworks}
				onSubmit={(values) => {
					setValuesFilterArtworks(values);
					setShowFilter(false);
				}}
				onReset={() => {
					setValuesFilterArtworks(valuesFilterInitialValues);
					setShowFilter(false);
				}}
			>
				{(formProps) => (
					<div className='flex flex-col gap-3 px-3 pt-8'>
						<div className='flex flex-col gap-2'>
							<TextContentSecond text={`${t('Obra')}:`} design='!font-medium' />
							<Field
								type='input'
								name='artwork'
								className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
							/>
						</div>
						{isGalleryUser && (
							<div className='flex flex-col gap-3'>
								<TextContentSecond
									text={`${t('Artista')}:`}
									design='!font-medium'
								/>
								<Field
									as='select'
									name='artist_id'
									className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
								>
									<option value=''>{t('SeleccionaUnaOpción')}</option>
									{Array.isArray(allArtistList) &&
										allArtistList?.map((artistItem, index) => (
											<option key={index} value={artistItem.value}>
												{artistItem?.label}
											</option>
										))}
								</Field>
							</div>
						)}
						<div className='flex flex-col gap-2'>
							<TextContentSecond text={`${t('Año')}:`} design='!font-medium' />
							<Field
								as='select'
								name='date'
								className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
							>
								<option value=''>{t('SeleccionaUnaOpción')}</option>
								{Array.isArray(dateList) &&
									dateList?.map((dateItem, index) => (
										<option key={index} value={dateItem}>
											{dateItem}
										</option>
									))}
							</Field>
						</div>
						<div className='flex flex-col gap-2'>
							<TextContentSecond
								text={`${t('Materiales')}:`}
								design='!font-medium'
							/>
							<Field
								as='select'
								name='materials'
								className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
							>
								<option value=''>{t('SeleccionaUnaOpción')}</option>
								{Array.isArray(materialsList) &&
									materialsList?.map((item, index) => (
										<option key={index} value={item}>
											{item}
										</option>
									))}
							</Field>
						</div>
						{isCatalogueArtistUser && isAppUserNijole && (
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={`${t('Codigo')}:`}
									design='!font-medium'
								/>
								<Field
									as='select'
									name='code'
									className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
								>
									<option value=''>{t('SeleccionaUnaOpción')}</option>
									{Array.isArray(codeList) &&
										codeList?.map((item, index) => (
											<option key={index} value={item}>
												{item}
											</option>
										))}
								</Field>
							</div>
						)}
						{isCatalogueArtistUser && isAppUserNijole && (
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={`${t('OtroCodigo')}:`}
									design='!font-medium'
								/>
								<Field
									as='select'
									name='code_other'
									className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
								>
									<option value=''>{t('SeleccionaUnaOpción')}</option>
									{Array.isArray(otherCodeList) &&
										otherCodeList?.map((item, index) => (
											<option key={index} value={item}>
												{item}
											</option>
										))}
								</Field>
							</div>
						)}
						{isCatalogueArtistUser && isAppUserNijole && (
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={`${t('Lugar')}:`}
									design='!font-medium'
								/>
								<Field
									as='select'
									name='location_general_name'
									className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
								>
									<option value=''>{t('SeleccionaUnaOpción')}</option>
									{Array.isArray(locationGeneralList) &&
										locationGeneralList?.map((item, index) => (
											<option key={index} value={item}>
												{item}
											</option>
										))}
								</Field>
							</div>
						)}
						{isCatalogueArtistUser && isAppUserNijole && (
							<div className='flex flex-col gap-2'>
								<TextContentSecond
									text={`${t('Sala')}:`}
									design='!font-medium'
								/>
								<Field
									as='select'
									name='location_room_name'
									className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
								>
									<option value=''>{t('SeleccionaUnaOpción')}</option>
									{Array.isArray(locationRoomList) &&
										locationRoomList?.map((item, index) => (
											<option key={index} value={item}>
												{item}
											</option>
										))}
								</Field>
							</div>
						)}
						<div className='my-5 flex flex-wrap justify-center gap-5'>
							<ButtonFab
								label={t('LimpiarFiltro')}
								size={'medium'}
								colour='white'
								action={() => formProps.resetForm()}
							/>
							<ButtonFab
								label={t('Aplicar')}
								size={'medium'}
								action={() => formProps.submitForm()}
							/>
						</div>
					</div>
				)}
			</Formik>
		</FilterSidebar>
	);
}
