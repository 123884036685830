// 3rd Party
import PropTypes from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';

// UI
import DownloadableExpertSlideItemArtwork from '../../../../molecules/Portfolio/ExpertSlideItems/DownloadableExpertSlideItems/DownloadableExpertSlideItemArtwork';

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
	artworkCard: {
		width: '42%',
		minHeight: '30vh',
		marginBottom: '25px',
	},
});

export default function DownloadableExpertSlideArtworksGroup({
	artworksGroup,
	artworkFieldsToShow,
}) {
	return (
		<View style={styles.container}>
			{artworksGroup?.map((artwork, index) => (
				<DownloadableExpertSlideItemArtwork
					key={index}
					artworkElements={artwork.slideItemElements}
					stylesContainer={styles.artworkCard}
					artworkFieldsToShow={artworkFieldsToShow}
				/>
			))}
		</View>
	);
}

DownloadableExpertSlideArtworksGroup.propTypes = {
	// contains the data to show
	slideItems: PropTypes.array,
	// values to show/hide the fields in artwork
	artworkFieldsToShow: PropTypes.object,
};
