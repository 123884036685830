import React from 'react';

import TextContentSecondSkeleton from '../../atoms/Skeleton/TextContentSecondSkeleton';

const TextSingleRowNewSkeleton = ({
	designTextSingleRowNewSkeleton,
	designTextContentSecondSkeleton,
}) => {
	return (
		<div
			className={`mb-4 flex flex-row justify-between ${designTextSingleRowNewSkeleton}`}
		>
			<TextContentSecondSkeleton
				designTextContentSecondSkeleton={designTextContentSecondSkeleton}
			/>
			<TextContentSecondSkeleton
				designTextContentSecondSkeleton={designTextContentSecondSkeleton}
			/>
		</div>
	);
};

export default TextSingleRowNewSkeleton;
