import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	routes: [],
	redirectRoute: '/',
	collectionOriginPath: '',
};

export const routesHistorySlice = createSlice({
	name: 'routesHistory',
	initialState,
	reducers: {
		updateRoutesHistory: (state, { payload }) => {
			state.routes = [...state.routes, payload];
		},
		updateRedirectRoute: (state, { payload }) => {
			state.redirectRoute = payload;
		},
		resetRedirectRoute: (state) => {
			state.redirectRoute = '/';
		},
		setCollectionOriginPath: (state, { payload }) => {
			state.collectionOriginPath = payload;
		},
	},
});

export const {
	updateRoutesHistory,
	updateRedirectRoute,
	resetRedirectRoute,
	setCollectionOriginPath,
} = routesHistorySlice.actions;

export default routesHistorySlice.reducer;
