import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

//BL
import {
	ENDPOINTS_ARTWORK,
	ENDPOINTS_COUNTRY,
	ENDPOINTS_ARTIST,
	ENDPOINTS_PERSON,
	ENDPOINTS_CURRENCY,
	ENDPOINTS_MEASURE,
} from '../../helpers/routes';
import { fetchGetData } from '../../hooks/fetchGetData';
import {
	artworkSingleTabs,
	artworkVideoSingleTabs,
	catalogueArtistArtworkSingleTabs,
	artworkSingleTabsArtist,
	artworkVideoSingleTabsArtist,
} from '../../data/ArtworkSingleData';
import {
	updateTabsObject,
	updateDropdowns,
} from '../../helpers/SingleViewHelpers';
import { fetchPutData } from '../../hooks/fetchPutData';
import postDataAndImage from '../../../BusinessLogic/helpers/postDataAndImage';
import { fetchPutArrayImages } from '../../hooks/fetchPutArrayImages';

export const getArtworks = createAsyncThunk(
	'artworks/fetchArtworks',
	async () => {
		const { organization_user_id } = localStorage;
		return await fetchGetData(
			`${ENDPOINTS_ARTWORK.GET_ARTWORKS}${organization_user_id}/`
		);
	}
);

export const getArtwork = createAsyncThunk(
	'artwork/fetchArtwork',
	async (id) => {
		return await fetchGetData(`${ENDPOINTS_ARTWORK.GET_ARTWORK}${id}/`);
	}
);

export const getPublicArtwork = createAsyncThunk(
	'artwork/fetchPublicArtwork',
	async (id) => {
		return await fetchGetData(`${ENDPOINTS_ARTWORK.GET_PUBLIC_ARTWORK}${id}/`);
	}
);

export const putArtwork = createAsyncThunk(
	'artwork/putArtwork',
	async (input) => {
		const { values, id } = input;
		return await fetchPutData(`${ENDPOINTS_ARTWORK.PUT_ARTWORK}${id}/`, values);
	}
);

export const getArtworkDropdown = createAsyncThunk(
	'artwork/getArtworkDropdown',
	async () => {
		const { organization_user_id } = localStorage;
		const { countries } = await fetchGetData(
			ENDPOINTS_COUNTRY.GET_COUNTRIES_WITH_LABELS
		);
		const artists = await fetchGetData(
			`${ENDPOINTS_ARTIST.GET_ARTISTS_WITH_LABELS}${organization_user_id}/`
		);
		const owner_contacts = await fetchGetData(
			`${ENDPOINTS_PERSON.GET_OWNER_CONTACTS_WITH_LABELS}${organization_user_id}/`
		);
		const currencies = await fetchGetData(ENDPOINTS_CURRENCY.GET_CURRENCIES);
		const measures = await fetchGetData(ENDPOINTS_MEASURE.GET_MEASURES);
		const final = {
			countries,
			artists,
			owner_contacts,
			currencies,
			measures,
		};
		return final;
	}
);

export const postArtwork = createAsyncThunk(
	'artwork/postArtwork',
	async (values) => {
		return await postDataAndImage(ENDPOINTS_ARTWORK.POST_ARTWORK, values);
	}
);

export const organizeImageGrid = createAsyncThunk(
	'artists/organizeImageGrid',
	async (values) => {
		return await fetchPutArrayImages(values);
	}
);

const initialStateArtworkTabs = artworkSingleTabs;
const initialStateCatalogueArtistArtworkTabs = catalogueArtistArtworkSingleTabs;

const initialSingleArtwork = {
	artist_name: '',
	description: '',
	date: '',
	location_name: '',
	main_picture_url: '',
	name: '',
	code: '',
	difference: '',
	observations: '',
	dimensions_height: '',
	dimensions_length: '',
	documents: [],
	images: [],
	object_type: '',
};

const initialArtworkSingleTabs = updateTabsObject(
	artworkSingleTabs,
	initialSingleArtwork
);

const initialCatalogueArtistArtworkSingleTabs = updateTabsObject(
	catalogueArtistArtworkSingleTabs,
	initialSingleArtwork
);

const initialState = {
	artworks: null,
	singleArtwork: null,
	status: null,
	artworkSingleTabs: initialArtworkSingleTabs,
	initialStateTabs: initialStateArtworkTabs,
	catalogueArtistArtworkSingleTabs: initialCatalogueArtistArtworkSingleTabs,

	initialCatalogueArtistArtworkStateTabs:
		initialStateCatalogueArtistArtworkTabs,

	artworkDropdown: {},
	artworkVideoSingleTabs,
	artworkSingleTabsArtist,
	artworkVideoSingleTabsArtist,
};

export const artworkSlice = createSlice({
	name: 'artworks',
	initialState,
	reducers: {},

	extraReducers: {
		[getArtworks.pending]: (state) => {
			state.status = 'loading';
		},
		[getArtworks.fulfilled]: (state, { payload }) => {
			state.artworks = payload;
			state.status = 'success';
		},
		[getArtworks.rejected]: (state) => {
			state.status = 'failed';
		},
		[getArtwork.pending]: (state) => {
			state.catalogueArtistArtworkSingleTabs = updateTabsObject(
				state.initialCatalogueArtistArtworkStateTabs,
				initialSingleArtwork
			);
			state.artworkSingleTabs = updateTabsObject(
				state.initialStateTabs,
				initialSingleArtwork
			);
			state.status = 'loading';
		},
		[getArtwork.fulfilled]: (state, { payload }) => {
			let Single = payload.artwork;
			state.singleArtwork = Single;
			state.status = 'success';

			if (Single) {
				state.artworkSingleTabs = updateTabsObject(
					state.artworkSingleTabs,
					Single
				);

				state.artworkVideoSingleTabs = updateTabsObject(
					state.artworkVideoSingleTabs,
					Single
				);

				state.catalogueArtistArtworkSingleTabs = updateTabsObject(
					state.catalogueArtistArtworkSingleTabs,
					Single
				);

				state.artworkVideoSingleTabsArtist = updateTabsObject(
					state.artworkVideoSingleTabsArtist,
					Single
				);

				state.artworkSingleTabsArtist = updateTabsObject(
					state.artworkSingleTabsArtist,
					Single
				);

				state.catalogueArtistArtworkSingleTabs = updateDropdowns(
					state.catalogueArtistArtworkSingleTabs,
					state.artworkDropdown['countries'],
					0,
					0,
					5
				);
				state.catalogueArtistArtworkSingleTabs = updateDropdowns(
					state.catalogueArtistArtworkSingleTabs,
					state.artworkDropdown['artists'],
					0,
					2,
					0
				);
				state.catalogueArtistArtworkSingleTabs = updateDropdowns(
					state.catalogueArtistArtworkSingleTabs,
					state.artworkDropdown['owner_contacts'],
					2,
					0,
					1
				);
				state.catalogueArtistArtworkSingleTabs = updateDropdowns(
					state.catalogueArtistArtworkSingleTabs,
					state.artworkDropdown['measures'],
					1,
					0,
					0
				);
				state.catalogueArtistArtworkSingleTabs = updateDropdowns(
					state.catalogueArtistArtworkSingleTabs,
					state.artworkDropdown['currencies'],
					2,
					2,
					1
				);
				state.artworkVideoSingleTabs = updateDropdowns(
					state.artworkVideoSingleTabs,
					state.artworkDropdown['artists'],
					0,
					7,
					0
				);
				state.artworkSingleTabs = updateDropdowns(
					state.artworkSingleTabs,
					state.artworkDropdown['artists'],
					0,
					6,
					0
				);
				state.artworkSingleTabs[0].content[4].information[1].options =
					state.artworkDropdown['currencies'];

				state.artworkSingleTabsArtist[0].content[4].information[1].options =
					state.artworkDropdown['currencies'];

				state.initialStateTabs[0].content[4].information[1].options =
					state.artworkDropdown['currencies'];

				state.catalogueArtistArtworkSingleTabs[2].content[2].information[1].options =
					state.artworkDropdown['currencies'];

				state.initialCatalogueArtistArtworkStateTabs[2].content[2].information[1].options =
					state.artworkDropdown['currencies'];

				state.artworkVideoSingleTabsArtist[0].content[5].information[1].options =
					state.artworkDropdown['currencies'];

				state.artworkVideoSingleTabs[0].content[5].information[1].options =
					state.artworkDropdown['currencies'];
			}
		},
		[getArtwork.rejected]: (state) => {
			state.status = 'failed';
		},

		//put artworks
		[putArtwork.pending]: (state) => {
			state.status = 'loading';
		},
		[putArtwork.fulfilled]: (state) => {
			state.status = 'success';
		},
		[putArtwork.rejected]: (state) => {
			state.status = 'failed';
		},

		//get artists/getArtistDropdown'
		[getArtworkDropdown.pending]: (state) => {
			state.status = 'loading';
		},
		[getArtworkDropdown.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.artworkDropdown = payload;
		},
		[getArtworkDropdown.rejected]: (state) => {
			state.status = 'failed';
		},

		//get artists/getArtistDropdown'
		[postArtwork.pending]: (state) => {
			state.status = 'loading';
		},
		[postArtwork.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
		},
		[postArtwork.rejected]: (state) => {
			state.status = 'failed';
		},
		// organize grid of Images
		[organizeImageGrid.pending]: (state) => {
			state.status = 'loading';
		},
		[organizeImageGrid.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
		},
		[organizeImageGrid.rejected]: (state) => {
			state.status = 'failed';
		},
	},
});

// export const { } = artworkSlice.actions;

export default artworkSlice.reducer;
