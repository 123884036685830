import React from 'react';

// UI
import AuthenticationTemplate from '../../../UI/templates/AuthenticationTemplate';
import PasswordRecoveryForm from '../../../UI/organisms/recovery/PasswordRecoveryForm';

const PasswordRecovery = () => {
	return (
		<AuthenticationTemplate>{<PasswordRecoveryForm />}</AuthenticationTemplate>
	);
};

export default PasswordRecovery;
