// 3rd Party
import PropTypes from 'prop-types';
import { View, Image } from '@react-pdf/renderer';

// BL
import { getHttpRequestDefaultOptions } from '../../../../../BusinessLogic/helpers/getHttpRequestOptions';

// UI
import DownloadableExpertSlideItemText from './DownloadableExpertSlideItemText';

export default function DownloadableExpertSlideItemArtwork({
	artworkElements,
	stylesContainer,
	artworkFieldsToShow,
}) {
	const showTitle = artworkFieldsToShow?.showTitle;
	const showDate = artworkFieldsToShow?.showDate;
	const showMeasures = artworkFieldsToShow?.showMeasures;
	const showTechnique = artworkFieldsToShow?.showTechnique;
	const showPrices = artworkFieldsToShow?.showPrices;

	return (
		<View style={[stylesContainer]} wrap={false}>
			{Array.isArray(artworkElements) &&
				[...artworkElements]
					?.sort((a, b) => a.order - b.order)
					.map((element, index) => (
						<View key={index}>
							{element.type === 'image_url' && (
								<Image
									src={getHttpRequestDefaultOptions(element.content)}
									style={{ marginBottom: '8px' }}
								/>
							)}
							{element.type === 'title' && showTitle && element.content && (
								<DownloadableExpertSlideItemText
									text={element.content}
									isSmallText={true}
								/>
							)}
							{element.type === 'date' && showDate && element.content && (
								<DownloadableExpertSlideItemText
									text={element.content}
									isSmallText={true}
								/>
							)}
							{element.type === 'technique' &&
								showTechnique &&
								element.content && (
									<DownloadableExpertSlideItemText
										text={element.content}
										isSmallText={true}
									/>
								)}
							{element.type === 'measures' &&
								showMeasures &&
								element.content && (
									<DownloadableExpertSlideItemText
										text={element.content}
										isSmallText={true}
									/>
								)}
							{element.type === 'price' && showPrices && element.content && (
								<DownloadableExpertSlideItemText
									text={element.content}
									isSmallText={true}
								/>
							)}
						</View>
					))}
		</View>
	);
}

DownloadableExpertSlideItemArtwork.propTypes = {
	// artwork data
	artworkElements: PropTypes.array,
	// css styles for container
	stylesContainer: PropTypes.object,
	// values to show/hide the fields in artwork
	artworkFieldsToShow: PropTypes.object,
};
