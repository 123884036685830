import BlankImage from '../../UI/assets/images/BlankImage.png';

const portfolio = {
	artworks: [
		{
			created_at: '2021-12-03 21:59:24',
			id: 1,
			image_quality: 'HIGH',
			main_picture: BlankImage,
			name: 'Obra de arte 1',
			organization_user_id: 1,
			slug: '3',
			type: 'COLLECTION',
			type_id: 1,
			updated_at: '2022-02-20 11:15:30',
			date: '2021',
			dimensions_height: '30',
			dimensions_length: '40',
			dimensions_width: '40',
			materials: 'Materiales',
			technique: 'Técnica',
		},
		{
			created_at: '2021-12-03 21:59:24',
			id: 2,
			image_quality: 'HIGH',
			main_picture: BlankImage,
			name: 'Obra de arte 2',
			organization_user_id: 1,
			slug: '3',
			type: 'COLLECTION',
			type_id: 2,
			updated_at: '2022-02-20 11:15:30',
			date: '2021',
			dimensions_height: '30',
			dimensions_length: '40',
			dimensions_width: '40',
			materials: 'Materiales',
			technique: 'Técnica',
		},
		{
			created_at: '2021-12-03 21:59:24',
			id: 3,
			image_quality: 'HIGH',
			main_picture: BlankImage,
			name: 'Obra de arte 3',
			organization_user_id: 1,
			slug: '3',
			type: 'COLLECTION',
			type_id: 4,
			updated_at: '2022-02-20 11:15:30',
			date: '2021',
			dimensions_height: '30',
			dimensions_length: '40',
			dimensions_width: '40',
			materials: 'Materiales',
			technique: 'Técnica',
		},
		{
			created_at: '2021-12-03 21:59:24',
			id: 4,
			image_quality: 'HIGH',
			main_picture: BlankImage,
			name: 'Obra de arte 4',
			organization_user_id: 1,
			slug: '3',
			type: 'COLLECTION',
			type_id: 4,
			updated_at: '2022-02-20 11:15:30',
			date: '2021',
			dimensions_height: '30',
			dimensions_length: '40',
			dimensions_width: '40',
			materials: 'Materiales',
			technique: 'Técnica',
		},
	],
	description:
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, est auctor hac platea sed faucibus placerat. Adipiscing tellus, condimentum curabitur nunc, lacus, nisl. Malesuada bibendum laoreet neque vitae amet, ultricies praesent. Sed eget ultrices platea morbi mauris eget.',
	main_picture: BlankImage,
	type: 'COLLECTION',
	organization_name: 'Nombre del artista',
	name: 'Nombre de la colección',
	artist: {
		first_name: 'Nombre del artista',
	},
};

export { portfolio };
