import { submitErrorToSlack } from '../helpers/SlackIntegrations';
import { api } from '../helpers/axios';

export const fetchDeleteElement = async (url) => {
	return api
		.delete(url)
		.then((res) => {
			if (res.status === 200) {
				return res.data;
			}
			throw new Error(res);
		})
		.catch((error) => {
			if (error.response.status >= 500) {
				submitErrorToSlack(url, error, 'DELETE');
			}
			console.error(error);
			throw error;
		});
};
