// 3rd parties
import React from 'react';
import PropTypes from 'prop-types';

// UI
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import TextContentSecondPortfolioUrl from '../../atoms/Text/Content/TextContentSecondPortfolioUrl';
/**
 * @description Single row with two text, label and full description
 * @param {*} param0
 * @returns
 */
export default function TextSingleRowNew({
	label,
	value,
	labelWeight,
	design,
	section,
}) {
	if (label === 'Precio') {
		value = value.toLocaleString('es-ES');
	}

	let textSingleRowNewContainerDesign = 'flex flex-row mb-4 justify-between ';

	if (label === 'URL' && section === 'Portafolio') {
		textSingleRowNewContainerDesign =
			' flex flex-col mb-4 sm:flex-row lg:justify-between ';
	}

	const renderSwitch = () => {
		switch (label) {
			case 'URL':
				return (
					section === 'Portafolio' && (
						<TextContentSecondPortfolioUrl
							design='w-full sm:w-1/2 md:w-[60%] mt-4 sm:mt-0 text-left text-grey-50'
							url={'app.datarte.art/'}
							urlSection={'portafolio/'}
							truncate={true}
							slug={value}
						/>
					)
				);
			case 'Mostrar precios':
			case 'Show prices':
				return (
					<div className='w-1/2 md:w-[60%] '>
						<button
							disabled
							type='button'
							className={`relative h-7 w-14 rounded-full
											${value ? 'bg-green-50' : 'bg-grey-50'}
											after:content-[' '] after:absolute after:top-1 after:h-5 after:w-5 after:rounded-full after:bg-white 
											${value ? 'after:right-1' : 'after:left-1'}
									`}
						/>
					</div>
				);

			default:
				return (
					<TextContentSecond
						design='w-1/2 md:w-[60%] text-left text-grey-50'
						truncate={true}
						text={value}
					/>
				);
		}
	};

	return (
		<div className={` ${textSingleRowNewContainerDesign}${design}`}>
			<TextContentSecond
				design='w-1/3 sm:w-1/2'
				style={labelWeight}
				text={`${label}: `}
			/>
			{renderSwitch()}
		</div>
	);
}

TextSingleRowNew.propTypes = {
	/** string simple label*/
	label: PropTypes.string,
	/** full description */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	/** this handles the font weight in inline style */
	labelWeight: PropTypes.object,
	/** add tailwind style in the container */
	design: PropTypes.string,
	/* identify in which section single is using */
	section: PropTypes.string,
};

TextSingleRowNew.defaultProps = {};
