//Third parties
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import ListItem from '@material-ui/core/ListItem';
import { useTranslation } from 'react-i18next';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextSubTitle from '../../molecules/Text/TextSubTitle';
import Cross from '../../assets/icons/Cross.svg';
import Check from '../../assets/icons/whiteCheck.svg';
import InputField from '../../molecules/Field/InputField';
import FieldSelectFormik from '../../molecules/Field/FieldSelectFormik';
import FieldPasswordFormik from '../../molecules/Field/FieldPasswordFormik';
import ButtonSwitch from '../../atoms/Buttons/ButtonSwitch';
import ListItemText from '../../atoms/Inputs/ListItemText';

// BL
import {
	portfolioFormValidationSchemaWithPassword,
	portfolioEditFormValidationSchema,
} from '../../../BusinessLogic/data/PortfolioSingle';
import { ENDPOINTS_PORTFOLIO } from '../../../BusinessLogic/helpers/routes';
import { fetchPostData } from '../../../BusinessLogic/hooks/fetchPostData';

/**
 * @description section that uses formik to edit the Portfolio
 * @param {*} param0
 * @returns
 */

const SectionEditPortfolio = ({
	colorHr,
	sectionTitle,
	informationArray,
	onSubmitAction,
	initialValues,
	setTurnEdit,
	id,
}) => {
	const { t } = useTranslation();

	const [validation, setValidation] = useState(
		portfolioEditFormValidationSchema
	);
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
		useState(false);
	const [typeOfPermission, setTypeOfPermission] = useState(
		initialValues.permissions
	);
	const [showPrices, setShowPrices] = useState(initialValues.show_prices);
	const [errorSlugRepeat, setErrorSlugRepeat] = useState(false);

	useEffect(() => {
		if (typeOfPermission === 'PASSWORD') {
			setValidation(portfolioFormValidationSchemaWithPassword);
		} else {
			setValidation(portfolioEditFormValidationSchema);
		}
	}, [typeOfPermission]);

	const handlePermissionChange = (e, formProps) => {
		setTypeOfPermission(e.target.value);
		formProps.values.permissions = e.target.value;
		formProps.setValues(formProps.values);
	};

	const handleOnSubmit = (values) => {
		const initialSlug = initialValues.slug;
		const finalSlug = values.slug;

		const submitValues = () => {
			let input = { values, id };
			values.show_prices = showPrices;
			onSubmitAction(input).then(() => {
				setTurnEdit(false);
			});
		};

		if (initialSlug !== finalSlug) {
			let checkSlugRepeat = {
				slug: finalSlug,
			};
			fetchPostData(ENDPOINTS_PORTFOLIO.POST_SLUG_REPEAT, checkSlugRepeat).then(
				(res) => {
					if (res.exist === 0) {
						submitValues();
					} else {
						setErrorSlugRepeat(true);
					}
				}
			);
		} else {
			submitValues();
		}
	};

	return (
		<div className='flex w-full flex-col '>
			<TextSubTitle
				text={sectionTitle}
				hasEdit={false}
				hasAdd={false}
				colour={colorHr}
			/>
			<div>
				<Formik
					enableReinitialize={true}
					initialValues={initialValues}
					validationSchema={validation}
					onSubmit={(values) => {
						handleOnSubmit(values);
					}}
				>
					{(formProps) => (
						<Form>
							<InputField
								item={informationArray[0]}
								key={1}
								errors={formProps.errors?.name}
							/>
							<InputField
								item={informationArray[1]}
								key={2}
								errors={formProps.errors?.slug}
								onBlurEvent={() => {
									setErrorSlugRepeat(false);
								}}
							/>
							{errorSlugRepeat && (
								<p className={`text-red-400`}>{t('PortafolioSlugRepetido')}</p>
							)}
							<FieldSelectFormik
								label={informationArray[2].label}
								dbName={informationArray[2].field}
								options={[
									{ value: 'PUBLIC', label: 'Público' },
									{ value: 'PASSWORD', label: 'Público con clave' },
									{ value: 'PRIVATE', label: 'Privado' },
								]}
								key={3}
								className='mx-2'
								onChangeAction={(e) => handlePermissionChange(e, formProps)}
								formProps={formProps}
							/>

							{typeOfPermission === 'PASSWORD' && (
								<div>
									<FieldPasswordFormik
										label='Contraseña'
										dbName='password'
										type={passwordVisibility ? 'text' : 'password'}
										passwordVisibility={passwordVisibility}
										setPasswordVisibility={setPasswordVisibility}
										errors={formProps.errors?.password}
										errorMessageDesign={`text-red-400`}
									/>

									<FieldPasswordFormik
										label='Confirmar contraseña'
										dbName='confirm_password'
										type={confirmPasswordVisibility ? 'text' : 'password'}
										confirmPasswordVisibility={confirmPasswordVisibility}
										setConfirmPasswordVisibility={setConfirmPasswordVisibility}
										errors={formProps.errors?.confirm_password}
										errorMessageDesign={`text-red-400`}
									/>
								</div>
							)}

							<ListItem
								style={{
									paddingLeft: '0px',
									paddingRight: '0px',
									justifyContent: 'space-between',
								}}
							>
								<ListItemText
									primary={t('MostrarPrecios')}
									design='body-Text2'
									style={{ fontWeight: 400 }}
								/>
								<div className='w-40 md:w-56'>
									<ButtonSwitch
										switched={showPrices}
										setSwitched={setShowPrices}
									/>
								</div>
							</ListItem>
							<FieldSelectFormik
								label={informationArray[4].label}
								dbName={informationArray[4].field}
								options={[
									{ value: 'HIGH', label: 'Alta' },
									{ value: 'MEDIUM', label: 'Media' },
									{ value: 'LOW', label: 'Baja' },
								]}
								key={4}
								className='mx-2'
							/>

							<div className='my-5 flex w-full flex-row justify-center gap-3'>
								<ButtonFab
									label='Cancelar'
									colour='white'
									action={() => setTurnEdit(false)}
									icon={Cross}
								/>
								<ButtonFab
									colour='green'
									label='Guardar'
									icon={Check}
									type='submit'
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

SectionEditPortfolio.propTypes = {
	/** title of the section*/
	sectionTitle: PropTypes.string,
	/** info used to create the report*/
	informationArray: PropTypes.array,
	/** action on submit */
	onSubmitAction: PropTypes.func,
	/** arrays of objects with labels and fields*/
	initialValues: PropTypes.object,
	/** Color of Header in Subtitle */
	colorHr: PropTypes.string,
	/** check */
	setTurnEdit: PropTypes.func,
	/** id of the element */
	id: PropTypes.number,
	/** object used for validation */
	validationSchemaObject: PropTypes.object,
};

export default SectionEditPortfolio;
