//3rd party
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

//UI
import SignupChooseTypeProfile from './SignupChooseTypeProfile';
import ButtonFabExtendedPrimary from '../../molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';

/**
 * @description first element of the sign up process flow
 * @param {*} param0
 * @returns
 */

const SignupFormRoleCompany = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [roleCompany, setRoleCompany] = useState('artist');

	const redirectURL = () => {
		if (roleCompany === 'artist') {
			navigate('/registro/artista');
		} else if (roleCompany === 'gallery') {
			navigate('/registro/galeria');
		} else if (roleCompany === 'organization') {
			navigate('/registro/organizacion');
		}
	};

	const updateRoleCompany = (role) => {
		setRoleCompany(role);
	};

	return (
		<div className='mx-auto w-11/12'>
			<div>
				<SignupChooseTypeProfile
					roleCompany='artist'
					updateRoleCompany={updateRoleCompany}
					title={t('Artista')}
					text={t('PlanArtistasInformación')}
					isSelected={roleCompany === 'artist'}
				/>
				<SignupChooseTypeProfile
					roleCompany='organization'
					updateRoleCompany={updateRoleCompany}
					title={t('Organizaciones')}
					text={t('PlanOrganizaciónInformación')}
					isSelected={roleCompany === 'organization'}
				/>
				<SignupChooseTypeProfile
					roleCompany='gallery'
					updateRoleCompany={updateRoleCompany}
					title={t('GaleríasDeArte')}
					text={t('PlanGaleríasInformación')}
					isSelected={roleCompany === 'gallery'}
				/>
			</div>
			<div className='mt-4 flex justify-center'>
				<ButtonFabExtendedPrimary
					text={t('Continuar')}
					typeButton={'button'}
					action={redirectURL}
				/>
			</div>
		</div>
	);
};

SignupFormRoleCompany.defaultProps = {};

export default SignupFormRoleCompany;
