import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//BL

import { cleanAllFilters } from '../../../BusinessLogic/redux/reducers/filter';
import {
	usePostPostulationCatalogue,
	usePutPostulationCatalogue,
} from '../../../BusinessLogic/hooks/query/useQueryPostulation';

//UI
import SectionPostulationArtistGrid from '../../../UI/organisms/Section/SectionPostulationArtistGrid';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import PostulationImageAndDetails from '../../../UI/organisms/Postulation/PostulationImageAndDetails';
import PortfolioFooter from '../../../UI/organisms/PortfolioPlanilla/PortfolioFooter';
import FilterSinglePostulation from '../../../UI/organisms/Filter/FilterSinglePostulation';
import TextContentPrincipal from '../../../UI/atoms/Text/Content/TextContentPrincipal';
import PostulationFiltersAndTitle from '../../../UI/organisms/Postulation/PostulationFiltersAndTitle';
import ButtonLinkBack from '../../atoms/Buttons/Link/ButtonLinkBack';
import ButtonLinkBackText from '../../molecules/Buttons/Link/ButtonLinkBackText';
import pencil from '../../assets/icons/pencil.svg';
import { ReactComponent as PortfolioWhite } from '../../assets/icons/PortfolioWhite.svg';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import { getArtist } from '../../../BusinessLogic/redux/reducers/artist';

const CatalogueConfirmation = ({
	setCatalogueView,
	preselectedArtworkIds,
	postulationSingle,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { artworks } = useSelector((state) => state.artworks);
	const { appUser } = useSelector((state) => state.appUser);
	const { singleArtist } = useSelector((state) => state.artists);

	const isArtistUser = appUser.role_company === 'ARTIST';
	const isCatalogueArtistUser = appUser.role_company === 'ARTIST_CATALOGATION';
	const isOrganizationUser = appUser.role_company === 'ORGANIZATION';
	const isGalleryUser = appUser.role_company === 'GALLERY';

	useEffect(() => {
		if (
			!isOrganizationUser &&
			(!singleArtist || singleArtist.id !== postulationSingle?.artist_id)
		) {
			postulationSingle.artist_id &&
				dispatch(getArtist({ id: postulationSingle.artist_id }));
		}
	}, [
		dispatch,
		singleArtist,
		postulationSingle.artist_id,
		isGalleryUser,
		isOrganizationUser,
	]);

	const postPostulationCatalogueMutation = usePostPostulationCatalogue();
	const putPostulationCatalogueMutation = usePutPostulationCatalogue();

	const mainArtist = {
		main_picture_url:
			postulationSingle.artist_main_image ??
			'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_contact',
		name: postulationSingle.organization_user_name,
		city_name: postulationSingle.artist_city,
		country_name: postulationSingle.artist_country,
		phone_number: postulationSingle.artist_phone,
		email: postulationSingle.artist_email,
	};

	const postulationSelectedArtworks = isOrganizationUser
		? postulationSingle.artworks
		: artworks?.filter((artwork) =>
				preselectedArtworkIds?.includes(artwork.id)
		  );

	useEffect(() => {
		dispatch(cleanAllFilters());
	}, [dispatch]);

	const applyAction = () => {
		(() => {
			if (postulationSingle.artworks.length < 1) {
				return postPostulationCatalogueMutation.mutateAsync({
					postulation_id: postulationSingle.id,
					organization_user_id: postulationSingle.organization_user_id,
					artworks: preselectedArtworkIds,
				});
			} else {
				return putPostulationCatalogueMutation.mutateAsync({
					postulation_id: postulationSingle.id,
					organization_user_id: postulationSingle.organization_user_id,
					artworks: preselectedArtworkIds,
				});
			}
		})()
			.then(() => {
				navigate(`/postulaciones/${postulationSingle.id}`);
			})
			.catch((err) => console.error(err));
	};

	return (
		<div className='m-0 w-full overflow-x-hidden p-0 pb-12'>
			<div className='container mx-auto mt-8 flex px-2 md:grid md:grid-cols-3 md:px-4 lg:px-8 2xl:px-10'>
				<ButtonLinkBack
					redirect={`/postulaciones/${postulationSingle.id}`}
					design='md:hidden'
				/>
				<ButtonLinkBackText
					design='hidden md:block w-full'
					textBack={`${t('RegresarAPostulación')}`}
					textBackDesign='pl-3 text-[#CBBFD9]'
					redirect={`/postulaciones/${postulationSingle.id}`}
					arrowLeftColor='#CBBFD9'
				/>
			</div>
			{isOrganizationUser && (
				<div className='container mx-auto flex w-full gap-4 px-2 py-8 md:px-4 lg:px-8 2xl:px-10'>
					<div className='flex h-14 w-14 items-center justify-center rounded-full bg-green-50 p-4'>
						<PortfolioWhite />
					</div>
					<div className='flex w-full flex-col gap-2 px-4'>
						<TextTitleSub
							title={t('CatalogoDeObras')}
							design={'text-purple-50'}
						/>
						<TextContentPrincipal
							text={t('ParaAplicarAEstaConvocatoriaDebesSeleccionar')}
						/>
					</div>
				</div>
			)}
			<div className='container mx-auto mb-12 flex flex-col px-2 md:px-4 lg:px-8 2xl:px-10'>
				<PostulationImageAndDetails data={mainArtist} />
				<div className='mt-8 flex w-2/3 flex-col pl-4 md:pl-8'>
					<TextContentPrincipal text='Ordenar por:' design='text-green-50' />
					<div className='mt-1 block md:hidden'>
						<FilterSinglePostulation design='absolute w-2/3' />
					</div>
				</div>
				<PostulationFiltersAndTitle
					design='mt-12 md:mt-1 mb-12 px-4 md:px-8'
					designFilterContainer='absolute w-2/6 lg:w-1/4 xl:w-1/5'
				/>
				<SectionPostulationArtistGrid artworks={postulationSelectedArtworks} />

				{(isArtistUser || isCatalogueArtistUser || isGalleryUser) &&
					postulationSingle.status === 'DRAFT' && (
						<div className='mb-16 mt-8 flex w-full flex-row items-center justify-center'>
							<ButtonFab
								action={() => setCatalogueView('selectionView')}
								label={t('Editar')}
								design='mr-5'
								colour='white'
								icon={pencil}
							/>
							<ButtonFab action={() => applyAction()} label={t('Continuar')} />
						</div>
					)}
			</div>
			<div className='fixed bottom-0 z-40 w-full'>
				<PortfolioFooter />
			</div>
		</div>
	);
};

export default CatalogueConfirmation;
