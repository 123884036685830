import React from 'react';
import PropTypes from 'prop-types';

const SkeletonTitle = ({
	designSkeletonTitle1,
	designSkeletonTitle2,
	designSkeletonTitle3,
	designSkeletonTitleRight,
	designSkeletonTitle4,
}) => {
	return (
		<div>
			<div className={'flex h-20 w-full items-center justify-between'}>
				<div className={`grid grid-cols-2 gap-1 ${designSkeletonTitle1}`}>
					<div className='skeleton-animation-background-light my-2 mr-1 h-8 w-8 animate-skeleton bg-light_grey-50'></div>
					<div className='my-2 h-8 w-8'></div>
				</div>
				<div className={`mt-3 w-40  ${designSkeletonTitle2}`}>
					<div className='skeleton-animation-background-light h-8 animate-skeleton bg-light_grey-50'></div>
					<div className='flex justify-center'>
						<div className='skeleton-animation-background-light col-start-2 col-end-3 mt-2 h-1 w-9/12 animate-skeleton bg-light_grey-50'></div>
					</div>
				</div>
				<div className={`flex justify-between ${designSkeletonTitle3}`}>
					<div
						className={`skeleton-animation-background-light mx-1 h-8 w-8 animate-skeleton bg-light_grey-50 ${designSkeletonTitleRight}`}
					></div>
					<div className='skeleton-animation-background-light h-8 w-8 animate-skeleton bg-light_grey-50'></div>
					<div className='skeleton-animation-background-light ml-1 hidden h-8 w-8 animate-skeleton bg-light_grey-50'></div>
				</div>
			</div>
			<div
				className={`skeleton-animation-background-dark float-right ml-1 h-4 w-20 animate-skeleton rounded-lg bg-middle_grey-50 ${designSkeletonTitle4}`}
			></div>
		</div>
	);
};

SkeletonTitle.defaultProps = {
	designSkeletonTitle1: 'invisible',
	designSkeletonTitle2: '',
	designSkeletonTitle3: '',
	designSkeletonTitle4: '',
	designSkeletonTitleRight: '',
};

SkeletonTitle.propTypes = {
	/** TailWind className  */
	designSkeletonTitle1: PropTypes.string,
	/** TailWind className  */
	designSkeletonTitle2: PropTypes.string,
	/** TailWind className  */
	designSkeletonTitle3: PropTypes.string,
	/** TailWind className  */
	designSkeletonTitle4: PropTypes.string,
};

export default SkeletonTitle;
