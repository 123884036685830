//3rd party

import { useParams, useNavigate } from 'react-router-dom';

//BL
import { useGetPostulationSingle } from '../../../BusinessLogic/hooks/query/useQueryPostulation';
import { useGetSingleCall } from '../../../BusinessLogic/hooks/query/useQueryCalls';

//UI
import NewPostulation from './NewPostulation';
import PortfolioLoading from '../../../UI/organisms/Loading/PortfolioLoading';
import SubmittedPostulation from './SubmittedPostulation';

/**
 * @description show single postulation elements
 * @returns
 */
const SinglePostulation = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { postulationSingle, isLoading } = useGetPostulationSingle(id);

	const { singleCall } = useGetSingleCall(postulationSingle?.call_id);
	if (!isLoading && !postulationSingle.id) navigate('/postulaciones');

	return (
		<>
			{postulationSingle &&
			postulationSingle.id === parseInt(id) &&
			singleCall ? (
				(() => {
					switch (postulationSingle.status) {
						case 'NEW':
						case 'DRAFT':
							return <NewPostulation />;

						case 'APPROVED':
						case 'SAVED':
						case 'REJECTED':
						case 'REVIEWED':
						case 'READ':
						case 'SUBMITTED':
							return (
								<SubmittedPostulation postulationSingle={postulationSingle} />
							);

						default:
							navigate('/postulaciones');
							return null;
					}
				})()
			) : (
				<PortfolioLoading />
			)}
		</>
	);
};

export default SinglePostulation;
