import { Routes, Route } from 'react-router-dom';

//BL
import FormLocationRoom from '../../Pages/sections/location/locationRoom/FormLocationRoom';
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';
import RoomSingle from '../../Pages/sections/location/locationRoom/RoomSingle';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';

export const SalasRouter = () => {
	return (
		<Routes>
			<Route
				path='/:id/edit'
				element={
					<PrivateRoute>
						<FormLocationRoom action='edit' />
					</PrivateRoute>
				}
			/>
			<Route
				path='/new'
				element={
					<PrivateRoute>
						<FormLocationRoom action='new' />
					</PrivateRoute>
				}
			/>
			<Route
				path='/:id'
				element={
					<PrivateRoute>
						<RoomSingle />
					</PrivateRoute>
				}
			/>
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default SalasRouter;
