import ButtonLinkText from '../../atoms/Buttons/Link/ButtonLinkText';

export default function UserMenu({ appUserId }) {
	return (
		<ul className='h-26 ring-black absolute right-10 mt-2 flex w-56 origin-top-right	flex-col rounded-md bg-white p-5  shadow-lg ring-opacity-5 focus:outline-none '>
			<ButtonLinkText
				route={`/management/configuracion/user/${appUserId}`}
				text='Ver usuario'
			/>
			<ButtonLinkText
				route={`/management/configuracion/user/editar/${appUserId}`}
				text='Editar usuario'
			/>
		</ul>
	);
}
