import { Route, Routes } from 'react-router-dom';

//BL
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';

import OnboardingWelcome from '../../Pages/onboarding/OnboardingWelcome';
import FirstArtwork from '../../Pages/onboarding/FirstArtwork';
import SecondArtwork from '../../Pages/onboarding/SecondArtwork';
import OnboardingUserProfile from '../../Pages/onboarding/OnboardingUserProfile';
import ImageOnboardingComplete from '../../UI/assets/images/imageOnboardingComplete.jpg';
import EyeWhite from '../../UI/assets/icons/EyeWhite.svg';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';

export const OnboardingRouter = () => {
	return (
		<Routes>
			<Route
				path='/'
				element={
					<PrivateRoute>
						<OnboardingWelcome />
					</PrivateRoute>
				}
			/>

			<Route
				path='/perfil'
				element={
					<PrivateRoute>
						<OnboardingUserProfile />
					</PrivateRoute>
				}
			/>

			<Route
				path='/primera-obra'
				element={
					<PrivateRoute>
						<FirstArtwork />
					</PrivateRoute>
				}
			/>

			<Route
				path='/segunda-obra'
				element={
					<PrivateRoute>
						<SecondArtwork />
					</PrivateRoute>
				}
			/>

			<Route
				path='/finalizacion'
				element={
					<PrivateRoute>
						<OnboardingWelcome
							image={ImageOnboardingComplete}
							title={'YaCreasteTuPortafolio'}
							subtitle={'RápidoYSencillo'}
							buttonText={'VisualizarPortafolio'}
							buttonIcon={EyeWhite}
							text={'Finalizar'}
							isClosure={true}
						/>
					</PrivateRoute>
				}
			/>
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default OnboardingRouter;
