//3rd party
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

//BL
import { getPortfolioBySlug } from '../../../BusinessLogic/redux/reducers/portfolio';
import { whatsAppClara } from '../../../BusinessLogic/data/PortfolioPublicView';
import { incorrectSlugs } from '../../../BusinessLogic/data/PortfolioRedirection';
import { handleSlugNormalization } from '../../../BusinessLogic/helpers/portfolioRedirection';

//UI
import PlantillaCollection from '../../../UI/organisms/Plantilla/PlantillaCollection';
import PlantillaCollectionClara from '../../../UI/organisms/Plantilla/PlantillaCollectionClara';
import PortfolioLoading from '../../../UI/organisms/Loading/PortfolioLoading';
import PortfolioV2FinalView from '../portfolio/PortfolioV2FinalView';
import ButtonFabSecondary from '../../../UI/atoms/Buttons/FAB/ButtonFabSecondary';
import ArrowLeftWhite from '../../../UI/assets/icons/ArrowLeftWhite.svg';
import ExpertPortfolio from '../../../UI/templates/PortfolioTemplates/ExpertPortfolio';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';

/**
 * @description plantilla general de Colecciones
 * It manages all the types of portfolios
 * @returns
 */

export default function PortfolioPublicView() {
	const navigate = useNavigate();
	const location = useLocation();
	const { slug } = useParams();
	const dispatch = useDispatch();
	const { portfolioPublic, loadingPortfolio } = useSelector(
		(state) => state.portfolios
	);

	const { appUser } = useSelector((state) => state.appUser);
	const [showLoading, setShowLoading] = useState(true);

	const isPostulationPreview = location.state?.from?.includes('postulaciones');

	useEffect(() => {
		if (incorrectSlugs.includes(slug)) {
			navigate(`/portafolio/${handleSlugNormalization(slug)}`);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		dispatch(getPortfolioBySlug(slug)).then((data) => {
			if (data?.error) {
				navigate(`/`);
			} else {
				setShowLoading(false);
			}
		});
		// eslint-disable-next-line
	}, [dispatch, slug]);

	//render manager
	const RenderSwitch = ({ portfolioType }) => {
		switch (portfolioType) {
			case 'EXPERT':
				return <ExpertPortfolio portfolio={portfolioPublic} />;
			case 'CLARA':
				return (
					<PlantillaCollectionClara
						portfolio={portfolioPublic}
						showLoading={loadingPortfolio}
						WhatsAppData={whatsAppClara}
					/>
				);
			case 'COLLECTION':
				return (
					<PlantillaCollection
						portfolio={portfolioPublic}
						showLoading={loadingPortfolio}
					/>
				);
			case 'GENERAL_TEMPLATE':
				return <PortfolioV2FinalView portfolio={portfolioPublic} />;
			default:
				return <PortfolioLoading />;
		}
	};

	return (
		<main>
			{showLoading ? (
				<PortfolioLoading />
			) : (
				<>
					{appUser?.token && (
						<div className='absolute left-5 top-5 md:left-10 md:top-10'>
							{isPostulationPreview ? (
								<ButtonFab
									label={'Regresar a la postulación'}
									size='large'
									icon={ArrowLeftWhite}
									action={() => navigate(-1)}
								/>
							) : (
								<ButtonFabSecondary
									action={() => navigate(-1)}
									icon={ArrowLeftWhite}
								/>
							)}
						</div>
					)}
					<RenderSwitch portfolioType={portfolioPublic.type} />
				</>
			)}
		</main>
	);
}
