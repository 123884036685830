//BL
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// BL
import {
	getExpertPortfolio,
	setCurrentViewExpertPortfolioAndSelection,
	setShowModalCreation,
	setShowSlideModalExpertPortfolio,
} from '../../../../BusinessLogic/redux/reducers/portfolio';

//UI
import ExpertPortfolioEditionLayout from '../../../../UI/layouts/portfolio/ExpertPortfolioEditionLayout';
import PortfolioLoading from '../../../../UI/organisms/Loading/PortfolioLoading';
import ExpertSlideArtworkIndividual from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideArtworkIndividual';
import ExpertSlideArtworksGroup from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideArtworksGroup';
import ExpertSlideBackCover from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideBackCover';
import ExpertSlideCover from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideCover';
import ExpertSlideFullScreenImage from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideFullScreenImage';
import ExpertSlideTextBlock from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideTextBlock';
import ExpertSlideTextBlockAndImage from '../../../../UI/organisms/Portfolio/ExpertSlides/ExpertSlideTextBlockAndImage';
import { ModalPostulation } from '../../../../UI/organisms/PopUp/ModalPostulation';
import PageNotFound from '../../../fallBackPages/PageNotFound';

const ExpertPortfolioEdition = () => {
	const dispatch = useDispatch();
	const { portfolioId } = useParams();
	const { t } = useTranslation();

	const { singlePortfolio, expertPortfolioViewAndSelection } = useSelector(
		(state) => state.portfolios
	);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (portfolioId !== String(singlePortfolio?.id)) {
			setIsLoading(true);
			dispatch(getExpertPortfolio(portfolioId)).finally(() => {
				setIsLoading(false);
			});
		}
	}, [dispatch, singlePortfolio?.id, portfolioId]);

	useEffect(() => {
		dispatch(
			setCurrentViewExpertPortfolioAndSelection({
				currentView: 'editionView',
				selectedElementId: null,
				selectedItemId: null,
				selectedSlideId: null,
			})
		);
		dispatch(
			setShowSlideModalExpertPortfolio({
				isVisible: false,
				modalView: '',
			})
		);
	}, [dispatch]);

	if (isLoading) {
		return <PortfolioLoading />;
	}

	const portfolioSlidesHasContent =
		Array.isArray(singlePortfolio?.slides) &&
		singlePortfolio?.slides?.length > 0;

	// const orderedSlides = [
	// 	...singlePortfolio.slides.sort((a, b) => a.order - b.order),
	// ];

	if (
		singlePortfolio?.code === 403 ||
		parseInt(singlePortfolio?.code) === 404
	) {
		return <PageNotFound />;
	}

	return (
		<ExpertPortfolioEditionLayout>
			{singlePortfolio?.show_modal_creation && (
				<ModalPostulation
					modalTitle={t('HemosCreadoUnPortafolio')}
					modalText={t('ParaEditarTuPortafolio')}
					buttonLabel={t('Continuar')}
					actionClose={() => dispatch(setShowModalCreation(false))}
					modalClassName={'text-center mx-auto w-4/5 px-8'}
				/>
			)}
			<div className='relative z-40 mx-auto min-h-[90%] w-[80%] overflow-hidden pt-10 pb-20 md:pb-0'>
				<div className='w-full bg-white '>
					{portfolioSlidesHasContent &&
						[...singlePortfolio.slides]
							.sort((a, b) => a.order - b.order)
							.map((slide, index) => {
								switch (slide.type) {
									case 'cover':
										return (
											<ExpertSlideCover
												slideData={slide}
												key={index}
												isEdition={true}
												isSelectedSlide={
													expertPortfolioViewAndSelection?.selectedSlideId ===
													slide.slideId
												}
											/>
										);
									case 'textBlock':
										return (
											<ExpertSlideTextBlock
												slideData={slide}
												key={index}
												isEdition={true}
												isSelectedSlide={
													expertPortfolioViewAndSelection?.selectedSlideId ===
													slide.slideId
												}
											/>
										);
									case 'textBlockAndImage':
										return (
											<ExpertSlideTextBlockAndImage
												slideData={slide}
												key={index}
												isEdition={true}
												isSelectedSlide={
													expertPortfolioViewAndSelection?.selectedSlideId ===
													slide.slideId
												}
											/>
										);
									case 'artworkIndividual':
										return (
											<ExpertSlideArtworkIndividual
												slideData={slide}
												key={index}
												isEdition={true}
												isSelectedSlide={
													expertPortfolioViewAndSelection?.selectedSlideId ===
													slide.slideId
												}
											/>
										);

									case 'artworksGroup':
										return (
											<ExpertSlideArtworksGroup
												slideData={slide}
												key={index}
												isEdition={true}
												isSelectedSlide={
													expertPortfolioViewAndSelection?.selectedSlideId ===
													slide.slideId
												}
											/>
										);

									case 'backCover':
										return (
											<ExpertSlideBackCover
												slideData={slide}
												key={index}
												isEdition={true}
												isSelectedSlide={
													expertPortfolioViewAndSelection?.selectedSlideId ===
													slide.slideId
												}
											/>
										);

									case 'fullScreenImage':
										return (
											<ExpertSlideFullScreenImage
												slideData={slide}
												key={index}
												isEdition={true}
												isSelectedSlide={
													expertPortfolioViewAndSelection?.selectedSlideId ===
													slide.slideId
												}
											/>
										);

									default:
										return null;
								}
							})}
				</div>
			</div>
		</ExpertPortfolioEditionLayout>
	);
};

export default ExpertPortfolioEdition;
