//3rd party
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

//BL

//UI
import details from '../../assets/icons/details.svg';
import OrganizationMenu from '../../molecules/userManagement/OrganizationMenu';
import FigureCirclePerfil from '../../atoms/Figure/FigureCirclePerfil';
import { DetailsOrganization } from '../../molecules/userManagement/DetailsOrganization';
import UnderlineHr from '../../atoms/UnderLine/UnderlineHr';
import ImageMain from '../../molecules/Image/ImageMain';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import TextContentThird from '../../atoms/Text/Content/TextContentThird';

export default function ManagamentOrganization({
	nameOrganization,
	emailUsuario,
	rolUser,
	accountType,
	accountRole,
	idOrganization,
	roleId,
	FirstLetter,
}) {
	const [clickedOutside, setClickedOutside] = useState(false);
	const { t } = useTranslation();
	return (
		<div className='shadow_box-strong relative mx-auto mb-5 flex h-48 w-full flex-col rounded-sm p-5 lg:h-52 lg:w-full'>
			<div className='mb-4 flex flex-row justify-between lg:hidden'>
				<TextContentPrincipal
					text={nameOrganization}
					style={{ fontWeight: 600 }}
				/>
				<div
					onClick={() => {
						setClickedOutside(!clickedOutside);
					}}
				>
					<ImageMain image={details} alt='menu user' />
				</div>
				{clickedOutside && (
					<OrganizationMenu
						rolUser={rolUser}
						idOrganization={idOrganization}
						roleId={roleId}
						action={() => {
							setClickedOutside(false);
						}}
					/>
				)}
			</div>

			<div className='relative mb-4 flex flex-row lg:mb-14'>
				<FigureCirclePerfil
					initial={FirstLetter}
					design='bg-green-50 text-white rounded-full h-12 w-12 lg:h-14 lg:w-14 flex items-center justify-center mr-2.5'
				/>
				<div className='lg:flex lg:w-full lg:flex-col lg:justify-center'>
					<div className='hidden lg:flex lg:w-full lg:flex-row lg:justify-between'>
						<TextContentPrincipal
							text={nameOrganization}
							style={{ fontWeight: 600 }}
						/>
						<div onClick={() => setClickedOutside(!clickedOutside)}>
							<ImageMain image={details} alt='menu user' />
						</div>
						{clickedOutside && (
							<OrganizationMenu
								rolUser={rolUser}
								idOrganization={idOrganization}
								roleId={roleId}
							/>
						)}
					</div>
					<div className='flex flex-col items-start justify-center'>
						<TextContentThird
							text={t('Mi cuenta')}
							design='lg:hidden'
							style={{ fontWeight: 600 }}
						/>
						<TextContentThird text={emailUsuario} design='text-grey-50' />
					</div>
				</div>
			</div>

			<div className='lg:ml-13 flex flex-row'>
				<DetailsOrganization
					title={t('RolEnLaCuenta')}
					data={t(rolUser)}
					designOne='mb-2.5'
					designTwo='text-grey-50'
					className='flex flex-col items-start'
				/>
				<UnderlineHr design='w-px bg-yellow-50 mx-2.5 h-full border-none w-0.5 lg:ml-13' />
				<DetailsOrganization
					title={`${t('PlanCuenta')}${accountType}`}
					data={t(accountRole)}
					designOne='mb-2.5'
					designTwo='text-grey-50'
					className='flex flex-col items-start'
				/>
				<UnderlineHr design='w-px bg-yellow-50 mx-2.5 h-full border-none w-0.5 lg:ml-13' />
				<DetailsOrganization
					title={`${t('PlanAcceso')} ${accountType}`}
					data={accountType}
					designOne='mb-2.5'
					designTwo='text-grey-50'
					className='flex flex-col items-start'
				/>
			</div>
		</div>
	);
}
