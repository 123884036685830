//3rd party components
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

//UI
import 'react-lazy-load-image-component/src/effects/blur.css';
import TextContentThird from '../../atoms/Text/Content/TextContentThird';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';

/**
 * @description Molecule that has the announcement preview information.
 * @param {*} param0
 * @returns
 */
function ImageAndInfo({
	key,
	id,
	imagen,
	title,
	redirect,
	sub_header,
	design,
	titleDesign,
	subHeaderDesign,
	textContainerDesign,
	lazyLoadImageBorder,
	imageFormat,
	designWrapperImage,
}) {
	let defaultImage =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';
	let redirectPath = redirect ? `/${redirect}/${id}` : '';
	let image_format = imageFormat ? '_medium' : '';

	return (
		<div className={design} key={key}>
			<Link
				className='relative '
				to={{
					pathname: redirectPath,
				}}
			>
				<div className='relative flex flex-col items-center'>
					<LazyLoadImage
						alt={title}
						src={imagen ? imagen + image_format : defaultImage}
						width='100%'
						height='100%'
						effect='blur'
						wrapperClassName={`overflow-hidden flex items-center lazyLoadingImages max-h-48 ${
							lazyLoadImageBorder && 'border-solid border-4 border-yellow-50'
						} ${designWrapperImage}`}
						delayTime='0'
						threshold='800'
						style={{ objectFit: 'container', aspectRatio: '16:9' }}
					/>
					<div className={textContainerDesign}>
						{title && (
							<TextContentPrincipal
								design={titleDesign}
								style={{ fontWeight: '700' }}
								text={title}
							/>
						)}
						{sub_header && (
							<TextContentThird design={subHeaderDesign} text={sub_header} />
						)}
					</div>
				</div>
			</Link>
		</div>
	);
}

ImageAndInfo.propTypes = {
	/** Id of the image. This id is used for navigation */
	id: PropTypes.number,
	/** key number of the entity */
	key: PropTypes.number,
	/** URL link to the image */
	imagen: PropTypes.string,
	/** Title on the card */
	title: PropTypes.string,
	/** String for the redirect*/
	redirect: PropTypes.string,
	/** Sub title on the card */
	sub_header: PropTypes.string,
	/* String prop that set the design for component container*/
	design: PropTypes.string,
	/* String prop that set the design for the subtitle */
	titleDesign: PropTypes.string,
	/* String prop that set the design for the subtitle */
	subHeaderDesign: PropTypes.string,
	/* String prop that set the design for the container of title and  subtitle */
	textContainerDesign: PropTypes.string,
	/* Boolean prop, if true, sets a border design for the container in the LazyLoadImage component*/
	lazyLoadImageBorder: PropTypes.bool,
	/* Boolean prop, if true, sets the image_format to _medium else "" */
	imageFormat: PropTypes.bool,
	/* Tailwind styles for wrapper image */
	designWrapperImage: PropTypes.string,
};

ImageAndInfo.defaultProps = {
	design:
		'relative w-3/4 sm:w-2/4 md:w-full border rounded-md shadow-lg mx-auto',
	titleDesign: 'pt-2 pb-1 text-light_green-50 line-clamp-2 words-break',
	subHeaderDesign: 'mt-1 line-clamp-3 font-light text-grey-50 words-break',
	textContainerDesign: 'p-4 pt-2',
	imageFormat: true,
};

export default ImageAndInfo;
