import { submitErrorToSlack } from '../helpers/SlackIntegrations';
import { api } from '../helpers/axios';

export const fetchPostFiles = async (url, values) => {
	const form = new FormData();
	for (const [key, value] of Object.entries(values)) {
		if (value) {
			if (key === 'filesArray') {
				form.append('files', value.file);
			} else {
				form.append(key, value);
			}
		}
	}
	return api
		.post(url, form, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			if (res.status === 200) {
				return res.data;
			}

			throw new Error(res.data);
		})
		.catch((error) => {
			console.error(error);
			if (error.response.status >= 500) {
				submitErrorToSlack(url, error, 'POST');
			}
			throw error;
		});
};
