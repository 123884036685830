//UI
import Clara from '../../UI/assets/portfolio/Clara.svg';

const whatsAppClara = {
	phoneNumber: '+573163618699',
	accountName: 'Clara Valdenebro',
	statusMessage: 'claritavaldenebro@gmail.com',
	chatMessage:
		'Si deseas contactarme o saber más sobre alguna obra en particular, contáctame por aquí o envíame un email.',
	avatar: Clara,
};

const dummyDataPortfolio = {
	image_quality: 'HIGH',
	slug: 'test12312312',
	organization_user_id: '1',
	type: 'COLLECTION',
	type_id: '221',
	name: 'test',
	slides: [
		{
			//slide 1
			id: null,
			portfolioId: null,
			createdAt: null,
			updatedAt: null,
			//accedo a content -> slide.content
			content: [
				{
					//element 1
					type: 'Text',
					content: 'Mi Portafolio',
					maxLength: '50',
					fontSize: 'text-3xl',
					fontWeight: 'font-bold',
					fontColor: 'text-grey-50',
					position: { left: 26.7, top: 32.2 },
					positionDraggable: { x: -51, y: 159 },
					height: null,
					width: null,
					textId: null,
					typeInput: 'textarea',
				},
				{
					//element 2
					type: 'Text',
					content: 'subtitulo',
					maxLength: '50',
					fontSize: 'text-[4.5vw] sm:text-2xl',
					fontWeight: 'font-bold',
					fontColor: 'text-grey-50',
					position: { left: 26.7, top: 50 },
					positionDraggable: { x: -51, y: 159 },
					height: null,
					width: null,
					textId: null,
					typeInput: 'textarea',
				},
			],
		},
		{
			//slide 2
			id: null,
			portfolioId: null,
			createdAt: null,
			updatedAt: null,
			content: [
				{
					type: 'artwork',
					artworkId: '2326',
					elementType: 'elementArtworkComplete',
					textPosition: { left: 24.1, top: 81.6 },
					picturePosition: { left: 23.8, top: 0 },
					picture: [
						{
							url: 'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5254',
						},
					],
					text: [
						{
							name: 'Paisajes Diverso Grado AbstracciÃ³n',
							height: null,
							length: null,
							weight: null,
							width: null,
							measure: '00 x 00 x 00 cm',
							technique: 'Técnica: .',
							year: 'Año: 0000',
						},
					],
				},
			],
		},
		{
			//slide 3
			id: null,
			portfolioId: null,
			createdAt: null,
			updatedAt: null,
			//accedo a content -> slide.content
			content: [
				{
					//element 2
					type: 'Text',
					content:
						'Esta colección recoge diferentes instantes de paisajes: sintetizan llanuras o volcanes o atmósferas tropicales.',
					maxLength: '50',
					fontSize: 'text-[4.5vw] sm:text-2xl',
					fontWeight: 'font-bold',
					fontColor: 'text-grey-50',
					position: { left: 0, top: 40 },
					positionDraggable: { x: -51, y: 159 },
					height: null,
					width: null,
					textId: null,
					typeInput: 'textarea',
				},
			],
		},
		{
			//slide 4
			id: null,
			portfolioId: null,
			createdAt: null,
			updatedAt: null,
			content: [
				{
					type: 'artwork',
					artworkId: '2328',
					elementType: 'artworkElementPicture',
					textPosition: {},
					picturePosition: { left: 0, top: 12 },
					picture: [
						{
							url: 'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5256',
						},
					],
					text: [
						{
							name: 'Port',
							height: null,
							length: null,
							weight: null,
							width: null,
							measure: 'Medida: 00 x 00 x 00 cm',
							technique: 'Técnica: .',
							year: 'Año: 0000',
						},
					],
				},
			],
		},
	],
};

export { whatsAppClara, dummyDataPortfolio };
