import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { useDispatch, useSelector } from 'react-redux';
import {
	setCurrentViewExpertPortfolioAndSelection,
	setShowSlideModalExpertPortfolio,
} from '../../../../BusinessLogic/redux/reducers/portfolio';

export default function ExpertSlideFullScreenImage({ slideData, isEdition }) {
	const dispatch = useDispatch();
	const { expertPortfolioViewAndSelection } = useSelector(
		(state) => state.portfolios
	);
	const imageElement = slideData?.slideItems
		?.find((element) => element.type === 'image')
		?.slideItemElements?.find((element) => element.type === 'image_url');

	const imageUrl = imageElement?.content;

	const smallImageUrl = imageUrl && imageUrl + '_small';
	const mediumImageUrl = imageUrl && imageUrl + '_medium';

	const handleClickOnImageElement = () => {
		dispatch(
			setShowSlideModalExpertPortfolio({
				isVisible: false,
				modalView: '',
			})
		);
		if (expertPortfolioViewAndSelection.currentView === 'imageSelectionView') {
			dispatch(
				setCurrentViewExpertPortfolioAndSelection({
					currentView: 'editionView',
					selectedElementId: null,
					selectedItemId: null,
					selectedSlideId: null,
				})
			);
		} else {
			dispatch(
				setCurrentViewExpertPortfolioAndSelection({
					selectedElementId: imageElement?.slideItemElementId,
					currentView: 'imageSelectionView',
					selectedItemId: slideData?.slideItems?.find(
						(element) => element.type === 'image'
					),
					selectedSlideId: slideData.slideId,
				})
			);
		}
	};

	if (isEdition) {
		return (
			<section className='flex w-full flex-col gap-2 border-b-2 border-dashed py-4 pb-8'>
				{imageElement && (
					<p className='pr-2 pb-4 text-right text-xs opacity-70'>
						Sección - Imagen completa
					</p>
				)}
				{imageElement && (
					<LazyLoadImage
						effect='blur'
						src={mediumImageUrl}
						placeholderSrc={smallImageUrl}
						className={`mx-auto w-[90%] cursor-pointer shadow-lg md:shadow-2xl ${
							imageElement?.slideItemElementId ===
								expertPortfolioViewAndSelection.selectedElementId &&
							'border-4 border-yellow-50'
						} `}
						onClick={handleClickOnImageElement}
					/>
				)}
			</section>
		);
	}

	return (
		<section className='flex w-full flex-col'>
			<LazyLoadImage
				src={imageElement?.content}
				className={'mx-auto shadow-lg  md:shadow-2xl'}
			/>
		</section>
	);
}
