import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description render an input with its label for the form.
 * @returns
 */

export default function InputCustomName({
	name,
	type,
	value,
	field,
	designContainerInputCustom,
	designInput,
	form,
	...props
}) {
	return (
		<div className={`w-40 md:w-56 ${designContainerInputCustom}`}>
			<input
				className={`${
					name === 'slug' && 'lowercase'
				} body-Text2 h-6 w-full rounded border-none bg-light_grey-75 ${designInput}`}
				type={type}
				name={name}
				value={value}
				{...field}
				{...props}
			/>
		</div>
	);
}

InputCustomName.propTypes = {
	/** receives the type of element that will be the input */
	type: PropTypes.string,
	/** styles to the component container */
	designContainerInputCustom: PropTypes.string,
	/** styles to the component input */
	designInput: PropTypes.string,
};
