//3rd party
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextSubTitle from '../../molecules/Text/TextSubTitle';
import Cross from '../../assets/icons/Cross.svg';
import Check from '../../assets/icons/whiteCheck.svg';
import InputField from '../../molecules/Field/InputField';
import FieldSelectFormik from '../../molecules/Field/FieldSelectFormik';
import { ReactComponent as RedCross } from '../../assets/icons/crossInRedCircle.svg';
import ViewModal from '../../templates/ViewModal';
import LoadingText from '../../molecules/Loading/LoadingText';
import { transformInfoIntoInitialValues } from '../../../BusinessLogic/helpers/FormHelper';

/**
 * @description section that uses formik to edit the user role of the organization
 * @param {*} param0
 * @returns
 */
function SectionEditRoles({
	colorHr,
	sectionTitle,
	informationArray,
	onSubmitAction,
	setTurnEdit,
	confirmDeleteElement,
}) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const validationSchema = Yup.object({
		organization_name: Yup.string()
			.min(2, 'Requiere por lo menos 2 caracteres.')
			.required('El nombre es requerido.'),
	});

	const [openDeleteOption, setOpenDeleteOption] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [showSaving, setShowSaving] = useState(false);
	const modalTitle = t('EliminarUsuario');
	const modalTextOne = t('SeguroQueDeseasEliminarEsteUsuario');

	const handleSubmitInfo = (index) => {
		const formsArray = document.getElementsByClassName('submitButton');

		if (formsArray[index]) {
			formsArray[index].click();
		} else {
			setTurnEdit(false);
			setShowSaving(false);
		}
	};

	const confirmDeleteUser = () => {
		confirmDeleteElement(selectedUserId);
	};

	return (
		<div className='flex w-full flex-col '>
			<div className={showSaving ? 'opacity-60' : ''}>
				<TextSubTitle
					text={sectionTitle}
					hasEdit={false}
					hasAdd={false}
					colour={colorHr}
				/>
				<div className='flex flex-col gap-6'>
					<>
						{informationArray.map(
							({ userData, organization_user_id, user_account_id }, i) => (
								<div key={i}>
									<Formik
										enableReinitialize
										initialValues={transformInfoIntoInitialValues(userData)}
										validationSchema={validationSchema}
										onSubmit={(values) => {
											setShowSaving(true);
											let input = {
												organization_name: values.organization_name,
												role_id: values.role_id,
												organization_user_id,
											};
											onSubmitAction(input).then(() => {
												if (i === informationArray.length - 1) {
													setTurnEdit(false);
													setShowSaving(false);
												} else {
													handleSubmitInfo(i + 1);
												}
											});
										}}
										validateOnChange
									>
										{(formProps) => (
											<>
												<Form className='formEditUsers'>
													<div className='flex justify-end'>
														<RedCross
															className='h-5 w-5 cursor-pointer'
															onClick={() => {
																setSelectedUserId(user_account_id);
																setOpenDeleteOption(true);
															}}
														/>
													</div>
													<InputField item={userData[0]} />
													<FieldSelectFormik
														label={t(userData[1].label)}
														dbName={userData[1].field}
														options={[
															{ value: '1', label: 'Administrador', id: '1' },
															{
																value: '2',
																label: 'Co- Administrador',
																id: '2',
															},
															{ value: '3', label: 'Editar', id: '3' },
															{ value: '4', label: 'Lector', id: '4' },
															{ value: '5', label: 'Temporal', id: '5' },
														]}
														className='mx-2'
														formProps={formProps}
													/>
												</Form>
												<button
													onClick={() => formProps.submitForm()}
													type='submit'
													className='submitButton invisible'
												></button>
											</>
										)}
									</Formik>
									<hr />
								</div>
							)
						)}
						{showSaving ? (
							<LoadingText text={t('Guardando')} />
						) : (
							<div className='my-5 flex w-full flex-row justify-center gap-3'>
								<ButtonFab
									label='Cancelar'
									colour='white'
									action={() => setTurnEdit(false)}
									icon={Cross}
								/>
								<ButtonFab
									colour='green'
									label='Guardar'
									icon={Check}
									action={() => handleSubmitInfo(0)}
									type='submit'
								/>
							</div>
						)}
					</>
				</div>
			</div>
			{openDeleteOption && (
				<ViewModal
					setConfirmDeleteElement={confirmDeleteUser}
					closeMenu={setOpenDeleteOption}
					modalTitle={modalTitle}
					modalTextOne={modalTextOne}
					navigate={navigate}
				/>
			)}
		</div>
	);
}

SectionEditRoles.propTypes = {
	/** title of the section*/
	sectionTitle: PropTypes.string,
	/** info used to create the report*/
	informationArray: PropTypes.array,
	/** action on submit */
	onSubmitAction: PropTypes.func,
	/** Color of Header in Subtitle */
	colorHr: PropTypes.string,
	/** check */
	setTurnEdit: PropTypes.func,
	/** id of the element */
	id: PropTypes.number,
	/** object used for validation */
	validationSchemaObject: PropTypes.object,
};

export default SectionEditRoles;
