import { submitErrorToSlack } from '../helpers/SlackIntegrations';
import { api } from '../helpers/axios';

export const fetchPutData = async (url, values) => {
	return api
		.put(url, values)
		.then((res) => {
			if (res.status === 200) {
				return res.data;
			}

			throw new Error(res.data);
		})
		.catch((error) => {
			if (error.response.status >= 500) {
				submitErrorToSlack(url, error, 'PUT');
			}
			console.error(error);
			throw error;
		});
};
