//3rd party
import { useTranslation } from 'react-i18next';

//UI
import AuthenticationTemplate from '../../../UI/templates/AuthenticationTemplate';
import SignupFormRoleCompany from '../../../UI/organisms/SignUp/SignupFormRoleCompany';
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import ImagenSignUpDesktop from '../../../UI/assets/images/ImagenSignUpDesktop.jpg';

/**
 *
 * @description Shows the sign up section
 * @param {*} param0
 * @returns
 */
const SignUp = () => {
	const { t } = useTranslation();

	return (
		<AuthenticationTemplate image={ImagenSignUpDesktop}>
			<div className='flex flex-col gap-2 text-center'>
				<TextTitleSub textColor='text-green-50' title={t('CrearCuenta')} />
				<TextContentSecond design='text-green-50' text={t('EligePerfil')} />
			</div>
			<SignupFormRoleCompany />
		</AuthenticationTemplate>
	);
};

export default SignUp;
