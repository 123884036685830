import React from 'react';
import { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// UI
import { ReactComponent as ClosedCrossIcon } from '../../assets/icons/CrossGrey.svg';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import { InputGreenCheckboxAlt } from '../../atoms/Inputs/InputGreenCheckboxAlt';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import { updateRedirectRoute } from '../../../BusinessLogic/redux/reducers/routesHistory';

const ModalCallToRegister = ({ setShowCallToRegister }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const handlePopUpClickContainer = (e) => e.stopPropagation();

	useEffect(() => {
		document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	}, []);

	return (
		<div
			className='fixed right-0 top-0 z-20 my-auto flex h-full w-full items-center justify-center bg-black-50 bg-opacity-70 '
			onClick={() => setShowCallToRegister(false)}
		>
			<div
				className='m-auto mx-auto my-6 flex h-3/4  w-[95%] items-center sm:w-1/2 md:p-0 lg:w-1/3'
				onClick={handlePopUpClickContainer}
			>
				<div className='max-width-[50%] relative flex flex-col  rounded-lg border-0  bg-white shadow-lg'>
					<div
						className='absolute right-6 top-4  cursor-pointer'
						onClick={() => setShowCallToRegister(false)}
					>
						<ClosedCrossIcon />
					</div>
					<div className='mx-8 my-12 flex flex-col items-center'>
						<TextTitleSub
							title={t('IniciaSesiónParaPostularte')}
							design={'text-center text-green-50'}
						/>
						<TextContentSecond
							text={t('ParaPoderAplicarDebesRegistrarte')}
							design='text-center mt-4'
						/>
						<TextTitleSub
							title={t('PorQuéTenerUnaCuentaEnDatarte')}
							design={'text-center text-green-50 mt-6'}
						/>
						<div className='flex flex-col gap-4 pb-2 pt-6'>
							<InputGreenCheckboxAlt
								text={t('AplicaAConvocatoriasIlimitadasDesdeLaApp')}
								checked={true}
							/>
							<InputGreenCheckboxAlt
								text={t('DescubreNuevasOportunidadesCadaSemana')}
								checked={true}
							/>
							<InputGreenCheckboxAlt
								text={t('CreaPortafoliosVirtualesPersonalizados')}
								checked={true}
							/>
							<InputGreenCheckboxAlt
								text={t('OrganizaTusObrasPorColeccionesEnLaNube')}
								checked={true}
							/>
						</div>
						<div className='flex flex-col-reverse items-center justify-between'>
							<div
								onClick={() => {
									dispatch(updateRedirectRoute(pathname));
									navigate('/iniciar-sesion');
								}}
							>
								<TextContentSecond
									text={t('IngresaATuCuenta')}
									design='underline mt-5 text-sky-300 cursor-pointer'
								/>
							</div>
							<ButtonFab
								label={t('Registrarse')}
								action={() => {
									dispatch(updateRedirectRoute(pathname));
									navigate('/registro');
								}}
								size={'large'}
								design={'pt-6 pb-2'}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ModalCallToRegister.propTypes = {
	/** Boolean that determines if the modal is visible in the parent component*/
	showCallToRegister: PropTypes.bool,
	/** Function to sets the showModalRegister value in the parent component */
	setShowCallToRegister: PropTypes.func,
};

export default ModalCallToRegister;
