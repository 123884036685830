//3rd parties
import PropTypes from 'prop-types';

/**
 * @description style for text content.
 * css class is body-Text2
 * @param {*} param0
 * @returns
 */

const TextContentSecondPortfolioUrl = ({
	url,
	urlSection,
	slug,
	design,
	truncate,
}) => {
	return (
		<div
			className={`body-Text2  flex flex-col lowercase xl:flex-row ${design} ${
				truncate && 'truncate'
			} `}
		>
			<div className='flex flex-row'>
				<p>{url}</p>
				<p>{urlSection}</p>
			</div>
			<p>{slug}</p>
		</div>
	);
};

export default TextContentSecondPortfolioUrl;

TextContentSecondPortfolioUrl.propTypes = {
	/** String with the app url*/
	url: PropTypes.string,
	/** String with the section (portafolio) url*/
	urlSection: PropTypes.string,
	/** String with the  portafolio's slug*/
	slug: PropTypes.string,
	/** Tailwind Class*/
	design: PropTypes.string,
	/** truncate */
	truncate: PropTypes.bool,
};

TextContentSecondPortfolioUrl.defaultProps = {
	truncate: false,
};
