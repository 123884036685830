// 3rd Party
import { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// BL
import { getArtistDropdown } from '../../../BusinessLogic/redux/reducers/artist';

// UI
import FilterSidebar from './FilterSidebar';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';

const FilterAllPortfolios = ({
	valuesFilterPortfolios,
	setValuesFilterPortfolios,
	valuesFilterInitialValues,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { artistDropdown } = useSelector((state) => state.artists);
	const { appUser } = useSelector((state) => state.appUser);

	const [showFilter, setShowFilter] = useState(false);

	const allArtistList = artistDropdown?.allArtist;
	const isGalleryUser = appUser.role_company === 'GALLERY';

	useEffect(() => {
		if (isGalleryUser) {
			if (Object.keys(artistDropdown).length === 0) {
				dispatch(getArtistDropdown());
			}
		}
	}, [dispatch, artistDropdown, isGalleryUser]);

	return (
		<FilterSidebar showFilter={showFilter} setShowFilter={setShowFilter}>
			<Formik
				initialValues={valuesFilterPortfolios}
				onSubmit={(values) => {
					setValuesFilterPortfolios(values);
					setShowFilter(false);
				}}
				onReset={() => {
					setValuesFilterPortfolios(valuesFilterInitialValues);
					setShowFilter(false);
				}}
			>
				{(formProps) => (
					<div className='flex flex-col gap-3 px-3 pt-8'>
						<div className='flex flex-col gap-2'>
							<TextContentSecond
								text={`${t('Portafolio')}:`}
								design='!font-medium'
							/>
							<Field
								type='input'
								name='portfolio'
								className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
							/>
						</div>
						{isGalleryUser && (
							<div className='flex flex-col gap-3'>
								<TextContentSecond
									text={`${t('Artista')}:`}
									design='!font-medium'
								/>
								<Field
									name='artist_id'
									as='select'
									className='body-Text2 h-8 w-full rounded border-none bg-middle_grey-50 px-1 text-black-50'
								>
									<option value=''>{t('SeleccionaUnaOpción')}</option>
									{Array.isArray(allArtistList) &&
										allArtistList?.map((artistItem, index) => (
											<option key={index} value={artistItem.value}>
												{artistItem?.label}
											</option>
										))}
								</Field>
							</div>
						)}
						<div className='my-5 flex flex-row justify-center gap-5'>
							<ButtonFab
								label={t('LimpiarFiltro')}
								size={'medium'}
								colour='white'
								action={() => formProps.resetForm()}
							/>
							<ButtonFab
								label={t('Aplicar')}
								size={'medium'}
								action={() => formProps.submitForm()}
							/>
						</div>
					</div>
				)}
			</Formik>
		</FilterSidebar>
	);
};

export default FilterAllPortfolios;
