//3rd party

import PropTypes from 'prop-types';
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	WhatsappShareButton,
	EmailShareButton,
} from 'react-share';
import {
	FacebookIcon,
	TwitterIcon,
	EmailIcon,
	LinkedinIcon,
	WhatsappIcon,
} from 'react-share';

//BL

export function ShareSection({ url }) {
	return (
		<div className='mb-4'>
			<div className='flex justify-center'>
				<div className='mr-4'>
					<FacebookShareButton url={url} className='flex flex-row'>
						<FacebookIcon size={28} round />
					</FacebookShareButton>
				</div>

				<div className='mr-4'>
					<TwitterShareButton
						title={'¡Te invito a ver mi portafolio aquí!'}
						url={url}
						className='flex flex-row'
					>
						<TwitterIcon size={28} round />
					</TwitterShareButton>
				</div>
				<div className='mr-4'>
					<WhatsappShareButton
						title={'¡Te invito a ver mi portafolio aquí!'}
						url={url}
						className='flex flex-row'
					>
						<WhatsappIcon size={28} round />
					</WhatsappShareButton>
				</div>
				<div className='mr-4'>
					<EmailShareButton
						subject={'¡Te invito a ver mi portafolio aquí!'}
						url={url}
						className='flex flex-row'
					>
						<EmailIcon size={28} round />
					</EmailShareButton>
				</div>

				<div className='mr-4 hidden'>
					<LinkedinShareButton url={url} className='flex flex-row'>
						<LinkedinIcon size={28} round />
					</LinkedinShareButton>
				</div>
			</div>
		</div>
	);
}

ShareSection.propTypes = {
	/* Url to be shared */
	url: PropTypes.string,
};
