import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDeleteElement } from '../../../BusinessLogic/hooks/fetchDeleteElement';
import { fetchDeleteImage } from '../../hooks/fetchDeleteImage';
import { fetchGetData } from '../../../BusinessLogic/hooks/fetchGetData';
import { messageDeleteElement } from '../../../BusinessLogic/helpers/messageDeleteElement';

export const deleteElement = createAsyncThunk(
	'element/fetchDeleteElement',
	async (url) => {
		return await fetchDeleteElement(url);
	}
);

export const getInfoElementToDelete = createAsyncThunk(
	'element/fetchElement',
	async (url) => {
		return await fetchGetData(url);
	}
);

export const deleteFile = createAsyncThunk(
	'element/fetchDeleteFile',
	async (values) => {
		return await fetchDeleteImage(values);
	}
);

const initialState = {
	elementInfo: '',
	section: '',
	backendAnswer: '',
	deletedElementCounter: 0,
};

export const ElementSlice = createSlice({
	name: 'elements',
	initialState,
	reducers: {
		setSection: (state, { payload }) => {
			state.section = payload;
		},
	},
	extraReducers: {
		[deleteElement.pending]: (state, action) => {
			state.status = 'loading';
		},
		[deleteElement.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
			state.deletedElementCounter += 1;
		},
		[deleteElement.rejected]: (state, action) => {
			state.status = 'failed';
		},
		[getInfoElementToDelete.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getInfoElementToDelete.fulfilled]: (state, { payload }) => {
			const section = state.section;
			const message = messageDeleteElement(section, payload);
			state.elementInfo = message;
			state.status = 'success';
		},
		[deleteFile.rejected]: (state, action) => {
			state.status = 'failed';
		},
		[deleteFile.pending]: (state, action) => {
			state.status = 'loading';
		},
		[deleteFile.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
			state.deletedElementCounter += 1;
		},
		[deleteFile.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});

export const { setSection } = ElementSlice.actions;

export default ElementSlice.reducer;
