//3rd party
import React from 'react';
import PropTypes from 'prop-types';

//UI
import ImageAndTitle from '../../molecules/Image/ImageAndTitle';
import CardPostulation from '../../molecules/Card/CardPostulation';

/**
 * @description contain information about the artist or organization to the postulation.
 * @returns
 */
function PostulationImageAndDetails({ data }) {
	return (
		<div className='flex w-full flex-row items-center justify-center gap-4 py-8 pr-4 md:justify-center md:gap-6'>
			<div className='flex w-32 md:w-44 md:justify-end'>
				<ImageAndTitle
					imagen={data?.main_picture_url}
					roundedBorder='9999px'
					imageFormat=''
					aspectRatioProp='1/1'
					objectFitProp='cover'
				/>
			</div>
			<div className='flex h-full w-max flex-col gap-2 text-left'>
				{data?.name && (
					<p className='text-xl text-strong_yellow-50 md:text-3xl'>
						{data?.name}
					</p>
				)}
				<CardPostulation
					city={data?.city_name}
					country={data?.country_name}
					telephone={data?.phone_number}
					email={data?.email}
				/>
			</div>
		</div>
	);
}

PostulationImageAndDetails.propTypes = {
	/* have all the information of the artist */
	data: PropTypes.object,
};

export default PostulationImageAndDetails;
