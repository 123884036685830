import React, { useState } from 'react';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import { ReactComponent as Cross } from '../../assets/icons/Cross.svg';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import { FilterChipClick } from './filterComponents.js/FilterChipClick';
import {
	CleanFilterArrayByClick,
	setApplyFilterArrayByClick,
	setApproved,
	setMixMaxFilterArrayByClick,
} from '../../../BusinessLogic/redux/reducers/filter';
import { useDispatch } from 'react-redux';

const FunnelFilterPostulation = ({ showCallFilter, setShowCallFilter }) => {
	const dispatch = useDispatch();
	const [minimum, setMinimum] = useState('');
	const [maximum, setMaximum] = useState('');

	return (
		<div
			className={`absolute top-8 -right-4 z-10 flex flex-col gap-4 rounded-lg border-2 bg-white p-6 shadow-lg ${
				!showCallFilter && 'hidden'
			} select-none`}
		>
			<div className='flex w-full min-w-max flex-row justify-between pl-16'>
				<div className='min-w-max'>
					<TextTitleSub title='Filtrar por' design='text-green-50' />
				</div>
				<Cross
					className='cursor-pointer'
					onClick={() => setShowCallFilter(!showCallFilter)}
				/>
			</div>
			<div>
				<FilterChipClick filterLabel='Técnica' filterVariable='technique' />
			</div>

			<div>
				<FilterChipClick filterLabel='Material' filterVariable='materials' />
			</div>
			<div>
				<label className='body-Text3' style={{ fontWeight: 600 }}>
					{' '}
					Obras Aprobadas
				</label>
				<select
					className=' h-8 w-full cursor-pointer rounded-md bg-middle_grey-50'
					onChange={(e) => {
						dispatch(setApproved(e.target.value));
					}}
				>
					<option></option>
					<option className='body-Text3'>Aprobados</option>
				</select>
			</div>
			<div>
				<label className='body-Text3' style={{ fontWeight: 600 }}>
					{' '}
					Precio{' '}
				</label>
				<div className='flex flex-row gap-4'>
					<input
						type='number'
						onChange={(e) => setMinimum(e.target.value)}
						className='h-8 w-1/2 cursor-pointer rounded-md bg-middle_grey-50 pl-1'
						placeholder='Mínimo'
					/>
					<input
						type='number'
						onChange={(e) => setMaximum(e.target.value)}
						className='h-8 w-1/2 cursor-pointer rounded-md bg-middle_grey-50 pl-1'
						placeholder='Máximo'
					/>
				</div>
			</div>
			<div className='flex flex-row gap-8'>
				<ButtonFab
					size='medium'
					design='min-w-max shadow-lg'
					label='Limpiar Filtro'
					colour='white'
					action={() => {
						dispatch(CleanFilterArrayByClick());
					}}
				/>
				<ButtonFab
					size='medium'
					design='shadow-lg'
					label='Aplicar'
					colour='green'
					action={() => {
						dispatch(setMixMaxFilterArrayByClick({ minimum, maximum }));
						dispatch(setApplyFilterArrayByClick());
					}}
				/>
			</div>
		</div>
	);
};
export default FunnelFilterPostulation;
