//3rd party files
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

//BL
import {
	PrivateRoute,
	PublicRoute,
} from '../../Pages/authentication/components/LoginUtils';
import Homepage from '../../Pages/homepage/Homepage';
import Login from '../../Pages/authentication/login/Login';
import { ErrorBoundary } from 'react-error-boundary';
import ArtistRouter from './ArtistRouter';
import ArtworkRouter from './ArtworkRouter';
import CollectionRouter from './CollectionRouter';
import ContactRouter from './ContactRouter';
import DocumentRouter from './DocumentRouter';
import LocationGeneralRouter from './LocationGeneralRouter';
import PortfolioPublicRouter from './PortfolioPublicRouter';
import PortfolioRouter from './PortfolioRouter';
import SalasRouter from './SalasRouter';
import CallRouter from './CallRouter';
import OnboardingRouter from './OnboardingRouter';
import AuthRoutes from './AuthRoutes';
import UserManagament from './userManagament';
import PlantillaRouter from './PlantillaRouter';
import LocationRouter from './LocationRouter';
import PostulationRouter from './PostulationRouter';
import ExpositionRouter from './ExpositionRouter';
import { updateRoutesHistory } from '../redux/reducers/routesHistory';

//UI
import { Portfolio } from '../../UI/atoms/redirect/Portfolio';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';
import ErrorPage from '../../Pages/fallBackPages/ErrorPage';
import ResetPassword from '../../Pages/authentication/recovery/ResetPassword';
import PasswordRecovery from '../../Pages/authentication/recovery/PasswordRecovery';
import ScrollToTop from '../hooks/scrollToTop';
import SEO from '../../UI/molecules/SEO/SEO';
import PaymentsRouter from './PaymentsRouter';

/**
 * @name: AppRouter
 * @description: Manages the routes of the application
 * @returns
 */

const AppRouter = () => {
	const location = useLocation();
	const { pathname } = location;
	const dispatch = useDispatch();

	useEffect(() => {
		const { token } = localStorage;
		if (token) {
			dispatch(updateRoutesHistory(pathname));
		}
	}, [dispatch, pathname]);

	return (
		<>
			<ScrollToTop />
			<SEO />
			<ErrorBoundary key={pathname} FallbackComponent={ErrorPage}>
				<Routes>
					<Route
						path='/'
						element={
							<PrivateRoute>
								<Homepage />
							</PrivateRoute>
						}
					/>

					<Route
						path='/iniciar-sesion'
						element={
							<PublicRoute>
								<Login />
							</PublicRoute>
						}
					/>

					<Route
						path='/login'
						element={<Navigate to='/iniciar-sesion' replace={true} />}
					/>

					<Route
						path='/auth/signup'
						element={<Navigate to='/registro' replace={true} />}
					/>

					<Route
						path='/restablecer_contrasena/:token'
						element={
							<PublicRoute>
								<ResetPassword />
							</PublicRoute>
						}
					/>
					<Route
						path='/recuperacion_contrasena'
						element={
							<PublicRoute>
								<PasswordRecovery />
							</PublicRoute>
						}
					/>
					<Route
						path='/management'
						element={
							<PrivateRoute>
								<userManagament />
							</PrivateRoute>
						}
					/>

					<Route
						path='planes'
						element={
							<PrivateRoute>
								<PaymentsRouter />
							</PrivateRoute>
						}
					/>

					<Route path='/success' element={<Navigate to='/' replace />} />

					<Route path='/portfolioanamaria' element={<Portfolio />} />

					<Route path='/error' element={<ErrorPage />} />

					<Route path='artistas/*' element={<ArtistRouter />} />
					<Route path='obras/*' element={<ArtworkRouter />} />
					<Route path='registro/*' element={<AuthRoutes />} />
					<Route path='convocatorias/*' element={<CallRouter />} />
					<Route path='colecciones/*' element={<CollectionRouter />} />
					<Route path='contactos/*' element={<ContactRouter />} />
					<Route path='documentos/*' element={<DocumentRouter />} />
					<Route path='exposiciones/*' element={<ExpositionRouter />} />
					<Route path='ubicaciones/*' element={<LocationRouter />} />
					<Route path='lugares/*' element={<LocationGeneralRouter />} />
					<Route path='bienvenida/*' element={<OnboardingRouter />} />

					<Route path='plantilla/*' element={<PlantillaRouter />} />
					<Route path='portafolios/*' element={<PortfolioRouter />} />
					<Route path='portafolio/*' element={<PortfolioPublicRouter />} />
					<Route path='postulaciones/*' element={<PostulationRouter />} />

					<Route path='salas/*' element={<SalasRouter />} />

					<Route path='management/*' element={<UserManagament />} />

					<Route path='/cancel' element={<Navigate to='/planes' replace />} />

					<Route path='*' element={<PageNotFound />} />
				</Routes>
			</ErrorBoundary>
		</>
	);
};

export default AppRouter;
