//Third parties
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { isValid, format } from 'date-fns';

//BL
import { getCities } from '../../../BusinessLogic/redux/reducers/staticData';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextSubTitle from '../../molecules/Text/TextSubTitle';
import Cross from '../../assets/icons/Cross.svg';
import Check from '../../assets/icons/whiteCheck.svg';
import FieldSelectFormik from '../../molecules/Field/FieldSelectFormik';
import InputCase from '../../molecules/Field/InputCase';

/**
 * @description section that uses formik to edit the City and Country
 * @param {*} param0
 * @returns
 */

const SectionEditCityCountry = ({
	colorHr,
	sectionTitle,
	informationArray,
	onSubmitAction,
	initialValues,
	setTurnEdit,
	id,
	validationSchema,
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { cities, countries } = useSelector((state) => state.staticData);
	const { appUser } = useSelector((state) => state.appUser);
	const validationSchemaObject = Yup.object(validationSchema);

	const location = useLocation();
	const isArtistUser = appUser.role_company === 'ARTIST';
	const isGalleryUser = appUser.role_company === 'GALLERY';
	const isCatalogueArtistUser = appUser.role_company === 'ARTIST_CATALOGATION';

	const isAValidArtistArtworkDate =
		location.pathname.includes('obras') &&
		isValid(new Date(initialValues.date)) &&
		(isArtistUser || isGalleryUser);

	const isAValidCatalogueArtistArtworkDate =
		location.pathname.includes('obras') &&
		isValid(new Date(initialValues.date)) &&
		isCatalogueArtistUser;

	const handleInitialValues = (initialValues) => {
		if (isAValidArtistArtworkDate || isAValidCatalogueArtistArtworkDate) {
			initialValues.date = format(new Date(initialValues.date), 'yyyy');
		}
		return initialValues;
	};

	const handleCountryChange = (e, formProps) => {
		dispatch(getCities(e.target.value));
		formProps.setFieldValue('city_id', 52560);
	};

	const handleFormSubmission = (values) => {
		const isADateValueInArtistArtwork =
			location.pathname.includes('obras') &&
			values.hasOwnProperty('date') &&
			values.date !== '' &&
			(isArtistUser || isGalleryUser);

		const isADateValueInCatalogueArtistArtwork =
			location.pathname.includes('obras') &&
			values.hasOwnProperty('date') &&
			values.date !== '' &&
			isCatalogueArtistUser;

		if (isADateValueInArtistArtwork || isADateValueInCatalogueArtistArtwork) {
			values.date = new Date(values.date, 1, 1);
		}
		let input = { values, id };

		onSubmitAction(input);
		setTurnEdit(false);
	};

	const renderSwitchFields = (information, index, formProps) => {
		switch (information.field) {
			case 'country_id':
			case 'origin_country_id':
				return (
					<FieldSelectFormik
						label={t(information.label)}
						dbName={information.field}
						options={countries}
						key={index}
						className='mx-2'
						onChangeAction={handleCountryChange}
						formProps={formProps}
						errors={formProps.errors && formProps.errors.country_id}
					/>
				);
			case 'city_id':
				return (
					<FieldSelectFormik
						label={t(information.label)}
						dbName={information.field}
						options={cities}
						key={index}
						className='mx-2'
						formProps={formProps}
						errors={formProps.errors && formProps.errors.city_id}
						defaultOptionValue={52560}
					/>
				);
			default:
				return (
					<InputCase item={information} key={index} formProps={formProps} />
				);
		}
	};

	return (
		<div className='flex w-full flex-col '>
			<TextSubTitle
				text={sectionTitle}
				hasEdit={false}
				hasAdd={false}
				colour={colorHr}
			/>
			<div>
				<Formik
					enableReinitialize
					initialValues={handleInitialValues(initialValues)}
					validationSchema={validationSchemaObject}
					onSubmit={handleFormSubmission}
				>
					{(formProps) => (
						<Form>
							{informationArray.map((field, index) =>
								renderSwitchFields(field, index, formProps)
							)}
							<div className='my-5 flex w-full flex-row justify-center gap-3'>
								<ButtonFab
									label='Cancelar'
									colour='white'
									action={() => setTurnEdit(false)}
									icon={Cross}
								/>
								<ButtonFab
									colour='green'
									label='Guardar'
									icon={Check}
									type='submit'
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

SectionEditCityCountry.propTypes = {
	/** title of the section*/
	sectionTitle: PropTypes.string,
	/** info used to create the report*/
	informationArray: PropTypes.array,
	/** action on submit */
	onSubmitAction: PropTypes.func,
	/** arrays of objects with labels and fields*/
	initialValues: PropTypes.object,
	/** Color of Header in Subtitle */
	colorHr: PropTypes.string,
	/** check */
	setTurnEdit: PropTypes.func,
	/** id of the element */
	id: PropTypes.number,
	/** object used for validation */
	validationSchemaObject: PropTypes.object,
	/* ValidationSchema used to manage error messages */
	validationSchema: PropTypes.object,
	/** this error string is generated from te yup validation schema in case of a missing requerid field*/
	errors: PropTypes.string,
};

export default SectionEditCityCountry;
