// 3rd party
import {
	Page,
	Text,
	View,
	Document,
	Svg,
	Path,
	StyleSheet,
	Image,
} from '@react-pdf/renderer';

//BL
import { getHttpRequestDefaultOptions } from '../../../BusinessLogic/helpers/getHttpRequestOptions';

/**
 * @description Download postulation catalogue of the one user
 * @param
 * @returns
 */

const styles = StyleSheet.create({
	page: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		fontSize: '13px',
		fontFamily: 'Helvetica',
		padding: '25px 0',
	},
	content: {
		padding: '50px',
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		textAlign: 'center',
		textTransform: 'uppercase',
		marginBottom: '30px',
	},
	name: {
		marginBottom: '10px',
		fontSize: '24px',
		fontFamily: 'Helvetica-Bold',
	},
	title: {
		fontSize: '15px',
	},
	description: {
		textTransform: 'none',
		margin: '15px 0',
		textAlign: 'left',
	},
	biography: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	biographyText: {
		width: '100%',
		paddingRight: '5%',
	},
	biographyImageContainer: {
		width: '35%',
		minWidth: '35%',
	},
	biographyImage: {
		objectFit: 'cover',
		width: '100%',
	},
	exhibitions: {
		marginTop: '30px',
		marginBottom: '30px',
	},
	artworks: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
	},
	artwork: {
		width: '45%',
		height: '370px',
		marginBottom: '10px',
		fontSize: '10px',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
	},
	textBold: {
		marginBottom: '25px',
		fontFamily: 'Helvetica-Bold',
	},
	textBoldCenter: {
		margin: '15px 0',
		textAlign: 'center',
		width: '100%',
		fontFamily: 'Helvetica-Bold',
		fontSize: '15px',
	},
	textLong: {
		whiteSpace: 'break-spaces',
		wordWrap: 'wrap',
	},
	artworkStatus: {
		textTransform: 'capitalize',
	},
	artworkImage: {
		objectFit: 'contain',
		width: '100%',
		maxHeight: '300px',
	},
	coverImage: {
		objectFit: 'contain',
		width: '100%',
		maxHeight: '384px',
		margin: '15px 0',
	},
	footer: {
		backgroundColor: '#9CBDB9',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		color: '#fff',
		padding: '5px 15px',
	},
	footerContentOne: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	footerText: {
		fontSize: '12px',
		marginLeft: '10px',
	},
	logoDatarte: {
		height: '30px',
		width: '30px',
	},
});

export default function DownloadableForm({ portfolio }) {
	const artistFirstName = portfolio?.artist?.first_name;
	const artistLastName = portfolio?.artist?.last_name;
	const portfolioArtistName = `${artistFirstName ?? ''} ${
		artistLastName ?? ''
	}`;
	return (
		<Document>
			<Page style={styles.page} size={'A4'}>
				<View style={styles.content}>
					<View style={styles.header}>
						<Text style={styles.name}>{portfolioArtistName}</Text>
						<Text style={styles.title}>{portfolio.name}</Text>
						<Text style={styles.description}>{portfolio.description}</Text>
						<Image
							src={getHttpRequestDefaultOptions(portfolio.main_picture)}
							style={styles.coverImage}
						/>
					</View>
					<Text style={styles.textBold}>BIOGRAFÍA</Text>
					<View style={styles.biography}>
						<View style={styles.biographyText}>
							<Text>{portfolio.artist?.biography}</Text>
						</View>
						<View style={styles.biographyImageContainer}>
							<Image
								style={styles.biographyImage}
								src={getHttpRequestDefaultOptions(
									portfolio.artist?.main_picture_url
								)}
							/>
						</View>
					</View>
					{portfolio.artist?.exhibitions && (
						<View style={styles.exhibitions}>
							<Text style={styles.textBold}>EXPOSICIONES</Text>
							<Text>{portfolio.artist?.exhibitions}</Text>
						</View>
					)}
				</View>
			</Page>
			{portfolio.artworks !== 'empty' && (portfolio.artworks ?? []).length > 0 && (
				<Page style={styles.page} size={'A4'}>
					<View style={styles.content}>
						{(portfolio.subcollection ?? []).length === 0 && (
							<Text style={styles.textBoldCenter}>OBRAS</Text>
						)}
						<View style={styles.artworks}>
							{portfolio?.artworks.map((artwork, i) => (
								<View style={styles.artwork} key={i}>
									<Image
										src={getHttpRequestDefaultOptions(artwork.main_picture)}
										style={styles.artworkImage}
									/>
									<Text>{artwork.name}</Text>
									{artwork.technique && <Text>{artwork.technique}</Text>}
									{artwork.dimensions_height && artwork.dimensions_width && (
										<Text>{`${artwork.dimensions_height}x${artwork.dimensions_width}cm`}</Text>
									)}
									{styles.artworkStatus && (
										<Text style={styles.artworkStatus}>
											{artwork.owner_status}
										</Text>
									)}
									{portfolio.show_prices && (
										<Text>{`${artwork.price} ${artwork.currency}`}</Text>
									)}
									{(artwork.object_type === 'VIDEO' ||
										artwork.object_type === 'PERFORMANCE' ||
										artwork.object_type === 'ANIMATION') &&
										artwork.video_url && <Text>{artwork.video_url}</Text>}
								</View>
							))}
						</View>
					</View>
				</Page>
			)}
			{portfolio.subcollection &&
				portfolio.subcollection.map((subcollection) => (
					<>
						<Page style={styles.page} size={'A4'}>
							<View style={styles.content}>
								<Text style={styles.textBoldCenter}>{subcollection.name}</Text>
								<Text>{subcollection.description}</Text>
								{!subcollection?.main_picture?.includes('default_image') && (
									<Image
										src={getHttpRequestDefaultOptions(
											subcollection.main_picture
										)}
										style={styles.coverImage}
									/>
								)}
							</View>
						</Page>
						<Page style={styles.page} size={'A4'}>
							<View style={styles.content}>
								<View style={styles.artworks}>
									{subcollection.elements?.map((artwork, i) => (
										<View style={styles.artwork} key={i}>
											<Image
												src={getHttpRequestDefaultOptions(artwork.main_picture)}
												style={styles.artworkImage}
											/>
											<Text>{artwork.name}</Text>
											{artwork.technique && <Text>{artwork.technique}</Text>}
											{artwork.dimensions_height &&
												artwork.dimensions_width && (
													<Text>{`${artwork.dimensions_height}x${artwork.dimensions_width}cm`}</Text>
												)}
											{styles.artworkStatus && (
												<Text style={styles.artworkStatus}>
													{artwork.owner_status?.toLocaleLowerCase()}
												</Text>
											)}
											{portfolio.show_prices && (
												<Text>{`${artwork.price} ${artwork.currency}`}</Text>
											)}
											{(artwork.object_type === 'VIDEO' ||
												artwork.object_type === 'PERFORMANCE' ||
												artwork.object_type === 'ANIMATION') &&
												artwork.video_url && <Text>{artwork.video_url}</Text>}
										</View>
									))}
								</View>
							</View>
						</Page>
					</>
				))}
			<Page size={[595.28, 40]}>
				<View style={styles.footer}>
					<View style={styles.footerContentOne}>
						<Svg style={styles.logoDatarte}>
							<Path
								d='M3.91016 26.6266H11.1007C17.8573 26.6266 22.6303 22.1945 22.6303 15.9648C22.6303 9.73508 17.8573 5.33398 11.1007 5.33398H3.91016V26.6266ZM6.32766 24.4261V7.53453H11.1007C16.4316 7.53453 20.1818 11.0368 20.1818 15.9648C20.1818 20.9238 16.4316 24.4261 11.1007 24.4261H6.32766Z'
								fill='white'
							/>
							<Path
								d='M26.4395 26.5962H28.9413L20.0233 5.33398H17.5215L8.60352 26.5962H11.1053L14.0779 19.4778H23.4669L26.4395 26.5962ZM14.0779 19.4778L18.7871 8.30512L23.4669 19.4778H14.0779Z'
								fill='white'
							/>
						</Svg>
						<Text style={styles.footerText}>Creado en Datarte.art</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
}
