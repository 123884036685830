//3rd
import React, { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// BL

import { updateEditingCall } from '../../../BusinessLogic/redux/reducers/call';
import {
	useDeleteBenefitElementFromCall,
	usePutCall,
} from '../../../BusinessLogic/hooks/query/useQueryCalls';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import UnderlineHr from '../../atoms/UnderLine/UnderlineHr';
import FieldTextAreaFormik from '../../molecules/Field/FieldTextAreaFormik';
import FieldTextFormik from '../../molecules/Field/FieldTextFormik';
import Cross from '../../../UI/assets/icons/Cross.svg';
import Check from '../../../UI/assets/icons/whiteCheck.svg';
import RadioSelectFormik from '../../molecules/Field/RadioSelectFormik';
import TemplateWithNavBar from '../../templates/TemplateWithNavBar';
import NavbarSecondaryNew from '../Navbar/NavbarSecondaryNew';
import ViewModal from '../../../UI/templates/ViewModal';
import { ReactComponent as DeleteCanGrey } from '../../assets/icons/DeleteCanGrey.svg';
import { useGetSingleCall } from '../../../BusinessLogic/hooks/query/useQueryCalls';

const CallBenefitForm = ({ setShowBenefitForm, initialValues, edit }) => {
	const { t } = useTranslation();
	const { slug } = useParams();
	const { editingCall } = useSelector((state) => state.call);
	const dispatch = useDispatch();
	const { singleCall } = useGetSingleCall(slug);

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const putCallMutation = usePutCall();
	const deleteBenefitElementFromCallMutation =
		useDeleteBenefitElementFromCall();

	const handleSubmit = (values) => {
		let updatedCall = editingCall;

		if (edit) {
			const callBenefitsFiltered = (singleCall?.benefits ?? []).map((benefit) =>
				benefit !== initialValues ? benefit : values
			);

			updatedCall = {
				...editingCall,
				benefits: callBenefitsFiltered,
			};
		} else {
			updatedCall = {
				...editingCall,
				benefits: [...(singleCall?.benefits ?? []), values],
			};
		}

		putCallMutation.mutateAsync(updatedCall).then(() => {
			dispatch(updateEditingCall(updatedCall));
			setShowBenefitForm(false);
		});
	};

	const handleDeleteBenefitElement = (id) => {
		deleteBenefitElementFromCallMutation.mutateAsync(id).then(() => {
			setShowBenefitForm(false);
		});
	};

	return (
		<TemplateWithNavBar>
			{showDeleteModal && (
				<ViewModal
					setConfirmDeleteElement={() =>
						handleDeleteBenefitElement(initialValues.benefit_id)
					}
					closeMenu={setShowDeleteModal}
					modalTitle={'Eliminar Campo'}
					modalTextOne={'¿Seguro que deseas eliminar este campo?'}
				/>
			)}
			<NavbarSecondaryNew
				title={edit ? 'Editar Beneficio' : 'Nuevo Beneficio'}
				textColor='text-purple-50 w-max'
				color='bg-purple-50'
				showDots={false}
				redirect={`/convocatorias/${slug}`}
				textBack={`${t('RegresarConvocatoria')}`}
				textBackDesign='pl-3 text-purple-50'
				arrowLeftColor='purple'
				containerDesign='mb-4'
				optionalIcon={edit && <DeleteCanGrey data-test='delete-icon' />}
				handleClickOptionalIcon={() => setShowDeleteModal(true)}
			/>
			<Formik
				initialValues={initialValues}
				onSubmit={(values) => handleSubmit(values)}
			>
				{(formProps) => (
					<div className='my-3 flex flex-col gap-3'>
						<FieldTextFormik
							label={'Título:'}
							dbName='benefit_name'
							type='Text'
							className={'md:max-w-md'}
							designInput={'!bg-middle_grey-50 h-7'}
						/>

						<RadioSelectFormik
							label={'Tipo:'}
							dbName='benefit_type'
							options={[
								{ label: 'Económico', value: 'ECONOMICO' },
								{ label: 'Otros', value: 'OTRO' },
							]}
							className={'grid grid-cols-2 md:max-w-2xl md:grid-cols-3'}
							labelListDesign={
								'flex flex-col md:grid grid-cols-2 col-start-2 col-end-3 md:col-end-4'
							}
						/>

						<div>
							<div className='mt-2 flex flex-col gap-1 py-2'>
								<TextTitleSub
									title={'Descripción'}
									textColor={'text-purple-50'}
								/>
								<UnderlineHr colour={'purple'} />
							</div>
							<FieldTextAreaFormik
								dbName='benefit_description'
								label='benefit_description'
								designInput={'bg-middle_grey-50'}
							/>
						</div>

						<div className='my-5 flex w-full flex-row justify-center gap-3'>
							<ButtonFab
								label={t('Cancelar')}
								colour='white'
								icon={Cross}
								action={() => setShowBenefitForm(false)}
							/>
							<ButtonFab
								colour='green'
								label={t('Guardar')}
								icon={Check}
								type='submit'
								action={() => formProps.submitForm()}
							/>
						</div>
					</div>
				)}
			</Formik>
		</TemplateWithNavBar>
	);
};

CallBenefitForm.propTypes = {
	setShowBenefitForm: PropTypes.bool,
	initialValues: PropTypes.object,
	edit: PropTypes.bool,
};

CallBenefitForm.defaultProps = {
	initialValues: {
		benefit_name: '',
		benefit_type: 'OTRO',
		benefit_description: '',
		benefit_id: null,
	},
};

export default CallBenefitForm;
