// 3rd Party
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { sortItemsByOrder } from '../../../../BusinessLogic/helpers/sortData';
//BL
import { setCurrentViewExpertPortfolioAndSelection } from '../../../../BusinessLogic/redux/reducers/portfolio';

// UL
import EditExpertArtwork from '../../../molecules/Portfolio/ExpertItems/EditExpertArtwork';
import ExpertSlideItemArtwork from '../../../molecules/Portfolio/ExpertSlideItems/ExpertSlideItemArtwork';
import PortfolioDraggableArtworks from '../../../molecules/Portfolio/PortfolioDraggableArtworks';

export default function ExpertSlideArtworksGroup({
	slideData,
	isEdition,
	isScaled,
	isSelectedSlide,
	designSlideItemArtwork = '',
}) {
	const artworksGroup = slideData?.slideItems;
	const artworksGroupHasContent =
		Array.isArray(artworksGroup) && artworksGroup.length > 0;

	const dispatch = useDispatch();
	const { expertPortfolioViewAndSelection } = useSelector(
		(state) => state.portfolios
	);

	const isReordering = expertPortfolioViewAndSelection.isReordering;

	const isArtworksSectionView =
		expertPortfolioViewAndSelection.currentView === 'artworksSectionView';

	const isVideoArtwork = (artwork) =>
		artwork.video_url &&
		(artwork?.object_type === 'VIDEO' ||
			artwork?.object_type === 'PERFORMANCE' ||
			artwork?.object_type === 'ANIMATION');

	if (isEdition && isSelectedSlide && isArtworksSectionView && isReordering) {
		return (
			<section
				className={`border-black flex cursor-pointer flex-col gap-y-8 border-b-2 px-2 pb-8 pt-4 ${
					isSelectedSlide &&
					isArtworksSectionView &&
					'border-2 border-yellow-50'
				}`}
			>
				{artworksGroupHasContent && (
					<p className='text-right text-xs opacity-70'>Sección - Obras</p>
				)}
				{artworksGroupHasContent && (
					<PortfolioDraggableArtworks
						draggableItems={sortItemsByOrder([...artworksGroup])}
						slideId={slideData.slideId}
					/>
				)}
			</section>
		);
	}
	if (isEdition) {
		return (
			<section
				onClick={() => {
					if (
						expertPortfolioViewAndSelection.selectedSlideId ===
						slideData?.slideId
					) {
						dispatch(
							setCurrentViewExpertPortfolioAndSelection({
								currentView: 'editView',
								selectedElementId: null,
								selectedItemId: null,
								selectedSlideId: null,
							})
						);
						return;
					}
					dispatch(
						setCurrentViewExpertPortfolioAndSelection({
							currentView: 'artworksSectionView',
							selectedElementId: null,
							selectedItemId: null,
							selectedSlideId: slideData?.slideId,
						})
					);
				}}
				className={`border-black flex cursor-pointer flex-col gap-y-8 border-b-2 px-2 pb-8 pt-4 ${
					isSelectedSlide &&
					isArtworksSectionView &&
					'border-2 border-yellow-50'
				}`}
			>
				{artworksGroupHasContent && (
					<p className='text-right text-xs opacity-70'>Sección - Obras</p>
				)}
				{artworksGroupHasContent && (
					<ul className='mx-[5%] flex flex-wrap gap-12 sm:grid md:grid-cols-2'>
						{sortItemsByOrder([...artworksGroup]).map((item, index) => (
							<EditExpertArtwork artworkItemData={item} key={index} />
						))}
					</ul>
				)}
			</section>
		);
	}
	return (
		<section className='mx-[5%] flex flex-col gap-2 md:gap-4'>
			<ul className='grid grid-cols-1 gap-y-8 md:grid-cols-2'>
				{sortItemsByOrder([...artworksGroup]).map((artwork, index) => (
					<ExpertSlideItemArtwork
						key={index}
						artworkElements={artwork.slideItemElements}
						designContainer={`${designSlideItemArtwork} md:max-w-[70%] ${
							index % 2 === 0 ? 'justify-self-start' : 'justify-self-end'
						}
						${isScaled && 'max-w-[70%]'}
						`}
						isScaled={isScaled}
						isVideoArtwork={isVideoArtwork(artwork)}
						videoUrl={artwork.video_url}
					/>
				))}
			</ul>
		</section>
	);
}

ExpertSlideArtworksGroup.propTypes = {
	// contains artworks data to show
	artworksGroup: PropTypes.array,
	// Is edition view or public view
	isEdition: PropTypes.bool,
	//Boolean that determines if the slide is scaled for a preview
	isScaled: PropTypes.bool,
};
