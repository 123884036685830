import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

//BL
import { getDocuments } from '../../../BusinessLogic/redux/reducers/document';

import { getArtworks } from '../../../BusinessLogic/redux/reducers/artwork';
import {
	setSelectedArtworks,
	setSelectedDocuments,
} from '../../../BusinessLogic/redux/reducers/album';
import {
	getCollection,
	getCollections,
	postCollectionAlbumFiles,
} from '../../../BusinessLogic/redux/reducers/collection';
import { useTranslation } from 'react-i18next';

import TextContentPrincipal from '../../../UI/atoms/Text/Content/TextContentPrincipal';
import { ReactComponent as Cross } from '../../../UI/assets/icons/Cross.svg';
import { ReactComponent as Check } from '../../../UI/assets/icons/Check.svg';
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import DocumentTypes from '../document/DocumentTypes';
import ViewModal from '../../../UI/templates/ViewModal';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import FilesSection from '../../../UI/organisms/Album/FilesSection';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
/* icons */

export default function AlbumFiles() {
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { artworks } = useSelector((state) => state.artworks);
	const { documents } = useSelector((state) => state.documents);
	const { appUser } = useSelector((state) => state.appUser);
	const { collectionSingle, newCollection } = useSelector(
		(state) => state.collections
	);
	const { selectedArtworks, selectedDocuments, typeOfFileInAlbumFiles } =
		useSelector((state) => state.album);

	const [currentArtworks, setCurrentArtworks] = useState([]);

	const { organization_user_id } = localStorage;
	const isSubCollection = newCollection.collection_type === 'SUB_COLLECTION';
	const isGalleryUser = appUser.role_company === 'GALLERY';

	const goBackLink = id
		? `/colecciones/${id}`
		: isSubCollection
		? `/colecciones/${newCollection.parent_id}/new`
		: '/colecciones/new';

	useEffect(() => {
		if (!artworks) {
			dispatch(getArtworks());
		}
		if (!documents) {
			dispatch(getDocuments());
		}
		if (artworks) {
			if (isGalleryUser) {
				let artworksFilteredByArtist = [];
				if (!collectionSingle || collectionSingle.length === 0) {
					artworksFilteredByArtist = artworks?.filter(
						(artwork) => artwork.artist_id === Number(newCollection.artist_id)
					);
				} else {
					artworksFilteredByArtist = artworks?.filter(
						(artwork) => artwork.artist_id === collectionSingle.artist_id
					);
				}
				setCurrentArtworks(artworksFilteredByArtist);
			} else {
				setCurrentArtworks(artworks);
			}
		}
	}, [
		dispatch,
		documents,
		artworks,
		isGalleryUser,
		collectionSingle,
		newCollection,
	]);

	let files = typeOfFileInAlbumFiles === 'Obras' ? currentArtworks : documents;

	if (files && Array.isArray(files) && files.length > 0) {
		files = files.map(({ id: type_id_element, ...rest }) => ({
			type_id_element,
			...rest,
		}));
	}

	const getInitialPreselectedFiles = (allFiles, preselectedFiles) => {
		if (allFiles?.length > 0) {
			return allFiles.filter(
				({ type_id_element }) =>
					preselectedFiles.includes(String(type_id_element)) ||
					preselectedFiles.includes(Number(type_id_element))
			);
		} else {
			return [];
		}
	};

	const initialPreSelectedFiles = !id
		? typeOfFileInAlbumFiles === 'Obras'
			? getInitialPreselectedFiles(files, selectedArtworks)
			: getInitialPreselectedFiles(files, selectedDocuments)
		: typeOfFileInAlbumFiles === 'Obras'
		? collectionSingle.collection_artworks
		: collectionSingle.collection_documents;

	const [preSelectedFiles, setPreSelectedFiles] = useState(
		initialPreSelectedFiles
	);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showGoBackModal, setShowGoBackModal] = useState(false);

	const checkExistingFile = (collectionFiles, file) => {
		const collectionFilesIds = [];
		collectionFiles?.forEach((collectionFile) => {
			collectionFilesIds.push(collectionFile.type_id_element);
		});
		return collectionFilesIds.includes(file.type_id_element);
	};

	const toggleFileInPreSelectedFiles = (file) => {
		if (!checkExistingFile(preSelectedFiles, file)) {
			setPreSelectedFiles([...preSelectedFiles, file]);
		} else {
			setPreSelectedFiles(
				preSelectedFiles.filter(
					(preSelectedFile) =>
						preSelectedFile.type_id_element !== file.type_id_element
				)
			);
		}
	};

	const deleteAllSelected = () => {
		setPreSelectedFiles([]);
	};

	const preSelectedFilesIds = (preSelectedFiles) => {
		const preSelectedFilesIds = [];
		if (typeOfFileInAlbumFiles === 'Obras') {
			preSelectedFiles?.forEach((preSelectedFile) => {
				preSelectedFilesIds.push(
					preSelectedFile.artwork_id
						? preSelectedFile.artwork_id
						: preSelectedFile.type_id_element
				);
			});
		} else {
			preSelectedFiles?.forEach((preSelectedFile) => {
				preSelectedFilesIds.push(preSelectedFile.type_id_element);
			});
		}
		return preSelectedFilesIds;
	};

	const confirmSelection = () => {
		if (typeOfFileInAlbumFiles === 'Obras') {
			dispatch(setSelectedArtworks(preSelectedFilesIds(preSelectedFiles)));
		}
		if (typeOfFileInAlbumFiles === 'Documentos') {
			dispatch(setSelectedDocuments(preSelectedFilesIds(preSelectedFiles)));
		}
		navigate(goBackLink);
	};

	const onSubmitSelection = () => {
		let values = {
			artwork: 'empty',
			document: 'empty',
			organization_user_id: organization_user_id,
		};
		if (preSelectedFiles) {
			if (typeOfFileInAlbumFiles === 'Obras') {
				values.artwork = preSelectedFilesIds(preSelectedFiles);
				dispatch(postCollectionAlbumFiles({ values, id })).then(() => {
					dispatch(getCollection(id));
					dispatch(getCollections());
					navigate(`/colecciones/${id}`);
				});
			} else {
				values.document = preSelectedFilesIds(preSelectedFiles);
				dispatch(postCollectionAlbumFiles({ values, id })).then(() => {
					dispatch(getCollection(id));
					navigate(`/colecciones/${id}`);
				});
			}
		}
	};

	const handleGoBackAction = () => {
		if (preSelectedFiles !== initialPreSelectedFiles) {
			setShowGoBackModal(true);
		} else {
			navigate(goBackLink);
		}
	};

	return (
		<TemplateWithNavBar design={'md:h-screen overflow-y-hidden'}>
			<NavbarSecondaryNew
				title={t(typeOfFileInAlbumFiles)}
				textColor='text-green-50'
				color='bg-green-50'
				showDots={false}
				textBack={
					isSubCollection
						? t('RegresarNuevaSubColección')
						: t('RegresarNuevaColección')
				}
				textBackDesign='pl-3 text-green-50'
				arrowLeftColor='green'
				redirect={location.pathname}
				backAction={() => handleGoBackAction()}
			/>

			<div className='md:h-5/6'>
				<div className='mx-auto flex flex-row items-center justify-between md:mx-0 md:w-1/4'>
					<TextContentPrincipal
						design={'text-grey-50'}
						text={`${preSelectedFiles?.length} ${t('ArchivosSeleccionados')}`}
						style={{ fontWeight: 400 }}
					/>
					<div className='flex flex-row gap-4'>
						<Cross
							data-test='cross-icon'
							className='h-6 w-6 cursor-pointer'
							onClick={() => setShowDeleteModal(true)}
						/>
						<Check
							data-test='check-icon'
							className='h-6 w-6 cursor-pointer'
							onClick={() => (!id ? confirmSelection() : onSubmitSelection())}
						/>
					</div>
				</div>
				<div className=' flex h-full flex-col justify-end gap-4 md:flex-row'>
					{preSelectedFiles &&
						Array.isArray(preSelectedFiles) &&
						preSelectedFiles.length > 0 && (
							<div className='flex h-40 max-h-full w-full overflow-y-hidden overflow-x-scroll bg-light_grey-75 md:h-full md:w-1/4 md:overflow-x-hidden md:overflow-y-scroll'>
								<div className='mx-auto flex max-h-full flex-row flex-nowrap gap-5 p-4 md:w-3/5 md:flex-col'>
									{preSelectedFiles.map((file, index) => (
										<div key={index} style={{ aspectRatio: '1/1' }}>
											<DocumentTypes
												document={file}
												preSelectedFiles={preSelectedFiles}
												objectFitProp={
													typeOfFileInAlbumFiles === 'Obras'
														? 'contain'
														: 'fill'
												}
												section='Collection'
											/>
										</div>
									))}
								</div>
							</div>
						)}

					<div
						className={` h-45 flex flex-col gap-4 md:overflow-y-scroll ${
							preSelectedFiles?.length > 0 ? 'md:w-3/4' : 'md:w-full'
						}`}
					>
						<TextContentSecond
							text={t(typeOfFileInAlbumFiles)}
							design='text-grey-50'
							style={{ fontWeight: 600 }}
						/>
						<FilesSection
							files={files ?? []}
							text={typeOfFileInAlbumFiles}
							action={toggleFileInPreSelectedFiles}
							preSelectedFiles={preSelectedFiles}
							objectFitProp={
								typeOfFileInAlbumFiles === 'Obras' ? 'contain' : 'fill'
							}
							section='Collection'
						/>
					</div>
				</div>

				{showDeleteModal && (
					<ViewModal
						setConfirmDeleteElement={deleteAllSelected}
						closeMenu={setShowDeleteModal}
						modalTitle={t('EliminarSelección')}
						modalTextOne={
							typeOfFileInAlbumFiles === 'Documentos'
								? t('SeguroQueDeseasEliminarLosDocumentosSeleccionados')
								: t('SeguroQueDeseasEliminarLasObrasSeleccionadas')
						}
					/>
				)}

				{showGoBackModal && (
					<ViewModal
						setConfirmDeleteElement={() => navigate(goBackLink)}
						closeMenu={setShowGoBackModal}
						modalTitle={t('BorrarCambios')}
						modalTextOne={
							<>
								{t(
									'AlVolverALaSecciónAnteriorVasABorrarTodosLosCambiosQueAcabasDeHacer'
								)}
								<br />
								{t('SeguroQueDeseasEliminarLosCambios')}
							</>
						}
					/>
				)}
			</div>
		</TemplateWithNavBar>
	);
}
