import { Field } from 'formik';
import ParagraphLabel from '../../atoms/Text/Labels/ParagraphLabel';

export default function CheckboxItem({
	design,
	type = 'checkbox',
	name,
	label,
	value,
}) {
	return (
		<Field
			type={type}
			name={name}
			label={label}
			design={design}
			value={value}
			as={CustomNameCheckbox}
		/>
	);
}

const CustomNameCheckbox = ({
	name,
	type,
	label,
	field,
	form,
	design,
	value,
	...props
}) => {
	return (
		<div className='mr-4 mb-4 flex items-center'>
			<input
				className='border-grey mr-1 inline-block h-4 w-4 appearance-none border checked:bg-green-50'
				type={type}
				name={name}
				id={`checkbox${value}`}
				value={value}
				{...field}
				{...props}
			/>
			<label
				className='body-Text2 ml-2 flex cursor-pointer items-center'
				htmlFor={`checkbox${value}`}
			>
				<ParagraphLabel design={`${design} font-light`} text={label} />
			</label>
		</div>
	);
};
