import React from 'react';
import PropTypes from 'prop-types';

import TextContentSecondSkeleton from '../../atoms/Skeleton/TextContentSecondSkeleton';
import ViewTitleAndFieldsSkeleton from './ViewTitleAndFieldsSkeleton';
import TextSubTitleSkeleton from '../../molecules/Skeleton/TextSubTitleSkeleton';
import SkeletonImage from '../../atoms/Skeleton/SkeletonImage';

const TabInfoAndEditSkeleton = ({
	numberOfTabs,
	numberOfTitleAndFields,
	numberOfSingleRow,
	numberOfSingleTitleAndField,
	designOfBottomTitleAndField,
}) => {
	const design = 'h-4 w-24 ';
	let tabsArray = [];
	let titleAndFieldsArray = [];

	for (let i = 0; i < numberOfTabs; i++) {
		tabsArray.push(
			<div key={i}>
				<TextContentSecondSkeleton designTextContentSecondSkeleton={design} />
			</div>
		);
	}

	for (let i = 0; i < numberOfTitleAndFields; i++) {
		titleAndFieldsArray.push(
			<div key={i}>
				<ViewTitleAndFieldsSkeleton
					numberOfSingleRow={numberOfSingleRow}
					numberOfSingleTitleAndField={numberOfSingleTitleAndField}
				/>
			</div>
		);
	}

	return (
		<div className='flex w-full flex-col px-2 md:w-2/3 xl:w-2/3 '>
			<div className='flex flex-row overflow-x-hidden pb-2'>
				<div className='w-26 -mt-2 mr-3 -mb-2 rounded-t-lg bg-light_grey-50'>
					<TextContentSecondSkeleton
						designTextContentSecondSkeleton={design + 'mt-2 ml-1.5'}
					/>
				</div>
				{tabsArray}
			</div>
			<div className='h-screen bg-light_grey-50'>
				{titleAndFieldsArray}
				<div
					className={`bg-light_grey-50 p-8 px-4 ${designOfBottomTitleAndField}`}
				>
					<TextSubTitleSkeleton />
					<SkeletonImage designMainImage={'h-44'} designCaption={'hidden'} />
				</div>
			</div>
		</div>
	);
};

TabInfoAndEditSkeleton.defaultProps = {
	numberOfTabs: 4,
	numberOfTitleAndFields: 2,
	designOfBottomTitleAndField: '',
};

TabInfoAndEditSkeleton.propTypes = {
	/** Number of tabs minus one to be displayed in the component*/
	numberOfTabs: PropTypes.number,
	/** Number of Title And Fields to be displayed in the component*/
	numberOfTitleAndFields: PropTypes.number,
	/** TailWind className for Bottom Title And Field without single row */
	designOfBottomTitleAndField: PropTypes.string,
};

export default TabInfoAndEditSkeleton;
