// 3rd Party
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// BL
import {
	deleteExpertPortfolioSlideItem,
	setCurrentViewExpertPortfolioAndSelection,
	setShowSlideModalExpertPortfolio,
} from '../../../../BusinessLogic/redux/reducers/portfolio';

// UI
import { ReactComponent as CrossGrey } from '../../../assets/icons/CrossGrey.svg';
import { ReactComponent as ImageChangeGray } from '../../../assets/icons/ImageChangeGray.svg';
import { ReactComponent as DeleteCanGrey } from '../../../assets/icons/DeleteCanGrey.svg';
import { ReactComponent as FadersHorizontalGrey } from '../../../assets/icons/FadersHorizontalGrey.svg';
import { ReactComponent as GreyGridView } from '../../../assets/icons/GreyGridView.svg';
import TextContentThird from '../../../atoms/Text/Content/TextContentThird';
import ViewModal from '../../../templates/ViewModal';

const ArtworkSelectionView = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { expertPortfolioViewAndSelection, singlePortfolio } = useSelector(
		(state) => state.portfolios
	);
	const [isLoading, setIsLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const handleDeleteExpertPortfolioSlideItem = (id) => {
		if (!id) return;
		setIsLoading(true);
		dispatch(deleteExpertPortfolioSlideItem(id)).finally(() =>
			setIsLoading(false)
		);
	};

	return (
		<div className='flex w-full max-w-full gap-4 overflow-x-auto px-2 xs:justify-around md:flex-col md:overflow-x-hidden md:p-0 md:pt-4'>
			{showDeleteModal && (
				<ViewModal
					setConfirmDeleteElement={() =>
						handleDeleteExpertPortfolioSlideItem(
							expertPortfolioViewAndSelection.selectedItemId
						)
					}
					closeMenu={setShowDeleteModal}
					modalTitle={t('EliminarObra')}
					modalTextOne={t('SeguroQueDeseasEliminarEstaObra')}
				/>
			)}
			<div
				className='my-auto flex h-10 w-10 cursor-pointer rounded-full  bg-white shadow transition-transform duration-300 hover:scale-105
            md:hidden
            '
			>
				<CrossGrey
					className='mx-auto h-10 w-10 self-center p-2'
					onClick={() => {
						dispatch(
							setCurrentViewExpertPortfolioAndSelection({
								currentView: 'editionView',
								selectedElementId: null,
								selectedItemId: null,
								selectedSlideId: null,
							})
						);
					}}
				/>
			</div>
			<div className='my-2 h-12 w-[0.5px] bg-white shadow md:hidden'></div>
			<div
				className='flex cursor-pointer flex-col rounded-sm p-2 transition-colors duration-300 hover:bg-light_grey-75'
				onClick={() => {
					//Reemplazar a single artwork
					//
					!isLoading &&
						dispatch(
							setShowSlideModalExpertPortfolio({
								isVisible: true,
								modalView: 'changeArtwork',
							})
						);
				}}
			>
				<ImageChangeGray className='mx-auto h-6 w-6' />
				<TextContentThird
					text={'Reemplazar obra'}
					design='text-grey-50 break-words w-min text-center text-xs text-[10px]  my-auto md:mx-auto'
					style={{ lineHeight: '12px' }}
				/>
			</div>
			<div
				className='flex cursor-pointer flex-col rounded-sm p-2 transition-colors duration-300 hover:bg-light_grey-75'
				onClick={() => {
					//reemplazar Image
					!isLoading &&
						dispatch(
							setShowSlideModalExpertPortfolio({
								isVisible: true,
								modalView: 'changeImage',
							})
						);
				}}
			>
				<GreyGridView className='mx-auto h-6 w-6 ' />
				<TextContentThird
					text={'Reemplazar imagen'}
					design='text-grey-50 break-words w-min text-center text-xs text-[10px] my-auto md:mx-auto'
					style={{ lineHeight: '12px' }}
				/>
			</div>
			<div
				className='flex cursor-pointer flex-col rounded-sm p-2 transition-colors duration-300 hover:bg-light_grey-75'
				onClick={() => {
					navigate(
						`/portafolios/portafolio-experto/${singlePortfolio.id}/configuracion/obra`
					);
				}}
			>
				<FadersHorizontalGrey className='mx-auto h-6 w-6 ' />
				<TextContentThird
					text={'Datos'}
					design='text-grey-50 break-words w-min text-center text-xs text-[10px] my-auto md:mx-auto'
					style={{ lineHeight: '12px' }}
				/>
			</div>
			<div
				className='flex cursor-pointer flex-col rounded-sm p-2 transition-colors duration-300 hover:bg-light_grey-75'
				onClick={() => {
					if (!isLoading) {
						setShowDeleteModal(!showDeleteModal);
					}
				}}
			>
				<DeleteCanGrey className='mx-auto h-6 w-6 ' />
				<TextContentThird
					text={'Eliminar'}
					design='text-grey-50 break-words w-min text-center text-xs text-[10px] my-auto md:mx-auto'
					style={{ lineHeight: '12px' }}
				/>
			</div>
		</div>
	);
};

export default ArtworkSelectionView;
