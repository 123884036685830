import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// UI
import OnboardingArtworkForm from '../../UI/organisms/Onboarding/OnboardingArtworkForm';

const FirstArtwork = () => {
	const { t } = useTranslation();
	const { artworks } = useSelector((state) => state.artworks);
	return (
		<OnboardingArtworkForm
			artworks={artworks}
			isSecondArtwork={false}
			subtitleText={t('AgregaTuPrimeraObra')}
		/>
	);
};

export default FirstArtwork;
