//3rd
import { useState } from 'react';
import PropTypes from 'prop-types';

//UI
import { ReactComponent as ArrowDown } from '../../../UI/assets/icons/ArrowDown.svg';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';

/**
 * @description renders a dropdown UI component
 * @param {*} param0
 * @returns
 */
const CardDropdown = ({
	title,
	titleDesign,
	titleStyle,
	children,
	disableDropdown,
}) => {
	const [showContent, setShowContent] = useState(
		disableDropdown ? true : false
	);
	return (
		<div className='w-full overflow-hidden pb-4'>
			<div
				className='flex w-full cursor-pointer justify-between py-2'
				onClick={() => {
					if (!disableDropdown) setShowContent(!showContent);
				}}
			>
				<TextContentPrincipal
					text={title}
					design={titleDesign}
					style={titleStyle}
				/>
				{!disableDropdown && (
					<span
						className={`${
							showContent ? 'rotate-180' : 'rotate-0'
						} duration-300 ease-in-out`}
					>
						<ArrowDown />
					</span>
				)}
			</div>
			{showContent && (
				<div className='w-full transition duration-300 ease-in-out'>
					{children}
				</div>
			)}
		</div>
	);
};

CardDropdown.propTypes = {
	/** Title to display in the component */
	title: PropTypes.string,
	/** Tailwind class that determines the style of the title*/
	titleDesign: PropTypes.string,
	/** Determines the css style of the style */
	titleStyle: PropTypes.object,
	/** Content of the dropdown */
	children: PropTypes.any,
};

CardDropdown.defaultProps = {};

export default CardDropdown;
