import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

/**
 * It renders a list of buttons that are used to switch between the different editable portfolio
 * sections
 * @returns A component that renders a list of buttons.
 */
const SelectionBarEditablePortfolioButtons = ({
	buttonList,
	setIndexEditablePortfolioButton,
	indexEditablePortfolioButton,
}) => {
	const { t } = useTranslation();
	return (
		<div className='flex w-full justify-evenly bg-middle_grey-50'>
			{buttonList.map((button, index) => (
				<button
					className={`flex w-1/2 flex-col items-center rounded p-2 transition-transform hover:scale-105 ${
						indexEditablePortfolioButton === index && 'bg-light_grey-75'
					}`}
					onClick={() => setIndexEditablePortfolioButton(index)}
					key={index}
				>
					{button.icon}
					{button.name && (
						<p className='text-xs text-grey-50'>{t(button.name)}</p>
					)}
				</button>
			))}
		</div>
	);
};

SelectionBarEditablePortfolioButtons.propTypes = {
	/* List of buttons to use in the editable portfolio */
	buttonList: PropTypes.array,
	/* Index of the  active button */
	indexEditablePortfolioButton: PropTypes.number,
	/* Function to set indexEditablePortfolio with the index of the selected button */
	setIndexEditablePortfolioButton: PropTypes.func,
};

export default SelectionBarEditablePortfolioButtons;
