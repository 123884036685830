//3rd party
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';

//UI
import TextContentSecond from '../../../atoms/Text/Content/TextContentSecond';
import { ReactComponent as ArrowLeftPurple } from '../../../assets/icons/ArrowLeftPurple.svg';
import { ReactComponent as ArrowLeftYellow } from '../../../assets/icons/ArrowLeftYellow.svg';
import { ReactComponent as ArrowLeftLightGreen } from '../../../assets/icons/ArrowLeftLightGreen.svg';
import { ReactComponent as ArrowLeftGreen } from '../../../assets/icons/ArrowLeftGreen.svg';

/**
 * @name ButtonLinkBackText
 * @description renders a back button with a text that sends the user to other section.
 * @param {*} param0
 * @returns
 */
function ButtonLinkBackText({
	design,
	textBack,
	textBackDesign,
	redirect,
	action,
	arrowLeftColor,
	isNotAutomaticRedirect,
}) {
	const navigate = useNavigate();
	const renderArrowLeft = (arrowLeftColor) => {
		switch (arrowLeftColor) {
			case 'yellow':
				return <ArrowLeftYellow />;
			case 'light_green':
				return <ArrowLeftLightGreen />;
			case 'green':
				return <ArrowLeftGreen />;
			default:
				return <ArrowLeftPurple />;
		}
	};

	return (
		<div className={design} onClick={action}>
			{redirect ? (
				<Link
					to={redirect}
					className={`flex w-fit-content cursor-pointer flex-row items-center`}
				>
					<figure>{renderArrowLeft(arrowLeftColor)}</figure>
					<TextContentSecond text={textBack} design={textBackDesign} />
				</Link>
			) : (
				<div
					className={`flex w-fit-content cursor-pointer flex-row items-center`}
					onClick={() => !isNotAutomaticRedirect && navigate(-1)}
				>
					<figure>{renderArrowLeft(arrowLeftColor)}</figure>
					<TextContentSecond text={textBack} design={textBackDesign} />
				</div>
			)}
		</div>
	);
}

ButtonLinkBackText.propTypes = {
	/* styles apply to the container */
	design: PropTypes.string,
	/* styles apply to the text next to arrow figure */
	textBackDesign: PropTypes.string,
	/* text next to the arrow to specify the section to return */
	textBack: PropTypes.string,
	/** If exists, redirects to this string. Otherwise, goes back on the history  */
	redirect: PropTypes.string,
	/* trigger a function to execute on onClick */
	action: PropTypes.func,
	/* determine the color of the arrowLeft asset */
	arrowLeftColor: PropTypes.string,
	/* determine whether to return to the previous URL or just execute the action prop */
	isNotAutomaticRedirect: PropTypes.bool,
};

export default ButtonLinkBackText;
