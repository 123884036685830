//3rd party
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

//BL
import { useWindowSize } from '../../../BusinessLogic/hooks/useWindowSize';
//UI
import DocumentAndTitle from '../../../UI/molecules/Document/DocumentAndTitle';
import ImageTitleSimple from '../../../UI/molecules/Image/ImageTitleSimple';
import VideoAndTitle from '../../../UI/molecules/Video/VideoAndTitle';

/**
 * @description render a Document or a Image with title.
 * @param {*} param0
 * @returns
 */
function DocumentTypes({
	document,
	redirect,
	saveLastSectionURL,
	preSelectedFiles,
	showDownloadOptions,
	addCheckToDocument,
	addCheckToVideo,
	addCheckToImage,
	deleteDocumentButton,
	showDeleteOption,
	objectFitProp,
	section,
}) {
	const documentTypesRef = useRef();
	useWindowSize();
	const documentTypesMeasure = String(documentTypesRef?.current?.offsetWidth);
	const checkExistingFile = (collectionFiles, file) => {
		const collectionFilesIds = [];
		collectionFiles.forEach((collectionFile) => {
			collectionFilesIds.push(
				section === 'Collection'
					? collectionFile.type_id_element
					: collectionFile.id
			);
		});
		return collectionFilesIds.includes(
			section === 'Collection' ? file.type_id_element : file.id
		);
	};

	const isExternalVideoUrl = () => {
		if (document?.object_type) {
			return (
				(document.object_type === 'VIDEO' ||
					document.object_type === 'PERFORMANCE' ||
					document.object_type === 'ANIMATION') &&
				document.video_url
			);
		}
		return document.type === 'VIDEO' && document.video_url;
	};

	const renderDocument = () => {
		if (document.type === 'DOCUMENT') {
			return (
				<DocumentAndTitle
					key={document.id}
					id={document.id}
					pdf={document.main_picture_url}
					title={document.name}
					saveLastSectionURL={saveLastSectionURL}
					documentWidth={documentTypesMeasure}
					documentHeight={documentTypesMeasure}
					showDownloadOptions={showDownloadOptions}
					addCheckToDocument={addCheckToDocument}
					addCheckToVideo={addCheckToVideo}
					addCheckToImage={addCheckToImage}
					showDeleteOption={showDeleteOption}
					deleteDocumentButton={deleteDocumentButton}
					redirect={redirect}
					fileSelectedPreview={
						preSelectedFiles && checkExistingFile(preSelectedFiles, document)
					}
				/>
			);
		}
		if (document.type === 'VIDEO' && !document?.video_url) {
			return (
				<VideoAndTitle
					document={document}
					src={document.main_picture_url}
					key={document.id}
					id={document.id}
					title={document.name}
					redirect={redirect}
					showDownloadOptions={showDownloadOptions}
					addCheckToVideo={addCheckToVideo}
					saveLastSectionURL={saveLastSectionURL}
					showDeleteOption={showDeleteOption}
					deleteDocumentButton={deleteDocumentButton}
					documentWidth={documentTypesMeasure}
					documentHeight={documentTypesMeasure}
					fileSelectedPreview={
						preSelectedFiles && checkExistingFile(preSelectedFiles, document)
					}
				/>
			);
		}
		return (
			<ImageTitleSimple
				key={document.id}
				id={document.id}
				redirect={redirect}
				imagen={
					isExternalVideoUrl()
						? document?.video_url
						: document?.main_picture_url
				}
				title={document.name}
				showDeleteOption={showDeleteOption}
				showDownloadOptions={showDownloadOptions}
				addCheckToImage={addCheckToImage}
				deleteDocumentButton={deleteDocumentButton}
				fileSelectedPreview={
					preSelectedFiles && checkExistingFile(preSelectedFiles, document)
				}
				objectFitProp={objectFitProp}
				isVideoImage={isExternalVideoUrl()}
			/>
		);
	};

	return <div ref={documentTypesRef}>{renderDocument()}</div>;
}

DocumentTypes.propTypes = {
	/* contains data from documents Section, documents, img url, etc */
	document: PropTypes.object,
	/* url redirect to specific document */
	redirect: PropTypes.string,
	/**  bool that handle to save the actual specific location */
	saveLastSectionURL: PropTypes.bool,
	/* Object that contains the preSelectedFiles */
	preSelectedFiles: PropTypes.array,
	/**String prop to determinate in which section the component is */
	section: PropTypes.string,
};

DocumentTypes.defaultProps = {
	saveLastSectionURL: false,
	objectFitProp: 'fill',
};

export default DocumentTypes;
