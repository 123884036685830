// 3rd party
import { useState, useEffect } from 'react';
import { Dropzone, FileItem } from '@dropzone-ui/react';
import PropTypes from 'prop-types';
// UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';

/**
 * @description Drag and Drop component to upload images
 * @param {*} param0
 * @returns
 */

const DropZoneModal = ({
	showDropZoneModal,
	setDropZoneFiles,
	dropZoneFiles,
	collectAndCreateImage,
	maxFiles,
	setShowDropZoneModal,
	dropzoneMobileMessage,
	dropzoneMessage,
	maxSize,
}) => {
	const [isMobile, setIsMobile] = useState(false);

	const updateFiles = (incomingFiles) => {
		setDropZoneFiles(incomingFiles);
	};

	const handleDelete = (id) => {
		setDropZoneFiles(dropZoneFiles.filter((x) => x.id !== id));
	};

	if (showDropZoneModal) {
		document.body.classList.add('overflow-hidden');
	} else {
		document.body.classList.remove('overflow-hidden');
	}

	useEffect(() => {
		const changeInViewport = () => {
			if (window.innerWidth > 768) {
				setIsMobile(false);
			} else {
				setIsMobile(true);
			}
		};
		window.addEventListener('resize', changeInViewport);
		return () => {
			window.removeEventListener('resize', changeInViewport);
		};
	});

	return (
		<div className='fixed right-0 top-0 z-20 h-full w-full bg-black-50 bg-opacity-70'>
			<div className='m-auto flex h-full w-11/12 items-center md:w-1/2'>
				<div className='flex w-full select-none flex-col gap-4'>
					<Dropzone
						className='p-4 md:h-72'
						onChange={updateFiles}
						value={dropZoneFiles}
						localization={'ES-es'}
						minHeight={'195px'}
						maxHeight={'480px'}
						label={isMobile ? dropzoneMobileMessage : dropzoneMessage}
						accept={'image/*'}
						maxFiles={maxFiles}
						maxFileSize={maxSize ?? 104857600}
						view={'grid'}
					>
						{dropZoneFiles.map((file) => (
							<FileItem
								{...file}
								key={file.id}
								onDelete={handleDelete}
								alwaysActive
								localization={'ES-es'}
								preview
								info
								resultOnTooltip
							/>
						))}
					</Dropzone>
					<div className='flex justify-center'>
						<ButtonFab
							size={dropZoneFiles.length < 1 ? 'medium' : 'large'}
							label={dropZoneFiles.length < 1 ? 'Cerrar' : 'Cargar imágenes'}
							action={() => {
								if (collectAndCreateImage) {
									collectAndCreateImage();
								}
								document.body.classList.remove('overflow-hidden');
								setShowDropZoneModal && setShowDropZoneModal(false);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DropZoneModal;

DropZoneModal.propTypes = {
	/* variable that represents if the modal is visible */
	showDropZoneModal: PropTypes.bool,
	/* function that updated the array of images to uploaded  */
	setDropZoneFiles: PropTypes.func,
	/* array of images to be uploaded */
	dropZoneFiles: PropTypes.array,
	/**  function that takes the array of images and send the request to backend */
	collectAndCreateImage: PropTypes.func,
	/**  maximum number of files allowed in the dropzone */
	maxFiles: PropTypes.number,
	/**Dropzone message for mobile */
	dropzoneMobileMessage: PropTypes.string,
	/**Dropzone message */
	dropzoneMessage: PropTypes.string,
};

DropZoneModal.defaultProps = {
	maxFiles: 10,
	dropzoneMobileMessage: 'Haz click para cargar imágenes',
	dropzoneMessage: 'Suelta las imágenes aquí o haz click para cargar.',
};
