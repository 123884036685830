//3rd party
import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

//BL
import { useDispatch, useSelector } from 'react-redux';
import { app_user_login } from '../../../BusinessLogic/redux/reducers/appUser';
import { resetRedirectRoute } from '../../../BusinessLogic/redux/reducers/routesHistory';
// import { getOnboardingArtist } from '../../../BusinessLogic/redux/reducers/onboarding';

//UI
import TextContentThird from '../../../UI/atoms/Text/Content/TextContentThird';
import FieldTextFormik from '../../../UI/molecules/Field/FieldTextFormik';
import FieldPasswordFormik from '../../../UI/molecules/Field/FieldPasswordFormik';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';

/**
 * @description render login form
 * @param {*} param0
 * @returns
 */
const LoginForm = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const navigate = useNavigate();
	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const { redirectRoute } = useSelector((state) => state.routesHistory);

	const handleChangeFormValues = () => {
		setEmailError('');
		setPasswordError('');
	};

	return (
		<div className='mx-auto w-4/5 pt-24'>
			<Formik
				initialValues={{ email: '', password: '' }}
				validationSchema={Yup.object({
					email: Yup.string()
						.required(t('EmailRequerido'))
						.email(t('EmailValidación')),
					password: Yup.string().required(t('ContraseñaRequerida')),
				})}
				onSubmit={(values) => {
					dispatch(app_user_login(values)).then(async ({ payload }) => {
						if (payload.code === 200) {
							//FIX ME
							//It is necessary to set a timeout as the navigate function is being replaced by the default login behavior.
							setTimeout(() => {
								navigate(redirectRoute);
								dispatch(resetRedirectRoute());
							}, '100');
						} else {
							const error = payload.response.data.message;
							if (error === 'The user does not exist') {
								setEmailError([
									<b>{t('Error')} </b>,
									t('DatosDeRegistroIncorrectos'),
								]);
								setPasswordError('');
							}
							if (error === 'credentials error') {
								setPasswordError([
									<b>{t('ContraseñaIncorrecta')}</b>,
									t('VuelveAIntentarloOSelecciona'),
									<span className='underline'>
										{t('OlvidasteTuContraseña')}
									</span>,
									` ${t('ParaCambiarla')}`,
								]);
								setEmailError('');
							}
						}
					});
				}}
			>
				{(formProps) => (
					<Form onChange={() => handleChangeFormValues()}>
						<FieldTextFormik
							label={t('Email')}
							designContainerInput=''
							dbName='email'
							errorMessageDesign='text-red-500 mt-[6px] body-Text3 !text-xs'
							errors={formProps?.errors?.email}
						/>
						{emailError && (
							<div className='flex w-full justify-end'>
								<TextContentSecond
									text={emailError}
									design='text-red-500 w-40 md:w-56 !text-xs'
								/>
							</div>
						)}
						<FieldPasswordFormik
							label={t('Contraseña')}
							dbName='password'
							type={passwordVisibility ? 'text' : 'password'}
							errorMessageDesign='text-red-500 body-Text3 mt-[6px]'
							passwordVisibility={passwordVisibility}
							setPasswordVisibility={setPasswordVisibility}
							errors={formProps?.errors?.password}
						/>
						{passwordError && (
							<div className='flex w-full justify-end'>
								<TextContentSecond
									text={passwordError}
									design='text-red-500 pb-6 w-40 md:w-56 text-left !text-xs'
								/>
							</div>
						)}
						<div
							className='cursor-pointer'
							onClick={() => navigate('/recuperacion_contrasena')}
						>
							<TextContentSecond
								text={t('OlvidasteTuContraseña')}
								design='w-full text-right underline'
							/>
						</div>
						<div className='mt-4 flex justify-center'>
							<ButtonFabExtendedPrimary text={t('Ingresar')} />
						</div>
						<div className='m-auto mt-4 flex w-9/12 justify-center'>
							<TextContentThird text={t('CheckCuenta')} />
							<Link to='/registro'>
								<TextContentThird
									text={t('Regístrate')}
									design='ml-2 underline'
								/>
							</Link>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

LoginForm.propTypes = {
	/** TailWind className that manages the main container */
	className: PropTypes.string,
};

export default LoginForm;
