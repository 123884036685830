//3rd party
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

//BL

//UI
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import TextSubTitle from '../../../UI/molecules/Text/TextSubTitle';
import Cross from '../../assets/icons/Cross.svg';
import Check from '../../assets/icons/whiteCheck.svg';
import FieldPasswordFormik from '../../molecules/Field/FieldPasswordFormik';
/**
 * @description section that uses formik to edit the Perfil Password
 * @param {*} param0
 * @returns
 */
const SectionEditPassword = ({
	colorHr,
	sectionTitle,
	onSubmitAction,
	setTurnEdit,
	id,
}) => {
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
		useState(false);

	const errorMessageDesign = 'text-red-400 body-Text3';
	return (
		<div className='flex w-full flex-col '>
			<TextSubTitle
				text={sectionTitle}
				hasEdit={false}
				hasAdd={false}
				colour={colorHr}
			/>
			<div>
				<Formik
					enableReinitialize
					initialValues={{
						password: '',
						confirm_password: '',
					}}
					validationSchema={Yup.object({
						password: Yup.string()
							.required('NuevaContraseñaRequerida')
							.min(8, 'ContraseñaMuyCorta'),
						confirm_password: Yup.string()
							.required('ConfirmarNuevaContraseña')
							.when('password', (password, schema) => {
								return schema.test({
									test: (confirm_password) => confirm_password === password,
									message: 'ContraseñaNoConcuerda',
								});
							}),
					})}
					onSubmit={(values) => {
						let input = { values, id };
						onSubmitAction(input);
						setTurnEdit(false);
					}}
				>
					{(formProps) => (
						<Form>
							<FieldPasswordFormik
								label='Nueva contraseña'
								dbName='password'
								type={passwordVisibility ? 'text' : 'password'}
								errorMessageDesign={errorMessageDesign}
								textInlineStyle={{ fontWeight: 300 }}
								errors={formProps.errors?.password}
								passwordVisibility={passwordVisibility}
								setPasswordVisibility={setPasswordVisibility}
							/>
							<FieldPasswordFormik
								label='Confirmar nueva contraseña'
								dbName='confirm_password'
								type={confirmPasswordVisibility ? 'text' : 'password'}
								errorMessageDesign={errorMessageDesign}
								textInlineStyle={{ fontWeight: 300 }}
								labelDesign='w-24 sm:w-max md:w-24 xl:w-max'
								errors={formProps.errors?.confirm_password}
								confirmPasswordVisibility={confirmPasswordVisibility}
								setConfirmPasswordVisibility={setConfirmPasswordVisibility}
							/>
							<div className='my-5 flex w-full flex-row justify-center gap-3'>
								<ButtonFab
									label='Cancelar'
									colour='white'
									action={() => setTurnEdit(false)}
									icon={Cross}
								/>
								<ButtonFab
									colour='green'
									label='Guardar'
									icon={Check}
									type='submit'
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

SectionEditPassword.propTypes = {
	/** title of the section*/
	sectionTitle: PropTypes.string,
	/** info used to create the report*/
	informationArray: PropTypes.array,
	/** action on submit */
	onSubmitAction: PropTypes.func,
	/** arrays of objects with labels and fields*/
	initialValues: PropTypes.object,
	/** Color of Header in Subtitle */
	colorHr: PropTypes.string,
	/** check */
	setTurnEdit: PropTypes.func,
	/** id of the element */
	id: PropTypes.number,
	/* ValidationSchema used to manage error messages */
	validationSchema: PropTypes.object,
};

export default SectionEditPassword;
