export const PAYMENTS_EN = {
	Suscrito: 'Subscribed',
	IrAlInicio: 'Go to home',
	Planes: 'Plans',
	CreaTuCuentaDeArtista: 'Create your artist account',
	SeleccionaUnPlan: 'Select a plan',
	PlanMensual: 'Monthly plan',
	PlanAnual: 'Annual plan',
	TienesUnCupondeDescuento: 'Do you have a discount coupon?',
	QuieresObtenerUnCuponDeDescuento:
		'Do you want to get a discount coupon? You can get a free account by partnering with us. We partner with other art organizations to increase the impact of calls. Contact us expressing your partnership motivation at the email ',

	MejorOferta: 'Best offer',
	Suscribirse: 'Subscribe',
	EstoIncluye: 'This includes:',
	AplicanTerminosCondiciones: '*terms and conditions apply',
	EstaSuscripcionSeraRenovada:
		'*this subscription will be automatically renewed every year.',
	PlanGratis: 'Free',
	PlanPremium: 'Membership',
	ArtistaIndividual: 'Individual artist',
	PorAnualidad: 'per year',
	PorSiempre: 'forever',
	PorMes: 'per month',
	PrecioPorDemanda: '*Price on demand',
	ContactoDatarte: 'at contact@datarte.art',
	EncuentraNuevasOportunidadesYPostulateALasConvocatorias:
		'Find new opportunities and apply to calls',
	AplicaAConvocatoriasIlimitadasDesdeLaApp:
		'Apply to unlimited calls from the app',
	DescubreNuevasOportunidadesCadaSemana:
		'Discover new opportunities every week',
	CreaPortafoliosVirtualesPersonalizados:
		'Create personalized virtual portfolios',
	OrganizaTusObrasPorColeccionesEnLaNube:
		'Organize your works by collections in the cloud',

	TeAyudamosAAumentarLasPosibilidadesDeGanarteLasOportunidades:
		'We help you increase your chances of winning opportunities',
	ApoyoEnLasOportunidadesQueQuieresGanarte:
		'Support in the opportunities you want to win:',
	MentoriaDeApoyo: 'Support mentoring',
	ChatDeApoyo: 'Support chat',

	OptimizacionDeTuPerfilComoArtista: "Artist's profile optimization:",
	CursosVirtualesEnVivo: 'Live virtual courses',
	CursosVirtualesPregrabadosConLecturasYHojasDeTrabajo:
		'Pre-recorded virtual courses with readings and worksheets',

	AccesoAnticipadoYPrioritarioDeLasOportunidades:
		'Early and priority access to opportunities',
	NewsletterConLasUltimasTendenciasReferentesDestacadosYPreciosActualesDeMercado:
		'Newsletter with the latest trends, outstanding references and current market prices',
	NewsletterConListaDeEventosYExposiciones:
		'Newsletter with list of events and exhibitions',
	MentoriaGrupalDeSeguimientoCreativo: 'Group mentoring for creative follow-up',
	HazteMiembro: 'Become a member!',
	Aceptamos10MiembrosNuevosAlMes: 'We accept 10 new members per month',
	PlanActivo: 'Active plan',
	FechaDeVencimiento: 'Expiration date',
	CancelarPlan: 'Cancel plan',
	CancelarPlanAnual: 'Cancel Annual membership',
	SeguroCancelarPlan: 'Are you sure you want to cancel your plan?',
	SiCancelasPlan:
		'If you decide to cancel, you will lose all available benefits for the remainder of the current year. Please note that the payment made is not refundable.',
	PlanCancelado: 'Plan canceled',
	PlanCanceladoText: 'Your plan has been successfully canceled.',
	EligeTuPlan: 'Choose your plan',
	RegresarConfiguracion: 'Back to settings',
	CambiaPlan: 'Change plan',
	SeguroCambiaPlan: 'Are you sure you want to change your plan?',
	SiCambiaPlan:
		'If you decide to change, you will lose all the benefits available for the rest of the current year. Keep in mind that the payment made is not refundable',

	UnUnicoPago: '*one-time payment per year. Non-refundable.',
};
