//3rd
import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

//UI
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

/**
 * @description renders a card with an image beside a title and a description
 * @param {*} param0
 * @returns
 */
const CardTextIcon = ({
	title,
	titleStyle,
	description,
	image,
	titleDesign,
	descriptionDesign,
	componentDesign,
}) => {
	return (
		<div className={`flex items-start gap-4 py-4 ${componentDesign}`}>
			{image && (
				<LazyLoadImage
					alt={title}
					src={image}
					effect='blur'
					wrapperClassName='overflow-hidden w-16 aspect-square rounded-full flex items-center lazyLoadingImages'
					className='object-cover'
					delayTime='0'
					threshold='800'
				/>
			)}
			<div className='flex w-full flex-col gap-2'>
				<TextContentSecond
					text={title}
					design={titleDesign}
					style={titleStyle}
				/>
				<TextContentSecond text={description} design={descriptionDesign} />
			</div>
		</div>
	);
};

CardTextIcon.propTypes = {
	/** Title text to display in the card */
	title: PropTypes.string,
	/** Title style */
	titleStyle: PropTypes.object,
	/** Description test to display in the card */
	description: PropTypes.string,
	/**Src of the image of the card */
	image: PropTypes.string,
	/**Tailwind class that defines the design of the title text */
	titleDesign: PropTypes.string,
	/**Tailwind class that defines the design of the description text */
	descriptionDesign: PropTypes.string,
	/**Tailwind class that defines the design of the component container */
	componentDesign: PropTypes.string,
};

CardTextIcon.defaultProps = {
	titleDesign: 'font-semibold',
	titleStyle: { fontWeight: '600' },
	descriptionDesign: '',
	componentDesign: 'w-auto',
};

export default CardTextIcon;
