import React from 'react';

const ButtonSwitch = ({ switched, setSwitched, onSwitchAction }) => {
	const handleSwitch = () => {
		if (setSwitched) {
			setSwitched((state) => !state);
		}
		if (onSwitchAction) {
			onSwitchAction();
		}
	};

	return (
		<button
			onClick={() => handleSwitch()}
			data-test='switch-button'
			type='button'
			className={`relative h-7 w-14 rounded-full
                        ${switched ? 'bg-green-50' : 'bg-grey-50'}
                        after:content-[' '] after:absolute after:top-1 after:h-5 after:w-5 after:rounded-full after:bg-white 
                        ${switched ? 'after:right-1' : 'after:left-1'}
                    `}
		/>
	);
};

export default ButtonSwitch;
