//3rd party
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';

//UI
import { ListItem } from '@material-ui/core';
import ListItemText from '../../../UI/atoms/Inputs/ListItemText';

/**
 * @description Select element for Formik
 * @param {*} param0
 * @returns
 */

const CheckboxSelectFormik = ({ label, dbName, options, key }) => {
	return (
		<div>
			<ListItem
				key={key}
				style={{
					paddingLeft: '0px',
					paddingRight: '0px',
					justifyContent: 'space-between',
				}}
			>
				<ListItemText
					primary={label}
					design='body-Text2 w-36'
					style={{ fontWeight: 400 }}
				/>
				<div className='flex w-40 flex-col md:w-56'>
					{options.map((o, i) => (
						<label className='body-Text2 flex px-1 ' key={i}>
							<Field type='checkbox' name={dbName} value={o.id} key={i} />
							<p className='w-32 px-2'>{o.label}</p>
						</label>
					))}
				</div>
			</ListItem>
			<ErrorMessage name={dbName} component='div' className='text-red-400' />
		</div>
	);
};

CheckboxSelectFormik.propTypes = {
	/** label of the select */
	label: PropTypes.string,
	/** This fields connects the data. Please use the same name that on the database for simplicity */
	dbName: PropTypes.string,
	/** Array with the options that will get displayed */
	options: PropTypes.array,
	/** key to differentiate in a list */
	key: PropTypes.number,
	/** selected values */
	selectedValues: PropTypes.array,
};

export default CheckboxSelectFormik;
