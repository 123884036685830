//3rd party
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
	useGetSingleCall,
	useGetSingleCallPublic,
} from '../../../BusinessLogic/hooks/query/useQueryCalls';

//UI
import SingleCallView from './SingleCallView';
import SingleCallForm from './SingleCallForm';
import PortfolioLoading from '../../../UI/organisms/Loading/PortfolioLoading';

/**
 * @description renders single call view or form according to the call status
 * @param {*} param0
 * @returns
 */
const SingleCall = () => {
	const { slug } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { appUser } = useSelector((state) => state.appUser);
	const isOrganizationUser = appUser?.role_company === 'ORGANIZATION';
	const { singleCall } = useGetSingleCall(slug);
	const { singleCallPublic } = useGetSingleCallPublic(slug);

	const statusCall = singleCall?.status || singleCallPublic?.status;

	const [callToDisplay, setCallToDisplay] = useState(null);
	const [isEditing, setIsEditing] = useState(false);

	const isOrganizationCreatedCall =
		appUser.organization_user_id === singleCall?.organization_user_id;

	const renderCallView = () => {
		if (statusCall === 'DRAFT' || isEditing) {
			if (isOrganizationUser && isOrganizationCreatedCall) {
				return <SingleCallForm isEditing={isEditing} />;
			} else {
				navigate('/convocatorias');
				return;
			}
		} else if (statusCall === 'CLOSED' || statusCall === 'OPEN') {
			return (
				<SingleCallView
					callToDisplay={callToDisplay}
					setIsEditing={setIsEditing}
				/>
			);
		} else {
			return navigate('/convocatorias');
		}
	};

	useEffect(() => {
		if (!appUser.token) {
			setCallToDisplay(singleCallPublic);
		} else if (appUser.token) {
			setCallToDisplay(singleCall);
		}
		if (isOrganizationUser && singleCall && !isOrganizationCreatedCall) {
			navigate('/convocatorias');
		}
	}, [
		appUser,
		singleCall,
		singleCallPublic,
		dispatch,
		slug,
		isOrganizationCreatedCall,
		navigate,
		isOrganizationUser,
	]);

	return (
		<>
			{callToDisplay && callToDisplay.status && <>{renderCallView()}</>}
			{!callToDisplay && <PortfolioLoading />}
		</>
	);
};

export default SingleCall;

SingleCall.propTypes = {};
