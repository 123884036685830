import React from 'react';
import PropTypes from 'prop-types';

import { LazyLoadImage } from 'react-lazy-load-image-component';

/**
 * It takes in a picture, name, width, and height, and returns a lazy loaded image with the picture,
 * name, width, and height as props
 * @returns A LazyLoadImage component with the src, alt, and className props.
 */
const ImageElementArtwork = ({ picture, name, heightElement }) => {
	return (
		<LazyLoadImage
			src={picture}
			alt={name}
			height={'100%'}
			style={{ height: heightElement, width: 'fit-content' }}
		/>
	);
};
ImageElementArtwork.propTypes = {
	/** Pass the url of the image to the component */
	picture: PropTypes.string,
	/** Pass the name to the component */
	name: PropTypes.string,
	/** Pass  height to the element*/
	heightElement: PropTypes.string,
};

export default ImageElementArtwork;
