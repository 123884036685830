import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import { useNavigate } from 'react-router-dom';

// BL
import { compressAndResizeImage } from '../../BusinessLogic/helpers/imageSize';
import { createMainImage } from '../../BusinessLogic/redux/reducers/createMainImage';
import { putArtist } from '../../BusinessLogic/redux/reducers/artist';

// UI
import ImageOnboardingUserProfile from '../../UI/assets/images/ImageOnboardingUserProfile.jpg';
import ImageFullScreen from '../../UI/molecules/Image/ImageFullScreen';
import TextTitlePrincipal from '../../UI/atoms/Text/Titles/TextTitlePrincipal';
import ButtonFab from '../../UI/atoms/Buttons/FAB/ButtonFab';
import ButtonWithTextIcon from '../../UI/molecules/Buttons/WithIcon/ButtonWithTextIcon';
import ImageChangeWhite from '../../UI/assets/icons/ImageChangeWhite.svg';
import { ReactComponent as CaretDownGreen } from '../../UI/assets/icons/CaretDownGreen.svg';
import CheckIcon from '../../UI/assets/icons/whiteCheck.svg';
import SpinnerWhite from '../../UI/assets/icons/loadingSpinnerSmallWhite.svg';
import DropZoneModal from '../../UI/molecules/DropZone/DropZoneModal';

const OnboardingUserProfile = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { appUser } = useSelector((state) => state.appUser);
	const { artworks } = useSelector((state) => state.artworks);
	const artistsStatus = useSelector((state) => state.artists.status);
	const createImageStatus = useSelector((state) => state.createImage.status);

	const [showAdvancedForm, setShowAdvancedForm] = useState(false);
	const [showDropZoneModal, setShowDropZoneModal] = useState(false);
	const [dropZoneFiles, setDropZoneFiles] = useState([]);
	const [mainImageValues, setMainImageValues] = useState([]);

	const createMainImageFunction = (mainImageArray) => {
		mainImageArray.forEach(async (image) => {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			let values = {
				small_file,
				medium_file,
				large_file,
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
				type: 'ARTIST',
				type_id: String(appUser.main_artist_id),
				organization_user_id: appUser.organization_user_id,
				file_type: 'IMAGE',
			};
			setMainImageValues(values);
		});
	};

	const collectAndCreateImage = () => {
		createMainImageFunction(dropZoneFiles);
		setShowDropZoneModal(false);
	};

	const handleContinue = () => {
		if (artworks.code === 0) {
			navigate('/bienvenida/primera-obra');
		} else if (!artworks.code) {
			navigate('/bienvenida/segunda-obra');
		} else {
			navigate('/');
		}
	};

	const handleSubmit = (values) => {
		const input = { values: { biography: values.biography }, id: values.id };
		dispatch(putArtist(input));
		dispatch(createMainImage(mainImageValues));
		handleContinue();
	};

	return (
		<div className='flex flex-col md:flex-row'>
			{showDropZoneModal && (
				<DropZoneModal
					showDropZoneModal={showDropZoneModal}
					setShowDropZoneModal={setShowDropZoneModal}
					dropZoneFiles={dropZoneFiles}
					collectAndCreateImage={collectAndCreateImage}
					setDropZoneFiles={setDropZoneFiles}
					maxFiles={1}
				/>
			)}
			<div className='md:hidden'>
				<LazyLoadImage
					src={ImageOnboardingUserProfile}
					width='100%'
					height='100%'
					effect='blur'
					wrapperClassName={` overflow-hidden flex items-center lazyLoadingImages`}
					delayTime='0'
					threshold='800'
					className='max-h-[20vh] object-cover '
				/>
			</div>
			<ImageFullScreen
				image={ImageOnboardingUserProfile}
				className={' hidden md:block md:h-screen md:w-1/2'}
			/>
			<div className='flex flex-col justify-center p-8  pb-28 md:h-[100vh] md:w-1/2  md:items-center md:pb-8 '>
				<TextTitlePrincipal
					design={'text-center'}
					title={t('HazteConocer')}
					textColor={'text-green-50'}
				/>
				<p className='body-Text2 p-8 text-center '>
					{t('ExploraNuevasOportunidadesEnNuestraAplicación')}
				</p>
				<div className='flex w-full  justify-center'>
					<ButtonFab
						action={() => setShowDropZoneModal(true)}
						size={'large'}
						label={t('AñadirImagenDePerfil')}
						icon={ImageChangeWhite}
					/>
				</div>
				<p className='body-Text3 flex w-full justify-center p-4'>
					{t('TamañoMáximoSoportadoDeLaImagenEsDe100MB')}
				</p>
				{dropZoneFiles.length === 1 && (
					<p className='body-Text2 flex w-full justify-center p-4'>
						{t('ImagenAgregada')}
						<span className='px-2 text-green-50'>
							{dropZoneFiles[0]?.file.name}
						</span>
					</p>
				)}

				<Formik
					enableReinitialize
					initialValues={{
						biography: '',
						id: appUser.main_artist_id,
					}}
					onSubmit={(values) => {
						handleSubmit(values);
					}}
				>
					<Form className='w-full'>
						{showAdvancedForm && (
							<div className='w-full'>
								<h3 className='w-full text-left text-green-50 '>
									{t('EscribeTuBiografía')}
								</h3>
								<p className='body-Text2 py-4'>
									{t('OfreceInformaciónSobreQuiénEres')}
								</p>
								<Field
									as='textarea'
									className='h-[10rem] w-full bg-middle_grey-50'
									type='text'
									name='biography'
								/>
							</div>
						)}
						<div className=' grid-col grid w-full justify-items-center  gap-1'>
							<ButtonWithTextIcon
								text={t('OpcionesAvanzadas')}
								contentDesign={'text-green-50'}
								design='rounded mx-auto py-8'
								action={() => setShowAdvancedForm(!showAdvancedForm)}
								icon={
									<CaretDownGreen
										className={
											showAdvancedForm && '-rotate-180 transform transition'
										}
									/>
								}
							/>
						</div>
						<div className='flex justify-center'>
							<div className='grid w-full grid-cols-4 gap-2 xl:w-[60%]'>
								<span className='h-2 rounded border bg-green-50'></span>
								<span className='h-2 rounded border bg-light_grey-75'></span>
								<span className='h-2 rounded border bg-light_grey-75'></span>
								<span className='h-2 rounded border bg-light_grey-75'></span>
							</div>
						</div>

						<div className='flex w-full flex-row justify-evenly gap-1 pt-8'>
							<ButtonFab
								label={t('SaltarPasos')}
								design={'text-gray-400 filter-none'}
								size={'medium'}
								colour={'white'}
								action={() => navigate('/')}
							/>
							<ButtonFab
								disabled={
									createImageStatus === 'loading' || artistsStatus === 'loading'
										? true
										: false
								}
								design={'drop-shadow-lg'}
								colour='green'
								size={'medium'}
								label={
									createImageStatus === 'loading' || artistsStatus === 'loading'
										? t('Guardando')
										: t('Continuar')
								}
								icon={
									createImageStatus === 'loading' || artistsStatus === 'loading'
										? SpinnerWhite
										: CheckIcon
								}
								type='submit'
							/>
						</div>
					</Form>
				</Formik>
			</div>
		</div>
	);
};

export default OnboardingUserProfile;
