import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ENDPOINTS_STRIPE } from '../../../BusinessLogic/helpers/routes';
import { fetchPostDataWithUser } from '../../../BusinessLogic/hooks/fetchPostDataWithUser';

import PaymentBenefit from './PaymentBenefit';
import WarningModal from '../../../Pages/sections/postulation/WarningModal';

import { ReactComponent as StarPaymentIcon } from '../../assets/icons/StarPayment.svg';
import { getAppUser } from '../../../BusinessLogic/redux/reducers/appUser';
import { fetchDeleteDataWithUser } from '../../../BusinessLogic/hooks/fetchDeleteWithUser';
import { showActivePlanModalKey } from '../../../BusinessLogic/data/ArtistSingleData';

const PaymentCard = ({
	name,
	role,
	price,
	duration,
	benefits,
	best_choice,
	price_id,
	subtitle,
	footer,
	isRedirect,
	className = '',
	notes,
}) => {
	const dispatch = useDispatch();
	const { appUser } = useSelector((state) => state.appUser);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [showWarningModal, setShowWarningModal] = useState(false);

	const activePlanModalKey = JSON.parse(
		localStorage.getItem(showActivePlanModalKey)
	);

	const organizationUserId = appUser.organization_user_id;
	const activePlan = appUser.subscription_type;
	const isActivePlan = activePlan === name;
	const isActivePlanFree = activePlan === 'PlanGratis';

	const premiumBenefits = benefits?.premium;
	const commonBenefits = benefits?.common;
	const bestChoiceBackground = best_choice ? 'bg-light_grey-75' : '';

	const onClickSubscription = () => {
		if (!isActivePlanFree) {
			setShowWarningModal(true);
		} else {
			handleSendSubscription();
		}
	};

	const handleSendSubscription = async () => {
		try {
			setIsLoading(true);

			let response = null;

			if (!isActivePlanFree) {
				response = await fetchDeleteDataWithUser(
					ENDPOINTS_STRIPE.DELETE_SUBSCRIPTION(organizationUserId)
				);
			} else {
				response = await fetchPostDataWithUser(
					ENDPOINTS_STRIPE.POST_CREATE_CHECKOUT_SESSION,
					{
						price_id: price_id,
						plan_name: name,
						organization_user_id: organizationUserId,
					}
				);
			}

			if (name === 'PlanPremium') {
				localStorage.setItem(showActivePlanModalKey, JSON.stringify(true));
			}

			dispatch(getAppUser());

			if (response?.url) {
				window.location.href = response.url;
			} else {
				navigate('/');
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<article
			className={`rounded border border-gray-100 bg-white shadow-lg  ${className}`}
		>
			{showWarningModal && (
				<WarningModal
					title={t('CambiaPlan')}
					textPrimary={t('SeguroCambiaPlan')}
					textSecondary={t('SiCambiaPlan')}
					closeModalFunction={setShowWarningModal}
					mainButtonTitle={t('Aceptar')}
					secondaryButtonTitle={t('Cancelar')}
					mainButtonAction={handleSendSubscription}
				/>
			)}
			<div className={`px-5 py-8 ${bestChoiceBackground}`}>
				{best_choice && (
					<span className=' mb-4 mr-auto flex max-w-max items-center  gap-2 rounded  bg-yellow-50 p-2 text-xs text-white'>
						<StarPaymentIcon />
						{t('MejorOferta')}
					</span>
				)}
				<h2 className=' text-3xl font-bold text-black-50'>{t(name)}</h2>
				<h3 className='text-sm font-bold text-green-50'>{t(role)}</h3>
				<h3 className='mt-2 text-2xl font-bold text-black-50'>{t(price)}</h3>
				<h4 className='  mb-3 font-light text-black-50'>{t(duration)}</h4>

				{isRedirect && (
					<button
						onClick={onClickSubscription}
						className='w-full max-w-[190px] rounded  bg-green-50 px-2 py-3 text-sm text-white shadow-sm disabled:opacity-50  lg:hover:opacity-60'
						disabled={isLoading || (isActivePlan && !activePlanModalKey)}
					>
						{t(
							isActivePlan && !activePlanModalKey ? 'Suscrito' : 'Suscribirse'
						)}
					</button>
				)}
			</div>
			<div className='space-y-5 px-5 pb-5 pt-3  text-start text-sm font-extralight text-black-50'>
				<h4>{t(subtitle)}</h4>
				<ul className='space-y-3'>
					{premiumBenefits?.map((benefit, index) => {
						if (typeof benefit === 'object') {
							return (
								<h4 className='font-semibold' key={index}>
									{t(benefit.title)}
								</h4>
							);
						}
						return <PaymentBenefit key={index} benefit={benefit} isPremium />;
					})}
					{commonBenefits?.map((benefit, index) => {
						if (typeof benefit === 'object') {
							return <h4 key={index}>{t(benefit.title)}</h4>;
						}
						return <PaymentBenefit key={index} benefit={benefit} />;
					})}
				</ul>
				<div className='my-3 space-y-3'>
					{footer?.map((text, index) => (
						<h4 key={index}>{t(text)}</h4>
					))}
				</div>
				<div role='note' className='space-y-3'>
					{notes.map((note) => (
						<p className='mb-3 text-xs font-light text-black-50'>{t(note)}</p>
					))}
				</div>
			</div>
		</article>
	);
};

PaymentCard.propTypes = {
	/** Name of the plan */
	name: PropTypes.string,
	/** Role of the plan */
	role: PropTypes.string,
	/** Price of the plan */
	price: PropTypes.string,
	/** Duration of the plan */
	duration: PropTypes.string,
	/** Benefits of the plan */
	benefits: PropTypes.object,
	/** Best choice of the plan */
	best_choice: PropTypes.bool,
	/** Price id of the plan */
	price_id: PropTypes.string,
	/** Subtitle of the plan */
	subtitle: PropTypes.string,
	/** Footer of the plan */
	footer: PropTypes.array,
	/** Redirect of the plan */
	isRedirect: PropTypes.bool,

	className: PropTypes.string,

	notes: PropTypes.array,
};

export default PaymentCard;
