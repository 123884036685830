// 3rd Party
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
	text: {
		fontSize: '13px',
		lineHeight: '1.5',
	},
	smallText: {
		fontSize: '10px',
	},
	containerWithLabel: {
		display: 'flex',
		flexDirection: 'row',
	},
});

export default function DownloadableExpertSlideItemText({
	text,
	label,
	stylesText,
	stylesContainer,
	isSmallText,
}) {
	const { t } = useTranslation();

	return (
		<View style={[styles.containerWithLabel, stylesContainer]}>
			<Text style={[styles.text, stylesText, isSmallText && styles.smallText]}>
				{label && `${t(label)}:`}
				{text}
			</Text>
		</View>
	);
}

DownloadableExpertSlideItemText.propTypes = {
	// text content
	text: PropTypes.string,
	// if is not empty, show a label
	label: PropTypes.string,
	// css styles for text
	stylesText: PropTypes.object,
	// css styles for container
	stylesContainer: PropTypes.object,
	// bool to resize text and label
	isSmallText: PropTypes.bool,
};
