import * as Yup from 'yup';

export const single = {
	created_at: '2023-02-05 13:19:07',
	has_postulation: 0,
	id: 810,
	image_quality: 'MEDIUM',
	is_deleted: false,
	is_general_portfolio: false,
	main_picture_url:
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
	name: 'Clara Valdenebro',
	organization_user_id: 31,
	permission: 'PUBLIC',
	show_date: true,
	show_measures: true,
	show_owner_status: true,
	show_prices: true,
	show_technique: true,
	show_title: true,
	slides: [
		//Cover - order 1 -
		{
			order: 1,
			portfolioId: 810,
			slideId: 61,
			slideItems: [
				{
					order: 1,
					slideId: 61,
					slideItemId: 97,
					slideItemsElements: [
						{
							content: 'Clara Valdenebro',
							order: 1,
							slideItemElementId: 163,
							type: 'title',
						},
						{
							content: 'Portafolio Clara Valdenebro',
							order: 2,
							slideItemElementId: 164,
							type: 'sub_title',
						},
					],
					type: 'title',
				},
				{
					order: 2,
					slideId: 61,
					slideItemId: 98,
					slideItemsElements: [
						{
							content: null,
							order: 3,
							slideItemElementId: 165,
							type: 'text',
						},
					],
					type: 'text',
				},
				{
					order: 3,
					slideId: 61,
					slideItemId: 99,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/7871',
							order: 4,
							slideItemElementId: 166,
							type: 'url',
						},
					],
					type: 'image',
				},
			],
			type: 'cover',
		},
		{
			order: 2,
			portfolioId: 810,
			slideId: 62,
			slideItems: [
				{
					order: 1,
					slideId: 62,
					slideItemId: 100,
					slideItemsElements: [
						{
							content: 'Biografía',
							order: 1,
							slideItemElementId: 167,
							type: 'title',
						},
					],
					type: 'title',
				},
				{
					order: 2,
					slideId: 62,
					slideItemId: 101,
					slideItemsElements: [
						{
							content:
								'La obra de Clara gira al rededor del tema Naturaleza. Artista plástica con más de 4 décadas en el arte, en donde la investigación ha sido una constante en su obra. Las expresiones del mundo Natural están sintetizadas en estas colecciones:\n\n1.paisajes del mar.\n2.paisajes con diverso grado de abstracción.\n3.percepciones y memorias secretas de Naturaleza, en donde la vibrante naturaleza aparece plasmada en una novedosa técnica .\n4. Paisajes de Páramo, neblinas y flora.\n5. Intervención contemporánea año 2021, en obra clásica del maestro Negret.\n\nEn toda la propuesta pictórica plantea la relación con la naturaleza, La cosmovición de lo orgánico , de lo natural, lo ecológico.\nEn estas “colecciones” podemos apreciar siempre la evocación de la naturaleza como fuente inagotable para la investigación y realización de esta Obra.',
							order: 2,
							slideItemElementId: 168,
							type: 'text',
						},
					],
					type: 'text',
				},
				{
					order: 3,
					slideId: 62,
					slideItemId: 102,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5122',
							order: 3,
							slideItemElementId: 169,
							type: 'url',
						},
					],
					type: 'image',
				},
			],
			type: 'textBlockAndImage',
		},
		{
			order: 4,
			portfolioId: 810,
			slideId: 63,
			slideItems: [
				{
					order: 1,
					slideId: 63,
					slideItemId: 103,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 170,
							type: 'url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 171,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 172,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 173,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 174,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 175,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 7,
							slideItemElementId: 176,
							type: 'measures',
						},
						{
							content: '',
							order: 8,
							slideItemElementId: 177,
							type: 'technique',
						},
					],
					type: 'artwork',
				},
			],
			type: 'artworksGroup',
		},
		{
			order: 5,
			portfolioId: 810,
			slideId: 64,
			slideItems: [
				{
					order: 1,
					slideId: 64,
					slideItemId: 104,
					slideItemsElements: [
						{
							content: 'Obra retrospectiva',
							order: 1,
							slideItemElementId: 178,
							type: 'title',
						},
					],
					type: 'title',
				},
				{
					order: 2,
					slideId: 64,
					slideItemId: 105,
					slideItemsElements: [
						{
							content: null,
							order: 2,
							slideItemElementId: 179,
							type: 'text',
						},
					],
					type: 'text',
				},
				{
					order: 3,
					slideId: 64,
					slideItemId: 106,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/7871',
							order: 3,
							slideItemElementId: 180,
							type: 'url',
						},
					],
					type: 'image',
				},
			],
			type: 'cover',
		},
		{
			order: 6,
			portfolioId: 810,
			slideId: 65,
			slideItems: [
				{
					order: 1,
					slideId: 65,
					slideItemId: 107,
					slideItemsElements: [
						{
							content: 'Mares de oro, de sol y de frío',
							order: 1,
							slideItemElementId: 181,
							type: 'subtitle',
						},
					],
					type: 'title',
				},
				{
					order: 2,
					slideId: 65,
					slideItemId: 108,
					slideItemsElements: [
						{
							content:
								'Momentos de fuerza, de color de inmensidad, de toda esa emoción que siempre produce el Mar, como si siempre fuera: “la primera vez”.\n\nHe idealizado su calor y su fuerza interviniendo con Dorado sus destellos de luz, también a veces resalto el mar frío y las sombras con reflejos de Luna.\nEl inmenso e inconmensurable mar en pedacitos de Lienzo como atrapándolo por instantes. \n\nTécnica : acrílico sobre lienzo.',
							order: 2,
							slideItemElementId: 182,
							type: 'text',
						},
					],
					type: 'text',
				},
				{
					order: 3,
					slideId: 65,
					slideItemId: 109,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/7872',
							order: 3,
							slideItemElementId: 183,
							type: 'image_url',
						},
					],
					type: 'image',
				},
			],
			type: 'cover',
		},
		{
			order: 6,
			portfolioId: 810,
			slideId: 66,
			slideItems: [
				{
					order: 1,
					slideId: 66,
					slideItemId: 110,
					slideItemsElements: [
						{
							content: 'Paisajes con diverso grado de abstracción',
							order: 1,
							slideItemElementId: 184,
							type: 'subtitle',
						},
					],
					type: 'title',
				},
				{
					order: 2,
					slideId: 66,
					slideItemId: 111,
					slideItemsElements: [
						{
							content:
								'Esta colección recoge diferentes instantes de paisajes: unos de los páramos su atmósfera y colorido, otros sintetizan llanuras o volcanes o atmósferas tropicales. Otros solo la expresiva síntesis en monocromías de rojos .\nTodos inspirados en la emoción de captar un trozo de la Naturaleza.\nTécnica : mixta con acrílicos sobre lienzo o papel de algodón',
							order: 2,
							slideItemElementId: 185,
							type: 'text',
						},
					],
					type: 'text',
				},
				{
					order: 3,
					slideId: 66,
					slideItemId: 112,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/7873',
							order: 3,
							slideItemElementId: 186,
							type: 'image_url',
						},
					],
					type: 'image',
				},
			],
			type: 'cover',
		},
		{
			order: 6,
			portfolioId: 810,
			slideId: 67,
			slideItems: [
				{
					order: 1,
					slideId: 67,
					slideItemId: 113,
					slideItemsElements: [
						{
							content: 'Percepciones y memorias secretas de la naturaleza',
							order: 1,
							slideItemElementId: 187,
							type: 'subtitle',
						},
					],
					type: 'title',
				},
				{
					order: 2,
					slideId: 67,
					slideItemId: 114,
					slideItemsElements: [
						{
							content:
								'El mundo natural, inspira  nuevamente  a clara como temática en esta serie en un ejercicio intelectual de Abstracción,Este dispendioso  proceso no mengua- más bien acentúa- el evidente origen de los temas, por ello el nombre de esta serie:"Memorias secretas de naturaleza".-lejanías de paisajes y transparencias atmosféricas-estructuras primarias de vegetación, bejucos y lianas.-huellas geológicas:-oro y plata como brotes minerales del subsuelo,como óxidos terrosos, o como brillos de sol y de luna.-sugerencias de humedades ocultas, de vapores nubosos, de neblinas y transparenciasTan precisa temática, expresada con dominio total del color, en nueva  técnica investigada y lograda con aguadas de acrílicos, y laminillas de oro.Especificaciones: el papel o soporte es un papel aglomerado de glucosa, de alto gramaje. La obra consta de 18 cuadros de medida promedio de .45x.45cm.',
							order: 2,
							slideItemElementId: 188,
							type: 'text',
						},
					],
					type: 'text',
				},
				{
					order: 3,
					slideId: 67,
					slideItemId: 115,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5225',
							order: 3,
							slideItemElementId: 189,
							type: 'image_url',
						},
					],
					type: 'image',
				},
			],
			type: 'cover',
		},
		{
			order: 6,
			portfolioId: 810,
			slideId: 68,
			slideItems: [
				{
					order: 1,
					slideId: 68,
					slideItemId: 116,
					slideItemsElements: [
						{
							content: 'Paisajes de Páramo y Flora',
							order: 1,
							slideItemElementId: 190,
							type: 'subtitle',
						},
					],
					type: 'title',
				},
				{
					order: 2,
					slideId: 68,
					slideItemId: 117,
					slideItemsElements: [
						{
							content:
								'Allí donde nace el viento, allí donde las neblinas ocultan las montañas, allí comienza el Arte. El arte hace visible lo efímero para perpetuar la Natura inmortal y plasmar así unos instantes nada más.',
							order: 2,
							slideItemElementId: 191,
							type: 'text',
						},
					],
					type: 'text',
				},
				{
					order: 3,
					slideId: 68,
					slideItemId: 118,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/7874',
							order: 3,
							slideItemElementId: 192,
							type: 'image_url',
						},
					],
					type: 'image',
				},
			],
			type: 'cover',
		},
		{
			order: 6,
			portfolioId: 810,
			slideId: 69,
			slideItems: [
				{
					order: 1,
					slideId: 69,
					slideItemId: 119,
					slideItemsElements: [
						{
							content: 'Intervención a un artista y acción de la naturaleza. ',
							order: 1,
							slideItemElementId: 193,
							type: 'subtitle',
						},
					],
					type: 'title',
				},
				{
					order: 2,
					slideId: 69,
					slideItemId: 120,
					slideItemsElements: [
						{
							content:
								'He incursionado en las prácticas  del ARTE CONTEMPORANEO : \nAnoche interviene en una obra del artista Negrete, mi coterráneo.\nFué algo muy pensado, quise decir muchas cosas, que las dejo a libre interpretación del espectador o receptor del mensaje que conlleva.\nContaré algo del proceso de esta intervención: \nUn día, observé que el cuadro lo estaba habitado  por un pequeño animalito llamado "gorgojo", quien voraz devoraba el cuadro, de la firma del autor hacia arriba.\nLlamó de inmediato mi atención las deliciosas formas zoomorfas y naturales, de inmediato Vi la oportunidad de meterme e incursionar en una nueva propuesta. Lo miré muchas veces, lo retuve en imaginación y comencé a imaginarme un Color, un color que me dijera algo, que significara algo, que saliera del cuadro mismo.  Si, había un rojo estridente, un rojo naranja, un rojo irrumpiendo entre negros y grises en medio de la proporción Aurea del cuadro y su armónica composición, irrumpían nuevas formas hechas ahora por una animalito que encontró allí su "asididero" y que con su asimetría y vibración natural prolongaría la vida de esta nueva obra, y el ROJO que además me decía:\n...Todo sigue, nada puede detenerse cuando La Natura interviene, aunque sea con desangre, significativo del momento que nos está tocando vivir, significante también de una nueva visión y de que el arte siempre tiene que decirnos algo más, para que decifremos el mundo en que estamos. \n',
							order: 2,
							slideItemElementId: 194,
							type: 'text',
						},
					],
					type: 'text',
				},
				{
					order: 3,
					slideId: 69,
					slideItemId: 121,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5227',
							order: 3,
							slideItemElementId: 195,
							type: 'image_url',
						},
					],
					type: 'image',
				},
			],
			type: 'cover',
		},
		{
			order: 6,
			portfolioId: 810,
			slideId: 70,
			slideItems: [
				{
					order: 1,
					slideId: 70,
					slideItemId: 122,
					slideItemsElements: [
						{
							content: 'Años 70s a los 80s (Colección privada)',
							order: 1,
							slideItemElementId: 196,
							type: 'subtitle',
						},
					],
					type: 'title',
				},
				{
					order: 2,
					slideId: 70,
					slideItemId: 123,
					slideItemsElements: [
						{
							content:
								'Con motivo de una Expo.\n\nDespués de unos años, más que receso fueron de investigación y acopio.\nClara nos muestra su obra reciente en Quito, de nuevo su admirable dominio del color. Ahora los paisajes abstractos y los insectos y aves heráldicas han cedido su paso a la búsqueda Borgiana de otro arquetipo de la naturaleza :las flores de hielo, las flores blancas, cromatismo como una Sordina, flores en estancia de Selva húmeda, en fin flores en su vegetal medio de Verde Verdor. Pero siempre el color como protagonista exaltante y constante.\nLa obra de clara no acepta el feismo como expresión contemporánea. “Quiero estar de parte de la vida, para recrear lo bello” , dice Clara con su alegre expresividad . y así es su obra: Una vital recreación de lo bello EN LA BÚSQUEDA DE LO ESENCIAL.\n\nRamón Sarria -Puig.\nCrítico de arte',
							order: 2,
							slideItemElementId: 197,
							type: 'text',
						},
					],
					type: 'text',
				},
				{
					order: 3,
					slideId: 70,
					slideItemId: 124,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5228',
							order: 3,
							slideItemElementId: 198,
							type: 'image_url',
						},
					],
					type: 'image',
				},
			],
			type: 'cover',
		},
		{
			order: 7,
			portfolioId: 810,
			slideId: 71,
			slideItems: [
				{
					order: 1,
					slideId: 71,
					slideItemId: 125,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5134',
							order: 1,
							slideItemElementId: 199,
							type: 'url',
						},
						{
							content: 'Serie : mar y oro',
							order: 2,
							slideItemElementId: 200,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 201,
							type: 'date',
						},
						{
							content: '600000',
							order: 4,
							slideItemElementId: 202,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 203,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 204,
							type: 'state',
						},
						{
							content: '30.0x0x40.0cm',
							order: 7,
							slideItemElementId: 205,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 8,
							slideItemElementId: 206,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5135',
							order: 9,
							slideItemElementId: 207,
							type: 'url',
						},
						{
							content: 'Serie Mar',
							order: 10,
							slideItemElementId: 208,
							type: 'title',
						},
						{
							content: '',
							order: 11,
							slideItemElementId: 209,
							type: 'date',
						},
						{
							content: '600000',
							order: 12,
							slideItemElementId: 210,
							type: 'price',
						},
						{
							content: '',
							order: 13,
							slideItemElementId: 211,
							type: 'materials',
						},
						{
							content: 'VENDIDO',
							order: 14,
							slideItemElementId: 212,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 15,
							slideItemElementId: 213,
							type: 'measures',
						},
						{
							content: '',
							order: 16,
							slideItemElementId: 214,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5136',
							order: 17,
							slideItemElementId: 215,
							type: 'url',
						},
						{
							content: 'Serie: Mar de oro',
							order: 18,
							slideItemElementId: 216,
							type: 'title',
						},
						{
							content: '',
							order: 19,
							slideItemElementId: 217,
							type: 'date',
						},
						{
							content: '1200000',
							order: 20,
							slideItemElementId: 218,
							type: 'price',
						},
						{
							content: '',
							order: 21,
							slideItemElementId: 219,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 22,
							slideItemElementId: 220,
							type: 'state',
						},
						{
							content: '80.0x0x80.0cm',
							order: 23,
							slideItemElementId: 221,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 24,
							slideItemElementId: 222,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5137',
							order: 25,
							slideItemElementId: 223,
							type: 'url',
						},
						{
							content: 'Serie Mar',
							order: 26,
							slideItemElementId: 224,
							type: 'title',
						},
						{
							content: '',
							order: 27,
							slideItemElementId: 225,
							type: 'date',
						},
						{
							content: '1200000',
							order: 28,
							slideItemElementId: 226,
							type: 'price',
						},
						{
							content: '',
							order: 29,
							slideItemElementId: 227,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 30,
							slideItemElementId: 228,
							type: 'state',
						},
						{
							content: '100.0x0x60.0cm',
							order: 31,
							slideItemElementId: 229,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 32,
							slideItemElementId: 230,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5138',
							order: 33,
							slideItemElementId: 231,
							type: 'url',
						},
						{
							content: 'Serie Mar',
							order: 34,
							slideItemElementId: 232,
							type: 'title',
						},
						{
							content: '',
							order: 35,
							slideItemElementId: 233,
							type: 'date',
						},
						{
							content: '400000',
							order: 36,
							slideItemElementId: 234,
							type: 'price',
						},
						{
							content: '',
							order: 37,
							slideItemElementId: 235,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 38,
							slideItemElementId: 236,
							type: 'state',
						},
						{
							content: '30.0x0x30.0cm',
							order: 39,
							slideItemElementId: 237,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 40,
							slideItemElementId: 238,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5139',
							order: 41,
							slideItemElementId: 239,
							type: 'url',
						},
						{
							content: 'Serie Mar',
							order: 42,
							slideItemElementId: 240,
							type: 'title',
						},
						{
							content: '',
							order: 43,
							slideItemElementId: 241,
							type: 'date',
						},
						{
							content: '600000',
							order: 44,
							slideItemElementId: 242,
							type: 'price',
						},
						{
							content: '',
							order: 45,
							slideItemElementId: 243,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 46,
							slideItemElementId: 244,
							type: 'state',
						},
						{
							content: '50.0x0x35.0cm',
							order: 47,
							slideItemElementId: 245,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 48,
							slideItemElementId: 246,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5140',
							order: 49,
							slideItemElementId: 247,
							type: 'url',
						},
						{
							content: 'Serie Mar',
							order: 50,
							slideItemElementId: 248,
							type: 'title',
						},
						{
							content: '',
							order: 51,
							slideItemElementId: 249,
							type: 'date',
						},
						{
							content: '400000',
							order: 52,
							slideItemElementId: 250,
							type: 'price',
						},
						{
							content: '',
							order: 53,
							slideItemElementId: 251,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 54,
							slideItemElementId: 252,
							type: 'state',
						},
						{
							content: '30.0x0x30.0cm',
							order: 55,
							slideItemElementId: 253,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 56,
							slideItemElementId: 254,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5141',
							order: 57,
							slideItemElementId: 255,
							type: 'url',
						},
						{
							content: 'Serie Mar',
							order: 58,
							slideItemElementId: 256,
							type: 'title',
						},
						{
							content: '',
							order: 59,
							slideItemElementId: 257,
							type: 'date',
						},
						{
							content: '0',
							order: 60,
							slideItemElementId: 258,
							type: 'price',
						},
						{
							content: '',
							order: 61,
							slideItemElementId: 259,
							type: 'materials',
						},
						{
							content: 'VENDIDO',
							order: 62,
							slideItemElementId: 260,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 63,
							slideItemElementId: 261,
							type: 'measures',
						},
						{
							content: '',
							order: 64,
							slideItemElementId: 262,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5142',
							order: 65,
							slideItemElementId: 263,
							type: 'url',
						},
						{
							content: 'Serie Mar',
							order: 66,
							slideItemElementId: 264,
							type: 'title',
						},
						{
							content: '',
							order: 67,
							slideItemElementId: 265,
							type: 'date',
						},
						{
							content: '1000000',
							order: 68,
							slideItemElementId: 266,
							type: 'price',
						},
						{
							content: '',
							order: 69,
							slideItemElementId: 267,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 70,
							slideItemElementId: 268,
							type: 'state',
						},
						{
							content: '60.0x0x40.0cm',
							order: 71,
							slideItemElementId: 269,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre cartón',
							order: 72,
							slideItemElementId: 270,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5143',
							order: 73,
							slideItemElementId: 271,
							type: 'url',
						},
						{
							content: 'Colección Serie Mares',
							order: 74,
							slideItemElementId: 272,
							type: 'title',
						},
						{
							content: '',
							order: 75,
							slideItemElementId: 273,
							type: 'date',
						},
						{
							content: '0',
							order: 76,
							slideItemElementId: 274,
							type: 'price',
						},
						{
							content: '',
							order: 77,
							slideItemElementId: 275,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 78,
							slideItemElementId: 276,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 79,
							slideItemElementId: 277,
							type: 'measures',
						},
						{
							content: '',
							order: 80,
							slideItemElementId: 278,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5144',
							order: 81,
							slideItemElementId: 279,
							type: 'url',
						},
						{
							content: 'Colección Serie Mares',
							order: 82,
							slideItemElementId: 280,
							type: 'title',
						},
						{
							content: '',
							order: 83,
							slideItemElementId: 281,
							type: 'date',
						},
						{
							content: '0',
							order: 84,
							slideItemElementId: 282,
							type: 'price',
						},
						{
							content: '',
							order: 85,
							slideItemElementId: 283,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 86,
							slideItemElementId: 284,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 87,
							slideItemElementId: 285,
							type: 'measures',
						},
						{
							content: '',
							order: 88,
							slideItemElementId: 286,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5145',
							order: 89,
							slideItemElementId: 287,
							type: 'url',
						},
						{
							content: 'Colección Serie Mares',
							order: 90,
							slideItemElementId: 288,
							type: 'title',
						},
						{
							content: '',
							order: 91,
							slideItemElementId: 289,
							type: 'date',
						},
						{
							content: '0',
							order: 92,
							slideItemElementId: 290,
							type: 'price',
						},
						{
							content: '',
							order: 93,
							slideItemElementId: 291,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 94,
							slideItemElementId: 292,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 95,
							slideItemElementId: 293,
							type: 'measures',
						},
						{
							content: '',
							order: 96,
							slideItemElementId: 294,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5146',
							order: 97,
							slideItemElementId: 295,
							type: 'url',
						},
						{
							content: 'De la serie Mares de Oro, de Sol y de Frío',
							order: 98,
							slideItemElementId: 296,
							type: 'title',
						},
						{
							content: '',
							order: 99,
							slideItemElementId: 297,
							type: 'date',
						},
						{
							content: '0',
							order: 100,
							slideItemElementId: 298,
							type: 'price',
						},
						{
							content: '',
							order: 101,
							slideItemElementId: 299,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 102,
							slideItemElementId: 300,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 103,
							slideItemElementId: 301,
							type: 'measures',
						},
						{
							content: '',
							order: 104,
							slideItemElementId: 302,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5149',
							order: 105,
							slideItemElementId: 303,
							type: 'url',
						},
						{
							content: 'De la serie Mares de Oro, de Sol y de Frío',
							order: 106,
							slideItemElementId: 304,
							type: 'title',
						},
						{
							content: '',
							order: 107,
							slideItemElementId: 305,
							type: 'date',
						},
						{
							content: '0',
							order: 108,
							slideItemElementId: 306,
							type: 'price',
						},
						{
							content: '',
							order: 109,
							slideItemElementId: 307,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 110,
							slideItemElementId: 308,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 111,
							slideItemElementId: 309,
							type: 'measures',
						},
						{
							content: '',
							order: 112,
							slideItemElementId: 310,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5150',
							order: 113,
							slideItemElementId: 311,
							type: 'url',
						},
						{
							content: 'De la serie Mares de Oro, de Sol y de Frío',
							order: 114,
							slideItemElementId: 312,
							type: 'title',
						},
						{
							content: '',
							order: 115,
							slideItemElementId: 313,
							type: 'date',
						},
						{
							content: '0',
							order: 116,
							slideItemElementId: 314,
							type: 'price',
						},
						{
							content: '',
							order: 117,
							slideItemElementId: 315,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 118,
							slideItemElementId: 316,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 119,
							slideItemElementId: 317,
							type: 'measures',
						},
						{
							content: '',
							order: 120,
							slideItemElementId: 318,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5151',
							order: 121,
							slideItemElementId: 319,
							type: 'url',
						},
						{
							content: 'De la serie Mares de Oro, de Sol y de Frío',
							order: 122,
							slideItemElementId: 320,
							type: 'title',
						},
						{
							content: '',
							order: 123,
							slideItemElementId: 321,
							type: 'date',
						},
						{
							content: '0',
							order: 124,
							slideItemElementId: 322,
							type: 'price',
						},
						{
							content: '',
							order: 125,
							slideItemElementId: 323,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 126,
							slideItemElementId: 324,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 127,
							slideItemElementId: 325,
							type: 'measures',
						},
						{
							content: '',
							order: 128,
							slideItemElementId: 326,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5152',
							order: 129,
							slideItemElementId: 327,
							type: 'url',
						},
						{
							content: 'De la serie Mares de Oro, de Sol y de Frío',
							order: 130,
							slideItemElementId: 328,
							type: 'title',
						},
						{
							content: '',
							order: 131,
							slideItemElementId: 329,
							type: 'date',
						},
						{
							content: '0',
							order: 132,
							slideItemElementId: 330,
							type: 'price',
						},
						{
							content: '',
							order: 133,
							slideItemElementId: 331,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 134,
							slideItemElementId: 332,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 135,
							slideItemElementId: 333,
							type: 'measures',
						},
						{
							content: '',
							order: 136,
							slideItemElementId: 334,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5153',
							order: 137,
							slideItemElementId: 335,
							type: 'url',
						},
						{
							content: 'De la serie Mares de Oro, de Sol y de Frío',
							order: 138,
							slideItemElementId: 336,
							type: 'title',
						},
						{
							content: '',
							order: 139,
							slideItemElementId: 337,
							type: 'date',
						},
						{
							content: '0',
							order: 140,
							slideItemElementId: 338,
							type: 'price',
						},
						{
							content: '',
							order: 141,
							slideItemElementId: 339,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 142,
							slideItemElementId: 340,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 143,
							slideItemElementId: 341,
							type: 'measures',
						},
						{
							content: '',
							order: 144,
							slideItemElementId: 342,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5154',
							order: 145,
							slideItemElementId: 343,
							type: 'url',
						},
						{
							content: 'De la serie Mares de Oro, de Sol y de Frío',
							order: 146,
							slideItemElementId: 344,
							type: 'title',
						},
						{
							content: '',
							order: 147,
							slideItemElementId: 345,
							type: 'date',
						},
						{
							content: '0',
							order: 148,
							slideItemElementId: 346,
							type: 'price',
						},
						{
							content: '',
							order: 149,
							slideItemElementId: 347,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 150,
							slideItemElementId: 348,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 151,
							slideItemElementId: 349,
							type: 'measures',
						},
						{
							content: '',
							order: 152,
							slideItemElementId: 350,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5155',
							order: 153,
							slideItemElementId: 351,
							type: 'url',
						},
						{
							content: 'De la serie Mares de Oro, de Sol y de Frío',
							order: 154,
							slideItemElementId: 352,
							type: 'title',
						},
						{
							content: '',
							order: 155,
							slideItemElementId: 353,
							type: 'date',
						},
						{
							content: '0',
							order: 156,
							slideItemElementId: 354,
							type: 'price',
						},
						{
							content: '',
							order: 157,
							slideItemElementId: 355,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 158,
							slideItemElementId: 356,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 159,
							slideItemElementId: 357,
							type: 'measures',
						},
						{
							content: '',
							order: 160,
							slideItemElementId: 358,
							type: 'technique',
						},
					],
					type: 'artwork',
				},
			],
			type: 'cover',
		},
		{
			order: 7,
			portfolioId: 810,
			slideId: 72,
			slideItems: [
				{
					order: 1,
					slideId: 72,
					slideItemId: 126,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5156',
							order: 1,
							slideItemElementId: 359,
							type: 'url',
						},
						{
							content: 'Diptico en Rojo ',
							order: 2,
							slideItemElementId: 360,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 361,
							type: 'date',
						},
						{
							content: '1200000',
							order: 4,
							slideItemElementId: 362,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 363,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 6,
							slideItemElementId: 364,
							type: 'state',
						},
						{
							content: '80.0x0x80.0cm',
							order: 7,
							slideItemElementId: 365,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 8,
							slideItemElementId: 366,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5157',
							order: 9,
							slideItemElementId: 367,
							type: 'url',
						},
						{
							content: 'Abstracción sinfónica en Rojo',
							order: 10,
							slideItemElementId: 368,
							type: 'title',
						},
						{
							content: '',
							order: 11,
							slideItemElementId: 369,
							type: 'date',
						},
						{
							content: '1000000',
							order: 12,
							slideItemElementId: 370,
							type: 'price',
						},
						{
							content: '',
							order: 13,
							slideItemElementId: 371,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 14,
							slideItemElementId: 372,
							type: 'state',
						},
						{
							content: '76.0x0x70.0cm',
							order: 15,
							slideItemElementId: 373,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 16,
							slideItemElementId: 374,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5158',
							order: 17,
							slideItemElementId: 375,
							type: 'url',
						},
						{
							content: 'Abstracto rojo acrílico sobre lienzo',
							order: 18,
							slideItemElementId: 376,
							type: 'title',
						},
						{
							content: '',
							order: 19,
							slideItemElementId: 377,
							type: 'date',
						},
						{
							content: '3000000',
							order: 20,
							slideItemElementId: 378,
							type: 'price',
						},
						{
							content: '',
							order: 21,
							slideItemElementId: 379,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 22,
							slideItemElementId: 380,
							type: 'state',
						},
						{
							content: '100.0x0x100.0cm',
							order: 23,
							slideItemElementId: 381,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 24,
							slideItemElementId: 382,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5159',
							order: 25,
							slideItemElementId: 383,
							type: 'url',
						},
						{
							content: 'Paisaje abstracto',
							order: 26,
							slideItemElementId: 384,
							type: 'title',
						},
						{
							content: '',
							order: 27,
							slideItemElementId: 385,
							type: 'date',
						},
						{
							content: '0',
							order: 28,
							slideItemElementId: 386,
							type: 'price',
						},
						{
							content: '',
							order: 29,
							slideItemElementId: 387,
							type: 'materials',
						},
						{
							content: 'VENDIDO',
							order: 30,
							slideItemElementId: 388,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 31,
							slideItemElementId: 389,
							type: 'measures',
						},
						{
							content: '',
							order: 32,
							slideItemElementId: 390,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5160',
							order: 33,
							slideItemElementId: 391,
							type: 'url',
						},
						{
							content: 'Trigal abstracto',
							order: 34,
							slideItemElementId: 392,
							type: 'title',
						},
						{
							content: '',
							order: 35,
							slideItemElementId: 393,
							type: 'date',
						},
						{
							content: '0',
							order: 36,
							slideItemElementId: 394,
							type: 'price',
						},
						{
							content: '',
							order: 37,
							slideItemElementId: 395,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 38,
							slideItemElementId: 396,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 39,
							slideItemElementId: 397,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 40,
							slideItemElementId: 398,
							type: 'technique',
						},
					],
					type: 'artwork',
				},
			],
			type: 'cover',
		},
		{
			order: 7,
			portfolioId: 810,
			slideId: 73,
			slideItems: [
				{
					order: 1,
					slideId: 73,
					slideItemId: 127,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5161',
							order: 1,
							slideItemElementId: 399,
							type: 'url',
						},
						{
							content: 'Percepciones de Naturaleza #1',
							order: 2,
							slideItemElementId: 400,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 401,
							type: 'date',
						},
						{
							content: '600000',
							order: 4,
							slideItemElementId: 402,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 403,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 404,
							type: 'state',
						},
						{
							content: '50.0x0x50.0cm',
							order: 7,
							slideItemElementId: 405,
							type: 'measures',
						},
						{
							content: 'Acrílico en aguada sobre papel de algodón',
							order: 8,
							slideItemElementId: 406,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5162',
							order: 9,
							slideItemElementId: 407,
							type: 'url',
						},
						{
							content: 'Percepciones de Naturaleza #2',
							order: 10,
							slideItemElementId: 408,
							type: 'title',
						},
						{
							content: '',
							order: 11,
							slideItemElementId: 409,
							type: 'date',
						},
						{
							content: '600000',
							order: 12,
							slideItemElementId: 410,
							type: 'price',
						},
						{
							content: '',
							order: 13,
							slideItemElementId: 411,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 14,
							slideItemElementId: 412,
							type: 'state',
						},
						{
							content: '50.0x0x50.0cm',
							order: 15,
							slideItemElementId: 413,
							type: 'measures',
						},
						{
							content: 'Acrílico en aguada sobre papel de algodón',
							order: 16,
							slideItemElementId: 414,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5163',
							order: 17,
							slideItemElementId: 415,
							type: 'url',
						},
						{
							content: 'Memorias secretas de Naturaleza #1',
							order: 18,
							slideItemElementId: 416,
							type: 'title',
						},
						{
							content: '',
							order: 19,
							slideItemElementId: 417,
							type: 'date',
						},
						{
							content: '800000',
							order: 20,
							slideItemElementId: 418,
							type: 'price',
						},
						{
							content: '',
							order: 21,
							slideItemElementId: 419,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 22,
							slideItemElementId: 420,
							type: 'state',
						},
						{
							content: '60.0x0x60.0cm',
							order: 23,
							slideItemElementId: 421,
							type: 'measures',
						},
						{
							content: 'Acrílico en aguada sobre papel de algodón',
							order: 24,
							slideItemElementId: 422,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5164',
							order: 25,
							slideItemElementId: 423,
							type: 'url',
						},
						{
							content: 'Memorias secretas de Naturaleza #2',
							order: 26,
							slideItemElementId: 424,
							type: 'title',
						},
						{
							content: '',
							order: 27,
							slideItemElementId: 425,
							type: 'date',
						},
						{
							content: '800000',
							order: 28,
							slideItemElementId: 426,
							type: 'price',
						},
						{
							content: '',
							order: 29,
							slideItemElementId: 427,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 30,
							slideItemElementId: 428,
							type: 'state',
						},
						{
							content: '60.0x0x60.0cm',
							order: 31,
							slideItemElementId: 429,
							type: 'measures',
						},
						{
							content: 'Acrílico en aguada sobre papel de algodón',
							order: 32,
							slideItemElementId: 430,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5165',
							order: 33,
							slideItemElementId: 431,
							type: 'url',
						},
						{
							content: 'GIRAR 90 ( BLANCO ARRIBA)',
							order: 34,
							slideItemElementId: 432,
							type: 'title',
						},
						{
							content: '',
							order: 35,
							slideItemElementId: 433,
							type: 'date',
						},
						{
							content: '600000',
							order: 36,
							slideItemElementId: 434,
							type: 'price',
						},
						{
							content: '',
							order: 37,
							slideItemElementId: 435,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 38,
							slideItemElementId: 436,
							type: 'state',
						},
						{
							content: '50.0x0x50.0cm',
							order: 39,
							slideItemElementId: 437,
							type: 'measures',
						},
						{
							content: 'Acrílico en aguada sobre papel de algodón',
							order: 40,
							slideItemElementId: 438,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5166',
							order: 41,
							slideItemElementId: 439,
							type: 'url',
						},
						{
							content: 'Percepciones de la naturaleza #4',
							order: 42,
							slideItemElementId: 440,
							type: 'title',
						},
						{
							content: '',
							order: 43,
							slideItemElementId: 441,
							type: 'date',
						},
						{
							content: '600000',
							order: 44,
							slideItemElementId: 442,
							type: 'price',
						},
						{
							content: '',
							order: 45,
							slideItemElementId: 443,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 46,
							slideItemElementId: 444,
							type: 'state',
						},
						{
							content: '50.0x0x50.0cm',
							order: 47,
							slideItemElementId: 445,
							type: 'measures',
						},
						{
							content: 'Acrílico en aguada sobre papel de algodón',
							order: 48,
							slideItemElementId: 446,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5167',
							order: 49,
							slideItemElementId: 447,
							type: 'url',
						},
						{
							content: 'Percepciones de la naturaleza #5',
							order: 50,
							slideItemElementId: 448,
							type: 'title',
						},
						{
							content: '',
							order: 51,
							slideItemElementId: 449,
							type: 'date',
						},
						{
							content: '600000',
							order: 52,
							slideItemElementId: 450,
							type: 'price',
						},
						{
							content: '',
							order: 53,
							slideItemElementId: 451,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 54,
							slideItemElementId: 452,
							type: 'state',
						},
						{
							content: '50.0x0x50.0cm',
							order: 55,
							slideItemElementId: 453,
							type: 'measures',
						},
						{
							content: 'Acrílico en aguada sobre papel de algodón',
							order: 56,
							slideItemElementId: 454,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5168',
							order: 57,
							slideItemElementId: 455,
							type: 'url',
						},
						{
							content: 'Memorias secretas de Naturaleza #3',
							order: 58,
							slideItemElementId: 456,
							type: 'title',
						},
						{
							content: '',
							order: 59,
							slideItemElementId: 457,
							type: 'date',
						},
						{
							content: '800000',
							order: 60,
							slideItemElementId: 458,
							type: 'price',
						},
						{
							content: '',
							order: 61,
							slideItemElementId: 459,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 62,
							slideItemElementId: 460,
							type: 'state',
						},
						{
							content: '60.0x0x60.0cm',
							order: 63,
							slideItemElementId: 461,
							type: 'measures',
						},
						{
							content: 'Acrílico en aguada sobre papel de algodón',
							order: 64,
							slideItemElementId: 462,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5169',
							order: 65,
							slideItemElementId: 463,
							type: 'url',
						},
						{
							content: 'Percepciones de la naturaleza #7',
							order: 66,
							slideItemElementId: 464,
							type: 'title',
						},
						{
							content: '',
							order: 67,
							slideItemElementId: 465,
							type: 'date',
						},
						{
							content: '0',
							order: 68,
							slideItemElementId: 466,
							type: 'price',
						},
						{
							content: '',
							order: 69,
							slideItemElementId: 467,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 70,
							slideItemElementId: 468,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 71,
							slideItemElementId: 469,
							type: 'measures',
						},
						{
							content: '',
							order: 72,
							slideItemElementId: 470,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5163',
							order: 73,
							slideItemElementId: 471,
							type: 'url',
						},
						{
							content: 'Memorias secretas de Naturaleza #1',
							order: 74,
							slideItemElementId: 472,
							type: 'title',
						},
						{
							content: '',
							order: 75,
							slideItemElementId: 473,
							type: 'date',
						},
						{
							content: '800000',
							order: 76,
							slideItemElementId: 474,
							type: 'price',
						},
						{
							content: '',
							order: 77,
							slideItemElementId: 475,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 78,
							slideItemElementId: 476,
							type: 'state',
						},
						{
							content: '60.0x0x60.0cm',
							order: 79,
							slideItemElementId: 477,
							type: 'measures',
						},
						{
							content: 'Acrílico en aguada sobre papel de algodón',
							order: 80,
							slideItemElementId: 478,
							type: 'technique',
						},
					],
					type: 'artwork',
				},
			],
			type: 'cover',
		},
		{
			order: 7,
			portfolioId: 810,
			slideId: 74,
			slideItems: [
				{
					order: 1,
					slideId: 74,
					slideItemId: 128,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5170',
							order: 1,
							slideItemElementId: 479,
							type: 'url',
						},
						{
							content: 'Paisaje Páramo  ',
							order: 2,
							slideItemElementId: 480,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 481,
							type: 'date',
						},
						{
							content: '800000',
							order: 4,
							slideItemElementId: 482,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 483,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 6,
							slideItemElementId: 484,
							type: 'state',
						},
						{
							content: '30.0x0x30.0cm',
							order: 7,
							slideItemElementId: 485,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 8,
							slideItemElementId: 486,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5171',
							order: 9,
							slideItemElementId: 487,
							type: 'url',
						},
						{
							content: 'Flora silvestre',
							order: 10,
							slideItemElementId: 488,
							type: 'title',
						},
						{
							content: '',
							order: 11,
							slideItemElementId: 489,
							type: 'date',
						},
						{
							content: '0',
							order: 12,
							slideItemElementId: 490,
							type: 'price',
						},
						{
							content: '',
							order: 13,
							slideItemElementId: 491,
							type: 'materials',
						},
						{
							content: 'VENDIDO',
							order: 14,
							slideItemElementId: 492,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 15,
							slideItemElementId: 493,
							type: 'measures',
						},
						{
							content: '',
							order: 16,
							slideItemElementId: 494,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5172',
							order: 17,
							slideItemElementId: 495,
							type: 'url',
						},
						{
							content: 'Floral Sinfonía Verde',
							order: 18,
							slideItemElementId: 496,
							type: 'title',
						},
						{
							content: '',
							order: 19,
							slideItemElementId: 497,
							type: 'date',
						},
						{
							content: '1200000',
							order: 20,
							slideItemElementId: 498,
							type: 'price',
						},
						{
							content: '',
							order: 21,
							slideItemElementId: 499,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 22,
							slideItemElementId: 500,
							type: 'state',
						},
						{
							content: '100.0x0x60.0cm',
							order: 23,
							slideItemElementId: 501,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 24,
							slideItemElementId: 502,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5173',
							order: 25,
							slideItemElementId: 503,
							type: 'url',
						},
						{
							content: 'Viento en el Páramo áureo ',
							order: 26,
							slideItemElementId: 504,
							type: 'title',
						},
						{
							content: '',
							order: 27,
							slideItemElementId: 505,
							type: 'date',
						},
						{
							content: '800000',
							order: 28,
							slideItemElementId: 506,
							type: 'price',
						},
						{
							content: '',
							order: 29,
							slideItemElementId: 507,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 30,
							slideItemElementId: 508,
							type: 'state',
						},
						{
							content: '50.0x0x40.0cm',
							order: 31,
							slideItemElementId: 509,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 32,
							slideItemElementId: 510,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5174',
							order: 33,
							slideItemElementId: 511,
							type: 'url',
						},
						{
							content: 'Flora en recreo ',
							order: 34,
							slideItemElementId: 512,
							type: 'title',
						},
						{
							content: '',
							order: 35,
							slideItemElementId: 513,
							type: 'date',
						},
						{
							content: '3000000',
							order: 36,
							slideItemElementId: 514,
							type: 'price',
						},
						{
							content: '',
							order: 37,
							slideItemElementId: 515,
							type: 'materials',
						},
						{
							content: 'VENDIDO',
							order: 38,
							slideItemElementId: 516,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 39,
							slideItemElementId: 517,
							type: 'measures',
						},
						{
							content: '',
							order: 40,
							slideItemElementId: 518,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5175',
							order: 41,
							slideItemElementId: 519,
							type: 'url',
						},
						{
							content: 'Neblinas Diptico ',
							order: 42,
							slideItemElementId: 520,
							type: 'title',
						},
						{
							content: '',
							order: 43,
							slideItemElementId: 521,
							type: 'date',
						},
						{
							content: '1000000',
							order: 44,
							slideItemElementId: 522,
							type: 'price',
						},
						{
							content: '',
							order: 45,
							slideItemElementId: 523,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 46,
							slideItemElementId: 524,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 47,
							slideItemElementId: 525,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 48,
							slideItemElementId: 526,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5176',
							order: 49,
							slideItemElementId: 527,
							type: 'url',
						},
						{
							content: 'Floral',
							order: 50,
							slideItemElementId: 528,
							type: 'title',
						},
						{
							content: '',
							order: 51,
							slideItemElementId: 529,
							type: 'date',
						},
						{
							content: '400000',
							order: 52,
							slideItemElementId: 530,
							type: 'price',
						},
						{
							content: '',
							order: 53,
							slideItemElementId: 531,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 54,
							slideItemElementId: 532,
							type: 'state',
						},
						{
							content: '40.0x0x30.0cm',
							order: 55,
							slideItemElementId: 533,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 56,
							slideItemElementId: 534,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5177',
							order: 57,
							slideItemElementId: 535,
							type: 'url',
						},
						{
							content: 'Aires de Páramo',
							order: 58,
							slideItemElementId: 536,
							type: 'title',
						},
						{
							content: '',
							order: 59,
							slideItemElementId: 537,
							type: 'date',
						},
						{
							content: '800000',
							order: 60,
							slideItemElementId: 538,
							type: 'price',
						},
						{
							content: '',
							order: 61,
							slideItemElementId: 539,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 62,
							slideItemElementId: 540,
							type: 'state',
						},
						{
							content: '30.0x0x30.0cm',
							order: 63,
							slideItemElementId: 541,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 64,
							slideItemElementId: 542,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5178',
							order: 65,
							slideItemElementId: 543,
							type: 'url',
						},
						{
							content: 'Neblinas ',
							order: 66,
							slideItemElementId: 544,
							type: 'title',
						},
						{
							content: '',
							order: 67,
							slideItemElementId: 545,
							type: 'date',
						},
						{
							content: '3000000',
							order: 68,
							slideItemElementId: 546,
							type: 'price',
						},
						{
							content: '',
							order: 69,
							slideItemElementId: 547,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 70,
							slideItemElementId: 548,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 71,
							slideItemElementId: 549,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 72,
							slideItemElementId: 550,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5179',
							order: 73,
							slideItemElementId: 551,
							type: 'url',
						},
						{
							content: 'Áureo Floral',
							order: 74,
							slideItemElementId: 552,
							type: 'title',
						},
						{
							content: '',
							order: 75,
							slideItemElementId: 553,
							type: 'date',
						},
						{
							content: '0',
							order: 76,
							slideItemElementId: 554,
							type: 'price',
						},
						{
							content: '',
							order: 77,
							slideItemElementId: 555,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 78,
							slideItemElementId: 556,
							type: 'state',
						},
						{
							content: '60.0x0x40.0cm',
							order: 79,
							slideItemElementId: 557,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 80,
							slideItemElementId: 558,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5180',
							order: 81,
							slideItemElementId: 559,
							type: 'url',
						},
						{
							content: 'Neblinas ',
							order: 82,
							slideItemElementId: 560,
							type: 'title',
						},
						{
							content: '',
							order: 83,
							slideItemElementId: 561,
							type: 'date',
						},
						{
							content: '0',
							order: 84,
							slideItemElementId: 562,
							type: 'price',
						},
						{
							content: '',
							order: 85,
							slideItemElementId: 563,
							type: 'materials',
						},
						{
							content: 'VENDIDO',
							order: 86,
							slideItemElementId: 564,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 87,
							slideItemElementId: 565,
							type: 'measures',
						},
						{
							content: 'Acrílico sobre lienzo',
							order: 88,
							slideItemElementId: 566,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5181',
							order: 89,
							slideItemElementId: 567,
							type: 'url',
						},
						{
							content: 'Floral Sinfonía ',
							order: 90,
							slideItemElementId: 568,
							type: 'title',
						},
						{
							content: '',
							order: 91,
							slideItemElementId: 569,
							type: 'date',
						},
						{
							content: '0',
							order: 92,
							slideItemElementId: 570,
							type: 'price',
						},
						{
							content: '',
							order: 93,
							slideItemElementId: 571,
							type: 'materials',
						},
						{
							content: 'DISPONIBLE',
							order: 94,
							slideItemElementId: 572,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 95,
							slideItemElementId: 573,
							type: 'measures',
						},
						{
							content: '',
							order: 96,
							slideItemElementId: 574,
							type: 'technique',
						},
					],
					type: 'artwork',
				},
			],
			type: 'cover',
		},
		{
			order: 7,
			portfolioId: 810,
			slideId: 75,
			slideItems: [
				{
					order: 1,
					slideId: 75,
					slideItemId: 129,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5184',
							order: 1,
							slideItemElementId: 575,
							type: 'url',
						},
						{
							content: 'Incursión Arte Contemporaneo',
							order: 2,
							slideItemElementId: 576,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 577,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 578,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 579,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 580,
							type: 'state',
						},
						{
							content: '100.0x60.0x0cm',
							order: 7,
							slideItemElementId: 581,
							type: 'measures',
						},
						{
							content: '',
							order: 8,
							slideItemElementId: 582,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5185',
							order: 9,
							slideItemElementId: 583,
							type: 'url',
						},
						{
							content: 'Incursión Arte Contemporaneo',
							order: 10,
							slideItemElementId: 584,
							type: 'title',
						},
						{
							content: '',
							order: 11,
							slideItemElementId: 585,
							type: 'date',
						},
						{
							content: '0',
							order: 12,
							slideItemElementId: 586,
							type: 'price',
						},
						{
							content: '',
							order: 13,
							slideItemElementId: 587,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 14,
							slideItemElementId: 588,
							type: 'state',
						},
						{
							content: '100.0x60.0x0cm',
							order: 15,
							slideItemElementId: 589,
							type: 'measures',
						},
						{
							content: '',
							order: 16,
							slideItemElementId: 590,
							type: 'technique',
						},
					],
					type: 'artwork',
				},
			],
			type: 'cover',
		},
		{
			order: 7,
			portfolioId: 810,
			slideId: 76,
			slideItems: [
				{
					order: 1,
					slideId: 76,
					slideItemId: 130,
					slideItemsElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5186',
							order: 1,
							slideItemElementId: 591,
							type: 'url',
						},
						{
							content: '70 a los 80 (colección privada)',
							order: 2,
							slideItemElementId: 592,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 593,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 594,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 595,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 596,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 7,
							slideItemElementId: 597,
							type: 'measures',
						},
						{
							content: '',
							order: 8,
							slideItemElementId: 598,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5187',
							order: 9,
							slideItemElementId: 599,
							type: 'url',
						},
						{
							content: '70 a los 80 (colección privada)',
							order: 10,
							slideItemElementId: 600,
							type: 'title',
						},
						{
							content: '',
							order: 11,
							slideItemElementId: 601,
							type: 'date',
						},
						{
							content: '0',
							order: 12,
							slideItemElementId: 602,
							type: 'price',
						},
						{
							content: '',
							order: 13,
							slideItemElementId: 603,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 14,
							slideItemElementId: 604,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 15,
							slideItemElementId: 605,
							type: 'measures',
						},
						{
							content: '',
							order: 16,
							slideItemElementId: 606,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5188',
							order: 17,
							slideItemElementId: 607,
							type: 'url',
						},
						{
							content: '70 a los 80 (colección privada)',
							order: 18,
							slideItemElementId: 608,
							type: 'title',
						},
						{
							content: '',
							order: 19,
							slideItemElementId: 609,
							type: 'date',
						},
						{
							content: '0',
							order: 20,
							slideItemElementId: 610,
							type: 'price',
						},
						{
							content: '',
							order: 21,
							slideItemElementId: 611,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 22,
							slideItemElementId: 612,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 23,
							slideItemElementId: 613,
							type: 'measures',
						},
						{
							content: '',
							order: 24,
							slideItemElementId: 614,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5189',
							order: 25,
							slideItemElementId: 615,
							type: 'url',
						},
						{
							content: '70 a los 80 (colección privada)',
							order: 26,
							slideItemElementId: 616,
							type: 'title',
						},
						{
							content: '',
							order: 27,
							slideItemElementId: 617,
							type: 'date',
						},
						{
							content: '0',
							order: 28,
							slideItemElementId: 618,
							type: 'price',
						},
						{
							content: '',
							order: 29,
							slideItemElementId: 619,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 30,
							slideItemElementId: 620,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 31,
							slideItemElementId: 621,
							type: 'measures',
						},
						{
							content: '',
							order: 32,
							slideItemElementId: 622,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5190',
							order: 33,
							slideItemElementId: 623,
							type: 'url',
						},
						{
							content: '70 a los 80 (colección privada)',
							order: 34,
							slideItemElementId: 624,
							type: 'title',
						},
						{
							content: '',
							order: 35,
							slideItemElementId: 625,
							type: 'date',
						},
						{
							content: '0',
							order: 36,
							slideItemElementId: 626,
							type: 'price',
						},
						{
							content: '',
							order: 37,
							slideItemElementId: 627,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 38,
							slideItemElementId: 628,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 39,
							slideItemElementId: 629,
							type: 'measures',
						},
						{
							content: '',
							order: 40,
							slideItemElementId: 630,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5191',
							order: 41,
							slideItemElementId: 631,
							type: 'url',
						},
						{
							content: '70 a los 80 (colección privada)',
							order: 42,
							slideItemElementId: 632,
							type: 'title',
						},
						{
							content: '',
							order: 43,
							slideItemElementId: 633,
							type: 'date',
						},
						{
							content: '0',
							order: 44,
							slideItemElementId: 634,
							type: 'price',
						},
						{
							content: '',
							order: 45,
							slideItemElementId: 635,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 46,
							slideItemElementId: 636,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 47,
							slideItemElementId: 637,
							type: 'measures',
						},
						{
							content: '',
							order: 48,
							slideItemElementId: 638,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5192',
							order: 49,
							slideItemElementId: 639,
							type: 'url',
						},
						{
							content: '70 a los 80 (colección privada)',
							order: 50,
							slideItemElementId: 640,
							type: 'title',
						},
						{
							content: '',
							order: 51,
							slideItemElementId: 641,
							type: 'date',
						},
						{
							content: '0',
							order: 52,
							slideItemElementId: 642,
							type: 'price',
						},
						{
							content: '',
							order: 53,
							slideItemElementId: 643,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 54,
							slideItemElementId: 644,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 55,
							slideItemElementId: 645,
							type: 'measures',
						},
						{
							content: '',
							order: 56,
							slideItemElementId: 646,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5193',
							order: 57,
							slideItemElementId: 647,
							type: 'url',
						},
						{
							content: '70 a los 80 (colección privada)',
							order: 58,
							slideItemElementId: 648,
							type: 'title',
						},
						{
							content: '',
							order: 59,
							slideItemElementId: 649,
							type: 'date',
						},
						{
							content: '0',
							order: 60,
							slideItemElementId: 650,
							type: 'price',
						},
						{
							content: '',
							order: 61,
							slideItemElementId: 651,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 62,
							slideItemElementId: 652,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 63,
							slideItemElementId: 653,
							type: 'measures',
						},
						{
							content: '',
							order: 64,
							slideItemElementId: 654,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5194',
							order: 65,
							slideItemElementId: 655,
							type: 'url',
						},
						{
							content: '70 a los 80 (colección privada)',
							order: 66,
							slideItemElementId: 656,
							type: 'title',
						},
						{
							content: '',
							order: 67,
							slideItemElementId: 657,
							type: 'date',
						},
						{
							content: '0',
							order: 68,
							slideItemElementId: 658,
							type: 'price',
						},
						{
							content: '',
							order: 69,
							slideItemElementId: 659,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 70,
							slideItemElementId: 660,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 71,
							slideItemElementId: 661,
							type: 'measures',
						},
						{
							content: '',
							order: 72,
							slideItemElementId: 662,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5195',
							order: 73,
							slideItemElementId: 663,
							type: 'url',
						},
						{
							content: '70 a los 80 (colección privada)',
							order: 74,
							slideItemElementId: 664,
							type: 'title',
						},
						{
							content: '',
							order: 75,
							slideItemElementId: 665,
							type: 'date',
						},
						{
							content: '0',
							order: 76,
							slideItemElementId: 666,
							type: 'price',
						},
						{
							content: '',
							order: 77,
							slideItemElementId: 667,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 78,
							slideItemElementId: 668,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 79,
							slideItemElementId: 669,
							type: 'measures',
						},
						{
							content: '',
							order: 80,
							slideItemElementId: 670,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5196',
							order: 81,
							slideItemElementId: 671,
							type: 'url',
						},
						{
							content: '70 a los 80 (colección privada)',
							order: 82,
							slideItemElementId: 672,
							type: 'title',
						},
						{
							content: '',
							order: 83,
							slideItemElementId: 673,
							type: 'date',
						},
						{
							content: '0',
							order: 84,
							slideItemElementId: 674,
							type: 'price',
						},
						{
							content: '',
							order: 85,
							slideItemElementId: 675,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 86,
							slideItemElementId: 676,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 87,
							slideItemElementId: 677,
							type: 'measures',
						},
						{
							content: '',
							order: 88,
							slideItemElementId: 678,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5232',
							order: 89,
							slideItemElementId: 679,
							type: 'url',
						},
						{
							content: 'Vitral Moderno',
							order: 90,
							slideItemElementId: 680,
							type: 'title',
						},
						{
							content: '',
							order: 91,
							slideItemElementId: 681,
							type: 'date',
						},
						{
							content: '0',
							order: 92,
							slideItemElementId: 682,
							type: 'price',
						},
						{
							content: '',
							order: 93,
							slideItemElementId: 683,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 94,
							slideItemElementId: 684,
							type: 'state',
						},
						{
							content: '0x180.0x180.0cm',
							order: 95,
							slideItemElementId: 685,
							type: 'measures',
						},
						{
							content: '',
							order: 96,
							slideItemElementId: 686,
							type: 'technique',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5234',
							order: 97,
							slideItemElementId: 687,
							type: 'url',
						},
						{
							content: 'Vitrales Año 80',
							order: 98,
							slideItemElementId: 688,
							type: 'title',
						},
						{
							content: '',
							order: 99,
							slideItemElementId: 689,
							type: 'date',
						},
						{
							content: '0',
							order: 100,
							slideItemElementId: 690,
							type: 'price',
						},
						{
							content: '',
							order: 101,
							slideItemElementId: 691,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 102,
							slideItemElementId: 692,
							type: 'state',
						},
						{
							content: '0x0x0cm',
							order: 103,
							slideItemElementId: 693,
							type: 'measures',
						},
						{
							content: '',
							order: 104,
							slideItemElementId: 694,
							type: 'technique',
						},
					],
					type: 'artwork',
				},
			],
			type: 'cover',
		},
		{
			order: 8,
			portfolioId: 810,
			slideId: 77,
			slideItems: [
				{
					order: 1,
					slideId: 77,
					slideItemId: 131,
					slideItemsElements: [
						{
							content: 'subtitle',
							order: 1,
							slideItemElementId: 695,
							type: 'title',
						},
					],
					type: 'title',
				},
				{
					order: 2,
					slideId: 77,
					slideItemId: 132,
					slideItemsElements: [
						{
							content: 'elvalde@gmail.com',
							order: 2,
							slideItemElementId: 696,
							type: 'text',
						},
					],
					type: 'text',
				},
			],
			type: 'backCover',
		},
	],
	slug: 'portafolio-Clara',
	type: 'EXPERT',
	type_id: 196,
	updated_at: null,
};

export const defaultPortfolioData = {
	created_at: '2023-02-02 06:35:34',
	has_postulation: 0,
	id: 809,
	image_quality: 'MEDIUM',
	is_deleted: false,
	is_general_portfolio: false,
	organization_user_id: 31,
	permission: 'PUBLIC',
	type: 'EXPERT',
	updated_at: null,
	//main_picture_url: debería ser incialmente la misma imagen de portada de la colección.
	main_picture_url:
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
	//name: Nombre del artista que crea el portafolio
	name: 'Clara Valdenebro',
	//slug: minúsculas, sin caracteres especiales, ¿qué ocurre con tildes en nombre?, `portafolio-{nombre}` o `portafolio-{nombre}-{número}`
	slug: 'portafolio-Clara1',
	//id de colección que se asocia
	type_id: 196,
	//show_x : dependen de los valores que se envíen en el post.
	show_date: true,
	show_measures: true,
	show_owner_status: true,
	show_prices: true,
	show_technique: true,
	show_title: true,
	slides: [
		//slide - Type Cover
		{
			portfolioId: 1,
			slideId: 1,
			order: 1,
			type: 'cover',
			slideItems: [
				{
					slideId: 1,
					type: 'title',
					slideItemId: 1,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 1,
							order: 1,
							type: 'title',
							//Portfolio name
							content: 'Maria Camila Castaño',
						},
						{
							slideItemElementId: 2,
							order: 2,
							type: 'sub_title',
							content: 'Portafolio',
						},
					],
				},
				{
					slideId: 1,
					type: 'text',
					slideItemId: 2,
					order: 2,
					slideItemElements: [
						{
							slideItemElementId: 3,
							order: 1,
							type: 'text',
							//descripción del portafolio
							content:
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
						},
					],
				},
				{
					slideId: 1,
					type: 'image',
					slideItemId: 3,
					order: 3,
					slideItemElements: [
						{
							slideItemElementId: 4,
							order: 1,
							type: 'image_url',
							//La imagen de portada de la colección
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5227',
						},
					],
				},
			],
		},
		// slide - type textBlockAndImage
		//Para ARTISTA -> Se crea SOLO si el artista tiene rellenado el campo de biografía
		//Para galería -> No se crea esta slide.
		{
			portfolioId: 1,
			slideId: 2,
			order: 2,
			type: 'textBlockAndImage',
			slideItems: [
				{
					slideId: 5,
					type: 'title',
					slideItemId: 1,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 1,
							order: 1,
							type: 'title',
							content: 'Biografía',
						},
					],
				},
				{
					slideId: 6,
					type: 'text',
					slideItemId: 2,
					order: 2,
					slideItemElements: [
						{
							slideItemElementId: 1,
							order: 1,
							type: 'text',
							//El texto del campo Biografía
							content:
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
						},
					],
				},
				{
					slideId: 7,
					type: 'image',
					slideElementId: 3,
					order: 3,
					slideItemElements: [
						{
							slideItemElementId: 1,
							order: 1,
							type: 'image_url',
							//La imagen del artista
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5227',
						},
					],
				},
			],
		},
		// slide - type textBlock
		//Para ARTISTA -> Se crea SOLO si el artista tiene rellenado el campo de exposiciones
		//Para galería -> No se crea esta slide.
		{
			portfolioId: 1,
			slideId: 4,
			order: 3,
			type: 'textBlock',
			slideItems: [
				{
					slideId: 4,
					type: 'title',
					slideItemId: 1,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 1,
							order: 1,
							type: 'title',
							content: 'Exposiciones',
						},
					],
				},
				{
					slideId: 4,
					type: 'text',
					slideItemId: 2,
					order: 2,
					slideItemElements: [
						{
							slideItemElementId: 1,
							order: 1,
							type: 'text',
							content:
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
						},
					],
				},
			],
		},
		//slide - Artwork Group
		{
			portfolioId: 1,
			slideId: 2,
			order: 4,
			type: 'artworksGroup',
			// Tiene todas las obras de la colección asociada
			slideItems: [
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
			],
		},
		//slide - Cover Subcolección
		{
			portfolioId: 1,
			slideId: 1,
			order: 5,
			type: 'cover',
			slideItems: [
				{
					slideId: 1,
					type: 'title',
					slideItemId: 1,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 1,
							order: 1,
							type: 'subtitle',
							//Subollection name
							content: 'Maria Camila Castaño',
						},
					],
				},
				{
					slideId: 1,
					type: 'text',
					slideItemId: 2,
					order: 2,
					slideItemElements: [
						{
							slideItemElementId: 3,
							order: 1,
							type: 'text',
							//descripción de la Subcolección
							content:
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
						},
					],
				},
				{
					slideId: 1,
					type: 'image',
					slideItemId: 3,
					order: 3,
					slideItemElements: [
						{
							slideItemElementId: 4,
							order: 1,
							type: 'image_url',
							//La imagen de portada de la Subcolección
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5227',
						},
					],
				},
			],
		},
		// slide - Artwork Group de la subcolección
		{
			portfolioId: 1,
			slideId: 2,
			order: 6,
			type: 'artworksGroup',
			// Tiene todas las obras de la subcolección de la colección
			slideItems: [
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
			],
		},
		//slide - backCover
		{
			portfolioId: 1,
			slideId: 6,
			order: 7,
			type: 'backCover',
			slideItems: [
				{
					slideId: 4,
					type: 'title',
					slideItemId: 1,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 1,
							order: 1,
							type: 'title',
							// gallery/artist name
							content: 'Maria Camila Castaño',
						},
						{
							slideItemElementId: 2,
							order: 2,
							type: 'subtitle',
							// gallery/artist name
							content: 'Maria Camila Castaño',
						},
					],
				},
			],
		},
		//Artwork individual
		{
			portfolioId: 1,
			slideId: 3,
			order: 8,
			type: 'artworkIndividual',
			slideItems: [
				{
					slideId: 2,
					type: 'artwork',
					slideItemId: 1,
					order: 3,
					slideItemElements: [
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 1,
							slideItemElementId: 149,
							type: 'image_url',
						},
						{
							content: 'PORTADA',
							order: 2,
							slideItemElementId: 150,
							type: 'title',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 151,
							type: 'date',
						},
						{
							content: '0',
							order: 4,
							slideItemElementId: 152,
							type: 'price',
						},
						{
							content: '',
							order: 5,
							slideItemElementId: 153,
							type: 'materials',
						},
						{
							content: 'CONSERVADO',
							order: 6,
							slideItemElementId: 154,
							type: 'state',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							slideItemElementId: 5,
							order: 5,
							type: 'technique',
							content: '1000',
						},
					],
				},
			],
		},
		//slide - full screen
		{
			portfolioId: 1,
			slideId: 7,
			order: 9,
			type: 'fullScreenImage',
			slideItems: [
				{
					slideId: 7,
					type: 'image',
					slideElementId: 1,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 1,
							order: 1,
							type: 'image_url',
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5227',
						},
					],
				},
			],
		},
	],
};

export const single_portfolio = {
	/*
	Contexto: Es el ejemplo de un portafolio creado con un artista que tiene contenido en su biografía,
	tiene contenido en Exposiciones y tiene 3 subcolecciones. 
	La primera subcolección, tiene una obra, la segunda tiene 3 obras y la tercera no tiene obras.
	Por favor, si me llego a equivocar en un order o id, preguntar para que no haya confusión.
	*/
	created_at: '2023-02-05 13:19:07',
	has_postulation: 0,
	id: 1,
	image_quality: 'MEDIUM',
	is_deleted: false,
	is_general_portfolio: false,
	main_picture_url:
		'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5229',
	name: 'Clara Valdenebro',
	organization_user_id: 31,
	permission: 'PUBLIC',
	show_date: true,
	show_measures: true,
	show_owner_status: true,
	show_prices: true,
	show_technique: true,
	show_title: true,
	slug: 'portafolio-Clara',
	type: 'EXPERT',
	type_id: 196,
	updated_at: null,
	slides: [
		//Cover - presentación del portafolio.
		{
			portfolioId: 1,
			slideId: 1,
			order: 1,
			type: 'cover',
			slideItems: [
				{
					slideId: 1,
					type: 'title',
					slideItemId: 1,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 1,
							order: 1,
							type: 'title',
							content: 'Las nieves',
						},
						{
							slideItemElementId: 2,
							order: 2,
							type: 'subtitle',
							content: 'Maria Camila Castaño',
						},
					],
				},
				{
					slideId: 1,
					type: 'text',
					slideItemId: 2,
					order: 2,
					slideItemElements: [
						{
							slideItemElementId: 3,
							order: 1,
							type: 'text',
							content:
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
						},
					],
				},
				{
					slideId: 1,
					type: 'image',
					slideItemId: 3,
					order: 3,
					slideItemElements: [
						{
							slideItemElementId: 4,
							order: 1,
							type: 'image_url',
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5227',
						},
					],
				},
			],
		},
		//textBlockAndImage - Biografía del Artista.
		{
			portfolioId: 1,
			slideId: 2,
			order: 2,
			type: 'textBlockAndImage',
			slideItems: [
				{
					slideId: 2,
					type: 'title',
					slideItemId: 4,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 5,
							order: 1,
							type: 'title',
							content: 'Biografía',
						},
					],
				},
				{
					slideId: 2,
					type: 'text',
					slideItemId: 5,
					order: 2,
					slideItemElements: [
						{
							slideItemElementId: 6,
							order: 2,
							type: 'text',
							content:
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
						},
					],
				},
				{
					slideId: 2,
					type: 'image',
					slideElementId: 6,
					order: 3,
					slideItemElements: [
						{
							slideItemElementId: 7,
							order: 3,
							type: 'image_url',
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5227',
						},
					],
				},
			],
		},
		//textBlock - Exposiciones del Artista.
		{
			portfolioId: 1,
			slideId: 3,
			order: 3,
			type: 'textBlock',
			slideItems: [
				{
					slideId: 3,
					type: 'title',
					slideItemId: 7,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 8,
							order: 1,
							type: 'title',
							content: 'Exposiciones',
						},
					],
				},
				{
					slideId: 3,
					type: 'text',
					slideItemId: 8,
					order: 2,
					slideItemElements: [
						{
							slideItemElementId: 9,
							order: 1,
							type: 'text',
							content:
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
						},
					],
				},
			],
		},
		//Cover - presentación de subcolección 1.
		{
			portfolioId: 1,
			slideId: 4,
			order: 4,
			type: 'cover',
			slideItems: [
				{
					slideId: 4,
					type: 'title',
					slideItemId: 9,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 12,
							order: 1,
							type: 'title',
							content: 'Subcolección de mar',
						},
					],
				},
				{
					slideId: 4,
					type: 'text',
					slideItemId: 10,
					order: 2,
					slideItemElements: [
						{
							slideItemElementId: 13,
							order: 1,
							type: 'text',
							content:
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
						},
					],
				},
				{
					slideId: 4,
					type: 'image',
					slideItemId: 11,
					order: 3,
					slideItemElements: [
						{
							slideItemElementId: 14,
							order: 1,
							type: 'image_url',
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5227',
						},
					],
				},
			],
		},
		//artworkGroup - obras de subcolección 1.
		{
			portfolioId: 1,
			slideId: 5,
			order: 5,
			type: 'artworkGroup',
			slideItems: [
				{
					slideId: 5,
					type: 'artwork',
					slideItemId: 12,
					order: 3,
					slideItemElements: [
						{
							content: 'Las flores 1',
							order: 1,
							slideItemElementId: 15,
							type: 'title',
						},
						{
							content: 'Las flores de la naturaleza.',
							order: 2,
							slideItemElementId: 16,
							type: 'description',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 17,
							type: 'materials',
						},
						{
							slideItemElementId: 18,
							order: 4,
							type: 'technique',
							content: 'Lienzo',
						},
						{
							slideItemElementId: 19,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							content: '',
							order: 6,
							slideItemElementId: 20,
							type: 'date',
						},
						{
							content: '0',
							order: 7,
							slideItemElementId: 21,
							type: 'price',
						},
						{
							content: 'CONSERVADO',
							order: 8,
							slideItemElementId: 22,
							type: 'owner_status',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 9,
							slideItemElementId: 23,
							type: 'image_url',
						},
					],
				},
			],
		},
		//Cover - presentación de subcolección 2.
		{
			portfolioId: 1,
			slideId: 6,
			order: 6,
			type: 'cover',
			slideItems: [
				{
					slideId: 6,
					type: 'title',
					slideItemId: 13,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 24,
							order: 1,
							type: 'title',
							content: 'Subcolección de fuego',
						},
					],
				},
				{
					slideId: 6,
					type: 'text',
					slideItemId: 14,
					order: 2,
					slideItemElements: [
						{
							slideItemElementId: 25,
							order: 1,
							type: 'text',
							content:
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
						},
					],
				},
				{
					slideId: 6,
					type: 'image',
					slideItemId: 15,
					order: 3,
					slideItemElements: [
						{
							slideItemElementId: 26,
							order: 1,
							type: 'image_url',
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5227',
						},
					],
				},
			],
		},
		//artworkGroup - obras de subcolección 2.
		{
			portfolioId: 1,
			slideId: 7,
			order: 7,
			type: 'artworkGroup',
			slideItems: [
				{
					slideId: 7,
					type: 'artwork',
					slideItemId: 16,
					order: 1,
					slideItemElements: [
						{
							content: 'Los fuegos 1',
							order: 1,
							slideItemElementId: 27,
							type: 'title',
						},
						{
							content: 'Los fuegos de la naturaleza.',
							order: 2,
							slideItemElementId: 28,
							type: 'description',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 29,
							type: 'materials',
						},
						{
							slideItemElementId: 30,
							order: 4,
							type: 'technique',
							content: 'Lienzo',
						},
						{
							slideItemElementId: 31,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							content: '',
							order: 6,
							slideItemElementId: 32,
							type: 'date',
						},
						{
							content: '0',
							order: 7,
							slideItemElementId: 33,
							type: 'price',
						},
						{
							content: 'CONSERVADO',
							order: 8,
							slideItemElementId: 34,
							type: 'owner_status',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 9,
							slideItemElementId: 35,
							type: 'image_url',
						},
					],
				},
				{
					slideId: 7,
					type: 'artwork',
					slideItemId: 17,
					order: 2,
					slideItemElements: [
						{
							content: 'Los fuegos 2',
							order: 1,
							slideItemElementId: 36,
							type: 'title',
						},
						{
							content: 'Los fuegos de la naturaleza.',
							order: 2,
							slideItemElementId: 37,
							type: 'description',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 38,
							type: 'materials',
						},
						{
							slideItemElementId: 39,
							order: 4,
							type: 'technique',
							content: 'Lienzo',
						},
						{
							slideItemElementId: 40,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							content: '',
							order: 6,
							slideItemElementId: 41,
							type: 'date',
						},
						{
							content: '0',
							order: 7,
							slideItemElementId: 42,
							type: 'price',
						},
						{
							content: 'CONSERVADO',
							order: 8,
							slideItemElementId: 43,
							type: 'owner_status',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 9,
							slideItemElementId: 44,
							type: 'image_url',
						},
					],
				},
				{
					slideId: 7,
					type: 'artwork',
					slideItemId: 18,
					order: 3,
					slideItemElements: [
						{
							content: 'Los fuegos 3',
							order: 1,
							slideItemElementId: 45,
							type: 'title',
						},
						{
							content: 'Los fuegos de la naturaleza.',
							order: 2,
							slideItemElementId: 46,
							type: 'description',
						},
						{
							content: '',
							order: 3,
							slideItemElementId: 47,
							type: 'materials',
						},
						{
							slideItemElementId: 48,
							order: 4,
							type: 'technique',
							content: 'Lienzo',
						},
						{
							slideItemElementId: 49,
							order: 5,
							type: 'measures',
							content: '00x00x00cm',
						},
						{
							content: '',
							order: 6,
							slideItemElementId: 50,
							type: 'date',
						},
						{
							content: '0',
							order: 7,
							slideItemElementId: 51,
							type: 'price',
						},
						{
							content: 'CONSERVADO',
							order: 8,
							slideItemElementId: 52,
							type: 'owner_status',
						},
						{
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5257',
							order: 9,
							slideItemElementId: 53,
							type: 'image_url',
						},
					],
				},
			],
		},
		//Cover - presentación de subcolección 3.
		{
			portfolioId: 1,
			slideId: 8,
			order: 8,
			type: 'cover',
			slideItems: [
				{
					slideId: 8,
					type: 'title',
					slideItemId: 19,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 54,
							order: 1,
							type: 'title',
							content: 'Subcolección de aire',
						},
					],
				},
				{
					slideId: 8,
					type: 'text',
					slideItemId: 55,
					order: 2,
					slideItemElements: [
						{
							slideItemElementId: 25,
							order: 1,
							type: 'text',
							content:
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis turpis sagittis est in ac. Ac ipsum a nullam sed purus ante sit. Egestas metus, nunc pretium sagittis neque morbi tortor lorem posuere. Iaculis sit arcu massa iaculis amet nulla.',
						},
					],
				},
				{
					slideId: 8,
					type: 'image',
					slideItemId: 21,
					order: 3,
					slideItemElements: [
						{
							slideItemElementId: 56,
							order: 1,
							type: 'image_url',
							content:
								'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5227',
						},
					],
				},
			],
		},
		//Al no tener obras la subcolección 3, NO SE CREA ARTWORKGROUP SLIDE.

		//Back Cover - finalización del portafolio
		{
			portfolioId: 1,
			slideId: 9,
			order: 9,
			type: 'backCover',
			slideItems: [
				{
					slideId: 9,
					type: 'title',
					slideItemId: 22,
					order: 1,
					slideItemElements: [
						{
							slideItemElementId: 57,
							order: 1,
							type: 'title',
							content: 'Maria Camila Castaño',
						},
						{
							slideItemElementId: 58,
							order: 1,
							type: 'subtitle',
							content: 'maria@maria.co',
						},
					],
				},
			],
		},
	],
};

const configurationFormInitialValues = {
	show_title: '1',
	show_date: '1',
	show_technique: '1',
	show_measures: '1',
	show_prices: '1',
};

const configurationFormLabelValues = {
	show_title: 'Titulo',
	show_date: 'Fecha',
	show_technique: 'Técnica',
	show_measures: 'Medidas',
	show_prices: 'Precios',
};

const editFormLabelValues = {
	name: 'Titulo',
	slug: 'URL',
	permissions: 'Acceso',
};

const editFormValidationSchema = Yup.object({
	name: Yup.string().required('TituloRequerido'),
	slug: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('UrlObligatorio')
		.matches(/^[a-z0-9]+(?:[-,_][a-z0-9]+)*$/, 'UrlAlfanuméricaYGuiones'),
	permissions: Yup.string().required('AccesoRequerido'),
});

const editFormDefaultValuesToShow = {
	name: false,
	slug: false,
	permissions: false,
};

const editFormAccessValues = [
	{
		label: 'Público',
		value: 'PUBLIC',
	},
	{
		label: 'Privado',
		value: 'PRIVATE',
	},
];

export {
	configurationFormInitialValues,
	configurationFormLabelValues,
	editFormLabelValues,
	editFormValidationSchema,
	editFormDefaultValuesToShow,
	editFormAccessValues,
};
