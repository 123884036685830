import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import TextContentThird from '../../atoms/Text/Content/TextContentThird';

export default function DataPerfil({
	name = 'Nombre Apellido',
	email = 'correo@gmail.com',
	designName,
	designEmail,
	designPerfil,
}) {
	return (
		<div className='flex flex-col items-start md:mt-4 md:items-center'>
			<div className='md:hidden'>
				<TextContentThird
					text={name}
					design={designName}
					style={{ fontWeight: 600 }}
				/>
			</div>
			<div className='md:hidden'>
				<TextContentThird text={email} design={designEmail} />
			</div>
			<div className='hidden md:block'>
				<TextContentSecond
					text={name}
					design={designName}
					style={{ fontWeight: 600 }}
				/>
			</div>
			<div className='hidden md:block'>
				<TextContentSecond text={email} design={designEmail} />
			</div>
		</div>
	);
}
