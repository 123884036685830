/* 3rd party */
import PropTypes from 'prop-types';

/* Components */
import TextMainTitle from '../../molecules/Text/TextMainTitle';
import ButtonChangeView from '../../molecules/Buttons/ButtonChangeView';
import FilterButton from '../../atoms/Buttons/Link/FilterButton';

/* icons */
import { ReactComponent as Share } from '../../../UI/assets/icons/Share.svg';
import ButtonLinkBackText from '../../molecules/Buttons/Link/ButtonLinkBackText';

/**
 * @description Secondary NAVBAR.
 * Needs to be refactored.
 * @param {*} param0
 * @returns
 */
export default function SecondaryNavbar({
	title,
	color,
	textColor,
	redirect,
	hidden,
	changeView,
	setChangeView,
	Filter,
	data,
	filterArray,
	setFilterArray,
	filterChange,
	setFilterChange,
	nameFilter,
	setNameFilter,
	showShare,
	showFilter,
	showchangeView,
	textBack,
	textBackDesign,
	arrowLeftColor,
	backAction,
	children,
	containerDesign,
}) {
	return (
		<div
			className={`hidden h-20 w-full items-center justify-between px-4 md:flex md:h-32 ${containerDesign}`}
		>
			<ButtonLinkBackText
				design={`w-1/4 ${hidden}`}
				redirect={redirect}
				textBack={textBack}
				textBackDesign={textBackDesign}
				arrowLeftColor={arrowLeftColor}
				action={backAction}
			/>
			<div className='hidden md:block'>
				<TextMainTitle title={title} textColor={textColor} color={color} />
			</div>
			<div className='hidden md:flex md:w-1/4 md:flex-row md:justify-end'>
				{showShare ? <Share /> : ''}
				{showchangeView && (
					<ButtonChangeView
						changeView={changeView}
						setChangeView={setChangeView}
					/>
				)}
				{showFilter && (
					<FilterButton
						Filter={Filter}
						data={data}
						filterArray={filterArray}
						setFilterArray={setFilterArray}
						filterChange={filterChange}
						setFilterChange={setFilterChange}
						nameFilter={nameFilter}
						setNameFilter={setNameFilter}
					/>
				)}
				{children}
			</div>
		</div>
	);
}

SecondaryNavbar.propTypes = {
	/** Title of the Secondary Nav Bar */
	title: PropTypes.string,
	/* hr color of the title */
	color: PropTypes.string,
	/* style textColor of the title */
	textColor: PropTypes.string,
	/* url to redirect */
	redirect: PropTypes.string,
	/* tailwind style to hide a container */
	hidden: PropTypes.string,
	/* boolean that can change the view of elements */
	changeView: PropTypes.bool,
	/* function that change the value of changeView */
	setChangeView: PropTypes.func,
	/* boolean that reveals the filter icon or not */
	showFilter: PropTypes.bool,
	/* boolean that reveals the changeview icon or not */
	showchangeView: PropTypes.bool,
	/* text next to the arrow to specify the section to return */
	textBack: PropTypes.string,
	/* styles apply to the text next to arrow figure */
	textBackDesign: PropTypes.string,
	/* determine the color of the arrowLeft asset */
	arrowLeftColor: PropTypes.string,
	/* styles applied to the navbar container */
	containerDesign: PropTypes.string,
};

SecondaryNavbar.defaultProps = {
	showFilter: true,
	showchangeView: true,
};
