//Third parties

import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { useSelector } from 'react-redux';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextSubTitle from '../../molecules/Text/TextSubTitle';
import Cross from '../../assets/icons/Cross.svg';
import Check from '../../assets/icons/whiteCheck.svg';
import InputField from '../../molecules/Field/InputField';
import FieldDateFormik from '../../molecules/Field/FieldDateFormik';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '../../../UI/atoms/Inputs/ListItemText';
import { validationSchemaDocumentEdit } from '../../../BusinessLogic/data/DocumentSingle2';

const SectionEditDocument = ({
	colorHr,
	sectionTitle,
	informationArray,
	onSubmitAction,
	setTurnEdit,
	id,
}) => {
	const { t } = useTranslation();
	const { singleDocument } = useSelector((state) => state.documents);
	const { artists } = useSelector((state) => state.artists);
	const { artworks } = useSelector((state) => state.artworks);
	const { organization_user_id } = localStorage;
	const date = singleDocument.date;
	const newDate = date.split(' ')[0];

	const initialValuesDocument = {
		id,
		name: singleDocument.name,
		description: singleDocument.description,
		date: newDate,
		organization_user_id: organization_user_id,
		element: singleDocument.type ?? 'NO_ELEMENT',
		element_id: singleDocument.type_id ?? 0,
	};
	const handleNoneValue = (e, formProps) => {
		formProps.values.element_id = 0;
		formProps.values.element = e.target.value;
		formProps.setValues(formProps.values);
	};

	return (
		<div className='flex w-full flex-col '>
			<TextSubTitle
				text={sectionTitle}
				hasEdit={false}
				hasAdd={false}
				colour={colorHr}
			/>
			<div>
				<Formik
					enableReinitialize
					initialValues={initialValuesDocument}
					validationSchema={validationSchemaDocumentEdit}
					onSubmit={(values) => {
						if (
							values.element_id === 0 ||
							values.element === 'NO_ELEMENT' ||
							values.element_id === 'none'
						) {
							values = { ...values, element: 'NO_ELEMENT', element_id: 0 };
						}
						let input = { values, id };
						onSubmitAction(input);
						setTurnEdit(false);
					}}
				>
					{(formProps) => (
						<Form>
							<div className='mx-auto flex w-full flex-col justify-between '>
								<InputField item={informationArray[0]} key={1} />
								<InputField item={informationArray[1]} key={2} />
								<FieldDateFormik label={t('Fecha')} dbName={'date'} />

								<ListItem
									style={{
										paddingLeft: '0px',
										paddingRight: '0px',
										justifyContent: 'space-between',
									}}
								>
									<div className='pr-2 md:w-32'>
										<ListItemText
											primary={t('Pertenece')}
											design='body-Text2 gap-12'
											style={{ fontWeight: 400 }}
										/>
									</div>
									<Field
										name='element'
										type='Text'
										as='select'
										className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-75 md:w-56'
										onChange={(e) => handleNoneValue(e, formProps)}
									>
										<option value='NO_ELEMENT'>{t('Ninguno')}</option>
										{artists?.length > 0 && (
											<option value='ARTIST'>{t('Artista')}</option>
										)}
										{artworks?.length > 0 && (
											<option value='ARTWORK'>{t('Obras')}</option>
										)}
									</Field>
								</ListItem>

								<div className='flex flex-row items-center justify-between'>
									<div className='md:w-32'>
										<ListItemText
											primary={
												(formProps.values.element === t('ARTIST') &&
													t('Artista')) ||
												(formProps.values.element === t('ARTWORK') &&
													t('Obras'))
											}
											design='body-Text2 '
											style={{ fontWeight: 400 }}
										/>
									</div>

									{((formProps.values.element === t('ARTIST') &&
										artists?.length >= 1) ||
										(formProps.values.element === t('ARTWORK') &&
											artworks?.length >= 1)) && (
										<div className='flex w-40 md:w-56'>
											<Field
												name='element_id'
												type='Text'
												as='select'
												className='body-Text2 h-6 w-full rounded border-none bg-light_grey-75'
											>
												{formProps.values.element === t('ARTIST') && (
													<option value={'none'} key={0}>
														{'Seleccionar artista'}
													</option>
												)}
												{formProps.values.element === t('ARTIST') && (
													<>
														{artists.map((element) => (
															<option value={element.id} key={element.id + 1}>
																{element.name}
															</option>
														))}
													</>
												)}

												{formProps.values.element === t('ARTWORK') && (
													<option value={'none'} key={0}>
														{'Seleccionar obra'}
													</option>
												)}

												{formProps.values.element === t('ARTWORK') && (
													<>
														{artworks.map((element) => (
															<option value={element.id} key={element.id + 1}>
																{element.name}
															</option>
														))}
													</>
												)}
											</Field>
										</div>
									)}
								</div>

								<div className='my-5 flex w-full flex-row justify-center gap-3'>
									<ButtonFab
										label='Cancelar'
										colour='white'
										action={() => setTurnEdit(false)}
										icon={Cross}
									/>
									<ButtonFab
										colour='green'
										label='Guardar'
										icon={Check}
										type='submit'
									/>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

SectionEditDocument.propTypes = {
	/** title of the section*/
	sectionTitle: PropTypes.string,
	/** info used to create the report*/
	informationArray: PropTypes.array,
	/** action on submit */
	onSubmitAction: PropTypes.func,
	/** Color of Header in Subtitle */
	colorHr: PropTypes.string,
	/** check */
	setTurnEdit: PropTypes.func,
	/** id of the element */
	id: PropTypes.number,
	/** object used for validation */
};

export default SectionEditDocument;
