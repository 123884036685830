import { getArtists } from '../redux/reducers/artist';
import { getArtworks } from '../redux/reducers/artwork';
import { getDocuments } from '../redux/reducers/document';
import { getCollections } from '../redux/reducers/collection';
import { getPortfolios } from '../redux/reducers/portfolio';
import { getLocationsGeneral } from '../redux/reducers/locationGeneral';
import { getExpositions } from '../redux/reducers/exposition';
import { getContacts } from '../redux/reducers/contact';
import { deleteAllPortfolio } from '../redux/reducers/portfolio';

export const cleanData = (section, dispatch) => {
	switch (section) {
		case 'artistas':
			dispatch(getArtists());
			dispatch(getArtworks());
			dispatch(getCollections());
			dispatch(getPortfolios());
			break;
		case 'obras':
			dispatch(getArtworks());
			dispatch(getCollections());
			dispatch(getPortfolios());
			break;
		case 'contactos':
			dispatch(getContacts());
			break;
		case 'documentos':
			dispatch(getDocuments());
			break;
		case 'colecciones':
			dispatch(getCollections());
			dispatch(getPortfolios());
			break;
		case 'lugares':
			dispatch(getLocationsGeneral());
			break;
		case 'exposiciones':
			dispatch(getExpositions());
			break;
		case 'salas':
			dispatch(getLocationsGeneral());
			break;
		case 'ubicacion':
			dispatch(getLocationsGeneral());
			break;
		case 'portafolios':
			dispatch(deleteAllPortfolio());
			dispatch(getPortfolios());
			break;

		default:
			break;
	}
};
