//3rd party
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

//BL
import { getDocuments } from '../../../BusinessLogic/redux/reducers/document';

//UI
import FilterDocument from './FilterDocument';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import SecondaryNavbar from '../../../UI/organisms/Navbar/NavbarSecondary';
import ButtonChangeView from '../../../UI/molecules/Buttons/ButtonChangeView';
import FilterButton from '../../../UI/atoms/Buttons/Link/FilterButton';
import DocumentSectionWithFilter from '../../../UI/organisms/Document/DocumentSectionWithFilter';
import ButtonFabPrincipalUrl from '../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import ViewAllSkeleton from '../../../UI/templates/SkeletonTemplates/ViewAllSkeleton';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

/**
 * @description render document all view
 * @param {*} param0
 * @returns
 */
export default function AllDocuments() {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { value } = useSelector((state) => state.artistFilter);
	const { documents, status } = useSelector((state) => state.documents);

	const [changeView, setChangeView] = useState(false);
	const [filterArray, setFilterArray] = useState({});
	const [filterChange, setFilterChange] = useState(0);
	const [nameFilter, setNameFilter] = useState(value.payload);

	useEffect(() => {
		if (!documents) {
			const { organization_user_id } = localStorage;
			organization_user_id && dispatch(getDocuments());
		}
		//eslint-disable-next-line
	}, []);

	return (
		<>
			{!documents && status === 'loading' && (
				<ViewAllSkeleton
					numberOfImages={documents?.length}
					designSkeletonTitle4={'invisible'}
					designSkeletonImages={
						'w-full px-4 grid grid-cols-3 sm:grid-cols-5 md:grid-cols-7 2xl:grid-cols-9 gap-x-2 gap-y-0 justify-center'
					}
				/>
			)}
			{documents && (
				<TemplateWithNavBar section={'Documentos'}>
					<SecondaryNavbar
						title={t('Documentos')}
						color='bg-light_green-50'
						textColor='text-light_green-50'
						redirect='home'
						hidden='invisible'
						changeView={changeView}
						setChangeView={setChangeView}
						Filter={FilterDocument}
						data={documents}
						filterArray={filterArray}
						setFilterArray={setFilterArray}
						filterChange={filterChange}
						setFilterChange={setFilterChange}
						nameFilter={nameFilter}
						setNameFilter={setNameFilter}
					/>
					<div className='flex w-full flex-col items-center justify-between px-4 xs:mb-5 xs:h-12 xs:flex-row md:hidden'>
						<div className='invisible w-16'></div>
						<TextMainTitle
							title={t('Documentos')}
							textColor='text-light_green-50'
							color='bg-light_green-50'
						/>
						<div className='my-3 flex w-full flex-row justify-between xs:my-0 xs:w-auto'>
							<ButtonChangeView
								changeView={changeView}
								setChangeView={setChangeView}
							/>
							<FilterButton
								Filter={FilterDocument}
								data={documents}
								filterArray={filterArray}
								setFilterArray={setFilterArray}
								filterChange={filterChange}
								setFilterChange={setFilterChange}
								nameFilter={nameFilter}
								setNameFilter={setNameFilter}
							/>
						</div>
					</div>
					<DocumentSectionWithFilter
						data={documents}
						redirect='documentos'
						filterChange={filterChange}
						filterArray={filterArray}
						nameFilter={nameFilter}
						changeView={changeView}
						newEntityLink='/documentos/new'
					/>
					<ButtonFabPrincipalUrl label={t('Crear')} url='/documentos/new' />
				</TemplateWithNavBar>
			)}
		</>
	);
}
