import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	navigationHistoryURL: [],
	previousPagePathName: '/',
};

export const navigationSlice = createSlice({
	name: 'navigation',
	initialState,
	reducers: {
		saveSectionURL: (state, action) => {
			const newURL = action.payload;
			const navURLhistory = state.navigationHistoryURL;
			navURLhistory.push(newURL);
			state.navigationHistoryURL = [...state.navigationHistoryURL];
		},
		backSectionURL: (state) => {
			const navURLhistory = state.navigationHistoryURL;
			navURLhistory.pop();
			state.navigationHistoryURL = [...state.navigationHistoryURL];
		},
		resetHistoryURL: (state) => {
			state.navigationHistoryURL = [];
		},
		setPreviousPagePathName: (state, { payload }) => {
			state.previousPagePathName = payload;
		},
	},
});

export const {
	saveSectionURL,
	backSectionURL,
	resetHistoryURL,
	setPreviousPagePathName,
} = navigationSlice.actions;

export default navigationSlice.reducer;
