//3rd parties
import PropTypes from 'prop-types';

/**
 * @description Body text 3
 * @param {*} param0
 * @returns
 */

export default function TextContentThird({ text, design, style }) {
	return (
		<p className={` text-sm font-light ${design} `} style={style}>
			{text}
		</p>
	);
}

TextContentThird.propTypes = {
	/** Text of the component */
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/** Tailwind className attributes */
	design: PropTypes.string,
	/** Style attributes */
	style: PropTypes.object,
};
