import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// BL
import { restorePasswordByToken } from '../../../BusinessLogic/redux/reducers/appUser';
import { resetPasswordSchema } from '../../../BusinessLogic/data/PasswordRecoveryData';
// UI
import AuthenticationTemplate from '../../../UI/templates/AuthenticationTemplate';
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import ModalWithTextAndLink from '../../../UI/templates/ModalWithTextAndLink';
import SpinnerWhite from '../../../UI/assets/icons/loadingSpinnerSmallWhite.svg';
import { ReactComponent as EyeGrey } from '../../../UI/assets/icons/EyeGrey.svg';
import { ReactComponent as EyeSlashGrey } from '../../../UI/assets/icons/EyeSlashGrey.svg';

const ResetPassword = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { token } = useParams();
	const { status } = useSelector((state) => state.appUser);

	const [showModalWithTextAndLink, setShowModalWithTextAndLink] =
		useState(false);
	const [isAnError, setIsAnError] = useState(false);
	const [isANewPasswordCreated, setIsANewPasswordCreated] = useState(false);
	const [isTheEmailAndTokenAlreadySent, setIsTheEmailAndTokenAlreadySent] =
		useState(false);
	const [isTheTokenExpired, setIsTheTokenExpired] = useState(false);

	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
		useState(false);

	const togglePassword = (name) => {
		if (name === 'password') {
			setIsPasswordVisible(!isPasswordVisible);
		} else if (name === 'confirm_password') {
			setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
		}
	};
	const handleSubmit = (values) => {
		const input = { values: { password: values.password }, token: token };

		dispatch(restorePasswordByToken(input)).then((res) => {
			switch (res.payload.status) {
				case 'password_created':
					setIsANewPasswordCreated(true);
					setShowModalWithTextAndLink(true);
					break;
				case 'expired_token':
					setShowModalWithTextAndLink(true);
					setIsTheTokenExpired(true);
					break;
				case 'used_token':
					setShowModalWithTextAndLink(true);
					setIsTheEmailAndTokenAlreadySent(true);
					break;

				default:
					break;
			}

			if (res?.payload?.response?.status === 404) {
				setShowModalWithTextAndLink(true);
				setIsAnError(true);
			}
		});
	};

	return (
		<AuthenticationTemplate>
			<div className='py-8'>
				<TextTitleSub
					title={t('RestablecerContraseña')}
					design={'w-full text-center text-green-50'}
				/>

				<Formik
					initialValues={{
						password: '',
						confirm_password: '',
					}}
					onSubmit={(values) => {
						handleSubmit(values);
					}}
					validationSchema={resetPasswordSchema}
				>
					<Form>
						<div className='mx-[5%] flex justify-between pb-4 pt-8'>
							<p className='body-Text2'>{t('NuevaContraseña')}</p>
							<div className='relative flex w-1/2 flex-col'>
								<Field
									className='body-Text2 h-6 rounded border-none bg-light_grey-75'
									type={isPasswordVisible ? 'text' : 'password'}
									name='password'
								/>
								<div
									className='absolute right-1 top-1 cursor-pointer'
									onClick={() => togglePassword('password')}
								>
									{isPasswordVisible ? <EyeGrey /> : <EyeSlashGrey />}
								</div>
								<ErrorMessage
									name='password'
									render={(msg) => (
										<div className='body-Text3 text-red-400'>{t(msg)}</div>
									)}
								/>
							</div>
						</div>
						<div className='mx-[5%] flex  justify-between'>
							<p className='body-Text2'>{t('ConfirmarLaNuevaContraseña')}</p>
							<div className='relative flex  w-1/2 flex-col'>
								<Field
									className='body-Text2 h-6 rounded border-none bg-light_grey-75'
									type={isConfirmPasswordVisible ? 'text' : 'password'}
									name='confirm_password'
								/>
								<div
									className='absolute right-1 top-1 cursor-pointer'
									onClick={() => togglePassword('confirm_password')}
								>
									{isConfirmPasswordVisible ? <EyeGrey /> : <EyeSlashGrey />}
								</div>

								<ErrorMessage
									name='confirm_password'
									render={(msg) => (
										<div className='body-Text3 text-red-400'>{t(msg)}</div>
									)}
								/>
							</div>
						</div>
						<div className='mt-8 flex justify-center'>
							<ButtonFab
								disabled={status === 'loading' ? true : false}
								icon={status === 'loading' ? SpinnerWhite : ''}
								label={t('CambiarMiContraseña')}
								type='submit'
								size={'large'}
							/>
						</div>
					</Form>
				</Formik>
				{isTheEmailAndTokenAlreadySent && showModalWithTextAndLink && (
					<ModalWithTextAndLink
						thereIsALink={true}
						linkText={t('OlvidasteTuContraseña')}
						thereIsAQuestionAndEmail={false}
						h3Text={t('ClickEn')}
						setShowModalWithTextAndLink={setShowModalWithTextAndLink}
						text={t('NoSePuedeCambiarLaContraseña')}
					/>
				)}
				{isAnError && showModalWithTextAndLink && (
					<ModalWithTextAndLink
						thereIsALink={false}
						thereIsAQuestionAndEmail={true}
						setShowModalWithTextAndLink={setShowModalWithTextAndLink}
						text={t('SeHaProducidoUnError')}
					/>
				)}
				{isANewPasswordCreated && showModalWithTextAndLink && (
					<ModalWithTextAndLink
						thereIsALink={true}
						thereIsAQuestionAndEmail={false}
						h3Text={t('ClickEn')}
						linkText={'Login Datarte'}
						linkRedirection={'/'}
						setShowModalWithTextAndLink={setShowModalWithTextAndLink}
						text={t('CambioDeContraseñaRealizadoCorrectamente')}
					/>
				)}
				{isTheTokenExpired && showModalWithTextAndLink && (
					<ModalWithTextAndLink
						thereIsALink={true}
						linkText={t('OlvidasteTuContraseña')}
						thereIsAQuestionAndEmail={false}
						h3Text={t('ClickEn')}
						setShowModalWithTextAndLink={setShowModalWithTextAndLink}
						text={t('solicitudCambioDeContraseñaACaducado')}
					/>
				)}
			</div>
		</AuthenticationTemplate>
	);
};

export default ResetPassword;
