// 3rd party
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// UI
import SectionImageGrid from '../Section/SectionImageGrid';
import { ReactComponent as AddIcon } from '../../assets/icons/blackAdd.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/blackDownload.svg';
import { ReactComponent as DeleteBlack } from '../../assets/icons/DeleteBlack.svg';
import { ReactComponent as BlackGridView } from '../../assets/icons/BlackGridView.svg';
import DropZoneModal from '../../molecules/DropZone/DropZoneModal';
import { useSelector } from 'react-redux';

/**
 * @description renders a tab with an image grid section or its edit version
 * @param {*} param0
 * @returns
 */

const TabImageAndEdit = ({
	information,
	indexInfo,
	color,
	AddIconLink,
	createImagesFunction,
	deleteImagesFunction,
	submitOrganizeImageGrid,
	submitOrganizeArtworksGrid,
	submitOrganizeSubCollections,
	saveLastSectionURL,
	saveURLHistory,
	objectFitProp,
	section,
	addImageAction,
	isSectionEditable,
}) => {
	const { t } = useTranslation();
	const location = useLocation();
	//edit button states
	const [showEditPopup, setShowEditPopup] = useState(false);
	const [hasEdit, setHasEdit] = useState(false);
	const [showDeleteOption, setShowDeleteOption] = useState(false);
	const [showOrganizeGridOption, setShowOrganizeGridOption] = useState(false);
	//three dots button states
	const [showDotsPopup, setShowDotsPopup] = useState(false);
	const [hasDots, setHasDots] = useState(isSectionEditable ?? false);
	const [showDownloadOptions, setShowDownloadOptions] = useState(false);
	const [showDropZoneModal, setShowDropZoneModal] = useState(false);
	const [dropZoneFiles, setDropZoneFiles] = useState([]);
	const [hasAdd, setHasAdd] = useState(false);

	const { collectionSingle } = useSelector((state) => state.collections);
	const isDefaultGeneralCollection = collectionSingle?.is_general_collection;

	const isCollectionPage = location.pathname.includes('colecciones');
	const isImagenTab = information.tab === 'Imágenes';
	const isImagenTabInCollectionPage = isImagenTab && isCollectionPage;

	const collectAndCreateImage = () => {
		createImagesFunction(dropZoneFiles);
		setDropZoneFiles([]);
		setShowDropZoneModal(false);
	};

	const dotsOptionsInArtworksTab = [
		{
			label:
				information.tab === 'Subcolecciones'
					? `${t('OrganizarSubColecciones')}`
					: `${t('OrganizarObras')}`,
			action: () => {
				setShowDotsPopup(false);
				setShowOrganizeGridOption(!showOrganizeGridOption);
			},
			icon: <BlackGridView className='h-6 w-6' />,
		},
	];

	const dotsOptionsInImageTab = [
		{
			label: `${t('EliminarImágenes')}`,
			action: () => {
				setShowEditPopup(false);
				setShowDeleteOption(!showDeleteOption);
				setShowDownloadOptions(false);
				setShowOrganizeGridOption(false);
			},
			icon: <DeleteBlack className='h-6 w-6' />,
		},
		{
			label: `${t('OrganizarImágenes')}`,
			action: () => {
				setShowEditPopup(false);
				setShowOrganizeGridOption(!showOrganizeGridOption);
				setShowDownloadOptions(false);
				setShowDeleteOption(false);
			},
			icon: <BlackGridView className='h-6 w-6' />,
		},
		{
			label: `${t('DescargarImagen')}`,
			action: () => {
				setShowDownloadOptions(!showDownloadOptions);
				setShowDotsPopup(false);
				setShowDeleteOption(false);
				setShowOrganizeGridOption(false);
			},
			icon: <DownloadIcon className='h-6 w-6' />,
		},
	];

	const dotsOptionsInImageTabInCollectionPage = [
		{
			label: `${t('DescargarImagen')}`,
			action: () => {
				setShowDownloadOptions(!showDownloadOptions);
				setShowDotsPopup(false);
				setShowDeleteOption(false);
				setShowOrganizeGridOption(false);
			},
			icon: <DownloadIcon className='h-6 w-6' />,
		},
	];

	const editIconOptions = [
		{
			label: `${t('AñadirNuevaImagen')}`,
			action: () => {
				if (addImageAction) {
					addImageAction();
				} else {
					setShowDropZoneModal(true);
					setShowDotsPopup(false);
				}
			},
			icon: <AddIcon />,
		},
	];

	useEffect(() => {
		//default layout - only +
		setHasAdd(true);
		setHasEdit(false);
		setHasDots(isSectionEditable ?? false);
		setShowDownloadOptions(false);
		setShowOrganizeGridOption(false);
		setShowDeleteOption(false);
		//specific layout by tab
		if (information.tab === 'Imágenes') {
			setHasEdit(true);
			setHasAdd(false);
			setHasDots(isSectionEditable ?? true);
		} else if (information.tab === 'Subcolecciones') {
			setHasDots(isSectionEditable ?? true);
			setHasEdit(false);
			setHasAdd(true);
		} else if (section === 'Collection') {
			setHasDots(isSectionEditable ?? true);
			setHasEdit(false);
			setHasAdd(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [indexInfo, information.tab, isSectionEditable]);

	return (
		<div className='flex min-h-[30%] flex-col overflow-hidden bg-light_grey-50 p-4 px-8'>
			{showDropZoneModal && (
				<DropZoneModal
					showDropZoneModal={showDropZoneModal}
					setShowDropZoneModal={setShowDropZoneModal}
					dropZoneFiles={dropZoneFiles}
					collectAndCreateImage={collectAndCreateImage}
					setDropZoneFiles={setDropZoneFiles}
				/>
			)}
			<SectionImageGrid
				section={section}
				sectionTitle={t(information.tab)}
				images={information.content}
				colorHr={color}
				hasEdit={hasEdit}
				setHasEdit={setHasEdit}
				showDotsPopup={showDotsPopup}
				setShowDotsPopup={setShowDotsPopup}
				AddIconLink={AddIconLink}
				indexInfo={indexInfo}
				hasAdd={hasAdd}
				hasDots={hasDots}
				dotsOptionsInTab={
					isImagenTabInCollectionPage
						? dotsOptionsInImageTabInCollectionPage
						: dotsOptionsInImageTab
				}
				dotsOptionsInArtworksTab={dotsOptionsInArtworksTab}
				showDownloadOptions={showDownloadOptions}
				setShowDownloadOptions={setShowDownloadOptions}
				deleteImagesFunction={deleteImagesFunction}
				editIconOptions={editIconOptions}
				setShowEditPopup={setShowEditPopup}
				showEditPopup={showEditPopup}
				setShowOrganizeGridOption={setShowOrganizeGridOption}
				showOrganizeGridOption={showOrganizeGridOption}
				submitOrganizeImageGrid={submitOrganizeImageGrid}
				submitOrganizeArtworksGrid={submitOrganizeArtworksGrid}
				submitOrganizeSubCollections={submitOrganizeSubCollections}
				showDeleteOption={showDeleteOption}
				setShowDeleteOption={setShowDeleteOption}
				saveLastSectionURL={saveLastSectionURL}
				saveURLHistory={saveURLHistory}
				objectFitProp={objectFitProp}
				isDefaultGeneralCollection={isDefaultGeneralCollection}
			/>
		</div>
	);
};

export default TabImageAndEdit;

TabImageAndEdit.propTypes = {
	/* object with title, type, and content of the tab*/
	information: PropTypes.object,
	/* number that representes the tab that is displayed */
	indexInfo: PropTypes.number,
	/* color used in title and hr in the section */
	color: PropTypes.string,
	/**  string with the link in the add (+) icon */
	AddIconLink: PropTypes.string,
	/**  function that create new images in Images Tab */
	createImagesFunction: PropTypes.func,
	/**  function that delete a single image in Images Tab */
	deleteImagesFunction: PropTypes.func,
	/**  function that organize the grid of images in Images Tab */
	submitOrganizeImageGrid: PropTypes.func,
	/**  function that organize the grid of artworks in documents Tab */
	submitOrganizeArtworksGrid: PropTypes.func,
	/**  bool that handle to save the actual specific location */
	saveLastSectionURL: PropTypes.bool,
	/* function that execute to save the actual url in redux state */
	saveURLHistory: PropTypes.func,
	/** determines the object fit of the pre-visualization images */
	objectFitProp: PropTypes.string,
	/**Function that replaces the default add image action*/
	addImageAction: PropTypes.func,
	/**Boolean prop that determines if the image section can be edited*/
	isSectionEditable: PropTypes.bool,
	/**Boolean prop that determines if the image section is located on default general collection*/
	isDefaultGeneralCollection: PropTypes.bool,
};

TabImageAndEdit.defaultProps = {
	color: 'purple',
	saveLastSectionURL: false,
	objectFitProp: 'cover',
};
