import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//3rd party
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

//BL
import { backSectionURL } from '../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../BusinessLogic/helpers/textGoBackGenerator';
import { fetchGetData } from '../../../BusinessLogic/hooks/fetchGetData';
import {
	ENDPOINTS_CITY,
	ENDPOINTS_COUNTRY,
} from '../../../BusinessLogic/helpers/routes';
import {
	expositionFormValidationSchema,
	expositionInitialValues,
} from '../../../BusinessLogic/data/ExpositionSingle';
import {
	getExpositions,
	postExposition,
} from '../../../BusinessLogic/redux/reducers/exposition';

//UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import whiteCheck from '../../../UI/assets/icons/whiteCheck.svg';
import ListItemText from '../../../UI/atoms/Inputs/ListItemText';
import FieldTextFormik from '../../../UI/molecules/Field/FieldTextFormik';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import SectionForm from '../../../UI/organisms/Section/SectionForm';
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import ImageSectionForm from '../../../UI/organisms/Image/ImageSectionForm';
import LoadingText from '../../../UI/molecules/Loading/LoadingText';
import FieldTextAreaFormik from '../../../UI/molecules/Field/FieldTextAreaFormik';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

/**
 * @description Expositions  Form to manage new exposition  and edit
 * @param {*} param0
 * @returns
 */
function ExpositionsForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { singleExposition } = useSelector((state) => state.expositions);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);

	const [cities, setCities] = useState([]);
	const [countries, setCountries] = useState([]);
	const [showSaving, setShowSaving] = useState(false);

	const { organization_user_id, unknown_values } = localStorage;
	const unknown = JSON.parse(unknown_values);

	const initialValues = {
		...expositionInitialValues,
		organization_user_id,
		main_picture_id: unknown.picture_id,
		type: 'EXPOSITION',
		type_id: singleExposition?.id ?? 0,
		file_type: 'IMAGE',
	};

	const checkRedirect = () => {
		let redirect = '/exposiciones';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage = 'RegresarExposiciones';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return resultMessage;
	};

	useEffect(() => {
		fetchGetData(ENDPOINTS_COUNTRY.GET_COUNTRIES).then((data) =>
			setCountries(data)
		);
		fetchGetData(`${ENDPOINTS_CITY.GET_CITIES}47/`).then((data) =>
			setCities(data)
		);
	}, [organization_user_id]);

	const handleCountry = (e, formProps) => {
		const countryId = e.target.value;
		fetchGetData(`${ENDPOINTS_CITY.GET_CITIES}${countryId}/`).then((data) =>
			setCities(data)
		);
		formProps.values.country_id = countryId;
		formProps.setValues(formProps.values);
	};

	return (
		<TemplateWithNavBar>
			<div className={showSaving && 'opacity-60'}>
				<SecondaryNavbarSpecific
					title={t('NuevaExposición')}
					textColor='text-green-50'
					color='bg-green-50'
					hide='md:invisible'
					redirect={checkRedirect()}
					textBack={CheckTextBack()}
					textBackDesign='pl-3 text-green-50'
					arrowLeftColor='green'
					backAction={() => returnBackAction()}
				/>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={expositionFormValidationSchema}
					onSubmit={(values) => {
						setShowSaving(true);
						dispatch(postExposition(values))
							.then((res) => {
								navigate(`/exposiciones/${res.payload.id}`);
								dispatch(getExpositions());
							})
							.finally(() => setShowSaving(false));
					}}
				>
					{(formProps) => (
						<Form className='mb-20'>
							<List>
								<div className='flex flex-col gap-4 md:flex-row lg:gap-12'>
									<ImageSectionForm
										formProps={formProps}
										dropzoneLabel={'SoltarOClickImagenPortadaExposicion'}
										mobileDropzoneLabel={'ClickCargarImagenPortadaExposicion'}
									/>
									<div className='mx-auto flex w-full flex-col md:w-1/2'>
										<SectionForm
											title={t('Identificación')}
											textColor='text-green-50'
											color='bg-green-50'
										>
											<FieldTextFormik
												label={t('Título')}
												dbName='name'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.name}
											/>
											<FieldTextFormik
												label={t('FechaDeInicio')}
												dbName='initial_date'
												type='Date'
											/>
											<FieldTextFormik
												label={t('FechaFinalización')}
												dbName='ending_date'
												type='Date'
											/>
											<FieldTextFormik
												label={t('Lugar')}
												dbName='location'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.location}
											/>
											<ListItem
												style={{
													paddingLeft: '0px',
													paddingRight: '0px',
													justifyContent: 'space-between',
												}}
											>
												<ListItemText primary={t('País')} design={'text-sm'} />
												<Field
													name='country_id'
													type='Text'
													as='select'
													onChange={(e) => handleCountry(e, formProps)}
													className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
												>
													<option value=''></option>
													{countries.map((country) => (
														<option value={country.id}>{country.name}</option>
													))}
												</Field>
											</ListItem>
											{formProps.errors?.country_id && (
												<div className={`ml-auto w-40 text-red-400 md:w-56`}>
													{t(formProps.errors?.country_id)}
												</div>
											)}
											<ListItem
												style={{
													paddingLeft: '0px',
													paddingRight: '0px',
													justifyContent: 'space-between',
												}}
											>
												<ListItemText
													design={'text-sm'}
													primary={t('Ciudad')}
												/>
												<Field
													name='city_id'
													type='Text'
													as='select'
													className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
												>
													<option value=''></option>
													{cities.length > 1 &&
														cities.map((city) => (
															<option y={city.id} value={city.id}>
																{city.name}
															</option>
														))}
												</Field>
											</ListItem>
											{formProps.errors?.city_id && (
												<div className={`ml-auto w-40 text-red-400 md:w-56`}>
													{t(formProps.errors?.city_id)}
												</div>
											)}
										</SectionForm>
										<SectionForm
											title={t('Descripción')}
											textColor='text-green-50'
											color='bg-green-50'
										>
											<FieldTextAreaFormik
												label='description'
												dbName='description'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.description}
											/>
										</SectionForm>
										{showSaving ? (
											<LoadingText text={t('Guardando')} />
										) : (
											<ButtonFabExtendedPrimary
												className='self-center text-white'
												icon={whiteCheck}
												text={t('Guardar')}
											/>
										)}
									</div>
								</div>
							</List>
						</Form>
					)}
				</Formik>
			</div>
		</TemplateWithNavBar>
	);
}

export default ExpositionsForm;
