import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { isValid } from 'date-fns';

// BL
import { useWindowSize } from '../../../BusinessLogic/hooks/useWindowSize';

// UI
import CardCall from '../../molecules/Card/CardCall';
import ButtonLinkSeeMore from '../../atoms/Buttons/Link/ButtonLinkSeeMore';
import TextTitlePrincipal from '../../atoms/Text/Titles/TextTitlePrincipal';
import Calendar from '../Calendar/Calendar';
import CalendarEventCard from '../../molecules/Calendar/CalendarEventCard';

const SectionArtistHome = ({ calls, calendarEvents }) => {
	useWindowSize();
	const { t } = useTranslation();
	const calendarContainerRef = useRef();

	const isValidCalendarEventsArray =
		calendarEvents && Array.isArray(calendarEvents);

	const isValidCallsArray = calls && Array.isArray(calls);

	const twoDaysAgo = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);
	const currentCalendarEvents =
		isValidCalendarEventsArray &&
		calendarEvents?.filter(
			(event) =>
				isValid(new Date(event.date)) && new Date(event.date) >= twoDaysAgo
		);
	const calendarMeasure = String(calendarContainerRef?.current?.offsetHeight);

	return (
		<div className='flex h-full w-full flex-col gap-5 md:mt-10'>
			<div className='px-2'>
				<TextTitlePrincipal
					title={t('ConvocatoriasAbiertas')}
					design='text-left w-full text-[20px]'
					textColor='text-purple-50'
				/>
				<ButtonLinkSeeMore
					link={'/convocatorias'}
					design='border-solid border border-black-50 text-black-50 rounded-md hover:bg-light_purple-50  py-1 mt-4 sm:mt-auto'
				/>
			</div>
			<div
				className={'mx-auto grid grid-cols-1 gap-8 px-2 pt-4 md:grid-cols-3'}
			>
				{isValidCallsArray &&
					calls.map((call, index) => (
						<div key={index} className='m-0 flex p-0'>
							<CardCall
								key={call.id}
								id={call.id}
								slug={call.route_slug}
								organizationName={call.organization_name}
								organizationMainPictureUrl={call.call_organization_image}
								callName={call.name}
								city={call.city}
								country={call.country}
								callMainPictureUrl={call.main_picture_url}
								closeDate={call.closes_at}
								category={call.category}
								technique={call.technique}
								status={call.status}
								description={call.sub_header}
								hasAlliance={call.has_datarte_alliance}
							/>
						</div>
					))}
			</div>
			<div className='py-8 px-2'>
				<TextTitlePrincipal
					title={t('LoQueEstaPasando')}
					design='text-left w-full text-[20px]'
					textColor='text-purple-50'
				/>
			</div>
			<div className='mx-2 grid h-full grid-cols-1 border py-6 px-3 md:grid-cols-2  '>
				<div className='h-fit  ' ref={calendarContainerRef}>
					<Calendar calendarEvents={currentCalendarEvents} />
				</div>
				<div
					className='mt-8 
				 w-full overflow-y-auto md:mt-0'
					style={{ height: `${calendarMeasure}px` }}
				>
					{isValidCalendarEventsArray &&
						currentCalendarEvents?.map((calendarEvent, index) => (
							<div key={index} className={`mb-4 w-full md:px-8  `}>
								<CalendarEventCard calendarEvent={calendarEvent} />
							</div>
						))}
				</div>
			</div>
		</div>
	);
};
SectionArtistHome.propTypes = {
	/** Array with the calls to be display in the component*/
	calls: PropTypes.array,
	/** Array with the calendar events to be display in the component*/
	calendarEvents: PropTypes.array,
};

export default SectionArtistHome;
