import * as Yup from 'yup';

export const showActivePlanModalKey = `showActivePlanModal`;

//singleArtist
const artistSingleTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'cityCountry',
				field: 'identification',
				information: [
					{ label: 'Nombres', value: '', field: 'first_name' },
					{ label: 'Apellidos', value: '', field: 'last_name' },
					{
						label: 'PaisDeOrigen',
						value: '',
						field: 'country_id',
						type: 'dropdown',
						options: [],
					},
					{
						label: 'Ciudad',
						value: '',
						field: 'city_id',
						type: 'dropdown',
						options: [],
					},
					{ label: 'Teléfono', value: '', field: 'phone_number' },
					{ label: 'Email', value: '', field: 'email' },
				],
			},
			{
				title: 'Contacto',
				type: 'labels',
				field: 'contact',
				information: [
					{ label: 'WhatsApp', value: '', field: 'whatsapp_contact' },
					{ label: 'Instagram', value: '', field: 'instagram_contact' },
					{ label: 'Facebook', value: '', field: 'facebook_contact' },
					{ label: 'Twitter', value: '', field: 'twitter_contact' },
					{ label: 'LinkedIn', value: '', field: 'linkedin_contact' },
					{ label: 'PaginaWeb', value: '', field: 'website_contact' },
				],
			},
			{
				title: 'Biografía',
				type: 'text',
				field: 'biography',
				information: '',
			},
		],
	},
	{
		tab: 'Descripción',
		type: 'Information',
		content: [
			{
				title: 'Educación',
				type: 'text',
				field: 'education',
				information: '',
			},
			{
				title: 'Residencias',
				type: 'text',
				field: 'residencies',
				information: '',
			},
			{
				title: 'Exposiciones',
				type: 'text',
				field: 'exhibitions',
				information: '',
			},
			{
				title: 'Publicaciones',
				type: 'text',
				field: 'publications',
				information: '',
			},
			{
				title: 'ProyectosEspeciales',
				type: 'text',
				field: 'projects',
				information: '',
			},
		],
	},
	{
		tab: 'Obras',
		type: 'Artworks',
		content: [
			{
				title: 'Obras',
				type: 'images',
				field: 'artworks',
				information: [],
			},
		],
	},
	{
		tab: 'Documentos',
		type: 'Documents',
		content: [
			{
				title: 'Documentos',
				type: 'images',
				field: 'documents',
				information: [],
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const buttonsArtist = [
	'Identificación',
	'Descripción',
	'Obras',
	'Documentos',
	'Imágenes',
];

const buttonsGalleryArtists = [
	'Identificación',
	'Descripción',
	'Obras',
	'Imágenes',
];

const artistValidationSchema = [
	//this needs to have the same order than the buttons
	[
		//and the same order than the appearance of the content sections
		//far from elegant
		{
			first_name: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.required('NombreEsNecesario'),
			last_name: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.required('ApellidoNecesario'),
			phone: Yup.string().min(7, 'TeléfonoValido').nullable(),
			email: Yup.string().email('CorreoValido').nullable(),
			country_id: Yup.number().min(1, ''),
			city_id: Yup.number().min(1, ''),
		},
		{
			whatsapp_contact: Yup.string().min(7, 'TeléfonoValido').nullable(),
			instagram_contact: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.nullable(),
			facebook_contact: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			twitter_contact: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			linkedin_contact: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			website_contact: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
		},
		{
			biography: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
		},
	],
	[
		{
			education: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
		},
		{
			residencies: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
		},
		{
			exhibitions: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
		},
		{
			publications: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
		},
		{
			projects: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
		},
	],
];

const artistFormValidationSchema = Yup.object({
	first_name: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('NombreRequerido'),
	last_name: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('ApellidoRequerido'),
	biography: Yup.string().max(10000, 'NumeroMáximoCaracteres').nullable(),
	education: Yup.string().max(10000, 'NumeroMáximoCaracteres').nullable(),
	exhibitions: Yup.string().max(10000, 'NumeroMáximoCaracteres').nullable(),
	publications: Yup.string().max(10000, 'NumeroMáximoCaracteres').nullable(),
});

const artistInitialValues = {
	first_name: '',
	last_name: '',
	organization_user_id: '',
	main_picture_id: '',
	main_picture_url: '',
	biography: '',
	exhibitions: '',
	publications: '',
	education: '',
	name_image: '',
	description_image: '',
};

export {
	buttonsArtist,
	buttonsGalleryArtists,
	artistSingleTabs,
	artistValidationSchema,
	artistFormValidationSchema,
	artistInitialValues,
};
