import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// UI
import ButtonFabExtendedPrimary from '../../molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import SelectionContainer from '../../molecules/container/SelectionContainer';

// BL
import {
	titleTextSelected,
	subtitleTextSelected,
	textSimpleSelected,
} from '../../../BusinessLogic/data/EditablePortfolio/EditablePortfolioTextData';
import { onSubmitTextElement } from '../../../BusinessLogic/helpers/editablePortfolio';
import {
	getPortfolioToEdit,
	setSlideElement,
} from '../../../BusinessLogic/redux/reducers/editablePortfolio';
import { putPortfolioSlide } from '../../../BusinessLogic/redux/reducers/editablePortfolio';

/**
 * It's a function that returns a div with a title, a subtitle and a simple text, and a button to add
 * the selected text to the page
 * @returns A component that allows the user to select a text element to add to the page.
 */

const EditablePortfolioText = ({
	setIndexEditablePortfolioButton,
	setShowSideModal,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	let [valuesSlide, setValuesSlide] = useState({});
	const {
		idOfPortfolioSlideToEdit,
		editablePortfolioSlide,
		idOfPortfolioToEdit,
	} = useSelector((state) => state.editablePortfolio);

	const storageSelection = () => {
		if (window.localStorage.getItem('EditablePortfolioTextElementSelected')) {
			return window.localStorage.getItem(
				'EditablePortfolioTextElementSelected'
			);
		}
	};
	useEffect(() => {
		setValuesSlide({
			id: idOfPortfolioSlideToEdit,
			values: {
				portfolio_id: idOfPortfolioToEdit,
				order: '',
				content: editablePortfolioSlide.content,
			},
		});
	}, [
		editablePortfolioSlide.content,
		idOfPortfolioSlideToEdit,
		idOfPortfolioToEdit,
	]);

	const handleSlideElementText = (elementText) => {
		valuesSlide.values = {
			...valuesSlide.values,
			content: [...valuesSlide.values.content, elementText],
		};
		return dispatch(putPortfolioSlide(valuesSlide)).then((res) => {
			dispatch(getPortfolioToEdit(idOfPortfolioToEdit));
		});
	};

	const [titleSelected, setTitleSelected] = useState(false);
	const [simpleTextSelected, setSimpleTextSelected] = useState(false);
	const [selection, setSelection] = useState('No selection');
	const [subtitleSelected, setSubtitleSelected] = useState(false);

	const filteredSelectionText = (selection) => {
		switch (selection) {
			case 'titleTextSelected':
				return titleTextSelected;
			case 'subtitleTextSelected':
				return subtitleTextSelected;
			case 'simpleTextSelected':
				return textSimpleSelected;
			default:
				return ' ';
		}
	};
	const storageSelected = filteredSelectionText(storageSelection());

	const onSubmitSelection = (selection) => {
		const values = onSubmitTextElement(filteredSelectionText(selection));
		window.localStorage.setItem(
			'EditablePortfolioTextElementSelected',
			selection
		);
		dispatch(setSlideElement(values));
	};

	return (
		<div className='flex h-full flex-col items-center rounded-2xl bg-middle_grey-50 pt-5 '>
			<div className='flex w-[90%] items-start sm:w-[80%]'>
				<p className='m-2 pt-4 text-xs font-light text-grey-50'>
					{t('PulsarElTextoDelEjemploParaAgregarloALaPágina')}
				</p>
			</div>
			<div className='w-[90%] sm:w-[80%]'>
				<SelectionContainer
					design={'h-16 flex items-center mx-2 my-4 rounded cursor-pointer'}
					selection={titleSelected}
					action={() => {
						setTitleSelected(!titleSelected);
						setSelection('titleTextSelected');
						subtitleSelected && setSubtitleSelected(false);
						simpleTextSelected && setSimpleTextSelected(false);
					}}
				>
					<h1 className='pl-4 text-3xl font-bold'>{t('AgregarUnTítulo')}</h1>
				</SelectionContainer>

				<SelectionContainer
					design={'h-16 flex items-center mx-2 my-4 rounded cursor-pointer'}
					selection={subtitleSelected}
					action={() => {
						setSubtitleSelected(!subtitleSelected);
						setSelection('subtitleTextSelected');
						titleSelected && setTitleSelected(false);
						simpleTextSelected && setSimpleTextSelected(false);
					}}
				>
					<h3 className='pl-4 text-2xl font-light'>
						{t('AgregarUnSubtítulo')}
					</h3>
				</SelectionContainer>

				<SelectionContainer
					design={'h-16 flex items-center mx-2 my-4 rounded cursor-pointer'}
					selection={simpleTextSelected}
					action={() => {
						setSimpleTextSelected(!simpleTextSelected);
						setSelection('simpleTextSelected');
						titleSelected && setTitleSelected(false);
						subtitleSelected && setSubtitleSelected(false);
					}}
				>
					<p className='pl-4 text-base font-light'>{t('AñadirUnParrafo')}</p>
				</SelectionContainer>
				{(titleSelected || subtitleSelected || simpleTextSelected) && (
					<div className='flex justify-center'>
						<ButtonFabExtendedPrimary
							text={t('AgregarAPágina')}
							action={() => {
								onSubmitSelection(selection);
								setIndexEditablePortfolioButton();
								setShowSideModal(false);
								handleSlideElementText(
									onSubmitTextElement(filteredSelectionText(selection))
								);
							}}
						/>
					</div>
				)}
				{storageSelected.content && (
					<div className='my-8 mx-2'>
						<p className='text-xs text-grey-50'>{t('UsadoRecientemente')}</p>
						<div className='py-4'>
							<p
								className={
									storageSelected.fontColor +
									' ' +
									storageSelected.fontSize +
									' ' +
									storageSelected.fontWeight
								}
							>
								{storageSelected.content}
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default EditablePortfolioText;
