import { api } from '../helpers/axios';
import { submitErrorToSlack } from '../helpers/SlackIntegrations';

export const fetchPutFormData = async (url, values) => {
	const formData = new FormData();
	for (const value in values) {
		let currentValue = values[value];
		if (Array.isArray(currentValue)) {
			currentValue = JSON.stringify(currentValue);
		}
		formData.append(value, currentValue);
	}
	return api
		.put(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			if (res.status === 200) {
				return res.data;
			}

			throw new Error(res.data);
		})
		.catch((error) => {
			if (error.response.status >= 500) {
				submitErrorToSlack(url, error, 'PUT');
			}
			console.error(error);
			throw error;
		});
};
