import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	ENDPOINTS_LOCATION,
	ENDPOINTS_COUNTRY,
	ENDPOINTS_PERSON,
	ENDPOINTS_CITY,
} from '../../helpers/routes';
import { fetchGetData } from '../../hooks/fetchGetData';
import { singleLocationGeneralTabs } from '../../data/SingleLocationGeneral2';
import {
	updateTabsObject,
	updateDropdowns,
} from '../../helpers/SingleViewHelpers';
import { fetchPutData } from '../../hooks/fetchPutData';
import { fetchPutArrayImages } from '../../hooks/fetchPutArrayImages';
import postDataAndImage from '../../helpers/postDataAndImage';

export const getLocationsGeneral = createAsyncThunk(
	'locationsGeneral/getLocationsGeneral',
	async () => {
		const { organization_user_id } = localStorage;
		return await fetchGetData(
			`${ENDPOINTS_LOCATION.GET_LOCATIONS_GENERAL}${organization_user_id}/`
		);
	}
);

export const getLocationGeneral = createAsyncThunk(
	'locationsGeneral/fetchLocationGeneral',
	async (id) => {
		return await fetchGetData(
			`${ENDPOINTS_LOCATION.GET_LOCATION_GENERAL}${id}/`
		);
	}
);

export const getCountries = createAsyncThunk(
	'locationsGeneral/fetchCountries',
	async () => {
		return await fetchGetData(ENDPOINTS_COUNTRY.GET_COUNTRIES_WITH_LABELS);
	}
);

export const putLocationGeneral = createAsyncThunk(
	'locationsGeneral/putLocationGeneral',
	async (input) => {
		const { values, id } = input;
		return await fetchPutData(
			`${ENDPOINTS_LOCATION.PUT_LOCATION_GENERAL}${id}/`,
			values
		);
	}
);

export const postLocationGeneral = createAsyncThunk(
	'locationGeneral/postLocationGeneral',
	async (values) => {
		return await postDataAndImage(
			ENDPOINTS_LOCATION.POST_LOCATION_GENERAL,
			values
		);
	}
);

export const getLocationGeneralDropdown = createAsyncThunk(
	'locationsGeneral/getLocationGeneralDropdown',
	async () => {
		const { organization_user_id } = localStorage;
		const { countries } = await fetchGetData(
			ENDPOINTS_COUNTRY.GET_COUNTRIES_WITH_LABELS
		);
		const location_contacts = await fetchGetData(
			`${ENDPOINTS_PERSON.GET_LOCATION_CONTACTS_WITH_LABELS}${organization_user_id}/`
		);
		const cities = await fetchGetData(ENDPOINTS_CITY.GET_CITIES_ALL);
		let final = {
			countries: countries,
			location_contacts: location_contacts,
			cities: cities,
		};
		return final;
	}
);

export const organizeImageGrid = createAsyncThunk(
	'locationsGeneral/organizeImageGrid',
	async (values) => {
		return await fetchPutArrayImages(values);
	}
);

const initialSingleLocationGeneral = {
	name: '',
	address: '',
	postcode: '',
	details: '',
	obras: [],
	location_room: [],
	main_picture_url:
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
	country_id: 47,
	city_id: 9314,
	contact_person_id: '',
};
const initialLocationGeneralSingleTabs = updateTabsObject(
	singleLocationGeneralTabs,
	initialSingleLocationGeneral
);

const initialState = {
	locationsGeneral: null,
	singleLocationGeneral: null,
	status: null,
	locationGeneralSingleTabs: initialLocationGeneralSingleTabs,
	locationGeneralDropdown: {
		countries: [],
		location_contacts: [],
		cities: [],
	},
	loadedLocationGeneralDropdown: false,
	countries: [],
};

export const locationGeneralSlice = createSlice({
	name: 'locationGeneral',
	initialState,
	reducers: {},
	extraReducers: {
		[getLocationsGeneral.pending]: (state) => {
			state.status = 'loading';
		},
		[getLocationsGeneral.fulfilled]: (state, { payload }) => {
			state.locationsGeneral = payload;
			state.status = 'success';
		},
		[getLocationsGeneral.rejected]: (state) => {
			state.status = 'failed';
		},
		[getLocationGeneral.pending]: (state) => {
			state.status = 'loading';
		},
		[getLocationGeneral.fulfilled]: (state, { payload }) => {
			let Single = payload;
			state.singleLocationGeneral = Single;
			state.locationGeneralSingleTabs = updateTabsObject(
				state.locationGeneralSingleTabs,
				Single
			);
			state.locationGeneralSingleTabs = updateDropdowns(
				state.locationGeneralSingleTabs,
				state.locationGeneralDropdown['location_contacts'],
				0,
				0,
				2
			);
			state.locationGeneralSingleTabs = updateDropdowns(
				state.locationGeneralSingleTabs,
				state.locationGeneralDropdown['countries'],
				0,
				1,
				2
			);
			state.locationGeneralSingleTabs = updateDropdowns(
				state.locationGeneralSingleTabs,
				state.locationGeneralDropdown['cities'],
				0,
				1,
				3
			);
			state.status = 'success';
		},
		[getLocationGeneral.rejected]: (state) => {
			state.status = 'failed';
		},

		//put locationGeneral
		[putLocationGeneral.pending]: (state) => {
			state.status = 'loading';
		},
		[putLocationGeneral.fulfilled]: (state) => {
			state.status = 'success';
		},
		[putLocationGeneral.rejected]: (state) => {
			state.status = 'failed';
		},

		//Post Location General
		[postLocationGeneral.pending]: (state) => {
			state.status = 'loading';
		},
		[postLocationGeneral.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
		},
		[postLocationGeneral.rejected]: (state) => {
			state.status = 'failed';
		},

		//get artists/getArtistDropdown'
		[getLocationGeneralDropdown.pending]: (state) => {
			state.status = 'loading';
		},
		[getLocationGeneralDropdown.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.locationGeneralDropdown = payload;
			state.loadedLocationGeneralDropdown = true;
		},
		[getLocationGeneralDropdown.rejected]: (state) => {
			state.status = 'failed';
		},

		//get countries'
		[getCountries.pending]: (state) => {
			state.status = 'loading';
		},
		[getCountries.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.countries = payload.countries;
		},
		[getCountries.rejected]: (state) => {
			state.status = 'failed';
		},
		// organize grid of Images
		[organizeImageGrid.pending]: (state) => {
			state.status = 'loading';
		},
		[organizeImageGrid.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
		},
		[organizeImageGrid.rejected]: (state) => {
			state.status = 'failed';
		},
	},
});

// export const { } = locationGeneralSlice.actions;

export default locationGeneralSlice.reducer;
