import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
//UI
import TextContentPrincipal from '../../../atoms/Text/Content/TextContentPrincipal';

const EditablePortfolioSubNavbar = ({ portfolioName }) => {
	const location = useLocation();
	const currentLocation = location.pathname;

	const portfolioNameText = portfolioName || '';

	const [barText, setBarText] = useState(portfolioName);

	useEffect(() => {
		if (currentLocation.includes('secciones/agregar')) {
			setBarText('Agrega una sección');
		} else if (currentLocation.includes('secciones')) {
			setBarText('Visualización de secciones de tu portafolio');
		} else {
			setBarText(portfolioNameText);
		}
	}, [currentLocation, portfolioNameText]);

	return (
		<div
			className={`z-50 flex justify-center bg-white py-1 shadow transition-all duration-300 md:py-2`}
		>
			<TextContentPrincipal design={'text-sm md:text-base'} text={barText} />
		</div>
	);
};

export default EditablePortfolioSubNavbar;
