//Third parties
import PropTypes from 'prop-types';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

//BL

//UI
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import TextSubTitle from '../../../UI/molecules/Text/TextSubTitle';
import InputCustomTextArea from '../../../UI/atoms/Inputs/InputCustomTextArea';
import Cross from '../../assets/icons/Cross.svg';
import Check from '../../assets/icons/whiteCheck.svg';

/**
 * @description section that uses formik to edit the section's text
 * @param {*} param0
 * @returns
 */

const SectionEditTitleAndText = ({
	sectionTitle,
	field,
	initialText,
	colorHr,
	setTurnEdit,
	onSubmitAction,
	id,
	validationSchema,
}) => {
	const { t } = useTranslation();

	const validationSchemaObject = Yup.object(validationSchema);

	return (
		<div>
			<TextSubTitle
				text={sectionTitle}
				hasEdit={false}
				hasAdd={false}
				colour={colorHr}
			/>

			<div>
				<Formik
					enableReinitialize
					initialValues={{ [field]: initialText }}
					validationSchema={validationSchemaObject}
					onSubmit={(values) => {
						let input = { values, id };
						onSubmitAction(input);
						setTurnEdit(false);
					}}
				>
					{(formProps) => (
						<Form>
							<div>
								<Field
									name={field}
									className='h-48 w-full rounded border-none bg-white text-sm text-grey-50'
									as={InputCustomTextArea}
								/>
								{formProps.errors && (
									<div className='ml-auto text-red-400'>
										{t(formProps.errors[field])}
									</div>
								)}
							</div>

							<div className='my-5 flex w-full flex-row justify-center gap-3 md:mb-1'>
								<ButtonFab
									label={t('Cancelar')}
									colour='white'
									action={() => setTurnEdit(false)}
									icon={Cross}
								/>
								<ButtonFab
									colour='green'
									label={t('Guardar')}
									icon={Check}
									type='submit'
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default SectionEditTitleAndText;

SectionEditTitleAndText.propTypes = {
	/* title of the section with information */
	sectionTitle: PropTypes.string,
	/* string with name of the field to be edited related to the database */
	field: PropTypes.string,
	/* string with the text of the section  */
	initialText: PropTypes.string,
	/** Color of Header in Subtitle */
	colorHr: PropTypes.string,
	/** Trigger a function when the edit is clicked */
	setTurnEdit: PropTypes.func,
	/* ValidationSchema used to manage error messages */
	validationSchema: PropTypes.object,
	/** this error string is generated from te yup validation schema in case of a missing requerid field*/
	errors: PropTypes.string,
};
