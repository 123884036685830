import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';

// UI
import ButtonFabExtendedPrimary from '../Buttons/FABExtended/ButtonFabExtendedPrimary';
import SelectionContainer from '../container/SelectionContainer';

/**
 * It renders two cards (image, text), each with a button that allows the user to select the artwork
 * @returns A div with two SelectionContainer components.
 */
const ArtworkCompleteElement = ({ setChooseArtwork, setSelection }) => {
	const { t } = useTranslation();
	const image =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';

	const [completeArtworkSelected, setCompleteArtworkSelected] = useState(false);

	return (
		<div className='w-[80%] py-6 sm:w-[60%] '>
			<SelectionContainer
				design={
					'flex flex-col w-full px-2 m-2 mb-8 rounded flex justify-center items-center'
				}
				selection={completeArtworkSelected}
				action={() => {
					setCompleteArtworkSelected(!completeArtworkSelected);
				}}
			>
				<div className='mt-4 w-[70%] '>
					<LazyLoadImage
						alt={'image'}
						src={image}
						width=''
						height=''
						placeholderSrc={image + '_small'}
						wrapperClassName='max-w-full  flex justify-center md:justify-start'
						className='flex max-h-full '
						delayTime='0'
						threshold='300'
					/>
				</div>
				<div className=' my-1 w-full px-[15%] pb-2'>
					<p className='text-sm md:text-[1.8vmin]'>
						{t('Nombre')} : Lorem ipsum
					</p>
					<p className='text-xs md:text-[1.5vmin]'>
						{t('Medida')} : 00 x 00 x 00 cm
					</p>
					<p className='text-xs md:text-[1.5vmin]'>
						{t('Técnica')} : Lorem ipsum
					</p>
					<p className='text-xs md:text-[1.5vmin]'>{t('Año')} : 0000</p>
				</div>
			</SelectionContainer>

			{completeArtworkSelected && (
				<div className='-mt-5 mb-16 flex justify-center'>
					<ButtonFabExtendedPrimary
						text={t('Continuar')}
						className='text-white'
						action={() => {
							setChooseArtwork(true);
							setSelection('completeArtworkSelected');
						}}
					/>
				</div>
			)}
		</div>
	);
};

ArtworkCompleteElement.propTypes = {
	/** Function to set chooseArtwork to a boolean value in the parent component */
	setChooseArtwork: PropTypes.func,
	/** Function to set selection to a string value in the parent component */
	setSelection: PropTypes.func,
};

export default ArtworkCompleteElement;
