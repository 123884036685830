// 3rd Party
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// BL
import {
	getExpertPortfolio,
	putExpertPortfolio,
} from '../../../../BusinessLogic/redux/reducers/portfolio';
import { configurationFormLabelValues } from '../../../../BusinessLogic/data/ExpertPortfolioData';

// UL
import TextContentSecond from '../../../../UI/atoms/Text/Content/TextContentSecond';
import ButtonSwitch from '../../../../UI/atoms/Buttons/ButtonSwitch';
import ButtonFab from '../../../../UI/atoms/Buttons/FAB/ButtonFab';
import ExpertPortfolioEditionLayout from '../../../../UI/layouts/portfolio/ExpertPortfolioEditionLayout';
import TextTitlePrincipal from '../../../../UI/atoms/Text/Titles/TextTitlePrincipal';

export default function ExpertPortfolioConfigurationArtwork() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { singlePortfolio } = useSelector((state) => state.portfolios);
	const { portfolioId } = useParams();
	const [formConfigurationValues, setFormConfigurationValues] = useState(
		getFormData(singlePortfolio)
	);
	const [isLoadingForm, setIsLoadingForm] = useState(false);

	function getFormData(portfolio) {
		return {
			show_title: portfolio.show_title,
			show_date: portfolio.show_date,
			show_technique: portfolio.show_technique,
			show_measures: portfolio.show_measures,
			show_prices: portfolio.show_prices,
		};
	}

	useEffect(() => {
		if (portfolioId !== singlePortfolio?.id) {
			setIsLoadingForm(true);
			dispatch(getExpertPortfolio(portfolioId))
				.then(({ payload }) => {
					setFormConfigurationValues(getFormData(payload));
				})
				.catch(() => {
					alert(t('NoFuePosibleCargarElPortfolioExperto'));
					navigate('/portafolios');
				})
				.finally(() => setIsLoadingForm(false));
		}
	}, [dispatch, navigate, portfolioId, singlePortfolio.id, t]);

	const handleSubmit = (values) => {
		setIsLoadingForm(true);
		let putExpertPortfolioData = {
			id: singlePortfolio.id,
			values: getFormData(values),
		};
		dispatch(putExpertPortfolio(putExpertPortfolioData))
			.then(() => navigate(-1))
			.catch((e) => console.error(e))
			.finally(() => setIsLoadingForm(false));
	};

	return (
		<ExpertPortfolioEditionLayout>
			<div className='mx-auto h-full max-w-[85%]'>
				<TextTitlePrincipal
					design='text-center mt-10 text-lg mb-6 underline md:px-[20%]'
					title={t('DatosDeObras')}
					textColor='text-yellow-50'
				/>
				<TextContentSecond
					design='mt-16 mb-12 !text-base max-w-[560px] mx-auto'
					text={t('EscogeLosCamposDeObras')}
				/>
				<Formik initialValues={formConfigurationValues} onSubmit={handleSubmit}>
					{(formProps) => (
						<Form>
							<div className='mx-auto lg:max-w-[45%]'>
								{Object.keys(formProps.values).map((key, index) => (
									<div className='mb-2 flex justify-between' key={index}>
										<TextContentSecond
											design='mb-4 max-w-[70%]'
											text={t(configurationFormLabelValues[key])}
										/>
										<ButtonSwitch
											switched={formProps.values[key]}
											onSwitchAction={() =>
												formProps.setFieldValue(key, !formProps.values[key])
											}
										/>
									</div>
								))}
							</div>
							<div className='mx-auto mt-12 mb-4 flex max-w-[1020px] justify-end'>
								<ButtonFab
									colour='green'
									label={isLoadingForm ? `${t('Guardando')}...` : t('Guardar')}
									type='submit'
									disabled={isLoadingForm}
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</ExpertPortfolioEditionLayout>
	);
}
