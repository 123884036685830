import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// UI
import OnboardingArtworkForm from '../../UI/organisms/Onboarding/OnboardingArtworkForm';
import ImageOnboardingArtwork2 from '../../UI/assets/images/ImageOnboardingArtwork2.jpg';

const SecondArtwork = () => {
	const { artworks } = useSelector((state) => state.artworks);
	const { t } = useTranslation();
	return (
		<OnboardingArtworkForm
			image={ImageOnboardingArtwork2}
			subtitleText={t('AgregaTuSegundaObra')}
			isSecondArtwork={true}
			artworks={artworks}
		/>
	);
};

export default SecondArtwork;
