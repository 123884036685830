import { fetchPostData } from '../hooks/fetchPostData';
import { fetchPostImage } from '../hooks/fetchPostImage';

export default async function postDataAndImage(ENDPOINT, values) {
	await fetchPostImage(values).then((res) => {
		values = {
			...values,
			main_picture_id: res.id,
		};
	});
	return fetchPostData(ENDPOINT, values);
}
