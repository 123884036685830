//3rd party
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

//UI
import { ReactComponent as Back } from '../../../../UI/assets/icons/Back.svg';

/**
 * @name ButtonLinkBack
 * @description renders a back button that sends the user to other section.
 * @param {*} param0
 * @returns
 */
export default function ButtonLinkBack({
	design,
	redirect,
	action,
	isNotAutomaticRedirect,
}) {
	const navigate = useNavigate();

	return (
		<div className={design} onClick={action && action}>
			{redirect ? (
				<Link to={redirect}>
					<figure src={Back} alt='Atrás'>
						<Back fill='none' />
					</figure>
				</Link>
			) : (
				<figure
					src={Back}
					alt='Atrás'
					className='cursor-pointer'
					onClick={() => {
						!isNotAutomaticRedirect && navigate(-1);
					}}
				>
					<Back fill='none' />
				</figure>
			)}
		</div>
	);
}

ButtonLinkBack.propTypes = {
	/** Tailwind className to modify the main component */
	design: PropTypes.string,
	/** If exists, redirects to this string. Otherwise, goes back on the history  */
	redirect: PropTypes.string,
	/* trigger a function to execute on onClick */
	action: PropTypes.func,
	/* determine whether to return to the previous URL or just execute the action prop */
	isNotAutomaticRedirect: PropTypes.bool,
};
