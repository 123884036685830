import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//BL
import { stringToSlug } from '../../BusinessLogic/helpers/textFormat';
import { updateOnboardingFirstLogged } from '../../BusinessLogic/redux/reducers/onboarding';
// UI
import ImageFullScreen from '../../UI/molecules/Image/ImageFullScreen';
import ImageOnboardingMain from '../../UI/assets/images/ImageOnboardingMain.jpg';
import TextTitlePrincipal from '../../UI/atoms/Text/Titles/TextTitlePrincipal';
import TextTitleSub from '../../UI/atoms/Text/Titles/TextTitleSub';
import ButtonFab from '../../UI/atoms/Buttons/FAB/ButtonFab';
import CheckIcon from '../../UI/assets/icons/whiteCheck.svg';
import { ModalPostulation } from '../../UI/organisms/PopUp/ModalPostulation';

/**
 * It's a function that returns a component that displays an image, a title, a subtitle, a button, and
 * a text the onboarding view at the start or end of the onboarding process.
 * @returns A component that is being rendered.
 */
const OnboardingWelcome = ({
	image,
	title,
	subtitle,
	text,
	buttonIcon,
	buttonText,
	isClosure,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { onboarding, onboardingFirstLogged } = useSelector(
		(state) => state.onboarding
	);
	const dispatch = useDispatch();
	const { appUser } = useSelector((state) => state.appUser);
	const [isWelcome] = useState(
		onboarding && !Object.values(onboarding).every((value) => value === 1)
	);

	const handleClick = () => {
		isWelcome && !isClosure && navigate('/bienvenida/perfil');
		isClosure &&
			navigate(
				`/portafolio/${stringToSlug(appUser.first_name)}${stringToSlug(
					appUser.last_name
				)}-primera-coleccion`
			);
	};

	return (
		<>
			{onboardingFirstLogged && (
				<ModalPostulation
					modalTitle={t('CuentaCreada')}
					modalText={
						<>
							{t('CuentaCreadaExitosamente')}
							<b className='font-bold'>Datarte </b>
							{t('PostularteEnLasConvocatorias')}
						</>
					}
					buttonLabel={t('Cerrar')}
					actionClose={() => dispatch(updateOnboardingFirstLogged(false))}
					modalClassName={'mx-auto w-4/5 px-8'}
				/>
			)}
			<ImageFullScreen
				className={'absolute -z-10 h-full w-full'}
				image={image}
				alt={' onboarding image'}
			/>
			<div className='align-center z-10 flex h-[100vh] flex-col items-center justify-center p-2 '>
				<TextTitlePrincipal
					title={t(title)}
					design='text-white max-w-max mx-auto text-center w-[70%]'
					underline
				/>
				<div className='flex flex-col gap-1 pt-8'>
					<TextTitleSub title={t(subtitle)} design='text-white text-center' />
				</div>

				<div className='flex flex-col gap-1 pt-8'>
					<ButtonFab
						design={'drop-shadow-lg'}
						colour='green'
						size={'large'}
						label={t(buttonText)}
						icon={buttonIcon}
						action={() => handleClick()}
					/>
				</div>
				{isClosure && (
					<div className='flex w-[70vw] justify-center pt-8 sm:w-[50vw]'>
						<div className='grid w-full grid-cols-4 gap-2 xl:w-[60%]'>
							<span className='h-2 rounded border bg-green-50'></span>
							<span className='h-2 rounded border bg-green-50'></span>
							<span className='h-2 rounded border bg-green-50'></span>
							<span className='h-2 rounded border bg-green-50'></span>
						</div>
					</div>
				)}

				<div className='flex flex-col gap-1 pt-8'>
					<p
						className='cursor-pointer text-center text-white '
						onClick={() => navigate('/')}
					>
						{t(text)}
					</p>
					<hr />
				</div>
			</div>
		</>
	);
};

OnboardingWelcome.propTypes = {
	/** URL of the  image */
	image: PropTypes.string,
	/** Text in the component */
	title: PropTypes.string,
	/** Text in the component */
	subtitle: PropTypes.string,
	/** Text in the component */
	text: PropTypes.string,
	/** Icon for the button */
	buttonIcon: PropTypes.string,
	/** Text for display in the button */
	buttonText: PropTypes.string,
	/** Bool prop to determinate if the component is render in the ending of the onboarding  */
	isClosure: PropTypes.bool,
};

OnboardingWelcome.defaultProps = {
	image: ImageOnboardingMain,
	title: 'BienvenidoADatarte',
	subtitle: 'CompletaEstosPasos',
	buttonIcon: CheckIcon,
	buttonText: 'Empezar',
	text: 'SaltarPasos',
};
export default OnboardingWelcome;
