//3rd parties
import React, { useState } from 'react';
import PropTypes from 'prop-types';

//UI
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import { ReactComponent as Arrow } from '../../assets/icons/CaretLeft.svg';
import { ReactComponent as ArrowGreen } from '../../assets/icons/CaretLeftGreen.svg';
import PopupSmall from '../../organisms/PopUp/PopupSmall';

import { useTranslation } from 'react-i18next';
/**
 * @description show a selected language from a list.
 * @returns
 */
export default function SelectorLanguage({
	languageList,
	popupDesign,
	letterColor,
	titleColor,
	showGreenArrow,
	languageLabels,
	titleStyle,
	labelsStyle,
}) {
	const [openDropDown, setOpenDropDown] = useState(false);
	const { i18n } = useTranslation();
	const changeLanguage = (language) => {
		i18n.changeLanguage(language);
	};

	return (
		<div className='relative flex-col'>
			<div className='flex flex-row md:pr-2'>
				{showGreenArrow ? (
					<ArrowGreen
						onClick={() => setOpenDropDown(!openDropDown)}
						className={`ml-2 h-6 w-6 transform cursor-pointer transition duration-150 ease-in-out 
							${openDropDown ? 'rotate-90' : '-rotate-90'}`}
					/>
				) : (
					<Arrow
						onClick={() => setOpenDropDown(!openDropDown)}
						className={`ml-2 h-6 w-6 transform cursor-pointer transition duration-150 ease-in-out 
							${openDropDown ? 'rotate-90' : '-rotate-90'}`}
					/>
				)}
			</div>
			{openDropDown && (
				<PopupSmall design={`${popupDesign} flex flex-row my-2  md:mx-0 `}>
					{languageList.map((item) => (
						<div
							onClick={() => {
								setOpenDropDown(false);
								changeLanguage(item.toLowerCase());
							}}
							className='flex w-full cursor-pointer justify-center hover:bg-light_grey-100'
						>
							<TextTitleSub
								textColor={letterColor}
								title={languageLabels[item] ?? item}
								style={labelsStyle}
							/>
						</div>
					))}
				</PopupSmall>
			)}
		</div>
	);
}

SelectorLanguage.propTypes = {
	/** List of language to choose*/
	languageList: PropTypes.array,
	/** a string of tailwind styles for the popup container */
	popupDesign: PropTypes.string,
	/** a string of tailwind styles for the color of the text inside the popup container*/
	letterColor: PropTypes.string,
	/** a string of tailwind styles for the color of the title of the selector*/
	titleColor: PropTypes.string,
	/**Determines if show a green arrow instead of default arrow */
	showGreenArrow: PropTypes.bool,
	/**Label to show in each language*/
	languageLabels: PropTypes.object,
	/**Style attributes for the title*/
	titleStyle: PropTypes.object,
};

SelectorLanguage.defaultProps = {
	languageList: ['ES', 'EN'],
	popupDesign: 'bg-green-50 text-white',
	letterColor: 'text-white',
	titleColor: 'text-white',
	showGreenArrow: false,
	languageLabels: { ES: 'ES', EN: 'EN' },
};
