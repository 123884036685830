//Third parties
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

//UI
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import FieldPasswordFormik from '../../molecules/Field/FieldPasswordFormik';
import TextSubTitle from '../../../UI/molecules/Text/TextSubTitle';
import FieldSelectFormik from '../../../UI/molecules/Field/FieldSelectFormik';
import FieldTextFormikPortfolio from '../../molecules/Field/FieldTextFormikPortfolio';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '../../atoms/Inputs/ListItemText';
import ButtonSwitch from '../../atoms/Buttons/ButtonSwitch';

const PortfolioEditForm = ({
	currentPortfolioName,
	validationSchema,
	validationSchemaWithPassword,
	onSubmit,
	slug,
	type_id,
}) => {
	const { t } = useTranslation();
	const [typeOfPermission, setTypeOfPermission] = useState('PUBLIC');
	const [validation, setValidation] = useState(validationSchema);
	const [initialValues, setInitialValues] = useState({});
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
		useState(false);
	const [showPrices, setShowPrices] = useState(true);
	const { portfolioPreviewName, portfolioPreviewSlug } = useSelector(
		(state) => state.portfolios
	);

	useEffect(() => {
		setInitialValues({
			name: portfolioPreviewName ? portfolioPreviewName : currentPortfolioName,
			type: 'COLLECTION',
			image_quality: 'HIGH',
			slug: portfolioPreviewSlug ? portfolioPreviewSlug : slug,
			permissions: 'PUBLIC',
			organization_user_id: Number(localStorage.organization_user_id),
			type_id: type_id,
			show_prices: showPrices ? 1 : 0,
		});
	}, [
		currentPortfolioName,
		portfolioPreviewName,
		portfolioPreviewSlug,
		showPrices,
		slug,
		type_id,
	]);

	useEffect(() => {
		if (typeOfPermission === 'PASSWORD') {
			setValidation(validationSchemaWithPassword);
		} else {
			setValidation(validationSchema);
		}
	}, [typeOfPermission, validationSchemaWithPassword, validationSchema]);

	const handlePermissionChange = (e, formProps) => {
		setTypeOfPermission(e.target.value);
		formProps.values.permissions = e.target.value;
		formProps.setValues(formProps.values);
	};

	return (
		<div className='w-full md:w-1/2'>
			<div className='m-auto w-full pt-10 md:w-96 md:pt-0'>
				<TextSubTitle
					text={t('PersonalizaTuPortafolio')}
					hasEdit={false}
					colour='yellow'
					hasAdd={false}
				/>
				<div>
					<Formik
						enableReinitialize={true}
						initialValues={initialValues}
						validationSchema={validation}
						onSubmit={onSubmit}
					>
						{(formProps) => (
							<Form>
								<FieldTextFormikPortfolio
									label={t('Titulo')}
									dbName='name'
									formikFieldType='text'
									errors={formProps.errors?.name}
									errorMessageDesign={`text-red-400`}
									formProps={formProps}
								/>

								<FieldTextFormikPortfolio
									label={t('URL')}
									dbName='slug'
									formikFieldType='text'
									errors={formProps.errors?.slug}
									errorMessageDesign={`text-red-400`}
									formProps={formProps}
								/>
								<ListItem
									style={{
										paddingLeft: '0px',
										paddingRight: '0px',
										justifyContent: 'space-between',
									}}
								>
									<ListItemText
										primary={t('Acceso')}
										design='body-Text2'
										style={{ fontWeight: 400 }}
									/>
									<Field
										onChange={(e) => handlePermissionChange(e, formProps)}
										name='permissions'
										type='Text'
										as='select'
										className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-75 md:w-56'
									>
										<option value={'PUBLIC'}>{t('Público')}</option>
										<option value={'PASSWORD'}>{t('PúblicoConClave')}</option>
										<option value={'PRIVATE'}>{t('Privado')}</option>
									</Field>
								</ListItem>

								{typeOfPermission === 'PASSWORD' && (
									<div>
										<FieldPasswordFormik
											label={t('Contraseña')}
											dbName='password'
											type={passwordVisibility ? 'text' : 'password'}
											errors={formProps.errors?.password}
											errorMessageDesign={`text-red-400`}
											passwordVisibility={passwordVisibility}
											setPasswordVisibility={setPasswordVisibility}
										/>

										<FieldPasswordFormik
											label={t('Confirmar contraseña')}
											dbName='confirm_password'
											type={confirmPasswordVisibility ? 'text' : 'password'}
											errors={formProps.errors?.confirm_password}
											errorMessageDesign={`text-red-400`}
											confirmPasswordVisibility={confirmPasswordVisibility}
											setConfirmPasswordVisibility={
												setConfirmPasswordVisibility
											}
										/>
									</div>
								)}

								<ListItem
									style={{
										paddingLeft: '0px',
										paddingRight: '0px',
										justifyContent: 'space-between',
									}}
								>
									<ListItemText
										primary={t('MostrarPrecios')}
										design='body-Text2'
										style={{ fontWeight: 400 }}
									/>
									<div className='w-40 md:w-56'>
										<ButtonSwitch
											switched={showPrices}
											setSwitched={setShowPrices}
										/>
									</div>
								</ListItem>
								<FieldSelectFormik
									label={t('Resolución')}
									dbName='image_quality'
									options={[
										{ value: 'HIGH', label: t('Alta') },
										{ value: 'MEDIUM', label: t('Media') },
										{ value: 'LOW', label: t('Baja') },
									]}
								/>
								<div className='mb-20 mt-20 flex w-full justify-center'>
									<ButtonFab
										colour='green'
										icon=''
										label={t('Guardar')}
										type='submit'
									/>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

PortfolioEditForm.propTypes = {
	/** default name of the portfolio */
	currentPortfolioName: PropTypes.string,
	/** validation schema */
	validationSchema: PropTypes.object,
	/** submit action */
	onSubmit: PropTypes.func,
	/** validation schema of passwords and slug */
	validationSchemaWithPassword: PropTypes.object,
	/** url of the portfolio */
	slug: PropTypes.string,
	/** portfolio id */
	type_id: PropTypes.number,
};

export default PortfolioEditForm;
