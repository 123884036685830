import React from 'react';
import PropTypes from 'prop-types';

import SkeletonImage from '../../atoms/Skeleton/SkeletonImage';
import TemplateWithNavbarSkeleton from './TemplateWithNavbarSkeleton';
import TabInfoAndEditSkeleton from '../../organisms/Skeleton/TabInfoAndEditSkeleton';

const ViewSingleSkeleton = ({
	numberOfTabs,
	numberOfTitleAndFields,
	numberOfSingleRow,
	numberOfSingleTitleAndField,
	designOfBottomTitleAndField,
	designSkeletonTitle2,
	designMainImage,
	designCaption,
}) => {
	return (
		<div className='min-h-screen p-2'>
			<TemplateWithNavbarSkeleton
				designSkeletonTitle1={'visible'}
				designSkeletonTitle2={designSkeletonTitle2}
				designSkeletonTitle4={'invisible'}
				designSkeletonTitleRight={'invisible'}
			/>

			<div className='flex w-full flex-col justify-between gap-8 py-6 md:flex-row'>
				<div className='relative mx-auto w-11/12 py-6 md:w-1/4'>
					<div className='px-4 md:px-0'>
						<SkeletonImage
							designMainImage={designMainImage}
							designCaption={designCaption}
						/>
					</div>
				</div>
				<TabInfoAndEditSkeleton
					numberOfTabs={numberOfTabs}
					numberOfTitleAndFields={numberOfTitleAndFields}
					numberOfSingleRow={numberOfSingleRow}
					numberOfSingleTitleAndField={numberOfSingleTitleAndField}
					designOfBottomTitleAndField={designOfBottomTitleAndField}
				/>
			</div>
		</div>
	);
};
ViewSingleSkeleton.propTypes = {
	/** Number of tabs minus one to be displayed in the component*/
	numberOfTabs: PropTypes.number,
	/** Number of Title And Fields to be displayed in the component*/
	numberOfTitleAndFields: PropTypes.number,
	/** Number of  Single Row to be displayed inside of Title And Fields in the component*/
	numberOfSingleRow: PropTypes.number,
	/** Number of Title And Field with one single row to be displayed  in the component*/
	numberOfSingleTitleAndField: PropTypes.number,
	/** TailWind className for Bottom Title And Field without single row */
	designOfBottomTitleAndField: PropTypes.string,
	/** TailWind className for the main image to be displayed in the component*/
	designMainImage: PropTypes.string,
	/** TailWind className for the caption to be displayed in the component*/
	designCaption: PropTypes.string,
};
export default ViewSingleSkeleton;
