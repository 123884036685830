import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//BL
import {
	putPortfolio,
	getPortfolios,
} from '../../../BusinessLogic/redux/reducers/portfolio';
import { getPortfolioToEdit } from '../../../BusinessLogic/redux/reducers/editablePortfolio';

//UI
import { ReactComponent as EyeBlack } from '../../../UI/assets/icons/EyeBlack.svg';
import { ReactComponent as ShareNetworkBlack } from '../../../UI/assets/icons/ShareNetworkBlack.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import { ReactComponent as Pencil } from '../../assets/icons/pencil.svg';
import SharePortfolio from '../../../Pages/sections/portfolio/SharePortfolio';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import Cross from '../../../UI/assets/icons/Cross.svg';
import Check from '../../../UI/assets/icons/whiteCheck.svg';
import InputFieldForm from '../../../UI/molecules/Field/InputFieldForm';
import FieldPasswordFormik from '../../molecules/Field/FieldPasswordFormik';

export const PortfolioConfiguration = ({
	image,
	title,
	id,
	information,
	slug,
	permissions,
	organization_user_id,
	validationSchema,
	validationSchemaWithPassword,
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [showShareModal, setShowShareModal] = useState(false);
	const [showEditTitle, setShowEditTitle] = useState(false);
	const [showEditSlug, setShowEditSlug] = useState(false);
	const [showEditPermissions, setShowEditPermissions] = useState(false);
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
		useState(false);

	const optionsPortfolioConfiguration = [
		{
			action: () => {
				setShowShareModal(true);
			},
			label: `Compartir portafolio`,
			icon: <ShareNetworkBlack className='h-6 w-6' />,
		},
		{
			action: () => {
				window.open(`${window.location.origin}/portafolio/${slug}`);
			},
			label: `Visualizar portafolio`,
			icon: <EyeBlack className='h-6 w-6' />,
		},
	];

	const initialValues = {
		name: title,
		slug,
		permissions,
		id,
		organization_user_id,
	};

	const onSubmitAction = async (values) => {
		await dispatch(putPortfolio(values));
		dispatch(getPortfolioToEdit(id));
		dispatch(getPortfolios());
	};

	return (
		<>
			<div className='flex w-full flex-col bg-light_grey-75 px-3'>
				<div className='flex w-full flex-col md:flex-row md:justify-between md:gap-10'>
					<div className='py-6 md:w-1/2'>
						<LazyLoadImage
							alt={title}
							src={image}
							width='100%'
							height='100%'
							effect='blur'
							wrapperClassName={`flex items-center`}
							className={'h-[40vh] w-full object-cover'}
							delayTime='0'
							threshold='800'
						/>
					</div>

					<div className='flex w-full flex-col md:w-1/2 md:py-4 md:pr-6 md:pl-2'>
						<div className='flex w-full flex-col items-center justify-between py-3'>
							{showEditTitle ? (
								<div className='w-full'>
									<Formik
										enableReinitialize={true}
										initialValues={initialValues}
										onSubmit={(values) => {
											let input = { values, id };
											onSubmitAction(input);
											setShowEditTitle(false);
										}}
									>
										{(formProps) => (
											<Form>
												<InputFieldForm item={information[0]} />
												<div className='my-5 flex w-full flex-row justify-center gap-3'>
													<ButtonFab
														label='Cancelar'
														colour='white'
														action={() => setShowEditTitle(false)}
														icon={Cross}
													/>
													<ButtonFab
														colour='green'
														label='Guardar'
														icon={Check}
														type='submit'
													/>
												</div>
											</Form>
										)}
									</Formik>
								</div>
							) : (
								<div className='flex w-full items-center justify-between py-3'>
									<TextContentSecond design={'w-12 md:w-20'} text={'Título:'} />
									<TextContentSecond
										text={title}
										design={'text-grey-50 w-1/2'}
									/>
									<Pencil
										className='cursor-pointer'
										onClick={() => setShowEditTitle(true)}
									/>
								</div>
							)}
						</div>
						<div className='flex w-full flex-col items-center justify-between py-3'>
							{showEditSlug ? (
								<div className='w-full'>
									<Formik
										enableReinitialize={true}
										initialValues={initialValues}
										onSubmit={(values) => {
											let input = { values, id };
											onSubmitAction(input);
											setShowEditSlug(false);
										}}
									>
										{(formProps) => (
											<Form>
												<InputFieldForm item={information[1]} />
												<div className='my-5 flex w-full flex-row justify-center gap-3'>
													<ButtonFab
														label='Cancelar'
														colour='white'
														action={() => setShowEditSlug(false)}
														icon={Cross}
													/>
													<ButtonFab
														colour='green'
														label='Guardar'
														icon={Check}
														type='submit'
													/>
												</div>
											</Form>
										)}
									</Formik>
								</div>
							) : (
								<div className='flex w-full items-center justify-between py-3'>
									<TextContentSecond design={'w-12 md:w-20'} text={'Url:'} />
									<TextContentSecond
										text={slug}
										design={'text-grey-50 w-1/2'}
									/>
									<Pencil
										className='cursor-pointer'
										onClick={() => setShowEditSlug(true)}
									/>
								</div>
							)}
						</div>

						<div className='flex w-full flex-col items-center justify-between py-3'>
							{showEditPermissions ? (
								<div className='w-full'>
									<Formik
										enableReinitialize={true}
										initialValues={initialValues}
										validationSchema={validationSchema}
										onSubmit={(values) => {
											let input = { values, id };
											onSubmitAction(input);
											setShowEditPermissions(false);
										}}
									>
										{(formProps) => (
											<Form>
												<div className='flex w-full items-center justify-between '>
													<TextContentSecond text={'Acceso'} />
													<Field
														name='permissions'
														type='Text'
														as='select'
														className='body-Text2 h-6 w-4/5 rounded border-none bg-middle_grey-50'
													>
														{
															// FIX ME: these fields should be dynamic, these values should be brought from the backend
														}
														<option value={'PUBLIC'}>{t('Público')}</option>
														<option value={'PASSWORD'}>
															{t('PúblicoConClave')}
														</option>
														<option value={'PRIVATE'}>{t('Privado')}</option>
													</Field>
												</div>

												<div className='flex w-full flex-col'>
													{formProps.values.permissions === 'PASSWORD' && (
														<div className='mt-4 flex flex-col gap-2 pr-6'>
															<FieldPasswordFormik
																label={t('Contraseña')}
																dbName='password'
																type={passwordVisibility ? 'text' : 'password'}
																errors={formProps.errors?.password}
																errorMessageDesign={`text-red-400`}
																passwordVisibility={passwordVisibility}
																setPasswordVisibility={setPasswordVisibility}
																inputClass={
																	'w-full rounded border-none body-Text2 h-6 bg-middle_grey-50'
																}
															/>

															<FieldPasswordFormik
																label={t('Confirmar contraseña')}
																dbName='confirm_password'
																type={
																	confirmPasswordVisibility
																		? 'text'
																		: 'password'
																}
																errors={formProps.errors?.confirm_password}
																errorMessageDesign={`text-red-400`}
																confirmPasswordVisibility={
																	confirmPasswordVisibility
																}
																setConfirmPasswordVisibility={
																	setConfirmPasswordVisibility
																}
																inputClass={
																	'flex w-full rounded border-none body-Text2 h-6 bg-middle_grey-50'
																}
															/>
														</div>
													)}
												</div>
												<div className='my-6 flex w-full flex-row justify-center gap-3'>
													<ButtonFab
														label='Cancelar'
														colour='white'
														action={() => setShowEditPermissions(false)}
														icon={Cross}
													/>
													<ButtonFab
														colour='green'
														label='Guardar'
														icon={Check}
														type='submit'
													/>
												</div>
											</Form>
										)}
									</Formik>
								</div>
							) : (
								<div className='flex w-full items-center justify-between py-3'>
									<TextContentSecond design={'w-12 md:w-20'} text={'Acceso:'} />
									<TextContentSecond
										text={
											(permissions === 'PUBLIC' && 'Público') ||
											(permissions === 'PRIVATE' && 'Privado') ||
											(permissions === 'PASSWORD' && 'Público con clave')
										}
										design={'text-grey-50 w-1/2'}
									/>
									<Pencil
										className='cursor-pointer'
										onClick={() => setShowEditPermissions(true)}
									/>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className='mt-10 mb-10 flex w-full flex-col md:mt-2 md:place-items-center'>
					<div className='mb-10 md:w-1/2 md:place-items-center lg:w-1/3'>
						{optionsPortfolioConfiguration.map((option, i) => (
							<button
								className={`mb-4 flex w-full flex-row place-items-center justify-center gap-2 rounded-md bg-white py-2 shadow-md hover:bg-gray-200`}
								key={i + 1}
								onClick={option.action}
							>
								{option.icon}
								<TextContentSecond text={option.label} />
							</button>
						))}
					</div>
				</div>
				{showShareModal && (
					<div
						className='fixed top-0 right-0 z-40 flex h-full w-full cursor-pointer bg-black-50/70 bg-opacity-70 shadow-xl'
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<SharePortfolio
							urlToShare={`${window.location.origin}/portafolio/${slug}`}
							setShare={setShowShareModal}
							share={showShareModal}
							designTwo={
								'fixed inset-0 bg-black-50 bg-opacity-75 transition-opacity'
							}
						/>
					</div>
				)}
			</div>
		</>
	);
};
PortfolioConfiguration.propTypes = {
	/* title identification portfolio*/
	title: PropTypes.string,
	/* url of the portfolio */
	url: PropTypes.string,
	/* portfolio access (public or private)*/
	permissions: PropTypes.string,
	/* portfolio cover image */
	image: PropTypes.string,
	/* number of id of the portfolio we are creating or editing */
	id: PropTypes.number,
	/** default name of the portfolio */
	currentPortfolioName: PropTypes.string,
	/** validation schema */
	validationSchema: PropTypes.object,
};
