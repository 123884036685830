import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description Renders a green checkbox customizable input. The checked attribute can be toggled on or off.
 * @name
 * @returns
 */

export const InputGreenCheckboxAlt = ({ text, containerDesign, checked }) => {
	return (
		<div className={`flex gap-2 ${containerDesign}`}>
			<input
				type='checkbox'
				checked={checked}
				className={`h-4 w-4 appearance-none rounded-sm border-2 
						border-green-50 after:relative 
						after:-top-1/2 after:text-white checked:bg-green-50 checked:after:content-['✓']`}
			/>
			<label className='body-Text2'>{text}</label>
		</div>
	);
};

InputGreenCheckboxAlt.propTypes = {
	/**Takes in a text rendered by the component */
	text: PropTypes.string,
	/**Takes in styles for the component container */
	containerDesign: PropTypes.string,
	/**Takes in styles for the text */
	textDesign: PropTypes.string,
	/**Takes in a bool to validate whether the checked attribute is active or not */
	checked: PropTypes.bool,
};
