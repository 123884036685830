import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//BL
import { portfolio } from '../../../BusinessLogic/data/PlantillaCollection';
import { getArtist } from '../../../BusinessLogic/redux/reducers/artist';
import { getCollection } from '../../../BusinessLogic/redux/reducers/collection';

//UI
import ArrowLeftWhite from '../../../UI/assets/icons/ArrowLeftWhite.svg';
import ButtonFabSecondary from '../../../UI/atoms/Buttons/FAB/ButtonFabSecondary';
import PlantillaCollection from '../../../UI/organisms/Plantilla/PlantillaCollection';
/**
 * @description Preview of the default plantilla data
 * @returns
 */

const TemplateCollectionPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { collectionSingle } = useSelector((state) => state.collections);
	const { appUser } = useSelector((state) => state.appUser);
	const { artworks } = useSelector((state) => state.artworks);
	const { uniqueIdCollection, portfolioPreview } = useSelector(
		(state) => state.portfolios
	);
	const { singleArtist } = useSelector((state) => state.artists);

	const [portfolioPreviewData, setPortfolioPreviewData] = useState({});

	const isArtistUser = appUser.role_company === 'ARTIST';
	const isCatalogueArtistUser = appUser.role_company === 'ARTIST_CATALOGATION';

	useEffect(() => {
		if (
			uniqueIdCollection &&
			uniqueIdCollection !== `${collectionSingle?.id}`
		) {
			dispatch(getCollection(uniqueIdCollection));
		}
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		const id = appUser.main_artist_id;

		if (isArtistUser || isCatalogueArtistUser) {
			dispatch(getArtist({ id }));
		}
	}, [
		appUser.main_artist_id,
		appUser.role_company,
		artworks,
		dispatch,
		isArtistUser,
		isCatalogueArtistUser,
	]);

	useEffect(() => {
		let collectionSingleFiles = [];
		// Rename collectionSingle Files key main_picture_url to main_picture
		if (collectionSingle?.collection_artworks) {
			collectionSingleFiles = collectionSingle.collection_artworks?.map(
				({ main_picture_url: main_picture, ...rest }) => ({
					main_picture,
					...rest,
				})
			);
		}
		// Update portfolioPreviewData state
		if (collectionSingle && portfolioPreview !== false) {
			setPortfolioPreviewData((portfolioPreviewData) => ({
				...portfolioPreviewData,
				description: collectionSingle.description,
				name: collectionSingle.name,
				organization_name: appUser.first_name,
				main_picture: collectionSingle.main_picture_url,
				artworks: collectionSingleFiles,
				artist: singleArtist,
			}));
		} else {
			setPortfolioPreviewData({
				...portfolio,
			});
		}
	}, [appUser, portfolioPreview, singleArtist, collectionSingle]);

	return (
		<main>
			<div className='absolute top-5 left-5 md:top-10 md:left-10'>
				<ButtonFabSecondary
					action={() => {
						navigate(-1);
					}}
					design=''
					icon={ArrowLeftWhite}
				/>
			</div>
			<PlantillaCollection
				portfolio={portfolioPreviewData}
				showLoading={false}
			/>
		</main>
	);
};

export default TemplateCollectionPage;
