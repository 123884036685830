//3rd party
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//UI
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import CardSwitch from '../../../UI/molecules/Card/CardSwitch';
import { ReactComponent as StarIcon } from '../../../UI/assets/icons/StarIcon.svg';
import { ReactComponent as MoneyIcon } from '../../../UI/assets/icons/MoneyIcon.svg';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import AddWhite from '../../../UI/assets/icons/AddWhite.svg';
import CallBenefitForm from '../../../UI/organisms/Call/CallBenefitForm';
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import UnderlineHr from '../../../UI/atoms/UnderLine/UnderlineHr';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import { ReactComponent as EditIcon } from '../../../UI/assets/icons/pencil.svg';

//BL
import { updateEditingCall } from '../../../BusinessLogic/redux/reducers/call';

import {
	usePutCall,
	useGetSingleCall,
} from '../../../BusinessLogic/hooks/query/useQueryCalls';

/**
 * @description Renders the benefits view of a call
 * @param {*} param0
 * @returns
 */
const CallBenefits = () => {
	const { t } = useTranslation();
	const { slug } = useParams();
	const { editingCall } = useSelector((state) => state.call);

	const { singleCall } = useGetSingleCall(slug);
	const dispatch = useDispatch();
	const [hasBenefits, setHasBenefits] = useState(editingCall.has_benefit);
	const [showBenefitForm, setShowBenefitForm] = useState(false);
	const [selectedBenefit, setSelectedBenefit] = useState(undefined);

	const putCallMutation = usePutCall();

	useEffect(() => {
		if (hasBenefits !== editingCall.has_benefit) {
			dispatch(
				updateEditingCall({
					...editingCall,
					has_benefit: hasBenefits,
				})
			);
		}
	}, [dispatch, editingCall, hasBenefits]);

	const getBenefitIcon = (benefitType) => {
		switch (benefitType) {
			case 'ECONOMICO':
				return <MoneyIcon />;
			default:
				return <StarIcon />;
		}
	};

	const handleEditBenefit = (benefit) => {
		setSelectedBenefit(benefit);
		setShowBenefitForm(true);
	};

	const onSwitchAction = () => {
		putCallMutation.mutateAsync({
			...editingCall,
			has_benefit: hasBenefits !== editingCall.has_benefit && hasBenefits,
		});
	};

	return (
		<>
			{showBenefitForm ? (
				<CallBenefitForm
					setShowBenefitForm={setShowBenefitForm}
					initialValues={selectedBenefit}
					edit={selectedBenefit !== undefined}
				/>
			) : (
				<TemplateWithNavBar>
					<NavbarSecondaryNew
						title={showBenefitForm ? 'Nuevo Beneficio' : 'Beneficios'}
						textColor='text-purple-50 w-max'
						color='bg-purple-50'
						showDots={false}
						redirect={`/convocatorias/${slug}`}
						textBack={`${t('RegresarConvocatoria')}`}
						textBackDesign='pl-3 text-purple-50'
						arrowLeftColor='purple'
					/>
					<div className='w-full'>
						<CardSwitch
							mainText={'Requerido'}
							secondText={'Beneficios de la convocatoria.'}
							switched={hasBenefits}
							setSwitched={setHasBenefits}
							onSwitchAction={onSwitchAction}
						/>
						{hasBenefits && (
							<div>
								<div className='flex w-full flex-col flex-wrap'>
									{(singleCall?.benefits ?? []).length > 0 && (
										<div className='flex flex-col gap-1 py-4'>
											<TextTitleSub
												title={'Beneficios'}
												textColor={'text-purple-50'}
											/>
											<UnderlineHr colour={'purple'} />
										</div>
									)}
									{(singleCall?.benefits ?? []).map((benefit, i) => (
										<div
											key={i}
											className='my-3 flex flex-row items-start gap-2'
										>
											<div className='flex aspect-square w-16 flex-col items-center justify-start'>
												{getBenefitIcon(benefit.benefit_type)}
											</div>
											<div className='flex w-full flex-col gap-1'>
												<TextContentSecond
													text={benefit.benefit_name}
													design={'text-purple-50'}
													style={{ fontSize: '1rem', fontWeight: '700' }}
												/>
												<TextContentSecond text={benefit.benefit_description} />
											</div>
											<div
												className='cursor-pointer'
												onClick={() => handleEditBenefit(benefit)}
											>
												<EditIcon />
											</div>
										</div>
									))}
								</div>
								<div className='mt-8 mb-16 flex w-full flex-row justify-center gap-3'>
									<ButtonFab
										label={'Agregar beneficio'}
										size={'large'}
										icon={AddWhite}
										action={() => {
											setSelectedBenefit();
											setShowBenefitForm(true);
										}}
									/>
								</div>
							</div>
						)}
					</div>
				</TemplateWithNavBar>
			)}
		</>
	);
};

export default CallBenefits;
