import PropTypes from 'prop-types';

//components
import TextTitlePrincipal from '../../atoms/Text/Titles/TextTitlePrincipal';

/**
 * @description title in each of the sections
 * @param {*} param0
 * @returns
 */

export default function TextMainTitle({ title, color, textColor, design }) {
	return (
		<div className={design}>
			<TextTitlePrincipal title={title} textColor={textColor} />
			<hr className={` ${color}  h-0.5`} />
		</div>
	);
}

TextMainTitle.propTypes = {
	/** Text of the title */
	title: PropTypes.string,
	/** Color of the HR below the title. Updates the classname attribute  */
	color: PropTypes.string,
	/** Color of the text of the title. Updates the classname attribute  */
	textColor: PropTypes.string,
	/** handles stiles of the container  */
	design: PropTypes.string,
};

TextMainTitle.defaultProps = {
	design: 'text-center flex flex-col',
};
