import { PropTypes } from 'prop-types';

export default function CollectionsButton({ label }) {
	return (
		<button
			className='flex max-w-max cursor-default items-center
			 justify-center rounded-3xl bg-black-50 px-4 py-1 text-xs text-white'
		>
			{label}
		</button>
	);
}

CollectionsButton.propTypes = {
	/*Name of the collection of a work */
	label: PropTypes.string,
};
