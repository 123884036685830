const filterPostulationGalleryInitialValues = {
	artist_id: '',
	sorting: '',
	category: '',
	technique: '',
	country: '',
	city: '',
	name: '',
	organization: '',
	date: '',
};

const filterPostulationArtistInitialValues = {
	sorting: '',
	category: '',
	technique: '',
	country: '',
	city: '',
	name: '',
	organization: '',
	date: '',
};

export {
	filterPostulationGalleryInitialValues,
	filterPostulationArtistInitialValues,
};
