// 3rd party
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// UI
import SectionDocumentGrid from '../Section/SectionDocumentGrid';
import { ReactComponent as DownloadIcon } from '../../assets/icons/blackDownload.svg';
import { ReactComponent as DeleteBlack } from '../../assets/icons/DeleteBlack.svg';

/**
 * @description renders a tab with an document grid section or its edit version
 * @param {*} param0
 * @returns
 */
function TabDocumentAndEdit({
	information,
	indexInfo,
	color,
	AddIconLink,
	saveLastSectionURL,
	saveURLHistory,
	deleteDocumentFunction,
}) {
	const location = useLocation();
	//edit button states
	const [hasEdit, setHasEdit] = useState(false);
	const [showDeleteOption, setShowDeleteOption] = useState(false);
	const [turnEdit, setTurnEdit] = useState(false);
	const [showEditPopup, setShowEditPopup] = useState(false);
	const [hasDots, setHasDots] = useState(false);
	const [showDownloadOptions, setShowDownloadOptions] = useState(false);
	const [showDotsPopup, setShowDotsPopup] = useState(false);

	const { t } = useTranslation();
	useEffect(() => {
		setTurnEdit(false);
	}, [indexInfo]);

	const isCollectionPage = location.pathname.includes('colecciones');

	const dotsOptionsInDocumentTab = [
		{
			label: `${t('DescargarDocumentos')}`,
			action: () => {
				setShowDownloadOptions(!showDownloadOptions);
				setShowDotsPopup(false);
				setShowDeleteOption(false);
			},
			icon: <DownloadIcon className='h-6 w-6' />,
		},
		{
			label: `${t('EliminarDocumentos')}`,
			action: () => {
				setShowDeleteOption(!showDeleteOption);
				setShowEditPopup(false);
				setShowDownloadOptions(false);
			},
			icon: <DeleteBlack className='h-6 w-6' />,
		},
	];

	const documentTabDotsOptionsInCollection = [
		{
			label: `${t('DescargarDocumentos')}`,
			action: () => {
				setShowDownloadOptions(!showDownloadOptions);
				setShowDotsPopup(false);
				setShowDeleteOption(false);
			},
			icon: <DownloadIcon className='h-6 w-6' />,
		},
	];

	useEffect(() => {
		setHasDots(false);
		setHasEdit(false);
		setShowDownloadOptions(false);
		setShowDeleteOption(false);

		//specific layout by tab
		if (information.tab === 'Documentos') {
			setHasDots(true);
			setHasEdit(true);
		}
	}, [indexInfo, information.tab]);

	return (
		<div className='flex flex-col bg-light_grey-50 p-4 px-8'>
			<SectionDocumentGrid
				sectionTitle={t(information.tab)}
				documents={information.content}
				colorHr={color}
				turnEdit={turnEdit}
				hasDots={hasDots}
				hasEdit={hasEdit}
				setHasEdit={setHasEdit}
				setTurnEdit={setTurnEdit}
				AddIconLink={AddIconLink}
				saveLastSectionURL={saveLastSectionURL}
				saveURLHistory={saveURLHistory}
				dotsOptionsInTab={
					isCollectionPage
						? documentTabDotsOptionsInCollection
						: dotsOptionsInDocumentTab
				}
				showDownloadOptions={showDownloadOptions}
				setShowDownloadOptions={setShowDownloadOptions}
				showDeleteOption={showDeleteOption}
				setShowDeleteOption={setShowDeleteOption}
				deleteDocumentFunction={deleteDocumentFunction}
				showDotsPopup={showDotsPopup}
				setShowEditPopup={setShowEditPopup}
				showEditPopup={showEditPopup}
				setShowDotsPopup={setShowDotsPopup}
			/>
		</div>
	);
}

TabDocumentAndEdit.propTypes = {
	/* object with title, type, and content of the tab*/
	information: PropTypes.object,
	/* number that representes the tab that is displayed */
	indexInfo: PropTypes.number,
	/* color used in title and hr in the section */
	color: PropTypes.string,
	/* url used in the add icon */
	AddIconLink: PropTypes.string,
	/**  bool that handle to save the actual specific location */
	saveLastSectionURL: PropTypes.bool,
	/* function that execute to save the actual url in redux state */
	saveURLHistory: PropTypes.func,
	/**  function that organize the grid of images in Images Tabs  */
	submitOrganizeImageGrid: PropTypes.func,
	/**  function that delete a single image in Images Tab */
	deleteImagesFunction: PropTypes.func,
};

TabDocumentAndEdit.defaultProps = {
	saveLastSectionURL: false,
};

export default TabDocumentAndEdit;
