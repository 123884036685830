// 3rd party
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @description render a container of a navbar after this one is triggered, and inside render the children.
 * @returns
 */
export default function PopupSmall({ children, ref, design = 'bg-white' }) {
	return (
		<div className='relative z-20' ref={ref}>
			<div
				className={`ring-black absolute flex origin-top-right flex-col shadow-lg ring-opacity-5 ${design}`}
			>
				{children}
			</div>
		</div>
	);
}

PopupSmall.propTypes = {
	/** additional tailwind styles for the container */
	design: PropTypes.string,
	/** this is used in a special case when it need to save the DOM element in a refState */
	ref: PropTypes.func,
	/** any react element that can be render inside the popup container */
	children: PropTypes.node,
};
