// 3rd Party
import PropTypes from 'prop-types';

// BL
import { addBreakLinesTagsToText } from '../../../../BusinessLogic/helpers/textFormat';

export default function ExpertSlideElementText({ text, designText }) {
	return (
		<p className={`font-mulish text-black-50 ${designText}`}>
			{addBreakLinesTagsToText(text)}
		</p>
	);
}

ExpertSlideElementText.propTypes = {
	text: PropTypes.string,
	designText: PropTypes.string,
};
