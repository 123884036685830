export const PAYMENTS_ES = {
	Suscrito: 'Suscrito',
	IrAlInicio: 'Ir al inicio',
	Planes: 'Planes',
	CreaTuCuentaDeArtista: 'Crea tu cuenta de artista',
	SeleccionaUnPlan: 'Selecciona un plan',
	PlanMensual: 'Plan mensual',
	PlanAnual: 'Plan anual',
	TienesUnCupondeDescuento: '¿Tienes un cupón de descuento?',
	QuieresObtenerUnCuponDeDescuento: `¿Quieres obtener un cupón de descuento? Puedes conseguir una cuenta
    en Datarte gratuita aliándote con nosotros. Nos aliamos con otras
    organizaciones de arte para aumentar el impacto de las
    convocatorias. Contáctanos expresando tu motivación de alianza al
    correo electrónico `,
	MejorOferta: 'Mejor oferta',
	Suscribirse: 'Suscribirse',
	EstoIncluye: 'Esto incluye:',
	AplicanTerminosCondiciones: '*aplican términos y condiciones',
	EstaSuscripcionSeraRenovada:
		'*esta suscripción será automáticamente renovada cada año.',
	PlanGratis: 'Gratis',
	PlanPremium: 'Membresía',
	ArtistaIndividual: 'Artista individual',
	PorAnualidad: 'por año',
	PorSiempre: 'por siempre',
	PorMes: 'por mes',
	PrecioPorDemanda: '*Precio por demanda',
	ContactoDatarte: 'a contacto@datarte.art',
	EncuentraNuevasOportunidadesYPostulateALasConvocatorias:
		'Encuentra nuevas oportunidades y postúlate a las convocatorias',
	AplicaAConvocatoriasIlimitadasDesdeLaApp:
		'Aplica a convocatorias ilimitadas desde la app',
	DescubreNuevasOportunidadesCadaSemana:
		'Descubre nuevas oportunidades cada semana',
	CreaPortafoliosVirtualesPersonalizados:
		'Crea portafolios virtuales personalizados',
	OrganizaTusObrasPorColeccionesEnLaNube:
		'Organiza tus obras por colecciones en la nube',
	TeAyudamosAAumentarLasPosibilidadesDeGanarteLasOportunidades:
		'Te ayudamos a aumentar las posibilidades de ganarte las oportunidades',
	ApoyoEnLasOportunidadesQueQuieresGanarte:
		'Apoyo en las oportunidades que quieres ganarte:',
	MentoriaDeApoyo: 'Mentoría de apoyo',
	ChatDeApoyo: 'Chat de apoyo',
	AccesoAnticipadoYPrioritarioDeLasOportunidades:
		'Acceso anticipado y prioritario de las oportunidades',
	OptimizacionDeTuPerfilComoArtista: 'Optimización de tu perfil como artista:',
	CursosVirtualesEnVivo: 'Cursos virtuales en vivo',
	CursosVirtualesPregrabadosConLecturasYHojasDeTrabajo:
		'Cursos virtuales pregrabados con lecturas y hojas de trabajo',
	AccesoALecturasYHojasDeTrabajo: 'Acceso a lecturas y hojas de trabajo',
	NewsletterConLasUltimasTendenciasReferentesDestacadosYPreciosActualesDeMercado:
		'Newsletter con las últimas tendencias, referentes destacados y precios actuales de mercado',
	NewsletterConListaDeEventosYExposiciones:
		'Newsletter con lista de eventos y exposiciones',
	MentoriaGrupalDeSeguimientoCreativo:
		'Mentoría grupal de seguimiento creativo',
	HazteMiembro: 'Hazte miembro!',
	Aceptamos10MiembrosNuevosAlMes: 'Aceptamos 10 miembros nuevos al mes',
	PlanActivo: 'Plan activo',
	FechaDeVencimiento: 'Fecha de vencimiento',
	CancelarPlan: 'Cancelar plan',
	CancelarPlanAnual: 'Cancelar membresía anual',
	SeguroCancelarPlan: '¿ Seguro que deseas cancelar tu plan ?',
	SiCancelasPlan:
		'Si decides cancelar, perderás todos los beneficios disponibles por el resto del año vigente. Ten en cuenta que el pago realizado no es reembolsable.',
	PlanCancelado: 'Plan cancelado',
	PlanCanceladoText: 'Su plan ha sido cancelado satisfactoriamente.',
	EligeTuPlan: 'Elige tu plan',
	RegresarConfiguracion: 'Regresar a configuración',
	CambiaPlan: 'Cambiar plan',
	SeguroCambiaPlan: '¿Seguro que deseas cancelar tu membresía anual? ',
	SiCambiaPlan:
		'Si decides cambiar, perderás todos los beneficios disponibles por el resto del año vigente. Ten en cuenta que el pago realizado no es reembolsable.',

	UnUnicoPago: '*un único pago al año. No reembolsable. ',
};
