import { useEffect } from 'react';

export function useCheckStorageVersion(version) {
	useEffect(() => {
		localStorage.getItem('lng');
		const appVersion = localStorage.getItem('APP_VERSION');
		if (appVersion && appVersion !== version) {
			localStorage.clear();
			window.location.reload();
			localStorage.setItem('APP_VERSION', version);
			return;
		}

		if (appVersion === 'undefined' || appVersion === null) {
			localStorage.setItem('APP_VERSION', version);
		}
	}, [version]);
}
