//3rd party
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useState } from 'react';

//UI
import { ReactComponent as HouseWhite } from '../../../assets/icons/HouseWhite.svg';
import { ReactComponent as ShareNetworkWhite } from '../../../assets/icons/ShareNetworkWhite.svg';
import { ReactComponent as EyeWhite } from '../../../assets/icons/EyeWhite.svg';
import { ReactComponent as DownloadSimpleWhite } from '../../../assets/icons/DownloadSimpleWhite.svg';
import { ReactComponent as FadersHorizontalWhite } from '../../../assets/icons/FadersHorizontalWhite.svg';
import { ReactComponent as ArrowLeftWhite } from '../../../assets/icons/ArrowLeftWhite.svg';
import TextContentPrincipal from '../../../atoms/Text/Content/TextContentPrincipal';
import DownloadableExpertPortfolio from '../../../templates/PortfolioTemplates/DownloadableExpertPortfolio/DownloadableExpertPortfolio';
import SharePortfolio from '../../../../Pages/sections/portfolio/SharePortfolio';

/**
 * @description Navbar for Expert Portfolio
 * @param {*} param0
 * @returns Functional component - EditablePorfolioTopNavbar
 */

const EditablePortfolioTopNavbar = () => {
	const navigate = useNavigate();
	const { portfolioId } = useParams();
	const location = useLocation();
	const currentLocation = location.pathname;

	const { singlePortfolio } = useSelector((state) => state.portfolios);
	const [showShareModal, setShowShareModal] = useState(false);

	return (
		<nav
			className={`z-50 flex bg-green-50 py-2 px-4 shadow transition-all duration-300 md:py-4`}
		>
			{!currentLocation.includes('edicion') && (
				<div
					className='flex cursor-pointer items-center gap-4'
					onClick={() =>
						navigate(`/portafolios/portafolio-experto/${portfolioId}/edicion`)
					}
				>
					<ArrowLeftWhite />
				</div>
			)}
			{currentLocation.includes('edicion') && (
				<div className='flex w-full justify-between gap-8'>
					<div
						className='flex cursor-pointer items-center gap-4 transition-all duration-300 hover:scale-105'
						onClick={() => navigate('/portafolios')}
					>
						<HouseWhite className={'md:hidden'} />
						<ArrowLeftWhite className='hidden md:block' />
						<TextContentPrincipal
							text='Regresar a portafolios'
							design={'hidden md:block text-white text-sm'}
							style={{ fontWeight: '700' }}
						/>
					</div>
					<div className='flex flex-row items-center gap-2 transition-all duration-300 xxs:gap-4 md:gap-8'>
						{currentLocation.includes('edicion') && (
							<>
								<EyeWhite
									className='cursor-pointer transition-all duration-300 hover:scale-110'
									onClick={() => {
										navigate(`/portafolio/${singlePortfolio.slug}`);
									}}
								/>
								<ShareNetworkWhite
									className='cursor-pointer transition-all duration-300 hover:scale-110'
									onClick={() => {
										setShowShareModal(true);
									}}
								/>
								<PDFDownloadLink
									document={
										<DownloadableExpertPortfolio
											expertPortfolioData={singlePortfolio}
										/>
									}
									fileName={`Portafolio - ${singlePortfolio?.name}`}
								>
									<DownloadSimpleWhite className='cursor-pointer  transition-all duration-300 hover:scale-110' />
								</PDFDownloadLink>
							</>
						)}
						<FadersHorizontalWhite
							className='cursor-pointer  transition-all duration-300 hover:scale-110'
							onClick={() => {
								navigate(
									`/portafolios/portafolio-experto/${portfolioId}/configuracion`
								);
							}}
						/>
					</div>
				</div>
			)}
			{showShareModal && (
				<div
					className='fixed top-0 right-0 z-[9999] flex h-full w-full cursor-pointer bg-black-50/70 bg-opacity-70 shadow-xl'
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<SharePortfolio
						urlToShare={`${window.location.origin}/portafolio/${singlePortfolio.slug}`}
						setShare={setShowShareModal}
						share={showShareModal}
						designTwo={
							'fixed inset-0 bg-black-50 bg-opacity-75 transition-opacity'
						}
					/>
				</div>
			)}
		</nav>
	);
};

export default EditablePortfolioTopNavbar;
