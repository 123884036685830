/* React */
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/* BL */
import {
	getPortfolio,
	putPortfolio,
	getPortfolios,
} from '../../../BusinessLogic/redux/reducers/portfolio';
import {
	portfolioSingleButtons,
	portfolioValidationSchema,
} from '../../../BusinessLogic/data/PortfolioSingle';
/* UI */
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import NewTagButtons from '../../../UI/molecules/Buttons/Tag/NewTagButtons';
import TabInfoAndEdit from '../../../UI/organisms/Tabs/TabInfoAndEdit';
import PortfolioEditImageAndVisualize from '../../../UI/organisms/Portfolio/PortfolioEditImageAndVisualize';
import { ReactComponent as Delete } from '../../../UI/assets/icons/Delete.svg';
import ButtonFabExtendeNav from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendeNav';
import ViewSingleSkeleton from '../../../UI/templates/SkeletonTemplates/ViewSingleSkeleton';
import { ModalPostulation } from '../../../UI/organisms/PopUp/ModalPostulation';
import PageNotFound from '../../fallBackPages/PageNotFound';
import PortfolioLoading from '../../../UI/organisms/Loading/PortfolioLoading';

/**
 * @description new portfolio single section. It has an Image carousel and tabs.
 * Also includes the management of the edit.
 */

const PortfolioSingle = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { singlePortfolio, portfolioSingleTabs, portfolios, status } =
		useSelector((state) => state.portfolios);

	const [allPortfolios, setAllPortfolios] = useState([]);
	const [showModalCannotDelete, setShowModalCannotDelete] = useState(false);
	const [indexInfo, setIndexInfo] = useState(0);
	const [showAlertModal, setShowAlertModal] = useState(false);

	useEffect(() => {
		if (!portfolios) {
			dispatch(getPortfolios());
		} else {
			setAllPortfolios(portfolios);
		}
		dispatch(getPortfolio(id));
	}, [dispatch, id, portfolios]);

	const onSubmitAction = async (values) => {
		await dispatch(putPortfolio(values));
		dispatch(getPortfolios());
	};

	const renderSwitch = () => {
		switch (portfolioSingleTabs[indexInfo].type) {
			case 'Information':
				return (
					<TabInfoAndEdit
						information={portfolioSingleTabs[indexInfo]}
						onSubmitAction={onSubmitAction}
						indexInfo={indexInfo}
						id={parseInt(id)}
						validationSchema={portfolioValidationSchema[indexInfo]}
						section='Portafolio'
					/>
				);
			default:
				return <h1>No hay portafolios disponibles</h1>;
		}
	};

	if (
		singlePortfolio?.code === 403 ||
		parseInt(singlePortfolio?.code) === 404
	) {
		return <PageNotFound />;
	}

	if (!singlePortfolio) {
		return <PortfolioLoading />;
	}

	return (
		<div>
			{showModalCannotDelete && (
				<ModalPostulation
					modalTitle={t('PortafolioNoPuedeEliminarse')}
					modalText={t('PortafolioNoPuedeEliminarseDescription')}
					buttonLabel={t('Cerrar')}
					modalClassName={'mx-auto w-4/5 px-8'}
					actionClose={() => setShowModalCannotDelete(false)}
				/>
			)}
			{status === 'loading' ? (
				<ViewSingleSkeleton
					numberOfTitleAndFields={1}
					numberOfTabs={0}
					numberOfSingleRow={4}
					designCaption={'hidden'}
					designOfBottomTitleAndField={'hidden'}
					designSkeletonTitle2={'invisible'}
				/>
			) : (
				<TemplateWithNavBar section='Portafolios'>
					<NavbarSecondaryNew
						title={singlePortfolio?.name}
						color='bg-yellow-50'
						textColor='text-yellow-50'
						redirect='/portafolios'
						textPopup={`${t('EliminarPortafolio')}`}
						popupIcon={<Delete />}
						modalTitle={`${t('EliminarPortafolio')}`}
						modalTextOne={`${t('SeguroQueDeseasEliminarEstePortafolio')}`}
						id={parseInt(id)}
						section='portafolios'
						textBack={`${t('RegresarPortafolios')}`}
						textBackDesign='pl-3 text-yellow-50'
						arrowLeftColor='yellow'
						titleContainer='mb-6 text-center md:mb-0 '
						showDots={!singlePortfolio.is_general_portfolio}
						handleDeleteError={() => setShowAlertModal(true)}
						setShowModalCannotDelete={setShowModalCannotDelete}
					/>
					<ButtonFabExtendeNav
						id={parseInt(id)}
						listItems={allPortfolios}
						section='portafolios'
					/>
					<div className='flex w-full flex-col gap-8 md:flex-row'>
						<PortfolioEditImageAndVisualize
							image={singlePortfolio.main_picture_url}
							alt={singlePortfolio.name}
							buttonAction={() =>
								navigate(`/portafolio/${singlePortfolio.slug}`)
							}
							design='md:pt-7 mb-12'
							wrapperClassName='w-60 h-60 lg:w-80 lg:h-80'
						/>
						<div className='flex w-full flex-col md:w-2/3'>
							<NewTagButtons
								buttonList={portfolioSingleButtons}
								indexInfo={indexInfo}
								setIndexInfo={setIndexInfo}
							/>

							<div className='min-h-screen bg-light_grey-50 py-4'>
								{renderSwitch()}
							</div>
						</div>
					</div>
				</TemplateWithNavBar>
			)}
			{showAlertModal && (
				<ModalPostulation
					modalTitle={t('NoPuedesBorrarEstePortafolio')}
					modalText={t('EstePortafolioHaceParteDeUnaDeTusConvocatorias')}
					buttonLabel={t('Cerrar')}
					actionClose={() => setShowAlertModal(false)}
					modalClassName='text-center'
				/>
			)}
		</div>
	);
};

export default PortfolioSingle;

PortfolioSingle.propTypes = {};
