//3rd party
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
//BL
import {
	deleteUserFromOrganization,
	getAppUser,
	getUserOrganizations,
	putAppUser,
	putAppUserPassword,
} from '../../../BusinessLogic/redux/reducers/appUser';
import {
	buttonsUserProfile,
	buttonsUserProfileArtist,
	userProfileValidationSchema,
} from '../../../BusinessLogic/data/Perfil';

//UI
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import TabInfoAndEdit from '../../../UI/organisms/Tabs/TabInfoAndEdit';
import NewTagButtons from '../../../UI/molecules/Buttons/Tag/NewTagButtons';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import ImageAndTitle from '../../../UI/molecules/Image/ImageAndTitle';
import roleUserDataBuilder from '../../../BusinessLogic/helpers/roleUserDataBuilder';

/**
 *
 * @description render a configuration view of the user information
 * @param {*} param0
 * @returns
 */
export default function ConfigurationUser() {
	const dispatch = useDispatch();
	let [searchParams] = useSearchParams();
	const { userProfileTabs, appUserChange, appUser, userOrganizations } =
		useSelector((state) => state.appUser);
	const userRole = appUser.role_company.toLowerCase();
	const isArtistUser = userRole === 'artist';

	const tabSearchParam = searchParams.get('tab');

	const [indexInfo, setIndexInfo] = useState(Number(tabSearchParam) || 0);
	const { t } = useTranslation();

	let { organization_user_id, role_user_id } = localStorage;

	useEffect(() => {
		dispatch(getAppUser());
		dispatch(getUserOrganizations());
	}, [dispatch, appUserChange]);

	const onSubmitAction = (values) => {
		const newValues = {
			...values['values'],
			organization_user_id: organization_user_id,
			role_id: role_user_id,
		};
		dispatch(putAppUser(newValues));
	};

	const confirmDeleteElement = (selectedUserId) => {
		dispatch(deleteUserFromOrganization(selectedUserId));
	};

	const changePasswordFunction = (values) => {
		values = {
			...values['values'],
		};
		dispatch(putAppUserPassword(values));
	};

	const renderSwitch = () => {
		switch (userProfileTabs[indexInfo]?.type) {
			case 'Information':
				return (
					<TabInfoAndEdit
						information={userProfileTabs[indexInfo]}
						index={indexInfo}
						colorHr='green'
						onSubmitAction={onSubmitAction}
						validationSchema={
							userProfileValidationSchema[indexInfo]
								? userProfileValidationSchema[indexInfo]
								: []
						}
						hideEdit={true}
						hasInviteUser={true}
						usersOrganizationInformation={roleUserDataBuilder(
							userOrganizations
						)}
						confirmDeleteElement={confirmDeleteElement}
					/>
				);
			case 'Password':
				return (
					<TabInfoAndEdit
						information={userProfileTabs[indexInfo]}
						index={indexInfo}
						colorHr='green'
						onSubmitAction={changePasswordFunction}
						validationSchema={
							userProfileValidationSchema[indexInfo]
								? userProfileValidationSchema[indexInfo]
								: []
						}
					/>
				);

			case 'Plans':
				if (!isArtistUser) {
					return null;
				}
				return (
					<TabInfoAndEdit
						information={userProfileTabs[indexInfo]}
						index={indexInfo}
						colorHr='green'
						hideEdit={true}
					/>
				);

			default:
				return <p className='p-2'>{t('NoHayInformaciónDisponible')}</p>;
		}
	};

	return (
		<TemplateWithNavBar>
			<SecondaryNavbarSpecific
				title={t('Configuración')}
				textColor='text-green-50'
				color='bg-green-50'
				hide='invisible'
				textBack={t('RegresarHome')}
				arrowLeftColor='green'
				textBackDesign='pl-3 text-green-50'
			/>
			<div className='mx-2 flex w-full flex-col md:mt-10 md:flex-row md:gap-2 lg:gap-0'>
				<div className='flex w-full justify-center p-6 md:w-1/3 '>
					<ImageAndTitle aspectRatioProp='auto' imagen={''} />
				</div>
				<div className='flex w-full flex-col px-2 md:w-2/3 xl:w-2/3'>
					<NewTagButtons
						buttonList={
							isArtistUser ? buttonsUserProfileArtist : buttonsUserProfile
						}
						indexInfo={indexInfo}
						setIndexInfo={setIndexInfo}
					/>
					<div className='min-h-screen bg-light_grey-50 py-4'>
						{renderSwitch()}
					</div>
				</div>
			</div>
		</TemplateWithNavBar>
	);
}
