import { useLocation } from 'react-router';
import { useState, useEffect } from 'react';

export const useHideTawkChat = () => {
	const location = useLocation();
	const [isVisible, setIsVisible] = useState(false);
	useEffect(() => {
		const routesToHide = ['/portafolios/portafolio-experto', '/portafolio/'];
		if (
			!window.Tawk_API &&
			routesToHide.some((route) => location.pathname.includes(route))
		) {
			setIsVisible(false);
		} else if (
			window.Tawk_API &&
			routesToHide.some((route) => location.pathname.includes(route))
		) {
			window.Tawk_API.hideWidget();
			setIsVisible(false);
		} else {
			setIsVisible(true);
		}
		return () => {
			if (
				window.Tawk_API &&
				routesToHide.some((route) => location.pathname.includes(route))
			) {
				window.Tawk_API.showWidget();
			}
		};
	}, [location.pathname]);
	return isVisible;
};
