//3rd party
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
	LazyLoadImage,
	trackWindowScroll,
} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactPlayer from 'react-player';

//UI
import ModalImage from '../../organisms/PopUp/ModalImage';

//BL
import { artworkImageAlt } from '../../../BusinessLogic/helpers/altImageHelper';
import { useEffect } from 'react';

/**
 * @description image used in the portfolio section.
 */

function ArtworkImage({
	main_picture,
	name,
	date,
	dimensions_height,
	dimensions_length,
	dimensions_width,
	image_quality,
	technique,
	owner_status,
	scrollPosition,
	price,
	currency,
	showPrices,
	artistName,
	description,
	objectType,
	videoUrl,
}) {
	//manges the quality of the images. Useful to increase the speed
	let image_quality_slug = '';
	if (image_quality === 'HIGH') {
		image_quality_slug = '';
	} else if (image_quality === 'MEDIUM') {
		image_quality_slug = '_medium';
	} else if ((image_quality_slug = 'SMALL')) {
		image_quality_slug = '_small';
	}

	const [showModalImage, setShowModalImage] = useState(false);
	const [showArtworkText, setShowArtworkText] = useState(false);

	const isVideoArtwork =
		objectType === 'VIDEO' ||
		objectType === 'PERFORMANCE' ||
		objectType === 'ANIMATION';

	useEffect(() => {
		if (isVideoArtwork) {
			setShowArtworkText(true);
		}
	}, [isVideoArtwork]);

	const getDimensionsText = () => {
		const dimensionsArray = [
			dimensions_height,
			dimensions_length,
			dimensions_width,
		].filter((dimension) => dimension);

		const dimensionsText =
			dimensionsArray.length > 0 ? `${dimensionsArray.join('x')}cm` : '';

		return dimensionsText;
	};

	const getArtworkYear = (fullDate) => {
		if (!fullDate) return null;

		if (!isNaN(Date.parse(fullDate))) {
			return new Date(fullDate).getFullYear();
		} else {
			return null;
		}
	};

	return (
		<div
			className={`flex flex-col justify-start ${
				isVideoArtwork ? 'w-full' : 'w-fit'
			}`}
		>
			{isVideoArtwork && videoUrl ? (
				<ReactPlayer
					url={videoUrl}
					width={'100%'}
					height={'100%'}
					className='h-full w-auto aspect-video overflow-hidden'
					controls
				/>
			) : (
				<LazyLoadImage
					src={`${main_picture}${image_quality_slug}`}
					placeholderSrc={main_picture + '_small'}
					effect='blur'
					alt={artworkImageAlt(name, artistName, description)}
					wrapperClassName='w-fit cursor-pointer'
					className='max-h-96'
					delayTime='300'
					threshold='300'
					scrollPosition={scrollPosition}
					useIntersectionObserver={true}
					onClick={() => setShowModalImage(true)}
					afterLoad={() => setShowArtworkText(true)}
				/>
			)}
			{showModalImage && (
				<ModalImage
					mainPicture={main_picture}
					name={artworkImageAlt(name, artistName, description)}
					setShowModalImage={setShowModalImage}
					showModalImage={showModalImage}
				/>
			)}
			{showArtworkText && (
				<div className='w-fit text-left'>
					<p className='mt-2 text-sm font-light text-gray-800'>{name}</p>
					<p className='text-sm font-light text-gray-800'>
						{getArtworkYear(date)}
					</p>
					<p className='text-sm font-light text-gray-800'>{technique}</p>
					<p className='text-sm font-light text-gray-800'>
						{getDimensionsText()}
					</p>
					<p className='text-sm font-light capitalize text-gray-800'>
						{owner_status?.toLowerCase()}
					</p>
					{price > 0 && showPrices && (
						<p className='text-sm font-light capitalize text-gray-800'>
							{`${(price ?? '').toLocaleString('es-ES')} ${currency}`}
						</p>
					)}
					{isVideoArtwork && videoUrl && (
						<a
							href={videoUrl}
							target='_blank'
							rel='noreferrer'
							className='text-sm font-light text-cyan-600'
						>
							{videoUrl}
						</a>
					)}
				</div>
			)}
		</div>
	);
}

export default trackWindowScroll(ArtworkImage);

ArtworkImage.propTypes = {
	/** URL for the main image */
	main_picture: PropTypes.string,
	/** name of the artwork */
	name: PropTypes.string,
	/** date of the artwork */
	date: PropTypes.string,
	/** Height the artwork */
	dimensions_height: PropTypes.string,
	/** Length the artwork */
	dimensions_length: PropTypes.string,
	/** Width the artwork */
	dimensions_width: PropTypes.string,
	/** Image Quality the artwork */
	image_quality: PropTypes.string,
	/** Technique of the artwork */
	technique: PropTypes.string,
	/** Owner status of the artwork */
	owner_status: PropTypes.string,
	/** price of the artwork */
	price: PropTypes.string,
	/** currency of the artwork */
	currency: PropTypes.string,
	/** Bool prop to set if the price will be show */
	showPrices: PropTypes.bool,
	//** Name of the artist */
	artistName: PropTypes.string,
	//** Description of the artwork */
};

ArtworkImage.defaultProp = {
	image_quality: 'HIGH',
};
