import { useState } from 'react';
import { Field } from 'formik';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//BL
import {
	contactInitialValues,
	contactFormValidationSchema,
} from '../../../BusinessLogic/data/ContactSingle2';
import { backSectionURL } from '../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../BusinessLogic/helpers/textGoBackGenerator';
import {
	getContacts,
	postContact,
} from '../../../BusinessLogic/redux/reducers/contact';

//UI
import whiteCheck from '../../../UI/assets/icons/whiteCheck.svg';
import ListItemText from '../../../UI/atoms/Inputs/ListItemText';
import FieldTextFormik from '../../../UI/molecules/Field/FieldTextFormik';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import ImageSectionForm from '../../../UI/organisms/Image/ImageSectionForm';
import SectionForm from '../../../UI/organisms/Section/SectionForm';
import LoadingText from '../../../UI/molecules/Loading/LoadingText';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

/**
 * @description manages the Person form page edit or create.
 * @returns
 */
export default function PersonForm() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { singleContact } = useSelector((state) => state.contacts);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);

	const { organization_user_id, unknown_values } = localStorage;
	const unknownValues = JSON.parse(unknown_values);
	const [showSaving, setShowSaving] = useState(false);

	//define the variable for the dropwdown input boxes
	const initialValues = {
		...contactInitialValues,
		main_picture_id: unknownValues.picture_id,
		organization_user_id,
		type: 'PERSON',
		type_id: singleContact?.id ?? 0,
		file_type: 'IMAGE',
	};

	const checkRedirect = () => {
		let redirect = '/contactos';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage = 'RegresarContactos';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	return (
		<TemplateWithNavBar>
			<div className={showSaving && 'opacity-60'}>
				<SecondaryNavbarSpecific
					title={t('NuevoContacto')}
					textColor='text-purple-50'
					color='bg-purple-50'
					hide='md:invisible'
					redirect={checkRedirect()}
					textBack={CheckTextBack()}
					textBackDesign='pl-3 text-purple-50'
					backAction={() => returnBackAction()}
				/>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={contactFormValidationSchema}
					onSubmit={(values) => {
						values = {
							...values,
							role: values.role.toString(),
						};
						setShowSaving(true);
						dispatch(postContact(values))
							.then((res) => {
								navigate(`/contactos/${res.payload.id}`);
								dispatch(getContacts());
							})
							.finally(() => setShowSaving(false));
					}}
				>
					{(formProps) => (
						<Form className='mb-20'>
							<List>
								<div className='flex flex-col gap-4 md:flex-row lg:gap-12'>
									<ImageSectionForm
										formProps={formProps}
										dropzoneLabel={'SoltarOClickImagenPortadaContacto'}
										mobileDropzoneLabel={'ClickCargarImagenPortadaContacto'}
									/>
									<div className='mx-auto flex w-full flex-col md:w-1/2'>
										<SectionForm
											title={t('Identificación')}
											textColor='text-purple-50'
											color='bg-purple-50'
										>
											<FieldTextFormik
												label={t('Nombre')}
												dbName='first_name'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.first_name}
											/>
											<FieldTextFormik
												label={t('Apellido')}
												dbName='last_name'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.last_name}
											/>
											<div className='justify-initial'>
												<ListItem
													style={{
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														primary={t('Rol')}
														design='body-Text2 w-36'
														style={{ fontWeight: 400 }}
													/>
													<div className='flex w-40 flex-col md:w-56'>
														<label className='body-Text2'>
															<Field
																type='checkbox'
																name='role'
																value='1'
																className='mr-2.5'
															/>
															{t('Photographer')}
														</label>
														<label className='body-Text2'>
															<Field
																type='checkbox'
																name='role'
																value='2'
																className='mr-2.5'
															/>
															{t('LocationContact')}
														</label>
														<label className='body-Text2'>
															<Field
																type='checkbox'
																name='role'
																value='3'
																className='mr-2.5'
															/>
															{t('Owner')}
														</label>
													</div>
													{formProps.errors?.role && (
														<div
															className={`ml-auto w-40 text-red-400 md:w-56`}
														>
															{t(formProps.errors?.role)}
														</div>
													)}
												</ListItem>
											</div>

											<FieldTextFormik
												label={t('Email')}
												dbName='email'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.email}
											/>
											<FieldTextFormik
												label={t('NúmeroTeléfono')}
												dbName='phone_number'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.phone_number}
											/>
											<FieldTextFormik
												label={t('Otro')}
												dbName='other'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.other}
											/>
										</SectionForm>
										{showSaving ? (
											<LoadingText text={t('Guardando')} />
										) : (
											<ButtonFabExtendedPrimary
												className='self-center text-white'
												icon={whiteCheck}
												text={t('Guardar')}
											/>
										)}
									</div>
								</div>
							</List>
						</Form>
					)}
				</Formik>
			</div>
		</TemplateWithNavBar>
	);
}
