function updateTabsObject(SingleTabs, Single) {
	if (!SingleTabs) return 0;
	if (!Single) return 0;

	let SingleTabsNew = SingleTabs;
	let tabs = SingleTabs;

	tabs.map((tab, tabIndex) => {
		let contents = tab.content;
		return contents.map((content, contentIndex) => {
			if (
				(content.type === 'labels') |
				(content.type === 'cityCountry') |
				(content.type === 'location') |
				(content.type === 'portfolio') |
				(content.type === 'documents')
			) {
				//manages the scenario for labels
				let informations = content?.information;
				informations?.map((information, informationIndex) => {
					let field = information.field;
					if (Object.keys(Single).includes(field)) {
						SingleTabsNew[tabIndex].content[contentIndex].information[
							informationIndex
						].value = Single[field];
					}
					return null;
				});
			} else if (content.type === 'text') {
				//manages the scenario for text
				let fieldText = content.field;
				if (Object.keys(Single).includes(fieldText)) {
					SingleTabsNew[tabIndex].content[contentIndex].information =
						Single[fieldText];
				}
			} else if (content.type === 'images') {
				//manages the scenario for images
				let fieldImage = content.field;

				//check if the objects exist
				if (Object.keys(Single).includes(fieldImage)) {
					let ImageArray = [];
					if (Single[fieldImage]?.length > 0) {
						Single[fieldImage].map((imageElement) => {
							let SingleImage = {};
							SingleImage['id'] = imageElement['id'];

							SingleImage['name'] =
								imageElement['name'] || imageElement['document_name'];
							SingleImage['main_picture_url'] =
								imageElement['main_picture_url'] || imageElement['url'];
							SingleImage['type'] = imageElement['type'];
							SingleImage['order'] = imageElement['order'];
							SingleImage['file_type'] = imageElement['file_type'];
							SingleImage['video_url'] = imageElement['video_url'] ?? null;
							ImageArray.push(SingleImage);
							return null;
						});
						//sort the array by the 'order' key.
						ImageArray.sort((a, b) => a.order - b.order);
					}

					SingleTabsNew[tabIndex].content[contentIndex].information =
						ImageArray;
				}
			}
			return null;
		});
	});
	return SingleTabsNew;
}
function updateDropdowns(SingleTabs, Values, Tab, L1, L2) {
	if (!SingleTabs) return 0;

	let SingleTabsNew = SingleTabs;
	SingleTabsNew[Tab]['content'][L1]['information'][L2]['options'] = Values;

	return SingleTabsNew;
}

export { updateTabsObject, updateDropdowns };
