import React from 'react';
import { Formik, Field } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

//BL

import {
	useGetCallForm,
	useGetSingleCall,
} from '../../../BusinessLogic/hooks/query/useQueryCalls';
import { navbarOptionsGallery } from '../../../BusinessLogic/data/NavbarOptions';
// UI
import NavbarDesktop from '../../../UI/molecules/Navbar/NavbarDesktop';
import ButtonLinkBack from '../../../UI/atoms/Buttons/Link/ButtonLinkBack';
import ButtonLinkBackText from '../../../UI/molecules/Buttons/Link/ButtonLinkBackText';
import TextTitlePrincipal from '../../../UI/atoms/Text/Titles/TextTitlePrincipal';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import TextSubsectionTitle from '../../../UI/molecules/Text/TextSubsectionTitle';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import { ReactComponent as DeleteCanGrey } from '../../../UI/assets/icons/DeleteCanGrey.svg';
import CheckIcon from '../../../UI/assets/icons/whiteCheck.svg';

/**
 * It renders a preview form of a call
 * @returns A component
 */

const CallFormPreview = () => {
	const navigate = useNavigate();
	const { slug } = useParams();

	const { singleCall } = useGetSingleCall(slug);
	const { singleCallForm } = useGetCallForm(singleCall?.form_id);

	const callFormElements = singleCallForm?.forms?.form_elements;

	const renderSwitchFormElementType = (element, index) => {
		switch (element.type) {
			case 'TEXTO_CORTO':
				return (
					<div className='m-8 '>
						<TextSubsectionTitle
							classNameContainer={'text-left'}
							classNameHr={'mb-4 w-full'}
							title={element.name}
							color={'#CBBFD9'}
							textColor={'text-[#CBBFD9]'}
						/>
						<Field
							as='input'
							maxLength='150'
							type='text'
							className='h-[2rem] w-full bg-[#DEDFE2]'
							required={false}
						/>
					</div>
				);
			case 'TEXTO_LARGO':
				return (
					<div className='m-8'>
						<TextSubsectionTitle
							classNameContainer={'text-left '}
							title={element.name}
							color={'#CBBFD9'}
							textColor={'text-[#CBBFD9]'}
						/>
						<Field
							as='textarea'
							className='h-[10rem] w-full bg-[#DEDFE2]'
							type='text'
							required={false}
						/>
					</div>
				);
			case 'ADD_DOC':
				return (
					<div className='m-8'>
						<TextSubsectionTitle
							classNameContainer={'text-left'}
							title={element.name}
							color={'#CBBFD9'}
							textColor={'text-[#CBBFD9]'}
						/>
						<p className='py-1'> Documento adjuntado: </p>
						<div className='flex justify-between'>
							<p className='py-4 text-[#34B3E5]'>{'Documento.pdf'}</p>
							<div className='cursor-pointer py-4'>
								<DeleteCanGrey />
							</div>
						</div>
						<div className='flex w-full flex-col items-center'>
							<ButtonFabExtendedPrimary
								colorClass={'bg-white'}
								className={'text-black'}
								text={'Adjuntar'}
								typeButton={'button'}
							/>
						</div>
					</div>
				);

			default:
				return (
					<div className='m-8'>
						<TextSubsectionTitle
							title={element.title}
							color={'#CBBFD9'}
							textColor={'text-[#CBBFD9]'}
						/>
						<Field className='textarea' type='text' name={element.title} />
					</div>
				);
		}
	};

	return (
		<div className='min-h-screen'>
			<NavbarDesktop
				options={navbarOptionsGallery}
				normalState='rotate-90'
				rotateState='-rotate-90'
			/>
			<div className='mt-8 flex px-2 md:grid md:grid-cols-3'>
				<ButtonLinkBack
					redirect={`/convocatorias/${slug}/formulario`}
					design='md:hidden'
				/>
				<ButtonLinkBackText
					design='hidden md:block w-full'
					textBack={`Regresar a formulario`}
					textBackDesign='pl-3 text-[#CBBFD9]'
					redirect={`/convocatorias/${slug}/formulario`}
					arrowLeftColor='#CBBFD9'
				/>
				<TextTitlePrincipal
					title={'Visualizar'}
					textColor='text-[#CBBFD9]'
					color='bg-[#CBBFD9]'
					design='text-center flex flex-col w-full mr-[10%]'
				/>
			</div>
			<div className='my-8'>
				<Formik>
					{
						<div>
							{callFormElements?.map((element, index) => (
								<div key={index}>{renderSwitchFormElementType(element)}</div>
							))}
							<div className='mb-16 mt-8 flex w-full flex-row justify-center gap-3'>
								<ButtonFab
									size={'large'}
									colour='green'
									label={'Aceptar'}
									icon={CheckIcon}
									action={() => navigate(`/convocatorias/${slug}/formulario`)}
								/>
							</div>
						</div>
					}
				</Formik>
			</div>
		</div>
	);
};

export default CallFormPreview;
