//3rd party
import { PropTypes } from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function ImageFullScreen({
	image,
	alt,
	className,
	placeholderSrc,
}) {
	return (
		<div className={`${className}`}>
			<LazyLoadImage
				alt={alt}
				src={image}
				width='100%'
				height='100%'
				effect='blur'
				className='object-cover'
				wrapperClassName={`overflow-hidden flex items-center lazyLoadingImages min-h-screen `}
				delayTime='0'
				threshold='800'
				placeholderSrc={placeholderSrc ?? image}
			/>
		</div>
	);
}

ImageFullScreen.propTypes = {
	/** URL of the main image */
	image: PropTypes.string,
	/** Alt of the image */
	alt: PropTypes.string,
	/** Tailwind className of the main conmponent */
	className: PropTypes.string,
	/** Source of a placeholder image*/
	placeholderSrc: PropTypes.string,
};
