import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description render an textarea with its label for the form.
 * @returns
 */

export default function InputCustomTextArea(props) {
	return (
		<textarea
			id='formtext'
			className={`h-48 w-full rounded border-none bg-light_grey-50 ${props.designInput}`}
			{...props}
		/>
	);
}
InputCustomTextArea.propTypes = {
	/* styles to input text area */
	designInput: PropTypes.string,
};
