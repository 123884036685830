//3rd party
import { ListItem } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//UI
import InputCustomTextArea from '../../atoms/Inputs/InputCustomTextArea';

/**
 * @description text field used for all the formic calculations
 * @param {*} param0
 * @returns
 */
export default function FieldTextAreaFormik({
	dbName,
	errorMessageDesign,
	errors,
	designInput,
	isInputDisabled,
}) {
	const { t } = useTranslation();
	return (
		<>
			<ListItem
				style={{ display: 'block', paddingLeft: '0px', paddingRight: '0px' }}
			>
				<div className='flex flex-col gap-1'>
					<Field
						name={dbName}
						align='top'
						designInput={designInput}
						as={InputCustomTextArea}
						disabled={isInputDisabled}
					/>
					{errors && (
						<div className={`w-full ${errorMessageDesign}`}>{t(errors)}</div>
					)}
				</div>
			</ListItem>
		</>
	);
}

FieldTextAreaFormik.propTypes = {
	/** This fields connects the data. Please use the same name that on the database for simplicity */
	dbName: PropTypes.string,
	/** Tailwind design of the ErrorMessage*/
	errorMessageDesign: PropTypes.string,
	/** this error string is generated from te yup validation schema in case of a missing requerid field*/
	errors: PropTypes.string,
	/** styles for component input */
	designInput: PropTypes.string,
	/**Determines if the input should be disabled*/
	isInputDisabled: PropTypes.bool,
};
