//3rd parties
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import Eye from '../../../UI/assets/icons/Eye.svg';
import ButtonWithIcon from '../../molecules/Buttons/WithIcon/ButtonWithIcon';

//HELPERS
import { checkPassword } from '../../../BusinessLogic/helpers/checkPassword';

/**
 * @name PasswordModal
 * @description this page manages the modal that allows you to check the password
 * and see the portfolio when it is private
 * @returns
 */

function PasswordModal({ mainTitle, portfolio, setAccess }) {
	const navigate = useNavigate();
	const [seePassword, setSeePassword] = useState(false);
	const [password, setPassword] = useState('');

	return (
		<div
			class='fixed inset-0 z-50 overflow-y-auto'
			aria-labelledby='modal-title'
			role='dialog'
			aria-modal='true'
		>
			<div class='flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
				<div
					class='fixed inset-0 bg-gray-400 transition-opacity'
					aria-hidden='true'
				></div>
				<span class='hidden sm:inline-block sm:h-screen' aria-hidden='true'>
					&#8203;
				</span>
				<div class='absolute top-36 inline-block transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all sm:my-8 sm:w-80 sm:max-w-lg sm:align-middle lg:static lg:top-0	'>
					<div class='bg-white p-4 sm:p-6 sm:pb-8'>
						<div class='sm:flex sm:items-start'>
							<div className='flex w-80 flex-col justify-between sm:w-80'>
								<TextTitleSub
									title={mainTitle}
									textColor='text-green-50'
									design='self-center mb-4'
								/>
								<div className='flex items-start'>
									<div className='border-azul-50 mb-4 mr-4 flex w-full rounded border px-4'>
										<input
											className='B1Text focus:border-azul-50 w-full border  border-transparent bg-white pl-1 active:bg-white'
											onChange={(e) => setPassword(e.target.value)}
											type={seePassword ? 'text' : 'password'}
										/>
									</div>
									<ButtonWithIcon
										icon={<img src={Eye} alt='see password' />}
										showBoolean={seePassword}
										setShowBoolean={setSeePassword}
									/>
								</div>

								<div className='flex justify-center'>
									<ButtonFab
										action={() => navigate('/portafolios/')}
										colour='white'
										label='Cerrar'
										design='mx-4'
									/>
									<ButtonFab
										action={() => checkPassword(portfolio, password, setAccess)}
										label='Continuar'
										design='mx-4'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

PasswordModal.propTypes = {
	/** main modal's title, gives context what you've done*/
	mainTitle: PropTypes.string,
};

PasswordModal.defaultProps = {
	/** main modal's title, gives context what you've done*/
	mainTitle: 'Contraseña ',
};

export default PasswordModal;
