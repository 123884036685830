// 3rd Party
import PropTypes from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';

// UI
import DownloadableExpertSlideItemTitle from '../../../../molecules/Portfolio/ExpertSlideItems/DownloadableExpertSlideItems/DownloadableExpertSlideItemTitle';
import DownloadableExpertSlideItemText from '../../../../molecules/Portfolio/ExpertSlideItems/DownloadableExpertSlideItems/DownloadableExpertSlideItemText';
import DownloadableExpertSlideItemImage from '../../../../molecules/Portfolio/ExpertSlideItems/DownloadableExpertSlideItems/DownloadableExpertSlideItemImage';

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		justifyContent: 'start',
		flexDirection: 'column',
	},
	title: {
		textAlign: 'center',
		textTransform: 'uppercase',
		marginBottom: '14px',
		fontSize: '20px',
	},
	mainTitle: {
		fontSize: '24px',
	},
	subtitle: {
		textAlign: 'center',
	},
	imageWrapper: {
		marginTop: '10px',
		height: '50%',
	},
	image: {
		objectFit: 'contain',
		height: '60%',
		width: '60%',
		marginHorizontal: 'auto',
		marginTop: '10px',
	},
});

export default function DownloadableExpertSlideCover({
	slideItems,
	isMainCover,
}) {
	const titleElements = slideItems?.find(
		(item) => item.type === 'title'
	)?.slideItemElements;

	const textContent = slideItems
		?.find((item) => item.type === 'text')
		?.slideItemElements?.find((element) => element.type === 'text');

	const imageURL = slideItems
		?.find((item) => item.type === 'image')
		?.slideItemElements?.find((element) => element.type === 'image_url');

	return (
		<View style={styles.container}>
			<DownloadableExpertSlideItemTitle
				titleElements={titleElements}
				stylesTitle={[styles.title, isMainCover && styles.mainTitle]}
				stylesSubtitle={styles.subtitle}
			/>
			<DownloadableExpertSlideItemText text={textContent?.content} />
			<DownloadableExpertSlideItemImage
				stylesImage={styles.image}
				imageURL={imageURL?.content}
			/>
		</View>
	);
}

DownloadableExpertSlideCover.propTypes = {
	// contains the data to show
	slideItems: PropTypes.array,
	// specify if is main slide cover for change title styles
	isMainCover: PropTypes.bool,
};
