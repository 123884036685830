import {
	deleteValueFromArrayVariables,
	deleteValueFromFilterArrayVariables,
	deleteValueFromMainArray,
	setFilterArray,
	setNewVariableToArray,
	setNewVariableToFilterArray,
} from '../../../../../BusinessLogic/redux/reducers/filter';

//functions
function multiPropsFilters(array, filters) {
	// filters -> filterArray
	// array -> data
	const filterKeys = Object.keys(filters);

	if (array?.code === 0 || filterKeys.length === 0) {
		return array;
	} else {
		let firstElements = array?.filter((item) => {
			//validates all filter criteria
			return filterKeys.every((key) => {
				return item[key] !== '' && filters[key].includes(item[key]);
			});
		});

		return firstElements;
	}
}

function filterArrayVariables(
	arrayVariables,
	filterArray,
	dispatch,
	valueToFilter
) {
	let auxArray = arrayVariables;
	let auxMainArray = filterArray;
	let howManyValuesAreThere = auxMainArray[valueToFilter].length;
	if (howManyValuesAreThere === 1) {
		auxArray = auxArray.filter((value) => !value);
		dispatch(deleteValueFromFilterArrayVariables(auxArray));
	}
}

function filterMainArray(value, valueToFilter, filterArray, dispatch) {
	let auxArray = filterArray;
	let howManyValuesAreThere2 = auxArray[valueToFilter].length;
	if (howManyValuesAreThere2 === 1) {
		const { [valueToFilter]: removedProperty, ...newArray } = auxArray;
		dispatch(deleteValueFromMainArray(newArray));
	} else {
		auxArray = {
			...auxArray,
			[valueToFilter]: auxArray[valueToFilter].filter(
				(element) => element !== value
			),
		};
		dispatch(deleteValueFromMainArray(auxArray));
	}
}

function clickOnChip(
	value,
	dispatch,
	filterArray,
	arrayVariables,
	valueToFilter,
	click
) {
	switch (click) {
		case true:
			let payload = {
				value: value,
				valueToFilter: valueToFilter,
			};
			filterArrayVariables(
				arrayVariables,
				filterArray,
				dispatch,
				valueToFilter
			);
			filterMainArray(value, valueToFilter, filterArray, dispatch);
			dispatch(deleteValueFromArrayVariables(payload));

			break;
		case false:
			dispatch(setNewVariableToFilterArray(valueToFilter));
			dispatch(setNewVariableToArray(value));

			let keysMainArrayFilter = Object.keys(filterArray);
			let isThisTheFirstValue = keysMainArrayFilter.indexOf(valueToFilter);

			let auxFilterArray = {
				...filterArray,
				[valueToFilter]:
					isThisTheFirstValue === -1
						? [value]
						: [...filterArray[valueToFilter], value],
			};
			dispatch(setFilterArray(auxFilterArray));
			break;

		default:
			break;
	}
}

//functions
function multiPropsFiltersSorted(
	array,
	filters,
	filters2,
	applyFilterByClick = false,
	minimum = '',
	maximum = '',
	approved
) {
	// filters -> filterArray
	// array -> data

	let keysFilters2 = Object.keys(filters2);
	if (applyFilterByClick || keysFilters2.length !== 0) {
		let data = returnData(array, filters['sortedFilter']);
		if (minimum !== '' && maximum !== '') {
			let dataFilteredByPrice = data.filter(
				(element) =>
					Number(element.price) >= Number(minimum) &&
					Number(element.price) <= Number(maximum)
			);
			let dataFilteredByFilters2 = multiPropsFilters(
				dataFilteredByPrice,
				filters2
			);
			let dataByApproved = FilterByApproved(dataFilteredByFilters2, approved);
			return dataByApproved;
		}
		let dataByApproved = FilterByApproved(data, approved);
		return multiPropsFilters(dataByApproved, filters2);
	} else {
		if (minimum !== '' && maximum !== '') {
			let dataFilteredByPrice = array.filter(
				(element) =>
					Number(element.price) >= Number(minimum) &&
					Number(element.price) <= Number(maximum)
			);
			let dataFilteredByFilters2 = multiPropsFilters(
				dataFilteredByPrice,
				filters2
			);
			let dataByApproved = FilterByApproved(dataFilteredByFilters2, approved);
			return dataByApproved;
		}
		let dataByApproved = FilterByApproved(array, approved);
		return multiPropsFilters(dataByApproved, filters2);
	}
}

const returnData = (array, option) => {
	switch (option) {
		case 'Obras aprobadas':
			let variableToFilter = { selected: [true] };
			return FilterBySelectedOption(array, variableToFilter);
		case 'Más nuevo a antiguo':
			return FilterByDateNewer(array);
		case 'Más antiguo a nuevo':
			return FilterByDateOlder(array);
		case 'Precio de menor a mayor':
			return FilterByLowerPrice(array);
		case 'Precio de mayor a menor':
			return FilterByHigherPrice(array);
		default:
			return array;
	}
};

function FilterBySelectedOption(array, filters) {
	const filterKeys = Object.keys(filters);

	if (array.code === 0 || filterKeys.length === 0) {
		return array;
	} else {
		let firstElements = array.filter((item) => {
			//validates all filter criteria
			return filterKeys.every((key) => {
				return filters[key].includes(item[key]);
			});
		});

		let secondElements = array.filter((item) => {
			//validates all filter criteria
			return filterKeys.every((key) => {
				return !filters[key].includes(item[key]);
			});
		});

		return [...firstElements, ...secondElements];
	}
}

function FilterByDateOlder(array) {
	let newArray = array.map((element) => {
		let elementCopy = JSON.parse(JSON.stringify(element));
		elementCopy.fecha = new Date(element.created_at);
		return elementCopy;
	});

	let dateSorted = newArray.sort(function (a, b) {
		if (a.fecha > b.fecha) return 1;
		if (a.fecha < b.fecha) return -1;
		return 0;
	});

	let dateSortedDeleteFechas = dateSorted.map((element) => {
		let elementCopy = JSON.parse(JSON.stringify(element));
		delete elementCopy['fecha'];
		return elementCopy;
	});

	return dateSortedDeleteFechas;
}

function FilterByDateNewer(array) {
	let newArray = array.map((element) => {
		let elementCopy = JSON.parse(JSON.stringify(element));
		elementCopy.fecha = new Date(element.created_at);
		return elementCopy;
	});

	let dateSorted = newArray.sort(function (a, b) {
		if (a.fecha < b.fecha) return 1;
		if (a.fecha > b.fecha) return -1;
		return 0;
	});

	let dateSortedDeleteFechas = dateSorted.map((element) => {
		let elementCopy = JSON.parse(JSON.stringify(element));
		delete elementCopy['fecha'];
		return elementCopy;
	});

	return dateSortedDeleteFechas;
}

function FilterByHigherPrice(array) {
	let copyArray = [...array];
	copyArray.sort((a, b) => b.price - a.price);
	return copyArray;
}

function FilterByLowerPrice(array) {
	let copyArray = [...array];
	copyArray.sort((a, b) => a.price - b.price);
	return copyArray;
}

function FilterByApproved(dataFilteredByFilters2, approved) {
	if (approved) {
		return dataFilteredByFilters2.filter(
			(element) => element.selected === true
		);
	}
	return dataFilteredByFilters2;
}

export {
	multiPropsFilters,
	filterArrayVariables,
	filterMainArray,
	clickOnChip,
	multiPropsFiltersSorted,
};
