import { AnimatePresence } from 'framer-motion';
//UI
import ArtworkSelectionView from '../LayoutSpecificViews/ArtworkSelectionView';
import ArtworksSectionView from '../LayoutSpecificViews/ArtworksSectionView';
import EditionView from '../LayoutSpecificViews/EditionView';
import ImageSelectionView from '../LayoutSpecificViews/ImageSelectionView';
import SectionsView from '../LayoutSpecificViews/SectionsView';
import SideModalExpertPortfolio from '../LayoutSpecificViews/SideModalExpertPortfolio';

const EditablePortfolioBottomBarMobile = ({
	showReorderView,
	setShowReorderView,
	duplicateSlideAction,
	deleteSlideAction,
	currentView,
	showSlideModalExpertPortfolio,
}) => {
	return (
		<AnimatePresence>
			{showSlideModalExpertPortfolio ? (
				<div className='fixed top-[52px] z-40'>
					<SideModalExpertPortfolio key='sideModal' />
				</div>
			) : (
				<div
					className={`fixed bottom-0 z-40 w-full shadow md:hidden
			${currentView !== 'editionView' && 'bg-middle_grey-50'}
			`}
				>
					{currentView === 'editionView' && <EditionView />}
					{currentView === 'imageSelectionView' && <ImageSelectionView />}
					{currentView === 'artworksSectionView' && <ArtworksSectionView />}
					{currentView === 'artworkSelectionView' && <ArtworkSelectionView />}
					{currentView === 'sectionsView' && (
						<SectionsView
							showReorderView={showReorderView}
							setShowReorderView={setShowReorderView}
							duplicateSlideAction={duplicateSlideAction}
							deleteSlideAction={deleteSlideAction}
						/>
					)}
				</div>
			)}
		</AnimatePresence>
	);
};

export default EditablePortfolioBottomBarMobile;
