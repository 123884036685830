//3rd
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

//UI
import { ReactComponent as ClosedCrossIcon } from '../../../UI/assets/icons/CrossGrey.svg';
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import TextContentPrincipal from '../../../UI/atoms/Text/Content/TextContentPrincipal';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';

//BL
import { getArtists } from '../../../BusinessLogic/redux/reducers/artist';
import { getArtworks } from '../../../BusinessLogic/redux/reducers/artwork';
import {
	useGetPostulationByArtistAndCall,
	usePostCreatePostulation,
} from '../../../BusinessLogic/hooks/query/useQueryPostulation';

const ModalCallGalleryPostulation = ({ hideModal, callToDisplay }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { artists } = useSelector((store) => store.artists);
	const { artworks } = useSelector((store) => store.artworks);
	const { appUser } = useSelector((state) => state.appUser);
	const { organization_user_id } = appUser;

	const isGalleryWithArtists = Array.isArray(artists) && artists.length > 0;
	const [selectedArtistId, setSelectedArtistId] = useState(
		isGalleryWithArtists && artists[0]?.id
	);

	const postCreatePostulationMutation = usePostCreatePostulation();

	const { postulationSingle, isLoading } = useGetPostulationByArtistAndCall({
		call_id: callToDisplay.id,
		organization_user_id,
		artist_id: selectedArtistId && Number(selectedArtistId),
	});

	useEffect(() => {
		if (!artists) {
			dispatch(getArtists());
		}
	}, [dispatch, artists]);

	useEffect(() => {
		if (!artworks) {
			dispatch(getArtworks());
		}
	}, [dispatch, artworks]);

	useEffect(()=>{
		if(!selectedArtistId && isGalleryWithArtists){
			setSelectedArtistId(artists[0]?.id);
		}
	}, [selectedArtistId, isGalleryWithArtists, artists]);

	useEffect(() => {
		document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	}, []);

	const artistHasAllRequirements = () => {
		const callHasPortfolio = callToDisplay.has_portfolio;
		const artistArtworks = artworks?.filter(
			(artwork) => String(artwork.artist_id) === String(selectedArtistId)
		);
		return callHasPortfolio ? artistArtworks?.length > 0 : true;
	};

	const submitButton = (formProps) => {
		if (isLoading) return <></>;
		if (postulationSingle?.id) {
			return (
				<ButtonFab
					label={
						postulationSingle?.status === 'DRAFT'
							? 'Continuar postulación'
							: 'Ver postulación'
					}
					action={() => navigate(`/postulaciones/${postulationSingle.id}`)}
				/>
			);
		}
		return (
			<ButtonFab label={t('Aceptar')} action={() => formProps.submitForm()} />
		);
	};

	return (
		<div className='fixed inset-0 z-50 flex items-center justify-center bg-black-50/50'>
			<div className='flex w-[80%] flex-col rounded-lg border-0 bg-white p-4 shadow-lg md:w-1/2 lg:w-1/3'>
				<div className='flex flex-row items-center justify-end'>
					<span onClick={hideModal} className='cursor-pointer'>
						<ClosedCrossIcon />
					</span>
				</div>
				<div className='mb-3 mt-1 gap-4 md:px-7'>
					<TextTitleSub
						title={t('SeleccioneElArtista')}
						design={'w-full text-center text-green-50'}
					/>
					<TextContentPrincipal
						text={
							isGalleryWithArtists
								? t('SeleccioneElArtistaAPostular')
								: t('ParaAplicarALaConvocatoriaDebeCrearArtista')
						}
						design={'w-full text-center my-2'}
					/>
					<Formik
						initialValues={{ artist_id: selectedArtistId }}
						validationSchema={Yup.object({
							artist_id: Yup.string().not(['0'], t('SeleccioneElArtista')),
						})}
						onSubmit={async (values) => {
							let res = await postCreatePostulationMutation.mutateAsync({
								call_id: callToDisplay.id,
								organization_user_id,
								...values,
							});
							if (res?.id) {
								navigate(`/postulaciones/${res.id}`);
							}
						}}
					>
						{(formProps) => (
							<Form>
								{isGalleryWithArtists && (
									<>
										<Field
											name={'artist_id'}
											type='text'
											as='select'
											className='h-8 w-full rounded border-none bg-light_grey-75 px-1'
											onChange={({ target }) => {
												formProps.setFieldValue('artist_id', target.value);
												setSelectedArtistId(target.value);
											}}
										>
											{artists?.length > 0 &&
												artists?.map((artist) => (
													<option key={artist.id} value={artist.id}>
														{artist.name}
													</option>
												))}
										</Field>
										{formProps.errors.artist_id && (
											<TextContentPrincipal
												text={formProps.errors.artist_id}
												design={'text-sm text-red-500'}
											/>
										)}
										{!artistHasAllRequirements() && (
											<TextContentPrincipal
												text={t('DebesCrearObrasOrganización')}
												design={'w-full text-center my-2'}
											/>
										)}
									</>
								)}
								<div className='my-4 flex w-full flex-row items-center justify-center gap-4 md:gap-6'>
									<ButtonFab
										label={'Cancelar'}
										colour={'white'}
										action={hideModal}
									/>
									{isGalleryWithArtists ? (
										<>
											{formProps.values.artist_id &&
											!artistHasAllRequirements() ? (
												<ButtonFab
													label={t('CrearObras')}
													action={() => navigate('/obras/new')}
												/>
											) : (
												submitButton(formProps)
											)}
										</>
									) : (
										<ButtonFab
											label={t('CrearArtista')}
											action={() => navigate('/artistas/crear')}
										/>
									)}
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default ModalCallGalleryPostulation;
