//3rd
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//UI
import TextTitlePrincipal from '../../atoms/Text/Titles/TextTitlePrincipal';
import HomeGalleryCover from '../../assets/images/HomeGalleryCover.jpg';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import CallsAndPostulationsPreview from '../Call/CallsAndPostulationsPreview';

//BL
import { homepageOrganizationsItemsContent } from '../../../BusinessLogic/data/HomepageData';

/**
 * @description manages the homepage elements for organizations.
 * @returns
 */
const SectionOrganizationHome = ({ calls }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const showCallsAndPostulationsPreview = calls?.length > 0;

	return (
		<div>
			<div className='flex flex-col-reverse pb-16 pt-12 md:flex-row'>
				<div className='flex w-full flex-col gap-6 md:w-1/2 '>
					<TextTitlePrincipal
						title={t('GestionaTusConvocatoriasEnUnSoloLugar')}
						design='text-center md:text-left'
						textColor='text-purple-50'
					/>
					<ul className='list-disc pl-5 font-extralight'>
						{homepageOrganizationsItemsContent.map((item, index) => (
							<li key={index}>{t(item)}</li>
						))}
					</ul>
					<div className='flex justify-center'>
						<ButtonFab
							label={t('CrearConvocatoria')}
							size={'large'}
							action={() => navigate('/convocatorias')}
						/>
					</div>
				</div>
				<div className='w-full md:w-1/2'>
					<LazyLoadImage
						src={HomeGalleryCover}
						className='w-full'
						effect='blur'
					/>
				</div>
			</div>
			{showCallsAndPostulationsPreview && (
				<CallsAndPostulationsPreview calls={calls} />
			)}
		</div>
	);
};

SectionOrganizationHome.propTypes = {
	//Array with the calls data
	calls: PropTypes.array,
};

export default SectionOrganizationHome;
