/* --------------------------PAGE 1------------------------------- */
/* header text 1*/
const headerTitle = 'Ana Maria Velasco';
const headerText =
	'Ana María Velasco creates maps that outline her journey through nature and her experiences with the spiritual world. Like an explorer, she gathers information through observation and experience then shares evidence from her encounters.';

/* main text content 1*/
const titleOne = 'When I am there you are here';
const lasierra =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1548';
const picDescriptionOne = [
	'When I am there you are here 2020',
	'70” inches x 50” inches (104 x 177 cm)',
	'Acrylic on canvas',
];

const ContentTextOne =
	'Born in Colombia, Velasco often approaches the country, its biodiversity, its politics, and its people as a foundation for her visual vocabulary. Her curiosity extends into metaphysical investigations of interconnectedness. Her beautifully detailed and brightly colored paintings stem from her expeditions into the seen and unseen world.';
const mariaPic =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user6/1320';

/* --------------------------PAGE 2------------------------------- */

/* header text 2 */
const outer =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1550';
const headerTitleTwo = 'Outer';
const headerTextTwo =
	"Velasco, continues her exploration of La Sierra Nevada de Santa Marta as an ongoing subject and a metaphor. She has spent the last twelve years walking through this mountain range, establishing an intimate relationship with this site and discovering new aspects of humanity's dependence on nature after each expedition.";

/* main text content 2*/
const titleTwo = 'The world has a heart';
const sierranevada =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1551';
const picDescriptionTwo = [
	' The world has a heart 2020',
	'64” x 38” inches (96 x 162 cm)',
	'Acrylic on canvas',
];

const ContentTextTwo =
	'La Sierra Nevada de Santa Marta is the largest coastal mountain range in the world. With peaks of 18,700 ft above sea level it has been identified as the most irreplaceable site for biodiversity conservation in the world given its very high endemism and the amount of threatened species that live there.';

const santaMone =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1552';
const santaMtwo =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1553';

const sierraheart =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1554';
const picDescriptionThree = [
	'Pink Sierra 2019',
	'70” x 42” inches (104 x 177 cm)',
	'Gouache and watercolor on paper',
];

const ContentTextThree =
	'The native people from La Sierra, refer to this area as “The heart of the world.” They believe that this mountain is a microcosm of the planet, a reflection of what happens in the world at large.';

const ladyandkid =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1555';
const ContentTextFour =
	'Over the years walking through this site, Velasco has built relationships and engaged with flora and fauna through rituals. Through this process she has learned to appreciate these elements as perceived by those who care for their habitats.';

const portraitsierra =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1577';
const picDescriptionFour = [
	'The water under the blue sky 2019',
	'10” x 7” iches',
	'Gouache and watercolor on paper',
];

/* -------------------------PAGE 3-------------------------------- */

/* header text 3 */
const headerTitleThird = 'Inner';
const inner =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1556';

/* main text content 3*/
const ContentTextFive =
	'Velasco was guided in preparing offerings to the spirits and land. Called “pagamentos,” the specific assemblages of goods were meant to create unity between different realms. Her paintings continue honoring nature and extending gratitude.';
const videopagamento =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/anamaria_videos/Video+1+-+AMV.mp4';

const landscapes =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1557';
const ContentTextSix =
	'Through her pilgrimages within these natural surroundings, she creates impressions of sacred moments. The colors, the contrasts between light and dark, and through imagined perspectives, her paintings convey her deep connection to the natural world.';

const ImageMakingWaterFromCrystals =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1558';
const Titlep3c1 = 'Making water from crystals';
const picDescription5 = [
	'Making water from crystals 2017',
	'(from the series Pagamentos)',
	'10” x 7” inches (18 x 26 cm)',
	'Gouache and watercolor on paper',
];

const ImageMyBloodIsGold =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1560';
const Titlep3c2 = 'My blood is gold';
const picDescription6 = [
	'My blood is gold 2019',
	'(from the series Pagamentos)',
	'10” x 7” inches (18 x 26 cm)',
	'Gouache and watercolor on paper',
];

const ImageTheHopOfWhatWas =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1559';
const Titlep3c3 = 'The hope of what was';
const picDescription7 = [
	'The hope of what was 2016',
	'(from the series Pagamentos)',
	'10” x 7” inches (18 x 26 cm)',
	'Gouache and watercolor on paper',
];

const ContentText7 =
	'Not concerned with scientific accuracy, Velasco paints from memory. She offers her internalized vision of these sites in these intimate paintings.';

const ImageKnittingRocks =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1561';
const Titlep3c4 = 'Knitting rocks';
const picDescription8 = [
	'Knitting rocks 2016',
	'(from the series Pagamentos)',
	'10” x 7” inches (18 x 26 cm)',
	'Gouache and watercolor on paper',
];

const ImageTheWatersThoughts =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1562';
const Titlep3c5 = 'The water’s thoughts';
const picDescription9 = [
	'The water’s thoughts 2019',
	'(from the series Pagamentos)',
	'10” x 7” inches (18 x 26 cm)',
	'Gouache and watercolor on paper',
];

/* -------------------------PAGE 4-------------------------------- */
const ImageP4n1 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1563';
const TitleP4n1 = 'Secret';
const ContentP4n1 =
	'As an explorer, Velasco paints records of her lived experiences and spiritual encounters. Her artistic practice and life journey align as she consistently celebrates the unity of the natural and metaphysical.';

const VideoP4n2 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/anamaria_videos/Interdependencia+Video+2021+Final_2.mp4';

const TitleP4n3 = 'La Sierra';
const ImageP4n3 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1564';
const DecriptionP4n3 = [
	'La Sierra 2017',
	'11’ x 6’ feet (330 x 180 cm)',
	'Acrylic on canvas',
];

const ContentP4n4 =
	'In the painting, La Sierra Nevada de Santa Marta 2017, characters personifying different roles and situations appear on the borderlines of territorial spaces. With rivers that carve the landscape, snow peaks and waterfalls, the coca leaf is the protagonist.';
const ImageP4n4 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1565';
const DecriptionP4n4 = ['La Sierra 2017. Close up'];

const ContentP4n5 =
	'The coca plant, which appears repeatedly in her paintings,carries significance as sacred and medicinal for Indigenous people, also is a basis for cocaine that has fueled humanitarian crises.';
const ImageP4n5 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1441';
const DecriptionP4n5 = ['La Sierra 2017. Close up'];

const VideoP4n6 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/anamaria_videos/VIDEO-2021-09-07-22-10-29.mp4';
const DecriptionP4n6 = 'Video';

const ContentP4n7 =
	'In Velasco’s paintings opposing elements come together in one place ー this land, where armed soldiers are as present as football stars, where Indigenous, Buddhist, and Catholic beliefs intermingle, and where jaguars, roses, and plantains are all deeply symbolic.';

const TitleP4n8 = 'Aluna';
const ImageP4n8 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1569';
const DecriptionP4n8 = [
	'Aluna 2016',
	'7’ x 5’ feet (213 cm x 152cm)',
	'Acrylic on canvas',
];

const ContentP4n8b =
	"In Velasco's paintings the lives of humans are entwined with nature, expressing her perspective that all things are interconnected and inseparable.";

const ContentP4n9 =
	'Mother earth or Aluna is believed to be the force behind all nature, a living being. She represents cosmic consciousness that is the source of all life and intelligence and the mind inside nature too.';
const ImageP4n9 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1570';
const DecriptionP4n9 = ['Aluna 2016. Close up'];

const ImageP4n10 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1571';
const DecriptionP4n10 = ['Aluna 2016. Close up'];

const ImageP4n11 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1572';
const DecriptionP4n11 = ['Aluna 2016. Close up'];

const ImageP4n12 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1573';
const DecriptionP4n12 = ['Aluna 2016. Close up'];

const ImageP4n13 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1574';
const DecriptionP4n13 = ['Aluna 2016. Close up'];

const ContentP4n14 =
	'In this series she combines vibrant colors, meticulous details and various textures to create luscious, verdant landscapes. These idyllic landscapes provide insight intothe country’s diverse identities.';

const VideoP4n15 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/anamaria_videos/VIDEO-2021-09-07-22-12-44.mp4';
const DecriptionP4n15 = 'Video';

const TitleP4n16 = 'She was everything that was coming';
const ImageP4n16 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1575';
const DecriptionP4n16 = [
	'She was everything that was coming 2019',
	'11’ feet x 6’feet (330 x 180 cm)',
	'Acrylic on canvas',
];

const ContentP4n17 =
	'While Velasco focuses on sociopolitical themes, personal memories and dreams are also evident throughout her work. Ultimately, her paintings ask us to question the inherent imbalance between conflict and co-existence.';

const ImageP4n18 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1578';
const DecriptionP4n18 = ['She was everything that was coming 2019. Close up'];

const ImageP4n19 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1580';
const DecriptionP4n19 = ['She was everything that was coming 2019. Close up'];

const ImageP4n20 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1581';
const DecriptionP4n20 = ['She was everything that was coming 2019. Close up'];

const ImageP4n21 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1579';
const DecriptionP4n21 = ['She was everything that was coming 2019. Close up'];

const ContentP4n22 =
	"In 'She was everything that was coming 2019', Velasco depicts an array of endemic plants and animals within a sweeping view of La Sierra Nevada. The non-human elements outsize the sports cars and popular culture figures which suggest the importance of prioritizing nature as industrialization encroaches.";

const VideoP4n23 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/anamaria_videos/VIDEO-2021-09-07-22-01-52.mp4';
const DecriptionP4n23 = 'Video';

const ContentP4n24 =
	'Her art features species that are facing extinction. During expeditions into remote bio-diverse areas, Velasco learned about the wonders around her. Her devotion to ecology is conveyed in paintings of these creatures presented as holy icons imbued with spiritual blessings.';

const ImageP4n25 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1582';
const DecriptionP4n25 = ['She was everything that was coming 2019. Close up'];

const ImageP4n26 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1583';
const DecriptionP4n26 = ['She was everything that was coming 2019. Close up'];

const ImageP4n27 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1584';
const DecriptionP4n27 = [
	'Jaguar 2018',
	'12” x 12” inches',
	'Acrylic and plaster on canvas',
];

const ImageP4n28 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1585';
const DecriptionP4n28 = [
	'Starry Night Harlequin Toad 2020',
	'8” x 8” inches',
	'Acrylic and plaster on canvas',
];

const ContentP4n29 =
	'These large-scale works are a plea for conservation. Their prominence in the compositions commands respect for the subjects which face the perilous impacts of climate change and deforestation.';

const ImageP4n30 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1586';
const DecriptionP4n30 = [
	'The starry night harlequin toad 2021',
	'5’ feet x 5’ feet',
	'Acrylic and plaster on canvas',
];

/* -------------------------PAGE 5-------------------------------- */
const ImageP5n1 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user13/1663';
const TitleP5n1 = 'Naturaleza Muerta';
const ContentP5n1 =
	'Moved by social protests, economic strife, and the Covid-19 global pandemic, she began her current series of still-life paintings. Recalling the transformative ceremonies in her life, she makes art as an offering to encourage empathy, love, and healing.';

const VideoP5n2 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/anamaria_videos/Interdependencia+Video+2021+Final_3.mp4';

const ImageP5n3 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1590';
const DescriptionP5n3 = [
	'Orchid and Fruit 2021',
	'9 x 7 inches (22 x 17 cm)',
	'Gouache and watercolor on paper.',
];

const ImageP5n4 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1592';
const DescriptionP5n4 = [
	'Quetzal, Vegetables and Fruit 2021',
	'9 x 7 inches (22 x 17 cm)',
	'Gouache and watercolor on paper.',
];

const ImageP5n5 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1591';
const DescriptionP5n5 = [
	'Quetzal, Vegetables and Fruit 2021',
	'9 x 7 inches (22 x 17 cm)',
	'Gouache and watercolor on paper.',
];

const ContentP5n6 = [
	'The cobalt-blue backgrounds of these still-lifes resonate incontrast to the arrangements of multi-colored tropical fruits and flowers from Velasco’s childhood in Nicaragua and Colombia. These works serve as prayers that carry sociopolitical, environmental, and spiritual significance.',
];
/* -------------------------PAGE 6-------------------------------- */
const ImageP6n1 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1593';
const TitleP6n1 = 'Early works';
const ContentP6n1 =
	'Velasco’s earlier paintings are diaristic and universal. With diverse and contradictory narratives that explore how to transform a soccer game into a river of roses, alchemize a mountain top into a war zone, or unify a dance party with a funeral gathering.';

const TitleP6n2 = 'Luna Llena';
const ImageP6n2 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1595';
const DescriptionP6n2 = [
	'Luna Llena 2000',
	'6’ x 6’ feet (300 x 150 cm)',
	'Acrylic and oil on canvas.',
];

const VideoP6n3 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/anamaria_videos/VIDEO-2021-07-24-23-56-57.mp4';

const ImageP6n4 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1596';
const DescriptionP6n4 = ['Luna llena 2000. Close up'];
const ImageP6n5 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1597';
const DescriptionP6n5 = ['Luna llena 2000. Close up'];
const ContentP6n6 =
	'One element flows into another, leaving behind a trace of dreamlike memories. ';

const ImageP6n7 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1598';
const DescriptionP6n7 = ['Luna llena 2000. Close up'];

const ImageP6n8 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1599';
const DescriptionP6n8 = ['Luna llena 2000. Close up'];

const ContentP6n9 =
	'The layered scenes often include battlefields and sports arenas where the turmoil associated with these sites meets a celebratory calm. These juxtapositions offer insight into her personal and political ethos.';

const TitleP6n10 = 'Luna Rosa';
const ImageP6n10 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1600';
const DescriptionP6n10 = [
	'Luna Rosa, 2001',
	'11” x 6” feet (300 x 150 cm)',
	'Acrylic and oil on canvas',
];

const ContentP6n11 =
	'She is interested in the possibility of revealing a glimpse into another realm, leading the viewers through imaginary tales that invite them to weave their own story.';

const ImageP6n12 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1601';
const DescriptionP6n12 = ['Luna rosa 2001. Close up'];

const ImageP6n13 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1602';
const DescriptionP6n13 = ['Luna rosa 2001. Close up'];

const ImageP6n14 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1603';
const DescriptionP6n14 = ['Luna rosa 2001. Close up'];

const ImageP6n15 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1604';
const DescriptionP6n15 = ['Luna rosa 2001. Close up'];

const VideoP5n16 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/anamaria_videos/VIDEO-2021-09-07-22-13-34.mp4';
const ContentP6n16 = 'Video';

const TitleP6n17 = 'People';

const ImageP6n18 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1619';
const DescriptionP6n18 = [
	'Estervina & Maria Magdelena',
	'10 inches x 7 inches',
	'Graphite and acrylic on paper',
];

const ImageP6n19 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1617';
const DescriptionP6n19 = [
	'Johnny',
	'Graphite on paper',
	'12 inches x 9 inches',
];

const ImageP6n20 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1616';
const DescriptionP6n20 = [
	'Juliana & Chadwick',
	'10 inches x 7 inches',
	'Pencil on paper',
];

const contentP6n21 =
	'Many of the figures that she has woven together through line and ghost like silhouettes are family members and friends. These include black and white portraits that capture her subjects, both as individuals and representatives of the spirit of their age and culture. She creates from her personal encounters with these people, but within the large paintings become anonymous characters of a collective and universal narrative.';
const ImageP6n21 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1618';
const DescriptionP6n21 = [
	'María José',
	'10” x 7” inches',
	'Ink, pencil and acrylic on paper.',
];

/* -------------------------PAGE 7-------------------------------- */
const ImageP7n1 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1605';
const TitleP7n1 = 'Line drawings';

const ImageP7n2 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1609';
const ContentP7n2 =
	'Velasco’s pilgrimages through Colombia inform her worldview of interconnectedness, empathy, and reciprocity. Her interest in international sociopolitical movements stems from her youth spent in the context of narco-terrorism in Colombia and dictatorship and revolution in Nicaragua.';

const ImageP7n3 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1606';
const DescriptionP7n3 = ['2018 - 2021'];

const ImageP7n4 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1607';

const ContentP7n4 =
	'For the last 12 years, she has embedded herself in Indigenous communities from La Sierra Nevada of Santa Marta, Colombia (Kogis, Arhuacos, and Wiwas). These artistic and personal expeditions impact her artmaking practice and life. ';

const ImageP7n5a =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1491';
const ImageP7n5b =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1487';

const ContentP7n5 =
	'She continues doing community work with La Sierra Artist Residency and Caring for Colombia foundations, engaging in ongoing conservation and education efforts through her paintings and social projects.';

const ImageP7n6a =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1608';
const ImageP7n6b =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1620';

const ContentP7n8 =
	'Velasco studied Tibetan Art for 10 years at The New York Tibetan Art Studio in NYC. This rigorous training helped her unify her spiritual practices with her creative process. It also influenced her use of line, composition, and perspective.';

const ImageP7n9 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1610';
const DescriptionP7n9 = [
	'Buddha Offering 2007',
	'63 x 76 cm',
	'Japanese ink and brush on paper.',
];

const ImageP7n10 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1612';
const DescriptionP7n10 = [
	'Buddha Offering 2007',
	'63 x 76 cm',
	'Japanese ink and brush on paper.',
];

const ImageP7n11 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1529';
const DescriptionP7n11 = [
	'Buddha Offering 2007',
	'63 x 76 cm',
	'Japanese ink and brush on paper.',
];

const ContentP7n12 =
	"Velasco also makes environmental-political drawings for one of Colombia’s most important newspapers, 'El Espectador' under the pseudonym Manic Breathing.";

const ImageP7n13 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1613';
const DescriptionP7n13 = [
	'N21. November 21, 2019.',
	'Manic Breathing. El Espectador.',
];

const ImageP7n14 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1614';
const DescriptionP7n14 = [
	'La Alcaldesa. November 1, 2019.',
	'Manic Breathing. El Espectador.',
];

const ContentP7n15 =
	'These are all ways in which she aims to feed and heal this reciprocal relationship and interdependence of nature and all sentient beings.';
const ImageP7n15 =
	'https://art-catalogue.s3.us-east-2.amazonaws.com/app_user13/1615';
const DescriptionP7n15 = [
	'Greta en La Plaza de Bolivar. September 27, 2019.',
	'Manic Breathing. El Espectador.',
];

export const NavigationData = [
	{ label: 'Ana Maria Velasco', url: '#anamaria' },
	{ label: 'Outer', url: '#outer' },
	{ label: 'Inner', url: '#inner' },
	{ label: 'Secret', url: '#secret' },
	{ label: 'Naturaleza Muerta', url: '#Offering' },
	{ label: 'Early works', url: '#EarlyWorks' },
	{ label: 'Line Drawings', url: '#LineDrawings' },
];

export {
	headerTitle,
	headerText,
	titleOne,
	lasierra,
	picDescriptionOne,
	ContentTextOne,
	mariaPic,
	outer,
	headerTitleTwo,
	headerTextTwo,
	titleTwo,
	sierranevada,
	picDescriptionTwo,
	sierraheart,
	ContentTextTwo,
	santaMone,
	santaMtwo,
	picDescriptionThree,
	ContentTextThree,
	ladyandkid,
	ContentTextFour,
	portraitsierra,
	picDescriptionFour,
	headerTitleThird,
	inner,
	ContentTextFive,
	videopagamento,
	landscapes,
	ContentTextSix,
	ImageMakingWaterFromCrystals,
	Titlep3c1,
	Titlep3c2,
	Titlep3c3,
	Titlep3c4,
	Titlep3c5,
	picDescription5,
	ImageMyBloodIsGold,
	picDescription6,
	ImageTheHopOfWhatWas,
	picDescription7,
	ContentText7,
	ImageKnittingRocks,
	picDescription8,
	ImageTheWatersThoughts,
	picDescription9,
	ImageP4n1,
	TitleP4n1,
	ContentP4n1,
	VideoP4n2,
	TitleP4n3,
	ImageP4n3,
	DecriptionP4n3,
	ContentP4n4,
	ImageP4n4,
	DecriptionP4n4,
	ContentP4n5,
	ImageP4n5,
	DecriptionP4n5,
	VideoP4n6,
	DecriptionP4n6,
	ContentP4n7,
	TitleP4n8,
	ImageP4n8,
	DecriptionP4n8,
	ContentP4n8b,
	ContentP4n9,
	ImageP4n9,
	DecriptionP4n9,
	ImageP4n10,
	DecriptionP4n10,
	ImageP4n11,
	DecriptionP4n11,
	ImageP4n12,
	DecriptionP4n12,
	ImageP4n13,
	DecriptionP4n13,
	ContentP4n14,
	VideoP4n15,
	DecriptionP4n15,
	TitleP4n16,
	ImageP4n16,
	DecriptionP4n16,
	ContentP4n17,
	ImageP4n18,
	DecriptionP4n18,
	ImageP4n19,
	DecriptionP4n19,
	ImageP4n20,
	DecriptionP4n20,
	ImageP4n21,
	DecriptionP4n21,
	ContentP4n22,
	VideoP4n23,
	DecriptionP4n23,
	ContentP4n24,
	ImageP4n25,
	DecriptionP4n25,
	ImageP4n26,
	DecriptionP4n26,
	ImageP4n27,
	DecriptionP4n27,
	ImageP4n28,
	DecriptionP4n28,
	ContentP4n29,
	ImageP4n30,
	DecriptionP4n30,
	ImageP5n1,
	TitleP5n1,
	ContentP5n1,
	VideoP5n2,
	ImageP5n3,
	DescriptionP5n3,
	ImageP5n4,
	DescriptionP5n4,
	ImageP5n5,
	DescriptionP5n5,
	ContentP5n6,
	ImageP6n1,
	TitleP6n1,
	ContentP6n1,
	TitleP6n2,
	ImageP6n2,
	DescriptionP6n2,
	VideoP6n3,
	ImageP6n4,
	DescriptionP6n4,
	ImageP6n5,
	DescriptionP6n5,
	ContentP6n6,
	ImageP6n7,
	DescriptionP6n7,
	ImageP6n8,
	DescriptionP6n8,
	ContentP6n9,
	TitleP6n10,
	ImageP6n10,
	DescriptionP6n10,
	ContentP6n11,
	ImageP6n12,
	DescriptionP6n12,
	ImageP6n13,
	DescriptionP6n13,
	ImageP6n14,
	DescriptionP6n14,
	ImageP6n15,
	DescriptionP6n15,
	VideoP5n16,
	ContentP6n16,
	TitleP6n17,
	ImageP6n18,
	DescriptionP6n18,
	ImageP6n19,
	DescriptionP6n19,
	ImageP6n20,
	DescriptionP6n20,
	contentP6n21,
	ImageP6n21,
	DescriptionP6n21,
	ImageP7n1,
	TitleP7n1,
	ImageP7n2,
	ContentP7n2,
	ImageP7n3,
	DescriptionP7n3,
	ImageP7n4,
	ContentP7n4,
	ImageP7n5a,
	ImageP7n5b,
	ContentP7n5,
	ImageP7n6a,
	ImageP7n6b,
	ContentP7n8,
	ImageP7n9,
	DescriptionP7n9,
	ImageP7n10,
	DescriptionP7n10,
	ImageP7n11,
	DescriptionP7n11,
	ContentP7n12,
	ImageP7n13,
	DescriptionP7n13,
	ImageP7n14,
	DescriptionP7n14,
	ContentP7n15,
	ImageP7n15,
	DescriptionP7n15,
};
