/* -- Third Party -- */
import React, { useEffect, useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import { Form, Formik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';

//BL
import { fetchPostDataWithUser } from '../../../BusinessLogic/hooks/fetchPostDataWithUser';
import { fetchGetData } from '../../../BusinessLogic/hooks/fetchGetData';
import {
	ENDPOINTS_APPUSER,
	ENDPOINTS_CITY,
	ENDPOINTS_COUNTRY,
} from '../../../BusinessLogic/helpers/routes';
import { fetchPutData } from '../../../BusinessLogic/hooks/fetchPutData';

//UI
import List from '@material-ui/core/List';
import { ListItem } from '@material-ui/core';

import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import ListItemText from '../../../UI/atoms/Inputs/ListItemText';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import RadioItem from '../../../UI/molecules/userManagement/RadioItem';
import FieldTextFormikManagement from '../../../UI/molecules/Field/FieldTextFormikManagement';
import SectionForm from '../../../UI/organisms/Section/SectionForm';
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import ImageSectionForm from '../../../UI/organisms/Image/ImageSectionForm';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

export default function UserEditForm(props) {
	const { organization_user_id } = localStorage;
	const navigate = useNavigate();
	const [user, setAppUser] = useState({});
	const [countries, setCountries] = useState([{ id: '', name: '' }]);
	const [cities, setCities] = useState([{ id: '', name: '' }]);
	const [countryId, setCountryId] = useState(1);
	const { id } = useParams();

	//Query GET to the API
	useEffect(() => {
		fetchPostDataWithUser(ENDPOINTS_APPUSER.GET_APPUSER + `${id}/`).then(
			(data) => setAppUser(data)
		);
		fetchGetData(ENDPOINTS_COUNTRY.GET_COUNTRIES).then((data) =>
			setCountries(data)
		);
	}, [id]);

	useEffect(() => {
		fetchGetData(ENDPOINTS_CITY.GET_CITIES + `${countryId}/`).then((data) =>
			setCities(data)
		);
	}, [countryId]);

	const handleCountry = (e) => {
		const idCountry = e.target.value;
		setCountryId(idCountry);
	};
	const returnView = () => {
		navigate('/management/configuracion/rolesypermisos');
	};

	return (
		<TemplateWithNavBar>
			<Formik
				enableReinitialize
				initialValues={{
					...user,
					role_id: '2',
					pickedSendEmail: '1',
					organization_user_id: organization_user_id,
				}}
				onSubmit={(values) => {
					fetchPutData(ENDPOINTS_APPUSER.PUT_APPUSER + `${id}/`, values);
					setTimeout(() => {
						returnView();
					}, 2000);
				}}
			>
				{(formProps) => (
					<Form>
						<SecondaryNavbarSpecific
							title='Editar Perfil'
							textColor='text-green-50'
							color='bg-green-50'
							hide='invisible'
						/>
						<div className='mx-auto w-fit-content md:hidden'>
							<TextMainTitle
								title='Editar Perfil'
								textColor='text-green-50'
								color='bg-green-50'
							/>
						</div>
						<div className='mb-20 px-4 md:flex md:flex-row md:justify-around'>
							<ImageSectionForm
								action='edit'
								url={user.main_picture_url}
								alt={user.name}
								formProps={formProps}
							/>
							<div className='mt-8 flex w-full flex-col items-center md:w-96'>
								<SectionForm
									title='Identificación de usuario'
									textColor='text-green-50'
									color='bg-green-50'
								>
									<FieldTextFormikManagement
										label='Nombre'
										dbName='first_name'
										type='Text'
									/>
									<FieldTextFormikManagement
										label='Apellido'
										dbName='last_name'
										type='Text'
									/>
									<FieldTextFormikManagement
										label='Compañia'
										dbName='company'
										type='Text'
									/>
								</SectionForm>
								<SectionForm
									title='Información de la cuenta'
									textColor='text-green-50'
									color='bg-green-50'
								>
									<FieldTextFormikManagement
										label='Contraseña'
										dbName='password'
										type='Text'
									/>
								</SectionForm>

								<SectionForm
									title='Información de usuario'
									textColor='text-green-50'
									color='bg-green-50'
								>
									<FieldTextFormikManagement
										label='Número de teléfono'
										dbName='phone_number'
										type='tel'
									/>
									<FieldTextFormikManagement
										label='Direccion'
										dbName='address'
										type='Text'
									/>

									<ListItem
										style={{
											paddingLeft: '0px',
											paddingRight: '0px',
											justifyContent: 'space-between',
										}}
									>
										<ListItemText
											primary='Rol en la compañia'
											design='body-Text2'
											style={{ fontWeight: 400 }}
										/>
										<Field
											name='role_company'
											type='Text'
											as='select'
											className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-75 md:w-56'
										>
											<option value='CURADOR'>CURADOR</option>
											<option value='MUSEO'>MUSEO</option>
											<option value='GALERIA'>GALERIA</option>
											<option value='ARTISTA'>ARTISTA</option>
										</Field>
									</ListItem>
									<ErrorMessage name='role_company' />

									<ListItem
										style={{
											paddingLeft: '0px',
											paddingRight: '0px',
											justifyContent: 'space-between',
										}}
									>
										<ListItemText
											primary='País'
											design='body-Text2'
											style={{ fontWeight: 400 }}
										/>
										<Field name='country_id' type='Text' as='select'>
											{({ field }) => (
												<select
													{...field}
													onClick={(e) => handleCountry(e)}
													className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-75 md:w-56'
												>
													{countries.map((country) => (
														<option value={country.id} key={country.id}>
															{country.name}
														</option>
													))}
												</select>
											)}
										</Field>
									</ListItem>
									<ErrorMessage name='country_id' />

									<ListItem
										style={{
											paddingLeft: '0px',
											paddingRight: '0px',
											justifyContent: 'space-between',
										}}
									>
										<ListItemText
											primary='Ciudad'
											design='body-Text2'
											style={{ fontWeight: 400 }}
										/>
										<Field
											name='city_id'
											type='Text'
											as='select'
											className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-75 md:w-56'
										>
											{cities.map((city) => (
												<option key={city.id} value={city.id}>
													{city.name}
												</option>
											))}
										</Field>
									</ListItem>
									<ErrorMessage name='city_id' />
								</SectionForm>
								<List>
									<SectionForm
										title='Role(s)*'
										textColor='text-green-50'
										color='bg-green-75'
										desgin='my-4'
									>
										<TextContentSecond
											design='text-grey-50 pb-4'
											text='Selecciona uno o más roles para las personas que estás invitando. Para cambiar roles, ve a administrar roles y permisos.'
										/>
										<div
											className='px-1'
											role='group'
											aria-labelledby='my-radio-group'
										>
											<RadioItem
												design='body-Text2 px-5 text-grey-50'
												type='radio'
												name='role_id'
												value='2'
												label='Administrador (Co-propietario)'
												paragraph='Tiene acceso completo para administrar, editar y publicar obras de arte y más.'
											/>
											<RadioItem
												design='body-Text2 px-5 text-grey-50'
												type='radio'
												name='role_id'
												value='3'
												label='Editor'
												paragraph='Puede administrar completamente la información de la aplicación, pero no otras áreas de su cuenta.'
											/>
											<RadioItem
												design='body-Text2 px-5 text-grey-50'
												type='radio'
												name='role_id'
												value='4'
												label='Lector'
												paragraph='Puede crear o responder mensajes u ofertas sin editar nada en su cuenta.'
											/>
											<RadioItem
												design='body-Text2 px-5 text-grey-50'
												type='radio'
												name='role_id'
												value='5'
												label='Usuario temporal'
												paragraph='Asigna un usuario temporal y una contraseña para mostrar completamente la información de la aplicación sin editar nada en su cuenta.'
											/>
										</div>
										<ErrorMessage name='role_id' />

										<div
											className='pb-4'
											role='group'
											aria-labelledby='my-radio-group'
										>
											<RadioItem
												design='body-Text2'
												type='radio'
												name='pickedSendEmail'
												value='1'
												paragraph='Enviar a este contacto una invitación por email para unirse a mi cuenta.'
											/>
										</div>
									</SectionForm>
								</List>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</TemplateWithNavBar>
	);
}
