// 3rd Party
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

// BL
import {
	PrivateRoute,
	PublicAccessRoute,
} from '../../Pages/authentication/components/LoginUtils';

// UI
import AllCollections from '../../Pages/sections/collection/AllCollections';
import CollectionForm from '../../Pages/sections/collection/CollectionForm';
import CollectionSingle from '../../Pages/sections/collection/CollectionSingle';
import AlbumFiles from '../../Pages/sections/collection/AlbumFiles';
import AllImages from '../../Pages/sections/collection/AllImages';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';

export const CollectionRouter = () => {
	const { appUser } = useSelector((state) => state.appUser);

	return (
		<Routes>
			{appUser.role_company !== 'ORGANIZATION' && (
				<>
					<Route
						path='/'
						element={
							<PrivateRoute>
								<AllCollections />
							</PrivateRoute>
						}
					/>
					<Route
						path='/new'
						element={
							<PrivateRoute>
								<CollectionForm action='new' />
							</PrivateRoute>
						}
					/>
					<Route
						path='/new/portada'
						element={
							<PrivateRoute>
								<AllImages />
							</PrivateRoute>
						}
					/>
					<Route
						path='/:id/portada'
						element={
							<PrivateRoute>
								<AllImages />
							</PrivateRoute>
						}
					/>
					<Route
						path='/:id'
						element={
							<PrivateRoute>
								<CollectionSingle />
							</PrivateRoute>
						}
					/>
					<Route
						path='/:id/new'
						element={
							<PrivateRoute>
								<CollectionForm action='new' />
							</PrivateRoute>
						}
					/>
					<Route
						path='/new/archivos'
						element={
							<PrivateRoute>
								<AlbumFiles />
							</PrivateRoute>
						}
					/>
					<Route
						path='/:id/archivos'
						element={
							<PrivateRoute>
								<AlbumFiles />
							</PrivateRoute>
						}
					/>

					<Route
						path='/public/:id'
						element={
							<PublicAccessRoute>
								<CollectionSingle isPublic={true} />
							</PublicAccessRoute>
						}
					/>
				</>
			)}
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default CollectionRouter;
