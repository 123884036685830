export default function PositionHandler(
	navigate,
	itemsLength,
	position,
	nextImage,
	posHandler,
	listItems,
	section
) {
	switch (posHandler) {
		case 'next':
			if (position < itemsLength - 1) {
				position = position + 1;
				nextImage = listItems[position].id;
				navigate(`/${section}/${nextImage}`);
			}
			break;

		case 'previous':
			if (position > 0) {
				position = position - 1;
				nextImage = listItems[position].id;
				navigate(`/${section}/${nextImage}`);
			}
			break;

		default:
			break;
	}
}
