// 3rd Party
import PropTypes from 'prop-types';

// UI
import DownloadableExpertSlideItemImage from '../../../../molecules/Portfolio/ExpertSlideItems/DownloadableExpertSlideItems/DownloadableExpertSlideItemImage';

export default function DownloadableExpertSlideFullScreenImage({
	imageElement,
}) {
	const imageURL = imageElement
		?.find((item) => item.type === 'image')
		?.slideItemElements?.find((element) => element.type === 'image_url');

	return <DownloadableExpertSlideItemImage imageURL={imageURL?.content} />;
}

DownloadableExpertSlideFullScreenImage.propTypes = {
	// contains the image data
	imageElement: PropTypes.array,
};
