//3rd party
import { Routes, Route } from 'react-router-dom';

//BL
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';
import RolesAndPermissions from '../../Pages/userManagament/configuration/RolesAndPermissions';
import UserEditForm from '../../Pages/userManagament/configuration/UserEditForm';
import UserNewForm from '../../Pages/userManagament/configuration/UserNewForm';
import UserView from '../../Pages/userManagament/configuration/UserView';
import PerfilForm from '../../Pages/userManagament/perfil/PerfilForm';
import Organizations from '../../Pages/userManagament/organization/Organizations';
import ConfigurationUser from '../../Pages/userManagament/configurationNew/ConfigurationUser';
import AdminPayments from '../../UI/organisms/Payments/AdminPayments';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';

export default function UserManagament() {
	return (
		<Routes>
			<Route
				path='/configuracion'
				element={
					<PrivateRoute>
						<ConfigurationUser />
					</PrivateRoute>
				}
			/>
			<Route
				path='/perfil/:id/edit'
				element={
					<PrivateRoute>
						<PerfilForm />
					</PrivateRoute>
				}
			/>
			<Route
				path='/configuracion/rolesypermisos'
				element={
					<PrivateRoute>
						<RolesAndPermissions />
					</PrivateRoute>
				}
			/>
			<Route
				path='/configuracion/user/nuevo'
				element={
					<PrivateRoute>
						<UserNewForm />
					</PrivateRoute>
				}
			/>
			<Route
				path='/configuracion/user/:id'
				element={
					<PrivateRoute>
						<UserView />
					</PrivateRoute>
				}
			/>
			<Route
				path='/configuracion/user/editar/:id'
				element={
					<PrivateRoute>
						<UserEditForm />
					</PrivateRoute>
				}
			/>
			<Route
				path='/organization'
				element={
					<PrivateRoute>
						<Organizations />
					</PrivateRoute>
				}
			/>
			<Route
				path='/configuracion/planes'
				element={
					<PrivateRoute>
						<AdminPayments />
					</PrivateRoute>
				}
			/>
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
}
