import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'formik';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//BL
import { fetchGetData } from '../../../../BusinessLogic/hooks/fetchGetData';
import {
	ENDPOINTS_COUNTRY,
	ENDPOINTS_PERSON,
	ENDPOINTS_CITY,
} from '../../../../BusinessLogic/helpers/routes';
import {
	getLocationsGeneral,
	postLocationGeneral,
} from '../../../../BusinessLogic/redux/reducers/locationGeneral';
import { backSectionURL } from '../../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../../BusinessLogic/helpers/textGoBackGenerator';
import { locationGeneralFormValidationSchema } from '../../../../BusinessLogic/data/SingleLocationGeneral2';

//UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import whiteCheck from '../../../../UI/assets/icons/whiteCheck.svg';
import ListItemText from '../../../../UI/atoms/Inputs/ListItemText';
import FieldTextFormik from '../../../../UI/molecules/Field/FieldTextFormik';
import ButtonFabExtendedPrimary from '../../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import SectionForm from '../../../../UI/organisms/Section/SectionForm';
import SecondaryNavbarSpecific from '../../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import ImageSectionForm from '../../../../UI/organisms/Image/ImageSectionForm';
import LoadingText from '../../../../UI/molecules/Loading/LoadingText';
import TemplateWithNavBar from '../../../../UI/templates/TemplateWithNavBar';

/**
 * @description manages the location general form page edit or create.
 * @returns
 */

export default function FormLocationGeneral() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { singleLocationGeneral } = useSelector(
		(state) => state.locationsGeneral
	);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);

	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [persons, setPersons] = useState([]);
	const [showSaving, setShowSaving] = useState(false);

	const { organization_user_id } = localStorage;

	//Query GET to the API
	useEffect(() => {
		fetchGetData(ENDPOINTS_COUNTRY.GET_COUNTRIES).then((data) =>
			setCountries(data.countries)
		);
		fetchGetData(ENDPOINTS_CITY.GET_CITIES + '47/').then((data) =>
			setCities(data.cities)
		);
		fetchGetData(
			ENDPOINTS_PERSON.GET_LOCATION_CONTACTS_WITH_LABELS +
				`${organization_user_id}/`
		).then((data) => setPersons(data));
	}, [organization_user_id]);

	const handleCountry = (e, formProps) => {
		const countryId = e.target.value;
		fetchGetData(ENDPOINTS_CITY.GET_CITIES + countryId + '/').then((data) =>
			setCities(data.cities)
		);
		formProps.values.country_id = countryId;
		formProps.setValues(formProps.values);
	};

	const checkRedirect = () => {
		let redirect = '/lugares';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage = 'RegresarLugares';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	return (
		<TemplateWithNavBar>
			<div className={showSaving && 'opacity-60'}>
				<SecondaryNavbarSpecific
					title={t('NuevoLugar')}
					textColor='text-purple-50'
					color='bg-purple-50'
					hide='md:invisible'
					redirect={checkRedirect()}
					textBack={CheckTextBack()}
					textBackDesign='pl-3 text-purple-50'
					backAction={() => returnBackAction()}
				/>
				<Formik
					initialValues={{
						name: '',
						details: '',
						address: '',
						country_id: undefined,
						type: 'LOCATION_GENERAL',
						city_id: undefined,
						type_id: singleLocationGeneral?.id ?? 0,
						file_type: 'IMAGE',
						contact_person_id: undefined,
						organization_user_id,
					}}
					validationSchema={locationGeneralFormValidationSchema}
					onSubmit={(values) => {
						setShowSaving(true);
						dispatch(postLocationGeneral(values))
							.then((res) => {
								navigate(`/lugares/${res.payload.id}`);
								dispatch(getLocationsGeneral());
							})
							.finally(() => setShowSaving(false));
					}}
				>
					{(formProps) => (
						<Form className='mb-20'>
							<List>
								<div className='flex flex-col gap-4 md:flex-row lg:gap-12'>
									<ImageSectionForm
										formProps={formProps}
										dropzoneLabel={'SoltarOClickImagenPortadaLugar'}
										mobileDropzoneLabel={'ClickCargarImagenPortadaLugar'}
									/>
									<div className='mx-auto flex w-full flex-col md:w-1/2'>
										<SectionForm
											title={t('Identificación')}
											textColor='text-purple-50'
											color='bg-purple-50'
										>
											<FieldTextFormik
												label={t('Nombre')}
												dbName='name'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.name}
											/>
											<FieldTextFormik
												label={t('Detalles')}
												dbName='details'
												type='Text'
											/>
											<FieldTextFormik
												label={t('Dirección')}
												dbName='address'
												type='Text'
											/>

											<ListItem
												style={{
													paddingLeft: '0px',
													paddingRight: '0px',
													justifyContent: 'space-between',
												}}
											>
												<ListItemText primary={t('País')} design={'text-sm'} />
												<Field
													name='country_id'
													type='Text'
													as='select'
													onChange={(e) => handleCountry(e, formProps)}
													className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
												>
													<option value=''></option>
													{countries.map((country) => (
														<option value={country.id}>{country.name}</option>
													))}
												</Field>
											</ListItem>
											{formProps.errors?.country_id && (
												<div className={`ml-auto w-40 text-red-400 md:w-56`}>
													{t(formProps.errors?.country_id)}
												</div>
											)}

											<ListItem
												style={{
													paddingLeft: '0px',
													paddingRight: '0px',
													justifyContent: 'space-between',
												}}
											>
												<ListItemText
													design={'text-sm'}
													primary={t('Ciudad')}
												/>
												<Field
													name='city_id'
													type='Text'
													as='select'
													className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
												>
													<option value=''></option>
													{cities.length > 1 &&
														cities.map((city) => (
															<option y={city.id} value={city.id}>
																{city.name}
															</option>
														))}
												</Field>
											</ListItem>
											{formProps.errors?.city_id && (
												<div className={`ml-auto w-40 text-red-400 md:w-56`}>
													{t(formProps.errors?.city_id)}
												</div>
											)}
											<ListItem
												style={{
													paddingLeft: '0px',
													paddingRight: '0px',
													justifyContent: 'space-between',
												}}
											>
												<ListItemText
													design={'text-sm'}
													primary={t('Contacto')}
												/>
												<Field
													name='contact_person_id'
													type='Text'
													as='select'
													className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
												>
													<option value=''></option>
													{persons?.map((person) => (
														<option value={person.value}>{person.label}</option>
													))}
												</Field>
											</ListItem>
											{formProps.errors?.contact_person_id && (
												<div className={`ml-auto w-40 text-red-400 md:w-56`}>
													{t(formProps.errors?.contact_person_id)}
												</div>
											)}
										</SectionForm>
										{showSaving ? (
											<LoadingText text={t('Guardando')} />
										) : (
											<ButtonFabExtendedPrimary
												className='self-center text-white'
												icon={whiteCheck}
												text={t('Guardar')}
											/>
										)}
									</div>
								</div>
							</List>
						</Form>
					)}
				</Formik>
			</div>
		</TemplateWithNavBar>
	);
}
