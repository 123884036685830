import React from 'react';
import { useSelector } from 'react-redux';

import ArtworkCompleteForm from './forms/ArtworkCompleteForm';
import ArtworkSimplifiedForm from './forms/ArtworkSimplifiedForm';

const ArtworkForm = () => {
	const { appUser } = useSelector((state) => state.appUser);

	return (
		<>
			{(appUser.role_company === 'GALLERY' ||
				appUser.role_company === 'ARTIST') && <ArtworkSimplifiedForm />}
			{appUser.role_company === 'ARTIST_CATALOGATION' && (
				<ArtworkCompleteForm />
			)}
		</>
	);
};

export default ArtworkForm;
