import { NAVBAR_FR } from './NavbarTranslations/Navbar_fr';
import { ONBOARDING_FR } from './OnboardingTranslations/Onboarding_fr';
import { SINGLE_FR } from './SingleTranslations/Single_fr';
import { VIEWALL_FR } from './ViewAllTraslations/ViewAll_fr';
import { FORM_FR } from './FormTranslations/Form_fr';
import { PAYMENTS_FR } from './PaymentsTranslations/Payments_fr';

export const TRANSLATIONS_FR = {
	...NAVBAR_FR,
	...SINGLE_FR,
	...FORM_FR,
	...ONBOARDING_FR,
	...VIEWALL_FR,
	...PAYMENTS_FR,
};
