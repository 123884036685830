import React from 'react';
import PropTypes from 'prop-types';

import TextSingleRowNewSkeleton from '../../molecules/Skeleton/TextSingleRowNewSkeleton';
import TextSubTitleSkeleton from '../../molecules/Skeleton/TextSubTitleSkeleton';

const ViewTitleAndFieldsSkeleton = ({
	numberOfSingleRow,
	numberOfSingleTitleAndField,
}) => {
	let rowsArray = [];
	let titleAndFieldArray = [];
	for (let i = 0; i < numberOfSingleRow; i++) {
		rowsArray.push(
			<div key={i}>
				<TextSingleRowNewSkeleton />
			</div>
		);
	}
	for (let i = 0; i < numberOfSingleTitleAndField; i++) {
		titleAndFieldArray.push(
			<div key={i}>
				<TextSubTitleSkeleton />
				<TextSingleRowNewSkeleton />
			</div>
		);
	}
	return (
		<div className='flex w-full flex-col'>
			<div className='flex w-full flex-col bg-light_grey-50 p-4 px-8'>
				<TextSubTitleSkeleton />
				<div>{rowsArray}</div>
				<div>{titleAndFieldArray}</div>
			</div>
		</div>
	);
};

ViewTitleAndFieldsSkeleton.propTypes = {
	numberOfSingleRow: PropTypes.number,
	numberOfSingleTitleAndField: PropTypes.number,
};

ViewTitleAndFieldsSkeleton.defaultProps = {
	numberOfSingleRow: 4,
	numberOfSingleTitleAndField: 0,
};

export default ViewTitleAndFieldsSkeleton;
