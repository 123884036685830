//3rd
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// UI
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import ButtonLinkBackText from '../../../UI/molecules/Buttons/Link/ButtonLinkBackText';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import { ReactComponent as Share } from '../../../UI/assets/icons/ShareNetwork.svg';
import TextContentPrincipal from '../../../UI/atoms/Text/Content/TextContentPrincipal';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import CardLink from '../../../UI/molecules/Card/CardLink';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import { ModalPostulation } from '../../../UI/organisms/PopUp/ModalPostulation';

//BL
import { dateShortFormat } from '../../../BusinessLogic/helpers/dateFormats';
import { getArtist } from '../../../BusinessLogic/redux/reducers/artist';
import {
	useGetPostulationSingle,
	usePutPostulationStatusArtist,
	usePutPostulationStatusOrganization,
} from '../../../BusinessLogic/hooks/query/useQueryPostulation';
import {
	useGetCallForm,
	useGetSingleCall,
} from '../../../BusinessLogic/hooks/query/useQueryCalls';

/**
 * @description Renders the view of a new postulation
 * @returns
 */
const NewPostulation = () => {
	const { id: postulationId } = useParams();
	const { t } = useTranslation();
	const { appUser } = useSelector((state) => state.appUser);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const isArtistUser = appUser?.role_company === 'ARTIST';
	const isCatalogueArtistUser = appUser?.role_company === 'ARTIST_CATALOGATION';
	const isOrganizationUser = appUser?.role_company === 'ORGANIZATION';
	const isGalleryUser = appUser?.role_company === 'GALLERY';

	const { postulationSingle } = useGetPostulationSingle(postulationId);

	const putPostulationStatusMutationOrganization =
		usePutPostulationStatusOrganization();
	const putPostulationStatusMutationArtist = usePutPostulationStatusArtist();

	const { singleCall } = useGetSingleCall(postulationSingle?.call_id);
	const { singleCallForm } = useGetCallForm(singleCall?.form_id);

	const { singleArtist } = useSelector((state) => state.artists);
	const [showModal, setShowModal] = useState(false);
	const [termsAndConditionsStatus, setTermsAndConditionsStatus] =
		useState(false);
	const [showErrors, setShowErrors] = useState(false);

	useEffect(() => {
		if (!singleArtist || singleArtist.id !== postulationSingle?.artist_id) {
			postulationSingle?.artist_id &&
				dispatch(getArtist({ id: postulationSingle.artist_id }));
		}
	}, [dispatch, singleArtist, postulationSingle?.artist_id]);

	let defaultImage =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';

	const validPortfolio =
		singleCall?.has_portfolio && singleCall?.type === 'FREE'
			? postulationSingle.portfolios?.length > 0
			: true;
	const validCatalogue =
		singleCall?.has_portfolio && singleCall?.type === 'CATALOGUE'
			? postulationSingle?.artworks?.length > 0
			: true;
	const validForm = singleCall?.has_form
		? postulationSingle?.form_completed === 'Completed'
		: true;
	const validCV = singleCall?.has_cv
		? postulationSingle.documents?.document_url
		: true;

	const submitPostulation = () => {
		const validPostulation =
			termsAndConditionsStatus &&
			validPortfolio &&
			validForm &&
			validCV &&
			validCatalogue;
		if (
			validPostulation &&
			(isArtistUser || isCatalogueArtistUser || isGalleryUser)
		) {
			putPostulationStatusMutationArtist
				.mutateAsync({
					postulation_id: postulationSingle.id,
					status: 'SUBMITTED',
					status_updated_at: new Date(Date.now()),
				})
				.then(() => setShowModal(true));
		} else if (validPostulation && isOrganizationUser) {
			putPostulationStatusMutationOrganization.mutateAsync({
				postulation_id: postulationSingle.id,
				status: 'SUBMITTED',
				status_updated_at: new Date(Date.now()),
			});
		} else {
			setShowErrors(true);
		}
	};
	const previousPage = sessionStorage.getItem('previousPage');

	return (
		<TemplateWithNavBar>
			<div className='mb-10 flex w-full flex-col'>
				<div className='flex items-center justify-between py-4 md:mt-4'>
					<ButtonLinkBackText
						textBack={
							previousPage === 'singleCall' && `Regresar a la convocatoria`
						}
						design='md:w-1/4'
						textBackDesign='pl-3 text-purple-50'
						redirect={
							previousPage === 'singleCall'
								? `/convocatorias/${singleCall?.route_slug}`
								: '/postulaciones'
						}
						arrowLeftColor='purple'
					/>
					<TextMainTitle
						title={singleCall?.name}
						textColor='text-purple-50'
						color='bg-purple-50 w-full'
						design='text-center flex flex-col'
					/>
					<div className='flex gap-2 md:w-1/4 md:justify-end'>
						<Share className='hidden w-max cursor-pointer md:block' />
					</div>
				</div>

				<div className='flex w-full py-10'>
					<div className='flex w-full items-center gap-4'>
						<div className='h-16 w-16 md:h-20 md:w-20 '>
							<LazyLoadImage
								alt={singleArtist?.name ?? ''}
								src={`${singleArtist?.main_picture_url ?? defaultImage}_small`}
								width='100%'
								height='100%'
								effect='blur'
								wrapperClassName={`overflow-hidden rounded-full flex items-center lazyLoadingImages`}
								delayTime='0'
								threshold='800'
							/>
						</div>
						<div className='flex w-max flex-col gap-2'>
							<TextContentPrincipal
								text={singleArtist?.name ?? ''}
								style={{ fontWeight: 700 }}
							/>
							<TextContentSecond
								design={`break-all`}
								text={`Creado el ${dateShortFormat(
									postulationSingle?.created_at
								)}`}
							/>
						</div>
					</div>
					<TextContentPrincipal
						text={'Nueva'}
						design={'flex mt-3 md:mt-4 text-blue-500'}
						style={{ fontWeight: 400 }}
					/>
				</div>

				<div className='flex w-full flex-col gap-2'>
					<div>
						<TextTitleSub
							title='Documentos requeridos'
							textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2'
							style={{
								fontWeight: '700',
							}}
						/>
						<div
							className='flex w-full flex-col gap-4 py-4'
							data-test='newPostulation-requirements-section'
						>
							{singleCall?.has_portfolio &&
								singleCall?.type === 'CATALOGUE' && (
									<CardLink
										title={'Catálogo'}
										text={
											validCatalogue
												? 'Catálogo adjunto'
												: 'Para aplicar a esta convocatoria debes seleccionar tus obras subidas a Datarte.'
										}
										showErrorWarning={!validCatalogue && showErrors}
										redirect={`/postulaciones/${postulationSingle.id}/catalogo`}
										showValidIcon={validCatalogue}
									/>
								)}
							{singleCall?.has_portfolio && singleCall?.type === 'FREE' && (
								<CardLink
									title={'Portafolio'}
									text={
										validPortfolio
											? `Portafolio adjunto: ${
													postulationSingle.portfolios[
														postulationSingle.portfolios.length - 1
													].portfolio_slug
											  }`
											: 'Para aplicar a esta convocatoria debes seleccionar un portafolio creado en Datarte.'
									}
									showErrorWarning={!validPortfolio && showErrors}
									redirect={`/postulaciones/${postulationSingle.id}/portafolio`}
									showValidIcon={validPortfolio}
								/>
							)}

							{singleCall?.has_form && singleCallForm?.forms?.form_elements && (
								<CardLink
									title={'Formulario'}
									text={
										validForm
											? 'Formulario diligenciado'
											: 'Para aplicar a esta convocatoria debes diligenciar un formulario.'
									}
									showErrorWarning={!validForm && showErrors}
									redirect={`/postulaciones/${postulationSingle.id}/formulario`}
									showValidIcon={validForm}
								/>
							)}

							{singleCall?.has_cv && (
								<CardLink
									title={'Curriculum vitae (CV)'}
									text={
										validCV
											? 'Curriculum vitae adjuntada'
											: 'Para aplicar a esta convocatoria debes adjuntar tu CV.'
									}
									showErrorWarning={!validCV && showErrors}
									redirect={`/postulaciones/${postulationSingle.id}/cv`}
									showValidIcon={validCV}
								/>
							)}
						</div>
					</div>

					<div>
						<TextTitleSub
							title='Términos y condiciones'
							textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2 mb-4'
							style={{
								fontWeight: '700',
							}}
						/>
						<label className='flex w-fit cursor-pointer justify-start gap-2 py-2'>
							<input
								type='checkbox'
								className={`
									h-4 w-4 cursor-pointer appearance-none rounded-sm 
									border-2 border-green-50 
									after:relative after:-top-1/2 after:text-white checked:bg-green-50 checked:after:content-['✓']
								`}
								checked={termsAndConditionsStatus}
								onChange={() =>
									setTermsAndConditionsStatus((status) => !status)
								}
							/>
							<div className='flex flex-row gap-1'>
								<TextContentSecond text={`He leído y acepto`} />

								<a
									href={'https://www.datarte.art/tycdatarte'}
									target='_blank'
									rel='noreferrer'
								>
									<TextContentSecond
										text={'los términos y condiciones de uso.'}
										design={'text-[#34B3E5] underline'}
									/>
								</a>
							</div>
						</label>
						{!termsAndConditionsStatus && showErrors && (
							<TextContentSecond
								text={'Por favor, acepte los Términos y Condiciones de uso.'}
								design={'text-red-500'}
							/>
						)}
					</div>
					<div className='mb-16 mt-8 flex w-full justify-center'>
						<ButtonFabExtendedPrimary
							className='self-center text-white'
							text={t('Postularse')}
							action={() => submitPostulation()}
						/>
					</div>
				</div>
			</div>
			{showModal && (
				<ModalPostulation
					modalTitle={'Postulación enviada'}
					modalText={
						'Tu postulación fue enviada. Te notificaremos al email registrado en Datarte el proceso de tu postulación.'
					}
					buttonLabel={'Cerrar'}
					actionClose={() => {
						navigate(`/postulaciones`);
					}}
				/>
			)}
		</TemplateWithNavBar>
	);
};

export default NewPostulation;

NewPostulation.propTypes = {};
