/* Icons */
import { ReactComponent as Email } from '../../../assets/icons/email.svg';

export default function ButtonLinkMessage({ design }) {
	return (
		<figure src={Email} alt='Atrás'>
			<Email fill='none' stroke={design} />
		</figure>
	);
}
