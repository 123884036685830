// 3rd Party
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

//BL
import AllLocationsGeneral from '../../Pages/sections/location/locationGeneral/AllLocationsGeneral';
import FormLocationGeneral from '../../Pages/sections/location/locationGeneral/FormLocationGeneral';
import FormLocation from '../../Pages/sections/location/lastLocation/FormLocation';
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';
import LocationSingleGeneral from '../../Pages/sections/location/locationGeneral/LocationSingleGeneral';
import LastLocationSingle from '../../Pages/sections/location/lastLocation/LastLocationSingle';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';

export const LocationGeneralRouter = () => {
	const { appUser } = useSelector((state) => state.appUser);

	return (
		<Routes>
			{appUser.role_company !== 'GALLERY' && (
				<>
					<Route
						path='/'
						element={
							<PrivateRoute>
								<AllLocationsGeneral />
							</PrivateRoute>
						}
					/>
					<Route
						path='/:id/edit'
						element={
							<PrivateRoute>
								<FormLocationGeneral action='edit' />
							</PrivateRoute>
						}
					/>
					<Route
						path='/new'
						element={
							<PrivateRoute>
								<FormLocationGeneral action='new' />
							</PrivateRoute>
						}
					/>
					<Route
						path='/:id'
						element={
							<PrivateRoute>
								<LocationSingleGeneral />
							</PrivateRoute>
						}
					/>
					<Route
						path='/ubicaciones/:id/edit'
						element={
							<PrivateRoute>
								<FormLocation action='edit' />
							</PrivateRoute>
						}
					/>
					<Route
						path='/ubicaciones/new'
						element={
							<PrivateRoute>
								<FormLocation action='new' />
							</PrivateRoute>
						}
					/>
					<Route
						path='/ubicaciones/:id'
						element={
							<PrivateRoute>
								<LastLocationSingle />
							</PrivateRoute>
						}
					/>
				</>
			)}
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default LocationGeneralRouter;
