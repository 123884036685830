import { Link } from 'react-router-dom';

/**
 * This function is a link that takes in a text, design, route, style, and section.
 * @returns A Link component with the text, design, route, style, and section props.
 */
export default function ButtonLinkText({
	text,
	design,
	route,
	style,
	section,
}) {
	return (
		<Link
			to={route}
			className={`${design} ${
				section === text ? 'text-base font-light text-yellow-50' : 'body-Text2'
			}`}
			style={style}
		>
			{text}
		</Link>
	);
}
