//3rd party
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//BL
import { getExpositions } from '../../../BusinessLogic/redux/reducers/exposition';
import FilterExposition from './FilterExposition';

//UI
import SecondaryNavbar from '../../../UI/organisms/Navbar/NavbarSecondary';
import ImagesSectionWithFilter from '../../../UI/organisms/Image/ImageSectionWithFilter';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import ButtonChangeView from '../../../UI/molecules/Buttons/ButtonChangeView';
import FilterButton from '../../../UI/atoms/Buttons/Link/FilterButton';
import ButtonFabPrincipalUrl from '../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import ViewAllSkeleton from '../../../UI/templates/SkeletonTemplates/ViewAllSkeleton';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

export default function AllExpositions() {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { value, expositions, status } = useSelector(
		(state) => state.expositions
	);

	const [changeView, setChangeView] = useState(false);
	const [filterArray, setFilterArray] = useState({});
	const [filterChange, setFilterChange] = useState(0);
	const [nameFilter, setNameFilter] = useState(value.payload);

	useEffect(() => {
		if (!expositions) {
			dispatch(getExpositions());
		}
		//eslint-disable-next-line
	}, []);

	return (
		<div>
			{!expositions && status === 'loading' && (
				<ViewAllSkeleton
					numberOfImages={expositions?.length}
					designSkeletonTitle4={'invisible'}
					designSkeletonImages={
						'w-full px-4 grid grid-cols-3 sm:grid-cols-5 md:grid-cols-7 2xl:grid-cols-9 gap-x-2 gap-y-0 justify-center'
					}
				/>
			)}
			{expositions && (
				<TemplateWithNavBar section={'Exposiciones'}>
					<SecondaryNavbar
						title={t('Exposiciones')}
						textColor='text-green-50'
						color='bg-green-50'
						redirect='home'
						hidden='invisible'
						changeView={changeView}
						setChangeView={setChangeView}
						Filter={FilterExposition}
						data={expositions}
						filterArray={filterArray}
						setFilterArray={setFilterArray}
						filterChange={filterChange}
						setFilterChange={setFilterChange}
						nameFilter={nameFilter}
						setNameFilter={setNameFilter}
					/>
					<div className='flex w-full flex-col items-center justify-between px-4 xs:mb-5 xs:h-12 xs:flex-row md:hidden'>
						<div className='invisible w-16'></div>
						<TextMainTitle
							title={t('Exposiciones')}
							textColor='text-purple-50'
							color='bg-purple-50'
						/>
						<div className='my-3 flex w-full flex-row justify-between xs:my-0 xs:w-auto'>
							<ButtonChangeView
								changeView={changeView}
								setChangeView={setChangeView}
							/>
							<FilterButton
								Filter={FilterExposition}
								data={expositions}
								filterArray={filterArray}
								setFilterArray={setFilterArray}
								filterChange={filterChange}
								setFilterChange={setFilterChange}
								nameFilter={nameFilter}
								setNameFilter={setNameFilter}
							/>
						</div>
					</div>
					<ImagesSectionWithFilter
						data={expositions}
						redirect='exposiciones'
						filterChange={filterChange}
						filterArray={filterArray}
						nameFilter={nameFilter}
						changeView={changeView}
						newEntityLink={'/exposiciones/new'}
					/>
					<ButtonFabPrincipalUrl label={t('Crear')} url='/exposiciones/new' />
				</TemplateWithNavBar>
			)}
		</div>
	);
}
