const daysSpanish = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
const daysEnglish = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const eventsData = [
	{ typeOfEvent: 'AperturaDeConvocatoria', color: 'bg-green-50' },
	{ typeOfEvent: 'FinalizaciónDeConvocatoria', color: 'bg-yellow-50' },
	{ typeOfEvent: 'ResultadosDeConvocatoria', color: 'bg-purple-50' },
	{ typeOfEvent: 'EventosImportantes', color: 'bg-light_green-50' },
];

export { daysSpanish, eventsData, daysEnglish };
