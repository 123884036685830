import { api } from '../helpers/axios';
import { submitErrorToSlack } from '../helpers/SlackIntegrations';

export const fetchPostData = async (url, values) => {
	return api
		.post(url, values)
		.then(async (res) => {
			const response = res.data;
			if (res.status === 200 || res.status === 201) {
				return response;
			}
			throw new Error(res);
		})
		.catch((error) => {
			if (error.response.status >= 500) {
				submitErrorToSlack(url, error, 'POST');
			}
			return error;
		});
};
