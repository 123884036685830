/* React */
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/* BL */
import {
	getLocationRoom,
	putLocationsRoom,
	organizeImageGrid,
} from '../../../../BusinessLogic/redux/reducers/locationRoom';
import {
	singleRoomButtons,
	singleRoomValidationSchema,
} from '../../../../BusinessLogic/data/SingleLocationRoom2';
import { ENDPOINT_FILES } from '../../../../BusinessLogic/helpers/routes';

import { deleteFile } from '../../../../BusinessLogic/redux/reducers/deleteElement';
import { createImage } from '../../../../BusinessLogic/redux/reducers/createImage';
import { compressAndResizeImage } from '../../../../BusinessLogic/helpers/imageSize';
import textGoBackGenerator from '../../../../BusinessLogic/helpers/textGoBackGenerator';
import {
	backSectionURL,
	saveSectionURL,
} from '../../../../BusinessLogic/redux/reducers/navigation';

/* UI */
import { ReactComponent as Delete } from '../../../../UI/assets/icons/Delete.svg';
import TemplateWithNavBar from '../../../../UI/templates/TemplateWithNavBar';
import NavbarSecondaryNew from '../../../../UI/organisms/Navbar/NavbarSecondaryNew';
import ImageAndTitle from '../../../../UI/molecules/Image/ImageAndTitle';
import NewTagButtons from '../../../../UI/molecules/Buttons/Tag/NewTagButtons';
import TabInfoAndEdit from '../../../../UI/organisms/Tabs/TabInfoAndEdit';
import TabImageAndEdit from '../../../../UI/organisms/Tabs/TabImageAndEdit';
import ButtonFabExtendeNav from '../../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendeNav';

/**
 * @description renders the single room section with a carousel.
 * @param {*} param0
 * @returns
 */
function RoomSingle() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const location = useLocation();

	const { singleLocationGeneral } = useSelector(
		(state) => state.locationsGeneral
	);

	const { singleLocationRoom, locationRoomSingleTabs } = useSelector(
		(state) => state.locationsRoom
	);

	const { elementInfo } = useSelector((state) => state.deleteElement);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);
	const [indexInfo, setIndexInfo] = useState(0);

	const { t } = useTranslation();

	useEffect(() => {
		if (!getLocationRoom || `${getLocationRoom.id}` !== id) {
			dispatch(getLocationRoom(id));
		}
		//eslint-disable-next-line
	}, []);

	function onSubmitAction(values) {
		values.values.main_picture_id = singleLocationRoom.main_picture_id;
		values.values.location_general_id = singleLocationRoom.location_general_id;
		dispatch(putLocationsRoom(values)).then(() =>
			dispatch(getLocationRoom(id))
		);
	}

	const checkRedirect = () => {
		let redirect = `/lugares/${singleLocationRoom.location_general_id}`;
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage = 'RegresarLugar';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	const saveURLHistory = () => {
		dispatch(saveSectionURL(location.pathname));
	};

	const createImagesFunction = async (imagesArray) => {
		for await (const image of imagesArray) {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			let values = {
				small_file,
				medium_file,
				large_file,
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
				type: 'LOCATION_ROOM',
				type_id: id,
				organization_user_id: singleLocationGeneral.organization_user_id,
				file_type: 'IMAGE',
			};
			await dispatch(createImage(values));
		}
		dispatch(getLocationRoom(id));
	};

	const deleteImagesFunction = (imageId) => {
		let values = {
			element_id: singleLocationGeneral.id,
			element: 'LOCATION_ROOM',
			url: `${ENDPOINT_FILES.DELETE_FILE}${imageId}/`,
		};
		dispatch(deleteFile(values)).then(() => dispatch(getLocationRoom(id)));
	};

	const submitOrganizeImageGrid = (sortableImages) => {
		let values = {
			images: sortableImages,
			type: 'LOCATION_ROOM',
			type_id: id,
		};
		dispatch(organizeImageGrid(values)).then(() =>
			dispatch(getLocationRoom(id))
		);
	};

	const renderSwitch = () => {
		switch (locationRoomSingleTabs[indexInfo].type) {
			case 'Information':
				return (
					<TabInfoAndEdit
						information={locationRoomSingleTabs[indexInfo]}
						indexInfo={indexInfo}
						colorHr='purple'
						onSubmitAction={onSubmitAction}
						id={parseInt(id)}
						validationSchema={
							singleRoomValidationSchema[indexInfo]
								? singleRoomValidationSchema[indexInfo]
								: null
						}
					/>
				);
			case 'Obras':
				return (
					<TabImageAndEdit
						information={locationRoomSingleTabs[indexInfo]}
						indexInfo={indexInfo}
						color='purple'
						AddIconLink={`/obras/new`}
						saveLastSectionURL={true}
						saveURLHistory={saveURLHistory}
					/>
				);
			case 'Ubicaciones':
				return (
					<TabImageAndEdit
						information={locationRoomSingleTabs[indexInfo]}
						indexInfo={indexInfo}
						color='purple'
						AddIconLink={`/ubicaciones/new`}
						saveLastSectionURL={true}
						saveURLHistory={saveURLHistory}
					/>
				);
			case 'Images':
				return (
					<TabImageAndEdit
						information={locationRoomSingleTabs[indexInfo]}
						indexInfo={indexInfo}
						colorHr='purple'
						AddIconLink={`/${locationRoomSingleTabs[
							indexInfo
						].tab.toLowerCase()}/new`}
						deleteImagesFunction={deleteImagesFunction}
						createImagesFunction={createImagesFunction}
						submitOrganizeImageGrid={submitOrganizeImageGrid}
					/>
				);

			default:
				return <h1>No hay documentos disponible</h1>;
		}
	};

	return (
		<TemplateWithNavBar section='salas'>
			{singleLocationRoom && (
				<>
					<NavbarSecondaryNew
						title={singleLocationRoom.name}
						color='bg-purple-50'
						textColor='text-purple-50'
						redirect={checkRedirect()}
						textPopup={`${t('EliminarSala')}`}
						popupIcon={<Delete />}
						modalTitle={`${t('EliminarSala')}`}
						modalTextOne={`${t('SeguroQueDeseasEliminarEstaSala')}`}
						modalTextTwo={elementInfo}
						id={parseInt(id)}
						section='salas'
						textBack={CheckTextBack()}
						textBackDesign='pl-3 text-purple-50'
						backAction={() => returnBackAction()}
					/>
					<ButtonFabExtendeNav
						id={parseInt(id)}
						listItems={singleLocationGeneral.locations_room}
						section='salas'
					/>
					<div className='flex w-full flex-col items-center justify-center gap-8 md:flex-row md:items-start'>
						<div className='flex w-full justify-center p-6 sm:w-[70%] md:w-1/3'>
							<ImageAndTitle imagen={singleLocationRoom.main_picture_url} />
						</div>
						<div className='flex w-full flex-col md:w-2/3'>
							<NewTagButtons
								buttonList={singleRoomButtons}
								indexInfo={indexInfo}
								setIndexInfo={setIndexInfo}
							/>

							<div className='min-h-screen bg-light_grey-50 py-4'>
								{renderSwitch()}
							</div>
						</div>
					</div>
				</>
			)}
		</TemplateWithNavBar>
	);
}

RoomSingle.propTypes = {};

RoomSingle.defaultProps = {};

export default RoomSingle;
