import { Field } from 'formik';
import ParagraphLabel from '../../atoms/Text/Labels/ParagraphLabel';

export default function RadioItem({
	design,
	type = 'radio',
	name,
	value,
	label,
	paragraph,
}) {
	return (
		<Field
			type={type}
			name={name}
			value={value}
			label={label}
			design={design}
			paragraph={paragraph}
			as={CustomNameRadio}
		/>
	);
}

const CustomNameRadio = ({
	name,
	type,
	value,
	label,
	field,
	form,
	checked,
	design,
	paragraph,
	...props
}) => {
	return (
		<label className={label ? '' : 'flex'}>
			<div className='mr-4 mb-4 flex items-center'>
				<input
					id={`radio${value}`}
					className='hidden'
					type={type}
					name={name}
					value={value}
					{...field}
					{...props}
				/>
				<label
					htmlFor={`radio${value}`}
					className='body-Text2 flex cursor-pointer items-center'
					style={{ fontWeight: 600 }}
				>
					<span className='border-grey mr-1 inline-block h-4 w-4 border'></span>
					{label}
				</label>
			</div>
			<ParagraphLabel design={`${design} font-light mb-5`} text={paragraph} />
		</label>
	);
};
