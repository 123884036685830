//3rd party
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//BL

//UI
import TextContentSecond from '../atoms/Text/Content/TextContentSecond';
import TextContentThird from '../atoms/Text/Content/TextContentThird';
import TextTitleSub from '../atoms/Text/Titles/TextTitleSub';

/**
 * @description Template for the modal view, maybe we can reuse it
 * @param {*} param0
 * @returns
 */

function ViewModal({
	setConfirmDeleteElement,
	closeMenu,
	modalTitle,
	modalTextOne,
	modalTextTwo,
	navigate,
	confirmButtonText,
	confirmButtonTextDesign,
	customChildren,
}) {
	const { t } = useTranslation();
	return (
		<div>
			<div className='fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black-50 bg-opacity-80 '>
				<div className='relative mx-auto my-6 w-auto max-w-3xl p-6 md:p-0'>
					<div className='relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg '>
						<div className='flex items-start justify-center px-5 pb-2.5 pt-5'>
							<TextTitleSub title={modalTitle} style={{ fontWeight: 600 }} />
						</div>
						<div className='relative flex flex-col items-center justify-center px-2 pb-2 text-center'>
							<TextContentSecond text={modalTextOne} design='mb-2' />
							{customChildren ? (
								customChildren
							) : (
								<TextContentThird text={modalTextTwo} design='text-center' />
							)}
						</div>
						<div className='mt-4 flex w-full flex-col items-center justify-center rounded-b border-t-2 border-solid border-slate-200'>
							<button
								className={`body-Text2 w-full rounded-b-lg border-b-2 border-solid py-2 text-strong_red-100 hover:bg-light_grey-50 ${
									confirmButtonTextDesign || ''
								}`}
								type='button'
								onClick={() => {
									setConfirmDeleteElement(true);
									if (navigate) {
										setTimeout(() => navigate('/'), 1000);
									}
									closeMenu(false);
								}}
								data-test='modal-delete-button'
							>
								{t(confirmButtonText)}
							</button>
							<button
								className='body-Text2 w-full rounded-b-lg py-3 hover:bg-light_grey-50'
								type='button'
								onClick={() => closeMenu(false)}
							>
								{t('Cancelar')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

ViewModal.propTypes = {
	/** function state that close the menu*/
	closeMenu: PropTypes.func,
	/* string with the modal title display */
	modalTitle: PropTypes.string,
	/* string that asking to confirm delete the entity */
	modalTextOne: PropTypes.string,
	/* string that show number of entitys to delete with a message */
	modalTextTwo: PropTypes.string,
	/** Text to display in the confirm button of the modal */
	confirmButtonText: PropTypes.string,

	customChildren: PropTypes.node,

	confirmButtonTextDesign: PropTypes.string,
};

ViewModal.defaultProps = {
	confirmButtonText: 'Eliminar',
};

export default ViewModal;
