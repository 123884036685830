//3rd party
import PropTypes from 'prop-types';

//UI
import TemplateWithNavBar from './TemplateWithNavBar';

/**
 * @description Temaplate used with the forms. Adds the Navbar
 * Refactor.
 * It's the same than the viewAll component
 * @param {*} param0
 * @returns
 */
export default function ViewForm({ children }) {
	return (
		<div>
			<TemplateWithNavBar>{children}</TemplateWithNavBar>
		</div>
	);
}

ViewForm.propTypes = {
	/** Children that goes in the wrapper*/
	children: PropTypes.array,
};
