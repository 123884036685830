export const sortItemsByOrder = (items) => {
	if (Array.isArray(items)) {
		return items.sort((a, b) => a.order - b.order);
	} else {
		return items;
	}
};

export const sortDatesInAscendingOrder = (dates) => {
	if (Array.isArray(dates)) {
		return [...dates].sort((a, b) => {
			const dateA = new Date(a?.date_event ?? '');
			const dateB = new Date(b?.date_event ?? '');
			return dateA - dateB;
		});
	}
};
