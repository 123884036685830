import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	ENDPOINTS_CITY,
	ENDPOINTS_COUNTRY,
	ENDPOINTS_CURRENCY,
	ENDPOINTS_MEASURE,
} from '../../helpers/routes';
import { fetchGetData } from '../../hooks/fetchGetData';

export const getCountries = createAsyncThunk(
	'staticData/getCountries',
	async () => {
		return await fetchGetData(ENDPOINTS_COUNTRY.GET_COUNTRIES_WITH_LABELS);
	}
);
export const getCities = createAsyncThunk(
	'staticData/getCities',
	async (countryId) => {
		return await fetchGetData(
			`${ENDPOINTS_CITY.GET_CITIES_WITH_LABELS}${countryId}/`
		);
	}
);
export const getCurrencies = createAsyncThunk(
	'staticData/getCurrencies',
	async () => {
		return await fetchGetData(ENDPOINTS_CURRENCY.GET_CURRENCIES);
	}
);
export const getMeasures = createAsyncThunk('artists/getMeasures', async () => {
	return await fetchGetData(ENDPOINTS_MEASURE.GET_MEASURES);
});

const initialState = {
	cities: null,
	countries: null,
	currencies: null,
	measures: null,
	status: null,
};

export const StaticData = createSlice({
	name: 'Static Data',
	initialState,
	reducers: {},
	extraReducers: {
		//Countries
		[getCountries.pending]: (state) => {
			state.status = 'loading';
		},
		[getCountries.fulfilled]: (state, { payload }) => {
			state.countries = payload.countries;
			state.status = 'success';
		},
		[getCountries.rejected]: (state) => {
			state.status = 'failed';
		},
		//Cities
		[getCities.pending]: (state) => {
			state.status = 'loading';
		},
		[getCities.fulfilled]: (state, { payload }) => {
			state.cities = payload.cities;
			state.status = 'success';
		},
		[getCities.rejected]: (state) => {
			state.status = 'failed';
		},
		//Currencies
		[getCurrencies.pending]: (state) => {
			state.status = 'loading';
		},
		[getCurrencies.fulfilled]: (state, { payload }) => {
			state.currencies = payload;
			state.status = 'success';
		},
		[getCurrencies.rejected]: (state) => {
			state.status = 'failed';
		},
		//Measures
		[getMeasures.pending]: (state) => {
			state.status = 'loading';
		},
		[getMeasures.fulfilled]: (state, { payload }) => {
			state.measures = payload;
			state.status = 'success';
		},
		[getMeasures.rejected]: (state) => {
			state.status = 'failed';
		},
	},
});

// export const {} = StaticData.actions;

export default StaticData.reducer;
