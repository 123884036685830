//3rd party
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

//BL
import {
	PrivateRoute,
	PublicAccessRoute,
} from '../../Pages/authentication/components/LoginUtils';
import PageNotFound from '../../Pages/fallBackPages/PageNotFound';

//UI
import AllExpositions from '../../Pages/sections/expositions/AllExpositions';
import ExpositionsForm from '../../Pages/sections/expositions/ExpositionsForm';
import ExpositionSingle from '../../Pages/sections/expositions/ExpositionSingle';

/**
 * @description handle all routes of exhibition section
 * @param {*} param0
 * @returns
 */
function ExpositonsRouter() {
	const { appUser } = useSelector((state) => state.appUser);

	return (
		<Routes>
			{appUser.role_company !== 'GALLERY' && (
				<>
					<Route
						exact
						path='/'
						element={
							<PrivateRoute>
								<AllExpositions />
							</PrivateRoute>
						}
					/>

					<Route
						exact
						path='/new'
						element={
							<PrivateRoute>
								<ExpositionsForm />
							</PrivateRoute>
						}
					/>

					<Route
						exact
						path='/:id'
						element={
							<PrivateRoute>
								<ExpositionSingle />
							</PrivateRoute>
						}
					/>

					<Route
						path='/public/:id'
						element={
							<PublicAccessRoute>
								<AllExpositions />
							</PublicAccessRoute>
						}
					/>
				</>
			)}
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
}

ExpositonsRouter.propTypes = {};

export default ExpositonsRouter;
