import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ButtonHeaderPayments = ({ className = '' }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const { appUser } = useSelector((state) => state.appUser);

	const activePlan = appUser.subscription_type;
	const isActivePlanFree = activePlan === 'PlanGratis';

	const handleGoToMemberships = () => {
		navigate('/planes');
	};

	if (!isActivePlanFree) {
		return null;
	}

	return (
		<button
			className={`rounded-3xl bg-[#CBBFD9] px-3 py-1 ${className}`}
			onClick={handleGoToMemberships}
		>
			<p className='text-sm text-white lg:text-base'>{t('hazteMiembro')}</p>
		</button>
	);
};

export default ButtonHeaderPayments;
