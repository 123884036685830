export const artworkImageAlt = (
	artworkName,
	artistName,
	artworkDescription
) => {
	let alt = '';

	if (artworkName) {
		alt += artworkName;
	}

	if (artworkName && artistName) {
		alt += ` de ${artistName}. `;
	}

	if (artworkDescription) {
		alt += artworkDescription;
	}

	return alt;
};
