//3rd party
import PropTypes from 'prop-types';

//UI
import ArtworkImage from '../../molecules/Portfolio/ArtworkImage';

/**
 *
 * @description used to manage portfolios when there are only artowkrs
 */

export default function PortfolioArtworks({
	artworks,
	image_quality,
	showTitle,
	showPrices,
	artistName,
}) {
	return (
		<div className='w-full'>
			{showTitle && (
				<h5 className='mb-8 w-full text-center font-louisGeorgeCafe text-xl font-semibold md:text-3xl'>
					Obras
				</h5>
			)}
			<div className='grid grid-cols-1 gap-10 md:grid-cols-2'>
				{artworks.map((element) => (
					<div
						key={element.id}
						className='flex flex-row justify-center md:justify-start md:even:justify-end'
					>
						<ArtworkImage
							main_picture={element.main_picture}
							name={element.name}
							date={element.date}
							dimensions_height={element.dimensions_height}
							dimensions_length={element.dimensions_length}
							dimensions_width={element.dimensions_width}
							materials={element.materials}
							technique={element.technique}
							image_quality={image_quality}
							owner_status={element.owner_status}
							price={element.price}
							currency={element.currency}
							showPrices={showPrices}
							artistName={artistName}
							description={element.description}
							objectType={element.object_type}
							videoUrl={element.video_url}
						/>
					</div>
				))}
			</div>
		</div>
	);
}

PortfolioArtworks.propTypes = {
	/** array that passes all the data to the next section  */
	artworks: PropTypes.array,
	/** Image quality that is passed to the artwork images*/
	image_quality: PropTypes.string,
	/** Determines if the title of the section should be displayed*/
	showTitle: PropTypes.bool,
	/** Bool prop to set if the price will be show */
	showPrices: PropTypes.bool,
	//** Name of the artist */
	artistName: PropTypes.string,
};

PortfolioArtworks.defaultProps = {
	image_quality: 'HIGH',
	showTitle: false,
};
