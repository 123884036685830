//3rd party
import { useEffect } from 'react';
import { useState } from 'react';

//BL
import {
	ENDPOINTS_APPUSER,
	ENDPOINTS_ORGANIZATION,
} from '../../../BusinessLogic/helpers/routes';
import { fetchGetData } from '../../../BusinessLogic/hooks/fetchGetData';
import { fetchPostDataWithUser } from '../../../BusinessLogic/hooks/fetchPostDataWithUser';
import { useTranslation } from 'react-i18next';

//UI
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import UnderlineHr from '../../../UI/atoms/UnderLine/UnderlineHr';
import AddRoleAndPermission from '../../../UI/molecules/userManagement/AddRoleAndPermission';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import User from '../../../UI/organisms/Managament/ManagamentUser';
import SecondaryNavbarSpecific from '../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import ViewManagement from '../../../UI/templates/ViewManagement';

export default function RolesAndPermissions() {
	const { organization_user_id, app_user } = localStorage;
	const [users, setUsers] = useState([{}]);
	const { t } = useTranslation();

	useEffect(() => {
		fetchGetData(
			`${ENDPOINTS_ORGANIZATION.GET_USERS_ORGANIZATION}${organization_user_id}/`
		).then((data) => setUsers(data));
		fetchPostDataWithUser(`${ENDPOINTS_APPUSER.GET_APPUSER}${app_user}/`).catch(
			(error) => console.error(error)
		);
	}, [organization_user_id, app_user]);

	return (
		<ViewManagement>
			<div className='md:flex md:flex-col'>
				<SecondaryNavbarSpecific
					title={t('Configuración')}
					textColor='text-green-50'
					color='bg-green-50'
					hide='invisible'
				/>
				<div className='mx-auto w-fit-content md:hidden'>
					<TextMainTitle
						title={t('Configuración')}
						textColor='text-green-50'
						color='bg-green-50'
					/>
				</div>
				<div className='w-fit-content px-6 pt-6'>
					<TextTitleSub title={t('RolesYPermisos')} textColor='text-green-50' />
					<UnderlineHr design='bg-green-50' />
				</div>
				<div className='px-6 pt-2'>
					<TextContentSecond text={t('MiraQuiénesPuedenTrabajar')} />
				</div>
				<AddRoleAndPermission design='pt-4' />

				<div className='w-fit-content px-6 py-6'>
					<TextTitleSub title={t('RolesAsignados')} textColor='text-green-50' />
					<UnderlineHr design='bg-green-50' />
				</div>
				{users.length === 0
					? ''
					: users.map((user) => (
							<div>
								<User
									key={user.id}
									userName={user.first_name}
									email={user.email}
									rol={user.role}
									appUserId={user.app_user_id}
									firstLetter={user.first_letter}
									date={user.date}
								/>
							</div>
					  ))}
			</div>
		</ViewManagement>
	);
}
