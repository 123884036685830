//3rd party components
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Play } from '../../assets/icons/Play.svg';

//BL
import { saveSectionURL } from '../../../BusinessLogic/redux/reducers/navigation';

//UI
import TextContentThird from '../../atoms/Text/Content/TextContentThird';
import { useDispatch } from 'react-redux';
import { ReactComponent as CheckIcon } from '../../assets/icons/Check.svg';
import { ReactComponent as RedCross } from '../../assets/icons/crossInRedCircle.svg';

/**
 * @description renders a document with a title below
 * @param {*} param0
 * @returns
 */

const VideoAndTitle = ({
	id,
	title,
	redirect,
	isPublic,
	src,
	saveLastSectionURL,
	documentHeight,
	fileSelectedPreview,
	addCheckToVideo,
	showDownloadOptions,
	showDeleteOption,
	deleteDocumentButton,
}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [videoActive, setVideoActive] = useState(false);

	let redirectPath = redirect ? `/${redirect}/${id}` : '';

	const toggleDownloadableDocument = () => {
		setVideoActive(!videoActive);
		addCheckToVideo(src, videoActive);
	};
	useEffect(() => {
		setVideoActive(false);
	}, [showDownloadOptions]);
	return (
		<div
			className='flex w-full items-center justify-between'
			onClick={() =>
				saveLastSectionURL && dispatch(saveSectionURL(location.pathname))
			}
		>
			<Link
				className='relative h-full w-full'
				to={
					showDownloadOptions || showDeleteOption
						? { pathname: '' }
						: { pathname: redirectPath }
				}
			>
				<div className=' relative flex flex-col items-center'>
					<div
						className={`flex w-full justify-center bg-black-50 ${
							fileSelectedPreview
								? ' border-4 border-solid border-yellow-50'
								: ''
						}`}
						style={{ height: `${documentHeight}px` }}
					>
						<Play className='absolute left-3 top-3' />
						<video src={src} className='p-1'></video>
					</div>
					{showDownloadOptions && (
						<div
							onClick={() => toggleDownloadableDocument()}
							className=' absolute top-0 right-0 flex h-full w-full  cursor-pointer select-none justify-end p-2 '
						>
							<div
								className={` m-2 flex h-1/6 w-1/6 justify-center rounded-full border-2 ${
									videoActive && 'bg-white'
								}`}
							>
								{videoActive && showDownloadOptions && (
									<p className='flex h-full w-full place-content-center rounded-full text-white'>
										<CheckIcon className='h-full w-full' />
									</p>
								)}
							</div>
						</div>
					)}
					{showDeleteOption && (
						<div
							onClick={() => deleteDocumentButton(id)}
							className=' absolute top-0 right-0 flex h-full w-full cursor-pointer select-none justify-end'
						>
							<div className={`m-2 flex h-1/6 w-1/6 justify-center`}>
								<RedCross className='h-full w-full' />
							</div>
						</div>
					)}
				</div>

				<div className='mt-2 mb-4 flex w-full items-center'>
					<TextContentThird
						design='text-grey-50 text-left line-clamp-2 pt-2 pr-1 words-break'
						text={title}
					/>
				</div>
			</Link>
		</div>
	);
};

VideoAndTitle.propTypes = {
	/* id of the document */
	id: PropTypes.number,
	/* document name identification */
	title: PropTypes.string,
	/* url redirect to specific document */
	redirect: PropTypes.string,
	/** Checks if the element is public*/
	isPublic: PropTypes.string,
	/**  bool that handle to save the actual specific location */
	saveLastSectionURL: PropTypes.bool,
	/* handles a dynamic width of the document */
	documentHeight: PropTypes.string,
	/*function that sends the image id that will be deleted */
	DeleteImageButton: PropTypes.func,
	/**  bool that handle the style if the file is selected */
	fileSelectedPreview: PropTypes.bool,
	/*function that sends the document id that will be deleted */
	deleteDocumentButton: PropTypes.func,
};

VideoAndTitle.defaultProps = {
	saveLastSectionURL: false,
	fileSelectedPreview: false,
};

export default VideoAndTitle;
