export default function FilterButton({
	Filter,
	data,
	filterArray,
	setFilterArray,
	filterChange,
	setFilterChange,
	nameFilter,
	setNameFilter,
}) {
	return (
		<div>
			<Filter
				filterArray={filterArray}
				setFilterArray={setFilterArray}
				collectionAll={data}
				filterChange={filterChange}
				setFilterChange={setFilterChange}
				nameFilter={nameFilter}
				setNameFilter={setNameFilter}
				showFilter={true}
			/>
		</div>
	);
}
