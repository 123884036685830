//3rd parties
import React from 'react';
import { PropTypes } from 'prop-types';

/**
 * @description render a circle with a letter in the center.
 * @returns
 */
export default function CirclePerfil({ design, initial }) {
	return <div className={`${design} circle-perfil`}>{initial}</div>;
}

CirclePerfil.propTypes = {
	/** a function that is trigger when the icon is clicked */
	initial: PropTypes.string,
	/** a string with tailwind styles */
	design: PropTypes.string,
};

CirclePerfil.defaultProps = {
	design:
		'bg-green-50 text-white rounded-full h-12 w-12 lg:h-14 lg:w-14 flex items-center justify-center',
};
