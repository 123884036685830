//3rd
import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { format, millisecondsToHours } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//BL
import { handleTimeZoneOffset } from '../../../BusinessLogic/helpers/dateFormats';

//UI
import CardCall from '../../molecules/Card/CardCall';
import CardOrganization from '../../molecules/Card/CardOrganization';
import { ReactComponent as BlackArrow } from '../../assets/icons/BlackArrow.svg';
import ButtonLinkSeeMore from '../../atoms/Buttons/Link/ButtonLinkSeeMore';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import { useGetAllPostulationsByCall } from '../../../BusinessLogic/hooks/query/useQueryPostulation';

/**
 * @description manages the preview of calls and its postulations.
 * @returns
 */
const CallsAndPostulationsPreview = ({ calls }) => {
	const { t } = useTranslation();
	const { appUser } = useSelector((state) => state.appUser);
	const { role_company } = appUser;
	const [currentCall, setCurrentCall] = useState(calls[0]);

	const { callPostulations } = useGetAllPostulationsByCall({
		role_company,
		callId: currentCall.id,
	});
	const callsLength = calls.length;
	const [currentCallIndex, setCurrentCallIndex] = useState(0);

	const showPostulations =
		Array.isArray(callPostulations) && callPostulations.length > 0;

	const daysLeftToCloseDate =
		currentCall.closes_at &&
		millisecondsToHours(new Date(currentCall.closes_at) - new Date()) / 24;

	const isCloseDateLessThanFourDays =
		daysLeftToCloseDate && daysLeftToCloseDate <= 4;

	const handleIndexChange = (newIndex) => {
		setCurrentCall(calls[newIndex]);
		setCurrentCallIndex(newIndex);
	};

	return (
		<div className='flex w-full flex-col'>
			<div className='my-5 flex w-full flex-col justify-between gap-2 sm:flex-row'>
				<TextTitleSub
					design={'text-purple-50'}
					title={t('ComoVanTusConvocatorias')}
				/>
				<ButtonLinkSeeMore
					link={'/convocatorias'}
					design='border-solid border border-black-50 text-black-50 rounded-md hover:bg-light_purple-50  py-1 mt-4 sm:mt-auto'
				/>
			</div>
			<div className='flex w-full flex-col gap-2 border p-4'>
				<div className='flex w-full flex-col gap-2 md:flex-row'>
					<div className='flex w-full md:w-2/5'>
						<CardCall
							key={currentCall.id}
							id={currentCall.id}
							slug={currentCall.route_slug}
							organizationName={currentCall.organization_name}
							organizationMainPictureUrl={currentCall.call_organization_image}
							callName={currentCall.name}
							city={currentCall.city}
							country={currentCall.country}
							callMainPictureUrl={currentCall.main_picture_url}
							closeDate={currentCall.closes_at}
							category={currentCall.category}
							technique={currentCall.technique}
							status={currentCall.status}
							description={currentCall.sub_header}
							hasAlliance={currentCall.has_datarte_alliance}
						/>
					</div>
					<div className='flex w-full flex-col px-0 py-4 md:w-3/5 md:px-4 md:py-0'>
						<div className='mb-2 border-b-2 border-purple-50 pb-2'>
							<TextTitleSub
								design={'text-purple-50'}
								title={t('PostulacionesRecibidas')}
							/>
						</div>
						<div className='flex max-h-[32rem] w-full flex-col overflow-y-auto'>
							{!showPostulations && (
								<TextContentSecond
									text={t('LoSentimosTodavíaNoTienesPostulaciones')}
									design={'font-light my-2'}
								/>
							)}
							{
								//FIX ME
								//previousPage key is being save in locale storage to redirect postulations correctly. This should be managed by Redux.
								//This implementation is also in the single call postulations view.
							}
							{showPostulations &&
								callPostulations.map((postulation, index) => (
									<div
										key={index}
										onClick={() => {
											sessionStorage.setItem('previousPage', 'singleCall');
										}}
									>
										<CardOrganization
											name={postulation.organization_user_name}
											status={postulation.status}
											image={postulation.organization_main_picture_id}
											date={format(
												new Date(
													handleTimeZoneOffset(postulation.status_updated_at) ??
														postulation.created_at
												),
												'dd/MM/yyyy'
											)}
											redirect={'postulaciones'}
											id={postulation.id}
										/>
									</div>
								))}
						</div>
						{isCloseDateLessThanFourDays && daysLeftToCloseDate >= 0 && (
							<TextContentSecond
								design={'font-light my-2'}
								text={
									<>
										<span className='font-semibold text-red-500'>
											{'Recuerda: '}
										</span>
										{`Faltan ${Math.round(
											daysLeftToCloseDate
										)} días para el cierre de esta convocatoria`}
									</>
								}
							/>
						)}
					</div>
				</div>
				<div
					className={`mt-2 flex w-full flex-row py-2 ${
						currentCallIndex === 0 ? 'justify-end' : 'justify-between'
					}`}
				>
					{currentCallIndex > 0 && (
						<div
							className='flex w-fit-content cursor-pointer flex-row items-center justify-between gap-3'
							onClick={() => handleIndexChange(currentCallIndex - 1)}
						>
							<BlackArrow className='rotate-180' />
							<TextContentSecond
								text={t('AnteriorConvocatoria')}
								design={'w-20'}
							/>
						</div>
					)}
					{currentCallIndex < callsLength - 1 && (
						<div
							className='flex w-fit-content cursor-pointer flex-row items-center gap-3'
							onClick={() => handleIndexChange(currentCallIndex + 1)}
						>
							<TextContentSecond
								text={t('SiguienteConvocatoria')}
								design={'w-20'}
							/>
							<BlackArrow />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

CallsAndPostulationsPreview.propTypes = {
	//Array with the calls data
	calls: PropTypes.array,
};

export default CallsAndPostulationsPreview;
