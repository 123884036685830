// 3rd party
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

/**
 * @description render the mainlogo in desktop, receives the logo and container design.
 * @returns
 */
export default function MainLogoDesktop({ image, alt, design, action }) {
	const appUser = useSelector((state) => state.appUser.appUser);

	return (
		<div className={design} onClick={action}>
			<Link to={appUser.token ? '/' : '/iniciar-sesion'}>
				<figure className='flex w-24 cursor-pointer'>
					<img src={image} alt={alt} />
				</figure>
			</Link>
		</div>
	);
}

MainLogoDesktop.propTypes = {
	/** logo svg in react element */
	image: PropTypes.string,
	/** name of the image or short name */
	alt: PropTypes.string,
	/** string tailwind design of the container */
	section: PropTypes.string,
	/* received function to do something, like a close a menu */
	action: PropTypes.func,
};

MainLogoDesktop.defaultProps = {
	design: '',
};
