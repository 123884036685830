import * as Yup from 'yup';

const userProfileTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'IdentificaciónUsuario',
				type: 'labels',
				field: 'identification',
				information: [
					{ label: 'Nombres', value: '', field: 'first_name' },
					{ label: 'Apellidos', value: '', field: 'last_name' },
					{ label: 'Email', value: '', field: 'email' },
				],
			},
		],
	},
	{
		tab: 'Contraseña',
		type: 'Password',
		content: [
			{
				title: 'Contraseña',
				type: 'password',
				field: 'password',
				information: [
					{
						label: 'Contraseña',
						value: '',
						field: 'password',
					},
					{
						label: 'Nueva contraseña',
						value: '',
						field: 'new_password',
					},
					{
						label: 'Confirmar nueva contraseña',
						value: '',
						field: 'confirm_password',
					},
				],
			},
		],
	},
	{
		tab: 'Planes',
		type: 'Plans',
		content: [
			{
				title: 'Planes',
				type: 'plans',
				field: 'plans',
			},
		],
	},
];

const signupFormInitialValues = {
	first_name: '',
	last_name: '',
	country_id: 47,
	email: '',
	password: '',
	confirm_password: '',
};

/* 
	{
		title: 'InformaciónUsuario',
		type: 'labels',
		field: 'information',
		information: [
			{ label: 'País', value: '', field: 'country' },
			{ label: 'Teléfono', value: '', field: 'phone_number' },
			{ label: 'Celular', value: '', field: 'cell_phone' },
			{ label: 'Whatsapp', value: '', field: 'whatsapp_contact' },
			{ label: 'Instagram', value: '', field: 'instagram_contact' },
			{ label: 'Linkedin', value: '', field: 'linkedin_contact' },
			{ label: 'Twitter', value: '', field: 'twitter_contact' },
			{ label: 'PáginaWeb', value: '', field: 'website_contact' },
			{ label: 'Dirección', value: '', field: 'address' },
			{ label: 'Compañía', value: '', field: 'company' },
			{ label: 'Moneda', value: '', field: 'currency' },
		],
	}, 
*/

const buttonsUserProfile = ['Identificación', 'Contraseña'];
const buttonsUserProfileArtist = ['Identificación', 'Contraseña', 'Planes'];

const artistSignupFormValidationSchema = Yup.object({
	first_name: Yup.string().required('PrimerNombreRequerido'),
	last_name: Yup.string().required('ApellidoRequerido'),
	email: Yup.string()
		.required('EmailRequeridoRegistro')
		.email('EmailValidación'),
	password: Yup.string()
		.required('ContraseñaRequeridaRegistro')
		.min(8, 'ContraseñaMínimoCaracteres'),
	confirm_password: Yup.string()
		.required('ContraseñaConfirmación')
		.when('password', (password, schema) => {
			return schema.test({
				test: (confirm_password) => confirm_password === password,
				message: 'ContraseñaNoConcuerda',
			});
		}),
});

const gallerySignupFormValidationSchema = Yup.object({
	first_name: Yup.string().required('PrimerNombreRequerido'),
	email: Yup.string()
		.required('EmailRequeridoRegistro')
		.email('EmailValidación'),
	password: Yup.string()
		.required('ContraseñaRequeridaRegistro')
		.min(8, 'ContraseñaMínimoCaracteres'),
	confirm_password: Yup.string()
		.required('ContraseñaConfirmación')
		.when('password', (password, schema) => {
			return schema.test({
				test: (confirm_password) => confirm_password === password,
				message: 'ContraseñaNoConcuerda',
			});
		}),
});

const userProfileValidationSchema = [
	//this needs to have the same order than the buttons
	[
		//and the same order than the appearance of the content sections
		//far from elegant
		{
			first_name: Yup.string()
				.min(2, 'Se requieren mínimo 2 caracteres.')
				.required('Se require agregar un nombre.'),
			last_name: Yup.string()
				.min(2, 'Se requieren mínimo 2 caracteres.')
				.required('Se require agregar un apellido.'),
			email: Yup.string()
				.email('Se debe ingresar un correo válido.')
				.nullable(),
		},
		{
			country: Yup.string()
				.min(2, 'Se requieren mínimo 2 caracteres.')
				.nullable(),
			phone_number: Yup.string()
				.min(7, 'Se requiere un teléfono válido.')
				.nullable(),
			cell_phone: Yup.string()
				.min(7, 'Se requiere un teléfono válido.')
				.nullable(),
			address: Yup.string()
				.min(2, 'Se requieren mínimo 2 caracteres.')
				.nullable(),
			currency: Yup.string()
				.min(2, 'Se requieren mínimo 2 caracteres.')
				.nullable(),
		},
	],
];

export {
	buttonsUserProfileArtist,
	buttonsUserProfile,
	userProfileTabs,
	userProfileValidationSchema,
	signupFormInitialValues,
	artistSignupFormValidationSchema,
	gallerySignupFormValidationSchema,
};
