//3rd party
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//BL
import { getPortfolios } from '../../../BusinessLogic/redux/reducers/portfolio';

//UI
import PortfolioLoading from '../../../UI/organisms/Loading/PortfolioLoading';
import PortfolioConfirmation from '../../../UI/templates/postulation/PortfolioConfirmation';
import PortfolioSelection from '../../../UI/templates/postulation/PortfolioSelection';
import { useParams } from 'react-router-dom';
import { useGetPostulationSingle } from '../../../BusinessLogic/hooks/query/useQueryPostulation';

/**
 * @description renders the view of selecting a postulation portfolio
 * @returns
 */
const PostulationPortfolio = () => {
	const { id: postulationId } = useParams();
	const dispatch = useDispatch();
	const { portfolios } = useSelector((state) => state.portfolios);
	const { postulationSingle, isLoading } =
		useGetPostulationSingle(postulationId);

	const { appUser } = useSelector((state) => state.appUser);
	const isGalleryUser = appUser.role_company === 'GALLERY';

	const selectedPortfolioSlug =
		postulationSingle?.portfolios[postulationSingle?.portfolios?.length - 1]
			?.portfolio_slug;
	const [portfolioView, setPortfolioView] = useState(
		selectedPortfolioSlug ? 'confirmationView' : 'selectionView'
	);
	const [preselectedPortfolio, setPreselectedPortfolio] = useState({
		slug: selectedPortfolioSlug,
	});

	useEffect(() => {
		if (!portfolios) {
			dispatch(getPortfolios());
		}
	}, [dispatch, portfolios]);

	useEffect(() => {
		if (portfolios && selectedPortfolioSlug) {
			setPreselectedPortfolio(
				portfolios.find((portfolio) => portfolio.slug === selectedPortfolioSlug)
			);
		}
	}, [portfolios, selectedPortfolioSlug]);

	if (isLoading) {
		return <PortfolioLoading />;
	}

	return (
		<>
			{!portfolios && <PortfolioLoading />}
			{portfolios && portfolioView === 'selectionView' && (
				<PortfolioSelection
					preselectedPortfolio={preselectedPortfolio}
					portfolios={
						isGalleryUser
							? portfolios.filter(
									({ artist_id }) => postulationSingle.artist_id === artist_id
							  )
							: portfolios
					}
					postulationSingle={postulationSingle}
				/>
			)}
			{portfolios && portfolioView === 'confirmationView' && (
				<PortfolioConfirmation
					setPortfolioView={setPortfolioView}
					preselectedPortfolio={preselectedPortfolio}
				/>
			)}
		</>
	);
};

export default PostulationPortfolio;
