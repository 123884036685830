//3rd parties
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

//reducers
import onboardingReducer from '../reducers/onboarding';
import artistReducer from '../reducers/artist';
import artworkReducer from '../reducers/artwork';
import artistFilterReducer from '../reducers/artistFilter';
import documentReducer from '../reducers/document';
import collectionReducer from '../reducers/collection';
import contactReducer from '../reducers/contact';
import ImageSlice from '../reducers/createMainImage';
import ImageReducer from '../reducers/createImage';
import locationsGeneralReducer from '../reducers/locationGeneral';
import locationsRoomReducer from '../reducers/locationRoom';
import lastLocationReducer from '../reducers/lastLocation';
import ArtworkImageSlice from '../reducers/artworkImage';
import fileListSlice from '../reducers/album';
import portfolioReducer from '../reducers/portfolio';
import appUserReduces from '../reducers/appUser';
import ElementSliceReducer from '../reducers/deleteElement';
import callReducer from '../reducers/call';
import postulationReducer from '../reducers/postulation';
import filterSliceReducer from '../reducers/filter';
import navigationReducer from '../reducers/navigation';
import expositionReducer from '../reducers/exposition';
import editablePortfolioReducer from '../reducers/editablePortfolio';
import portfolioV2Reducer from '../reducers/portfolioV2';
import staticData from '../reducers/staticData';
import calendarReducer from '../reducers/calendar';
import resourcesReducer from '../reducers/resources';
import routesHistory from '../reducers/routesHistory';

const reducers = combineReducers({
	artworkImage: ArtworkImageSlice,
	album: fileListSlice,
	appUser: appUserReduces,
	artists: artistReducer,
	artistFilter: artistFilterReducer,
	artworks: artworkReducer,
	call: callReducer,
	collections: collectionReducer,
	contacts: contactReducer,
	createImage: ImageSlice,
	image: ImageReducer,
	deleteElement: ElementSliceReducer,
	documents: documentReducer,
	editablePortfolio: editablePortfolioReducer,
	expositions: expositionReducer,
	filter: filterSliceReducer,
	locationsGeneral: locationsGeneralReducer,
	locationsRoom: locationsRoomReducer,
	lastLocations: lastLocationReducer,
	navigation: navigationReducer,
	onboarding: onboardingReducer,
	postulation: postulationReducer,
	portfolios: portfolioReducer,
	portfolioV2: portfolioV2Reducer,
	staticData: staticData,
	calendar: calendarReducer,
	resources: resourcesReducer,
	routesHistory: routesHistory,
});

const persistConfig = {
	key: 'root',
	storage: storage,
	//whitelist:['login']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
});

export default store;
