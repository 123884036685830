//3rd parties
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

/**
 * @description Full width video banner
 */

export default function VideoBanner({ url }) {
	return (
		<div className='mx-4 mb-4 pt-6 md:mx-16'>
			<ReactPlayer url={url} width='100%' height='100%' controls={true} />
		</div>
	);
}

VideoBanner.propTypes = {
	/** Url of the video */
	url: PropTypes.string,
};
