import { createSlice } from '@reduxjs/toolkit';
/* import { ENDPOINTS_COLLECTION } from '../../helpers/routes' */

const initialState = {
	artistList: [],
	artworkList: [],
	documentsList: [],
	countSelect: 0,
	status: null,
	currentTypeOfFile: '',
	selectedArtworks: [],
	selectedDocuments: [],
	typeOfFileInAlbumFiles: undefined,
};

export const fileListSlice = createSlice({
	name: 'album',
	initialState,
	reducers: {
		clearArtistList: (state) => {
			state.artistList = [];
		},
		clearCounter: (state, action) => {
			state.countSelect = action.payload;
		},
		clearArtworkList: (state) => {
			state.artworkList = [];
		},
		clearDocumentList: (state) => {
			state.documentsList = [];
		},
		counterFiles: (state) => {
			state.countSelect =
				state.artistList.length +
				state.artworkList.length +
				state.documentsList.length;
		},
		selectionFiles: (state, action) => {
			const { id } = action.payload;
			const { list } = action.payload;
			const artistArray = state.artistList;
			const artworkArray = state.artworkList;
			const documentArray = state.documentsList;
			switch (list) {
				case 'Artistas add':
					artistArray.push(id);
					state.artistList = [...state.artistList];
					break;

				case 'Artistas delete':
					let deleteArtist = artistArray.indexOf(id);
					artistArray.splice(deleteArtist, 1);
					state.artistList = [...state.artistList];
					break;

				case 'Obras add':
					artworkArray.push(id);
					state.artworkList = [...state.artworkList];
					break;

				case 'Obras delete':
					let deleteArtwork = artworkArray.indexOf(id);
					artworkArray.splice(deleteArtwork, 1);
					state.artworkList = [...state.artworkList];
					break;

				case 'Documentos add':
					documentArray.push(id);
					state.documentsList = [...state.documentsList];
					break;

				case 'Documentos delete':
					let deleteDocument = documentArray.indexOf(id);
					documentArray.splice(deleteDocument, 1);
					state.documentsList = [...state.documentsList];
					break;

				default:
					break;
			}
		},
		setTitleInAlbumFiles: (state, { payload }) => {
			state.typeOfFileInAlbumFiles = payload;
		},
		setSelectedArtworks: (state, { payload }) => {
			state.selectedArtworks = payload;
		},
		setSelectedDocuments: (state, { payload }) => {
			state.selectedDocuments = payload;
		},
		clearFilesInAlbum: (state, { payload }) => {
			state.selectedArtworks = [];
			state.selectedDocuments = [];
		},
	},
});

export const {
	clearArtistList,
	clearArtworkList,
	counterFiles,
	selectionFiles,
	clearCounter,
	clearDocumentList,
	setTitleInAlbumFiles,
	setSelectedArtworks,
	setSelectedDocuments,
	clearFilesInAlbum,
} = fileListSlice.actions;

export default fileListSlice.reducer;
