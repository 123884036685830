import { submitErrorToSlack } from '../helpers/SlackIntegrations';

/* body: JSON.stringify({'organization_user_id': values}), */
export const fetchGetDataWithValues = async (url, data) => {
	const { token } = localStorage;
	let endpoint = '';
	return fetch(url, {
		method: 'POST',
		cache: 'no-cache',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
		.then(async (res) => {
			const response = await res.json();
			endpoint = res.url;
			if (res.status >= 500) {
				submitErrorToSlack(endpoint, response, 'POST');
			}
			return response;
		})
		.catch((error) => {
			if (error.response.status >= 500) {
				submitErrorToSlack(endpoint, error, 'GET');
			}
		});
};
