/* -- Third Party -- */
/* -- this needs to be refactores -- */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/* BL */
import {
	addConfigurationInput,
	removeConfiguration,
} from '../../../BusinessLogic/redux/reducers/artistFilter';

/* UI */
import CloseIcon from '@material-ui/icons/Close';
import Buscar from '../../../UI/assets/icons/Buscar.svg';
import CircleFilter from '../../../UI/assets/icons/CircleFilter.svg';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';

function FilterInput({
	setNameFilter,
	setShowFilter,
	nameFilter,
	filterLabel,
}) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(addConfigurationInput(nameFilter));
	}, [dispatch, nameFilter]);

	return (
		<div className='w-full'>
			<div className='flex justify-between'>
				<div
					className={
						filterLabel === 'Nombre'
							? 'mb-6 text-4xl font-bold'
							: 'expandedFilter__title mb-6  text-4xl'
					}
				>
					{filterLabel === 'Nombre' ? 'Buscar' : filterLabel}
				</div>
				{filterLabel === 'Nombre' ? (
					<div onClick={() => setShowFilter(false)}>
						<CloseIcon />
					</div>
				) : (
					<div onClick={() => setShowFilter(false)}>
						<CloseIcon />
					</div>
				)}
			</div>
			<div className='flex justify-between lg:justify-start'>
				<figure className='lg:mr-4'>
					<img src={Buscar} alt={t('BuscarEnDatarte')} />
				</figure>
				<input
					className='w-11/12 border '
					placeholder={t('BuscarEnDatarte')}
					type='text'
					value={nameFilter}
					onChange={(e) => setNameFilter(e.target.value)}
				></input>
			</div>
		</div>
	);
}

function ClickableChip({
	chipSelected,
	filterArray,
	valueToFilter,
	setFilterArray,
	setFilterChange,
	filterChange,
	label,
	value,
}) {
	const [clicked, setClicked] = useState(chipSelected);

	function clickOnChip(e) {
		if (!clicked) {
			let newFilter = filterArray;
			if (newFilter[valueToFilter] === undefined) {
				//newFilter[valueToFilter] = [e.target.innerHTML]
				newFilter[valueToFilter] = [e.target.id];
			} else {
				newFilter[valueToFilter].push(e.target.id);
			}
			setFilterArray(newFilter);
		} else {
			let newFilter = filterArray;
			let indexToDelete = newFilter[valueToFilter].indexOf(e.target.id);
			newFilter[valueToFilter].splice(indexToDelete, 1);
			//delete null elements
			Object.keys(newFilter).forEach((key) => {
				if (newFilter[key].length === 0) {
					delete newFilter[key];
				}
			});

			setFilterArray(newFilter);
		}
		setClicked(!clicked);
		setFilterChange(filterChange + 1);
	}

	return (
		<div className='mb-4 mr-4 flex items-center'>
			<input
				onClick={(e) => {
					clickOnChip(e);
				}}
				type='radio'
				id={label}
				className='hidden'
				checked={clicked}
			></input>
			<label
				for={value}
				className='body-Text2 flex cursor-pointer items-center'
			>
				<span
					id={label}
					onClick={(e) => {
						clickOnChip(e);
					}}
					htmlFor={label}
					className='border-grey mr-1 inline-block h-4 w-4 border'
				></span>

				<h5
					className='text-base font-light'
					onClick={(e) => {
						clickOnChip(e);
					}}
					id={label}
				>
					{label}
				</h5>
			</label>
		</div>
	);
}

function FilterChip({
	collectionAll,
	filterVariable,
	filterChange,
	setFilterArray,
	filterLabel,
	filterArray,
	setFilterChange,
}) {
	const [dropdown, setDropdown] = useState(false);
	const [filterChipInput, setFilterChipInput] = useState('');

	let unique = [];

	// add condition to manage when the element arrives null
	if (collectionAll.code !== 0) {
		unique = [
			...new Set(collectionAll.map((item) => item[filterVariable])),
		].sort();
	}

	function checkIfInFilter({ val, filterArray, filterVariable }) {
		//function used to check the state of the filter
		let y = false;
		if (typeof filterArray !== 'undefined') {
			if (Object.keys(filterArray).includes(filterVariable)) {
				y = filterArray[filterVariable].includes(val);
			}
		}
		return y;
	}
	const chips = unique
		.filter((collection) => {
			if (collection === null || collection === undefined) {
				return '';
			} else {
				return collection.indexOf(filterChipInput) >= 0;
			}
		})
		.map((val) => (
			<ClickableChip
				label={val}
				filterArray={filterArray}
				setFilterArray={setFilterArray}
				valueToFilter={filterVariable}
				filterChange={filterChange}
				setFilterChange={setFilterChange}
				chipSelected={checkIfInFilter(val, filterArray, filterVariable)}
			/>
		));
	return (
		<div className=''>
			<TextTitleSub title={filterLabel} textColor='text-green-50' />
			<div className='max-h-52 overflow-x-auto '>
				<input
					className='my-4 w-full border-2 border-green-50 	'
					type='text'
					onClick={() => setDropdown(!dropdown)}
					value={filterChipInput}
					onChange={(e) => {
						setFilterChipInput(e.target.value);
					}}
				></input>
				<div className='container__chipDropdown'>{dropdown ? chips : ''}</div>
			</div>
		</div>
	);
}

function FilterTags({
	collectionAll,
	filterVariable,
	filterArray,
	setFilterArray,
	setFilterChange,
	filterChange,
	filterLabel,
}) {
	const initUnique = [
		...new Set(collectionAll.map((item) => item[filterVariable])),
	].sort();
	const uniqueInt = [];

	for (let i = 0; i < initUnique.length; i++) {
		let splitValues = initUnique[i].split(',');
		if (splitValues.length > 1) {
			for (let y = 0; y < splitValues.length; y++) {
				uniqueInt.push(splitValues[y]);
			}
		} else {
			uniqueInt.push(initUnique[i]);
		}
	}
	const unique = [...new Set(uniqueInt)].sort();

	/*     let keys = {}
        if (typeof props.filterArray !== 'undefined') {
            keys = Object.keys(props.filterArray)
        } */

	function checkIfInFilter(val, filterArray, filterVariable) {
		//function used to check the state of the filer
		let y = false;
		if (typeof filterArray !== 'undefined') {
			if (Object.keys(filterArray).includes(filterVariable)) {
				y = filterArray[filterVariable].includes(val);
			}
		}
		return y;
	}

	const chips = unique.map((val) => (
		<ClickableChip
			label={val}
			filterArray={filterArray}
			setFilterArray={setFilterArray}
			valueToFilter={filterVariable}
			filterChange={filterChange}
			setFilterChange={setFilterChange}
			chipSelected={checkIfInFilter(val, filterArray, filterVariable)}
		/>
	));
	return (
		<div>
			<div className='expandedFilter__title'>{filterLabel}</div>
			<div className='container__chip'>{chips}</div>
		</div>
	);
}

export const Filter = React.memo(
	({
		setFilterArray,
		setFilterChange,
		filterChange,
		filterSettings,
		nameFilter,
		setNameFilter,
		filterArray,
		collectionAll,
	}) => {
		const [showFilter, setShowFilter] = useState(false);

		const expandedFilter = (
			<div className='expandedFilter'>
				<div className='expandedFilter__header'>
					<div
						className='expandedFilter__erase'
						onClick={() => {
							setFilterArray({});
							setFilterChange(filterChange + 1);
							setShowFilter(false);
						}}
					></div>
				</div>
				{filterSettings.map((filterSetting) => {
					switch (filterSetting.filterType) {
						case 'input':
							return (
								<FilterInput
									filterLabel={filterSetting.filterLabel}
									filterVariable={filterSetting.filterVariable}
									nameFilter={nameFilter}
									setNameFilter={setNameFilter}
									setShowFilter={setShowFilter}
								/>
							);
						case 'chip':
							return (
								<FilterChip
									filterLabel={filterSetting.filterLabel}
									filterVariable={filterSetting.filterVariable}
									filterArray={filterArray}
									setFilterArray={setFilterArray}
									collectionAll={collectionAll}
									filterChange={filterChange}
									setFilterChange={setFilterChange}
									nameFilter={nameFilter}
									setNameFilter={setNameFilter}
								/>
							);
						default:
							return '';
					}
				})}
			</div>
		);

		return (
			<>
				<svg
					className='CardUx__Filter'
					onClick={() => setShowFilter(true)}
					width='32'
					height='32'
					viewBox='0 0 32 32'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M5.26172 6H26.7406C26.9343 6 27.1239 6.05627 27.2863 6.16198C27.4486 6.26769 27.5768 6.41828 27.6551 6.59545C27.7335 6.77262 27.7587 6.96874 27.7277 7.15997C27.6967 7.35121 27.6109 7.52932 27.4806 7.67267L19.2612 16.7139C19.0939 16.898 19.0012 17.1378 19.0012 17.3866V24.4648C19.0012 24.6294 18.9605 24.7915 18.8828 24.9367C18.8052 25.0818 18.6928 25.2055 18.5559 25.2969L14.5559 27.9635C14.4053 28.0639 14.2302 28.1216 14.0495 28.1303C13.8687 28.1391 13.6889 28.0986 13.5293 28.0132C13.3697 27.9278 13.2363 27.8006 13.1433 27.6454C13.0503 27.4901 13.0012 27.3125 13.0012 27.1315V17.3866C13.0012 17.1378 12.9084 16.898 12.7411 16.7139L4.52178 7.67267C4.39146 7.52932 4.30559 7.35121 4.2746 7.15997C4.24361 6.96874 4.26883 6.77262 4.34721 6.59545C4.42558 6.41828 4.55373 6.26769 4.71608 6.16198C4.87843 6.05627 5.06799 6 5.26172 6V6Z'
						stroke='black'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
				{showFilter ? expandedFilter : ''}
			</>
		);
	}
);

//functions
function multiPropsFilters(array, filters) {
	if (array.message) {
		return '';
	} else {
		const filterKeys = Object.keys(filters);
		return array?.filter((item) => {
			//validates all filter criteria
			return filterKeys?.every((key) => {
				return filters[key].includes(item[key]);
			});
		});
	}
}

//functions filtros Activos para ver lo que se va filtrando
function ActiveFilters({
	filterArray,
	filterChange,
	setFilterArray,
	setFilterChange,
}) {
	const dispatch = useDispatch();
	//transform filter array into list
	function get_list_from_props(dict) {
		let key, val;
		let arr = [];
		for (key in dict) {
			for (val in dict[key]) {
				arr.push([key, dict[key][val]]);
			}
		}
		return arr;
	}
	const [list_ActiveFilters, setList_ActiveFilters] = useState(
		get_list_from_props(filterArray)
	);

	useEffect(() => {
		setList_ActiveFilters(get_list_from_props(filterArray));
	}, [filterArray, filterChange]);

	function cleanFilters() {
		setFilterArray({});
		setFilterChange(filterChange + 1);
	}

	function removeSingleFilterValue(e) {
		let newFilter = filterArray;
		let type = e.target.getAttribute('type');
		let value = e.target.getAttribute('value');
		let indexToDelete = newFilter[type].indexOf(value);
		newFilter = JSON.parse(JSON.stringify(newFilter));
		newFilter[type].splice(indexToDelete, 1);
		//delete null elements
		Object.keys(newFilter).forEach((key) => {
			if (newFilter[key].length === 0) {
				delete newFilter[key];
			}
		});

		setFilterArray(newFilter);

		setFilterChange(filterChange + 1);
	}
	const { t } = useTranslation();
	return (
		<div>
			<div className='flex justify-between text-xs'>
				<h3 className='ActiveFilter__h3 mt-2'>{t('FILTROS')}</h3>
				<h3
					className='ActiveFilter__h3 mt-2 cursor-pointer'
					onClick={() => {
						cleanFilters();
						dispatch(removeConfiguration());
					}}
				>
					{t('LIMPIAR')}
				</h3>
			</div>
			<div className='my-4 flex flex-wrap'>
				{list_ActiveFilters.map((val) => (
					<div className='border-black flex rounded-full border bg-black-50 px-3 text-white'>
						<h4 className=''>{val[1]}</h4>
						<div className=''>
							<img
								className='ml-4 w-6 text-white'
								src={CircleFilter}
								type={val[0]}
								value={val[1]}
								onClick={(e) => {
									removeSingleFilterValue(e);
								}}
								alt='icono de filtro'
							/>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export {
	multiPropsFilters,
	FilterTags,
	FilterInput,
	FilterChip,
	ActiveFilters,
};
