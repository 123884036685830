//3rd pary
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

//BL

/* UI */
import ButtonLinkBack from '../../atoms/Buttons/Link/ButtonLinkBack';
import ButtonLinkShare from '../../atoms/Buttons/Link/ButtonLinkShare';
import TextMainTitle from '../../molecules/Text/TextMainTitle';
import { ReactComponent as DotsVertical } from '../../assets/icons/DotsVertical.svg';
import ButtonWithIcon from '../../molecules/Buttons/WithIcon/ButtonWithIcon';
import PopupSmall from '../PopUp/PopupSmall';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import ButtonLinkBackText from '../../molecules/Buttons/Link/ButtonLinkBackText';

/**
 * @description Secondary navbar used in the specific section (when there's a single element).
 * @param {*} param0
 * @returns
 */

export default function SecondaryNavbarSpecific({
	design,
	title,
	color,
	hide,
	textColor,
	redirect,
	popUpShare,
	setPopUpShare,
	isPublic,
	textPopup,
	popupIcon,
	textBack,
	textBackDesign,
	arrowLeftColor,
	backAction,
}) {
	const [dotOption, setDotOption] = useState(false);
	const { t } = useTranslation();
	return (
		<div
			className={`flex h-20 w-full items-center justify-between md:h-32 ${design}`}
		>
			<ButtonLinkBack
				design='md:hidden cursor-pointer w-1/5'
				redirect={redirect}
				action={backAction}
			/>
			<ButtonLinkBackText
				design='hidden md:block md:w-1/4'
				textBack={t(textBack)}
				textBackDesign={textBackDesign}
				redirect={redirect}
				arrowLeftColor={arrowLeftColor}
				action={backAction}
			/>
			<div className=''>
				<TextMainTitle title={title} color={color} textColor={textColor} />
			</div>
			<div className={`${hide} flex w-1/5 flex-row justify-end md:w-1/4`}>
				<ButtonWithIcon
					design='cursor-pointer'
					icon={<DotsVertical />}
					showBoolean={dotOption}
					setShowBoolean={setDotOption}
				/>
				<ButtonLinkShare
					redirect={redirect}
					popUpShare={popUpShare}
					setPopUpShare={setPopUpShare}
					isPublic={isPublic}
				/>
			</div>
			{dotOption && (
				<PopupSmall design='w-max bg-white shadow_box p-1 px-2 rounded-lg right-3 mt-3 pr-12'>
					<div className='flex w-full flex-row items-center justify-between'>
						<div className='flex justify-center'>
							<TextContentSecond text={textPopup} design='text-strong_red-50' />
						</div>
						<ButtonWithIcon
							icon={popupIcon}
							figureDesign='flex items-center justify-center'
						/>
					</div>
				</PopupSmall>
			)}
		</div>
	);
}

SecondaryNavbarSpecific.propTypes = {
	/** Tailwind className design modifier on the main container of this element.*/
	design: PropTypes.string,
	/** Title of the section */
	title: PropTypes.string,
	/** Color of the HR below the title. Updates the classname attribute  */
	color: PropTypes.string,
	/** Color of the text of the title. Updates the classname attribute  */
	textColor: PropTypes.string,
	/** Tailwind className of the shareButton. Use hidden to hide it.   */
	hide: PropTypes.string,
	/** If exists, redirects to this string. Otherwise, goes back on the history  */
	redirect: PropTypes.string,
	/* display popup with share feature */
	popUpShare: PropTypes.bool,
	/* handle to change value of popupshare */
	setPopUpShare: PropTypes.func,
	/** Is this section Public?*/
	isPublic: PropTypes.string,
	/* text next to the arrow to specify the section to return */
	textBack: PropTypes.string,
	/* styles apply to the text next to arrow figure */
	textBackDesign: PropTypes.string,
	/* determine the color of the arrowLeft asset */
	arrowLeftColor: PropTypes.string,
	/* action trigger when ButtonLinkBack is clicked */
	backAction: PropTypes.func,
};
