import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_DOCUMENT } from '../../helpers/routes';
import { fetchGetData } from '../../hooks/fetchGetData';
import {
	singleDocumentTabs,
	catalogueArtistSingleDocumentTabs,
} from '../../data/DocumentSingle2';
import { updateTabsObject } from '../../helpers/SingleViewHelpers';
import postDataAndFile from '../../helpers/postDataAndFile';
import postDataAndImage from '../../helpers/postDataAndImage';
import postDataAndVideo from '../../helpers/postDataAndVideo';
import { fetchPutArrayImages } from '../../hooks/fetchPutArrayImages';
import { fetchPutData } from '../../hooks/fetchPutData';

export const getDocuments = createAsyncThunk(
	'documents/fetchDocuments',
	async () => {
		const { organization_user_id } = localStorage;
		return await fetchGetData(
			`${ENDPOINTS_DOCUMENT.GET_DOCUMENTS}${organization_user_id}/`
		);
	}
);

export const getDocument = createAsyncThunk(
	'documents/fetchDocument',
	async (id) => {
		return await fetchGetData(`${ENDPOINTS_DOCUMENT.GET_DOCUMENT}${id}/`);
	}
);

export const getPublicDocument = createAsyncThunk(
	'documents/fetchDocument',
	async (id) => {
		return await fetchGetData(
			`${ENDPOINTS_DOCUMENT.GET_PUBLIC_DOCUMENT}${id}/`
		);
	}
);

export const putDocument = createAsyncThunk(
	'documents/putDocument',
	async (input) => {
		const { values, id } = input;

		return await fetchPutData(
			`${ENDPOINTS_DOCUMENT.PUT_DOCUMENT}${id}/`,
			values
		);
	}
);

export const postDocument = createAsyncThunk(
	'documents/postDocument',
	async (values) => {
		switch (values.file_type) {
			case 'DOCUMENT':
				return await postDataAndFile(ENDPOINTS_DOCUMENT.POST_DOCUMENT, values);
			case 'VIDEO':
				return await postDataAndVideo(ENDPOINTS_DOCUMENT.POST_DOCUMENT, values);
			default:
				return await postDataAndImage(ENDPOINTS_DOCUMENT.POST_DOCUMENT, values);
		}
	}
);

export const organizeImageGrid = createAsyncThunk(
	'documents/organizeImageGrid',
	async (values) => {
		return await fetchPutArrayImages(values);
	}
);

const initialStateDocumentTabs = singleDocumentTabs;
const catalogueArtistInitialStateDocumentTabs =
	catalogueArtistSingleDocumentTabs;
const initialSingleDocument = {
	name: 'Este es un nombre',
	description: 'Este es una descripción',
	artworks: [],
	pictures: [''],
	file_type: 'DOCUMENT',
	date: '23/03/2022',
	artwork: 'obra',
	artist: 'artista',
	document_url:
		'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/documents/5303',
};
const initialDocumentSingleTabs = updateTabsObject(
	singleDocumentTabs,
	initialSingleDocument
);
const catalogueArtistInitialDocumentSingleTabs = updateTabsObject(
	catalogueArtistSingleDocumentTabs,
	initialSingleDocument
);

const initialState = {
	documents: null,
	singleDocument: null,
	status: null,
	artists: [],
	artworks: [],
	initialStateTabs: initialStateDocumentTabs,
	catalogInitialStateTabs: catalogueArtistInitialStateDocumentTabs,
	documentSingleTabs: initialDocumentSingleTabs,
	catalogueArtistDocumentSingleTabs: catalogueArtistInitialDocumentSingleTabs,
};

export const documentSlice = createSlice({
	name: 'documents',
	initialState,
	reducers: {},
	extraReducers: {
		//get Documents
		[getDocuments.pending]: (state) => {
			state.status = 'loading';
		},
		[getDocuments.fulfilled]: (state, { payload }) => {
			state.documents = payload;
			state.status = 'success';
		},
		[getDocuments.rejected]: (state) => {
			state.status = 'failed';
		},
		//get Document
		[getDocument.pending]: (state) => {
			state.documentSingleTabs = updateTabsObject(
				state.initialStateTabs,
				initialSingleDocument
			);
			state.catalogueArtistDocumentSingleTabs = updateTabsObject(
				state.catalogInitialStateTabs,
				initialSingleDocument
			);
			state.status = 'loading';
		},
		[getDocument.fulfilled]: (state, { payload }) => {
			let Single = payload;
			state.singleDocument = Single;
			state.documentSingleTabs = updateTabsObject(
				state.documentSingleTabs,
				Single
			);

			state.catalogueArtistDocumentSingleTabs = updateTabsObject(
				state.catalogueArtistDocumentSingleTabs,
				Single
			);
			state.status = 'success';
		},
		[getDocument.rejected]: (state) => {
			state.status = 'failed';
		},
		//putDocument
		[putDocument.pending]: (state) => {
			state.status = 'loading';
		},
		[putDocument.fulfilled]: (state, { payload }) => {
			state.portfolio = payload;
			state.status = 'success';
		},
		[putDocument.rejected]: (state) => {
			state.status = 'failed';
		},
		//Post Artist
		[postDocument.pending]: (state) => {
			state.status = 'loading';
		},
		[postDocument.fulfilled]: (state) => {
			state.status = 'success';
		},
		[postDocument.rejected]: (state) => {
			state.status = 'failed';
		},

		// organize grid of Images
		[organizeImageGrid.pending]: (state) => {
			state.status = 'loading';
		},
		[organizeImageGrid.fulfilled]: (state) => {
			state.status = 'success';
		},
		[organizeImageGrid.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});

// export const {} = documentSlice.actions;

export default documentSlice.reducer;
