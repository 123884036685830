//3rd party
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PropTypes } from 'prop-types';
import 'react-lazy-load-image-component/src/effects/blur.css';

// UI
import { ReactComponent as ClosedCrossIcon } from '../../assets/icons/CrossWhite.svg';
import ReactPlayer from 'react-player';

/**
 * @description Modal to open images
 */

const ModalImage = ({
	mainPicture,
	name,
	showModalImage,
	setShowModalImage,
	isVideoImage,
}) => {
	const handlePopUpClickContainer = (e) => e.stopPropagation();

	if (showModalImage) {
		document.body.classList.add('overflow-hidden');
	}
	const hideModal = () => {
		document.body.classList.remove('overflow-hidden');
		setShowModalImage(false);
	};

	return (
		<div
			className='fixed right-0 top-0 z-20 my-auto flex h-full w-full cursor-pointer bg-black-50 bg-opacity-70'
			onClick={hideModal}
		>
			<div
				className='m-auto flex h-3/4 flex-col items-end md:flex-row md:items-center'
				onClick={handlePopUpClickContainer}
			>
				<div
					className='color-white right-20 top-20 mb-4 cursor-pointer md:absolute'
					onClick={hideModal}
				>
					<ClosedCrossIcon />
				</div>
				{isVideoImage ? (
					<ReactPlayer
						url={mainPicture}
						width={'100%'}
						height={'100%'}
						controls={true}
						light={true}
						className='aspect-square'
					/>
				) : (
					<LazyLoadImage
						alt={name}
						src={mainPicture}
						placeholderSrc={`${mainPicture}_small`}
						effect='blur'
						width='100%'
						height='100%'
						delayTime='0'
						threshold='300'
						className='max-h-full max-w-full cursor-default select-none'
					/>
				)}
			</div>
		</div>
	);
};

export default ModalImage;

ModalImage.propTypes = {
	/*Image within a portfolio*/
	mainPicture: PropTypes.string,
	/*Close modal of an image within the portfolio*/
	closeModalImage: PropTypes.func,
	/*Name of the image*/
	name: PropTypes.string,
	/*Open modal of an image within the portfolio*/
	openModalImg: PropTypes.bool,
};
