//3rd party
import { PropTypes } from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

//UI
import 'react-lazy-load-image-component/src/effects/blur.css';

/**
 * @description Component that has the Main image in the detailed View.
 * Has lazy loading
 * @param {*} param0
 * @returns
 */

export default function ImageMainSpecific({
	image,
	alt,
	wrapperClassName,
	width,
	height,
}) {
	return (
		<picture className='flex justify-center'>
			<LazyLoadImage
				alt={alt}
				src={image + '_medium'}
				width={width}
				height={height}
				effect='blur'
				placeholderSrc={image + '_small'}
				wrapperClassName={`lazyLoadingImages ${wrapperClassName}`}
				delayTime='0'
				treshold='800'
				style={{ objectFit: 'cover' }}
			/>
		</picture>
	);
}

ImageMainSpecific.propTypes = {
	/** URL of the main image */
	image: PropTypes.string,
	/** Alt of the image */
	alt: PropTypes.string,
	/** tailwind styles for the wrapper of the lazyloading image container */
	wrapperClassName: PropTypes.string,
	/** Width */
	width: PropTypes.number,
	/** Height */
	height: PropTypes.number,
};

ImageMainSpecific.defaultProps = {
	image:
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image',
};
