//3rd parties
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

//Interntal components
import AddIcon from '@material-ui/icons/Add';
import TextContentSecond from '../../Text/Content/TextContentSecond';

/**
 * @name ButtonFabPrincipalUrl
 * @description simple button to add an item
 * @param {*} param0
 * @returns
 */

export default function ButtonFabPrincipalUrl({
	label,
	url,
	style,
	icon,
	className,
	action,
}) {
	return (
		<div
			className={`fixed right-7 bottom-24 z-10 w-24 rounded bg-green-50 before:pointer-events-none before:absolute 
			before:inset-0 before:z-10 lg:cursor-pointer lg:before:h-full lg:before:w-full lg:hover:bg-[#bad1ce] lg:active:before:shadow-[inset_0px_4px_4px_1px_rgba(100,100,100,0.32)] ${className}`}
			style={style}
			onClick={() => action && action()}
		>
			<Link
				className='flex h-9 flex-row items-center justify-center'
				to={{ pathname: url }}
			>
				{icon && <AddIcon style={{ color: 'white' }} />}
				<TextContentSecond text={label} design={'normal-case text-white'} />
			</Link>
		</div>
	);
}

ButtonFabPrincipalUrl.propTypes = {
	/** Passes the text to the component */
	label: PropTypes.string,
	/** url to when click on the button*/
	url: PropTypes.string,
	/**style of the icon */
	style: PropTypes.string,
	/**icon add to the button */
	icon: PropTypes.bool,
	/** Action when click the button */
	action: PropTypes.func,
};

ButtonFabPrincipalUrl.defaultProps = {
	icon: false,
};
