//3rd party
import React from 'react';
import PropTypes from 'prop-types';

//UI
import TextContentThird from '../../atoms/Text/Content/TextContentThird';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';

/**
 * @description render a card with specific information
 * @param {*} param0
 * @returns
 */
function CardPostulation({ city, country, telephone, email }) {
	return (
		<div className='min-w-max '>
			{city && (
				<TextContentThird text={`${city}`} design='md:hidden block pb-3.5' />
			)}
			{country && (
				<TextContentThird text={`${country}`} design='md:hidden block pb-3.5' />
			)}
			{telephone && (
				<TextContentThird text={telephone} design='md:hidden block pb-3.5' />
			)}
			{email && (
				<TextContentThird text={email} design='md:hidden block pb-3.5' />
			)}
			{city && (
				<TextContentPrincipal
					text={`${city}`}
					design='hidden md:block pb-3.5'
					style={{ fontWeight: 300 }}
				/>
			)}
			{country && (
				<TextContentPrincipal
					text={`${country}`}
					design='hidden md:block pb-3.5'
					style={{ fontWeight: 300 }}
				/>
			)}
			{telephone && (
				<TextContentPrincipal
					text={telephone}
					design='hidden md:block pb-3.5'
					style={{ fontWeight: 300 }}
				/>
			)}
			{email && (
				<TextContentPrincipal
					text={email}
					design='hidden md:block pb-3.5'
					style={{ fontWeight: 300 }}
				/>
			)}
		</div>
	);
}

CardPostulation.propTypes = {
	/** country of the artist user */
	country: PropTypes.string,
	/** telephone of the artist user*/
	telephone: PropTypes.string,
	/** email of the artist user*/
	email: PropTypes.string,
};

export default CardPostulation;
