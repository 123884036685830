//3rd Party
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @description Element that is used as a secondary item
 * @param {*} param0
 * @returns
 */

export default function ButtonFabSecondary({
	icon,
	action,
	design,
	buttonDesign,
}) {
	return (
		<div className={design}>
			<button
				className={`z-10 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-green-50 ${buttonDesign}`}
				onClick={action}
			>
				<img src={icon} alt='pic_icon' />
			</button>
		</div>
	);
}

ButtonFabSecondary.propTypes = {
	/** Tailwind className that manages the main container */
	design: PropTypes.string,

	/** Source of the icon. Will it display in the img section*/
	icon: PropTypes.string,

	/** Function on click on the button of the icon */
	action: PropTypes.func,
};
