// 3rd Party
import React from 'react';
import PropTypes from 'prop-types';

// UI
import { ReactComponent as LoadingSpinner } from '../../assets/icons/LoadingSpinner.svg';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';

function LoadingText({ text }) {
	return (
		<div className='flex flex-row items-center justify-center'>
			<LoadingSpinner />
			<TextContentPrincipal
				text={text}
				design='text-green-50 text-opacity-100'
				style={{ fontWeight: '700' }}
			/>
		</div>
	);
}

LoadingText.propTypes = {
	/* message to show next to the spinner */
	text: PropTypes.string,
};

LoadingText.defaultProps = {
	text: 'Guardando',
};

export default LoadingText;
