import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/* BL */
import {
	ENDPOINTS_ARTWORK,
	ENDPOINTS_PERSON,
} from '../../../../BusinessLogic/helpers/routes';
import { fetchGetData } from '../../../../BusinessLogic/hooks/fetchGetData';
import {
	get_unique_fields_from_key_in_dict,
	get_unique_values_from_key_in_dict,
	get_values_equal_to_y_in_dict,
} from '../../../../BusinessLogic/helpers/artworkData';
import {
	artworkInitialValues,
	artworkFormValidationSchema,
} from '../../../../BusinessLogic/data/ArtworkSingleData';
import {
	getArtworks,
	postArtwork,
} from '../../../../BusinessLogic/redux/reducers/artwork';
import { backSectionURL } from '../../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../../BusinessLogic/helpers/textGoBackGenerator';
import { handleChangeDropdownBoolean } from '../../../../BusinessLogic/helpers/handleChangeDropdownBoolean';
import {
	getCollections,
	resetCollectionSingle,
} from '../../../../BusinessLogic/redux/reducers/collection';
import { getPortfolios } from '../../../../BusinessLogic/redux/reducers/portfolio';
import {
	getArtistDropdown,
	resetSingleArtist,
} from '../../../../BusinessLogic/redux/reducers/artist';
import {
	getCountries,
	getCurrencies,
} from '../../../../BusinessLogic/redux/reducers/staticData';

/* UI */
import whiteCheck from '../../../../UI/assets/icons/whiteCheck.svg';
import ListItemText from '../../../../UI/atoms/Inputs/ListItemText';
import FieldTextFormik from '../../../../UI/molecules/Field/FieldTextFormik';
import FieldTextAreaFormik from '../../../../UI/molecules/Field/FieldTextAreaFormik';
import SecondaryNavbarSpecific from '../../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import ImageSectionForm from '../../../../UI/organisms/Image/ImageSectionForm';
import SectionForm from '../../../../UI/organisms/Section/SectionForm';
import ButtonFabExtendedPrimary from '../../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import ViewForm from '../../../../UI/templates/ViewForm';
import LoadingText from '../../../../UI/molecules/Loading/LoadingText';
import ButtonWithTextIcon from '../../../../UI/molecules/Buttons/WithIcon/ButtonWithTextIcon';
import { ReactComponent as CaretDownBlack } from '../../../../UI/assets/icons/CaretDownBlack.svg';
import FieldSelectFormik from '../../../../UI/molecules/Field/FieldSelectFormik';

/**
 * @description edit and create new artworks
 * @param {*} param0
 * @returns
 */
export default function ArtworkCompleteForm() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { appUser } = useSelector((state) => state.appUser);
	const { countries, currencies } = useSelector((state) => state.staticData);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);
	const { artistDropdown } = useSelector((state) => state.artists);

	const unknownValues =
		appUser.unknown_values && JSON.parse(appUser.unknown_values);

	//define the variable for the dropwdown input boxes
	const [persons, setPersons] = useState([{ id: ' ', name: '' }]);
	const [location, setLocation] = useState([{}]);
	const [locationGeneralNames, setLocationGeneralNames] = useState([]);
	const [locationRoomNames, setLocationRoomNames] = useState([]);
	const [locationNames, setLocationNames] = useState([]);
	const [showSaving, setShowSaving] = useState(false);
	const [showAdvancedForm, setShowAdvancedForm] = useState(false);

	useEffect(() => {
		if (!countries) {
			dispatch(getCountries());
		}
		if (!currencies) {
			dispatch(getCurrencies());
		}
	}, [countries, dispatch, currencies]);

	//downloads all the data for the dropdown input boxes
	useEffect(() => {
		fetchGetData(
			ENDPOINTS_PERSON.GET_OWNER_CONTACTS + `${appUser.organization_user_id}/`
		).then((data) => setPersons(data));
		fetchGetData(
			ENDPOINTS_ARTWORK.GET_ARTWORK_LOCATION +
				`${appUser.organization_user_id}/`
		).then((data) => {
			setLocation(data);
		});
	}, [appUser]);

	const initialValues = {
		...artworkInitialValues,
		type: 'ARTWORK',
		type_id: 0,
		artist_id: '',
		owner_id: unknownValues?.owner_person_id ?? persons[0].person_id,
		location_id: 1,
		file_type: 'IMAGE',
		collection_id: unknownValues?.collection_id,
		main_picture_id: unknownValues?.picture_id,
		created_by_id: appUser.organization_user_id,
		organization_user_id: appUser.organization_user_id,
		currency_id: '5',
		object_type: 'BI-DIMENSIONAL',
	};

	//effects used to get the location data the first time
	useEffect(() => {
		//set available location General Names
		setLocationGeneralNames(
			get_unique_fields_from_key_in_dict(location, 'location_general_name')
		);

		let location_general_name = initialValues.location_general_name;
		let location_room_name = initialValues.location_room_name;
		let location_id = initialValues.location_id;

		if (
			(location_general_name === '') &
			(location_id !== 1) &
			(location_id !== undefined) &
			(location[0].location_general_name !== undefined)
		) {
			location_general_name = get_values_equal_to_y_in_dict(
				location,
				'location_id',
				location_id
			);
			location_room_name = get_values_equal_to_y_in_dict(
				location,
				'location_id',
				location_id
			);
		}

		//set available location Room Names
		let availableLocationRoom = get_values_equal_to_y_in_dict(
			location,
			'location_general_name',
			location_general_name
		);
		setLocationRoomNames(
			get_unique_fields_from_key_in_dict(
				availableLocationRoom,
				'location_room_name'
			)
		);

		//set available location Names
		let availableLocation = get_values_equal_to_y_in_dict(
			location,
			'location_room_name',
			location_room_name
		);
		setLocationNames(
			get_unique_values_from_key_in_dict(availableLocation, 'location_name')
		);
	}, [
		location,
		initialValues.location_general_name,
		initialValues.location_id,
		initialValues.location_room_name,
	]);

	//use to get all manage the section on locations
	function onChangeLocationGeneral(e, field, values, setValues) {
		let selectedLocationGeneral = e.target.value;
		//get available room
		let availableLocationRoom = get_values_equal_to_y_in_dict(
			location,
			'location_general_name',
			selectedLocationGeneral
		);
		let newLocationRoomNames = get_unique_fields_from_key_in_dict(
			availableLocationRoom,
			'location_room_name'
		);
		setLocationRoomNames(newLocationRoomNames);

		//get available location
		let availableLocation = get_values_equal_to_y_in_dict(
			location,
			'location_room_name',
			newLocationRoomNames[0]
		);
		let newAvailableLocation = get_unique_values_from_key_in_dict(
			availableLocation,
			'location_name'
		);
		setLocationNames(newAvailableLocation);

		values.location_id = newAvailableLocation[0].location_id;

		setValues(values);
		field.onChange(e);
	}

	function onChangeLocationRoom(e, field, values, setValues) {
		let selectedLocationRoom = e.target.value;
		let availableLocation = get_values_equal_to_y_in_dict(
			location,
			'location_room_name',
			selectedLocationRoom
		);
		let newAvailableLocation = get_unique_values_from_key_in_dict(
			availableLocation,
			'location_name'
		);
		setLocationNames(newAvailableLocation);

		values.location_id = newAvailableLocation[0].location_id;

		setValues(values);
		field.onChange(e);
	}

	function onChangeLocation(e, field, values, setValues) {
		let selectedLocation = e.target.value;
		values.location_id = selectedLocation;

		setValues(values);
		field.onChange(e);
	}

	const checkRedirect = () => {
		let redirect = '/obras';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage =
			navigationHistoryURL[navigationHistoryURL.length - 1] === '/'
				? 'RegresarHome'
				: 'RegresarObras';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	const handleOnsubmit = (values) => {
		setShowSaving(true);
		dispatch(postArtwork(values))
			.then((res) => {
				const newArtworkId = res?.payload?.artwork?.id;
				if (newArtworkId) {
					navigate(`/obras/${newArtworkId}`);
				}
				dispatch(getArtworks());
				dispatch(getCollections());
				dispatch(resetCollectionSingle());
				dispatch(getPortfolios());
				dispatch(resetSingleArtist());
			})
			.finally(() => setShowSaving(false));
	};

	useEffect(() => {
		dispatch(getArtistDropdown());
	}, [dispatch]);

	return (
		<ViewForm>
			<div className={showSaving && 'opacity-60'}>
				<SecondaryNavbarSpecific
					title={t('NuevaObra')}
					textColor='text-yellow-50'
					color='bg-yellow-50'
					hide='md:invisible'
					redirect={checkRedirect()}
					textBack={CheckTextBack()}
					textBackDesign='pl-3 text-yellow-50'
					arrowLeftColor='yellow'
					backAction={() => returnBackAction()}
				/>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={artworkFormValidationSchema}
					onSubmit={handleOnsubmit}
				>
					{(formProps) => (
						<Form className='mb-20'>
							<List>
								<div className='mx-auto flex w-11/12 flex-col gap-4 md:flex-row lg:w-4/5 lg:gap-12'>
									<ImageSectionForm
										formProps={formProps}
										dropzoneLabel={'SoltarOClickImagenPortadaObra'}
										mobileDropzoneLabel={'ClickCargarImagenPortadaObra'}
									/>
									<div className='mx-auto flex w-full flex-col md:w-1/2'>
										<SectionForm
											title={t('Identificación')}
											textColor='text-yellow-50'
											color='bg-yellow-50'
										>
											<FieldTextFormik
												label={t('Título')}
												dbName='name'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.name}
											/>
											<FieldTextFormik
												label={t('Código')}
												dbName='code'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.code}
											/>
											{showAdvancedForm && (
												<FieldTextFormik
													label={t('OtroCódigo')}
													dbName='code_other'
													type='Text'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.code_other}
												/>
											)}

											<ListItem
												style={{
													paddingLeft: '0px',
													paddingRight: '0px',
													justifyContent: 'space-between',
												}}
											>
												<ListItemText
													primary={t('Tipo')}
													design='body-Text2'
													style={{ fontWeight: 400 }}
												/>
												<Field
													name='object_type'
													type='Text'
													as='select'
													className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
												>
													<option value='BI-DIMENSIONAL'>
														{t('BiDimensional')}
													</option>
													<option value='TRI-DIMENSIONAL'>
														{t('TriDimensional')}
													</option>
													<option value='OTRO'>{t('Otro')}</option>
												</Field>
											</ListItem>
											{formProps.errors?.object_type && (
												<div className={`w-40 text-red-400 md:w-56`}>
													{t(formProps.errors?.object_type)}
												</div>
											)}
											<FieldTextFormik
												label={t('Fecha')}
												dbName='date'
												type='Date'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.date}
											/>

											<ListItem
												style={{
													paddingLeft: '0px',
													paddingRight: '0px',
													justifyContent: 'space-between',
												}}
											>
												<ListItemText
													primary={t('PaísDeOrigen')}
													design='body-Text2'
													style={{ fontWeight: 400 }}
												/>
												<div className='flex flex-col'>
													<Field
														name='origin_country_id'
														type='Text'
														as='select'
														className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
													>
														<option value=''></option>
														{countries?.map((country) => (
															<option value={country.value} key={country.value}>
																{country.label}
															</option>
														))}
													</Field>
													{formProps.errors?.origin_country_id && (
														<div className={`w-40 text-red-400 md:w-56`}>
															{t(formProps.errors?.origin_country_id)}
														</div>
													)}
												</div>
											</ListItem>

											{showAdvancedForm && (
												<FieldTextFormik
													label={t('Diferencias')}
													dbName='difference'
													type='string'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.difference}
												/>
											)}
										</SectionForm>

										<SectionForm
											title={t('Descripción')}
											textColor='text-yellow-50'
											color='bg-yellow-50'
										>
											<FieldTextAreaFormik
												label='description'
												dbName='description'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.description}
											/>
										</SectionForm>
										<SectionForm
											title={t('Autor')}
											textColor='text-yellow-50'
											color='bg-yellow-50'
										>
											<FieldSelectFormik
												label={t('Artista')}
												dbName='artist_id'
												options={artistDropdown?.allArtist}
												errors={formProps.errors && formProps.errors.artist_id}
												designErrors='text-right'
											/>
										</SectionForm>

										{showAdvancedForm && (
											<SectionForm
												title={t('Observaciones')}
												textColor='text-yellow-50'
												color='bg-yellow-50'
											>
												<FieldTextAreaFormik
													label='observations'
													dbName='observations'
													errors={formProps.errors?.observations}
												/>
											</SectionForm>
										)}

										{showAdvancedForm && (
											<SectionForm
												title='Propietario'
												textColor='text-yellow-50'
												color='bg-yellow-50'
											>
												<ListItem
													style={{
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														design='body-Text2'
														primary={t('Estado')}
														style={{ fontWeight: 400 }}
													/>
													<Field
														name='owner_status'
														type='Text'
														as='select'
														className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
													>
														<option value='CONSERVADO'>
															{t('Conservado')}
														</option>
														<option value='VENDIDO'>{t('Vendido')}</option>
														<option value='DONADO'>{t('Donado')}</option>
														<option value='DISPONIBLE'>
															{t('Disponible')}
														</option>
														<option value='DESCONOCIDO'>
															{t('Desconocido')}
														</option>
													</Field>
												</ListItem>
												{formProps.errors?.owner_status && (
													<div className={`w-40 text-red-400 md:w-56`}>
														{t(formProps.errors?.owner_status)}
													</div>
												)}
												<ListItem
													style={{
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														design='body-Text2'
														primary={t('Propietario')}
														style={{ fontWeight: 400 }}
													/>
													<Field
														name='owner_id'
														type='Text'
														as='select'
														className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
													>
														{persons[0].id === '' ? (
															<option value=''></option>
														) : (
															persons?.map((person) => (
																<option
																	key={person.person_id}
																	value={person.person_id}
																>
																	{person.person_name}
																</option>
															))
														)}
													</Field>
												</ListItem>
												{formProps.errors?.owner_id && (
													<div className={`w-40 text-red-400 md:w-56`}>
														{t(formProps.errors?.owner_id)}
													</div>
												)}
											</SectionForm>
										)}

										<SectionForm
											title={t('DimensionesObjeto')}
											textColor='text-yellow-50'
											color='bg-yellow-50'
										>
											<FieldTextFormik
												label={t('AlturaCm')}
												dbName='dimensions_height'
												type='Number'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.dimensions_height}
											/>
											<FieldTextFormik
												label={t('LargoCm')}
												dbName='dimensions_length'
												type='Number'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.dimensions_length}
											/>
											<FieldTextFormik
												label={t('AnchoCm')}
												dbName='dimensions_width'
												type='Number'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.dimensions_width}
											/>
											<FieldTextFormik
												label={t('PesoKg')}
												dbName='dimensions_weight'
												type='Number'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.dimensions_weight}
											/>
										</SectionForm>

										{showAdvancedForm && (
											<SectionForm
												title={t('DimensionesConBase')}
												textColor='text-yellow-50'
												color='bg-yellow-50'
											>
												<ListItem
													style={{
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														design='body-Text2'
														primary={t('TieneBase')}
														style={{ fontWeight: 400 }}
													/>
													<Field
														name='dimension_base'
														type='Text'
														as='select'
														className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
														onChange={(e) =>
															handleChangeDropdownBoolean(e, formProps)
														}
													>
														<option value='false'>{t('No')}</option>
														<option value='true'>{t('Si')}</option>
													</Field>
												</ListItem>
												{formProps.errors?.owner_id && (
													<div className={`w-40 text-red-400 md:w-56`}>
														{t(formProps.errors?.dimension_base)}
													</div>
												)}
												<FieldTextFormik
													label={t('AlturaDeBaseCm')}
													dbName='dimensions_base_height'
													type='Number'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.dimensions_base_height}
												/>
												<FieldTextFormik
													label={t('LargoDeBaseCm')}
													dbName='dimensions_base_length'
													type='Number'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.dimensions_base_length}
												/>
												<FieldTextFormik
													label={t('AnchoDeBaseCm')}
													dbName='dimensions_base_width'
													type='Number'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.dimensions_base_width}
												/>
												<FieldTextFormik
													label={t('PesoDeBaseKg')}
													dbName='dimensions_base_weight'
													type='Number'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.dimensions_base_weight}
												/>
											</SectionForm>
										)}

										<SectionForm
											title={t('TécnicaMateriales')}
											textColor='text-yellow-50'
											color='bg-yellow-50'
										>
											<FieldTextFormik
												label={t('Técnica')}
												dbName='technique'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.technique}
											/>
											<FieldTextFormik
												label={t('Soporte')}
												dbName='holder'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.holder}
											/>
											<FieldTextFormik
												label={t('Materiales')}
												dbName='materials'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.materials}
											/>
										</SectionForm>

										{showAdvancedForm && (
											<SectionForm
												title={t('Componentes')}
												textColor='text-yellow-50'
												color='bg-yellow-50'
											>
												<ListItem
													style={{
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														design='body-Text2'
														primary={t('TieneComponentes')}
														style={{ fontWeight: 400 }}
													/>
													<Field
														name='components'
														type='Text'
														as='select'
														className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
														onChange={(e) =>
															handleChangeDropdownBoolean(e, formProps)
														}
													>
														<option value='false'>{t('No')}</option>
														<option value='true'>{t('Si')}</option>
													</Field>
												</ListItem>
												{formProps.errors?.components && (
													<div className={`w-40 text-red-400 md:w-56`}>
														{t(formProps.errors?.components)}
													</div>
												)}
												<FieldTextFormik
													label={t('NúmeroDeComponentes')}
													dbName='components_number'
													type='Number'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.components_number}
												/>
												<FieldTextFormik
													label={t('DetallesDeLosComponentes')}
													dbName='components_detail'
													type='Text'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.components_detail}
												/>
											</SectionForm>
										)}

										{showAdvancedForm && (
											<SectionForm
												title={t('EstadoDeConservación')}
												textColor='text-yellow-50'
												color='bg-yellow-50'
											>
												<FieldTextAreaFormik
													label='preservation_status'
													dbName='preservation_status'
													errors={formProps.errors?.preservation_status}
												/>
											</SectionForm>
										)}

										{showAdvancedForm && (
											<SectionForm
												title={t('Ubicación')}
												textColor='text-yellow-50'
												color='bg-yellow-50'
											>
												<ListItem
													style={{
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														design='body-Text2'
														primary={t('UbicaciónGeneral')}
														style={{ fontWeight: 400 }}
													/>
													<Field name='location_general_name'>
														{({ field }) => (
															<select
																{...field}
																onChange={(e) =>
																	onChangeLocationGeneral(
																		e,
																		field,
																		formProps.values,
																		formProps.setValues
																	)
																}
																className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
															>
																{locationGeneralNames.map((locationGeneral) => (
																	<option
																		key={locationGeneral}
																		value={locationGeneral}
																	>
																		{locationGeneral}
																	</option>
																))}
															</select>
														)}
													</Field>
												</ListItem>
												{formProps.errors?.location_general_name && (
													<div className={`w-40 text-red-400 md:w-56`}>
														{t(formProps.errors?.location_general_name)}
													</div>
												)}
												<ListItem
													style={{
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														design='body-Text2 '
														primary={t('Cuarto')}
														style={{ fontWeight: 400 }}
													/>
													<Field
														name='location_room_name'
														className='rounded border-none bg-light_grey-50'
													>
														{({ field }) => (
															<select
																{...field}
																onChange={(e) =>
																	onChangeLocationRoom(
																		e,
																		field,
																		formProps.values,
																		formProps.setValues
																	)
																}
																className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
															>
																{locationRoomNames.map((locationGeneral) => (
																	<option
																		key={locationGeneral}
																		value={locationGeneral}
																	>
																		{locationGeneral}
																	</option>
																))}
															</select>
														)}
													</Field>
												</ListItem>
												{formProps.errors?.location_room_name && (
													<div className={`w-40 text-red-400 md:w-56`}>
														{t(formProps.errors?.location_room_name)}
													</div>
												)}
												<ListItem
													style={{
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														design='body-Text2'
														primary={t('UbicaciónNombre')}
														style={{ fontWeight: 400 }}
													/>
													<Field
														name='location_name'
														type='Text'
														className=' rounded border-none bg-light_grey-50'
													>
														{({ field }) => (
															<select
																{...field}
																onChange={(e) =>
																	onChangeLocation(
																		e,
																		field,
																		formProps.values,
																		formProps.setValues
																	)
																}
																className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
															>
																{locationNames.map((item) => (
																	<option
																		key={item.location_id}
																		value={item.location_id}
																	>
																		{item.location_name}
																	</option>
																))}
															</select>
														)}
													</Field>
												</ListItem>
												{formProps.errors?.location_name && (
													<div className={`w-40 text-red-400 md:w-56`}>
														{t(formProps.errors?.location_name)}
													</div>
												)}
											</SectionForm>
										)}

										<SectionForm
											title={t('Avalúo')}
											textColor='text-yellow-50'
											color='bg-yellow-50'
										>
											<FieldTextFormik
												label={t('Avalúo')}
												dbName='price'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.price}
											/>
											<ListItem
												style={{
													paddingLeft: '0px',
													paddingRight: '0px',
													justifyContent: 'space-between',
												}}
											>
												<ListItemText
													design='body-Text2'
													primary={t('Moneda')}
													style={{ fontWeight: 400 }}
												/>
												<Field
													name='currency_id'
													type='text'
													as='select'
													className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
												>
													{currencies?.map((currency) => (
														<option key={currency.value} value={currency.value}>
															{currency.label}
														</option>
													))}
												</Field>
											</ListItem>
											{formProps.errors?.valuation_number && (
												<div className={`w-40 text-red-400 md:w-56`}>
													{t(formProps.errors?.valuation_number)}
												</div>
											)}
										</SectionForm>

										<ButtonWithTextIcon
											text={
												showAdvancedForm
													? t('EsconderCatalogaciónAvanzada')
													: t('VerCatalogaciónAvanzada')
											}
											design='rounded mx-auto mb-8'
											action={() => setShowAdvancedForm(!showAdvancedForm)}
											icon={
												<CaretDownBlack
													className={
														showAdvancedForm &&
														'-rotate-180 transform transition'
													}
												/>
											}
										/>

										{showSaving ? (
											<LoadingText text={t('Guardando')} />
										) : (
											<ButtonFabExtendedPrimary
												className='mx-auto text-white'
												icon={whiteCheck}
												text={t('Guardar')}
												typeButton='submit'
											/>
										)}
									</div>
								</div>
							</List>
						</Form>
					)}
				</Formik>
			</div>
		</ViewForm>
	);
}
