import { submitErrorToSlack } from '../helpers/SlackIntegrations';

export const fetchDeleteImage = async (values) => {
	const { token } = localStorage;
	return fetch(values.url, {
		method: 'DELETE',
		cache: 'no-cache',
		headers: {
			content_type: 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(values),
	}).then(async (res) => {
		const response = await res.json();
		const endpoint = res.url;
		if (res.status >= 500) {
			submitErrorToSlack(endpoint, response, 'DELETE');
		}
		return response;
	});
};
