import { putExpertPortfolioSlideItemElement } from '../redux/reducers/portfolio';

export const putElementOnBlur = (
	dispatch,
	currentContent,
	elementId,
	previousContent
) => {
	const id = elementId;

	if (!dispatch || !currentContent || !id) return;
	if (currentContent === previousContent) return;
	dispatch(
		putExpertPortfolioSlideItemElement({
			id,
			values: {
				content: currentContent,
			},
		})
	);
};
