//3rd parties
import PropTypes from 'prop-types';
import React from 'react';
import TextContentSecond from '../../../atoms/Text/Content/TextContentSecond';
import { useTranslation } from 'react-i18next';

/**
 * @description Shows a list of buttons with the ability selected one to display a specific information
 * @param {*} param0
 * @returns
 */
export default function NewTagButtons({
	buttonList,
	indexInfo,
	setIndexInfo,
	designButtonActive,
}) {
	const { t } = useTranslation();
	return (
		<div className='flex items-center'>
			<div className='no-scrollbar box-content h-full w-full overflow-x-scroll whitespace-nowrap md:flex md:overflow-hidden'>
				{Array.isArray(buttonList) &&
					buttonList.map((buttonText, index) => (
						<button
							className={`h-8 w-28 cursor-pointer rounded-t-xl px-1 text-center ${
								index === indexInfo && designButtonActive
							} `}
							onClick={() => setIndexInfo(index)}
							key={index}
						>
							<TextContentSecond
								text={t(buttonText)}
								style={index === indexInfo ? { fontWeight: 700 } : {}}
							/>
						</button>
					))}
			</div>
		</div>
	);
}

NewTagButtons.propTypes = {
	/* array of a list of options to show information */
	buttonList: PropTypes.array,
	/* string prop to add design when button is selected or active*/
	designButton: PropTypes.string,
};

NewTagButtons.defaultProps = {
	indexInfo: 0,
	designButtonActive: 'bg-light_grey-50',
};
