// 3rd party
import PropTypes from 'prop-types';

// internal
import { ReactComponent as Details } from '../../../assets/icons/details.svg';
import { ReactComponent as DetailsLight } from '../../../assets/icons/DotsVertical.svg';

export default function ButtonLinkOpenMenu({ design = 'bold' }) {
	return (
		<figure src={Details} alt='Atrás'>
			{design === 'bold' ? (
				<Details fill='none' />
			) : (
				<DetailsLight fill='none' />
			)}
		</figure>
	);
}

ButtonLinkOpenMenu.propTypes = {
	design: PropTypes.string,
};
