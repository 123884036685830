//3rd
import { PropTypes } from 'prop-types';
import { useEffect, useRef } from 'react';

//UI
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

/**
 * @description render a popup that uses an array with an object. That object could have action, icon and text for each option.
 * @param {*} param0
 * @returns
 */

const PopupIconAndOptions = ({
	popupOptions,
	design,
	designPopup,
	buttonsPopup,
	showPopup,
	setShowPopup,
}) => {
	const popUp = useRef(null);

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			if (showPopup && popUp.current && !popUp.current.contains(e.target)) {
				setShowPopup && setShowPopup(false);
			}
		};
		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [setShowPopup, showPopup]);

	return (
		<div className='relative z-10'>
			<div
				className={`absolute mt-8 flex flex-col rounded-lg bg-white p-2 shadow-lg ${designPopup} -right-6`}
				ref={popUp}
			>
				<div className='flex select-none flex-col justify-between gap-2'>
					{popupOptions &&
						popupOptions.map((option, i) => (
							<button
								className={`flex flex-row place-items-center gap-2 whitespace-nowrap rounded-md px-2 ${design}`}
								key={i}
								onClick={option.action}
							>
								{option.icon}
								<TextContentSecond className='flex' text={option.label} />
							</button>
						))}
					{buttonsPopup && buttonsPopup}
				</div>
			</div>
		</div>
	);
};

export default PopupIconAndOptions;

PopupIconAndOptions.propTypes = {
	/** array of objects with icon, action and text */
	popupOptions: PropTypes.array,
	/** Tailwind classNames that manages the styles */
	design: PropTypes.string,
	/** boolean that determines if the popup is visible */
	showPopup: PropTypes.bool,
	/** function that shows or hides the popup */
	setShowPopup: PropTypes.func,
	/** customizable link  */
	buttonsPopup: PropTypes.element,
};
