import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// BL
import { editablePortfolioArtworkButtons } from '../../../BusinessLogic/data/EditablePortfolio/EditablePortfolioArtworkData';
// UI
import NewTagButtons from '../../molecules/Buttons/Tag/NewTagButtons';
import ArtworkCompleteElement from '../../molecules/Portfolio/ArtworkCompleteElement';
// import ArtworkTextElement from '../../molecules/Portfolio/ArtworkTextElement';
import EditablePortfolioArtworkSelection from '../../molecules/Portfolio/EditablePortfolioArtworkSelection';

/**
 * This function renders a component that allows the user to add elements to the portfolio
 * @returns A div with a conditional rendering of another div.
 */
const EditablePortfolioArtwork = ({
	setIndexEditablePortfolioButton,
	setShowSelectionBar,
	handleSlideEdition,
	setShowSideModal,
}) => {
	const { t } = useTranslation();
	const [indexArtworkElementButton, setIndexArtworkElementButton] = useState(0);
	const [chooseArtwork, setChooseArtwork] = useState(false);
	const [selection, setSelection] = useState('No Selection');

	const renderSwitch = () => {
		switch (indexArtworkElementButton) {
			case 0:
				return (
					<ArtworkCompleteElement
						setChooseArtwork={setChooseArtwork}
						setSelection={setSelection}
					/>
				);
			// case 1:
			// 	return (
			// 		<ArtworkTextElement
			// 			setChooseArtwork={setChooseArtwork}
			// 			setSelection={setSelection}
			// 		/>
			// 	);
			case 1:
				return (
					<EditablePortfolioArtworkSelection
						setIndexArtworkElementButton={setIndexArtworkElementButton}
						designButtonBack='hidden'
						setIndexEditablePortfolioButton={setIndexEditablePortfolioButton}
						setShowSelectionBar={setShowSelectionBar}
						setShowSideModal={setShowSideModal}
						handleSlideEdition={handleSlideEdition}
					/>
				);
			default:
				<div />;
		}
	};

	return (
		<div className='h-screen rounded-2xl bg-middle_grey-50'>
			{chooseArtwork ? (
				<div className='flex flex-col items-center '>
					<EditablePortfolioArtworkSelection
						setIndexEditablePortfolioButton={setIndexEditablePortfolioButton}
						setShowSelectionBar={setShowSelectionBar}
						setShowSideModal={setShowSideModal}
						setChooseArtwork={setChooseArtwork}
						selection={selection}
						design='bg-middle_grey-50  w-[80%] md:w-[70%] relative mt-4 md:mt-16'
						handleSlideEdition={handleSlideEdition}
					/>
				</div>
			) : (
				<div className='flex flex-col items-center'>
					<p className='py-8 text-center '>{t('AgregarElementos')}</p>
					<div className=' sm:border-b-0 '>
						<NewTagButtons
							buttonList={editablePortfolioArtworkButtons}
							setIndexInfo={setIndexArtworkElementButton}
							indexInfo={indexArtworkElementButton}
							designButtonActive={'bg-green-50 text-white'}
						/>
					</div>

					<div className=' w-full border-b-2 border-b-white sm:w-[23rem] md:w-96'></div>

					{renderSwitch()}
				</div>
			)}
		</div>
	);
};

EditablePortfolioArtwork.propTypes = {
	/**Function to change the index in the EditablePortfolioButton in the parent component */
	setIndexEditablePortfolioButton: PropTypes.func,
	/**Function to set if the SelectionBar will be or not displayed  */
	setShowSelectionBar: PropTypes.func,
	/**Function to dispatch the function when an slide is edited */
	handleSlideEdition: PropTypes.func,
	/**Function to set if the SideModal will be or not displayed */
	setShowSideModal: PropTypes.func,
};

export default EditablePortfolioArtwork;
