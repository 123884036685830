import { fetchGetData } from '../../hooks/fetchGetData';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	ENDPOINTS_EXPOSITION,
	ENDPOINTS_COUNTRY,
	ENDPOINTS_CITY,
} from '../../helpers/routes';
import { fetchPutData } from '../../hooks/fetchPutData';
import { fetchPutArrayImages } from '../../hooks/fetchPutArrayImages';
import postDataAndImage from '../../helpers/postDataAndImage';
import {
	updateTabsObject,
	updateDropdowns,
} from '../../helpers/SingleViewHelpers';
import { expositionSingleTabs } from '../../data/ExpositionSingle';

export const getExpositions = createAsyncThunk(
	'expositions/fetchExpositions',
	async () => {
		const { organization_user_id } = localStorage;
		return await fetchGetData(
			`${ENDPOINTS_EXPOSITION.GET_EXPOSITIONS}${organization_user_id}/`
		);
	}
);

export const getExposition = createAsyncThunk(
	'expositions/fetchExposition',
	async (id) => {
		return await fetchGetData(`${ENDPOINTS_EXPOSITION.GET_EXPOSITION}${id}/`);
	}
);

export const getPublicExposition = createAsyncThunk(
	'expositions/fetchExposition',
	async (id) => {
		return await fetchGetData(
			`${ENDPOINTS_EXPOSITION.GET_PUBLIC_EXPOSITION}${id}/`
		);
	}
);

export const putExposition = createAsyncThunk(
	'expositions/putExposition',
	async (input) => {
		const { values, id } = input;
		return await fetchPutData(
			`${ENDPOINTS_EXPOSITION.PUT_EXPOSITION}${id}/`,
			values
		);
	}
);

export const postExposition = createAsyncThunk(
	'exposition/postExposition',
	async (values) => {
		return await postDataAndImage(ENDPOINTS_EXPOSITION.POST_EXPOSITION, values);
	}
);

export const organizeImageGrid = createAsyncThunk(
	'expositions/organizeImageGrid',
	async (values) => {
		return await fetchPutArrayImages(values);
	}
);

export const getExpositionGeneralDropdown = createAsyncThunk(
	'expositions/getExpositionGeneralDropdown',
	async () => {
		const { countries } = await fetchGetData(
			ENDPOINTS_COUNTRY.GET_COUNTRIES_WITH_LABELS
		);
		const cities = await fetchGetData(ENDPOINTS_CITY.GET_CITIES_ALL);
		let final = {
			countries: countries,
			cities: cities,
		};
		return final;
	}
);

const initialStateExpositionTabs = expositionSingleTabs;
const initialSingleExposition = {
	name: '',
	content: '',
	pictures: [
		{
			url: 'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5122_medium',
		},
	],
};
const initialExpositionSingleTabs = updateTabsObject(
	expositionSingleTabs,
	initialSingleExposition
);

const initialState = {
	expositions: null,
	singleExposition: null,
	status: 'loading',
	expositionSingleTabs: initialExpositionSingleTabs,
	initialStateTabs: initialStateExpositionTabs,
	expositionDropdown: {
		countries: [],
		cities: [],
	},
	loadedExpositionDropdown: false,
	value: {},
	chip: {},
};

export const expositionSlice = createSlice({
	name: 'expositions',
	initialState,
	reducers: {
		addConfigurationInput(state, { payload }) {
			state.value = { ...state.value, payload };
		},
		addConfigurationChip(state, { payload }) {
			state.chip = { ...payload };
		},
		removeConfiguration(state, { payload }) {
			state.chip = { ...payload };
		},
	},
	extraReducers: {
		[getExpositions.pending]: (state) => {
			state.status = 'loading';
		},
		[getExpositions.fulfilled]: (state, { payload }) => {
			state.expositions = payload;
			state.status = 'success';
		},
		[getExpositions.rejected]: (state) => {
			state.status = 'failed';
		},
		[getExposition.pending]: (state) => {
			state.expositionSingleTabs = updateTabsObject(
				state.initialStateTabs,
				initialSingleExposition
			);
			state.status = 'loading';
		},
		[getExposition.fulfilled]: (state, { payload }) => {
			let Single = payload;
			state.singleExposition = Single;
			state.expositionSingleTabs = updateTabsObject(
				state.expositionSingleTabs,
				Single
			);
			state.expositionSingleTabs = updateDropdowns(
				state.expositionSingleTabs,
				state.expositionDropdown['countries'],
				0,
				0,
				4
			);
			state.expositionSingleTabs = updateDropdowns(
				state.expositionSingleTabs,
				state.expositionDropdown['cities'],
				0,
				0,
				5
			);
			state.status = 'success';
		},
		[getExposition.rejected]: (state) => {
			state.status = 'failed';
		},
		[putExposition.pending]: (state) => {
			state.status = 'loading';
		},
		[putExposition.fulfilled]: (state) => {
			state.status = 'success';
		},
		[putExposition.rejected]: (state) => {
			state.status = 'failed';
		},
		[postExposition.pending]: (state) => {
			state.status = 'loading';
		},
		[postExposition.fulfilled]: (state) => {
			state.status = 'success';
		},
		[postExposition.rejected]: (state) => {
			state.status = 'failed';
		},
		[organizeImageGrid.pending]: (state) => {
			state.status = 'loading';
		},
		[organizeImageGrid.fulfilled]: (state) => {
			state.status = 'success';
		},
		[organizeImageGrid.rejected]: (state) => {
			state.status = 'failed';
		},
		//getExpositionGeneralDropdown
		[getExpositionGeneralDropdown.pending]: (state) => {
			state.status = 'loading';
		},
		[getExpositionGeneralDropdown.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.expositionDropdown = payload;
			state.loadedExpositionDropdown = true;
		},
		[getExpositionGeneralDropdown.rejected]: (state) => {
			state.status = 'failed';
		},
	},
});

export const {
	removeConfiguration,
	addConfigurationInput,
	addConfigurationChip,
} = expositionSlice.actions;

export default expositionSlice.reducer;
