//3rd parties
import PropTypes from 'prop-types';

//Interntal components
import TextContentSecond from '../../Text/Content/TextContentSecond';

/**
 * @name ButtonFab
 * @description simple button to add an item
 *
 * Button Fab follos the figma rules.
 * @param {*} param0
 * @returns
 */

const ButtonFabAdmin = ({
	label,
	icon,
	action,
	containerDesign,
	textColor,
}) => {
	return (
		<button
			className={`z-10 flex h-14 w-20 cursor-pointer flex-col items-center justify-center rounded-md border bg-white shadow-md md:gap-1 ${containerDesign}`}
			onClick={action}
		>
			{icon && <img src={icon} className={'fill-blue-500'} alt='icon' />}
			<TextContentSecond
				text={label}
				design={`normal-case ${textColor}`}
				style={{ fontWeight: 400 }}
			/>
		</button>
	);
};

ButtonFabAdmin.propTypes = {
	/** Passes the text to the component */
	label: PropTypes.string,
	/** Icon passed to the component. If there's no icon it disappears.*/
	icon: PropTypes.string,
	/** Action Triggered on Click */
	action: PropTypes.func,
	/** Tailwind className of the main container */
	containerDesign: PropTypes.string,
	/** inline color to the text */
	textColor: PropTypes.object,
};

export default ButtonFabAdmin;
