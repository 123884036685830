//3rd
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import 'react-lazy-load-image-component/src/effects/blur.css';

//UI
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import { ReactComponent as Save } from '../../../UI/assets/icons/Save.svg';
import CallStatusLabel from '../../atoms/Text/Labels/CallStatusLabel';

//BL
import { arrayToString } from '../../../BusinessLogic/helpers/textFormat';

const CardPostulationSingle = ({
	title,
	logo,
	image,
	imageStyle,
	technique,
	dateLimit,
	category,
	organization,
	country,
	city,
	status,
	save,
	hasAlliance,
}) => {
	//FIXME: The structure and media query styles can be improved.
	//There's a lot of use of "hidden" Tailwind class to show or hide components in different media queries.

	let defaultImage =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';

	const cityCountryText = `${city ?? ''} ${(city && country && '-') ?? ''} ${
		country ?? ''
	}`;

	return (
		<div className='py-6 md:flex md:gap-6'>
			<div className='relative mb-4 flex w-full items-center gap-2 pt-6 pb-10 md:hidden'>
				<LazyLoadImage
					alt={title}
					src={logo ?? defaultImage}
					effect='blur'
					wrapperClassName='overflow-hidden aspect-square rounded-full flex items-center lazyLoadingImages max-h-24'
					className='max-h-full object-contain'
					delayTime='0'
					threshold='800'
					style={imageStyle}
				/>

				<div className='flex w-full flex-col justify-center '>
					<TextContentSecond text={organization} />
					<div className='flex justify-between'>
						<TextContentSecond text={cityCountryText} />

						<CallStatusLabel status={status} design={'hidden md:block'} />
					</div>
					{hasAlliance && (
						<TextContentSecond
							design={
								' px-2 h-6 w-max bg-green-50  rounded-lg text-white flex items-center md:hidden absolute bottom-0 right-0'
							}
							text={'Alianza Datarte'}
						/>
					)}
				</div>
				{save && <Save className='cursor-pointer md:hidden' />}
			</div>

			<div className='mt-2 flex w-full flex-col justify-center '>
				<LazyLoadImage
					alt={title}
					src={image ?? defaultImage}
					placeholderSrc={image ?? defaultImage}
					effect='blur'
					wrapperClassName='lazyLoadingImages md:max-h-64 aspect-video overflow-hidden'
					className='object-cover object-center'
					delayTime='0'
					threshold='800'
					style={imageStyle}
				/>
			</div>

			<div className='flex w-full min-w-[40%] gap-6 py-4 md:w-4/5 md:flex-col'>
				<div className='hidden h-fit w-full items-center gap-2 md:flex md:flex-col'>
					<div className='relative flex w-full'>
						<div className='flex h-20 w-full flex-row items-center justify-center'>
							<LazyLoadImage
								alt={title}
								src={logo ?? defaultImage}
								effect='blur'
								wrapperClassName='aspect-square h-full rounded-full overflow-hidden'
								className='h-full max-h-full w-full max-w-full object-contain '
								delayTime='0'
								threshold='800'
								style={imageStyle}
							/>
						</div>
						{hasAlliance && (
							<TextContentSecond
								design={
									'px-2 h-6 absolute right-0 w-max bg-green-50 rounded-lg text-white md:flex items-center hidden md:block top-[8.1%]'
								}
								text={'Alianza Datarte'}
							/>
						)}
					</div>
					<div className='mt-4 flex w-full flex-row'>
						<div className='w-2/3'>
							<TextContentSecond text={organization} />
							<TextContentSecond text={cityCountryText} />
						</div>
						<div className='w-max '>
							<CallStatusLabel
								status={status}
								design={'hidden md:block pt-[35%]'}
							/>
						</div>
					</div>
				</div>
				{(category || technique || dateLimit) && (
					<div className='flex w-full flex-col gap-4'>
						{arrayToString(category)?.length > 0 && (
							<div className='flex items-center justify-between gap-1 text-left'>
								<label className='w-1/2 text-sm md:w-2/3'>Categoría:</label>
								<TextContentSecond
									design={'w-1/2 md:w-1/3'}
									text={arrayToString(category)}
								/>
							</div>
						)}
						{arrayToString(technique)?.length > 0 && (
							<div className='flex items-center justify-between gap-1 text-left'>
								<label className='w-1/2 text-sm md:w-2/3'>Técnica:</label>
								<TextContentSecond
									design={'w-1/2 md:w-1/3'}
									text={arrayToString(technique)}
								/>
							</div>
						)}
						{dateLimit && (
							<div className='flex items-center justify-between gap-1 text-left'>
								<label className='w-1/2 text-sm md:w-2/3'>Fecha límite:</label>
								<TextContentSecond design={'w-1/2 md:w-1/3'} text={dateLimit} />
							</div>
						)}
					</div>
				)}

				<CallStatusLabel status={status} design={'md:hidden px-2'} />
			</div>
		</div>
	);
};

export default CardPostulationSingle;

CardPostulationSingle.propTypes = {
	/** Title of the call*/
	title: PropTypes.string,
	/** Call organizer Logo */
	logo: PropTypes.string,
	/** Main Call image */
	image: PropTypes.string,
	/** Styling of main image */
	imageStyle: PropTypes.string,
	/** Technique required in call */
	technique: PropTypes.string,
	/** Limit date to postulate */
	dateLimit: PropTypes.string,
	/** Category of the call */
	category: PropTypes.string,
	/** Call organizer */
	organization: PropTypes.string,
	/** Call country */
	country: PropTypes.string,
	/** Call city  */
	city: PropTypes.string,
	/** status */
	status: PropTypes.string,
	/** whether it has a save icon */
	save: PropTypes.string,
	/** Whether a call has an alliance with Datarte */
	hasAlliance: PropTypes.bool,
};
