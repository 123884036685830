//Third parties
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//UI
import FieldSelectFormik from './FieldSelectFormik';
import CheckboxSelectFormik from './CheckboxSelectFormik';
import InputField from './InputField';

//BL
import { handleChangeDropdownBoolean } from '../../../BusinessLogic/helpers/handleChangeDropdownBoolean';

const InputCase = ({ item, key, formProps }) => {
	const { t } = useTranslation();

	switch (item.type) {
		case 'dropdown':
			return (
				<FieldSelectFormik
					label={t(item.label)}
					dbName={item.field}
					options={item.options}
					formProps={formProps}
					onChangeAction={item.isDropdownBoolean && handleChangeDropdownBoolean}
					key={key}
					className='mx-2'
					hideDefaultOption={item?.hideDefaultOption}
				/>
			);
		case 'checkbox':
			return (
				<CheckboxSelectFormik
					label={t(item.label)}
					dbName={item.field}
					options={item.options}
					selectedValues={item.value}
					formProps={formProps}
					key={key}
				/>
			);

		case 'collections':
			return <></>;

		default:
			return (
				<InputField
					item={item}
					key={key}
					errors={formProps.errors && formProps.errors[item.field]}
				/>
			);
	}
};

InputCase.propTypes = {
	/** item for the the section*/
	item: PropTypes.object,
	/** key*/
	key: PropTypes.number,
	/** Props from formik*/
	formProps: PropTypes.object,
};

export default InputCase;
