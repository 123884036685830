import { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';

//BL
import { getArtist } from '../../../../BusinessLogic/redux/reducers/artist';
import { getArtworks } from '../../../../BusinessLogic/redux/reducers/artwork';
import { getAllArtworksImages } from '../../../../BusinessLogic/redux/reducers/artworkImage';

import { createImage } from '../../../../BusinessLogic/redux/reducers/createImage';
import {
	postExpertPortfolioSlideArtworkItem,
	putExpertPortfolioArtworkToArtwork,
	putExpertPortfolioSlideItemElement,
	setCurrentViewExpertPortfolioAndSelection,
	setShowSlideModalExpertPortfolio,
} from '../../../../BusinessLogic/redux/reducers/portfolio';
import { compressAndResizeImage } from '../../../../BusinessLogic/helpers/imageSize';

// UI
import { ReactComponent as MagnifyingGlassGrey } from '../../../assets/icons/MagnifyingGlassGrey.svg';
import { ReactComponent as Back } from '../../../assets/icons/Back.svg';
import TextContentPrincipal from '../../../atoms/Text/Content/TextContentPrincipal';
import TextContentThird from '../../../atoms/Text/Content/TextContentThird';
import ButtonFabExtendedPrimary from '../../Buttons/FABExtended/ButtonFabExtendedPrimary';
import ImageAndTitle from '../../Image/ImageTitleSimple';
import SpinnerWhite from '../../../assets/icons/loadingSpinnerSmallWhite.svg';
import DropZoneModal from '../../DropZone/DropZoneModal';

const SideModalExpertPortfolio = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { appUser } = useSelector((state) => state.appUser);
	const { artworks } = useSelector((state) => state.artworks);
	const { singleArtist } = useSelector((state) => state.artists);
	const { expertPortfolioViewAndSelection, showSlideModalExpertPortfolio } =
		useSelector((state) => state.portfolios);
	const { status } = useSelector((state) => state.image);
	const { allArtworksImages } = useSelector((state) => state.artworkImage);
	const allArtworksImagesStatus = useSelector(
		(state) => state.artworkImage.status
	);

	const mainArtistId = appUser?.main_artist_id;
	const modalView = showSlideModalExpertPortfolio.modalView;

	const [tabView, setTabView] = useState('artworks');
	const [filterValue, setFilterValue] = useState('');
	const [gridElementsToRender, setGridElementsToRender] = useState(null);
	const [selectedElement, setSelectedElement] = useState(null);
	const [artworkSelectedElements, setArtworkSelectedElements] = useState([]);
	const [isLoading, setIsLoading] = useState(null);
	const [showDropzoneModal, setShowDropzoneModal] = useState(false);
	const [dropZoneFiles, setDropZoneFiles] = useState([]);

	const isReplaceArtworkImageView =
		modalView === 'changeImage' &&
		expertPortfolioViewAndSelection.currentView === 'artworkSelectionView';

	const isValidArtworkId =
		expertPortfolioViewAndSelection.artworkId &&
		expertPortfolioViewAndSelection.artworkId !== 0;

	const isImageUploading =
		status === 'loading' || allArtworksImagesStatus === 'loading';

	const isVideoImage = (image) => {
		let isVideo = false;
		if (modalView === 'changeArtwork' || modalView === 'addArtwork') {
			isVideo =
				image?.object_type === 'VIDEO' ||
				image?.object_type === 'ANIMATION' ||
				image?.object_type === 'PERFORMANCE';
		}
		if (modalView === 'changeImage') {
			isVideo = image?.file_type === 'VIDEO';
		}

		return isVideo;
	};

	const handleImageUrlToShow = (image) => {
		if (modalView === 'changeArtwork' || modalView === 'addArtwork') {
			if (isVideoImage(image)) {
				return image?.video_url;
			} else {
				return image?.main_picture_url;
			}
		}
		if (modalView === 'changeImage') {
			return image?.url;
		}
	};

	const createImagesFunction = async (imagesArray) => {
		for (const image of imagesArray) {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			let values = {
				small_file,
				medium_file,
				large_file,
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
				type: 'ARTWORK',
				type_id:
					isReplaceArtworkImageView && isValidArtworkId
						? expertPortfolioViewAndSelection.artworkId
						: '0',
				organization_user_id: appUser.organization_user_id,
				file_type: 'IMAGE',
			};

			dispatch(createImage(values)).then((res) => {
				if (res.payload.status === 'Successfully') {
					dispatch(getAllArtworksImages());
					if (!isReplaceArtworkImageView) {
						setTabView('uploads');
					}
				}
			});
		}
	};

	const collectAndCreateImage = () => {
		createImagesFunction(dropZoneFiles);

		setDropZoneFiles([]);
		setShowDropzoneModal(false);
	};

	useEffect(() => {
		if (!singleArtist && mainArtistId) {
			dispatch(getArtist({ id: mainArtistId }));
		}
	}, [dispatch, singleArtist, mainArtistId]);

	useEffect(() => {
		if (!artworks) {
			dispatch(getArtworks());
		}
	}, [dispatch, artworks]);

	useEffect(() => {
		if (!allArtworksImages || allArtworksImages.length < 1) {
			dispatch(getAllArtworksImages());
		}
	}, [dispatch, allArtworksImages]);

	useEffect(() => {
		if (tabView === 'artworks') {
			isReplaceArtworkImageView && isValidArtworkId
				? setGridElementsToRender(
						allArtworksImages?.filter(
							(image) =>
								image.type_id === expertPortfolioViewAndSelection.artworkId
						)
				  )
				: setGridElementsToRender(
						filterElementsByName(
							isReplaceArtworkImageView ? allArtworksImages : artworks,
							filterValue
						)
				  );
		}
		if (tabView === 'profile') {
			setGridElementsToRender(
				filterElementsByName(singleArtist?.images, filterValue)
			);
		}
		if (tabView === 'uploads') {
			setGridElementsToRender(
				filterElementsByName(allArtworksImages, filterValue)
			);
		}
	}, [
		dispatch,
		tabView,
		artworks,
		allArtworksImages,
		singleArtist,
		filterValue,
		isReplaceArtworkImageView,
		isValidArtworkId,
		expertPortfolioViewAndSelection.artworkId,
	]);

	const handleSubmit = () => {
		if (!selectedElement && !artworkSelectedElements) return;

		const elementId = expertPortfolioViewAndSelection.selectedElementId;
		if (modalView === 'changeImage') {
			const values = {
				content: selectedElement.main_picture_url || selectedElement.url,
			};
			setIsLoading(true);
			dispatch(putExpertPortfolioSlideItemElement({ id: elementId, values }))
				.then(() => {
					dispatch(
						setShowSlideModalExpertPortfolio({
							isVisible: false,
							modalView: '',
						})
					);
					dispatch(
						setCurrentViewExpertPortfolioAndSelection({
							currentView: 'editionView',
							selectedElementId: null,
							selectedItemId: null,
							selectedSlideId: null,
						})
					);
				})
				.catch((error) => console.error(error))
				.finally(() => {
					setIsLoading(false);
				});
		}

		if (modalView === 'changeArtwork') {
			const artworkItemToChange =
				expertPortfolioViewAndSelection.selectedItemId;
			const artworkIdToBeCopied = selectedElement.id;
			dispatch(
				putExpertPortfolioArtworkToArtwork({
					ArtworkId: artworkIdToBeCopied,
					itemId: artworkItemToChange,
				})
			)
				.then(() => {
					dispatch(
						setShowSlideModalExpertPortfolio({
							isVisible: false,
							modalView: '',
						})
					);
					dispatch(
						setCurrentViewExpertPortfolioAndSelection({
							selectedElementId: null,
							currentView: 'editionView',
						})
					);
				})
				.catch((error) => console.error(error))
				.finally(() => {
					setIsLoading(false);
				});
		}
		if (modalView === 'addArtwork') {
			if (!artworkSelectedElements) return;

			const slideId = expertPortfolioViewAndSelection.selectedSlideId;
			const artworksIdToBeCopied = artworkSelectedElements.map(
				(artwork) => artwork.id
			);

			dispatch(
				postExpertPortfolioSlideArtworkItem({
					slide_id: slideId,
					artworks_id: artworksIdToBeCopied,
				})
			)
				.then(() => {
					dispatch(
						setShowSlideModalExpertPortfolio({
							isVisible: false,
							modalView: '',
						})
					);
					dispatch(
						setCurrentViewExpertPortfolioAndSelection({
							selectedElementId: null,
							currentView: 'editionView',
						})
					);
				})
				.catch((error) => console.error(error))
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const addSelectedElement = (newElement) => {
		if (selectedElement === newElement) {
			setSelectedElement(null);
			return;
		}
		setSelectedElement(newElement);
	};

	const addArtworkSelectedElements = (newElement) => {
		if (modalView === 'addArtwork') {
			if (!artworkSelectedElements?.includes(newElement)) {
				setArtworkSelectedElements([...artworkSelectedElements, newElement]);
			} else {
				const dataRemoveElementRepeat = artworkSelectedElements.filter(
					(element) => element !== newElement
				);
				setArtworkSelectedElements([...dataRemoveElementRepeat]);
			}
		}
	};

	const filterElementsByName = (imageElements, filterValue) => {
		if (!filterValue || !imageElements || !Array.isArray(imageElements)) {
			return imageElements;
		}
		return imageElements.filter((image) => {
			const targetValue = filterValue.toLowerCase();
			const imageName = image.name.toLowerCase();
			return imageName.includes(targetValue);
		});
	};

	const handleIsSelectedElement = (element) => {
		if (element?.id && selectedElement?.id) {
			return selectedElement.id === element.id;
		}
		if (element?.image_id && selectedElement?.image_id) {
			return element.image_id === selectedElement.image_id;
		}
		return false;
	};

	const handleIsArtworkSelectedElement = (element) => {
		if (artworkSelectedElements?.includes(element)) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<>
			{showDropzoneModal && (
				<div>
					<DropZoneModal
						showDropZoneModal={showDropzoneModal}
						setShowDropZoneModal={setShowDropzoneModal}
						dropZoneFiles={dropZoneFiles}
						collectAndCreateImage={collectAndCreateImage}
						setDropZoneFiles={setDropZoneFiles}
						maxFiles={1}
						dropzoneMobileMessage={'Haz click aquí para cargar la imagen'}
						dropzoneMessage={'Suelta la imagen aquí o haz click para cargar.'}
					/>
				</div>
			)}
			<motion.div
				initial={{ x: -1000 }}
				animate={{ x: 0 }}
				exit={{ x: -1000 }}
				transition={{ duration: 1 }}
				className='relative flex h-[90vh] w-screen flex-col gap-4 overflow-y-auto bg-middle_grey-50 p-8 pb-10  shadow-2xl xs:pb-20 md:fixed md:w-[50%] xl:px-20'
			>
				<div className='flex justify-between '>
					<Back
						className='cursor-pointer'
						onClick={() => dispatch(setShowSlideModalExpertPortfolio(false))}
					/>
					<TextContentPrincipal
						design={'!font-bold text-grey-50 mx-auto text-sm xs:text-base'}
						text={
							modalView === 'addArtwork'
								? 'Agregar elementos de obras'
								: 'Reemplazar Imagen'
						}
					/>
				</div>
				{!isReplaceArtworkImageView && (
					<div className='relative'>
						<MagnifyingGlassGrey className='absolute' />
						<input
							type='text'
							className='w-full rounded-xl py-2 pl-10 pr-2 text-left'
							placeholder={'Buscar en tus obras de Datarte'}
							onChange={(e) => {
								setFilterValue(e.target.value);
							}}
						/>
					</div>
				)}
				{modalView === 'changeImage' && (
					<ButtonFabExtendedPrimary
						isDisabled={isImageUploading}
						text={isImageUploading ? 'Guardando' : 'Subir una imagen'}
						className={'mx-auto py-1 text-white'}
						action={() => setShowDropzoneModal(!showDropzoneModal)}
						icon={isImageUploading ? SpinnerWhite : ''}
					/>
				)}
				{modalView === 'changeImage' && !isReplaceArtworkImageView && (
					<div className='mt-4 flex gap-2 border-b-2 border-white text-center'>
						<div onClick={() => setTabView('artworks')} className='w-1/3'>
							<TextContentThird
								design={`  cursor-pointer transition-all duration-300 rounded-t-lg pt-1 ${
									tabView === 'artworks'
										? 'bg-green-50 text-white'
										: 'hover:opacity-20'
								} `}
								text={isReplaceArtworkImageView ? 'Obra' : 'Obras'}
							/>
						</div>
						<div onClick={() => setTabView('profile')} className='w-1/3'>
							<TextContentThird
								design={`cursor-pointer transition-all duration-300 rounded-t-lg  pt-1 ${
									tabView === 'profile'
										? 'bg-green-50 text-white'
										: 'hover:opacity-50'
								} `}
								text={'Perfil'}
							/>
						</div>
						<div onClick={() => setTabView('uploads')} className='w-1/3'>
							<TextContentThird
								design={` cursor-pointer transition-all duration-300 rounded-t-lg ${
									tabView === 'uploads'
										? 'bg-green-50 text-white'
										: 'hover:opacity-50'
								} `}
								text={'Subidas'}
							/>
						</div>
					</div>
				)}
				<div className='flex min-h-[40%] w-full flex-col gap-4 overflow-y-auto'>
					{tabView === 'artworks' && (
						<TextContentThird
							text={
								isReplaceArtworkImageView ? (
									'Encuentra las imágenes relacionadas a esta obra'
								) : (
									<>
										{`Encuentra todas las imágenes de tus obras.`}
										<br />
										{`Para agregar una nueva obra ve a catalogación > obras`}
									</>
								)
							}
						/>
					)}
					{tabView === 'profile' && (
						<TextContentThird
							text={`Encuentra todas las imágenes de tu perfil.`}
						/>
					)}
					{tabView === 'uploads' && (
						<TextContentThird
							text={`Encuentra todas las imágenes que has subido.`}
						/>
					)}

					{gridElementsToRender?.length === 0 && (
						<TextContentThird
							design={`text-center `}
							text={'No hay obras disponibles'}
						/>
					)}
					{Array.isArray(gridElementsToRender) &&
						gridElementsToRender?.length > 0 && (
							<div className='grid  w-full grid-cols-3 justify-center gap-4 overflow-y-auto'>
								{gridElementsToRender?.length > 0 &&
									gridElementsToRender?.map((image, index) => (
										<Fragment key={index}>
											{modalView === 'addArtwork' ? (
												<div onClick={() => addArtworkSelectedElements(image)}>
													<ImageAndTitle
														id={image.id}
														imagen={handleImageUrlToShow(image)}
														title={tabView === 'artworks' && image.name}
														imageFormat={'small'}
														fileSelectedPreview={handleIsArtworkSelectedElement(
															image
														)}
														isVideoImage={isVideoImage(image)}
													/>
												</div>
											) : (
												<div onClick={() => addSelectedElement(image)}>
													<ImageAndTitle
														id={image.id}
														imagen={handleImageUrlToShow(image)}
														title={image.name}
														imageFormat={'small'}
														fileSelectedPreview={handleIsSelectedElement(image)}
														isVideoImage={isVideoImage(image)}
													/>
												</div>
											)}
										</Fragment>
									))}
							</div>
						)}
				</div>
				<div className={`mt-6 flex justify-center md:pb-22`}>
					<ButtonFabExtendedPrimary
						text={isLoading ? 'Cargando...' : t('AgregarAPágina')}
						action={handleSubmit}
						isDisabled={isLoading}
					/>
				</div>
			</motion.div>
		</>
	);
};

export default SideModalExpertPortfolio;
