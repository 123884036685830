export const incorrectSlugs = [
	'claudiaelenacardonacastrillón-coleccion-default',
	'cristianleonardorodríguezrincón-coleccion-default',
	'nohoralilianaavendañosánchez-coleccion-default',
	'jorgemariozumaqué-coleccion-default',
	'RobinsonMoreno-coleccion-general',
	'JuanRuge-coleccion-general',
	'ángelalbertocárdenasbermúdez-coleccion-default',
	'Lab',
	'diegotoropeláez-coleccion-default',
	'DúoAmazonas-coleccion-general',
	'CarlosRobertodaRosaRangel-coleccion-general',
	'LucasGallego_portafolio',
	'maríaalejandragarzóngraciano-coleccion-default',
	'NataliaHerrera_pequenosformatos',
	'SebastianHerrera_boceto1',
	'robertosánchez-coleccion-default',
	'CarlosRobertodaRosaRangel-coleccion-general',
	'doramorenozúñiga-coleccion-default',
	'camilogutiérrezmoreno-coleccion-default',
	'matiasmaestú-coleccion-default',
	'josécovo-coleccion-default',
	'Guadalupe_portafolio',
	'OliMartínez-coleccion-general',
	'luisalexanderrodríguezmurcia-coleccion-default',
	'cúrcumaartcenter-coleccion-default',
	'NataliaCorrea_toronto',
	'PaulaMatta-coleccion-general',
	'ángelamaríajaramilloborrero-coleccion-default',
	'alvaroenriqueriañomorales-coleccion-default',
	'LauraSavio-coleccion-general',
	'residenciacorazón-coleccion-default',
	'claudiapatriciabellobarragán-coleccion-default',
	'CarminaPerafan_primera-coleccion',
	'MarioTobar-coleccion-general',
	'maríateresaperonicadavid-coleccion-default',
	'camiloalejandrogarcíacamelo-coleccion-default',
	'paulagonzálezlatriglia-coleccion-default',
	'joséálvarezbolaño-coleccion-default',
	'pruebaángelacorrea-coleccion-default',
	'MarcosMedina-coleccion-default',
	'HugoCardenas-coleccion-general',
	'GabrielAngel-coleccion-default',
	'gonzaloleón-coleccion-default',
	'paolagarcía-coleccion-default',
	'ángelamaríajaramilloborrero-coleccion-default',
	'jorgelewismoralesflorián-coleccion-default',
	'MartinaAguirregomezcorta-coleccion-general',
	'nadiadíazgraverán-coleccion-default',
	'anamaríagutiérrezfernández-coleccion-default',
	'jhoedyosmánalbertogonzálezruiz-coleccion-default',
	'lorenatenoriopérez-coleccion-default',
	'linaumaña-coleccion-default',
	'waltercortésporras-coleccion-default',
	'carlosjuliánvillegasvillegas-coleccion-default',
	'maríalópez-coleccion-default',
	'miguelalbertoalvarezgaitán-coleccion-default',
	'marcoantonioherrerafernández-coleccion-default',
	'luisalbertotacángómez-coleccion-default',
	'andrésmauriciovallescarranza-coleccion-default',
	'monicalópezpinzón-coleccion-default',
	'LILIANABOTERO-coleccion-general',
	'juliánzalamea-coleccion-default',
	'ricardomacíalalinde-coleccion-default',
	'nathalysánchezangulo-coleccion-default',
	'YulianaTraverso-coleccion-general',
	'helenabeltrángil-coleccion-default',
	'douglasmauricioriveracórdova-coleccion-default',
	'maríaclarariveranoreña-coleccion-default',
	'SebastianHerrera_000',
	'alejandrasegoviajiménez-coleccion-default',
	'Artistadefelixtest_de_felix_primera-coleccion',
	'AnaMariaVelasco_colecciondesconocida',
	'josénasca-coleccion-default',
	'GuadalupeAguilar',
	'dorihanneherrera-coleccion-general',
	'juanfeliperamirezascencio-coleccion-default',
	'alejandraramírezzuluaga-coleccion-default',
	'maríaalejandragarzóngraciano-coleccion-default',
];
