import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import 'react-lazy-load-image-component/src/effects/blur.css';

// BL
import { compressAndResizeImage } from '../../../BusinessLogic/helpers/imageSize';
import {
	postArtwork,
	getArtworks,
} from '../../../BusinessLogic/redux/reducers/artwork';
import { artworkInitialValues } from '../../../BusinessLogic/data/ArtworkSingleData';
import { getOnboardingArtist } from '../../../BusinessLogic/redux/reducers/onboarding';
import { validateOnboardingArtworkForm } from '../../../BusinessLogic/data/OnboardingData';
import {
	getCollections,
	resetCollectionSingle,
} from '../../../BusinessLogic/redux/reducers/collection';
import { getPortfolios } from '../../../BusinessLogic/redux/reducers/portfolio';

// UI
import ImageOnboardingArtwork1 from '../../assets/images/ImageOnboardingArtwork1.png';
import ImageFullScreen from '../../molecules/Image/ImageFullScreen';
import TextTitlePrincipal from '../../atoms/Text/Titles/TextTitlePrincipal';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import ButtonWithTextIcon from '../../molecules/Buttons/WithIcon/ButtonWithTextIcon';
import ImageChangeWhite from '../../assets/icons/ImageChangeWhite.svg';
import { ReactComponent as CaretDownGreen } from '../../assets/icons/CaretDownGreen.svg';
import CheckIcon from '../../assets/icons/whiteCheck.svg';
import SpinnerWhite from '../../assets/icons/loadingSpinnerSmallWhite.svg';
import DropZoneModal from '../../molecules/DropZone/DropZoneModal';

const OnboardingArtworkForm = ({
	image,
	subtitleText,
	isSecondArtwork,
	artworks,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { appUser } = useSelector((state) => state.appUser);
	const { t } = useTranslation();
	const [showAdvancedForm, setShowAdvancedForm] = useState(false);
	const [showDropZoneModal, setShowDropZoneModal] = useState(false);
	const [dropZoneFiles, setDropZoneFiles] = useState([]);
	const { status } = useSelector((state) => state.artworks);
	const [artworkImageValues, setArtworkImageValues] = useState({});

	const getArtworksIds = (artworks) => {
		let idsArr = [];
		!artworks?.message &&
			artworks?.forEach((artwork) => {
				idsArr.push(artwork.artwork_id);
			});

		return idsArr;
	};
	const [artworksIds, setArtworksIds] = useState(getArtworksIds(artworks));
	const unknownValues =
		appUser.unknown_values && JSON.parse(appUser.unknown_values);

	const initialValues = {
		...artworkInitialValues,
		collection_id: unknownValues?.collection_id,
		main_picture_id: unknownValues?.picture_id,
		owner_id: unknownValues?.owner_person_id,
		artist_id: unknownValues?.artist_id,
		location_id: unknownValues?.location_id,
		created_by_id: appUser.organization_user_id,
		organization_user_id: Number(appUser.organization_user_id),
		type: 'ARTWORK',
		type_id: 0,
		file_type: 'IMAGE',
		name: '',
		date: '',
		owner_status: 'CONSERVADO',
		price: 0,
		currency_id: 5,
		measure_id: 1,
		origin_country_id: 47,
	};

	const createArtworkImageValues = (imageArray) => {
		imageArray.forEach(async (image) => {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			const values = {
				small_file: small_file,
				medium_file: medium_file,
				large_file: large_file,
				url_file: URL.createObjectURL(image.file),
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
			};
			setArtworkImageValues(values);
		});
	};

	const collectAndCreateImage = () => {
		createArtworkImageValues(dropZoneFiles);
		setShowDropZoneModal(false);
	};

	const handleSubmit = (payload) => {
		setDropZoneFiles([]);
		setArtworkImageValues({});
		setArtworksIds([...artworksIds, payload.id]);

		if (!artworks.code) {
			navigate('/bienvenida/segunda-obra');
		}

		if (isSecondArtwork) {
			dispatch(getOnboardingArtist(appUser.organization_user_id));
			navigate('/bienvenida/finalizacion');
		}
	};

	return (
		<div className='flex flex-col md:flex-row'>
			{showDropZoneModal && (
				<DropZoneModal
					showDropZoneModal={showDropZoneModal}
					setShowDropZoneModal={setShowDropZoneModal}
					dropZoneFiles={dropZoneFiles}
					collectAndCreateImage={collectAndCreateImage}
					setDropZoneFiles={setDropZoneFiles}
					maxFiles={1}
				/>
			)}
			<div className='md:hidden'>
				<LazyLoadImage
					src={image}
					width='100%'
					height='100%'
					effect='blur'
					className='max-h-[20vh] object-cover'
					wrapperClassName={` overflow-hidden flex items-center lazyLoadingImages`}
					delayTime='0'
					threshold='800'
				/>
			</div>
			<ImageFullScreen
				image={image}
				className={' hidden h-screen md:block md:w-1/2'}
			/>
			<div className='flex flex-col justify-center p-8  pb-28 md:w-1/2  md:items-center md:pb-8'>
				<TextTitlePrincipal
					design={'text-center'}
					title={t('OrdenaTuCreatividad')}
					textColor={'text-green-50'}
				/>
				<h2 className='p-8 text-center text-base font-normal '>
					{subtitleText}
				</h2>
				<div className='flex w-full justify-center'>
					<ButtonFab
						action={() => setShowDropZoneModal(true)}
						size={'large'}
						label={t('AñadirImagenDeObra')}
						icon={ImageChangeWhite}
					/>
				</div>
				<p className='body-Text3 flex w-full justify-center p-4'>
					{t('TamañoMáximoSoportadoDeLaImagenEsDe100MB')}
				</p>
				{dropZoneFiles.length === 1 && (
					<p className='body-Text2 flex w-full justify-center p-4'>
						{t('ImagenAgregada')}
						<span className='px-2 text-green-50'>
							{dropZoneFiles[0]?.file.name}
						</span>
					</p>
				)}
				<Formik
					enableReinitialize={true}
					validationSchema={validateOnboardingArtworkForm}
					initialValues={{
						...initialValues,
					}}
					onSubmit={(values) => {
						const artworkValues = { ...values, ...artworkImageValues };

						dispatch(postArtwork(artworkValues)).then((res) => {
							dispatch(getArtworks()).then(() => {
								dispatch(getCollections());
								dispatch(resetCollectionSingle());
								dispatch(getPortfolios());
								handleSubmit(res.payload);
							});
						});
					}}
				>
					<Form className='flex w-full flex-col justify-center'>
						<div className='grid-cols grid w-full place-content-center py-4 text-grey-50'>
							<p className='body-Text2 w-full sm:w-80'>{t('TituloDeLaObra')}</p>
							<Field
								as='input'
								className=' my-2 h-10 w-full rounded border border-middle_grey-50 sm:w-80'
								type='text'
								name='name'
							/>
							<ErrorMessage
								name='name'
								render={(msg) => (
									<div className='body-Text3 text-red-400'>{t(msg)}</div>
								)}
							/>
							{showAdvancedForm && (
								<div className='w-full sm:w-80'>
									<p className='body-Text2 mt-4 w-full sm:w-80'>{t('Fecha')}</p>
									<Field
										className=' my-2 h-10 w-full rounded border border-middle_grey-50 sm:w-80'
										type='date'
										name='date'
									/>
									<h3 className='body-Text2 w-full py-2 text-left '>Estado</h3>
									<Field
										name='owner_status'
										type='Text'
										as='select'
										className='body-Text2 h-10 w-full  rounded border border-middle_grey-50  py-2'
									>
										<option value='CONSERVADO'>{t('Conservado')}</option>
										<option value='VENDIDO'>{t('Vendido')}</option>
										<option value='DONADO'>{t('Donado')}</option>
										<option value='DISPONIBLE'>{t('Disponible')}</option>
										<option value='DESCONOCIDO'>{t('Desconocido')}</option>
									</Field>
									<h3 className='body-Text2 w-full py-2 text-left '>
										{t('Dimensiones')}
									</h3>

									<Field
										className='body-Text2 my-2  h-10 w-full rounded border border-middle_grey-50 px-2 sm:w-80'
										placeholder={t('AlturaCm')}
										name='dimensions_height'
										type='Number'
									/>
									<Field
										className='body-Text2 my-2  h-10 w-full rounded border border-middle_grey-50 px-2 sm:w-80'
										placeholder={t('LargoCm')}
										name='dimensions_length'
										type='Number'
									/>
									<Field
										className='body-Text2 my-2  h-10 w-full rounded border border-middle_grey-50 px-2 sm:w-80'
										placeholder={t('AnchoCm')}
										name='dimensions_width'
										type='Number'
									/>
									<h3 className='body-Text2 w-full py-2 text-left '>
										{t('Técnica')}
									</h3>

									<Field
										className='body-Text2 my-2  h-10 w-full rounded border border-middle_grey-50 px-2 sm:w-80'
										placeholder={t('Técnica')}
										name='technique'
										type='text'
									/>
								</div>
							)}
							<div className='flex justify-center'>
								<ButtonWithTextIcon
									text={t('OpcionesAvanzadas')}
									contentDesign={'text-green-50'}
									design='rounded mx-auto py-8'
									action={() => setShowAdvancedForm(!showAdvancedForm)}
									icon={
										<CaretDownGreen
											className={
												showAdvancedForm && '-rotate-180 transform transition'
											}
										/>
									}
								/>
							</div>
						</div>
						<div className='flex justify-center'>
							<div className='grid w-full grid-cols-4 gap-2 xl:w-[60%]'>
								<span className='h-2 rounded border bg-green-50'></span>
								<span className='h-2 rounded border bg-green-50'></span>
								<span
									className={
										isSecondArtwork
											? 'h-2 rounded border bg-green-50'
											: 'h-2 rounded border bg-light_grey-75'
									}
								></span>
								<span className='h-2 rounded border bg-light_grey-75'></span>
							</div>
						</div>
						<div className='flex w-full flex-row justify-evenly gap-10 pt-8'>
							<ButtonFab
								label={t('SaltarPasos')}
								design={'text-gray-400 filter-none'}
								size={'medium'}
								colour={'white'}
								action={() => navigate('/')}
							/>
							<ButtonFab
								disabled={status === 'loading' ? true : false}
								design={'drop-shadow-lg'}
								colour='green'
								size={'medium'}
								label={status === 'loading' ? t('Guardando') : t('Continuar')}
								icon={status === 'loading' ? SpinnerWhite : CheckIcon}
								type='submit'
							/>
						</div>
					</Form>
				</Formik>
			</div>
		</div>
	);
};

OnboardingArtworkForm.propTypes = {
	/** URL of the  image */
	image: PropTypes.string,
	/** Subtitle text */
	subtitleText: PropTypes.string,
	/** Bool prop to determinate if the form is going to create a second artwork */
	isSecondArtwork: PropTypes.bool,
	/** Artworks to be in the collection */
	artworks: PropTypes.array,
};
OnboardingArtworkForm.defaultProps = {
	image: ImageOnboardingArtwork1,
};

export default OnboardingArtworkForm;
