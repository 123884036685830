//3rd party
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Thumbs, Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';
//UI
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
//UI

import ImageAndTitle from './ImageAndTitle';
import ButtonFabExtendedPrimary from '../Buttons/FABExtended/ButtonFabExtendedPrimary';
import DropZoneModal from '../DropZone/DropZoneModal';
import ModalImage from '../../organisms/PopUp/ModalImage';
/**
 * @name ImageCarousel
 * @description Shows a carousel with a thumbnail. Plus navigation arrows
 * @helper https://swiperjs.com/
 */

const ImageCarousel = ({
	images,
	mainPicture,
	createMainImageFunction,
	sectionTitle,
	containerDesign,
	isThereACoverImageButton,
}) => {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [showDropZoneModal, setShowDropZoneModal] = useState(false);
	const [showModalImage, setShowModalImage] = useState(false);
	const [imageOnModal, setImageOnModal] = useState(null);
	const [dropZoneFiles, setDropZoneFiles] = useState([]);

	const { t } = useTranslation();
	let defaultImage =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';

	if (images === undefined || images.length === 0 || !mainPicture) {
		images = [{ main_picture_url: defaultImage }];
	}

	const collectAndCreateImage = () => {
		createMainImageFunction(dropZoneFiles);
		setDropZoneFiles([]);
		setShowDropZoneModal(false);
	};

	return (
		<div className={containerDesign}>
			<div>
				{showDropZoneModal && (
					<div>
						<DropZoneModal
							showDropZoneModal={showDropZoneModal}
							setShowDropZoneModal={setShowDropZoneModal}
							dropZoneFiles={dropZoneFiles}
							collectAndCreateImage={collectAndCreateImage}
							setDropZoneFiles={setDropZoneFiles}
							maxFiles={1}
						/>
					</div>
				)}
				{images.length <= 1 ? (
					<div className='flex flex-col justify-center'>
						<ImageAndTitle
							aspectRatioProp='auto'
							imagen={images[0].main_picture_url || mainPicture}
							objectFitProp='scale-down'
							sectionTitle={sectionTitle}
						/>
						{isThereACoverImageButton && (
							<div className='flex justify-center p-4'>
								<ButtonFabExtendedPrimary
									text={t('CambiarImagenPortada')}
									className={'w-2/3 text-white'}
									action={() => setShowDropZoneModal(true)}
								/>
							</div>
						)}
					</div>
				) : (
					<div className='relative flex select-none flex-col gap-4'>
						<div className=' mx-auto flex max-h-96 w-11/12 '>
							<Swiper
								key={'Swiper1'}
								style={{
									'--swiper-pagination-color': '#fff',
									'--swiper-navigation-color': '#000',
								}}
								loop={true}
								spaceBetween={10}
								grabCursor={true}
								pagination={true}
								modules={[Pagination, Thumbs, Navigation]}
								thumbs={{
									swiper:
										thumbsSwiper && !thumbsSwiper.destroyed
											? thumbsSwiper
											: null,
								}}
								className='mySwiper2'
							>
								{images.map((image, i) => (
									<SwiperSlide
										key={i}
										className=' my-auto flex justify-center p-4'
									>
										<img
											key={i}
											aspectRatioProp='auto'
											src={image.main_picture_url}
											alt={`Carousel ${i}`}
											style={{ objectFit: 'scale-down' }}
											onClick={() => {
												setShowModalImage(true);
												setImageOnModal(image.main_picture_url);
											}}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
						{isThereACoverImageButton && (
							<div className='-mt-8 mb-3 flex justify-center'>
								<ButtonFabExtendedPrimary
									text={t('CambiarImagenPortada')}
									className={'w-2/3 text-white'}
									action={() => setShowDropZoneModal(true)}
								/>
							</div>
						)}
						<div className='mx-auto flex h-20 w-11/12'>
							<Swiper
								key={'Swiper2'}
								style={{
									'--swiper-navigation-color': '#fff',
								}}
								loop={true}
								spaceBetween={10}
								slidesPerView={3}
								modules={[Navigation, Thumbs]}
								watchSlidesProgress
								onSwiper={setThumbsSwiper}
								className='mySwiper w-full'
								grabCursor={true}
							>
								{images.map((image, i) => (
									<SwiperSlide
										key={`key=${i}`}
										className='my-auto flex h-20 w-20 justify-center'
									>
										<img
											key={`img${i}`}
											src={image.main_picture_url}
											alt={`Carousel ${i}`}
											style={{ objectFit: 'cover', aspectRatio: '1/1' }}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</div>
				)}
			</div>
			{showModalImage && imageOnModal && (
				<ModalImage
					mainPicture={imageOnModal}
					name={'Carousel image'}
					showModalImage={showModalImage}
					setShowModalImage={setShowModalImage}
				/>
			)}
		</div>
	);
};

ImageCarousel.propTypes = {
	/** Array of images*/
	images: PropTypes.array,
	/** String prop to define the title of the section that is passed to the ImageAndTitle component */
	sectionTitle: PropTypes.string,
	/** String prop with the url of the main picture */
	mainPicture: PropTypes.string,
	/** Func prop to create the main image */
	createMainImageFunction: PropTypes.func,
	/** String prop with the container design*/
	containerDesign: PropTypes.string,
	/** Bool prop to determinate if there is a button under ImageAndTitle component*/
	isThereACoverImageButton: PropTypes.string,
};

ImageCarousel.defaultProps = {
	containerDesign: 'w-full md:w-1/3 xl:w-1/3 py-6 relative',
	isThereACoverImageButton: true,
};
export default ImageCarousel;
