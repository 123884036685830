import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//BL
import {
	ENDPOINTS_ARTIST,
	ENDPOINTS_CITY,
	ENDPOINTS_COUNTRY,
} from '../../helpers/routes';
import {
	updateTabsObject,
	updateDropdowns,
} from '../../helpers/SingleViewHelpers';
import { fetchGetData } from '../../hooks/fetchGetData';
import { artistSingleTabs } from '../../data/ArtistSingleData';
import { fetchPutData } from '../../hooks/fetchPutData';
import { fetchPutArrayImages } from '../../hooks/fetchPutArrayImages';
import postDataAndImage from '../../helpers/postDataAndImage';

export const getArtists = createAsyncThunk('artists/fetchArtists', async () => {
	const { organization_user_id } = localStorage;
	return await fetchGetData(
		`${ENDPOINTS_ARTIST.GET_ARTISTS}${organization_user_id}/`
	);
});

export const getArtist = createAsyncThunk(
	'artists/fetchArtist',
	async ({ id }) => {
		return await fetchGetData(`${ENDPOINTS_ARTIST.GET_ARTIST}${id}/`);
	}
);

export const getPublicArtist = createAsyncThunk(
	'artists/fetchArtist',
	async (id) => {
		return await fetchGetData(`${ENDPOINTS_ARTIST.GET_PUBLIC_ARTIST}${id}/`);
	}
);

export const putArtist = createAsyncThunk(
	'artists/putArtist',
	async (input) => {
		const { values, id } = input;
		return await fetchPutData(`${ENDPOINTS_ARTIST.PUT_ARTIST}${id}/`, values);
	}
);

export const postArtist = createAsyncThunk(
	'artist/postArtist',
	async (values) => {
		return await postDataAndImage(ENDPOINTS_ARTIST.POST_ARTIST, values);
	}
);

export const getArtistDropdown = createAsyncThunk(
	'artists/getArtistDropdown',
	async () => {
		const { organization_user_id } = localStorage;
		const { countries } = await fetchGetData(
			ENDPOINTS_COUNTRY.GET_COUNTRIES_WITH_LABELS
		);
		const { cities } = await fetchGetData(ENDPOINTS_CITY.GET_CITIES_ALL);
		const allArtist = await fetchGetData(
			ENDPOINTS_ARTIST.GET_ARTISTS_WITH_LABELS + `${organization_user_id}/`
		);
		let final = {
			countries,
			cities,
			allArtist,
		};
		return final;
	}
);

export const organizeImageGrid = createAsyncThunk(
	'artists/organizeImageGrid',
	async (values) => {
		return await fetchPutArrayImages(values);
	}
);

const initialStateArtistTabs = artistSingleTabs;
const initialSingleArtist = {
	name: '',
	content: '',
	pictures: [
		{
			url: 'https://art-catalogue.s3.us-east-2.amazonaws.com/organization_user31/5122_medium',
		},
	],
};
const initialArtistSingleTabs = updateTabsObject(
	artistSingleTabs,
	initialSingleArtist
);

const initialState = {
	artists: null,
	singleArtist: null,
	status: 'loading',
	artistSingleTabs: initialArtistSingleTabs,
	initialStateTabs: initialStateArtistTabs,
	artistDropdown: {},
	loadedArtistDropdown: false,
};

export const artistSlice = createSlice({
	name: 'artists',
	initialState,
	reducers: {
		resetSingleArtist: (state) => {
			state.singleArtist = null;
		},
	},
	extraReducers: {
		//GET ARTIST BY ORG ID
		[getArtists.pending]: (state) => {
			state.status = 'loading';
		},
		[getArtists.fulfilled]: (state, { payload }) => {
			state.artists = payload;
			state.status = 'success';
		},
		[getArtists.rejected]: (state) => {
			state.status = 'failed';
		},
		//single Artist
		[getArtist.pending]: (state) => {
			state.artistSingleTabs = updateTabsObject(
				state.initialStateTabs,
				initialSingleArtist
			);
			state.status = 'loading';
		},
		[getArtist.fulfilled]: (state, { payload }) => {
			let Single = payload;
			state.singleArtist = Single;
			if (Single) {
				state.artistSingleTabs = updateTabsObject(
					state.artistSingleTabs,
					Single
				);
				state.artistSingleTabs = updateDropdowns(
					state.artistSingleTabs,
					state.artistDropdown['countries'],
					0,
					0,
					2
				);
				state.artistSingleTabs = updateDropdowns(
					state.artistSingleTabs,
					state.artistDropdown['cities'],
					0,
					0,
					3
				);
			}
			state.status = 'success';
		},
		[getArtist.rejected]: (state) => {
			state.status = 'failed';
		},

		//put Single Artist
		[putArtist.pending]: (state) => {
			state.status = 'loading';
		},
		[putArtist.fulfilled]: (state) => {
			state.status = 'success';
		},
		[putArtist.rejected]: (state) => {
			state.status = 'failed';
		},

		//Post Artist
		[postArtist.pending]: (state) => {
			state.status = 'loading';
		},
		[postArtist.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
			state.loadedArtistDropdown = true;
		},
		[postArtist.rejected]: (state) => {
			state.status = 'failed';
		},

		//get artists/getArtistDropdown'
		[getArtistDropdown.pending]: (state) => {
			state.status = 'loading';
		},
		[getArtistDropdown.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.artistDropdown = payload;
			state.loadedArtistDropdown = true;
		},
		[getArtistDropdown.rejected]: (state) => {
			state.status = 'failed';
		},
		// organize grid of Images
		[organizeImageGrid.pending]: (state) => {
			state.status = 'loading';
		},
		[organizeImageGrid.fulfilled]: (state, { payload }) => {
			state.backendAnswer = payload;
			state.status = 'success';
		},
		[organizeImageGrid.rejected]: (state) => {
			state.status = 'failed';
		},
	},
});

export const { resetSingleArtist } = artistSlice.actions;

export default artistSlice.reducer;
