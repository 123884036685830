import { ENDPOINTS_PICTURES } from '../helpers/routes';
import { submitErrorToSlack } from '../helpers/SlackIntegrations';

export const fetchPutImage = async (values) => {
	const { token } = localStorage;
	let endpoint = '';
	let form = new FormData();
	form.append('small_file', values.small_file);
	form.append('medium_file', values.medium_file);
	form.append('large_file', values.large_file);
	form.append('organization_user_id', values.organization_user_id);
	form.append('name_image', values.name_image);
	form.append('description_image', values.description_image);
	form.append('type_id', values.type_id);
	form.append('type', values.type);
	form.append('file_type', values.file_type);
	form.append('action', values.action);

	if (values.main_picture_id === 1) {
		return fetch(ENDPOINTS_PICTURES.POST_PICTURE, {
			method: 'POST',
			body: form,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(async (res) => {
				const response = await res.json();
				endpoint = res.url;
				if (res.status >= 500) {
					submitErrorToSlack(endpoint, response, 'POST');
				}
				return response;
			})
			.catch((error) => {
				if (error.response.status >= 500) {
					submitErrorToSlack(endpoint, error, 'GET');
				}
			});
	} else {
		return fetch(
			ENDPOINTS_PICTURES.POST_PICTURE + values.main_picture_id + '/',
			{
				method: 'PUT',
				body: form,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then(async (res) => {
				const response = await res.json();
				endpoint = res.url;
				if (res.status >= 500) {
					submitErrorToSlack(endpoint, response, 'PUT');
				}
				return response;
			})
			.catch((error) => {
				if (error.response.status >= 500) {
					submitErrorToSlack(endpoint, error, 'GET');
				}
			});
	}
};
